import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NavMenuOptions } from '../../AppConstants';
import AuthenticationPage from '../../components/Auth/AuthenticationPage';
import IFrameDrawer from '../../components/IFrameDrawer/IFrameDrawer';
import { Toast } from '../../components/Notifications/Notifications';
import PrivateRoute from '../../components/privateRoute/PrivateRoute';
import KeyContactsMobile from '../../containers-mobile/KeyContactsMobile/KeyContactsMobile';
import DashboardMobile from '../../containers-mobile/LoginMobile/DashboardMobile/DashboardMobile';
import LoginMobile from '../../containers-mobile/LoginMobile/LoginMobile';
import useDeviceDetect from '../../hooks/UseDeviceDetect';
import AuthMonitor from '../../libs/helper/AuthMonitor';
import { isAuthenticated } from '../../libs/helper/HelperFunctions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import AccountManagement from '../AccountManagement/AccountManagement';
import Assessments from '../Assessments/Assessments';
import AssetAuthenticationRoutes from '../AssetAuthenticationRoutes/AssetAuthenticationRoutes';
import AssetAuthorisation from '../AssetAuthorisation/AssetAuthorisation';
import BlockedScreen from '../BlockedScreen/BlockedScreen';
import CBOSummary from '../CBOSummary/CBOSummary';
import CBO_Login from '../CBO_Login/CBO_Login';
import ConfigureCBOJourney from '../CBOConfigureJourney/ConfigureCBOJourney';
import CompliancePolicies from '../CompliancePolicies/CompliancePolicies';
import IndustryCompliancePolicies from '../CompliancePolicies/IndustryCompliancePolicies';
import Dashboard from '../Dashboard/Dashboard';
import DeliveryPipelines from '../DeliveryPipeline/DeliveryPipelines';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import GiveFeedbackForm from '../FeedbackForm/GiveFeedbackForm';
import FeedbackResponse from '../FeedbackResponse/FeedbackResponse';
import JobRuleSet from '../JobRuleSet/JobRuleSet';
import KeyContacts from '../KeyContacts/KeyContacts';
import Login from '../Login/Login';
import PipelineHistory from '../PipelineHistory/PipelineHistory';
import QuickLinks from '../QuickLinks/QuickLinks';
import UserManagement from '../UserManagement/UserManagement';
import WebAnalytics from '../WebAnalytics/WebAnalytics';
import Documentation from '../Documentation/Documentation';
import SubHubInsuranceDashboard from '../SubHubInsuranceDashboard/SubHubInsuranceDashboard';
import HomePage from '../HomePage/HomePage';
import PolicyAnalyticsDashboards from '../SubHubInsuranceDashboard/PolicyAnalyticsDashboard/PolicyAnalyticsDashboards';
import PersonaDrawer from '../Personas/PersonaDrawer';
import InsightsDashboard from '../SubHubInsuranceDashboard/InsightsDashboard/InsightsDashboard';
import ViewDetails from '../SubHubInsuranceDashboard/ViewDetails/ViewDetails';
import NewCaseDrawer from '../CaseSubmission/NewCaseDrawer';
// import MNACIDashboard from '../MNACIDashboard/MNACIDashboard';
import Chatbot from '../MNACIChatbot/Chatbot';
import Analysis from '../Analysis/Analysis';
import MNACIDashboardNew from '../MNACIDashboard/MNACIDashboardNew';

const Layout = () => {
  const { isMobile } = useDeviceDetect();

  const { showToast, description, message, type } = useAppSelector(
    ({ toast: { showToast, description, message, type } }: IApplicationState) => ({
      showToast,
      description,
      message,
      type,
    }),
  );

  return (
    <>
      {/* {isAuthenticated() && <AuthMonitor />} */}
      {showToast && Toast(description, message, type)}
      <Router>
        <Switch>
          <Route exact path={routes.login.viewGeneric}>
            {<Login />}
          </Route>
          <Route exact path={routes.submissions}>
            <PrivateRoute isMobile={isMobile}>
              <SubHubInsuranceDashboard breadCrumbRequired />
            </PrivateRoute>
          </Route>
          <Route exact path={'/dashboard/submissions/:Id'}>
            <PrivateRoute isMobile={isMobile}>
              <ViewDetails />
            </PrivateRoute>
          </Route>
          <Route path={routes.dashboard}>
            <PrivateRoute isMobile={isMobile}>
              {/* <SubHubInsuranceDashboard /> */}
              <PolicyAnalyticsDashboards />
            </PrivateRoute>
          </Route>
          <Route exact path={'/persona'}>
            {/* <PrivateRoute isMobile={isMobile}> */}
            <PersonaDrawer />
            {/* </PrivateRoute> */}
          </Route>
          <Route path={'/newCaseForm'}>
            {/* <PrivateRoute isMobile={isMobile}> */}
            <NewCaseDrawer />
            {/* </PrivateRoute> */}
          </Route>
          <Route path={routes.aibot}>
            <PrivateRoute isMobile={isMobile}>
            <Chatbot />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.underwriter}>
            <PrivateRoute isMobile={isMobile}>
              <SubHubInsuranceDashboard breadCrumbRequired={false} />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.businessIntelligence}>
            <PrivateRoute isMobile={isMobile}>
              <InsightsDashboard />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.ingestion}>
            <PrivateRoute isMobile={isMobile}>
              <MNACIDashboardNew />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.analysis}>
            <PrivateRoute isMobile={isMobile}>
              <Analysis />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.authentication}>
            <AuthenticationPage />
          </Route>
          <Route path="/">
            <Redirect to={isAuthenticated() ? routes.ingestion : routes.login.viewGeneric} />
          </Route>
          {/* <Route path="/">
            <Redirect to={'/login'} />
          </Route> */}
          {/* <Route exact path={routes.iframe}>
            <IFrameDrawer visible={true} />
          </Route>
          <Route exact path={routes.login.viewGeneric}>
            {isMobile ? <LoginMobile /> : <Login />}
          </Route>
         
          <Route exact path={routes.login.error}>
            <BlockedScreen />
          </Route>
          <Route exact path={routes.dashboard}>
            <PrivateRoute isMobile={isMobile}>
              {isMobile ? <DashboardMobile /> : <Dashboard />}
            </PrivateRoute>
          </Route>
          <Route path={routes.operations}>
            <PrivateRoute>
              <JobRuleSet />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.deliveryPipeline}>
            <PrivateRoute>
              <DeliveryPipelines />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.pipelineHistory}>
            <PrivateRoute>
              <PipelineHistory />
            </PrivateRoute>
          </Route>
          <Route path={routes.authentication}>
            <PrivateRoute hideHomePage>
              <AssetAuthenticationRoutes />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.accountManagement}>
            <PrivateRoute>
              <AccountManagement />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.userManagement}>
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.assetAuthorisation}>
            <PrivateRoute>
              <AssetAuthorisation />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.webAnalytics}>
            <PrivateRoute>
              <WebAnalytics />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.documentation}>
            <PrivateRoute>
              <Documentation />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.keyContacts}>
            <PrivateRoute
              isMobile={isMobile}
              showProjectSelector={false}
              pageTitle={NavMenuOptions.KEY_CONTACTS}
            >
              {isMobile ? <KeyContactsMobile /> : <KeyContacts />}
            </PrivateRoute>
          </Route>
          <Route exact path={routes.quickLinks}>
            <PrivateRoute>
              <QuickLinks />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.feedback}>
            <PrivateRoute>
              <FeedbackForm />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.assessment}>
            <PrivateRoute>
              <Assessments />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.giveFeedback}>
            <PrivateRoute>
              <GiveFeedbackForm />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.feedbackResponse}>
            <PrivateRoute>
              <FeedbackResponse />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.compliancePolicies}>
            <PrivateRoute>
              <CompliancePolicies />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.industryCompliancePolicies}>
            <PrivateRoute>
              <IndustryCompliancePolicies />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.cboSummary}>
            <PrivateRoute>
              <CBOSummary />
            </PrivateRoute>
          </Route>
          <Route exact path={routes.cboConfigureJourney}>
            <PrivateRoute>
              <ConfigureCBOJourney />
            </PrivateRoute>
          </Route>
          {/* <Route exact path={routes.createEnvironments}> // Maybe required later
            <PrivateRoute>
              <CreateCloudEnvironments />
            </PrivateRoute>
          </Route>

          <Route exact path={routes.cbo_login}>
            <CBO_Login />
          </Route>
        */}
        </Switch>
      </Router>
    </>
  );
};

export default Layout;
