import DevOps from '../../assets/icons/devsecops.svg';
import GCPIcon from '../../assets/icons/gcp-icon.svg';
import AWSIcon from '../../assets/icons/aws-icon.svg';
import OracleIcon from '../../assets/icons/oracle.png';
import AzureIcon from '../../assets/icons/azure-icon.svg';
import OpenShiftIcon from '../../assets/icons/openshift-icon.svg';
import MulticloudIcon from '../../assets/icons/multicloud_icon.png';
import DiscoverIcon7 from '../../assets/icons/discoverAccess7.svg';
import CloudFoundationStrategy from '../../assets/icons/cloud-foundation.svg';
import DiscoverIcon1 from '../../assets/icons/discover_assess_icon_1.svg';
import DiscoverIcon3 from '../../assets/icons/discover_assess_icon_2.svg';
import DiscoverIcon4 from '../../assets/icons/discover_assess_icon_3.svg';
import DiscoverIcon2 from '../../assets/icons/discover_assess_icon_4.svg';
import DiscoverIcon5 from '../../assets/icons/discover_assess_icon_5.svg';
import DiscoverIcon6 from '../../assets/icons/discover_assess_icon_6.svg';
import { IDiscoverAssessListItem, AssessJourneySection } from '../../libs/models/DashboardModels';
import { CWBDrawerType } from '../FinancialReportDrawer/FinancialReportDrawerConstants';

export interface ITabMenu {
  menuName: AssessJourneySection;
  blackIcon: string;
  whiteIcon: string;
}

export const DiscoverAssessLabels = {
  devsecopsAssessment: 'Devops Assessment',
  devsecDownload1: '1. Complete your Assessment by clicking on the “Download File” button.',
  devsecDownload2: ' 2. Upload the file to view the report.',
  downloadFile: 'Download File',
  downloadHint: 'Upload the DevOps Assessment file',
  uploadFile: 'Upload File',
  headerTitle: 'Discover & Access',
  reportTitle: 'Cloud Financial Analysis',
  subtitle:
    'Through discovery, dependency mapping, and risk assessments based on current usage, as well as migration analysis, Cloud Discovery & Assessment enables migrations to be conducted according to informed decisions, helping to minimize risks while ensuring business continuity throughout and after cloud migration.',
  cfaTitle: 'Cloud Financial Analysis',
  cspTitle: 'Cloud Suitability & Placement',
  omaTitle: 'Ops Model Assessment',
  csoTitle: 'Cloud Spend Optimization',
  viewReport: 'View Report',
  reaccess: 'Re-Assess',
  assess: 'Assess',
  enable: 'Enable',
  disable: 'Disable',
  viewMore: 'View More',
  modalOk: 'Submit',
  devsecAnalytics: 'DevOps Analytics',
  reAccessDevsec: 'Re-Assess DevOps',
  dimensionAnalysis: 'Dimension Analysis',
  capablityAnalysis: 'Capability Analysis',
  modalTitle: 'Successfully Uploaded the file',
  modalSubtitle: 'You would be able to see the metrics in “Discover & Access”',
  toastMessage: 'DevOps file successfully Downloaded!',
  heatMapTitle: 'Digital Maturity Assessment - Current State Heatmap',
  prioritizationMatrix: 'Prioritization Matrix',
  blankQuestionaire: 'BlankQuestionaire',
  success: 'Success',
  fileUpload: 'File Uploaded',
  error: 'Error',
  fileUploadFailed: 'File Upload Failed',
  loading: 'Loading...',
  digitalDelivery: 'Digital Delivery Capablities',
  devopsMaturity: 'DevOps Maturity Map',
  capablityKey: 'Capablity Key:',
  na: ' NA',
  nonExistent: ' Non-existent',
  developing: 'Developing',
  defined: 'Defined',
  advanced: 'Advanced',
  leading: 'Leading',
  popoverContent:
    ' Authorizes and audits all data requests, enforcing data security and compliance policies. Accelerate agility, minimize data security risks.',
  devOpsColor: ['#93d150', '#0070c0', '#fec100', '#702f9f', '#bf0001'],
};

export const DiscoverAssessList: IDiscoverAssessListItem[] = [
  {
    id: 0,
    label: 'Financial Analysis',
    isEnabled: false,
    applicationName: 'TCO',
    icon: DiscoverIcon1,
    cwbDrawerType: CWBDrawerType.CFA,
    reportCount: 5,
    popoverContent:
      'Provides a robust, user-friendly and repeatable approach for helping in the analysis of shifting applications from on-premise to the cloud.',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
  },
  {
    id: 1,
    label: 'Suitability & Placement',
    isEnabled: false,
    applicationName: 'CSP2',
    icon: DiscoverIcon4,
    reportCount: 3,
    cwbDrawerType: CWBDrawerType.CSP,
    popoverContent:
      'Conducts an assessment and provides insights for making strategic decisions on the overall public cloud readiness of the application portfolio.',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
    },
  {
    id: 2,
    label: 'Op Model Assessment',
    isEnabled: false,
    applicationName: 'OMA',
    reportCount: 2,
    icon: DiscoverIcon3,
    cwbDrawerType: CWBDrawerType.OMA,
    popoverContent:
      'Assesses an organization’s cloud maturity adoption on key aspects spanning across 3 broad dimensions: People and Talent, Policy and Controls, Platform and Adoption',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
    },
  {
    id: 3,
    label: 'Spend Optimization',
    isEnabled: true,
    reportCount: 9,
    applicationName: '',
    cwbDrawerType: CWBDrawerType.CSO,
    icon: DiscoverIcon2,
    popoverContent:
      'Provides analysis for cloud billing reports and provides suggestions for optimization in cloud run costs. To know more or to create a new CSO project please reach out to cwb@deloitte.com',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
  },
  {
    id: 4,
    label: 'Tech Value Benefit Realization',
    isEnabled: true,
    icon: DiscoverIcon5,
    applicationName: '',
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CTBR,
    popoverContent:
      'Provides insights on how to maximize savings and track the key value benefits during application migration.',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
  },
  {
    id: 5,
    label: 'Green Cloud',
    isEnabled: true,
    applicationName: '',
    icon: DiscoverIcon6,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.GC,
    popoverContent:
      'To help the users calculate changes in client’s carbon footprint upon migration to cloud​.',
    section: AssessJourneySection.PRE_CLOUD,
    isCloudStrategy: true,
  },
  {
    id: 6,
    label: 'DevOps Assessment',
    isEnabled: true,
    applicationName: 'devops',
    icon: DevOps,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.DOA,
    popoverContent:
      'DevOps assessment helps outline current maturity and identify a road map of improvement areas.',
    isMoreOptions: true,
    section: AssessJourneySection.PRE_CLOUD,
    isDiscoverCard: true,
    isCloudStrategy: false,
    isFoundationSummaryCard: true
  },
  {
    id: 7,
    label: 'Foundation Health Assessment',
    isEnabled: true,
    applicationName: 'health',
    icon: DiscoverIcon7,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFHA,
    popoverContent:
      'To get the health report of the environment by collecting logs and other information.',
    isMoreOptions: true,
    section: AssessJourneySection.PRE_CLOUD,
    isDiscoverCard: true,
    isCloudStrategy: false,
    isFoundationSummaryCard: true
  },
  {
    id: 14,
    label: 'Cloud Foundation Strategy',
    isEnabled: true,
    applicationName: 'CloudFoundationStrategy',
    icon: CloudFoundationStrategy,
    reportCount: 5,
    // cwbDrawerType: CWBDrawerType.CFHA,
    cwbDrawerType: CWBDrawerType.CFS,
    popoverContent:
      'lorem ipsum lorem ipsum 12',
    isMoreOptions: true,
    section: AssessJourneySection.PRE_CLOUD,
    isDiscoverCard: true,
    isFoundationSummaryCard: true,
  },
];
export const AssessmentDiscoverCard = [
  {
    id: 8,
    label: 'Google Cloud Platform',
    isEnabled: true,
    applicationName: 'health',
    icon: GCPIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFHA,
    popoverContent:
      'Lorem ipsum dolor sit amet vel quasi neque et tempore.',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: true
  },
  {
    id: 9,
    label: 'Amazon Web Services',
    isEnabled: false,
    applicationName: 'TCO',
    icon: AWSIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFA,
    popoverContent:
      'Lorem ipsum dolor sit amet vel quasi neque et tempore.',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: false
  },
  {
    id: 10,
    label: 'Oracle',
    isEnabled: false,
    applicationName: 'TCO',
    icon: OracleIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFA,
    popoverContent:
      'ipsum Lorem ipsum sit amet vel quasi neque et tempore.',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: false
  },
  {
    id: 11,
    label: 'Azure',
    isEnabled: false,
    applicationName: 'TCO',
    icon: AzureIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFA,
    popoverContent:
      'lorem ipsum Lorem ipsum dolor sit amet vel ',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: false
  },
  {
    id: 12,
    label: 'Multicloud',
    isEnabled: false,
    applicationName: 'TCO',
    icon: MulticloudIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFA,
    popoverContent:
      'Lorem ipsum dolor sit amet vel quasi neque et tempore.',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: false
  },
  {
    id: 13,
    label: 'OpenShift',
    isEnabled: false,
    applicationName: 'TCO',
    icon: OpenShiftIcon,
    reportCount: 5,
    cwbDrawerType: CWBDrawerType.CFA,
    popoverContent:
      'Lorem ipsum dolor vel quasi neque et tempore.',
    isMoreOptions: true,
    section: AssessJourneySection.ANALYSIS,
    isDiscoverCard: false
  },
];
export const capablityAnalyticsCategories = [
  'Telementry',
  'Logging',
  'Incident Response',
  'System Architecture',
  'Release Stratergies',
  'Self Service Portal',
  'Security',
];

export const dimensionAnalyticsDummyData = [
  {
    name: 'GSFICC',
    type: 'line',
    data: [1, 2, 3, 2, 1],
    pointPlacement: 'on',
  },
  {
    name: 'Leader',
    type: 'line',

    data: [3, 7, 2, 8, 1],
    pointPlacement: 'on',
  },
];
export const devopsHeatMapData = [
  { title: 'Plan', categories: ['Team Structure & Operating Model', 'Prioritization', 'Tracking'] },
  {
    title: 'Develop',
    categories: [
      'System Architecture',
      'Development & Build Practices',
      'Development Environments & Data ',
      'Security',
      'Version Control',
    ],
  },
  {
    title: 'Test',
    categories: ['Test Automation', 'Test Environments & Data'],
  },

  {
    title: 'Deploy',
    categories: [
      'Self-Service Portal ',
      'Automated Configuration & Deployment',
      'Release Strategies',
    ],
  },

  {
    title: 'Monitor',
    categories: ['Telemetry', 'Logging', 'Incident Response'],
  },
];
export const dimensionAnalyticsDummyCategories = ['Plan', 'Monitor', 'Develop', 'Deploy', 'Test'];

export enum IPrioritizationType {
  plan = 'Plan',
  develop = 'Develop',
  test = 'Test',
  deploy = 'Deploy',
  monitor = 'Monitor',
}

export const DiscoverAssessMenu: ITabMenu[] = [
  {
    menuName: AssessJourneySection.PRE_CLOUD,
    blackIcon: `${process.env.PUBLIC_URL}/config-icons/cloudenv_black.svg`,
    whiteIcon: `${process.env.PUBLIC_URL}/config-icons/cloudenv_white.svg`,
  },
  {
    menuName: AssessJourneySection.ANALYSIS,
    blackIcon: `${process.env.PUBLIC_URL}/config-icons/Architecture_black.svg`,
    whiteIcon: `${process.env.PUBLIC_URL}/config-icons/Architecture_white.svg`,
  },
  {
    menuName: AssessJourneySection.POST_CLOUD,
    blackIcon: `${process.env.PUBLIC_URL}/config-icons/Test_black.svg`,
    whiteIcon: `${process.env.PUBLIC_URL}/config-icons/Test_white.svg`,
  },
];
