import { put, takeEvery } from 'redux-saga/effects';
import {
  ICompliancePolicies,
  IIndustryCompliancePolicies,
} from '../../models/SecurityGovernanceModels';
import securityGovernanceService from '../../services/SecurityGovernanceService/SecurityGovernanceService';
import {
  SecurityGovernanceAction,
  SecurityGovernanceActions,
  SecurityGovernanceActionsTypes,
} from '../actions/SecurityGovernanceActions';

export function* getComplianceDetailsWorker(action: SecurityGovernanceAction) {
  try {
    const landingZoneID = action.data as string;
    const response: ICompliancePolicies = yield securityGovernanceService.getComplianceDetails(
      landingZoneID,
    );
    yield put(SecurityGovernanceActions.getComplianceDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SecurityGovernanceActions.getComplianceDetailsError(error));
    }
  }
}

export function* getIndustryComplianceDetailsWorker(action: SecurityGovernanceAction) {
  try {
    const landingZoneID = action.data as string;
    const response: IIndustryCompliancePolicies =
      yield securityGovernanceService.getIndustryComplianceDetails(landingZoneID);
    yield put(SecurityGovernanceActions.getIndustryComplianceDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SecurityGovernanceActions.getIndustryComplianceDetailsError(error));
    }
  }
}
export function* getComplianceSummaryWorker(action: SecurityGovernanceAction) {
  try {
    const projectId = action.data as string;
    const response: ICompliancePolicies = yield securityGovernanceService.getComplianceSummary(
      projectId,
    );
    yield put(SecurityGovernanceActions.getComplianceSummarySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(SecurityGovernanceActions.getComplianceSummaryError(error));
    }
  }
}

export function* securityGovernanceWatcher() {
  yield takeEvery(
    SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_STARTED,
    getComplianceDetailsWorker,
  );
  yield takeEvery(
    SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_STARTED,
    getComplianceSummaryWorker,
  );
  yield takeEvery(
    SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_STARTED,
    getIndustryComplianceDetailsWorker,
  );
}
