import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';

interface IDashboardHeaderProp {
  onSearch?: (searchText: React.ChangeEvent<HTMLInputElement>) => void;
}

const DashboardHeader = ({ onSearch }: IDashboardHeaderProp) => {
  return (
    <div className="d-flex justify-space-between header-ins-cards">
      <h2>Insights Dashbaord</h2>
      <div>
        <Input
          placeholder={'Search...'}
          //   onChange={onSearch}
          prefix={<SearchOutlined className="project-search-icon" />}
          className="search-style"
        />
        <Button type="text" className="create-btn-style">
          + Create
        </Button>
      </div>
    </div>
  );
};

export default DashboardHeader;
