import { put, takeEvery } from 'redux-saga/effects';
import { PersonaAction, PersonaActionTypes, PersonaActions } from '../actions/PersonaActions';
import { IDataAugmentationPayload, IDataAugmentationResponse, IStageResponse } from '../../models/PersonaModels';
import personaService from '../../services/Persona/PersonaService';

export function* getStageDetailsWorker(action: PersonaAction) {
  try {
    const submissionId = action.data as string;
    const response: IStageResponse[] = (yield personaService.getStageDetails(submissionId))!;
    
    yield put(PersonaActions.getStageDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(PersonaActions.getStageDetailsError(error));
    }
  }
}

export function* getAugmentationDataWorker(action: PersonaAction) {
  try {
    const {submissionID, eventID} = action.data as IDataAugmentationPayload;
    const response: IDataAugmentationResponse[] = (yield personaService.getAugmentationData(action.data as IDataAugmentationPayload))!;
    
    yield put(PersonaActions.getAugmentationDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(PersonaActions.getAugmentationDataError(error));
    }
  }
}

export function* personaWatcher() {
  yield takeEvery(PersonaActionTypes.DATA_INGESTION_STAGE_START, getStageDetailsWorker);
  yield takeEvery(PersonaActionTypes.DATA_AUGMENTATION_START, getAugmentationDataWorker);
}
