import AssetNeeded from '../../assets/icons/asset-need.svg';
export const DashboardLabels = {
  welcomeTitle: 'Hey Travis! Welcome to Tangra',
  welcomeSubtitle:
    'Start the setup process by Creating your First Project/ Account from the above selection dropdown.',
};

export enum DashboardCardClassNames {
  DISCOVER = 'Discover & Access',
  BUILD = 'build',
  MIGRATE = 'migrate',
  OPERATE = 'operate',
  SECURITY = 'security',
  SUMMARY = 'Cloud Summary',
}

export const AssetAuthorisationModalLabels = {
  title: 'Asset Authorization',
  icon: AssetNeeded,
  subtitle:
    'Authorizes and audits all data requests, enforcing data security and compliance policies. Accelerate agility, minimize data security risks and demonstrate regulatory compliance. Data Governance. Data Privacy',
  buttonTitle2: 'Do It Later',
  buttonTitle1: 'Authorize Now!',
};
