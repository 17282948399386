import {
  IGuardrailPayload,
  IGuardrailRespObj,
  IMetadataObj,
  IMetadataPayload,
} from '../../models/MetadataGuardrailModels';
import { IStoreAction } from '../StoreHelper';

export enum MetadataGuardrailsActionTypes {
  GET_METADATA_DETAILS_START = 'GET_METADATA_DETAILS_START',
  GET_METADATA_DETAILS_SUCCESS = 'GET_METADATA_DETAILS_SUCCESS',
  GET_METADATA_DETAILS_ERROR = 'GET_METADATA_DETAILS_ERROR',

  GET_METADATA_FORM_LIST_START = 'GET_METADATA_FORM_LIST_START',
  GET_METADATA_FORM_LIST_SUCCESS = 'GET_METADATA_FORM_LIST_SUCCESS',
  GET_METADATA_FORM_LIST_ERROR = 'GET_METADATA_FORM_LIST_ERROR',

  GET_GUARDRAILS_DETAILS_START = 'GET_GUARDRAILS_DETAILS_START',
  GET_GUARDRAILS_DETAILS_SUCCESS = 'GET_GUARDRAILS_DETAILS_SUCCESS',
  GET_GUARDRAILS_DETAILS_ERROR = 'GET_GUARDRAILS_DETAILS_ERROR',
}

export type MetadataGuardrailsPayload =
  | string
  | Error
  | null
  | IGuardrailPayload
  | IMetadataPayload
  | IMetadataObj[]
  | string[]
  | undefined
  | IGuardrailRespObj[];

export type MetadataGuardrailAction = IStoreAction<
  MetadataGuardrailsActionTypes,
  MetadataGuardrailsPayload
>;

export class MetadataGuardrailsActions {
  public static getMetadataDetailsStart(data: IMetadataPayload): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_START,
      data,
    };
  }
  public static getMetadataDetailsSuccess(
    data: IMetadataObj[] | undefined,
  ): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_SUCCESS,
      data,
    };
  }
  public static getMetadataDetailsError(error: Error): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_ERROR,
      data: error,
    };
  }

  public static getMetadataFormListStart(data: IGuardrailPayload): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_START,
      data,
    };
  }
  public static getMetadataFormListSuccess(
    data: string[] | undefined,
  ): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_SUCCESS,
      data,
    };
  }
  public static getMetadataFormListError(error: Error): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_ERROR,
      data: error,
    };
  }

  public static getGuardrailDetailsStart(data: IGuardrailPayload): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_START,
      data,
    };
  }
  public static getGuardrailDetailsSuccess(
    data: IGuardrailRespObj[] | undefined,
  ): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_SUCCESS,
      data,
    };
  }
  public static getGuardrailDetailsError(error: Error): MetadataGuardrailAction {
    return {
      type: MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_ERROR,
      data: error,
    };
  }
}
