/* eslint-disable max-lines */
export interface IFields {
  label: string;
  name: string;
  value: string;
  desc ?: string;
}
export interface IFormFields1 {
  [key: string]: IFields[];
}
export const formItems: IFormFields1 = {
  'Order_Form_1.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_1.pdf',
      desc : 'Unique Display name for Digital File'
    },
    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'ORDER FORM',
      desc : 'Nature/Category of contract'
    },
    {
      label: 'Effective Date',
      name: 'eff_date',
      value: '2021-01-02',
      desc: "Date on which a specific contract becomes legally valid and enforceable."
    },
    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: '2022-01-02',
      desc: "date on which contract comes to an end. After this date, the organization's rights to use the licensed software or services, as per the agreement, expire. It is a crucial milestone for tracking the duration of the licensing agreement"
    },
    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: '8500',
      desc: "Predetermined amount of credits or resources allocated to a service provider or entity participating in a Service Provider Program. These credits are typically used to access or utilize specific services, features, or resources offered by the program provider."

    },
    {
      label: 'Fee',
      name: 'fee',
      value: '85000.0',
      desc: "The deadline by which the partner must return a signed copy of the agreement or any associated documents."
    },
    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'AB Warehouse',
      desc: "Name of the organization that is the recipient of contract"
    },
    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: '1-507, Baker Street, London, UK',
      desc : "Physical location details of the customer associated with this contract"
    },
    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '04 May 2022',
      desc: "Records the date on which a customer is expected to sign and return a Contract"
    },
    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Inc',
      desc: "The name of the partner or organization with whom the VMware agreement is established"
    },
    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: '8596',
      desc: "A unique identifier assigned to the VMware partner agreement. This number helps track and reference the specific agreement."
    },
    {
      label: 'Partner Address',
      name: 'string',
      value: '3401 Hillview Avenue, Palo Alto, CA',
      desc: "The physical or mailing address of the partner organization, which may be used for communication and correspondence."
    },
    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'ABW8590',
      desc: "Unique identifier associated with a customer's contract"
    },
  ],
  'Order_Form_2.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_2.pdf',
    },
    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'ORDER FORM',
    },
    {
      label: 'Effective Date',
      name: 'eff_date',
      value: '2021-02-02',
    },
    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: '2022-02-02',
    },
    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: '8760',
    },
    {
      label: 'Fee',
      name: 'fee',
      value: '88000.0',
    },
    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'DB Warehouse',
    },
    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: '1510 Parker Street Rome UK',
    },
    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '04 Apr 2022',
    },
    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Inc',
    },
    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: '8597',
    },
    {
      label: 'Partner Address',
      name: 'string',
      value: '4500 Park Avenue Palo CA',
    },
    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'DBW8591',
    },
  ],
  'Order_Form_3.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_3.pdf',
    },
    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'ORDER FORM',
    },
    {
      label: 'Effective Date',
      name: 'eff_date',
      value: '2022-02-02',
    },
    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: '2023-02-02',
    },
    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: '9760',
    },
    {
      label: 'Fee',
      name: 'fee',
      value: '28000.0',
    },
    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'KB Warehouse',
    },
    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: '1-7869, Valvo Street, Italy, UK',
    },
    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '01 Apr 2023',
    },
    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Inc',
    },
    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: '8598',
    },
    {
      label: 'Partner Address',
      name: 'string',
      value: '6579 Mall Avenue, Palo, CA',
    },
    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'KBW8170',
    },
  ],
  'Order_Form_4.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_4.pdf',
    },
    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'ORDER FORM',
    },
    {
      label: 'Effective Date',
      name: 'eff_date',
      value: '2022-01-02',
    },
    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: '2023-01-02',
    },
    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: '1720',
    },
    {
      label: 'Fee',
      name: 'fee',
      value: '44000.0',
    },
    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'MB Warehouse',
    },
    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: '1-5678, Keba Street, Rome, UK',
    },
    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '01 May 2023',
    },
    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Inc',
    },
    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: '8571',
    },
    {
      label: 'Partner Address',
      name: 'string',
      value: '8901 Mall Avenue, Palo, CA',
    },
    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'MBW90170',
    },
  ],
  'Order_Form_5.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_5.pdf',
    },
    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'ORDER FORM',
    },
    {
      label: 'Effective Date',
      name: 'eff_date',
      value: '2019-01-02',
    },
    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: '2020-01-02',
    },
    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: '1000',
    },
    {
      label: 'Fee',
      name: 'fee',
      value: '32000.0',
    },
    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'LB Warehouse',
    },
    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: '1-008, Melbo Street, London, UK',
    },
    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '01 May 2020',
    },
    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Inc',
    },
    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: '10034',
    },
    {
      label: 'Partner Address',
      name: 'string',
      value: '5689 Kill Avenue, Palo, CA',
    },
    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'LBW90166',
    },
  ],
  'Order_Form_6.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_6.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'AMENDMENT NO. 5 TO THE ELA ORDER FORM',
    },

    {
      label: 'Amendment Effective Date',
      name: 'eff_date',
      value: '2019-04-11',
    },

    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: 'null',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fees',
      name: 'renewal_fee',
      value: '220428.470',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Great Western Bank',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: 'W. 41st Street, Sioux Falls, SD 57105, United States',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '08 Apr 2019',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'null',
    },

    {
      label: 'Agreement Number',
      name: 'agreement_number',
      value: 'null',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value: 'null',
    },

    {
      label: 'Entitlement Account',
      name: 'string',
      value: '114321965',
    },
  ],
  'Order_Form_7.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_7.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'SINGLE ELA RESALE AGREEMENT',
    },

    {
      label: 'SRA Effective Date',
      name: 'eff_date',
      value: 'null',
    },

    {
      label: 'ELA Period Expiration Date',
      name: 'ela_exp_date',
      value: '2022-04-31',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fee',
      name: 'renewal_fee',
      value: '24471.21',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Meghna Group of Industries',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: 'H/No- 15, Road- 34, Gulshan-1, Dhaka, Other 1212, BANGLADESH',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: 'null',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMWARE SOLUTION PROVIDER PARTNER PROGRAM AGREEMENT',
    },

    {
      label: 'Partner Agreement Number',
      name: 'agreement_number',
      value: 'null',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value:
        'Unique Trade Center, Level 17 (South), 8 Panthapath, Kawran Bazar C/A, Dhaka - 1215, Dhaka, Other 1215, BANGLADESH',
    },

    {
      label: 'Entitlement Account',
      name: 'string',
      value: '322370859',
    },
  ],
  'Order_Form_8.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_8.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'SINGLE ENTERPRISE ORDER RESALE AGREEMENT',
    },

    {
      label: 'SRA Effective Date',
      name: 'eff_date',
      value: '2023-01-11',
    },

    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: 'null',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fee',
      name: 'renewal_fee',
      value: '30626.64',
    },
    {
      label: 'Fees',
      name: 'enterprise_order_fees',
      value: '240450.06',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Servicios Compartidos Ticel',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: 'Avda. Apoquindo Nº 3669, Oficina 601 Santiago, x 7550190 CHILE',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '2/3/2023',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Authorized Distributor Partner Program Agreement',
    },

    {
      label: 'Partner Agreement Number',
      name: 'agreement_number',
      value: '00327798.0',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value: '11 de Septiembre 1860 of 183, Santiago, Other, 7500000, CHILE',
    },

    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'null',
    },
  ],
  'Order_Form_9.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_9.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'AMENDMENT NO. 1 TO SINGLE ELA RESALE AGREEMENT',
    },

    {
      label: 'Effective Date',
      name: 'eff_date',
      value: 'null',
    },

    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: 'null',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fee',
      name: 'renewal_fee',
      value: 'null',
    },

    {
      label: 'Additional Fee',
      name: 'additional_fee',
      value: '137900.16l',
    },

    {
      label: 'Fees',
      name: 'enterprise_order_fees',
      value: 'null',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Credit Mutuel Arkea',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: 'null',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: 'null',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'ECS SAS',
    },

    {
      label: 'Partner Agreement Number',
      name: 'agreement_number',
      value: 'null',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value: 'As set forth in Distributor Exhibit',
    },

    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'null',
    },
  ],
  'Order_Form_10.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_10.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'SINGLE ENTERPRISE ORDER RESALE AGREEMENT',
    },

    {
      label: 'SRA Effective Date',
      name: 'eff_date',
      value: '2023-01-27',
    },

    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: 'null',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fee',
      name: 'renewal_fee',
      value: 'null',
    },
    {
      label: 'Fees',
      name: 'enterprise_order_fees',
      value: '807814.56',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Meli Log Srl',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value:
        'Tronador 4890 Piso 6 Ciudad Autonoma de Buenos Aires, CIUDAD DE BUENOS AIRES C1430DNN ARGENTINA',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: '2/3/2023',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'VMware Authorized Distributor Partner Program Agreement',
    },

    {
      label: 'Partner Agreement Number',
      name: 'agreement_number',
      value: '00274954',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value: 'N/A, Lake, Other, NA, ARGENTINA',
    },

    {
      label: 'Customer Entitlement Account',
      name: 'string',
      value: '304166333',
    },
  ],
  'Order_Form_11.pdf': [
    {
      label: 'File Name',
      name: 'file_name',
      value: 'Order_Form_11.pdf',
    },

    {
      label: 'Contract Type',
      name: 'contract_type',
      value: 'AMENDMENT NO. 1 TO SINGLE ELA RESALE AGREEMENT',
    },

    {
      label: 'Effective Date',
      name: 'eff_date',
      value: 'null',
    },

    {
      label: 'Expiration Date',
      name: 'exp_date',
      value: 'null',
    },

    {
      label: 'SPP Credits Allowance',
      name: 'spp_cred',
      value: 'null',
    },

    {
      label: 'Renewal Fee',
      name: 'renewal_fee',
      value: '426382.94',
    },
    {
      label: 'Additional Fee',
      name: 'additional_fee',
      value: '228752.33',
    },

    {
      label: 'Customer Name',
      name: 'cust_name',
      value: 'Ubi Sistemi E Servizi Scpa',
    },

    {
      label: 'Customer Address',
      name: 'cust_addr',
      value: 'null',
    },

    {
      label: 'Customer Signature Return Date',
      name: 'cust_sign',
      value: 'null',
    },

    {
      label: 'Partner Name',
      name: 'partner_name',
      value: 'Dell Products',
    },

    {
      label: 'Partner Agreement Number',
      name: 'agreement_number',
      value: 'null4',
    },

    {
      label: 'Partner Address',
      name: 'string',
      value: 'As per Authorized Distributor Partner Program Agreement, as amended',
    },

    {
      label: 'Entitlement Account',
      name: 'string',
      value: 'null',
    },
  ],
  'Order_Form_12.pdf': [
    {
      label: 'FileName',
      name: 'file_name',
      value: 'Order_Form_12.pdf',
    },

    {
      label: 'ContractType',
      name: 'contract_type',
      value: 'ORDER FORM',
    },

    {
      label: 'EffectiveDate',
      name: 'eff_date',
      value: 'the last indicated date of execution below',
    },

    {
      label: 'SPP_ExpirationDate',
      name: 'spp_exp_date',
      value: '1 years following the Effective Date',
    },

    {
      label: 'SPPCreditsAllowance',
      name: 'spp_cred',
      value: '2140',
    },

    {
      label: 'Fees',
      name: 'fees',
      value: '207580.00',
    },

    {
      label: 'CustomerName',
      name: 'cust_name',
      value: 'ILM Corporation',
    },

    {
      label: 'CustomerAddress',
      name: 'cust_addr',
      value: '800 Corporate DrSte301,Stafford,VA22554,UNITED STATES',
    },

    {
      label: 'CustomerSignatureReturnDate',
      name: 'cust_sign',
      value: '3/5/2019',
    },

    {
      label: 'PartnerName',
      name: 'partner_name',
      value: 'null',
    },

    {
      label: 'PartnerAgreementNumber',
      name: 'agreement_number',
      value: 'null',
    },

    {
      label: 'PartnerAddress',
      name: 'string',
      value: 'null',
    },

    {
      label: 'EntitlementAccount',
      name: 'string',
      value: '691892044',
    },
  ],
  'Order_Form_13.pdf': [
    {

      label:'FileName',
      name:'file_name',
      value:'Order_Form_13.pdf',
      
      },
      
      {
      
      label:'ContractType',
      name:'contract_type',
      value:'SINGLE ELA RESALE AGREEMENT',
      
      },
      
      {
      
      label:'SRAEffectiveDate',
      name:'sra_eff_date',
      value:'2019-04-11',
      
      },
      
      {
      
      label:'ELAPeriodExpirationDate',
      name:'ela_exp_date',
      value:'3 years following the Effective Date',
      
      },
      
      {
      
      label:'SPPCreditsAllowance',
      name:'spp_cred',
      value:'null',
      
      },
      
      {
      
      label:'RenewalFee',
      name:'renewal_fee',
      value:'10871.06',
      
      },
      
      {
      
      label:'CustomerName',
      name:'cust_name',
      value:'Roswell Park Cancer Institute',
      
      },
      
      {
      
      label:'CustomerAddress',
      name:'cust_addr',
      value:'Elmand CarltonSt,Buffalo,NY14263,UNITED STATES',
      
      },
      
      {
      
      label:'CustomerSignatureReturnDate',
      name:'cust_sign',
      value:'null',
      
      },
      
      {
      
      label:'PartnerName',
      name:'partner_name',
      value:'Distributor Agreement',
      
      },
      
      {
      
      label:'PartnerAgreementNumber',
      name:'agreement_number',
      value:'00027509.0',
      
      },
      
      {
      
      label:'PartnerAddress',
      name:'string',
      value:'1759 WehrleDrive,Williamsville,NY14221,UNITED STATES',
      
      },
      
      {
      
      label:'EntitlementAccount',
      name:'string',
      value:'111222379',
      
      },
  ],
  'Order_Form_14.pdf': [
    {

      label:'FileName',
      name:'file_name',
      value:'Order_Form_14.pdf',
      
      },
      
      {
      
      label:'ContractType',
      name:'contract_type',
      value:'ELA ORDER FORM',
      
      },
      
      {
      
      label:'EffectiveDate',
      name:'eff_date',
      value:'2019-04-12',
      
      },
      
      {
      
      label:'ELAExpirationDate',
      name:'ela_exp_date',
      value:'2020-05-01',
      
      },
      
      {
      
      label:'SPPCreditsAllowance',
      name:'spp_cred',
      value:'null',
      
      },
      
      {
      
      label:'RenewalFee',
      name:'renewal_fee',
      value:'89483.24',
      
      },
      
      {
      
      label:'CustomerName',
      name:'cust_name',
      value:'Kindred Healthcare',
      
      },
      
      {
      
      label:'CustomerAddress',
      name:'cust_addr',
      value:'2201 UticaPike,Jeffersonville,FL47130,UNITED STATES',
      
      },
      
      {
      
      label:'CustomerSignatureReturnDate',
      name:'cust_sign',
      value:'3/5/2019',
      
      },
      
      {
      
      label:'PartnerName',
      name:'partner_name',
      value:'null',
      
      },
      
      {
      
      label:'PartnerAgreementNumber',
      name:'agreement_number',
      value:'null',
      
      },
      
      {
      
      label:'PartnerAddress',
      name:'string',
      value:'null',
      
      },
      
      {
      
      label:'EntitlementAccount',
      name:'string',
      value:'116542217',
      
      },
  ],
  'Order_Form_15.pdf': [
    {

      label:'FileName',
      name:'file_name',
      value:'Order_Form_15.pdf',
      
      },
      
      {
      
      label:'ContractType',
      name:'contract_type',
      value:'SINGLE ENTERPRISE ORDER RESALE AGREEMENTOEM',
      
      },
      
      {
      
      label:'SRAEffectiveDate',
      name:'eff_date',
      value:'2023-03-29',
      
      },
      
      {
      
      label:'ExpirationDate',
      name:'exp_date',
      value:'null',
      
      },
      
      {
      
      label:'SPPCreditsAllowance',
      name:'spp_cred',
      value:'null',
      
      },
      {
      
      label:'Fees',
      name:'enterprise_order_fees',
      value:'92488.5',
      
      },
      
      {
      
      label:'CustomerName',
      name:'cust_name',
      value:'Hospital AlemanAsoc.Civil',
      
      },
      
      {
      
      label:'CustomerAddress',
      name:'cust_addr',
      value:'AvPueyrredon 1640 Autonomade BsAsautonomade Bs.As.BuenosAires,CIUDAD DE BUENOS AIRES 1118 ARGENTINA',
      
      },
      
      {
      
      label:'CustomerSignatureReturnDate',
      name:'cust_sign',
      value:'5/5/2023',
      
      },
      
      {
      
      label:'PartnerName',
      name:'partner_name',
      value:'VMwareOEMAgreement',
      
      },
      
      {
      
      label:'PartnerAgreementNumber',
      name:'agreement_number',
      value:'null',
      
      },
      
      {
      
      label:'PartnerAddress',
      name:'string',
      value:'No.28,Zhejiang Road East,Tianhe District Unit01-08,11F,Yuexiu Financial Tower,Guangzhou,Jiangsu,510000,CHINA',
      
      },
      
      {
      
      label:'CustomerEntitlementAccount',
      name:'string',
      value:'115302196',
      
      },
  ],
};
