import { IDeliveryPipelineState, IPipelineHistory } from '../../models/PipelineModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class GetPipelinesService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getPipelines(
    landingZoneId: string,
  ): Promise<IDeliveryPipelineState[]> | IDeliveryPipelineState[] {
    return this.service.get({ route: [`${API_ROUTE.GET_PIPELINES}/${landingZoneId}`] });
  }

  public getPipelineInstances(
    pipelineId: string,
  ): Promise<IPipelineHistory[]> | IPipelineHistory[] {
    return this.service.get({ route: [`${pipelineId}/${API_ROUTE.GET_PIPELINE_INSTANCES}`] });
  }
}

const getPipelinesService = new GetPipelinesService();
export default getPipelinesService;
