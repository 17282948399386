export const SubHubInsuranceDashboard = {};

export const SubHubDashboardLabels = {
    title: 'Business Analytics',
};

export interface ICardsArrayProps{
  caseId: string,
  caseName: string,
  brokerName: string,
  totalQuote: number,
  resolvedQuote: number,
}

export const CardsArray: ICardsArrayProps[] = [
  {
    caseId: '01212276',
    caseName: 'Dealer-insurance',
    brokerName: 'Arnav Nash',
    resolvedQuote:2,
    totalQuote:4,
  },
  {
    caseId: '08245672',
    caseName: 'Renewal-insurance',
    brokerName: 'John Doe',
    resolvedQuote:2,
    totalQuote:6,
  },
  {
    caseId: '01245345',
    caseName: 'Auto-insurance',
    brokerName: 'Niel John',
    resolvedQuote:7,
    totalQuote:8,
  },
  {
    caseId: '01235465',
    caseName: 'Auto-insurance',
    brokerName: 'Radhika',
    resolvedQuote:1,
    totalQuote:5,
  },
  {
    caseId: '05371234',
    caseName: 'Motor-insurance',
    brokerName: 'Neo Frank',
    resolvedQuote:15,
    totalQuote:23,
  },
  
];
