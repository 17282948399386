import { put, takeEvery } from 'redux-saga/effects';
import { SideBarKey } from '../../../containers/Sidebar/SidebarConstants';
import {
  AssetType,
  IAccountDetailsResponse,
  IAssessmentStateResponse,
  IAuditHistoryPayload,
  IAuditHistoryResponse,
  IProjectOwnersPayload,
  IProjectOwnersResponse,
} from '../../models/DashboardModels';
import DashboardService from '../../services/DashboardService/DashboardService';
import {
  DashboardAction,
  DashboardActions,
  DashboardActionTypes,
} from '../actions/DashboardActions';
import { OverviewActions } from '../actions/OverviewActions';
import { SidebarActions } from '../actions/SidebarActions';

export function* getAllAccountsWorker(action: DashboardAction) {
  try {
    // API call to get all accounts
    const response: IAccountDetailsResponse[] = yield DashboardService.getUserProjects();
    yield put(DashboardActions.getAllAccountsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DashboardActions.getAllAccountsError(error));
    }
  }
}

export function* getAuditHistoryWorker(action: DashboardAction) {
  try {
    const response: IAuditHistoryResponse = yield DashboardService.getAuditDetails();

    if (response && response.auditHistory && response.auditHistory.dashboard) {
      const {
        auditHistory: {
          dashboard: { account, project, enabledDiscoverAssessItems },
        },
      } = response;

      if (account)
        yield put(
          OverviewActions.selectAccount({
            accountId: account.id!,
            accountName: account.name!,
            assetType: account.assetType,
          }),
        );
      if (project)
        yield put(
          OverviewActions.selectProject({ projectId: project.id!, projectName: project.name! }),
        );
      if (enabledDiscoverAssessItems)
        yield put(
          DashboardActions.setDiscoverAssessList(
            enabledDiscoverAssessItems.map((item) => parseInt(item.toString())) || [],
          ),
        );
      if (!account && !project) {
        yield put(SidebarActions.setSidebarKey(SideBarKey.DASHBOAARD));
      }
    }

    yield put(DashboardActions.getAuditHistorySuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(DashboardActions.getAuditHistoryError(error));
    }
  }
}

export function* getProjectOwnersWorker(action: DashboardAction) {
  try {
    let response: IProjectOwnersResponse[];
    const { assetType } = action.data as IProjectOwnersPayload;
    if (assetType === AssetType.TANGRA) {
      response = yield DashboardService.getProjectOwners(action.data as IProjectOwnersPayload);
    } else {
      response = yield DashboardService.getProjectOwnersCWB(action.data as IProjectOwnersPayload);
    }
    yield put(DashboardActions.getProjectOwnersSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DashboardActions.getProjectOwnersError(error));
    }
  }
}

export function* saveAuditHistoryWorker(action: DashboardAction) {
  try {
    const { data } = action;

    yield DashboardService.saveAuditDetails(data as IAuditHistoryPayload);
    yield put(DashboardActions.saveAuditHistorySuccess());
    yield put(SidebarActions.toggleLogout(true));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DashboardActions.saveAuditHistoryError(error));
    }
  }
}

export function* getAssessmentStateWorker(action: DashboardAction) {
  try {
    const { data } = action;
    const response: IAssessmentStateResponse = yield DashboardService.getAssessmentsDetails(
      data as string,
    );
    yield put(DashboardActions.getAssessmentDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DashboardActions.getAssessmentDataError(error));
    }
  }
}

export function* dashboardWatcher() {
  yield takeEvery(DashboardActionTypes.GET_ALL_ACCOUNTS_START, getAllAccountsWorker);
  yield takeEvery(DashboardActionTypes.GET_AUDIT_HISTORY_START, getAuditHistoryWorker);
  yield takeEvery(DashboardActionTypes.SAVE_AUDIT_HISTORY_START, saveAuditHistoryWorker);
  yield takeEvery(DashboardActionTypes.GET_PROJECT_OWNERS_START, getProjectOwnersWorker);
  yield takeEvery(DashboardActionTypes.GET_ASSESSMENTS_STATE_START, getAssessmentStateWorker);
}
