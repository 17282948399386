import { Avatar, Select } from 'antd';
import React, { useState } from 'react';
import './SubHubHeader.less';
import DownIcon from '../../../assets/icons/DownArrowWhite.svg';
import SidebarOptionsModal from '../../Sidebar/SidebarOptionsModal';
import { storage } from '../../../libs/services/LocalStorage';
const SubHubHeader = () => {
  const [isAdminPopoverOpen, setIsAdminPopoverOpen] = useState<boolean>(false);
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const userDetails = localStorage?.userDetails && JSON.parse(localStorage?.userDetails);

  const logout = () => {
    storage.clearAll();
    window.location.reload();
  };
  return (
    <div className="subhub-header-container">
      {/* <Select
        className="subhub-header-project-select"
        defaultValue="Commercial  Auto - Middle Market"
        style={{ width: 120 }}
        onChange={handleChange}
        options={[
          { value: 'Commercial  Auto - Middle Market', label: 'Commercial  Auto - Middle Market' },
          {
            value: 'Commercial  Auto - Middle Market 2',
            label: 'Commercial  Auto - Middle Market 2',
          },
          {
            value: 'Commercial  Auto - Middle Market 3',
            label: 'Commercial  Auto - Middle Market 3',
          },
        ]}
      /> */}
      <div className="profile-avatar-container">
        <div className="profile-details-container">
          <div className="profile-details-name">
            {userDetails?.given_name} {userDetails?.family_name}
          </div>
        </div>
        <div className="avatar-icon-container">
          <Avatar
            className="avatar-icon"
            // src={`${process.env.PUBLIC_URL}/config-icons/shi-avatar-icon.svg`}
          >
            {(userDetails?.given_name || 'Y').charAt(0)}
          </Avatar>
        </div>
        <img
          src={DownIcon}
          className="dropdown-aero ml-1 filter-inv cursor-pointer"
          alt="Dropdown Icon"
          onClick={() => setIsAdminPopoverOpen(!isAdminPopoverOpen)}
        />
      </div>
      {isAdminPopoverOpen && (
        <SidebarOptionsModal
          onLogout={logout}
          isAdminPopoverOpen={isAdminPopoverOpen}
          setIsAdminPopoverOpen={(value) => setIsAdminPopoverOpen(value)}
        />
      )}
    </div>
  );
};

export default SubHubHeader;
