import { put, takeEvery } from 'redux-saga/effects';
import { IEmailNotification, ILandingZoneNotification } from '../../models/NotificationModels';
import notificationService from '../../services/NotificationService/NotificationService';
import { NotificationActions, NotificationActionTypes } from '../actions/NotificationActions';

export function* getLandingZoneNotificationWorker() {
  try {
    const response: ILandingZoneNotification[] =
      (yield notificationService.getLandingZoneNotifications())!;
    yield put(NotificationActions.getLandingZoneNotificationSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(NotificationActions.getLandingZoneNotificationError(error));
    }
  }
}

export function* getEmailNotificationWorker() {
  try {
    const response: IEmailNotification[] = (yield notificationService.getEmailNotifications())!;
    yield put(NotificationActions.getEmailNotificationSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(NotificationActions.getEmailNotificationError(error));
    }
  }
}

export function* notificationWatcher() {
  yield takeEvery(
    NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_STARTED,
    getLandingZoneNotificationWorker,
  );
  yield takeEvery(
    NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_STARTED,
    getEmailNotificationWorker,
  );
}
