import React, { FC, useState } from 'react';
import { termsandConditionsUrl } from '../../AppConstants';
import { FooterLabels } from './LoginConstants';
import './Login.less';

const Footer = () => {
  const openTermsUse = () => {
    window.open(termsandConditionsUrl, '_blank');
  };

  return (
    <div className="footer-div">
      {FooterLabels.year}
      <span className="footer-div-text" onClick={() => openTermsUse()}>
        {FooterLabels.terms}
      </span>
      {FooterLabels.forMore}
      <span className="footer-div-text" onClick={() => openTermsUse()}>
        {FooterLabels.privacy}
      </span>
    </div>
  );
};

export default Footer;
