import React, { ReactNode } from 'react';

interface ISideNavItem {
  title: string;
  icon: string;
  window?: ReactNode;
}
interface ISideNavProps {
  navItems: ISideNavItem[];
  activeItem: string;
  setActiveItem: (key: string) => void;
}
const SideNav = ({ navItems, activeItem, setActiveItem }: ISideNavProps) => {
  return (
    <div className="navbar-section-container">
      <div className="connections"></div>
      <div className="navbar-section">
        {navItems.map((item) => (
          <div key={item.title} className="lm">
            <div
              className={`nav-item-container ${
                item.title === activeItem ? 'nav-item-container-selected' : ''
              }`}
              onClick={() => setActiveItem(item.title)}
            >
              <img
                src={item.icon}
                className="mr-1"
                alt="icon"
              />
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideNav;
