export const API_ROUTE = {
  LOGIN: 'users/login',
  TANGRA_LOGIN: 'login',
  TANGRA_REFRESH_TOKEN: 'refresh-token',
  CWB_REFRESH_TOKEN: 'cwb-refresh-token',
  CWB_SSO_LOGIN: 'cwb/login',
  GET_USER_PERMISSIONS: 'user/permission',
  CREATE_LANDING_ZONE: 'createLandingZone',
  LIST_LANDING_ZONES: 'landing-zone', //  'getLandingZones', //
  DELETE: 'destroyLandingZone',
  CWB: {
    GET_CHART_DATA: 'charts/bauvscloudfiveyrcosts',
    GET_FIVE_YR_PL_DATA: 'exportchartdata',
    GET_COMPUTE_COST_DATA: 'charts/computecost',
    GET_STORAGE_COST_DATA: 'charts/storagecost',
    GET_HEATMAP_DATA: 'charts/heatmap',
    GET_ASSESSMENT_DATA: 'configurecomponents',
    GET_CATEGORIES_DATA: 'assessmentdetailspopup',
    APP_SCOPE_ADJUSTMENT_DATA: 'charts/scopeplacement',
    APP_INSCOPE_DATA: 'charts/inoutscope',
    APP_ADJUSTMENT_COUNT: 'charts/breakdowncount',
  },
  WORKFLOW_START: 'landingzone/workflow/start',
  WORKFLOW_CONSOLE_START: 'https://vsrm.dev.azure.com/CE-DevOpsCloud/DCP/_apis/release/releases?api-version=6.0',
  WORKFLOW_STATUS: 'get-workflow-details',
  CLIENT: 'dashboard/clients/',
  LANDING_ZONE_STATUS: 'https://tangradcaapi.deloitte.com/listen/',
  RULESET_HISTORY: 'getapirules',
  PROJECT: 'project',
  CREATE_PIPELINE: 'pipeline',
  GET_PIPELINES: 'pipeline',
  UPDATE_PIPELINE: 'pipeline',
  GET_WORKFLOW_DETAILS: 'get-workflow-details',
  GET_PIPELINE_INSTANCES: 'instance',
  RUN_PIPELINE: 'pipeline/run',
  GET_LANDING_ZONES_RULESETS: 'landing-zone/ruleset',
  GET_JOB_LANDING_ZONES: 'getLandingZone',
  EDIT_RULESETS_PARAMS: 'ruleset',
  RUN_RULESET: 'ruleset/run',
  ADD_ACCOUNT: 'account/project',
  ADD_ONLY_ACCOUNT: 'account',
  ADD_PROJECT: 'project',
  UPDATE_PIPELINE_STATUS: 'pipeline',
  AUDIT_HISTORY: 'audit',
  UPLOAD_CREATE_RULE_SET_YAML: 'landingzone/ruleset',
  CREATE_MICROSERVICES: 'createMicroservices',
  GET_PRESIGNED_URL: 'presigned-url',
  DESTROY_WORKLOAD_PATTERN: 'destroyMicroservice',
  LANDING_ZONE_ERROR: 'landingZoneStatus', //Need to be changed from backend
  CREATE_USER: 'admin/user',
  GET_USER_MANAGEMENT_DETAILS: 'users',
  DASHBOARD: 'dashboard',
  landingZoneNotification: 'notification',
  notificationStatus: 'notification-status',
  alertNotification: 'alert',
  createLandingZone: 'landing-zone/yaml',
  GET_MOST_VISITED_DATA: 'page',
  GET_MOST_VISITED_ARCH_DATA: 'architecture',
  POST_VISITED_DATA: 'page',
  GET_CLOUD_ANALYTICS_DATA: 'cloud',
  GET_CLOUD_ASSESSMENT_DATA: 'cloud-assessment',
  NEW_VS_RETURNING_USERS: 'new-vs-returning-users',
  GET_ROLE_BASED_USAGE: 'admin-vs-users',
  GET_CLOUD_STATUS_DATA: 'cloud-status',
  GET_ACTIVE_USERS_DATA: 'active-users',
  GET_USERS_VISITS_DATA: 'visits',
  GET_PAGE_VIEWS_DATA: 'page-views',
  AVG_TIME_SESSION_DATA: 'average-session-time',
  GET_SESSION_TOKEN_DATA: 'user-activity',
  ANALYTICS_CLOUD_CSP: '/analytics/cloud-csp',
  ANALYTICS_CLOUD_STATUS: '/analytics/cloud-status',
  ANALYTICS_PIPELINE: '/analytics/pipeline',
  ANALYTICS_PIPELINE_CUMULATIVE: '/analytics/pipeline-cumulative',
  GET_S3_FILES: 's3-files ',
  QUESTIONNAIRE: 'questionnaire',
  FEED_BACK: {
    GET_FEEDBACK: 'feedback',
    POST_FEEDBACK: 'feedback',
  },
  SUMMARY: {
    GET_COMPLIANCE_SUMMARY_DETAILS: 'landing-zone/compliance-cumulative',
  },
  GET_COMPLIANCE_DETAILS: 'landing-zone/compliance',
  YAML_CONFIGURATIONS: 'yamlConfigurations',
  IMAGE_UPLOAD: '/image-upload',
  DEV_OPS_ANALYTICS: {
    GET_CAPABLILTIY_DATA: 'assessment',
    GET_GOVERNANCE_DATA: 'governance',
    GET_ASSESSMENT_UPLOAD_DATA: 'assessment-upload',
  },
  GET_QUESTIONNAIRE: {
    GET_CHART_DATA: 'questionnaire/chart',
  },
  yamlContent: '/yaml-content',
  GET_ASSESSMENTS_STATE: 'assessment-cards',
  GET_LANDING_ZONE_KEYS: 'landing-zone/keys',
  INDUSTRY_COMPLIANCE_DETAILS: 'industry-landing-zone/compliance',
  WORKFLOW: 'workflow',
  LANDINGZONE_WORKFLOW_LOGS: 'landingzone/workflow',
  GET_COFTA_URL: 'app-stream',
  DEPLOY_INSTANCE: 'instance',
  multiCloudYaml: 'multi-cloud-landing-zone/yaml',
  CREATE_MULTI_CLOUD: 'createMultiCloud',
  INDUSTRY_LZ_YAML: 'industry-landing-zone/yaml',
  SERVICES: 'services',
  POST_CLIENT_THEME : 'client-customization',
  GET_STAGE_DETAILS:'subhub/audit/',
  GET_AUGMENTATION_DATA:'subhub/submissions/',
  SEND_QUERY: 'analysis_query',
  GET_PDF_URL: 'get-contract-url',
  POST_SEARCH_CONTRACTS: 'analysis_query',
  GET_CONTRACT_LIST: 'list-contracts',
  GET_CONTRACT_DETAILS: 'get-contract-details',
  UPLOAD_CONTRACT: 'upload',
  GET_METADATA_GUARDRAIL: 'list'
};
