import { ICWBLoginResponse } from '../../models/CWBLoginModels';
import {
  ILoginPayload,
  ILoginResponse,
  ITangraLoginPayload,
  ITangraLoginResponse,
} from '../../models/LoginModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { storage, STORAGE_CONSTANTS } from '../LocalStorage';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.CWB;
const tangraServiceType = ServiceType.None;
const sessionServiceType = ServiceType.ANALYTICS;

export class LoginService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.cwb);

  private readonly tangraService: ApiServiceBase = new ApiService(
    tangraServiceType,
    ServerType.tangra,
  );

  private readonly sessionService: ApiServiceBase = new ApiService(
    sessionServiceType,
    ServerType.tangra,
  );

  public postLogin(
    payload: ILoginPayload,
  ): Promise<ILoginResponse | ICWBLoginResponse> | ILoginResponse | ICWBLoginResponse {
    return this.service.post({ route: [API_ROUTE.LOGIN] }, payload);
  }

  public postTangraLogin(
    payload: ITangraLoginPayload,
  ): Promise<ITangraLoginResponse> | ITangraLoginResponse {
    return this.tangraService.post({ route: [API_ROUTE.TANGRA_LOGIN] }, payload);
  }

  public postCwbSsoLogin(
    payload: ITangraLoginPayload,
  ): Promise<ITangraLoginResponse> | ITangraLoginResponse {
    return this.tangraService.post({ route: [API_ROUTE.CWB_SSO_LOGIN] }, payload);
  }

  public getSessionToken(): Promise<string> | string {
    return this.sessionService.post({ route: [API_ROUTE.GET_SESSION_TOKEN_DATA] }, {});
  }

  public postSessionToken(): Promise<string> | string {
    const sessionToken = storage.getItem(STORAGE_CONSTANTS.sessionToken);
    return this.sessionService.patch(
      { route: [API_ROUTE.GET_SESSION_TOKEN_DATA] },
      { Id: sessionToken },
    );
  }
}

const loginService = new LoginService();
export default loginService;
