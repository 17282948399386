export const DashboardHeaderConstants = {
  cloudSummary: 'Foundation Summary',
  discoverAccess: 'Foundation Assessment',
  buildDeploy: 'Build Foundation',
  migrateModernize: 'Foundation Readiness',
  operate: 'Site reliability Engineering',
  securityGovernance: 'Security & Governance',
  buildDeployTest: 'Build, Deploy & Test',
  dataAnalytics: 'Data Analytics',
  insights:'Insights',
  businessCases: 'Business Cases',
  reports: 'Reports',
  moduleNo1: 'Module No 1',
  settings: 'settings',
  underwriter: 'Underwriter',
  businessIntelligence:'Business Intelligence',
  chiefUnderwriter:'Chief Underwriter',
  
};
