import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { congnitoConfiguration } from '../../configs/AWS';
import { getEnv } from '../../libs/helper/HelperFunctions';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { DashboardActions } from '../../libs/store/actions/DashboardActions';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import Loader from '../Loader/Loader';
import {
  AuthenticationPageLabels as LABELS,
  AUTH_CODE_PARAM_KEY,
} from './AuthenticationPageConstants';
import './AuthenticationPageStyles.less';

const AuthenticationPage = () => {
  const dispatch = useDispatch();

  const dispatcher = {
    getAuditHistory: () => dispatch(DashboardActions.getAuditHistoryStart()),
    toggleLogout: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
  };

  const getAuditHistory = () => dispatcher.getAuditHistory();

  const history = useHistory();

  const { loginDetails, loginError } = useAppSelector((state: IApplicationState) => ({
    loginDetails: state.login.tangraLoginDetails,
    loginError: state.login.tangraLoginError,
  }));

  const getAuthCode = () => {
    const searchUrl = window.location.search;
    const urlParams = new URLSearchParams(searchUrl);
    const authCode = urlParams.get(AUTH_CODE_PARAM_KEY);
    return authCode;
  };

  useEffect(() => {
    const authCode = getAuthCode();
    const environment = getEnv();
    const COGNITO = congnitoConfiguration(environment);
    if (authCode) {
      dispatch(
        LoginActions.postTangraLoginStart({
          code: authCode,
          redirect_uri: COGNITO.REDIRECT_SIGN_IN,
        }),
      );
    } else {
      history.push(routes.login.viewGeneric);
    }
  }, []);

  useEffect(() => {
    if (loginError?.loginErrorType) {
      history.replace(routes.login.error);
    }
  }, [loginError]);

  useEffect(() => {
    if (loginDetails?.access_token) {
      storage.setItem(STORAGE_CONSTANTS.accessToken, loginDetails.access_token);
      storage.setItem(STORAGE_CONSTANTS.refreshToken, loginDetails.refresh_token);
      storage.setItem(STORAGE_CONSTANTS.userId, loginDetails.userId);
      storage.setItem(STORAGE_CONSTANTS.userName, `${loginDetails.email}`);
      storage.setItem(STORAGE_CONSTANTS.email, `${loginDetails.email}`);
      storage.setItem(STORAGE_CONSTANTS.userDetails, JSON.stringify(loginDetails));
      history.replace(routes.ingestion);
      getAuditHistory();
    }
  });
  return (
    <Loader loading loaderName={LABELS.authenticating}>
      {LABELS.authenticatePlaceHolder}
    </Loader>
  );
};

export default AuthenticationPage;
