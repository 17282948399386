import { Reducer } from 'redux';
import { ContractAction, ContractActionTypes } from '../actions/UploadContractActions';
import IContractState, { IPostContractsResponse } from '../state/ContractState';
import { IContractCard } from '../../../containers/MNACIDashboard/MNACIDashboard';
import { IContractDetails, IContractDetailsResponse } from '../../../containers/MNACIDashboard/MNACIDashboardModel';

export const initialState: IContractState = {
  error: '',
  loading: false,
  availableContracts: [
    {
      title: 'Order_Form_1.pdf',
      id: '2486',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_1.pdf',
    },
    {
      title: 'Order_Form_2.pdf',
      id: '2943',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_2.pdf',
    },
    {
      title: 'Order_Form_3.pdf',
      id: '0293',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_3.pdf',
    },
    {
      title: 'Order_Form_4.pdf',
      id: '4394',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_4.pdf',
    },
    {
      title: 'Order_Form_5.pdf',
      id: '2095',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_6.pdf',
      id: '20951',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_7.pdf',
      id: '20952',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_8.pdf',
      id: '20953',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_9.pdf',
      id: '20954',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_10.pdf',
      id: '20955',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_11.pdf',
      id: '20956',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_12.pdf',
      id: '20957',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_13.pdf',
      id: '20958',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_14.pdf',
      id: '20959',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
    {
      title: 'Order_Form_15.pdf',
      id: '209510',
      date: '09/08/2023',
      createdBy: 'Raheja Malikk',
      url: 'https://storage.googleapis.com/doc-ai-bi-project-public-forms/Order%20Form_5.pdf',
    },
  ],
  pdfUrl: '',
  searchedContracts: undefined,
  contractsListLoading: false,
  contractsListResponse: {} as IContractDetailsResponse,
  contractsDetailsLoading: false,
  contractsDetailsResponse: [] as IContractDetails[],
};

export const contractReducer: Reducer<IContractState> = (
  state = initialState,
  action: ContractAction,
) => {
  switch (action.type) {
    case ContractActionTypes.UPLOAD_CONTRACT_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ContractActionTypes.UPLOAD_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case ContractActionTypes.UPLOAD_CONTRACT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case ContractActionTypes.GET_PDF_URL_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        pdfUrl: '',
      };
    case ContractActionTypes.GET_PDF_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        pdfUrl: action.data as string,
      };
    case ContractActionTypes.GET_PDF_URL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case ContractActionTypes.POST_SEARCH_CONTRACTS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
        // searchedContracts: undefined,
      };
    case ContractActionTypes.POST_SEARCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        searchedContracts: action.data as IPostContractsResponse,
      };
    case ContractActionTypes.POST_SEARCH_CONTRACTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_LIST_STARTED:
      return {
        ...state,
        contractsListLoading: true,
        error: undefined,
        contractsListResponse: {} as IContractDetailsResponse,
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        contractsListLoading: false,
        error: undefined,
        contractsListResponse: action.data as IContractDetailsResponse,
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_LIST_ERROR:
      return {
        ...state,
        contractsListLoading: false,
        error: action.data as Error,
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_DATA_STARTED:
      return {
        ...state,
        contractsDetailsLoading: true,
        error: undefined,
        contractsDetailsResponse: [] as IContractDetails[],
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        contractsDetailsLoading: false,
        error: undefined,
        contractsDetailsResponse: action.data as IContractDetails[],
      };
    case ContractActionTypes.GET_CONTRACT_DETAILS_DATA_ERROR:
      return {
        ...state,
        contractsDetailsLoading: false,
        error: action.data as Error,
      };
    default:
      return state;
  }
};
