import { Reducer } from 'redux';
import { ICWBLoginResponse } from '../../models/CWBLoginModels';
import { ITangraLoginResponse } from '../../models/LoginModels';
import { LoginAction, LoginActionTypes } from '../actions/LoginActions';
import LoginState from '../state/LoginState';

export const initialState: LoginState = {
  error: '',
  loading: false,
  cwbLoginError: undefined,
  cwbLoginDetails: undefined,
  tangraLoginError: undefined,
  tangraLoginDetails: undefined,
  cwbSsoLoginError: undefined,
  cwbSsoLoginDetails: undefined,
};

export const loginReducer: Reducer<LoginState> = (state = initialState, action: LoginAction) => {
  switch (action.type) {
    case LoginActionTypes.CWB_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        cwbLoginError: undefined,
      };
    case LoginActionTypes.CWB_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        cwbLoginError: undefined,
        cwbLoginDetails: action.data as ICWBLoginResponse,
      };
    case LoginActionTypes.CWB_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        cwbLoginError: action.data,
      };

    case LoginActionTypes.TANGRA_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        tangraLoginError: undefined,
      };
    case LoginActionTypes.TANGRA_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        tangraLoginError: undefined,
        tangraLoginDetails: action.data as ITangraLoginResponse,
      };
    case LoginActionTypes.TANGRA_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        tangraLoginError: action.data,
      };
    
    case LoginActionTypes.CWB_SSO_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        cwbSsoLoginError: undefined,
      };
    case LoginActionTypes.CWB_SSO_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        cwbSsoLoginError: undefined,
        cwbSsoLoginDetails: action.data as ITangraLoginResponse,
      };
    case LoginActionTypes.CWB_SSO_LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        cwbSsoLoginError: action.data,
      };

    default:
      return state;
  }
};
