import { cmsPortalUrls } from '../../AppConstants';
import Operate5 from '../../assets/icons/migrate-icon5.svg';
import Operate6 from '../../assets/icons/migrate-icon6.svg';
import Operate1 from '../../assets/icons/operate-icon1.svg';
import Operate2 from '../../assets/icons/operate-icon2.svg';
import Operate3 from '../../assets/icons/operate-icon3.svg';
import Operate4 from '../../assets/icons/operate-icon4.svg';

export const OperateLabels = {
  enable: 'Enable',
  title: 'Operate',
  subtitle1: '(To launch the CMS/OpenCloud)',
  subtitle:
    'Through discovery, dependency mapping, and risk assessments based on current usage, as well as migration analysis, Cloud Discovery & Assessment enables migrations to be conducted according to informed decisions, helping to minimize risks while ensuring business continuity throughout and after cloud migration.',
};

export interface ICmsDrawerType {
  title: string;
  cmsType: CmsType;
  popoverContent: string;
  cmsRedirectLink?: string;
  icon: string;
}

export enum CmsType {
  OVERALL = 'OVERALL',
  SLA = 'SLA',
  OLA = 'OLA',
  ENV_HEALTH = 'ENV_HEALTH',
  HISTORIC_TREND = 'HISTORIC_TREND',
  VM_INVENTORY = 'VM_INVENTORY',
  SECURITY_COMPLIANCE = 'SECURITY_COMPLIANCE',
  CLOUD_FINANCIAL = 'CLOUD_FINANCIAL',
  DATA_DOG = 'DATA_DOG',
}

export const OperateList: ICmsDrawerType[] = [
  {
    title: 'Overall Analytics',
    cmsType: CmsType.OVERALL,
    cmsRedirectLink: cmsPortalUrls,
    icon: Operate1,
    popoverContent:
      "Overall Analytics gives a bird's eye view of the health, security & compliance status and ticket status for your infrastrucutre",
  },
  {
    title: 'Availability SLAs',
    cmsType: CmsType.SLA,
    cmsRedirectLink: cmsPortalUrls + 'service',
    icon: process.env.PUBLIC_URL + '/availablitysla.svg',
    popoverContent:
      'The availability SLA reflects system’s ability to automatically handle disruptive events that periodically occur in every region.',
  },
  {
    title: 'Observability',
    cmsType: CmsType.DATA_DOG,
    icon: Operate5,
    cmsRedirectLink: cmsPortalUrls + 'infra-monitoring',
    popoverContent:
      'visually track, analyze, and display key performance metrics, which enable you to monitor the health of your infrastructure',
  },
  {
    title: 'Infrastructure OLAs',
    cmsType: CmsType.OLA,
    icon: Operate2,
    // cmsRedirectLink: cmsPortalUrls + 'infra-monitoring',
    popoverContent:
      'The OLAs would be used to track internal service commitments such as response time for incidents or problems assigned to IT groups, Availability of servers supporting various applications.',
  },
  {
    title: 'Observability - Security',
    cmsType: CmsType.ENV_HEALTH,
    icon: Operate3,
    cmsRedirectLink: cmsPortalUrls + 'security',
    popoverContent: 'It gives the compliance status of the VM created.',
  },
  {
    title: 'Observability - Inventory',
    cmsType: CmsType.VM_INVENTORY,
    icon: process.env.PUBLIC_URL + '/cloudinventory.svg',
    cmsRedirectLink: cmsPortalUrls + 'vm-inventory',
    popoverContent:
      'Cloud Asset Inventory inventory is a collection of virtual and physical objects on which you can place permissions, monitor tasks and events, and set alarms',
  },
  {
    title: 'Cloud Spends',
    cmsType: CmsType.CLOUD_FINANCIAL,
    icon: process.env.PUBLIC_URL + '/CloudFinancials.svg',
    popoverContent:
      'Cloud Spends is the process of identifying, measuring, monitoring, and optimizing cloud costs. It consists of procedures and tools that enable organizations to manage their cloud spend',
  },
  {
    title: 'Observability – CMDB',
    cmsType: CmsType.DATA_DOG,
    icon: process.env.PUBLIC_URL + '/datadog.svg',
    popoverContent:
      'Historical trend displays the trends for the selected application or message family or services for the last 24 hours, last week, last 3 months, or last year.',
  },

  // {
  //   title: 'Security & Compliance',
  //   cmsType: CmsType.SECURITY_COMPLIANCE,
  //   icon: Operate6,
  //   cmsRedirectLink: cmsPortalUrls + 'security',
  //   popoverContent:
  //     ' Track the security threat and compliance status of the environment provisioned',
  // },
];

export const CMSRedirectLink = 'https://deloitteauthqa.opencloudportal.com/';
