import {
  IAppAttributeCountPayload,
  IAppInscopePayload,
  IAppInscopeResponse,
  IAppScopeAdjustmentPayload,
  IAppScopeAdjustmentResponse,
  ICombineAttributeCountResponse,
  IHeatmapPayload,
  IHeatmapResponse,
} from '../../models/CloudSuitabilityModels';
import { IStoreAction } from '../StoreHelper';

export enum CloudSuitabilityActionTypes {
  GET_HEATMAP_DATA_STARTED = 'GET_HEATMAP_DATA_STARTED',
  GET_HEATMAP_DATA_SUCCESS = 'GET_HEATMAP_DATA_SUCCESS',
  GET_HEATMAP_DATA_ERROR = 'GET_HEATMAP_DATA_ERROR',
  GET_APP_SCOPE_ADJUSTMENT_STARTED = 'GET_APP_SCOPE_ADJUSTMENT_STARTED',
  GET_APP_SCOPE_ADJUSTMENT_SUCCESS = 'GET_APP_SCOPE_ADJUSTMENT_SUCCESS',
  GET_APP_SCOPE_ADJUSTMENT_ERROR = 'GET_APP_SCOPE_ADJUSTMENT_ERROR',
  GET_APP_INSCOPE_STARTED = 'GET_APP_INSCOPE_STARTED',
  GET_APP_INSCOPE_SUCCESS = 'GET_APP_INSCOPE_SUCCESS',
  GET_APP_INSCOPE_ERROR = 'GET_APP_INSCOPE_ERROR',
  GET_APP_ADJUSTMENT_COUNT_STARTED = 'GET_APP_ADJUSTMENT_COUNT_STARTED',
  GET_APP_ADJUSTMENT_COUNT_SUCCESS = 'GET_APP_ADJUSTMENT_COUNT_SUCCESS',
  GET_APP_ADJUSTMENT_COUNT_ERROR = 'GET_APP_ADJUSTMENT_COUNT_ERROR',
}

export type CloudSuitabilityActionPayload =
  | Error
  | null
  | IHeatmapPayload
  | IHeatmapResponse
  | IAppScopeAdjustmentPayload
  | IAppScopeAdjustmentResponse
  | IAppInscopePayload
  | IAppInscopeResponse
  | ICombineAttributeCountResponse
  | ICombineAttributeCountResponse;

export type CloudSuitabilityAction = IStoreAction<
  CloudSuitabilityActionTypes,
  CloudSuitabilityActionPayload
>;

export class CloudSuitabilityActions {
  public static getHeatmapDataStart(data: IHeatmapPayload): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED,
      data,
    };
  }
  public static getHeatmapDataSuccess(data: IHeatmapResponse): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_SUCCESS,
      data,
    };
  }
  public static getHeatmapDataError(error: Error): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_HEATMAP_DATA_ERROR,
      data: error,
    };
  }
  public static getAppScopeAdjustmentStart(
    data: IAppScopeAdjustmentPayload,
  ): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_STARTED,
      data,
    };
  }
  public static getAppScopeAdjustmentSuccess(
    data: IAppScopeAdjustmentResponse,
  ): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_SUCCESS,
      data,
    };
  }
  public static getAppScopeAdjustmentError(error: Error): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_ERROR,
      data: error,
    };
  }

  public static getAppInScopeStarted(data: IAppInscopePayload): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_INSCOPE_STARTED,
      data,
    };
  }
  public static getAppInScopeSuccess(data: IAppInscopeResponse): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_INSCOPE_SUCCESS,
      data,
    };
  }
  public static getAppInScopeError(error: Error): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_INSCOPE_ERROR,
      data: error,
    };
  }

  public static getAppAdjustmentCountStarted(
    data: IAppAttributeCountPayload,
  ): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_STARTED,
      data,
    };
  }
  public static getAppAdjustmentCountSuccess(
    data: ICombineAttributeCountResponse,
  ): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_SUCCESS,
      data,
    };
  }
  public static getAppAdjustmentCountError(error: Error): CloudSuitabilityAction {
    return {
      type: CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_ERROR,
      data: error,
    };
  }
}
