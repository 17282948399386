import { ClockCircleOutlined, SmileOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Timeline, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import './Stages.less';
import { useDispatch } from 'react-redux';
import AvgSubmission from '../../../assets/images/avg-submission.png';
import AvgExtraction from '../../../assets/images/avg-extraction.png';
import ExpectedSubmission from '../../../assets/images/expected-submission.png';
import ExpectedExtraction from '../../../assets/images/expected-extraction.png';
import { PersonaActionTypes, PersonaActions } from '../../../libs/store/actions/PersonaActions';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import Loader from '../../../components/Loader/Loader';
import WhiteTick from '../../../assets/icons/white-tick.svg';
import AttentionMark from '../../../assets/icons/summary-warning.svg';

const StagesTabContent = () => {
  const dispatch = useDispatch();
  const { stageDetails, loading } = useAppSelector((state: IApplicationState) => ({
    stageDetails: state.personaDetails.stageDetails,
    loading: state.personaDetails.loading,
  }));
  const dispatcher = {
    // TBD: replace hardcoded submission ID
    getStageData: () => dispatch(PersonaActions.getStageDetails('7641885884291425')),
  };
  const [percentages, setPercentages] = React.useState([0, 0, 0, 0]);
  setTimeout(() => {
    setPercentages([100, 100, 100, 20]);
  }, 2000);

  const getProgressChart = (percentage: number, success: number) => {
    return (
      <Tooltip title="">
        <Progress
          percent={70}
          // count={percentage}
          format={() => `${success}`}
          gapDegree={140}
          success={{ percent: 0 }}
          strokeLinecap="butt"
          type="circle"
          strokeWidth={16}
          // size={[12,12]}
          // strokeColor={"#fff"}
          width={100}
          trailColor={'#CECECE'}
        />
      </Tooltip>
    );
  };
  useEffect(() => {
    dispatcher.getStageData();
  }, []);

  const timelineItems = React.useMemo(() => {
    const timelineItems = [
      {
        title: 'Email Extraction',
        pillStyle: 'email-pill-bg',
        aeroColor: '#f4b309',
        // progress: 100,
        progress: 0,
        actual: 542,
        expected: 700,
      },
      {
        title: 'Submission Classification',
        pillStyle: 'classification-pill-bg',
        aeroColor: '#5c5aca',
        progress: 0,
        actual: 122,
        expected: 610,
      },
      {
        title: 'Submission Extraction',
        pillStyle: 'extraction-pill-bg',
        aeroColor: '#74b0fe',
        progress: 0,
        actual: 146.25,
        expected: 500,
      },
      {
        title: 'Underwriter Review',
        pillStyle: 'underwriter-pill-bg',
        aeroColor: '#d275c9',
        progress: 0,
        actual: '--',
        expected: 230,
      },
    ];
    timelineItems[0].progress = 100;
    timelineItems[1].progress = 100;
    timelineItems[2].progress = 100;
    timelineItems[3].progress = 20;
    stageDetails?.forEach((detail) => {
      timelineItems.forEach((item) => {
        if (item.title === detail.category) {
          const actualTime =
            (new Date(detail.endDate).getTime() - new Date(detail.startDate).getTime()) / 1000;
          if (!isNaN(actualTime) && detail.endDate && detail.startDate) {
            item.actual = actualTime;
            item.expected = actualTime + 100;
          }
        }
      });
    });
    return timelineItems;
  }, [stageDetails]);
  return (
    <Loader loaderName="" loading={loading}>
      <div className="stages-container">
        {/* <div className="legend-container">
          <div className="align-item-center mr-2">
            <span className="legend filled-bg" />
            Average Time (Seconds)
          </div>
          <div className="align-item-center">
            <span className="legend track-bg" />
            Expected Time (Seconds)
          </div>
        </div> */}

        <Row className="header" gutter={[16, 16]}>
          <Col span={6}>Stages</Col>
          <Col span={3}>Avg. Time (in sec.)</Col>
          <Col span={12}>Progress</Col>
        </Row>

        <Timeline>
          {timelineItems.map((item, idx) => (
            <Timeline.Item
              key={item.title}
              // dot={<span className={`timeline-pill ${item.pillStyle}`} />}
              dot={
                item.progress === 100 ? (
                  <div className="timeline-pill-container timeline-pill-container-finished">
                    <img src={WhiteTick} alt="step-completed" className="tick-style" />
                  </div>
                ) : (
                  <div className="timeline-pill-container">
                    {/* <span className={`timeline-pill flex-center`}>{idx + 1}</span> */}
                    <img src={AttentionMark} alt="step-completed" className="attention-style" />
                  </div>
                )
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <p className={`${item.progress === 100 ? 'timeline-item-title' : ''}`}>
                    {item.title}
                  </p>
                  {/* <p className="timeline-item-stage">Stage {idx + 1}</p> */}
                </Col>
                <Col span={3}>
                  <p className={`${item.progress === 100 ? 'timeline-item-title' : ''}`}>
                    {item.actual}
                  </p>
                  {/* <p className="timeline-item-stage">Stage {idx + 1}</p> */}
                </Col>
                {/* <Col span={6} className='chart-height'>{getProgressChart(60, 50)}</Col>
              <Col span={6} className='chart-height'>{getProgressChart(60, 50)}</Col> */}
                <Col span={12}>
                  {/* {item.progress !== 100 && (
                    <div
                      style={{
                        marginLeft: `calc(${item.progress}% - 6px)`,
                        borderTopColor: '#5c5aca',
                      }}
                      className="down-aero"
                    ></div>
                  )} */}
                  <div className="track">
                    <span
                      className={`filled ${
                        percentages[idx] !== 100 ? 'border-right-radius-none' : ''
                      }`}
                      style={{ width: `${percentages[idx]}%` }}
                    ></span>
                  </div>
                  <div className="">
                    <p
                      style={{
                        marginLeft: `${percentages[idx]}%`,
                      }}
                      className="white-space-no-wrap"
                    >
                      {percentages[idx]}%
                    </p>

                    {/* <p>{100}%</p> */}
                  </div>
                </Col>
                {/* <Col span={12}>
                  <Progress type="circle" percent={item.progress} width={80} />
                </Col> */}
              </Row>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </Loader>
  );
};

export default StagesTabContent;
