import { CloudServiceProvider } from '../../AppConstants';
import { LandingZoneStatus } from './BuildDeployModels';

export interface ILandingZoneNotification {
  landingZoneName: string;
  account: string;
  project: string;
  username: string;
  dateTime: string;
  cloudProvider: CloudServiceProvider;
  status: LandingZoneStatus;
  Id: string;
}

export enum IEmailNotificationType {
  ACCOUNT_CREATED = 'accountCreated',
  PROJECT_CREATED = 'projectCreated',
  PROJECT_SUSPENDED = 'projectSuspended',
  PROJECT_EDITED = 'projectEdited',
}
export interface IEmailNotification {
  username: string;
  project: string;
  account: string;
  dateTime: string;
  notificationType: IEmailNotificationType;
}

export interface ILandingZoneNotificationReqPayload {
  accountId: string;
  projectId: string;
}

export interface IInitialNotificationStatus {
  landingZoneNotification: boolean;
  userNotification: boolean;
}
