import { put, takeEvery } from 'redux-saga/effects';
import { ChatbotAction, ChatbotActionTypes, ChatbotActions } from '../actions/ChatbotActions';
import chatbotService from '../../services/ChatbotService';
import { IChatbotResponseType } from '../../models/ChatbotModels';

export function* sendQueryWorker(action: ChatbotAction) {
  try {
    const response: IChatbotResponseType = (yield chatbotService.sendQueryUser(action.data as string))!;

    yield put(ChatbotActions.sendQuerySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ChatbotActions.sendQueryError(error));
    }
  }
}

export function* sendQueryWatcher() {
  yield takeEvery(ChatbotActionTypes.SEND_QUERY_START, sendQueryWorker);
}
