import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { IInitialNotificationStatus } from '../../models/NotificationModels';
import {
  IAddAccountPayload,
  ICreateAccountPayload,
  ICreateProjectPayload,
  IEditProjectPayload,
} from '../../models/OverviewModels';
import OverViewService from '../../services/OverviewService/OverviewService';
import { DashboardActions } from '../actions/DashboardActions';
import { NotificationActions } from '../actions/NotificationActions';
import { OverviewAction, OverviewActions, OverviewActionTypes } from '../actions/OverviewActions';
import { ToastActions } from '../actions/ToastActions';

export function* createAccountWorker(action: OverviewAction) {
  try {
    const data = action.data as ICreateAccountPayload;
    const response: string = yield OverViewService.createAccount(data);
    yield put(OverviewActions.createAccountSuccess());
    yield put(DashboardActions.getAllAccountsStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(OverviewActions.createAccountError(error));
    }
  }
}

export function* createProjectWorker(action: OverviewAction) {
  try {
    const data = action.data as ICreateProjectPayload;
    const response: string = yield OverViewService.addProject(data);
    yield put(OverviewActions.createProjectSuccess());
    yield put(DashboardActions.getAllAccountsStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(OverviewActions.createProjectError(error));
    }
  }
}

export function* addAccountWorker(action: OverviewAction) {
  try {
    const data = action.data as IAddAccountPayload;
    const response: string = yield OverViewService.addAccount(data);
    yield put(OverviewActions.addAccountSuccess());
    yield put(DashboardActions.getAllAccountsStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(
        ToastActions.showToast({
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
      yield put(OverviewActions.addAccountError(error));
    }
  }
}

export function* editProjectWorker(action: OverviewAction) {
  try {
    const data = action.data as IEditProjectPayload;
    yield OverViewService.editProject(data);
    yield put(OverviewActions.editProjectSuccess());
    yield put(DashboardActions.getAllAccountsStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(OverviewActions.editProjectError(error));
    }
  }
}

export function* getNotificationWorker() {
  try {
    const result: IInitialNotificationStatus = yield OverViewService.getNotificationStatus();
    const { landingZoneNotification } = result;
    /* If Initial Notification status is true ,setting the notification badge as true*/
    if (landingZoneNotification) {
      yield put(NotificationActions.toggleLandingZoneNotificationDot(true));
    }
    yield put(OverviewActions.getNotificationDetailsSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(OverviewActions.getNotificationDetailsError(error));
    }
  }
}

export function* overviewWatcher() {
  yield takeEvery(OverviewActionTypes.CREATE_ACCOUNT_START, createAccountWorker);
  yield takeEvery(OverviewActionTypes.CREATE_PROJECT_START, createProjectWorker);
  yield takeEvery(OverviewActionTypes.EDIT_PROJECT_START, editProjectWorker);
  yield takeEvery(OverviewActionTypes.ADD_ACCOUNT_START, addAccountWorker);
  yield takeEvery(
    OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_STARTED,
    getNotificationWorker,
  );
}
