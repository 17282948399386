import { Button } from 'antd';
import React, { useState } from 'react';
import StagesTabContent from './StagesTabContent';
import Attachments from './Attachments';
import Forms from './Forms';
import AttachmentsTable from './AttachmentsTable';

const DataIngestion = () => {
  const [selectedTab, setSelectedTab] = useState('Stages');
  const tabs = ['Stages', 'Attachments', 'Forms']
//   const content = () => {
//     switch (selectedTab) {
//       case tabs[0].label:
//         return 'stages';
//       case tabs[1].label:
//         return 'att';
//       default:
//         return 'forms';
//     }
//   };
  return (
    <>
      {/* <Tabs defaultActiveKey="1">
        {tabs.map((tab, idx) => (
          <TabPane tab={tab.label} key={idx+1}>
            {tab.children}
          </TabPane>
        ))}
      </Tabs> */}
      
      <div className="tabs-container">
        {tabs.map((tab) => (
          <Button
            type="text"
            key={tab}
            className={`tab ${selectedTab === tab ? 'selected' : ''}`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </Button>
        ))}
      </div>
        {/* {content()} */}
        {selectedTab === tabs[0] && <StagesTabContent/>}

        {selectedTab === tabs[1] && <AttachmentsTable/>}
        {/* {selectedTab === tabs[1] && <Attachments/>} */}
        {selectedTab === tabs[2] && <Forms/>}
    </>
  );
};

export default DataIngestion;
