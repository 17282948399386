import { IPdfUrlResponse } from '../../containers/MNACIDashboard/MNACIDashboard';
import {
  IContractDetailsRequestBody,
  IContractDetailsResponse,
} from '../../containers/MNACIDashboard/MNACIDashboardModel';
import {
  IPostContractsPayload,
  IPostContractsResponse,
  IUploadContracts,
} from '../store/state/ContractState';
import { API_ROUTE } from './ApiRoute';
import ApiService from './ApiService';
import ApiServiceBase from './ApiServiceBase';
import { STORAGE_CONSTANTS, storage } from './LocalStorage';
import { ServerType, ServiceType } from './ServiceType.data';

const serviceType = ServiceType.None;

export class ContractService {
  // private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.MNACPREURL);

  public getPdfUrl(fileName: string): Promise<IPdfUrlResponse> | IPdfUrlResponse {
    return this.service.post({ route: [`${API_ROUTE.GET_PDF_URL}`] }, { 'contract_id': fileName });
  }
  public postSearchContract(
    data: IPostContractsPayload,
  ): Promise<IPostContractsResponse> | IPostContractsResponse {
    return this.service.post({ route: [`${API_ROUTE.POST_SEARCH_CONTRACTS}`] }, data);
  }
  public getContractList(
    data: IContractDetailsRequestBody,
  ): Promise<IContractDetailsResponse> | IContractDetailsResponse {
    return this.service.post({ route: [`${API_ROUTE.GET_CONTRACT_LIST}`] }, data);
  }
  public getContractData(data: string): Promise<Record<string, string>> | Record<string, string> {
    return this.service.post(
      { route: [`${API_ROUTE.GET_CONTRACT_DETAILS}`] },
      { 'contract_id': data },
    );
  }
  public uploadContract(
    data: IUploadContracts,
  ): Promise<Record<string, string>> | Record<string, string> {
    return this.service.postMultipart({ route: [`${API_ROUTE.UPLOAD_CONTRACT}`] }, [
      { content: data.file, name: 'file' },
      {
        content:
          storage.getItem(STORAGE_CONSTANTS.email) &&
          storage.getItem(STORAGE_CONSTANTS.email)?.split('@')[0],
        name: 'createdBy',
      },
    ]);
  }
}

const contractService = new ContractService();
export default contractService;
