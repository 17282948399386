import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import {
  FeedbackResponseLabels,
  IFeedbackResponseData,
} from '../../../containers/FeedbackResponse/FeedbackResponseConstants';
import feedbackService from '../../services/Feedback/FeedbackService';
import {
  FeedbackAction,
  FeedbackActions,
  FeedbackActionTypes,
  IPostFeedBackObj,
} from '../actions/FeedbackActions';
import { ToastActions } from '../actions/ToastActions';

export function* getFeedbackWorker() {
  try {
    const response: IFeedbackResponseData[] = (yield feedbackService.getFeedback())!;
    yield put(FeedbackActions.getFeedbackSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(FeedbackActions.getFeedbackError(error));
    }
  }
}

export function* postFeedbackWorker(action: FeedbackAction) {
  try {
    const body = action.data as IPostFeedBackObj;
    yield feedbackService.postFeedback(body)!;
    yield put(FeedbackActions.postFeedbackSuccess());
    yield put(
      ToastActions.showToast({
        description: FeedbackResponseLabels.feedbackSubmited,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(FeedbackActions.postFeedbackError(error));
      yield put(
        ToastActions.showToast({
          description: FeedbackResponseLabels.feedbackSubmited,
          type: NotificationTypes.SUCCESS,
        }),
      );
    }
  }
}

export function* feedbackWatcher() {
  yield takeEvery(FeedbackActionTypes.GET_FEEDBACK_START, getFeedbackWorker);
  yield takeEvery(FeedbackActionTypes.POST_FEEDBACK_START, postFeedbackWorker);
}
