import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React from 'react';
import { pageSizeOptions } from '../../../AppConstants';
import Ellipse from '../../../assets/icons/ellipse.svg';
import ExpandedRow from '../../../assets/images/expanded-row.png'

export interface IGuidanceTableProps {
  key: string;
  subject: string;
  role: string;
  insight: string;
  relevance_score: number;
}
const DesicionGuidanceWidget = () => {
  const columns = [
    {
      // dataIndex: 'subject',
      title: 'Subject',
      render: (record: IGuidanceTableProps) => {
        return (
          <div className="d-flex">
            <div>
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/config-icons/shi-avatar-icon.svg`}
                className="mr-1"
              />
            </div>
            <div className="name-role-container">
              <div>{record.subject}</div>
              <div>{record.role}</div>
            </div>
          </div>
        );
      },
    },
    { dataIndex: 'insight', title: 'Insight' },
    {
      dataIndex: 'relevance_score',
      title: 'Relevance Score',
      render: (score: number) => {
        const getColor = (val: number) => {
          return val >= 80 ? 'severity-low' : val >= 40 ? 'severity-normal' : 'severity-high';
        };
        return <span className={getColor(score)+' font-bold'}>{score}% </span>;
      },
    },
    {
      title: 'Actions',
      render: () => {
        return <img src={Ellipse} alt='ellipse'/>;
      },
    },
  ];
  const tableData = [
    {
      key: '1',
      subject: 'Philip Nash',
      role: 'Producer',
      insight: 'Lower Risk Retail   > High value products',
      relevance_score: 91,
    },
    {
      key: '2',
      subject: 'Jose Griffin',
      role: 'Producer',
      insight: 'Lower Risk Retail   > High value products',
      relevance_score: 84,
    },
    {
      key: '3',
      subject: 'Chad Hodges',
      role: 'Applicant',
      insight: 'Larger exposure than typical for segment',
      relevance_score: 33,
    },
    {
      key: '4',
      subject: 'Carlos Greer',
      role: 'Applicant',
      insight: 'Agent Bind Rate High',
      relevance_score: 64,
    },
  ];
  return (
    <div className="decision-guidance-container">
      <h2>Decision Guidance</h2>
      <Table
        bordered={false}
        rowClassName={(record, index) => (index % 2 === 0 ? 'light-antd-row' : 'dark-antd-row')}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
          expandedRowClassName: () => `expanded-row-antd`,
          expandedRowRender: () => (
            <div className="flex-column gap-1 mb-1-dot-5 ml-2"><img src={ExpandedRow} alt="" className='w-100' /></div>
          ),
          rowExpandable: () => true,
          columnWidth: 7,
        }}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions,
        }}
        className="table-style user-responses-table"
        columns={columns}
        dataSource={tableData}
      />
    </div>
  );
};

export default DesicionGuidanceWidget;
