import { Layout } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader/Loader';
import { BuildDeployActions } from '../../libs/store/actions/BuildDeployActions';
import { DashboardActions } from '../../libs/store/actions/DashboardActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import AppHeader from '../AppHeader/AppHeader';
import ProductTour from '../ProductTour/ProductTour';
import Sidebar from '../Sidebar/Sidebar';
import { SideBarKey } from '../Sidebar/SidebarConstants';
import SubHubInsuranceDashboard from '../SubHubInsuranceDashboard/SubHubInsuranceDashboard';
import { ICountLandingZone, routesWithLoadingDisabled } from './HomePageConstants';
import { calculateLandingZones } from './HomePageHelperFunction';
import './HomePageStyles.less';

const { Content } = Layout;
interface IHomePageProps {
  children: ReactNode;
}

const HomePage = ({ children }: IHomePageProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // TODO: Set-up homepage state in redux to keep track of current page when more pages are added.

  const {
    isExpanded,
    hiddenSidebar,
    activeKey,
    loginLoading,
    userLoading,
    dashboardLoading,
    jobRunLoading,
    selectedProjectId,
    overViewLoading,
    loading,
    landingZones
  } = useAppSelector(
    ({
      sidebar: { isExpanded, hiddenSidebar, activeKey },
      overview: { selectedProjectId },
      login,
      user,
      dashboard,
      operations,
      overview,
      buildDeploy: { loading, landingZones },
    }: IApplicationState) => ({
      landingZones,
      isExpanded,
      hiddenSidebar,
      activeKey,
      loginLoading: login.loading,
      userLoading: user.loading,
      dashboardLoading: dashboard.loading,
      jobRunLoading: operations.loadingJobRun,
      overViewLoading: overview.loading,
      selectedProjectId,
      loading
    }),
  );

  const dispatcher = {
    getBuildDeployDetails: (data: string) =>
      dispatch(BuildDeployActions.getBuildDeployDetailStarted(data)),
    setTotalEnvironments: (data: ICountLandingZone) =>
      dispatch(BuildDeployActions.setTotalEnvironmentsCount(data)),
    getAssessmentsState: (data: string) => dispatch(DashboardActions.getAssessmentDataStart(data)),
  };

  const getBuildDeployDetails = (projectId: string) => dispatcher.getBuildDeployDetails(projectId);

  useEffect(() => {
    selectedProjectId && getBuildDeployDetails(selectedProjectId);
    selectedProjectId && dispatcher.getAssessmentsState(selectedProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  useEffect(() => {
    const count = calculateLandingZones(landingZones);

    dispatcher.setTotalEnvironments(count);
  }, [landingZones]);

  const loadingDisabled = routesWithLoadingDisabled.includes(history.location.pathname);

  return (
    <>
      <Loader
        loading={
          loadingDisabled
            ? false
            : dashboardLoading ||
              loginLoading ||
              jobRunLoading ||
              overViewLoading ||
              userLoading ||
              // TODO: Add the build deploy loading back once the dca APIs are fixed
              loading
        }
        loaderName=""
      >
        <Layout>
          {/* <Sidebar /> */}
          {!(hiddenSidebar && isExpanded) && (
            <Layout className="main-content-container">
              <AppHeader isScrolled={scrollPosition > 15} />
              <Content>{children}</Content>
            </Layout>
          )}
        </Layout>
      </Loader>
      {/*TODO: Need to change it to label rather than key */}
      <ProductTour startTour={activeKey === SideBarKey.HELP} />
    </>
  );
};

export default HomePage;
