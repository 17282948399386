/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { CloudServiceProvider } from '../../../AppConstants';
import { buildDeployMenu } from '../../../containers/BuildDeploy/BuildDeployConstants';
import { envTypes } from '../../../containers/DeployBuild/ModalConstants';
import { ICountLandingZone } from '../../../containers/HomePage/HomePageConstants';
import {
  IBuildDeployErrorState,
  ILandingZoneSector,
  LandingZoneStatus,
} from '../../models/BuildDeployModels';
import {
  ICheckMicroServicesPatternStatus,
  IConfigureMicroServiceError,
} from '../../models/DashboardModels';
import {
  BuildDeployAction,
  BuildDeployActionTypes,
  IConfigureLandingZoneError,
  ILandingZoneErrorResponse,
} from '../actions/BuildDeployActions';
import BuildDeployState, {
  IArchitectureCatalogueResponse,
  ICloudLandingZoneKeysResponse,
  IConfigureLZLoadingPayload,
  IDeploymentLogsResponse,
  IDeploymentsResponse,
  IEnvironmentsDeployed,
  ILandingZoneDeploymentStatus,
  ILandingZoneState,
  IPipelinesAnalyticsResponse,
  IPipelinesCummulativeAnalyticsResponse,
  IYamlContent,
  IDeployValueState,
  ILandingZoneDeploymentResponse,
} from '../state/BuildDeployState';

export const initialState: BuildDeployState = {
  error: '',
  deployments: [] as IDeploymentsResponse[],
  loading: false,
  loadingError: false,
  landingZonesError: undefined,
  pipelinesCummulative: [] as IPipelinesCummulativeAnalyticsResponse[],
  environmentsDeployed: [] as IEnvironmentsDeployed[],
  architectureList: [] as IArchitectureCatalogueResponse[],
  pipelinesAnalytics: [] as IPipelinesAnalyticsResponse[],
  yamlContent: {} as IYamlContent,
  yamlDefaultTemplateContent: {} as any,
  industryYamlContent: '',
  totalEnvironments: {
    all: 0,
    gcp: 0,
    aws: 0,
    azure: 0,
    oracle: 0,
    openshift: 0,
    multicloud: 0,
  },
  landingZoneDeploymentStatus: [] as ILandingZoneDeploymentStatus[],
  landingZones: [],
  buildYaml: '',
  selectedTab: buildDeployMenu[1].menuName,
  cloudLandingZoneKeys: [] as ICloudLandingZoneKeysResponse[],
  deployInstanceId: '',
  cloudLandingZoneDeployStatus: {
    state: '',
    failed: false,
  } as ILandingZoneDeploymentResponse,
  deploymentLogs: [] as IDeploymentLogsResponse[],
  deployValues: {
    cloudEnvName: '',
    selectedProvider: CloudServiceProvider.AWS,
    selectedEnvType: envTypes[0].name,
    defaultProviderSelected: false,
    multiCloudCluster: { 'default': ['XYZ'] },
    projectName : '',
    multiActiveCloud: 'default',
    multiCloudClusterForm: {},
    highlightedCluster: ['anthos_gcp'],
    industryYamlPolicies: [],
  } as IDeployValueState,
  multiCloudYamlContent: 'Environments: capabilities_multicloud: anthos_gcp: - bastion_name: bastionname cluster_tags: sd enable_feature: truegke_cluster_name: newvaluesmaster_ipv4_cidr_block: 10.0.3.0/28max_node_count: 4max_pods_per_node: 8min_node_count: 3',
};

export const buildDeployReducer: Reducer<BuildDeployState> = (
  state = initialState,
  action: BuildDeployAction,
) => {
  switch (action.type) {
    case BuildDeployActionTypes.ADD_LANDING_ZONE:
      return {
        ...state,
        landingZones: [action.data as ILandingZoneState, ...state.landingZones],
      };

    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_SUCCESS:
      return {
        ...state,
        landingZones: action.data as ILandingZoneState[],
        loading: false,
      };

    case BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.ENABLE_LANDING_ZONE_STARTED:
      return {
        ...state,
      };

    case BuildDeployActionTypes.ENABLE_LANDING_ZONE_SUCCESS:
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, action.data as number),
          {
            ...state.landingZones[action.data as number],
            landingZoneEnabled: true,
          },
          ...state.landingZones.slice((action.data as number) + 1),
        ],
      };

    case BuildDeployActionTypes.DISABLE_LANDING_ZONE:
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, action.data as number),
          {
            ...state.landingZones[action.data as number],
            landingZoneEnabled: false,
          },
          ...state.landingZones.slice((action.data as number) + 1),
        ],
      };

    case BuildDeployActionTypes.ENABLE_LANDING_ZONE_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_STARTED:
      return {
        ...state,
      };

    case BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_SUCCESS:
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, action.data as number),
          {
            ...state.landingZones[action.data as number],
            workLoadPatternMicroServicesEnabled: state.landingZones[action.data as number]
              .workLoadPatternMicroServicesConfigured
              ? true
              : !state.landingZones[action.data as number].workLoadPatternMicroServicesEnabled,
          },
          ...state.landingZones.slice((action.data as number) + 1),
        ],
      };

    case BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_ERROR:
      return {
        ...state,
        error: action.data,
      };

    case BuildDeployActionTypes.ENABLE_ETL_PATTERN_STARTED:
      return {
        ...state,
      };

    case BuildDeployActionTypes.ENABLE_ETL_PATTERN_SUCCESS:
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, action.data as number),
          {
            ...state.landingZones[action.data as number],
            workLoadPatternEtlEnabled: state.landingZones[action.data as number]
              .workLoadPatternEtlConfigured
              ? true
              : !state.landingZones[action.data as number].workLoadPatternEtlEnabled,
          },
          ...state.landingZones.slice((action.data as number) + 1),
        ],
      };

    case BuildDeployActionTypes.ENABLE_ETL_PATTERN_ERROR:
      return {
        ...state,
        error: action.data,
      };
    case BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_LOADING: {
      const { index, landingZoneName, csp, landingZoneId, sector } =
        action.data as IConfigureLZLoadingPayload;

      const newLandingZone = {
        ...state.landingZones[index],
        loadingLandingZone: true,
        landingZoneConfigured: false,
        landingZoneName,
        landingZoneId,
        landingZoneStatus: LandingZoneStatus.IN_PROGRESS,
        cloudServiceProvider: csp,
        sector: sector as ILandingZoneSector,
      };

      return {
        ...state,
        loading: false,
        landingZones: [newLandingZone, ...state.landingZones],
      };
    }

    case BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_SUCCESS: {
      const name = action.data as string;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === name) {
          landingZone.loadingLandingZone = false;
          landingZone.landingZoneEnabled = true;
          landingZone.landingZoneConfigured = true;
          landingZone.landingZoneStatus = LandingZoneStatus.CREATED;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
        loading: false,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_ERROR: {
      const landingzonesErrorData = action.data as IConfigureLandingZoneError;
      return {
        ...state,
        loading: false,
        error: landingzonesErrorData.error as Error,
        landingZones: [
          ...state.landingZones.slice(0, landingzonesErrorData.index),
          {
            ...state.landingZones[landingzonesErrorData.index],
            loadingLandingZone: false,
          },
          ...state.landingZones.slice(landingzonesErrorData.index + 1),
        ],
      };
    }

    case BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_STARTED: {
      return {
        ...state,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_LOADING: {
      const { landingZoneName, microServiceId } = action.data as ICheckMicroServicesPatternStatus;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === landingZoneName) {
          landingZone.loadingMicroServicesWorkload = true;
          landingZone.workLoadPatternMicroServicesConfigured = false;
          landingZone.workLoadPatternMicroServicesId = microServiceId;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_SUCCESS: {
      const { landingZoneName, microServiceId } = action.data as ICheckMicroServicesPatternStatus;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === landingZoneName) {
          landingZone.loadingMicroServicesWorkload = false;
          landingZone.workLoadPatternMicroServicesConfigured = true;
          landingZone.workLoadPatternMicroServicesId = microServiceId;
          landingZone.workLoadPatternMicroServicesError = false;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_FAILED: {
      const landingZoneName = action.data as string;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === landingZoneName) {
          landingZone.loadingMicroServicesWorkload = false;
          landingZone.workLoadPatternMicroServicesConfigured = false;
          landingZone.workLoadPatternMicroServicesError = true;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_ERROR: {
      const { error, landingZoneName } = action.data as IConfigureMicroServiceError;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === landingZoneName) {
          landingZone.loadingMicroServicesWorkload = false;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
      };
    }

    case BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_STARTED: {
      const etlStartedData = action.data as number;
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, etlStartedData),
          {
            ...state.landingZones[etlStartedData],
            loadingEtlWorkload: true,
          },
          ...state.landingZones.slice(etlStartedData + 1),
        ],
      };
    }

    case BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_SUCCESS: {
      const etlSuccessData = action.data as number;
      return {
        ...state,
        landingZones: [
          ...state.landingZones.slice(0, etlSuccessData),
          {
            ...state.landingZones[etlSuccessData],
            loadingEtlWorkload: false,
            workLoadPatternEtlConfigured: true,
          },
          ...state.landingZones.slice(etlSuccessData + 1),
        ],
      };
    }

    case BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_ERROR: {
      return {
        ...state,
        error: action.data as Error,
      };
    }

    case BuildDeployActionTypes.DELETE_LANDING_ZONE_STARTED:
      return {
        ...state,
        loading: true,
      };

    case BuildDeployActionTypes.DELETE_LANDING_ZONE_SUCCESS:
      return {
        ...state,
        loading: false,
        landingZones: state.landingZones.map((landingZone) =>
          landingZone.landingZoneId === action.data
            ? { ...landingZone, landingZoneStatus: LandingZoneStatus.DELETE_IN_PROFRESS }
            : landingZone,
        ),
      };
    case BuildDeployActionTypes.DELETE_LANDING_ZONE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_STARTED:
      return {
        ...state,
      };

    case BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_STARTED:
      return {
        ...state,
        loadingError: true,
      };

    case BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_SUCCESS: {
      const landingZoneError = action.data as ILandingZoneErrorResponse;
      const landingZoneErrorArr: IBuildDeployErrorState[] = [];
      for (const [key, value] of Object.entries(landingZoneError)) {
        landingZoneErrorArr.push({
          errorKey: key,
          errorValue: value,
        });
      }
      return {
        ...state,
        loadingError: false,
        landingZonesError: landingZoneErrorArr,
      };
    }
    case BuildDeployActionTypes.CONFIGURE_LANDING_ERROR_SUCCESS: {
      const name = action.data as string;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneName === name) {
          landingZone.loadingLandingZone = false;
          landingZone.landingZoneEnabled = true;
          landingZone.landingZoneConfigured = false;
          landingZone.landingZoneStatus = LandingZoneStatus.ERROR;
        }
        return landingZone;
      });
      return {
        ...state,
        loading: false,
        landingZones,
      };
    }

    case BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_FAILED:
      return {
        ...state,
        loadingError: false,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_SUCCESS: {
      const destroyMsWorkLoadLandingZoneId = action.data as string;
      const landingZones = state.landingZones.map((landingZone) => {
        if (landingZone.landingZoneId === destroyMsWorkLoadLandingZoneId) {
          landingZone.workLoadPatternMicroServicesConfigured = false;
          landingZone.workLoadPatternMicroServicesEnabled = false;
          landingZone.workLoadPatternMicroServicesId = undefined;
        }
        return landingZone;
      });
      return {
        ...state,
        landingZones,
      };
    }

    case BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.SET_TOTAL_ENVIRONMENTS:
      return {
        ...state,
        totalEnvironments: action.data as ICountLandingZone,
      };
    case BuildDeployActionTypes.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.data as string,
      };
    case BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_START:
      return {
        ...state,
        loading: true,
      };

    case BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_SUCCESS:
      return {
        ...state,
        buildYaml: action.data as string,
        loading: false,
      };

    case BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_START:
      return {
        ...state,
        loading: true,
      };

    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED:
      return {
        ...state,
        loading: true,
      };

    case BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_SUCCESS:
      return {
        ...state,
        environmentsDeployed: action.data as IEnvironmentsDeployed[],
        loading: false,
      };

    case BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_ERROR:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_SUCCESS:
      return {
        ...state,
        architectureList: action.data as IArchitectureCatalogueResponse[],
        loading: false,
      };

    case BuildDeployActionTypes.GET_ARCHITECTURE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_START:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        landingZoneDeploymentStatus: action.data as ILandingZoneDeploymentStatus[],
      };

    case BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_SUCCESS:
      return {
        ...state,
        pipelinesAnalytics: action.data as IPipelinesAnalyticsResponse[],
        loading: false,
      };

    case BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_SUCCESS:
      return {
        ...state,
        pipelinesCummulative: action.data as IPipelinesCummulativeAnalyticsResponse[],
        loading: false,
      };

    case BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.GET_YAML_CONTENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_YAML_CONTENT_SUCCESS:
      return {
        ...state,
        yamlContent: action.data as IYamlContent,
        loading: false,
      };

    case BuildDeployActionTypes.GET_YAML_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_SUCCESS:
      return {
        ...state,
        yamlDefaultTemplateContent: action.data as any,
        loading: false,
      };

    case BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_SUCCESS:
      return {
        ...state,
        industryYamlContent: action.data as string,
        loading: false,
      };

    case BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_SUCCESS:
      return {
        ...state,
        cloudLandingZoneKeys: action.data as ICloudLandingZoneKeysResponse[],
        loading: false,
      };

    case BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        landingZones: [action.data as ILandingZoneState, ...state.landingZones],
      };

    case BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_SUCCESS:
      return {
        ...state,
        deployInstanceId: action.data as string,
        loading: false,
      };
    case BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_SUCCESS:
      return {
        ...state,
        status: action.data as string,
        loading: false,
      };
    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_SUCCESS:
      return {
        ...state,
        cloudLandingZoneDeployStatus: action.data as ILandingZoneDeploymentResponse,
        loading: false,
      };

    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };

    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        deployments: action.data as IDeploymentsResponse[],
        loading: false,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_STARTED:
      return {
        ...state,
        deploymentLogs: [] as IDeploymentLogsResponse[],
        loading: true,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_SUCCESS:
      return {
        ...state,
        deploymentLogs: action.data as IDeploymentLogsResponse[],
        loading: false,
      };
    case BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case BuildDeployActionTypes.GET_DEPLOY_INPUTS:
      return {
        ...state,
        deployValues: action.data as IDeployValueState,
      };

    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };

    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_SUCCESS:
      return {
        ...state,
        multiCloudYamlContent: action.data as any,
        loading: false,
      };
    case BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
