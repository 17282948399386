import { CloudServiceProvider } from '../../AppConstants';
import { LandingZoneStatus } from '../../libs/models/BuildDeployModels';

export const OverviewHeaderLabels = {
  selectAccount: `Select Account`,
  selectProject: `Select Project`,
  adminPanelTitle: 'Welcome Admin!',
  adminPanelLogout: 'Logout from DCA',
  profileSettings: 'My Profile Settings',
  userManagement: 'User Management',
  projectManagement: 'Account & Project Management',
};
