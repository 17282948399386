export const NotificationDrawerLabels = {
  environment: 'Environment',
  account: 'Account',
  project: 'Project',
  username: 'Username',
  dateTime: 'Date & Time',
  status: 'Status',
  dateTimeLabel: 'dateTime',
  landingZoneName: 'landingZoneName',
};
