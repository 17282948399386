import { IEmailNotification, ILandingZoneNotification } from '../../models/NotificationModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const tangraServiceType = ServiceType.None;

export class NotificationService {
  private readonly service: ApiServiceBase = new ApiService(tangraServiceType, ServerType.tangra); // Initializing the local backend service layer

  public getLandingZoneNotifications():
    | Promise<ILandingZoneNotification[]>
    | ILandingZoneNotification[] {
    return this.service.get({ route: [API_ROUTE.landingZoneNotification] });
  }
  public getEmailNotifications(): Promise<IEmailNotification[]> | IEmailNotification[] {
    return this.service.get({ route: [API_ROUTE.alertNotification] });
  }
}

const notificationService = new NotificationService();
export default notificationService;
