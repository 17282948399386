import { RightCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import { UserType } from '../../libs/models/UserModels';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { routes } from '../../navigation/Routes';
import { getAuthURL, LoginLabels as LABELS } from './LoginConstants';

interface ISsoLoginProps {
  isMobile?: boolean;
  isCBO?: boolean;
}

const SSOLogin = ({ isMobile = false, isCBO = false }: ISsoLoginProps) => {
  const history = useHistory();
  const onClick = () => {
    const url = getAuthURL();
    storage.setItem(STORAGE_CONSTANTS.userType, UserType.INTERNAL);
    window.location.replace(url);
    // history.push('/dashboard')
  };

  return (
    <div>
      {/* {isCBO ? (
        <Button
          className={isMobile ? `login-mobile-btn-sso` : `login-btn-sso`}
          onClick={() => history.push(routes.cboSummary)}
        >
          <div className="login-btn-sso-icon-name">
            <div className="login-btn-sso-icon">
              <img alt="sso-icon" src={`${process.env.PUBLIC_URL}/ssoicon.svg`} />
            </div>
            <Typography className="login-btn-sso-name">{LABELS.ssoLoginText}</Typography>
          </div>
          <RightCircleOutlined />
        </Button>
      ) : ( */}
      <Button className={isMobile ? `login-mobile-btn` : `login-btn`} onClick={onClick}>
        {LABELS.ssoLoginText}
      </Button>
    </div>
  );
};

export default SSOLogin;
