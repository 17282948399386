import { Reducer } from 'redux';
import { DashboardCardClassNames } from '../../../containers/Dashboard/DashboardConstants';
import { DiscoverAssessMenu } from '../../../containers/DiscoverAssess/DiscoverAssessConstants';
import {
  AssessJourneySection,
  IAccountDetails,
  IAccountDetailsResponse,
  IAssessmentStateResponse,
  IProjectOwnersResponse,
} from '../../models/DashboardModels';
import { DashboardAction, DashboardActionTypes } from '../actions/DashboardActions';
import DashboardState from '../state/DashboardState';

export const initialState: DashboardState = {
  accountsProjectsMapping: [],
  enabledDiscoverAssessItems: [],
  error: undefined,
  loading: false,
  activeDashboardTitle: DashboardCardClassNames.SUMMARY,
  projectOwners: [],
  assessmentsState: {
    devsecops: false,
    governance: false,
    health: false,
    devops: false,
    strategicGovernance: false,
    financialGovernance: false,
    operationalGovernance: false,
  },
  selectedAssessTab: DiscoverAssessMenu[0].menuName ,
};

export const dashboardReducer: Reducer<DashboardState> = (
  state = initialState,
  action: DashboardAction,
) => {
  switch (action.type) {
    case DashboardActionTypes.GET_ALL_ACCOUNTS_START:
      return {
        ...state,
        loading: true,
      };

    case DashboardActionTypes.GET_ALL_ACCOUNTS_SUCCESS: {
      const allAccounts = action.data as IAccountDetailsResponse[];
      const accountDetails: IAccountDetails[] = allAccounts.map(
        ({ accountId, accountName, assetType, projects }) => ({
          accountId,
          accountName,
          assetType,
          projects: projects.map(
            ({ projectId, projectName, createdBy, createdOn, status, applications }) => ({
              projectId,
              projectName,
              createdBy,
              createdOn,
              status,
              applications,
            }),
          ),
        }),
      );
      return {
        ...state,
        accountsProjectsMapping: accountDetails,
        error: undefined,
        loading: false,
      };
    }

    case DashboardActionTypes.GET_ALL_ACCOUNTS_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case DashboardActionTypes.GET_AUDIT_HISTORY_START:
      return {
        ...state,
        loading: true,
      };

    case DashboardActionTypes.GET_AUDIT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DashboardActionTypes.GET_AUDIT_HISTORY_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case DashboardActionTypes.SAVE_AUDIT_HISTORY_START:
      return {
        ...state,
        loading: true,
      };

    case DashboardActionTypes.SAVE_AUDIT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DashboardActionTypes.SAVE_AUDIT_HISTORY_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case DashboardActionTypes.SET_DISCOVER_ASSESS_LIST:
      return {
        ...state,
        enabledDiscoverAssessItems: action.data as number[],
      };

    case DashboardActionTypes.SET_ACTIVE_DASHBOARD_TITLE_NAME:
      return {
        ...state,
        activeDashboardTitle: action.data as string,
      };

    case DashboardActionTypes.GET_PROJECT_OWNERS_START:
      return {
        ...state,
        loading: true,
      };

    case DashboardActionTypes.GET_PROJECT_OWNERS_SUCCESS:
      return {
        ...state,
        projectOwners: action.data as IProjectOwnersResponse[],
        loading: false,
      };

    case DashboardActionTypes.GET_PROJECT_OWNERS_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case DashboardActionTypes.GET_ASSESSMENTS_STATE_START:
      return {
        ...state,
        loading: true,
      };

    case DashboardActionTypes.GET_ASSESSMENTS_STATE_SUCCESS:
      return {
        ...state,
        assessmentsState: action.data as IAssessmentStateResponse,
        loading: false,
      };

    case DashboardActionTypes.GET_ASSESSMENTS_STATE_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };

    case DashboardActionTypes.SET_SELECTED_ASSESS_TAB:
      return {
        ...state,
        selectedAssessTab: action.data as AssessJourneySection,
      };

    default:
      return state;
  }
};
