/* eslint-disable max-lines */
import { DashboardHeaderConstants } from '../../components/DashboardHeader/DashboardHeaderConstants';
import { routes } from '../../navigation/Routes';

export const SidebarLabels = {
  overview: 'Cloud Journey',
  logout: 'Logout',
  asset: 'Asset Authorization',
  help: 'Help',
  operations: 'Deployments',
  analytics: 'Web Analytics',
  documentation: 'Documentation',
  keyContacts: 'Key Contacts',
  quickLinks: 'Quick Links',
  feedback: 'Feedback',
  feedbackResponse: 'Feedback',
  adminPanelLogout: 'Logout',
  profileSettings: 'My Profile Settings',
  userManagement: 'User Management',
  projectManagement: 'Account & Project Management',
  adminPanelTitle: 'Welcome Admin!',
  assessment: 'Assessments',
  discover: 'Discover & Access',
  build: 'Build & Deploy',
  security: 'Security & Governance',
  mobileViewNotFunctional:
    'This is a view-only app. For better experience, try the desktop version',
  overallSummary: 'Overall Summary',
  industrySolutions: 'Industry Solutions',
  operationalTransformation: 'Operational Transformation',
  cloudEngineering: 'Cloud Engineering',
  migrationMordernization: 'Migration & Mordernization',
  techOperation: 'Tech Operation',
  configureCboJourney: 'Configure Your CBO Journey',
};

export enum SideBarKey {
  DASHBOAARD = 'dashboard',
  BUSINESS_CASES = 'businesscases',
  REPORTS = 'reports',
  MODULE_NO_1 = 'module_no_1',
  SETTINGS = 'Settings',
  OPERATIONS = 'operations',
  ASSET_AUTHORISATION = 'assetAuthorisation',
  QUICKLINKS = 'quickLinks',
  WEB_ANALYTICS = 'webAnalytics',
  DOCUMENTATION = 'documentation',
  KEYCONTACTS = 'keyContacts',
  HELP = 'help',
  FEEDBACK = 'giveFeedback',
  FEEDBACK_RESPONSE = 'feedbackResponse',
  ACCOUNT_MANAGEMENT = 'dashboard/accountManagement',
  USER_MANAGEMENT = 'dashboard/userManagement',
  ASSESSMENT = 'assessment',
  FEEDBACK_FORM_ADMIN = 'feedback',
  DISCOVERASSESS = 'Discover & Assess',
  OPERATE = 'Operate',
  MIGRATE_MODERNIZE = 'Migrate & Modernize',
  CLOUD_SUMMARY = 'Foundation Summary',
  BUILD = 'Build, Test & Deploy',
  SECURITY = 'Security & Governance',
  LOGOUT = 'logOut',
  CBO_SUMMARY = 'cboSummary',
  CBO_CONFIGURE_JOURNEY = 'cboConfigureJourney',
  CBO_PAGES = 'CBO pages',
  INDUSTRY_SOLUTIONS = 'Industry Solutions',
  OPERATIONAL_TRANSFORMATION = 'Operational Transformantion',
  CLOUD_ENGINEERING = 'Cloud Engineering',
  MIGRATE_MODERNIZATION = 'Migrate Modernization',
  TECH_OPERATION = 'Tech Operation',
  CONFIGURE_CBO_JOURNEY = 'Configure Your CBO Journey',
  BUSINESS_INTELLIGENCE = 'businessintelligence',
  UNDERWRITER = 'underwriter',
  CHIEF_UNDERWRITER = 'chiefunderwriter',
}

export interface ISidebarItem {
  key: SideBarKey;
  path: string;
  label: string;
  icon: string;
  activeIcon: string;
  disable: boolean;
  activeTabTitle?: string;
  type: ApplicationUserTypes;
  parent?: SideBarKey;
  hasChildren?: boolean;
  level?: number;
  menuSection?: menuSections;
  subMenuItems?: string[];
}

export enum ApplicationUserTypes {
  CBO = 'CBO',
  TANGRA = 'TANGRA',
}

export enum menuSections {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const MENU_ITEMS: ISidebarItem[] = [
  {
    key: SideBarKey.UNDERWRITER,
    path: routes.underwriter,
    label: DashboardHeaderConstants.underwriter,
    icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
    activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
    level: 1,
    menuSection: menuSections.TOP,
    type: ApplicationUserTypes.TANGRA,
    disable: false,
  },
  {
    key: SideBarKey.BUSINESS_INTELLIGENCE,
    path: routes.businessIntelligence,
    // show insights dashboard which was left in progress
    label: DashboardHeaderConstants.businessIntelligence,
    icon: `/config-icons/sidebar/bus-case-white.svg`,
    activeIcon: `/config-icons/sidebar/bus-case-black.svg`,
    level: 1,
    menuSection: menuSections.TOP,
    type: ApplicationUserTypes.TANGRA,
    disable: false,
  },
  {
    key: SideBarKey.CHIEF_UNDERWRITER,
    path: routes.dashboard,
    label: DashboardHeaderConstants.chiefUnderwriter,
    icon: `/config-icons/sidebar/reports-white.svg`,
    activeIcon: `/config-icons/sidebar/reports-black.svg`,
    level: 1,
    menuSection: menuSections.TOP,
    type: ApplicationUserTypes.TANGRA,
    disable: false,
  },
  {
    key: SideBarKey.SETTINGS,
    path: routes.dashboard,
    label: DashboardHeaderConstants.settings,
    icon: `/config-icons/sidebar/settings-white.svg`,
    activeIcon: `/config-icons/sidebar/settings-black.svg`,
    level: 1,
    menuSection: menuSections.BOTTOM,
    type: ApplicationUserTypes.TANGRA,
    disable: false,
  },
  // {
  //   key: SideBarKey.CLOUD_SUMMARY,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.cloudSummary,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   activeTabTitle: DashboardHeaderConstants.cloudSummary,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.DISCOVERASSESS,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.discoverAccess,
  //   icon: `/config-icons/sidebar/discover_w.svg`,
  //   activeIcon: `/config-icons/sidebar/discover_b.svg`,
  //   activeTabTitle: DashboardHeaderConstants.discoverAccess,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.BUILD,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.buildDeploy,
  //   icon: `/config-icons/sidebar/build_w.svg`,
  //   activeIcon: `/config-icons/sidebar/Build_b.svg`,
  //   activeTabTitle: DashboardHeaderConstants.buildDeploy,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.MIGRATE_MODERNIZE,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.migrateModernize,
  //   icon: `/config-icons/sidebar/migrate_w.svg`,
  //   activeIcon: `/config-icons/sidebar/migrate_b.svg`,
  //   activeTabTitle: DashboardHeaderConstants.migrateModernize,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.OPERATE,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.operate,
  //   icon: `/config-icons/sidebar/operate_w.svg`,
  //   activeIcon: `/config-icons/sidebar/operate_b.svg`,
  //   activeTabTitle: DashboardHeaderConstants.operate,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.SECURITY,
  //   path: routes.dashboard,
  //   label: DashboardHeaderConstants.securityGovernance,
  //   icon: `/config-icons/sidebar/security_w.svg`,
  //   activeIcon: `/config-icons/sidebar/security_b.svg`,
  //   activeTabTitle: DashboardHeaderConstants.securityGovernance,
  //   parent: SideBarKey.DASHBOAARD,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.ASSET_AUTHORISATION,
  //   path: routes.assetAuthorisation,
  //   label: SidebarLabels.asset,
  //   icon: `/config-icons/sidebar/asset-white.svg`,
  //   activeIcon: `/config-icons/sidebar/asset-black.svg`,
  //   level: 1,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.OPERATIONS,
  //   path: routes.operations,
  //   label: SidebarLabels.operations,
  //   icon: `/config-icons/sidebar/Operations_White.svg`,
  //   activeIcon: `/config-icons/sidebar/Operations_Black.svg`,
  //   level: 1,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.WEB_ANALYTICS,
  //   path: routes.webAnalytics,
  //   label: SidebarLabels.analytics,
  //   icon: `/config-icons/sidebar/barchart_white.svg`,
  //   activeIcon: `/config-icons/sidebar/barchart_black.svg`,
  //   level: 1,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.DOCUMENTATION,
  //   path: routes.documentation,
  //   label: SidebarLabels.documentation,
  //   icon: `/config-icons/sidebar/documentation-light.svg`,
  //   activeIcon: `/config-icons/sidebar/documentation-dark.svg`,
  //   level: 1,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.QUICKLINKS,
  //   path: routes.quickLinks,
  //   label: SidebarLabels.quickLinks,
  //   icon: `/config-icons/sidebar/.svg`,
  //   activeIcon: `/config-icons/sidebar/.svg`,
  //   level: 1,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: true,
  // },
  // {
  //   key: SideBarKey.HELP,
  //   path: routes.dashboard,
  //   label: SidebarLabels.help,
  //   icon: `/config-icons/sidebar/Help_White.svg`,
  //   activeIcon: `/config-icons/sidebar/Help_Black.svg`,
  //   level: 1,
  //   menuSection: menuSections.BOTTOM,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.KEYCONTACTS,
  //   path: routes.keyContacts,
  //   label: SidebarLabels.keyContacts,
  //   icon: `/config-icons/sidebar/contacts_white.svg`,
  //   activeIcon: `/config-icons/sidebar/contacts_black.svg`,
  //   level: 1,
  //   menuSection: menuSections.BOTTOM,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.FEEDBACK_FORM_ADMIN,
  //   path: routes.feedback,
  //   label: SidebarLabels.feedback,
  //   icon: `/config-icons/sidebar/feedback-response-white.svg`,
  //   activeIcon: `/config-icons/sidebar/feedback-response-black.svg`,
  //   level: 1,
  //   menuSection: menuSections.BOTTOM,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.FEEDBACK,
  //   path: routes.giveFeedback,
  //   label: SidebarLabels.feedbackResponse,
  //   icon: `/config-icons/sidebar/feedback-response-white.svg`,
  //   activeIcon: `/config-icons/sidebar/feedback-response-black.svg`,
  //   level: 1,
  //   menuSection: menuSections.BOTTOM,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.FEEDBACK_RESPONSE,
  //   path: routes.feedbackResponse,
  //   label: DashboardHeaderConstants.cloudSummary,
  //   icon: `/config-icons/sidebar/.svg`,
  //   activeIcon: `/config-icons/sidebar/.svg`,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: true,
  // },
  // {
  //   key: SideBarKey.ASSESSMENT,
  //   path: routes.assessment,
  //   label: SidebarLabels.assessment,
  //   icon: `/config-icons/sidebar/.svg`,
  //   activeIcon: `/config-icons/sidebar/.svg`,
  //   type: ApplicationUserTypes.TANGRA,
  //   disable: true,
  // },
  // {
  //   key: SideBarKey.CBO_SUMMARY,
  //   path: routes.cboSummary,
  //   label: SidebarLabels.overallSummary,
  //   icon: `/config-icons/sidebar/Cloud_White.svg`,
  //   activeIcon: `/config-icons/sidebar/Cloud_Black.svg`,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.CBO,
  //   level: 1,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.CBO_PAGES,
  //   path: routes.dashboard,
  //   label: 'Sections',
  //   icon: `/config-icons/sidebar/operate_w.svg`,
  //   activeIcon: `/config-icons/sidebar/operate_b.svg`,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.CBO,
  //   level: 1,
  //   hasChildren: true,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.INDUSTRY_SOLUTIONS,
  //   path: routes.dashboard,
  //   label: SidebarLabels.industrySolutions,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   parent: SideBarKey.CBO_PAGES,
  //   type: ApplicationUserTypes.CBO,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.OPERATIONAL_TRANSFORMATION,
  //   path: routes.dashboard,
  //   label: SidebarLabels.operationalTransformation,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   parent: SideBarKey.CBO_PAGES,
  //   type: ApplicationUserTypes.CBO,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.CLOUD_ENGINEERING,
  //   path: routes.dashboard,
  //   label: SidebarLabels.cloudEngineering,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   parent: SideBarKey.CBO_PAGES,
  //   type: ApplicationUserTypes.CBO,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.MIGRATE_MODERNIZATION,
  //   path: routes.dashboard,
  //   label: SidebarLabels.migrationMordernization,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   parent: SideBarKey.CBO_PAGES,
  //   type: ApplicationUserTypes.CBO,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.TECH_OPERATION,
  //   path: routes.dashboard,
  //   label: SidebarLabels.techOperation,
  //   icon: `/config-icons/sidebar/cloud-summary-icon-white.svg`,
  //   activeIcon: `/config-icons/sidebar/cloud-summary-icon.svg`,
  //   parent: SideBarKey.CBO_PAGES,
  //   type: ApplicationUserTypes.CBO,
  //   disable: false,
  // },
  // {
  //   key: SideBarKey.CONFIGURE_CBO_JOURNEY,
  //   path: routes.cboConfigureJourney,
  //   label: SidebarLabels.configureCboJourney,
  //   icon: `/config-icons/sidebar/Operations_White.svg`,
  //   activeIcon: `/config-icons/sidebar/Operations_Black.svg`,
  //   menuSection: menuSections.TOP,
  //   type: ApplicationUserTypes.CBO,
  //   level: 1,
  //   disable: false,
  // },
];
