import React from 'react';
import { Drawer, Button } from 'antd';
import './ContractsDrawer.less';
import { ContractStatus, IContractsData } from '../MNACIDashboardNew';
import NewCaseFormSubmission from '../../../components/NewCaseFormSubmission/NewCaseFormSubmission';
import { formItems } from '../ContractsFormConstant';
import { storage, STORAGE_CONSTANTS } from '../../../libs/services/LocalStorage';

interface IContractsDrawer {
  visible: boolean;
  onClose: (value: any) => void;
  contractDetails: IContractsData;
}
const ContractsDrawer = ({ visible, onClose, contractDetails }: IContractsDrawer) => {
  const { status, contract_name, date } = contractDetails;
  const email = storage.getItem(STORAGE_CONSTANTS.email);
  const getDrawerTitle = () => {
    return (
      <div className="drawer-title">
        <div className="left-side">
          <p>
            {contract_name}
            <span className={`status ${status.toLowerCase()}`}>
              {status === ContractStatus.SUCCESS || status === ContractStatus.EXTRACTED
                ? ContractStatus.EXTRACTED.toUpperCase()
                : ContractStatus.ERROR.toUpperCase()}
            </span>
          </p>
        </div>
        <div className="right-side">
          <p>
            {date ? <>Created On<span>{date}</span></> : <></>}
          </p>
          <p>
            {email ? <>Created By<span>{email?.split('@')[0] || ''}</span></> : <></>}
          </p>
          <Button icon={<img src={`${process.env.PUBLIC_URL}/config-icons/edit.svg`} />}>
            Edit
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Drawer
      visible={visible}
      placement={'right'}
      width={'100%'}
      closable={true}
      onClose={onClose}
      title={getDrawerTitle()}
      className="contracts-drawer"
    >
      <NewCaseFormSubmission
        // activeForm={contractCards?.find((card) => card.id === selectedCard)?.title || ''}
        formItems1={formItems}
        formDetails={contractDetails}
      />
    </Drawer>
  );
};

export default ContractsDrawer;
