import { Reducer } from 'redux';
import { SideBarKey } from '../../../containers/Sidebar/SidebarConstants';
import { SidebarAction, SidebarActionTypes } from '../actions/SidebarActions';
import SidebarState from '../state/SidebarState';

export const initialState: SidebarState = {
  error: '',
  loading: false,
  isExpanded: false,
  hiddenSidebar: false,
  activeKey: SideBarKey.UNDERWRITER,
  logout: false,
};

export const sidebarReducer: Reducer<SidebarState> = (
  state = initialState,
  action: SidebarAction,
) => {
  switch (action.type) {
    case SidebarActionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        isExpanded: (action.data as boolean) || false,
      };

    case SidebarActionTypes.TOGGLE_HIDDEN_SIDEBAR:
      return {
        ...state,
        hiddenSidebar: (action.data as boolean) || false,
      };

    case SidebarActionTypes.SET_SIDEBAR_KEY:
      return {
        ...state,
        activeKey: action.data as string,
      };

    case SidebarActionTypes.TOGGLE_LOGOUT:
      return {
        ...state,
        logout: (action.data as boolean) || false,
      };

    default:
      return state;
  }
};
