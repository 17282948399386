import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { IShowToastPayload } from '../../libs/models/ToastModels';
import { ToastActions } from '../../libs/store/actions/ToastActions';
import { NotificationTypes } from './NotificationsConstants';

export const Toast = (description: string, message: string, type: NotificationTypes) => {
  const dispatch = useDispatch();

  const dispatcher = {
    hideToast: () => dispatch(ToastActions.hideToast()),
  };

  const hideToast = () => dispatcher.hideToast();

  notification[type]({
    key: 'toast',
    message: message,
    description: description,
    onClose: hideToast,
  });
};
