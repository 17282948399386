import { Reducer } from 'redux';
import { IUserResponse } from '../../models/UserModels';
import { UserAction, UserActionTypes } from '../actions/UserActions';
import PersonaState from '../state/PersonaState';
import { IDataAugmentationResponse, IStageResponse } from '../../models/PersonaModels';
import { PersonaAction, PersonaActionTypes } from '../actions/PersonaActions';
// import IPersonaState from '../state/PersonaState';

export const initialState: PersonaState = {
  error: '',
  loading: false,
  stageDetails: undefined,
  augmentationData:undefined,
};

export const personaReducer: Reducer<PersonaState> = (state = initialState, action: PersonaAction) => {
  switch (action.type) {
    case PersonaActionTypes.DATA_INGESTION_STAGE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PersonaActionTypes.DATA_INGESTION_STAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        stageDetails: action.data as IStageResponse[],
      };
    case PersonaActionTypes.DATA_INGESTION_STAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    // data augmentation actions
    case PersonaActionTypes.DATA_AUGMENTATION_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PersonaActionTypes.DATA_AUGMENTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        augmentationData: action.data as IDataAugmentationResponse[],
      };
    case PersonaActionTypes.DATA_AUGMENTATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
