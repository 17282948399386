import React from 'react';
import './DataEnrichmentTab.less';

const DataEnrichmentTab = () => {
  return (
    <div className="data-enrichment-container">
      <div className="ench-drawer-header">
        <div className="drawer-title">Toyota of Richardson Inc</div>
        <div className="drawer-details">
          <div className="score-details">D&B PRESCREEN SCORE</div>
          <div>
            <img
              className="score-icon"
              src={`${process.env.PUBLIC_URL}/hubSub/icons/data-ench-low.svg`}
              alt="Low Icon"
            />
          </div>
        </div>
      </div>
      <div className="ench-drawer-body">
        <div className="ench-drawer-body-title">
          Corporate higlights{' '}
          <sub className="ml-1 thubmnail-style">powered by Dun & Bradstreet</sub>
        </div>

        <div className="ench-drawer-body-content">
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-1.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">926566654</div>
                <div className="content-child-container-body-subtitle">Parent DUNS number</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-2.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">926566654</div>
                <div className="content-child-container-body-subtitle">
                  GLOBAL ULTIMATE DUNS number
                </div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-2.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">926566654</div>
                <div className="content-child-container-body-subtitle">
                  Domestic ULTIMATE DUNS number
                </div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-4.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">2,281</div>
                <div className="content-child-container-body-subtitle">
                  PLANT/FACILITY SIZE (SQ. FT.)
                </div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-5.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">1992</div>
                <div className="content-child-container-body-subtitle">Year founded</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-2.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">
                  61 <span className="low-risk">1 Low Risk (59)</span>{' '}
                </div>
                <div className="content-child-container-body-subtitle">D&B Credit score</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-7.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">13</div>
                <div className="content-child-container-body-subtitle">Employees</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-5.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">Corporation</div>
                <div className="content-child-container-body-subtitle">D&B LEGAL STATUS TYPE</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-2.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">
                  Motor Vehicle and Parts Dealers
                </div>
                <div className="content-child-container-body-subtitle">Industry</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-10.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">US $308.50K</div>
                <div className="content-child-container-body-subtitle">Sales</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-10.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">USD</div>
                <div className="content-child-container-body-subtitle">Reporting currency</div>
              </div>
            </div>
          </div>
          <div className="content-child">
            <div className="content-child-container">
              <div>
                <img
                  className="content-child-container-icon"
                  src={`${process.env.PUBLIC_URL}/hubSub/icons/child-10.svg`}
                  alt="Details Icon"
                />
              </div>
              <div className="content-child-container-body">
                <div className="content-child-container-body-title">US $0.75M</div>
                <div className="content-child-container-body-subtitle">Total assets</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ench-drawer-footer">
        <div className="ench-drawer-footer-left">
          {/* <div className="ench-drawer-footer-left-title">#1 Downtown Street Lane</div> */}
          <div className="ench-drawer-footer-left-subtitle">3D Flypast</div>
          <div>
            <img
              className="ench-drawer-footer-left-icon"
              src={`${process.env.PUBLIC_URL}/hubSub/icons/dummy-details-data-enrch.svg`}
              alt="Low Icon"
            />
          </div>
        </div>
        <div className="ench-drawer-footer-right">
          <div className="ench-drawer-footer-right-top">
            <div className="content-child">
              <div className="content-child-container">
                <div>
                  <img
                    className="content-child-container-icon"
                    src={`${process.env.PUBLIC_URL}/hubSub/icons/foot-child-1.svg`}
                    alt="Details Icon"
                  />
                </div>
                <div className="content-child-container-body">
                  <div className="content-child-container-body-title">
                    1221 N Central Expy Richardson, Texas, 75080-4693 United States
                  </div>
                  <div className="content-child-container-body-subtitle">Location</div>
                </div>
              </div>
            </div>
            <div className="content-child">
              <div className="content-child-container">
                <div>
                  <img
                    className="content-child-container-icon"
                    src={`${process.env.PUBLIC_URL}/hubSub/icons/foot-child-1.svg`}
                    alt="Details Icon"
                  />
                </div>
                <div className="content-child-container-body">
                  <div className="content-child-container-body-title">
                    Motor Vehicle and Parts Dealers
                  </div>
                  <div className="content-child-container-body-subtitle">Industry</div>
                </div>
              </div>
            </div>
            <div className="content-child">
              <div className="content-child-container">
                <div>
                  <img
                    className="content-child-container-icon"
                    src={`${process.env.PUBLIC_URL}/hubSub/icons/foot-child-1.svg`}
                    alt="Details Icon"
                  />
                </div>
                <div className="content-child-container-body">
                  <div className="content-child-container-body-title">10</div>
                  <div className="content-child-container-body-subtitle">Employees</div>
                </div>
              </div>
            </div>
          </div>
          <div className="ench-drawer-footer-right-bottom">
            <div className="footer-child">
              <div className="content-container">
                <div className="title">
                  75209 Environmental Risks{' '}
                  <sub className="ml-1 thubmnail-style">powered by Risk Factor</sub>
                </div>
                <div className="details">
                  <div className="details-child">
                    <div className="subtitle">Flood</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-1.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                  <div className="details-child">
                    <div className="subtitle">Fire</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-2.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                  <div className="details-child">
                    <div className="subtitle">Heat</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-3.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                  <div className="details-child">
                    <div className="subtitle">Wind</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-4.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-child">
              <div className="content-container">
                <div className="title">75209 PROPERTY CRIMES</div>
                <div className="details">
                  <div className="details-child">
                    <div className="subtitle">Burglary</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-4.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                  <div className="details-child">
                    <div className="subtitle">Theft</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-5.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                  <div className="details-child">
                    <div className="subtitle">Vehicle</div>
                    <div>
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/hubSub/icons/de-tag-5.svg`}
                        alt="Details Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataEnrichmentTab;
