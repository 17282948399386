import { Reducer } from 'redux';
import { IUserResponse } from '../../models/UserModels'
import { UserAction, UserActionTypes } from '../actions/UserActions';
import UserState from '../state/UserState';

export const initialState: UserState = {
  error: '',
  loading: false,
  userDetails: undefined,
};

export const userReducer: Reducer<UserState> = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionTypes.USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserActionTypes.USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        userDetails: action.data as IUserResponse
      };
    case UserActionTypes.USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
