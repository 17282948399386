import {
  ICompliancePolicies,
  IIndustryCompliancePolicies,
} from '../../models/SecurityGovernanceModels';
import { IStoreAction } from '../StoreHelper';
export enum SecurityGovernanceActionsTypes {
  GET_COMPLIANCE_DETAILS_STARTED = 'GET_COMPLIANCE_DETAILS_STARTED',
  GET_COMPLIANCE_DETAILS_SUCCESS = 'GET_COMPLIANCE_DETAILS_SUCCESS',
  GET_COMPLIANCE_DETAILS_ERROR = 'GET_COMPLIANCE_DETAILS_ERROR',
  GET_INDUSTRY_COMPLIANCE_DETAILS_STARTED = 'GET_INDUSTRY_COMPLIANCE_DETAILS_STARTED',
  GET_INDUSTRY_COMPLIANCE_DETAILS_SUCCESS = 'GET_INDUSTRY_COMPLIANCE_DETAILS_SUCCESS',
  GET_INDUSTRY_COMPLIANCE_DETAILS_ERROR = 'GET_INDUSTRY_COMPLIANCE_DETAILS_ERROR',
  GET_COMPLIANCE_SUMMARY_STARTED = 'GET_COMPLIANCE_SUMMARY_STARTED',
  GET_COMPLIANCE_SUMMARY_SUCCESS = 'GET_COMPLIANCE_SUMMARY_SUCCESS',
  GET_COMPLIANCE_SUMMARY_ERROR = 'GET_COMPLIANCE_SUMMARY_ERROR',
}

export type SecurityGovernanceActionPayload =
  | string
  | ICompliancePolicies
  | Error
  | IIndustryCompliancePolicies;

export type SecurityGovernanceAction = IStoreAction<
  SecurityGovernanceActionsTypes,
  SecurityGovernanceActionPayload
>;
export class SecurityGovernanceActions {
  public static getComplianceDetailsStarted(data: string): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_STARTED,
      data,
    };
  }
  public static getComplianceDetailsSuccess(data: ICompliancePolicies): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_SUCCESS,
      data,
    };
  }
  public static getComplianceDetailsError(data: Error): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_ERROR,
      data,
    };
  }
  public static getIndustryComplianceDetailsStarted(data: string): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_STARTED,
      data,
    };
  }
  public static getIndustryComplianceDetailsSuccess(
    data: IIndustryCompliancePolicies,
  ): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_SUCCESS,
      data,
    };
  }
  public static getIndustryComplianceDetailsError(data: Error): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_ERROR,
      data,
    };
  }
  public static getComplianceSummaryStarted(data: string): SecurityGovernanceAction {
    //Takes ProjectId as Arguments
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_STARTED,
      data,
    };
  }
  public static getComplianceSummarySuccess(data: ICompliancePolicies): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_SUCCESS,
      data,
    };
  }
  public static getComplianceSummaryError(data: Error): SecurityGovernanceAction {
    return {
      type: SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_ERROR,
      data,
    };
  }
}
