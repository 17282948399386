import { Button, Col, Dropdown, Input, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import DownIcon from '../../../assets/icons/dropdown-down-arrow.svg';
import DownloadIcon from '../../../assets/icons/download.svg';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import ExportToExcel from '../../ExportToExcel/ExportToExcel';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import { MetadataGuardrailsActions } from '../../../libs/store/actions/MetadataGuardrailsActions';
import {
  IGuardrailPayload,
  IMetadataObj,
  IMetadataPayload,
  MetadataGuardrailMethod,
} from '../../../libs/models/MetadataGuardrailModels';

export interface IReduceObj {
  [key: string]: string | boolean | number | any;
}
const tableHeaders = [
  {
    title: 'Metadata Field',
    dataIndex: 'Metadata Field',
    key: 'Metadata Field',
  },
  {
    title: 'Field Description',
    dataIndex: 'Field Description',
    key: 'Field Description',
  },
  {
    title: 'Column Name',
    dataIndex: 'Column Name',
    key: 'Column Name',
  },
  {
    title: 'Data Type',
    dataIndex: 'Data Type (Big Query)',
    key: 'Data Type (Big Query)',
  },
  {
    title: 'Sample Value',
    dataIndex: 'Sample Value',
    key: 'Sample Value',
  },
];

const Metadata = () => {
  const [selectedFormType, setSelectedFormType] = useState('');
  const [tableData, setTableData] = useState<IMetadataObj[]>([]);

  const dispatch = useDispatch();
  const dispatcher = {
    getMetadataDetails: (payload: IMetadataPayload) =>
      dispatch(MetadataGuardrailsActions.getMetadataDetailsStart(payload)),
    getFormList: (payload: IGuardrailPayload) =>
      dispatch(MetadataGuardrailsActions.getMetadataFormListStart(payload)),
  };

  const { metadataDetails, formTypes } = useAppSelector((state: IApplicationState) => ({
    metadataDetails: state.metadataGuardrailDetails.metadataDetails,
    formTypes: state.metadataGuardrailDetails.metadataFormlist,
  }));

  React.useEffect(() => {
    dispatcher.getFormList({
      method: MetadataGuardrailMethod.FORM_LIST,
    });
  }, []);

  React.useEffect(() => {
    selectedFormType &&
      dispatcher.getMetadataDetails({
        method: MetadataGuardrailMethod.METADATA_VIEW,
        form_type: selectedFormType.split('-')[0].trim(),
      });
  }, [selectedFormType]);

  React.useEffect(() => {
    selectedFormType && metadataDetails && setTableData(metadataDetails);
  }, [metadataDetails]);

  const overlay = (
    <DropdownMenu
      keys={formTypes || []}
      selectedKey={selectedFormType}
      onClick={setSelectedFormType}
    />
  );
  const handleDownload = () => {
    const getColumnTitle = (key: string) =>
      tableHeaders.filter((column) => column.key === key)?.[0]?.title;
    const excelFileData = tableData?.reduce((acc, eachRow) => {
      const obj: IReduceObj = {};
      Object.entries(eachRow).forEach(([key, value]) => {
        if (key !== 'key' && key !== 'Data Type (processor)') {
          const title = getColumnTitle(key);
          obj[title] = value;
        }
      });
      acc.push(obj);
      return acc;
    }, [] as IReduceObj[]);
    excelFileData && ExportToExcel(excelFileData as [], 'Metadata');
  };

  return (
    <Row className="metadata-content">
      <Col span={24} className="d-flex justify-content-between search-filter-download-container">
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          //   getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          className=""
        >
          <Button className="dropdown-btn">
            <Typography className="select-button-text">{selectedFormType}</Typography>
            <img src={DownIcon} className="select-button-down-icon" alt="Dropdown Icon" />
          </Button>
        </Dropdown>
        <div className="d-flex">
          <Button
            className="download-btn mr-1"
            icon={<img className="mr-0-dot-5" src={DownloadIcon} alt="" />}
            onClick={handleDownload}
          >
            Download All
          </Button>
          <div className="search-contacts-input-container">
            <Input
              placeholder="Search column names"
              onChange={(e) => {
                metadataDetails &&
                  setTableData(
                    metadataDetails.filter((row) =>
                      row['Column Name'].toLowerCase().includes(e.target.value.toLowerCase()),
                    ),
                  );
              }}
              className="search-contacts-input"
              prefix={<SearchOutlined className="search-icon" />}
            />
          </div>
        </div>
      </Col>
      <Col className="table" span={24}>
        <Table
          columns={tableHeaders}
          dataSource={tableData}
          pagination={{
            pageSizeOptions: [5, 10, 15],
            showSizeChanger: true,
            defaultPageSize: 5,
          }}
        />
      </Col>
    </Row>
  );
};

export default Metadata;
