import { Reducer } from 'redux';
import {
    IUploadThemePayload,
    IUploadThemeResponse
} from '../../models/ThemeModel';
import { ThemeAction, ThemeActions, ThemeActionTypes } from '../actions/ThemeSettingsActions';
import  { IThemeState  } from '../state/AnalyticsState';


export const initialState: IThemeState = {
    error: '',
    loading: false,
    clientId : "",
    jsonFile : false
};

export const ThemeSettingReducer: Reducer<IThemeState> = (
  state = initialState,
  action: ThemeAction,
) => {
  switch (action.type) {
    case ThemeActionTypes.POST_THEME_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ThemeActionTypes.POST_THEME_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        themeData: action.data as IUploadThemeResponse,
      };
    case ThemeActionTypes.POST_THEME_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    
    default:
      return state;
  }
};
