import { IStoreAction } from '../../StoreHelper';

export enum HitsAnalyticsActionTypes {
  GET_NEW_VS_RETURNING_USER_DATA_START = 'GET_NEW_VS_RETURNING_USER_DATA_START',
  GET_NEW_VS_RETURNING_USER_DATA_SUCCESS = 'GET_NEW_VS_RETURNING_USER_DATA_SUCCESS',
  GET_NEW_VS_RETURNING_USER_DATA_ERROR = 'GET_NEW_VS_RETURNING_USER_DATA_ERROR',
  GET_USAGE_LEVEL_DATA_START = 'GET_USAGE_LEVEL_DATA_START',
  GET_USAGE_LEVEL_DATA_SUCCESS = 'GET_USAGE_LEVEL_DATA_SUCCESS',
  GET_USAGE_LEVEL_DATA_ERROR = 'GET_USAGE_LEVEL_DATA_ERROR',
  GET_CLOUD_STATUS_DATA_START = 'GET_CLOUD_STATUS_DATA_START',
  GET_CLOUD_STATUS_DATA_SUCCESS = 'GET_CLOUD_STATUS_DATA_SUCCESS',
  GET_CLOUD_STATUS_DATA_ERROR = 'GET_CLOUD_STATUS_DATA_ERROR',
  GET_ACTIVE_USERS_DATA_START = 'GET_ACTIVE_USERS_DATA_START',
  GET_ACTIVE_USERS_DATA_SUCCESS = 'GET_ACTIVE_USERS_DATA_SUCCESS',
  GET_ACTIVE_USERS_DATA_ERROR = 'GET_ACTIVE_USERS_DATA_ERROR',
  GET_USERS_VISITS_DATA_START = 'GET_ACTIVE_USERS_DATA_START',
  GET_USERS_VISITS_DATA_SUCCESS = 'GET_USERS_VISITS_DATA_SUCCESS',
  GET_USERS_VISITS_DATA_ERROR = 'GET_USERS_VISITS_DATA_ERROR',
  GET_PAGE_VIEWS_DATA_START = 'GET_PAGE_VIEWS_DATA_START',
  GET_PAGE_VIEWS_DATA_SUCCESS = 'GET_PAGE_VIEWS_DATA_SUCCESS',
  GET_PAGE_VIEWS_DATA_ERROR = 'GET_PAGE_VIEWS_DATA_ERROR',
  GET_AVG_SESSION_TIME_DATA_START = 'GET_AVG_SESSION_TIME_DATA_START',
  GET_AVG_SESSION_TIME_DATA_SUCCESS = 'GET_AVG_SESSION_TIME_DATA_SUCCESS',
  GET_AVG_SESSION_TIME_DATA_ERROR = 'GET_AVG_SESSION_TIME_DATA_ERROR',
}

export interface IAnalyticsPieChartResponse {
  name: number;
  value: number;
}

export interface IChartValues {
  x: string;
  y: number;
}

export interface IAnalyticsAreaChartResponse {
  chartValues: IChartValues[];
  totalCount: number;
}

export type HitsAnalyticsActionPayload =
  | Error
  | string
  | IAnalyticsPieChartResponse[]
  | IAnalyticsAreaChartResponse;

export type HitsAnalyticsAction = IStoreAction<
  HitsAnalyticsActionTypes,
  HitsAnalyticsActionPayload
>;

export class HitsAnalyticsActions {
  public static getNewVsReturningUsersStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_START,
    };
  }
  public static getNewVsReturningUsersSuccess(
    data: IAnalyticsPieChartResponse[],
  ): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_SUCCESS,
      data,
    };
  }
  public static getNewVsReturningUsersError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR,
      data: error,
    };
  }
  public static getUsageLevelDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_START,
    };
  }
  public static getUsageLevelDataSuccess(data: IAnalyticsPieChartResponse[]): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_SUCCESS,
      data,
    };
  }
  public static getUsageLevelDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_ERROR,
      data: error,
    };
  }
  public static getCloudStatusDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_START,
    };
  }
  public static getCloudStatusDataSuccess(data: IAnalyticsPieChartResponse[]): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_SUCCESS,
      data,
    };
  }
  public static getCloudStatusDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_ERROR,
      data: error,
    };
  }
  public static getActiveUserDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_START,
    };
  }
  public static getActiveUserDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_SUCCESS,
      data,
    };
  }
  public static getActiveUserDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_ERROR,
      data: error,
    };
  }
  public static getUserVisitsDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_START,
    };
  }
  public static getUserVisitsDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_SUCCESS,
      data,
    };
  }
  public static getUserVisitsDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_ERROR,
      data: error,
    };
  }
  public static getPageViewsDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_START,
    };
  }
  public static getPageViewsDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_SUCCESS,
      data,
    };
  }
  public static getPageViewsDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_ERROR,
      data: error,
    };
  }

  public static getAvgTimeDataStart(): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_START,
    };
  }
  public static getAvgTimeDataSuccess(data: IAnalyticsAreaChartResponse): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_SUCCESS,
      data,
    };
  }
  public static getAvgTimeDataError(error: Error): HitsAnalyticsAction {
    return {
      type: HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_ERROR,
      data: error,
    };
  }
}
