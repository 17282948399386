import { IFeedbackResponseData } from '../../../containers/FeedbackResponse/FeedbackResponseConstants';
import { IUserResponse } from '../../models/UserModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class FeedbackService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getFeedback(): Promise<IFeedbackResponseData[]> | IFeedbackResponseData[] {
    return this.service.get({ route: [`${API_ROUTE.FEED_BACK.GET_FEEDBACK}`] });
  }
  public postFeedback(body: any): Promise<string> | string {
    return this.service.post({ route: [`${API_ROUTE.FEED_BACK.POST_FEEDBACK}`] }, body);
  }
}

const feedbackService = new FeedbackService();
export default feedbackService;
