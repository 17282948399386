import { Button, Col, Row } from 'antd';
import React from 'react';
import DashboardDetails from './DashboardDetails';
import DashboardAnalytics from './DashboardAnalytics';
import Input from 'rc-input';
import { SearchOutlined } from '@ant-design/icons';
import DashboardHeader from '../DashboardHeader';
import './InsightsDashboard.less'

const InsightsDashboard = () => {
  return (
    <div className='insights-container'>
      <DashboardHeader/>
      <Row gutter={[16,16]}>
        <Col span={16} className='gutter-row'>
          <DashboardDetails />
        </Col>
        <Col span={8} className='gutter-row'>
          <DashboardAnalytics />
        </Col>
      </Row>
    </div>
  );
};

export default InsightsDashboard;
