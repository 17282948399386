import { IFeedbackResponseData } from '../../../containers/FeedbackResponse/FeedbackResponseConstants';
import { IStoreAction } from '../StoreHelper';

export enum FeedbackActionTypes {
  GET_FEEDBACK_START = 'GET_FEEDBACK_START',
  GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_ERROR = 'GET_FEEDBACK_ERROR',
  POST_FEEDBACK_START = 'POST_FEEDBACK_START',
  POST_FEEDBACK_SUCCESS = 'POST_FEEDBACK_SUCCESS',
  POST_FEEDBACK_ERROR = 'POST_FEEDBACK_ERROR',
}
export interface IPostFeedBackObj {
  rating: {
    userExperience: string;
    features: string;
    functionality: string;
  };
  featureName?: string;
  comment: string;
  feedBackType: string;
  urgency?: string;
  subject: string;
}

export interface IFeedBackObj {
  userExperience: number;
  features: number;
  functionality: number;
  comment: string;
  featureName?: string;
  subject: string;
  description: string;
}

export type FeedbackActionPayload = IFeedbackResponseData[] | IPostFeedBackObj | Error | null;

export type FeedbackAction = IStoreAction<FeedbackActionTypes, FeedbackActionPayload>;

export class FeedbackActions {
  public static getFeedbackStart(): FeedbackAction {
    return {
      type: FeedbackActionTypes.GET_FEEDBACK_START,
    };
  }
  public static getFeedbackSuccess(data: IFeedbackResponseData[]): FeedbackAction {
    return {
      type: FeedbackActionTypes.GET_FEEDBACK_SUCCESS,
      data,
    };
  }
  public static getFeedbackError(error: Error): FeedbackAction {
    return {
      type: FeedbackActionTypes.GET_FEEDBACK_ERROR,
      data: error,
    };
  }
  public static postFeedbackStart(data: IPostFeedBackObj): FeedbackAction {
    return {
      type: FeedbackActionTypes.POST_FEEDBACK_START,
      data,
    };
  }
  public static postFeedbackSuccess(): FeedbackAction {
    return {
      type: FeedbackActionTypes.POST_FEEDBACK_SUCCESS,
    };
  }
  public static postFeedbackError(error: Error): FeedbackAction {
    return {
      type: FeedbackActionTypes.POST_FEEDBACK_ERROR,
      data: error,
    };
  }
}
