import { put, takeEvery } from 'redux-saga/effects';
import {
  ICreateUserRequestBody,
  IEditUserRequestBody,
  IUserManagementObject,
} from '../../models/UserManagementModel';
import userManagementService from '../../services/UserManagementService/UserManagementService';
import {
  UserManagementAction,
  UserManagementActions,
  UserManagementActionTypes,
} from '../actions/UserManagementActions';

export function* createUserWorker(action: UserManagementAction) {
  try {
    const data = action.data as ICreateUserRequestBody;
    const response: string = (yield userManagementService.createUser(data))!;
    yield put(UserManagementActions.createUserSuccess());
    yield put(UserManagementActions.getUserStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.createUserError(error));
    }
  }
}

export function* getUserWorker(action: UserManagementAction) {
  try {
    const response: IUserManagementObject[] = (yield userManagementService.getUser())!;
    yield put(UserManagementActions.getUserSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.getUserError(error));
    }
  }
}

export function* updateUserWorker(action: UserManagementAction) {
  try {
    const data = action.data as IEditUserRequestBody;
    const response: IUserManagementObject[] = (yield userManagementService.updateUser(data))!;
    yield put(UserManagementActions.editUserSuccess());
    yield put(UserManagementActions.getUserStart());
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserManagementActions.editUserError(error));
    }
  }
}

export function* userManagementWatcher() {
  yield takeEvery(UserManagementActionTypes.CREATE_USER_STARTED, createUserWorker);
  yield takeEvery(UserManagementActionTypes.GET_USER_MANAGEMENT_STARTED, getUserWorker);
  yield takeEvery(UserManagementActionTypes.UPDATE_USER_MANAGEMENT_STARTED, updateUserWorker);
}
