import { CloudServiceProvider } from '../../../../AppConstants';
import { IStoreAction } from '../../StoreHelper';

export enum TrafficAnalyticsActionTypes {
  GET_MOST_VISITED_DATA_START = 'GET_MOST_VISITED_DATA_START',
  GET_MOST_VISITED_DATA_SUCCESS = 'GET_MOST_VISITED_DATA_SUCCESS',
  GET_MOST_VISITED_DATA_ERROR = 'GET_MOST_VISITED_DATA_ERROR',
  GET_MOST_CLOUD_DETAILS_STARTED = 'GET_MOST_CLOUD_DETAILS_STARTED',
  GET_MOST_CLOUD_DETAILS_SUCCESS = 'GET_MOST_CLOUD_DETAILS_SUCCESS',
  GET_MOST_CLOUD_DETAILS_ERROR = 'GET_MOST_CLOUD_DETAILS_ERROR',
  POST_VISITED_PAGE_ACTION_START = 'POST_VISITED_PAGE_ACTION_START',
  POST_VISITED_PAGE_ACTION_SUCCESS = 'POST_VISITED_PAGE_ACTION_SUCCESS',
  POST_VISITED_PAGE_ACTION_ERROR = 'POST_VISITED_PAGE_ACTION_ERROR',
  GET_MOST_VISITED_ARCHITECTURE_START = 'GET_MOST_VISITED_ARCHITECTURE_START',
  GET_MOST_VISITED_ARCHITECTURE_SUCCESS = 'GET_MOST_VISITED_ARCHITECTURE_SUCCESS',
  GET_MOST_VISITED_ARCHITECTURE_ERROR = 'GET_MOST_VISITED_ARCHITECTURE_ERROR',
  GET_MOST_VISITED_CLOUD_ASSESSMENT_START = 'GET_MOST_VISITED_CLOUD_ASSESSMENT_START',
  GET_MOST_VISITED_CLOUD_ASSESSMENT_SUCCESS = 'GET_MOST_VISITED_CLOUD_ASSESSMENT__SUCCESS',
  GET_MOST_VISITED_CLOUD_ASSESSMENT_ERROR = 'GET_MOST_VISITED_CLOUD_ASSESSMENT_ERROR',
}

export interface IMostVisitedSectionPayload {
  startDate: string;
}

export interface IMostVisitedArchPayload {
  architectureType: string;
}

export enum PageType {
  NORMAL = 'normal',
  FOUNDATIONAL = 'foundational',
  WORKLOAD = 'workload',
  APPLICATION = 'application',
  CLOUD_ASSESSMENT = 'cloudAssessment',
}

export enum ViewedArchType {
  GCP = 'gcp',
  AWS = 'aws',
  AZURE = 'azure',
  ETL = 'etl',
  MICROSERVICE = 'microservices',
  ANALYTICS_BI = 'analyticsBi',
  CLIENT_SERVER = 'clientServerSide',
  APPLICATION_ARCH = 'applicationArchitecture',
}

export enum DiscoverAccessVisitedType {
  GREEN_CLOUD = 'greenCloud',
  CLOUD_TECH_VALUE = 'cloudTechValue',
  OP_MODEL = 'opModel',
  CLOUD_SPEND_OPTIMIZATION = 'cloudSpendOptimization',
}

export enum SectionType {
  BUILD_DEPLOY = 'buildDeploy',
  CLOUD_SUMMARY = 'cloudSummary',
  SECURITY_GOVERNANCE = 'securityGovernance',
  OPERATIONS = 'operations',
  ASSET_AUTHORIZATION = 'assetAuthorization',
  DISCOVER_ACCESS = 'discoverAccess',
  OPERATE = 'operate',
  MIGRATE_MODERNIZE = 'migrateModernize',
  USER_MANAGEMENT = 'userManagement',
  ACCOUNT_MANAGEMENT = 'accountManagement',
  QUICKS_LINKS = 'quickLinks',
  KEY_CONTACTS = 'keyContacts',
}

export interface IMostVisitedSectionResponse {
  sectionType: SectionType;
  visitCount: number;
  rank: number;
}

export interface IMostVisitedArchResponse {
  sectionType: SectionType | ViewedArchType | DiscoverAccessVisitedType;
  visitCount: number;
}

export interface IVisitedPagePayload {
  sectionType: SectionType | string;
  pageType: PageType;
}

export interface ICloudAnalysisResponse {
  cloudProvider: CloudServiceProvider;
  value: number;
}

export type TrafficAnalyticsActionPayload =
  | Error
  | IMostVisitedSectionPayload
  | IMostVisitedSectionResponse[]
  | IVisitedPagePayload
  | IMostVisitedArchPayload
  | IMostVisitedArchResponse[]
  | ICloudAnalysisResponse[];

export type TrafficAnalyticsAction = IStoreAction<
  TrafficAnalyticsActionTypes,
  TrafficAnalyticsActionPayload
>;

export class TrafficAnalyticsActions {
  public static getVisitedStart(data: IMostVisitedSectionPayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_START,
      data,
    };
  }
  public static getVisitedSuccess(data: IMostVisitedSectionResponse[]): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_SUCCESS,
      data,
    };
  }
  public static getVisitedError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_ERROR,
      data: error,
    };
  }
  public static postVisitedPageStart(data: IVisitedPagePayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_START,
      data,
    };
  }
  public static postVisitedPageSuccess(): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_SUCCESS,
    };
  }
  public static postVisitedPageError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_ERROR,
      data: error,
    };
  }
  public static getCloudProviderAnalyticsStart(): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_STARTED,
    };
  }
  public static getCloudProviderAnalyticsSuccess(
    data: ICloudAnalysisResponse[],
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_SUCCESS,
      data,
    };
  }
  public static getCloudProviderAnalyticsError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_ERROR,
      data: error,
    };
  }
  public static getArchVisitedStart(data: IMostVisitedArchPayload): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_START,
      data,
    };
  }
  public static getArchVisitedSuccess(data: IMostVisitedArchResponse[]): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_SUCCESS,
      data,
    };
  }
  public static getArchVisitedError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_ERROR,
      data: error,
    };
  }
  public static getCloudAssessmentVisitedStart(): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_START,
    };
  }
  public static getCloudAssessmentSuccess(
    data: IMostVisitedArchResponse[],
  ): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_SUCCESS,
      data,
    };
  }
  public static getCloudAssessmentError(error: Error): TrafficAnalyticsAction {
    return {
      type: TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_ERROR,
      data: error,
    };
  }
}
