export const PipelineLabels = {
  heading: 'Create Pipeline',
  editHeading: 'Edit Pipeline',
  pipelineNameLabel: 'Enter Pipeline Name',
  pipelineName: 'Pipeline Name',
  pipelineTemplateLabel: 'Choose Pipeline Template',
  pipelineTemplate: 'Pipeline Template',
  srcCodeURL: 'Source Code URL',
  imageRepoURL: 'Image Repository URL',
  imageName: 'Image Name',
  imageVersion: 'Image Version',
  envSrcCodeURL: 'Environment Source Code URL',
  environmentName: 'Environment Name',
  imageVersionURL: 'Image Version URL',
  blueVersion: 'Blue Version',
  greenVersion: 'Green Version',
  configButtonText: 'Configure',
  requiredMsg: ' is a required field',
  editButtonText: 'Edit',
  success: 'Success!!',
  addedSuccesfulText: 'Pipeline Created Successfully!',
  editSuccesfulText: 'Pipeline Updated Successfully!',
  pipelineTemplates: ['Build Pipeline', 'Deploy Pipeline', 'Blue-Green Deploy Pipeline'],
};
