import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { PipelineLabels } from '../../../containers/BuildPipeline/CreatePipelineConstants';
import { DeliveryPipelineLabels as LABELS } from '../../../containers/DeliveryPipeline/DeliveryPipelineConstants';
import {
  ICreatePipelineResponse,
  ICreatePipelineState,
  IDeliveryPipelineState,
  IGetInstanceDetailsPayload,
  IPipelineHistory,
  IPipelineInstanceDetailsResponse,
  IPipelineInstancePayload,
  IRunPipelineResponse,
  IRunPipelineState,
  IUpdatePipelineResponse,
  IUpdatePipelineState,
} from '../../models/PipelineModels';
import OperationsService from '../../services/OperationsService/OperationsService';
import CreatePipelineService from '../../services/pipelineService/CreatePipelineService';
import GetPipelinesService from '../../services/pipelineService/GetPipelinesService';
import RunPipelineService from '../../services/pipelineService/RunPipelineService';
import UpdatePipelineService from '../../services/pipelineService/UpdatePipelineService';
import { PipelineAction, PipelineActions, PipelineActionTypes } from '../actions/PipelineActions';
import { ToastActions } from '../actions/ToastActions';

export function* createPipelineWorker(action: PipelineAction) {
  try {
    const data = action.data as ICreatePipelineState;
    const response: ICreatePipelineResponse = yield CreatePipelineService.createPipeline(
      data.payload,
      data.landingZoneId,
    );
    yield put(PipelineActions.createPipelineSuccess(response));
    yield put(
      ToastActions.showToast({
        description: `${PipelineLabels.addedSuccesfulText}`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.createPipelineError(error));
    }
  }
}

export function* getPipelinesWorker(action: PipelineAction) {
  try {
    const data = action.data as string;
    const response: IDeliveryPipelineState[] = yield GetPipelinesService.getPipelines(data);
    yield put(PipelineActions.getPipelinesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.getPipelinesError(error));
    }
  }
}

export function* updatePipelineWorker(action: PipelineAction) {
  try {
    const data = action.data as IUpdatePipelineState;
    const response: IUpdatePipelineResponse = yield UpdatePipelineService.updatePipeline(
      data.payload,
      data.pipelineId,
    );
    yield put(PipelineActions.updatePipelineSuccess(response));
    yield put(
      ToastActions.showToast({
        description: `${PipelineLabels.editSuccesfulText}`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.updatePipelineError(error));
    }
  }
}

export function* runPipelineWorker(action: PipelineAction) {
  try {
    const data = action.data as IRunPipelineState;
    const response: IRunPipelineResponse = yield RunPipelineService.runPipeline(
      data.payload,
      data.pipelineId,
    );
    yield put(PipelineActions.runPipelineSuccess(response));
    yield put(
      ToastActions.showToast({
        description: `${data.payload.pipelineName} ${LABELS.runPipelineSuccessMsg}`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.runPipelineError(error));
      yield put(
        ToastActions.showToast({
          description: error.message,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getPipelineHistoryDetailsWorker(action: PipelineAction) {
  try {
    const { instanceId, isLatest, pipelineId } = action.data as IGetInstanceDetailsPayload;
    const response: IPipelineInstanceDetailsResponse = yield OperationsService.getWorkflowDetails(
      instanceId,
    );
    const payload: IPipelineInstancePayload = {
      instanceId,
      details: response,
    };
    if (isLatest)
      yield CreatePipelineService.updatePipelineStatus(pipelineId, { status: response.state });
    yield put(PipelineActions.getPipelineInstanceDetailsSuccess(payload));
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.getPipelineInstanceDetailsError(error));
    }
  }
}

export function* getPipelineInstancesWorker(action: PipelineAction) {
  try {
    const data = action.data as string;
    const response: IPipelineHistory[] = yield GetPipelinesService.getPipelineInstances(data);
    yield put(PipelineActions.getPipelineInstancesSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(PipelineActions.getPipelineInstancesError(error));
    }
  }
}

export function* pipelineWatcher() {
  yield takeEvery(PipelineActionTypes.CREATE_PIPELINE_START, createPipelineWorker);
  yield takeEvery(PipelineActionTypes.GET_PIPELINES_START, getPipelinesWorker);
  yield takeEvery(PipelineActionTypes.UPDATE_PIPELINE_START, updatePipelineWorker);
  yield takeEvery(PipelineActionTypes.RUN_PIPELINE_START, runPipelineWorker);
  yield takeEvery(
    PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_START,
    getPipelineHistoryDetailsWorker,
  );
  yield takeEvery(PipelineActionTypes.GET_PIPELINE_INSTANCES_START, getPipelineInstancesWorker);
}
