export const routes = {
  error: {
    new: '/404',
  },
  login: {
    viewGeneric: `/login`,
    error: '/error',
  },
  iframe: '/frame',
  // dashboard: '/dashboard',
  authentication: '/authenticate',
  assetAuthenticationRoutes: {
    cwb: 'cwb',
    cms: 'cms',
    cb360: 'cb360',
  },
  operations: '/operations',
  deliveryPipeline: '/dashboard/deliveryPipelines',
  pipelineHistory: '/dashboard/deliveryPipelines/history',
  accountManagement: '/dashboard/accountManagement',
  userManagement: '/dashboard/userManagement',
  assetAuthorisation: '/assetAuthorisation',
  webAnalytics: '/webAnalytics',
  documentation: '/documentation',
  quickLinks: '/quickLinks',
  keyContacts: '/keyContacts',
  feedback: '/feedback',
  giveFeedback: '/giveFeedback',
  feedbackResponse: '/feedbackResponse',
  compliancePolicies: '/compliancePolicies',
  industryCompliancePolicies: '/industryCompliancePolicies',
  createEnvironments: '/createEnvironments',
  assessment: '/assessment',
  cboSummary: '/cboSummary',
  cbo_login: '/cbo-login',
  cboConfigureJourney: '/cboConfigureJourney',
  dashboard: '/dashboard',
  submissions: '/dashboard/submissions',
  underwriter: '/underwriter',
  businessIntelligence: '/businessintelligence',
  landingPage: '/landing-page',
  ingestion: '/ingestion',
  analysis: '/analysis',
  aibot: '/aibot',
};
