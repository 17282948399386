import { Menu } from 'antd';
import React from 'react';
import './DropdownMenuStyles.less';

interface IDropdownMenuProps {
  keys: string[];
  selectedKey: string | undefined;
  onClick: (data: string) => void;
}

const DropdownMenu = ({ keys, selectedKey, onClick }: IDropdownMenuProps) => {
  return (
    <Menu className="account-dropdown-menu " selectedKeys={selectedKey ? [selectedKey] : []}>
      {keys.length ? (
        keys.map((key) => (
          <Menu.Item className="menu-item" key={key} onClick={() => onClick(key)}>
            {key}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item className="menu-item" key={0} disabled>
          <em>Nothing to show</em>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default DropdownMenu;
