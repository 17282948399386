import { IStoreAction } from '../StoreHelper';
import { IServiceCataloguePayload } from '../../models/ServiceCatalogueModel';
export enum ServiceCatalogueActionTypes {
  GET_SERVICE_CATALOGUE_DETAILS_STARTED = 'GET_SERVICE_CATALOGUE_DETAILS_STARTED',
  GET_SERVICE_CATALOGUE_DETAILS_SUCCESS = 'GET_SERVICE_CATALOGUE_DETAILS_SUCCESS',
  GET_SERVICE_CATALOGUE_DETAILS_ERROR = 'GET_SERVICE_CATALOGUE_DETAILS_ERROR',
}

export type ServiceCatalogueActionPayload = IServiceCataloguePayload | boolean | Error;

export type ServiceCatalogueAction = IStoreAction<
  ServiceCatalogueActionTypes,
  ServiceCatalogueActionPayload
>;

export class ServiceCatalogueActions {
  public static getCatalogueDetailsStarted(): ServiceCatalogueAction {
    return {
      type: ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_STARTED,
    };
  }
  public static getCatalogueDetailsSuccess(data: IServiceCataloguePayload): ServiceCatalogueAction {
    return {
      type: ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_SUCCESS,
      data,
    };
  }
  public static getCatalogueDetailsError(data: Error): ServiceCatalogueAction {
    return {
      type: ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_ERROR,
      data,
    };
  }
}
