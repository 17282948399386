import { RcFile } from 'antd/lib/upload';
import { CloudServiceProvider } from '../../AppConstants';

export interface IConfigurationModalProps {
  selectedId?: string;
  configurationType: ConfigurationType;
  createdLandingDetails: {
    showName: boolean;
    selectedlandingZoneName: string;
    selectedCloudProvider: CloudServiceProvider;
    createYaml: boolean;
  };
  next: () => void;
  onPrev: () => void;
  setLandingZoneValue?: (value: ILandingZoneValue) => void;
  onClickConfigure: (value: boolean) => void;
  yamlValues: (value: Record<string, string>) => void;
  showManual?: boolean;
  onClose: () => void;
  environmentType?: string;
}
export interface ILandingZoneValue {
  file: RcFile;
  landingZoneName: string;
  cloudProvider: CloudServiceProvider;
}

export enum ConfigurationType {
  LANDING_ZONE = 'landingZones',
  LANDING_ZONE_CREATED = 'LANDING_ZONE_CREATED',
  MICROSERVICES_PATTERN = 'microservicePattern',
  ETL_PATTERN = 'etlPattern',
}

export enum YamlUploadType {
  CUSTOM = 'Create Yaml File',
  BASIC = 'Configure Manually',
}

export interface IDynamicFormFields {
  id : string,
  initialValue? : string | number | boolean | Array<string>,
  type : string,
  name : string,
  placeholder?: string,
  required?: boolean,
  regex?: RegExp,
  min? : number,
  max? : number,
  defaultValue?: any,
  dependentOn?: string, //set it equal to the id of other field to create mapping
  noDuplicate?: boolean, // if set true no other same fields can have same value
  options?: Array<{}>,
  dependentOptions?: {[key: string]: []},
  mode?: any, // used for select box multiple selection
  parent?: string,
  handleFieldValidation?: (_ : any, value : any) => Promise<string> | ""
}
export interface IDynamicFormModalProps {
  formName: string;
  formFields : Array<IDynamicFormFields>;
  formValidator: (_ : any, name : string) => Promise<string>;
  preview : () => void
}
// may be use later
// export interface IPreviewYamlType {
//   iam: string;
//   networking: string;
//   security: string;
//   compute: string;
//   storage: string;
//   databases: string;
//   cloudProvider: string;
//   messaging: string;
//   logging: string;
//   terraform_policy: string;
// }
