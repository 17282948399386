import { Button } from 'antd';
import React, { FC, useState } from 'react';
import './Login.less';
import TangraLogo from '../../assets/images/tangra-logo-dark.svg';
import ExternalUserLogin from './ExternalUserLogin';
import SSOLogin from './SSOLogin';
import { LoginLabels as LABELS } from './LoginConstants';
import Footer from './Footer';

interface ILoginProps {}

const Login: FC<ILoginProps> = () => {
  const [loginType, setLoginType] = useState<string>('external-user');

  const login = () => (
    <div className="login-container">
      <img
        src={`${process.env.PUBLIC_URL}/config-icons/broadcom-icon.svg`}
        alt="MNACI Logo"
        className="login-logo"
      />
      <h2>
        <span>Welcome to Contracts Intelligence System</span>
      </h2>

      <div className="login-btn-container">
        <SSOLogin />
        <Button className="login-btn external-user" onClick={() => setLoginType('external-user')}>
          {LABELS.externalUserLoginText}
        </Button>
        <Button type="text" className="help-link">
          {LABELS.needHelp}
        </Button>
        <Footer />

        {/*Keeping it for future use */}
        {/* <span className="or-text">(or)</span>
        <Button className="login-btn signup">{LABELS.signUpText}</Button> */}
      </div>
    </div>
  );

  const renderSwitch = () => {
    switch (loginType) {
      case 'external-user':
        return <ExternalUserLogin setLoginType={setLoginType} />;

      default:
        return login();
    }
  };

  return (
    <div className="login-background">
      <div className="login-div large-screen-login">{renderSwitch()}</div>
    </div>
  );
};

export default Login;
