import { Table, Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContractActions } from '../../../libs/store/actions/UploadContractActions';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import { IPostContractsPayload, IPostContractsResponse } from '../../../libs/store/state/ContractState';
import ContractsDrawer from '../../MNACIDashboard/ContractsDrawer/ContractsDrawer';
import { ContractStatus, IContractsData } from '../../MNACIDashboard/MNACIDashboardNew';
import { filterType } from '../Analysis';
import './AnalysisTable.less';
import { DummyData1, fetchData, fetchTableHeaders, IDataSourceObj } from './AnalysisTableConstant';

interface IAnalysisTableProps {
  queryInput: string;
  tableHeaders : string;
  tableData ?: IPostContractsResponse | null;
  queryType ?: string;
  formSearch ?: any;
  contextSearch ?: any;
  current ?: number | 1;
}

interface IDataSource {
  [key: string]: string | null | undefined;
}

const AnalysisTable = ({ queryInput,tableHeaders,tableData,queryType,formSearch,contextSearch,current }: IAnalysisTableProps) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState<IDataSourceObj[]>();
  const [tableHeader, setTableHeader] = useState<ColumnsType<IDataSourceObj>>();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(current || 1);
  const [selectedContract, setSelectedContract] = useState<IContractsData>({
    contract_id: '',
    contract_name: '',
    contract_type: '',
    status: '',
    date: '',
    created_by: ''
  });
  useEffect(() => {
    current && setCurrentPage(current);
  },[contextSearch,formSearch])
  //   {
  //     vmware_agreement: '00476553',
  //     customer_name: 'NEC Corporation',
  //     contract_id: '1234',
  //     contract_title: 'ELA order form',
  //     fees: '1234',
  //   },
  const { searchedContracts } = useAppSelector(
    ({ contracts: { searchedContracts } }: IApplicationState) => ({
      searchedContracts
    }),
  );
  
  const dispatcher = {
    postSearchContract: (searchQuery: IPostContractsPayload) =>
      dispatch(ContractActions.postSearchContractStart(searchQuery)),
  };
  const columns: ColumnsType<IDataSourceObj> = [

    {
      title: 'Vmware Agreement',
      key: 'vmware_agreement',
      dataIndex: 'vmware_agreement',
      sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
    },
    {
      title: 'Customer Name',
      key: 'customer_name',
      dataIndex: 'customer_name',
      filters: [
        {
          text: 'Boeing',
          value: 'Boeing',
        },
      ],
      // eslint-disable-next-line consistent-return
      onFilter: (value: string | number | boolean, record: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        if (record.customer_name === value) return record;
      },
    },
    {
      title: 'Contract Id',
      key: 'contract_id',
      dataIndex: 'contract_id',
    },
    {
      title: 'Contract Title',
      key: 'contract_title',
      dataIndex: 'contract_title',
    },
    {
      title: 'Fees',
      key: 'fees',
      dataIndex: 'fees',
    },
  ];
  const showContractDetails = (record : any) => {
    const { contract_id,contract_name,contract_type,status,date,created_by} = record;
    setSelectedContract({
      contract_id: contract_id,
      contract_name: contract_name || 'Contract',
      contract_type: contract_type || 'No Type',
      status: status || ContractStatus.EXTRACTED,
      date: date || null,
      created_by: created_by || ''
    });
    setDrawerVisible(true);
  }
  const getTableHeaders = (headerObj : IDataSourceObj) => {
    const columnNames = Object.keys(headerObj);
    const headers : any= columnNames.map((name,key) => {
      let headerObj =  {};
      // if(name === 'view_contract'){
      //   headerObj = {
      //     title: 'VIEW CONTRACT',
      //     key: 'view_contract',
      //     dataIndex: 'view_contract',
      //     render: (text : any,record: any) => <a style={{ color : '#be3438'}} onClick={() => showContractDetails(record)}>{text}</a>,
      //   }
      // }
      // else{
        headerObj = { 
          title: name.replace("_"," ").toLocaleUpperCase(),
          key: name,
          dataIndex: name,
        }
        
      // }
      return headerObj;
    });
    headers.push({
            title: 'VIEW CONTRACT',
            key: 'view_contract',
            dataIndex: 'view_contract',
            render: (text : any,record: any) => <a style={{ color : '#be3438'}} onClick={() => showContractDetails(record)}>{'View'}</a>,
    });
    headers.unshift({
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (value: any,record:any,index:any)  => <a style={{ color : '#be3438'}} onClick={() => showContractDetails(record)}>{(currentPage - 1) * 10 + index+1}</a>,
    })
    return headers;
   
  }
  
  // useEffect(() => {
  //   const tableData = fetchData(queryInput);
  //   const tableHeaders = getTableHeaders(tableData?.[0]);
  //   setDataSource(tableData);
  //   setTableHeader(tableHeaders);
  // }, [queryInput]);
  useEffect(() => {
    if(tableData?.records?.length){
      const tableHeaders =  getTableHeaders(tableData && tableData?.records?.[0]);
      setTableHeader(tableHeaders);
      setDataSource(tableData?.records);
    }
    
    
    
  }, [tableData]);

  useEffect(() => {
    if(searchedContracts?.records?.length){
      const tableHeaders =  getTableHeaders(searchedContracts && searchedContracts?.records?.[0]);
      setTableHeader(tableHeaders);
      setDataSource(searchedContracts?.records);
    }
    else{
      setTableHeader([]);
      setDataSource([]);
    }
    
    
  }, [searchedContracts]);

  useEffect(() => {
    
    fetchTableHeaders(tableHeaders);
    //setTableHeader(fetchTableHeaders(tableHeaders));
  }, [dataSource]);
  const fetchRecords = (page: number,pageSize : number) => {
    dispatcher.postSearchContract({
      "query_type" : queryType || "keyword",
      "query_string" : queryType === filterType.KEYWORD ? Object.values(formSearch).join(" ") : contextSearch,
      "page_num" : page,
      "page_size" : pageSize
    })
    setCurrentPage(page);
  }
  
  //   useEffect(() => {
  //     if (dataSource) {
  //       const columnsProps = Object.keys(dataSource[0]);

  //       const columnData: IDataSource[] = columnsProps.map((column) => ({
  //         title: column.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
  //         dataIndex: column,
  //         key: column,

  //       }));

  //       setColumns(columnData);
  //     }
  //   }, [dataSource]);

  return (
    <div>
      {drawerVisible && (
          <ContractsDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            contractDetails={selectedContract}
          />
        )}
        <div  className="table-header" style={{ display: 'flex', justifyContent: 'space-between'}}>
          <p className='total-rec'>Total Records : { searchedContracts?.total_records}</p>
          <a href={searchedContracts?.csv_url} target="_blank">Report<img src={`${process.env.PUBLIC_URL}/config-icons/Download-active.svg`} style={{ paddingLeft: '0.3rem'}}></img></a>
        </div>
        
        
      <Table columns={tableHeader} dataSource={dataSource} 
      locale={{ emptyText: <Empty/> }}
      scroll={{
        y: 300,
        x: 1500
      }}
      pagination={{
        current: currentPage,
        total: tableData?.total_records,
        onChange: (page, pageSize) => {
          fetchRecords(page, pageSize);
        }
      }}
      />
    </div>
  );
};

export default AnalysisTable;
