import ColorScale from 'color-scales';
import moment from 'moment';
import { devEnvironments, Environment, localhostEnvironments, demoEnvironments } from '../../AppConstants';
import { AssessmentDetailsColor } from '../../containers/OpsModelAssessment/OpsModelAssessmentReportsConstants';
import { IUploadFileBody, LandingZoneStatus } from '../models/BuildDeployModels';
import { storage, STORAGE_CONSTANTS } from '../services/LocalStorage';
import { store } from '../store';

export const logOut = () => {
  const authTokenBasic = storage.getItem(STORAGE_CONSTANTS.authTokenBasic);
  storage.clearAll();
  storage.setItem(STORAGE_CONSTANTS.authTokenBasic, authTokenBasic!);
  store.dispatch({ type: 'LOG_OUT' });
  window.open('/#/login', '_self');
};

export const dollarFormatter = (value: number) => `$${value.toFixed(2)}`;

export const getFormattedTimestamp = (dateTime: string) => {
  const formattedDateTime = new Date(dateTime);
  return formattedDateTime.toString().split('GMT')[0];
};

export const getEnv = () => {
  if (devEnvironments.includes(window.location.hostname)) return Environment.DEV;
  if (localhostEnvironments.includes(window.location.hostname)) return Environment.LOCALHOST;
  if (demoEnvironments.includes(window.location.hostname)) return Environment.DEMO;
  else return Environment.PROD;
};

export const isAuthenticated = (): boolean => {
  const accessToken = storage.getItem(STORAGE_CONSTANTS.accessToken);
  const userId = storage.getItem(STORAGE_CONSTANTS.userId);
  if (accessToken && userId) return true;
  return false;
};

// Creating a promise which opens an connection to listen to the given endpoint
export const listenerApiHelper = (endpoint: string) => {
  return new Promise(function (resolve, reject) {
    const evtSource = new EventSource(endpoint);

    evtSource.addEventListener('message', (event) => {
      if (event.data.toLowerCase().includes(LandingZoneStatus.CREATED.toLowerCase())) {
        evtSource.close();
        resolve(event.data);
      } else if (event.data.toLowerCase().includes(LandingZoneStatus.ERROR.toLowerCase())) {
        evtSource.close();
        resolve(event.data);
      } else {
        reject();
      }
    });
  });
};

// Method to upload File onto S3
export const uploadFile = (url: string, body: IUploadFileBody): Promise<Response> => {
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return fetch(url, {
    method: 'POST',
    body: formData,
  });
};

export const getNextFiveYears = (year: string): string[] => {
  return [0, 1, 2, 3, 4, 5].map((add) => (parseInt(year) + add).toString());
};

export const calculatePercentageFromScore = (score: number): number => (score * 100) / 4;

export const getOpsModelColorScale = (percent: number): string => {
  if (percent < 50) {
    const colors = AssessmentDetailsColor[0] as string[];
    const colorScale = new ColorScale(0, 50, colors);
    return colorScale.getColor(percent).toHexString();
  } else if (percent < 75) {
    const colors = AssessmentDetailsColor[1] as string[];
    const colorScale = new ColorScale(50, 75, colors);
    return colorScale.getColor(percent).toHexString();
  } else {
    const colors = AssessmentDetailsColor[2] as string[];
    const colorScale = new ColorScale(75, 100, colors);
    return colorScale.getColor(percent).toHexString();
  }
};

//Method to check wether the string is valid JSON
export const jsonStringValidator = (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};
export const timeSince = (time: Date) => {
  let date = new Date().setDate(time.getDate());
  date = time.getTime();
  const time_formats = [
    [60, 'seconds', 1], // 60
    [3600, 'minute(s)', 60], // 60*60, 60
    [86400, 'hour(s)', 3600], // 60*60*24, 60*60
    [604800, 'day(s)', 86400], // 60*60*24*7, 60*60*24
    [2419200, 'week(s)', 604800], // 60*60*24*7*4, 60*60*24*7
    [29030400, 'month(s)', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [2903040000, 'year(s)', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
  ];
  const seconds = (+new Date() - date) / 1000,
    token = 'ago',
    list_choice = 1;

  if (seconds === 0) {
    return 'Just now';
  }

  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] === 'string') {
        return format[list_choice];
      } else
        return (
          (Math.floor(seconds / format[2]) as unknown as string) +
          ' ' +
          (format[1] as unknown as string) +
          ' ' +
          token
        );
    }
  return time;
};

//Method to Get TimeStamp of day from current timestamp
export const getTimeDayStamp = (days: number) => moment().subtract(days, 'days').format();

//Method to open url in new tab
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
