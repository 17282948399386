export interface IUserPayload {
  userId: string;
}

export enum UserType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface IUserResponse {
  email: string;
  family_name: string;
  given_name: string;
  user_permissions: IUserPermission;
}

// TODO: Backend should be asked to change all the responses with camelCase keys
export interface IUserPermission {
  add_account: boolean;
  add_project: boolean;
  map_user_to_project: boolean;
  map_user_to_account: boolean;
  configure_lz: boolean;
  view_lz: boolean;
  destroy: boolean;
  create_micro_services: boolean;
  create_etl: boolean;
  view_workload_pattern: boolean;
  view_widgets_analytics_dashboard: boolean;
  upload_ruleset_yaml: boolean;
  run_ruleset: boolean;
  create_ruleset: boolean;
  edit_ruleset: boolean;
  view_ruleset_history: boolean;
  create_pipeline: boolean;
  upload_yaml_for_pipeline: boolean;
  run_pipeline: boolean;
  edit_pipeline: boolean;
  view_pipeline_history: boolean;
  user_management: boolean;
}
