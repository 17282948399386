export enum IWebSocketEventMap {
  Close = 'close',
  Error1 = 'error',
  Message = 'message',
  Open = 'open',
}

export interface ISocketNotificationState {
  message: string;
}

export const NotificationLabels = {
  newNotification: 'new notification', //TODO NEED to ask backend to change
  userNotification: 'email alert', //TODO NEED to ask backend to change
  userId: 'userId',
};
