import { ReactNode } from 'react';
import CmsDummyImages1 from '../../dummydata/CmsDummyImages1.png';
import CmsDummyImages2 from '../../dummydata/CmsDummyImages2.png';
import CmsDummyImages3 from '../../dummydata/CmsDummyImages3.png';
import CmsDummyImages4 from '../../dummydata/CmsDummyImages4.png';
import Critical from '../../dummydata/GreenCloud/GreenCloudCritical.png';
import Datacenter from '../../dummydata/GreenCloud/GreenCloudDatacenter.png';
import General from '../../dummydata/GreenCloud/GreenCloudGeneral.png';
import Office from '../../dummydata/GreenCloud/GreencloudOffice.png';
import GreenCloudSpecifications from '../../dummydata/GreenCloud/GreenCloudSpecifications.png';
import { CmsType } from '../Operate/OperateConstants';

export const ReportDrawerLabels = {
  cfaHeaderTitle: 'Cloud Financial Analysis',
  cfHaHeaderTitle: 'Foundation Health Assessment',
  cspHeaderTitle: 'Cloud Suitability & Placement',
  omaHeaderTitle: 'Operating Model Assessment',
  csoHeaderTitle: 'Cloud Spend Optimization',
  ctbrHeaderTitle: 'Cloud Tech Value Benefit Realization',
  doaHeaderTitle: 'DevOps Assessment',
  cfhaHeaderTitle: 'Cloud Foundation Health Assessment',
  qeHeaderTitle: 'Quality Engineering',
  potentialTitle: 'Potential monthly opitimization Recommendations',
  totalSpend: 'Total Spend this month',
  stericylceTitle: 'Stericycle Infrastructure Dashboard',
  serviceSpend: 'Service Spend this month',
  vmabackup: 'VM Backup',
  vmbackup2: 'VM Backup 2',
  agentCompliance: 'Agent Compliance',
  topResources: 'Top Resources per account',
  cfHeader: 'Cloud Financials',
  account: 'Account',
  gcTitle: 'Green Cloud',
  accountValue: 'ACME Pvt. Ltd',
  project: 'Project',
  projectValue: 'ACME Project',
  projectId: 'Project ID',
  projectIdValue: '14F56YT',
  download: 'Download',
  impactProgress: 'Impact Progress',
  bauVsCloudCosts: 'BAU Costs vs Cloud Costs',
  fiveYearPl: '5-Yr P&L Overview',
  bauVsCloudComputeCost: 'BAU vs Cloud Compute Cost Comparision',
  bauVsCloudStorageCost: 'BAU vs Cloud Storage Cost Comparision',
  heatmap: 'Heatmap',
  appSummary: 'App Summary',
  sustainabilitySummary: 'Sustainability Summary',
  assesmentSummary: 'Assessment Summary',
  inputAssumptions: 'Inputs and Assumptions',
  assesmentDetails: 'Assessment Details',
  home: 'Home',
  computeTag: 'Compute Tag Snapshot',
  storageTag: 'Storage Tag Snapshot',
  generalMigration: 'General Migration',
  rightSizing: 'Right Sizing',
  idleInstance: 'Idle Instances',
  vaianceReport: 'Varinace Trend Report',
  monthlyReport: 'Monthly Varinace Report',
  dimensionAnalysis: 'Dimension Analysis',
  capabilityAnalysis: 'Capability Analysis',
  digitalMaturityAssessment: 'Digital Maturity Assessment',
  prioritizationMatrix: 'Prioritization Matrix',
  architectureFramework: 'Architected Framework',
  cloudHealth: 'Cloud Health',
  toolimg: 'Tooling & Automation',
};
export interface IFinancialDrawer {
  name: string;
  content: ReactNode;
}

export const dummyImageHelper = (value: CmsType | undefined): string => {
  switch (value) {
    case CmsType.OLA:
      return CmsDummyImages3;
    case CmsType.SLA:
      return CmsDummyImages2;
    case CmsType.ENV_HEALTH:
      return CmsDummyImages1;
    case CmsType.HISTORIC_TREND:
      return CmsDummyImages4;
    default:
      return '';
  }
};

export enum CWBDrawerType {
  CFA = 'CFA', // Cloud Financial Analysis
  CSP = 'CSP', //Cloud Suitability & Placement
  OMA = 'OMA', // Ops Model Assessment
  CSO = 'CSO', //Cloud Spend Assessment
  CTBR = 'CTBR', //Cloud Tech Value Benefit Realization
  GC = 'GC', //Green Cloud
  DOA = 'DOA', //DevOps Assessment
  CFHA = 'CFHA', //cloud foundation health assessment
  QE = 'QE', //Quality Engineering
  CFS = 'CFS', //Cloud Foundation Strategy
}

export const GreenCloudData = [
  {
    title: 'Carbon Footprint',
    image: General,
  },
  {
    title: 'Carbon Credits',
    image: Datacenter,
  },
  {
    title: 'Energy Savings',
    image: Critical,
  },
  {
    title: 'Detailed Report',
    image: Office,
  },
  {
    title: 'Environmental Impact',
    image: GreenCloudSpecifications,
  },
];
