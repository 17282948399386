import { IServiceCataloguePayload } from '../../models/ServiceCatalogueModel';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class ServiceCatalogueService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getServiceCatalogueDetails():
    | Promise<IServiceCataloguePayload>
    | IServiceCataloguePayload {
    const route: string[] = [`${API_ROUTE.SERVICES}`];
    return this.service.get({ route: route });
  }
}

const serviceCatalogueService = new ServiceCatalogueService();
export default serviceCatalogueService;
