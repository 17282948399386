import { CloudServiceProvider } from '../../AppConstants';
import ArchitectureBlack from '../../assets/icons/Architecture_black.svg';
import ArchitectureSelected from '../../assets/icons/Architecture-selected.svg';
import AWSIcon from '../../assets/icons/aws-icon.svg';
import AzureIcon from '../../assets/icons/azure-icon.svg';
import CloudenvBlack from '../../assets/icons/cloudenv_black.svg';
import CloudenvSelected from '../../assets/icons/cloudenv-selected.svg';
import DiscoverIcon1 from '../../assets/icons/discover_assess_icon_5.svg';
import GCPIcon from '../../assets/icons/gcp-icon.svg';
import OpenshiftIcon from '../../assets/icons/openshift-icon.svg';
import OracleIcon from '../../assets/icons/oracle-icon.png';
import TestingIcon1 from '../../assets/icons/testing-icon-1.svg';
import TestingIcon2 from '../../assets/icons/testing-icon-2.svg';
import TestBlack from '../../assets/icons/Test_black.svg';
import TestSelected from '../../assets/icons/Test-selected.svg';
import { ILandingZoneSector } from '../../libs/models/BuildDeployModels';
import { ILandingZoneState } from '../../libs/store/state/BuildDeployState';
export interface ITabMenu {
  menuName: string;
  blackIcon: string;
  whiteIcon: string;
}

export const BuildDeployLabels = {
  ALL: 'ALL',
  title: 'Build & Deploy',
  expandedModalLabel: 'Cloud Environment',
  subtitle:
    'Through discovery, dependency mapping, and risk assessments based on current usage, as well as migration analysis, Cloud Discovery & Assessment enables migrations to be conducted according to informed decisions, helping to minimize risks while ensuring business continuity throughout and after cloud migration.',
  add: 'Add',
  destroyModal: 'Are you sure you want to delete the',
  carouselTitle: 'Architecture Flow Diagrams',
  defaultLandingZoneName: 'Cloud Environment',
  destroyModalMessage: 'Are you sure you want to delete the',
  yesTitle: ' Yes, Delete',
  noTitle: 'No',
  viewWorkload: 'View Workload Architectures',
  show: 'Show',
  viewMore: 'View More',
  fileETL: 'fileETL',
  fileMS: 'fileMS',
  workLoadPattern: 'WorkLoad Pattern For Microservices',
  createLandingZone: 'Create Cloud Environment',
  error: 'Error',
  success: 'Success',
  imageUploaded: 'Image Uploaded Successfully',
  uploadOnlyPng: 'Please select only png file',
  imageUploadFailed: 'Image upload failed',
  projectsFoldername: 'projects/',
  defaultProject: 'unassigned',
  create: 'Create',
  runQE: 'Run TalkQE',
  runCofta: 'Run COFTA',
  viewReport: 'View Report',
  coftaVideoName: 'COFTA_VIDEO.mp4',
  talkeQeVideoName: 'TALKQE_VIDEO.mp4',
  noLandingZone: 'Landing Zone has not created for the selected Cloud Provider',
  architectureDiagrams: 'Architecture & Design',
  build: 'Build',
  testing: 'Test',
  deploy: 'Build Summary',
  cloudEnv: 'Cloud Environments',
  all: 'all',
  pipelineStatus: 'Pipeline Listing',
  completed: 'Completed',
  suspended: 'Suspended',
  active: 'Active',
  cloudLandingZone: 'Cloud Landing Zone',
  industryLandingZone: 'Industry Landing Zone',
  deployments: 'Deployments',
};

export const buildDeployMenu: ITabMenu[] = [
  {
    menuName: 'Architecture & Design',
    blackIcon: ArchitectureBlack,
    whiteIcon: ArchitectureSelected,
  },
  {
    menuName: 'Build Cloud Environments',
    blackIcon: CloudenvBlack,
    whiteIcon: CloudenvSelected,
  },
  {
    menuName: 'Test & Deploy',
    blackIcon: TestBlack,
    whiteIcon: TestSelected,
  },
];
export const BuildDeplouCloudFilter = ['aws', 'gcp', 'azure', 'oracle', 'openshift', 'multicloud'];

//Maybe use later
// export const NewLandingZone: ILandingZoneState = {
//   landingZoneId: '',
//   loadingLandingZone: false,
//   loadingEtlWorkload: false,
//   loadingMicroServicesWorkload: false,
//   landingZoneEnabled: false,
//   landingZoneConfigured: false,
//   landingZoneName: '',
//   projectName: undefined,
//   workLoadPatternMicroServicesEnabled: false,
//   workLoadPatternEtlEnabled: false,
//   workLoadPatternMicroServicesConfigured: false,
//   workLoadPatternEtlConfigured: false,
//   workLoadPatternMicroServicesId: undefined,
//   landingZoneStatus: undefined,
//   workLoadPatternMicroServicesError: false,
// };

// export const addLandingZoneHelper = (type: CloudServiceProvider | undefined) => {
//   return {
//     // ...NewLandingZone,
//     cloudServiceProvider: type,
//   };
// };

export const NewEnabledUnconfiguredLZ: ILandingZoneState = {
  sector: ILandingZoneSector.GENERAL,
  landingZoneId: '',
  loadingLandingZone: false,
  loadingEtlWorkload: false,
  loadingMicroServicesWorkload: false,
  landingZoneEnabled: true,
  landingZoneConfigured: false,
  landingZoneName: '',
  projectName: undefined,
  workLoadPatternMicroServicesEnabled: false,
  workLoadPatternEtlEnabled: false,
  workLoadPatternMicroServicesConfigured: false,
  workLoadPatternEtlConfigured: false,
  workLoadPatternMicroServicesId: undefined,
  landingZoneStatus: undefined,
  workLoadPatternMicroServicesError: false,
  errorMessage: ''
};

export const getCSPIcon = (csp: CloudServiceProvider, isLoading: boolean) => {
  switch (csp) {
    case CloudServiceProvider.AWS:
      return isLoading ? 'aws-icon-gray.svg' : 'aws-icon.svg';
    case CloudServiceProvider.GCP:
      return isLoading ? 'gcp-icon-gray.svg' : 'gcp-icon.svg';
    case CloudServiceProvider.AZURE:
      return isLoading ? 'azure-icon-gray.svg' : 'azure-icon.svg';
    case CloudServiceProvider.ORACLE:
      return isLoading ? 'oracle-icon-gray.svg' : 'oracle-icon.png';
    case CloudServiceProvider.OPENSHIFT:
      return isLoading ? 'openshift-icon-gray.svg' : 'openshift-icon.svg';
    case CloudServiceProvider.MULTICLOUD:
      return 'multicloud_icon.png';
    default:
      // eslint-disable-next-line consistent-return
      return;
  }
};

export const iconSelectorLandingZone = (csp?: CloudServiceProvider) => {
  if (csp === undefined) {
    return DiscoverIcon1;
  } else {
    switch (csp) {
      case CloudServiceProvider.AWS:
        return AWSIcon;
      case CloudServiceProvider.GCP:
        return GCPIcon;
      case CloudServiceProvider.AZURE:
        return AzureIcon;
      case CloudServiceProvider.OPENSHIFT:
        return OpenshiftIcon;
      case CloudServiceProvider.ORACLE:
        return OracleIcon;
      default:
        return DiscoverIcon1;
    }
  }
};

export enum ITestingType {
  QE = 'QE',
  COFTA = 'COFTA',
}
export interface ITestingContent {
  testingType: string;
  button: string;
  value: {
    name: string;
    popoverContent: string;
    icon: string;
    type: ITestingType;
  };
}

export const testingContent: ITestingContent[] = [
  {
    testingType: 'Functional Testing',
    button: 'View Report',
    value: {
      type: ITestingType.COFTA,
      name: 'Cognitive Testing',
      icon: TestingIcon1,
      popoverContent:
        'COFTA is an AI-enabled solution that eliminates automation scripting effort by converting manual, plain English test cases to automated test scripts and reduces the time needed for automated script maintenance with self-healing technology. This gives users the ability to shift to higher levels of automation without the inherent need for test automation and coding experience.',
    },
  },
  {
    testingType: 'Automated Testing',
    button: 'View Report',
    value: {
      name: 'Quality Engineering',
      type: ITestingType.QE,

      icon: TestingIcon2,
      popoverContent:
        'TalkQE (TM) enables data-driven decisions and reduces the time spent creating dashboards and responding to status questions. Test teams can better manage day-to-day tasks of test execution, defect management, and test reporting while enabling organizations to tap into data insights and standardized reporting through chatbots on desktop, web, and mobile devices.',
    },
  },
];

export enum TestingStateType {
  cofta = 'COFTA',
  talkQe = 'TalkQE',
}
