/* eslint-disable max-lines */
import { analysisChartData } from '../Analysis';
import { ColumnsType } from 'antd/lib/table';
export interface IDataSourceObj {
  [key : string] : string | number | null | undefined;
  vmware_agreement?: string;
  customer_name?: string;
  contract_id?: string;
  contract_title?: string | null;
  fees?: string;
  expiration_date?: string;
}
export interface IContractType {
  [key : string] : Record<string, string>[]  ;
}
export const DummyData1: IDataSourceObj[] = [{
  "vmware_agreement": "VMware Agreement #00694098",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8840210340512889",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "873960388.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00367819",
  "customer_name": "JAPAN POST INFORMATION TECHNOLOGYCO., LTD",
  "contract_id": "8286318369113889",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "806343232.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00680134",
  "customer_name": "MITSUBISHI ELECTRIC INFORMATIONNETWORK CORPORATION",
  "contract_id": "8286671652568335",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "748716916.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "FUKOKU MUTUAL LIFE INSURANCE COMPANY",
  "contract_id": "8840569241500288",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "686987261.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00433719",
  "customer_name": "JAL Information Technology Co., Ltd.",
  "contract_id": "8286304840966235",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "682356420.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "VMware Agreement # 00692456",
  "customer_name": "JAPAN RESEARCH INSTITUTE LIMITED",
  "contract_id": "8286347514559184",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "643929201.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00571650",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8286939624452522",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "574134345.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00626767",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8286303527712444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "573299329.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00492389",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8286022647242333",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "553410000.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "RECRUIT CO., LTD.",
  "contract_id": "8840410314094746",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "503905388.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00562209",
  "customer_name": "TKC CORPORATION",
  "contract_id": "8285455583074712",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "474219064.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00431562",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285993521329304",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "433576100.0",
  "view_contract" : "View"
}, {
  "vmware_agreement": "00361954",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285926944264498",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "429928000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00361309",
  "customer_name": "SUZUKI MOTOR CORPORATION",
  "contract_id": "8286235131915694",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "413247716.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478544",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285978204637912",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "381611134.0"
}, {
  "vmware_agreement": "00693922",
  "customer_name": "NET ONE SYSTEMS CO., LTD.",
  "contract_id": "8284889744446444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "378969349.0"
}, {
  "vmware_agreement": "VMware Agreement # 00378987",
  "customer_name": "OSAKA PREFECTURAL BOARD OF EDUCATION",
  "contract_id": "8286588459011492",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "362728000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00477115",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285331249581006",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "360000000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00475195",
  "customer_name": "CHUDEN CTI CO.,LTD.",
  "contract_id": "8286131756775774",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "349017190.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367250",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8284949814124126",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "344335416.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MITSUBISHI UFJ NICOS CO., LTD.",
  "contract_id": "8286357813122891",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "335293426.0"
}, {
  "vmware_agreement": "VMware Agreement # 00474761",
  "customer_name": "NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES",
  "contract_id": "8286203379234339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "333739787.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433028",
  "customer_name": "NRI System Techno, Ltd.",
  "contract_id": "8286377969095315",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "320057214.0"
}, {
  "vmware_agreement": "VMware Agreement # 00369261",
  "customer_name": "Japan Racing Association",
  "contract_id": "8285484944557803",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "314529072.0"
}, {
  "vmware_agreement": "00589663",
  "customer_name": "TOSHIBA CORPORATION",
  "contract_id": "8285720422567531",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "313671439.0"
}, {
  "vmware_agreement": "VMware Agreement # 00558790",
  "customer_name": "NAGAN PREFECTURE",
  "contract_id": "8286204050215910",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "289466791.0"
}, {
  "vmware_agreement": "00548899",
  "customer_name": "SEKISUI HOUSE, LTD.",
  "contract_id": "8285791246011780",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "287112153.0"
}, {
  "vmware_agreement": "VMware Agreement # 00397243",
  "customer_name": "Toppan Printing CO., LTD.",
  "contract_id": "8285058165420383",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "285179121.0"
}, {
  "vmware_agreement": "VMware Agreement # 00638793",
  "customer_name": "HOKUHOKU FINANCIAL GROUP INC",
  "contract_id": "8287928861275136",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "282633402.0"
}, {
  "vmware_agreement": "00549089",
  "customer_name": "HITACHI, LTD.",
  "contract_id": "8286555268440805",
  "contract_title": "ELA ORDER FORM",
  "fees": "247107006.0"
}, {
  "vmware_agreement": "00492070",
  "customer_name": "MINISTRY OF INTERNAL AFFAIRS AND COMMUNICATIONS",
  "contract_id": "8285730731005416",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "246359169.0"
}, {
  "vmware_agreement": "00360437",
  "customer_name": "NS Solutions Corporation",
  "contract_id": "8285897659390773",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "246041806.0"
}, {
  "vmware_agreement": "00374229",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284804635896033",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "245369222.0"
}, {
  "vmware_agreement": "00675391",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8285495287676973",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "239527426.0"
}, {
  "vmware_agreement": "00622789",
  "customer_name": "TEPCO POWER GRID, INCORPORATED",
  "contract_id": "8285121623921023",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "231512296.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MIZUHO BANK, LTD.",
  "contract_id": "8286148414921874",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "227400000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679388",
  "customer_name": "JAPAN SECURITIES DEPOSITORY CENTER, INC.",
  "contract_id": "8285572279813193",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "222692098.0"
}, {
  "vmware_agreement": "00620662",
  "customer_name": "JR EAST INFORMATION SYSTEMS COMPANY",
  "contract_id": "8286202767113254",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "221238621.0"
}, {
  "vmware_agreement": "00476553",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8285033030397369",
  "contract_title": "ELA ORDER FORM",
  "fees": "210498569.0"
}, {
  "vmware_agreement": "00428826",
  "customer_name": "Mazda Motor Corporation",
  "contract_id": "8285800991363448",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "205092005.0"
}, {
  "vmware_agreement": "00542947",
  "customer_name": "TOPPAN PRINTING CO. LTD",
  "contract_id": "8285720052545848",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195888500.0"
}, {
  "vmware_agreement": "VMware Agreement # 00443552",
  "customer_name": "Koto City Board Of Education",
  "contract_id": "8286470849027828",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195680960.0"
}, {
  "vmware_agreement": "006933821",
  "customer_name": "MAZDA MOTOR CORPORATION",
  "contract_id": "8285814854369709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195185294.0"
}, {
  "vmware_agreement": "00398318",
  "customer_name": "HONDA MOTOR CO., LTD.",
  "contract_id": "8286838907997103",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "192215160.0"
}, {
  "vmware_agreement": "00620266",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8285641501497143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191749658.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "NTT DOCOMO Inc.",
  "contract_id": "8840546302321300",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191251338.0"
}, {
  "vmware_agreement": "VMware Agreement # 00693063",
  "customer_name": "FUJITSU LIMITED",
  "contract_id": "8286147993302855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191178000.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "The Bank of Yokohama, Ltd.",
  "contract_id": "8284648389335976",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "189766118.0"
}, {
  "vmware_agreement": "00433680",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8286426437631938",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "189552517.0"
}, {
  "vmware_agreement": "VMware Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "DAIDO LIFE INSURANCE COMPANY",
  "contract_id": "8285719433511069",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "184388378.0"
}, {
  "vmware_agreement": "00707340",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8286687434813847",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "183060354.0"
}, {
  "vmware_agreement": "00438143",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284715293795161",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "183008000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373995",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8285538604856905",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "172853000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387464",
  "customer_name": "National Police Agency",
  "contract_id": "8286472061031125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "172234100.0"
}, {
  "vmware_agreement": "00378557",
  "customer_name": "AEON CREDIT SERVICE CO., LTD.",
  "contract_id": "8284873380552189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "169538169.0"
}, {
  "vmware_agreement": "VMware Agreement # 00395608",
  "customer_name": "Hachioji City",
  "contract_id": "8285790843092371",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "158448985.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478025",
  "customer_name": "MIZUHO BANK, LTD.",
  "contract_id": "8285405824392026",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "158380984.0"
}, {
  "vmware_agreement": "00548910",
  "customer_name": "SUZUKI MOTOR CORPORATION",
  "contract_id": "8285719768120207",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "156245097.0"
}, {
  "vmware_agreement": "00476481",
  "customer_name": "SQUARE ENIX CO. LTD",
  "contract_id": "8285952061082156",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "153346900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00621210",
  "customer_name": "JAPANESE RED CROSS AICHI MEDICALCENTER NAGOYA DAINI HOSPITAL",
  "contract_id": "8285858515587034",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "149931807.0"
}, {
  "vmware_agreement": "00691906",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285009303991363",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "148511040.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393599",
  "customer_name": "Koriyama City",
  "contract_id": "8285720587902808",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "146299533.0"
}, {
  "vmware_agreement": "00684366",
  "customer_name": "Tokyu Fudosan Holdings Corporation",
  "contract_id": "8286395599739189",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "145015095.0"
}, {
  "vmware_agreement": "VMware Agreement # 00091933",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284781975430421",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "144982265.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387000",
  "customer_name": "Bureau Of Waterworks Tokyo Metropolitan Government",
  "contract_id": "8286853951530054",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "142007954.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356153",
  "customer_name": "National Institute for Materials Science",
  "contract_id": "8601439566272512",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141408157.0"
}, {
  "vmware_agreement": "VMware Agreement # 00625643",
  "customer_name": "RECRUIT HOLDINGS CO.,LTD.",
  "contract_id": "8286396033282718",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141302981.0"
}, {
  "vmware_agreement": "VMware Agreement # 00567309",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285331885017096",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "139565374.0"
}, {
  "vmware_agreement": "VMware Agreement # 006787771",
  "customer_name": "HIROSHIMA CITY",
  "contract_id": "8285494634424434",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134770286.0"
}, {
  "vmware_agreement": "00358386",
  "customer_name": "Urban Renaissance Agency",
  "contract_id": "8286023247535867",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "134654390.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360759",
  "customer_name": "SUMITOMO MITSUI TRUST BANK LTD",
  "contract_id": "8285495346695793",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134442797.0"
}, {
  "vmware_agreement": "00433833",
  "customer_name": "JAL Information Technology Co., Ltd.",
  "contract_id": "8286121878134771",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132939760.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KOBE CITY BOARD OF EDUCATION",
  "contract_id": "8286357369705904",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "131989701.0"
}, {
  "vmware_agreement": "VMware Agreement # 00574833",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285720678403499",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128897181.0"
}, {
  "vmware_agreement": "00673154",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8285471605277851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0"
}, {
  "vmware_agreement": "00683650",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8286414546623922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00672781",
  "customer_name": "NATIONAL INSTITUTE OF INFORMATION AND COMMUNICATIONS TECHNOLOGY",
  "contract_id": "8285129615180808",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "127434025.0"
}, {
  "vmware_agreement": "00549090",
  "customer_name": "HITACHI, LTD.",
  "contract_id": "8285763231677663",
  "contract_title": "ELA ORDER FORM",
  "fees": "127041000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00494498",
  "customer_name": "THE UNIVERSITY OF TOKYO",
  "contract_id": "8285897665575932",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "126079427.0"
}, {
  "vmware_agreement": "00480180",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285679230025205",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "121221554.0"
}, {
  "vmware_agreement": "00540574",
  "customer_name": "NTT Plala Inc.",
  "contract_id": "8840162022872235",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "120963808.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MINISTRY OF THE ENVIRONMENT GOVERNMENT OF JAPAN",
  "contract_id": "8286270421429244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118839208.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433240",
  "customer_name": "Sumitomo Mitsui Trust Bank, Limited",
  "contract_id": "8286357534735480",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118826344.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368386",
  "customer_name": "Toyota Industries Corporation",
  "contract_id": "8840546304177855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118121612.0"
}, {
  "vmware_agreement": "VMware Agreement # 00391740",
  "customer_name": "The Chugoku Electric Power Co., Inc.",
  "contract_id": "8285746939479406",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "117846741.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398077",
  "customer_name": "Yokosuka City",
  "contract_id": "8286193358628335",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "111407319.0"
}, {
  "vmware_agreement": "VMware Agreement # 00700433",
  "customer_name": "JAPAN RACING ASSOCIATION",
  "contract_id": "8285829357824215",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "109488305.0"
}, {
  "vmware_agreement": "VMware Agreement # 00358915",
  "customer_name": "Osaka Municipal Waterworks Bureau",
  "contract_id": "8286023128056862",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "109007017.0"
}, {
  "vmware_agreement": "VMware Agreement # 00569483",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8285547104516441",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "105981777.0"
}, {
  "vmware_agreement": "00361046",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285940011840868",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "104914960.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382979",
  "customer_name": "Daiwa Lifenext Co. Ltd",
  "contract_id": "8286085346164631",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "104433117.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387319",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8286173708438988",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103745123.0"
}, {
  "vmware_agreement": "00489492",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8286805740186235",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "99637590.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367214",
  "customer_name": "ISID-AO, Ltd.",
  "contract_id": "8285813625955633",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98500000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379497",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8285802630868778",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98474405.0"
}, {
  "vmware_agreement": "00698116",
  "customer_name": "NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES",
  "contract_id": "8285777240557140",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "97873033.0"
}, {
  "vmware_agreement": "00378608",
  "customer_name": "NEC Corporation",
  "contract_id": "8285651775962743",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "96611840.0"
}, {
  "vmware_agreement": "VMware Agreement # 00575908",
  "customer_name": "JAPAN PENSION SERVICE",
  "contract_id": "8284972574021266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "93563985.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373743",
  "customer_name": "K-Opticom Corporation",
  "contract_id": "8285390677469596",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "92778400.0"
}, {
  "vmware_agreement": "VMware OEM Agreement",
  "customer_name": "Metropolitan Police Department",
  "contract_id": "8285157796065083",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "92309909.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549408",
  "customer_name": "TAIYO LIFE INSURANCE COMPANY",
  "contract_id": "8285868859427701",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "89967997.0"
}, {
  "vmware_agreement": "VMware Agreement # 00687761",
  "customer_name": "ROYAL PARK HOTELS AND RESORTS COMPANY, LIMITED.",
  "contract_id": "8286529292097859",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "89868455.0"
}, {
  "vmware_agreement": "VMware Agreement # 00498590",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8286038025949776",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88714429.0"
}, {
  "vmware_agreement": "VMware Agreement # 00362322",
  "customer_name": "Shinshu University Hospital",
  "contract_id": "8285484062436876",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88221526.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679421",
  "customer_name": "ASAHI HOSPITAL",
  "contract_id": "8284795292268974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "86507613.0"
}, {
  "vmware_agreement": "00559988",
  "customer_name": "EHIME PREFECTURE",
  "contract_id": "8285221842810155",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "85854769.0"
}, {
  "vmware_agreement": "VMware Agreement # 00383782",
  "customer_name": "Simplex Inc",
  "contract_id": "8286784284620871",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "85550493.0"
}, {
  "vmware_agreement": "VMware Agreement # 00444834",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8285694529244311",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "83727813.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KAGOSHIMA PREFECTURE",
  "contract_id": "8285344970897200",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "83302456.0"
}, {
  "vmware_agreement": "00391570",
  "customer_name": "SEKISUI PLASTICS CO., LTD.",
  "contract_id": "8286270324638052",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "81405405.0"
}, {
  "vmware_agreement": "00638988",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8840304764720246",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "79659346.0"
}, {
  "vmware_agreement": "VMware Agreement # 00551529",
  "customer_name": "City of Nagoya",
  "contract_id": "8285640784186691",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79566926.0"
}, {
  "vmware_agreement": "00545876",
  "customer_name": "TAKII \u0026 CO., LTD.",
  "contract_id": "8284873435726368",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79523600.0"
}, {
  "vmware_agreement": "00378305",
  "customer_name": "SECOM TRUST SYSTEMS CO., LTD.",
  "contract_id": "8285695225216160",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79495100.0"
}, {
  "vmware_agreement": "00444706",
  "customer_name": "EBARA CORPORATION",
  "contract_id": "8285992244377377",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78794480.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433855",
  "customer_name": "MITSUBISHI MOTORS CORPORATION",
  "contract_id": "8285651211145545",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78184311.0"
}, {
  "vmware_agreement": "00543300",
  "customer_name": "JAPAN POST Co., Ltd.",
  "contract_id": "8286395981234161",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78060831.0"
}, {
  "vmware_agreement": "VMware OEM Agreement; VMware Contract No. 00091933",
  "customer_name": "Ministry of Economy, Trade and Industry",
  "contract_id": "8286715802846307",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "77963777.0"
}, {
  "vmware_agreement": "VMware Agreement # 005452291",
  "customer_name": "NISHI IBURI WIDE AREA UNION",
  "contract_id": "8285764166691856",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "77184000.0"
}, {
  "vmware_agreement": "00569843",
  "customer_name": "TOKAI CORP.",
  "contract_id": "8285926578194598",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "76967182.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JAPAN METEOROLOGICAL AGENCY",
  "contract_id": "8286651206089275",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "75772536.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549580",
  "customer_name": "YURIHONJO CITY",
  "contract_id": "8285746983893931",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75500000.0"
}, {
  "vmware_agreement": "VMware Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "Ministry of Justice",
  "contract_id": "8286071000271649",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75427386.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386370",
  "customer_name": "Recruit Co. Ltd",
  "contract_id": "8285563687119123",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75009677.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "NATIONAL ASSOCIATION OF RACING",
  "contract_id": "8285306534856772",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "74563089.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "HEIWADO CO., LTD",
  "contract_id": "8285665269994587",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "74120063.0"
}, {
  "vmware_agreement": "VMware Agreement #00375413",
  "customer_name": "MITSUBISHI MOTORS CORPORATION",
  "contract_id": "8286202888872855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "73195000.0"
}, {
  "vmware_agreement": "00689806",
  "customer_name": "THE IYO BANK LTD",
  "contract_id": "8285679898526631",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "72019500.0"
}, {
  "vmware_agreement": "00375593",
  "customer_name": "SAGA-KEN MEDICAL CENTRE KOSEIKANE",
  "contract_id": "8285617638292962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "71612419.0"
}, {
  "vmware_agreement": "00367352",
  "customer_name": "Simplex Inc",
  "contract_id": "8285791209145066",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70839563.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "Ministry of Internal Affairs and Communications",
  "contract_id": "8285378174070673",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70774770.0"
}, {
  "vmware_agreement": "00492962",
  "customer_name": "OPTAGE Inc.",
  "contract_id": "8840498152216883",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70497531.0"
}, {
  "vmware_agreement": "VMware OEM Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "JAPAN POST BANK Co., Ltd.",
  "contract_id": "8286132785015043",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "69390324.0"
}, {
  "vmware_agreement": "VMware Agreement # 00561537",
  "customer_name": "KAGOSHIMA PREFECTURE",
  "contract_id": "8285694103179485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68825576.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386694",
  "customer_name": "Bureau Of Sewerage Tokyo Metropolitan Government",
  "contract_id": "8284828783974485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68511150.0"
}, {
  "vmware_agreement": "00444298",
  "customer_name": "DOCOMO Systems, Inc.",
  "contract_id": "8286022606258162",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68507482.0"
}, {
  "vmware_agreement": "VMware Agreement # 00567804",
  "customer_name": "NIPPON COMSYS CORPORATION",
  "contract_id": "8286716684380645",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67557873.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357046",
  "customer_name": "BRIDGESTONE CORPORATION",
  "contract_id": "8286022941891362",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67393128.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393668",
  "customer_name": "ICHIKAWA CITY BOARD OF EDUCATION",
  "contract_id": "8285678792960035",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67277700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00542977",
  "customer_name": "SQUARE ENIX CO. LTD.",
  "contract_id": "8288224434078472",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "66293800.0"
}, {
  "vmware_agreement": "VMware Agreement # 00397291",
  "customer_name": "TOKAI Corp.",
  "contract_id": "8285625442690168",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "66159732.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393657",
  "customer_name": "NIPPON CHEMI-CON CORPORATION",
  "contract_id": "8840485812780311",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65504736.0"
}, {
  "vmware_agreement": "00378053",
  "customer_name": "GlobalWafers Japan Co., Ltd.",
  "contract_id": "8285745823033713",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65115865.0"
}, {
  "vmware_agreement": "VMware Agreement # 00694313",
  "customer_name": "TSUBAKIMOTO KOGYO CO.,LTD.",
  "contract_id": "8285970337290088",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "62475500.0"
}, {
  "vmware_agreement": "00557224",
  "customer_name": "TOKYU FUDOSAN HOLDINGS CORPORATION",
  "contract_id": "8285110867806695",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "61878924.0"
}, {
  "vmware_agreement": "00494018",
  "customer_name": "SOFTBANK CORP.",
  "contract_id": "8840404707336179",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "60946781.0"
}, {
  "vmware_agreement": "VMware Agreement # 00492599",
  "customer_name": "MIYAZAKI PREFECTURE",
  "contract_id": "8285603271749539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60871356.0"
}, {
  "vmware_agreement": "00486149",
  "customer_name": "OGIS-RI Co.,Ltd.",
  "contract_id": "8286203827476781",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60622595.0"
}, {
  "vmware_agreement": "00679778",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285993237314702",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60461000.0"
}, {
  "vmware_agreement": "00362990",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8286838880788539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "60217144.0"
}, {
  "vmware_agreement": "00493392",
  "customer_name": "Gifu Prefectural Government",
  "contract_id": "8285587021122447",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59697972.0"
}, {
  "vmware_agreement": "VMware Agreement # 00381283",
  "customer_name": "ADVANTEST CORPORATION",
  "contract_id": "8286538635446665",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59602725.0"
}, {
  "vmware_agreement": "VMware Agreement # 00551301",
  "customer_name": "THE NORINCHUKIN BANK",
  "contract_id": "8286319220752175",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59438184.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382195",
  "customer_name": "Matsumoto City",
  "contract_id": "8285405908881280",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59027527.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393075",
  "customer_name": "Toyota Motor Corporation",
  "contract_id": "8286222192395043",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "58599151.0"
}, {
  "vmware_agreement": "00216417",
  "customer_name": "CHUBU ELECTRIC POWER CO., INC.",
  "contract_id": "8285470127172912",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "58324078.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00378990",
  "customer_name": "Asahi Hospital Service Corp.",
  "contract_id": "8285546467903411",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "57855249.0"
}, {
  "vmware_agreement": "VMware Agreement # 00442337",
  "customer_name": "Ota City",
  "contract_id": "8286235761295223",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57709943.0"
}, {
  "vmware_agreement": "VMware Agreement #00358123",
  "customer_name": "YAMAZAKI MAZAK CORPORATION",
  "contract_id": "8285868414515242",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57531393.0"
}, {
  "vmware_agreement": "VMware Agreement #00378142",
  "customer_name": "Yashima Denki Co., Ltd.",
  "contract_id": "8286768615717709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57379388.0"
}, {
  "vmware_agreement": "005456851",
  "customer_name": "HAKUHODO DY HOLDINGS INCORPORATED",
  "contract_id": "8285679806459126",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56744202.0"
}, {
  "vmware_agreement": "00637982",
  "customer_name": "JR EAST INFORMATION SYSTEMS",
  "contract_id": "8286822744226807",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "56657700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00474899",
  "customer_name": "SENDAI CITY",
  "contract_id": "8285926791641481",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56643000.0"
}, {
  "vmware_agreement": "00492054",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8285790954730763",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56264689.0"
}, {
  "vmware_agreement": "VMware Agreement #00379217",
  "customer_name": "JR Kyushu System Solutions Inc.",
  "contract_id": "8285815110715533",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55946859.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386047",
  "customer_name": "BUREAU OF TRANSPORTATION TOKYOMETROPOLITAN GOVERNMENT",
  "contract_id": "8285616349730185",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55434395.0"
}, {
  "vmware_agreement": "00557460",
  "customer_name": "OPTAGE INC",
  "contract_id": "8285641229722569",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55000000.0"
}, {
  "vmware_agreement": "00519708",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8284815186702304",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55000000.0"
}, {
  "vmware_agreement": "00497632",
  "customer_name": "Abiko city office",
  "contract_id": "8286193304534798",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54749461.0"
}, {
  "vmware_agreement": "VMware Agreement # 00494700",
  "customer_name": "ITEC HANKYU HANSHIN CO.,LTD.",
  "contract_id": "8285234991095545",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54673169.0"
}, {
  "vmware_agreement": "00494547",
  "customer_name": "OPTAGE INC",
  "contract_id": "8286173448884947",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54363302.0"
}, {
  "vmware_agreement": "00498756",
  "customer_name": "NIPPON STEEL CORPORATION",
  "contract_id": "8286055444616445",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54360197.0"
}, {
  "vmware_agreement": "00693028",
  "customer_name": "JB ADVANCED TECHNOLOGY CORPORATION",
  "contract_id": "8285097586178847",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54207000.0"
}, {
  "vmware_agreement": "00567179",
  "customer_name": "TOYOTA MOTOR CORPORATION",
  "contract_id": "8286122772227815",
  "contract_title": "Single ELA Resale Agreement OEM",
  "fees": "53998103.0"
}, {
  "vmware_agreement": "00706891",
  "customer_name": "EBARA CORPORATION",
  "contract_id": "8285909355023139",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "53776800.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360102",
  "customer_name": "Japan Post Trading Service Co., Ltd.",
  "contract_id": "8285572364477081",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "53341103.0"
}, {
  "vmware_agreement": "VMware Agreement # 00374639",
  "customer_name": "SUNDRUG CO., LTD.",
  "contract_id": "8286007604664897",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "53192128.0"
}, {
  "vmware_agreement": "95654 (Partner Master Terms) 95656 (VMware Distributor Exhibit) 129974 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "NOMURA SECURITIES CO. LTD",
  "contract_id": "8286084265699098",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "52494750.0"
}, {
  "vmware_agreement": "00545636",
  "customer_name": "J-POWER BUSINESS SERVICE CORPORATION",
  "contract_id": "9429076991245914",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "52210865.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367373",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286281820825714",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51548177.0"
}, {
  "vmware_agreement": "00561516",
  "customer_name": "OMUTA CITY OFFICE",
  "contract_id": "8285157351931237",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51538042.0"
}, {
  "vmware_agreement": "00544106",
  "customer_name": "Sapporo City",
  "contract_id": "8285678970791283",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51245885.0"
}, {
  "vmware_agreement": "VMware Agreement # 00645889",
  "customer_name": "Suntory Business Systems Ltd.",
  "contract_id": "8286619698724280",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50676888.0"
}, {
  "vmware_agreement": "VMware Agreement # 00678948",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286874809046061",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50104935.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Oji Holdings Corporation",
  "contract_id": "8285791515863459",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "49017342.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356450",
  "customer_name": "Mizuho Bank, Ltd.",
  "contract_id": "8285617726173382",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "48807985.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KYUSHU ELECTRIC POWER CO., INC.",
  "contract_id": "8285968452928590",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47000005.0"
}, {
  "vmware_agreement": "00187463",
  "customer_name": "SUMITOMO MITSUI TRUST BANK, LIMITED",
  "contract_id": "8286414203035463",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46599825.0"
}, {
  "vmware_agreement": "VMware Agreement # 00550984",
  "customer_name": "YUZAWA CITY",
  "contract_id": "8285378732666635",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46507350.0"
}, {
  "vmware_agreement": "VMware Agreement # 00432298",
  "customer_name": "TOPPAN PRINTING CO. LTD",
  "contract_id": "8285537149943916",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46038436.0"
}, {
  "vmware_agreement": "00491939",
  "customer_name": "Freddie Mac",
  "contract_id": "8286132798851430",
  "contract_title": "Amendment No. 4 to Single ELA Resale Agreement",
  "fees": "45992149.58"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO., LTD.",
  "contract_id": "8285650579169461",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "45935170.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373590",
  "customer_name": "Oita City Office",
  "contract_id": "8285129039925947",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45772919.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Jupiter Telecommunications Co. Ltd.",
  "contract_id": "8286769245711424",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45640630.0"
}, {
  "vmware_agreement": "00441480",
  "customer_name": "INES CORPORATION",
  "contract_id": "8285880732331206",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "45430522.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398555",
  "customer_name": "Daiwa Institute of Research BusinessInnovation Ltd.",
  "contract_id": "8286203304903102",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45233760.0"
}, {
  "vmware_agreement": "00431611",
  "customer_name": "Chuden CTI CO.,Ltd.",
  "contract_id": "8285680150219737",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "45165732.0"
}, {
  "vmware_agreement": "00497108",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8285121487131967",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "45110757.0"
}, {
  "vmware_agreement": "00443845",
  "customer_name": "Minato City",
  "contract_id": "8285940008309036",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "44550038.0"
}, {
  "vmware_agreement": "00497801",
  "customer_name": "JECC Corporation",
  "contract_id": "8285640063426659",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "44514288.0"
}, {
  "vmware_agreement": "00680122",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8840403890037821",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "44320027.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8285471704142805",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43897347.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379395",
  "customer_name": "JECC Corporation",
  "contract_id": "8286161176323050",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "43897347.0"
}, {
  "vmware_agreement": "00339482",
  "customer_name": "JECC Corporation",
  "contract_id": "8285880553346390",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "00363007",
  "customer_name": "JECC Corporation",
  "contract_id": "8286172402890379",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8285969483069130",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8286768341166726",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386445",
  "customer_name": "Ishinomaki-City",
  "contract_id": "8285447991349841",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43752325.0"
}, {
  "vmware_agreement": "VMware Agreement # 00687708",
  "customer_name": "J-POWER BUSINESS SERVICE CORPORATION",
  "contract_id": "8285858540733727",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43685481.0"
}, {
  "vmware_agreement": "00358518",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8284935399941643",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "43567590.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "TAIYO LIFE INSURANCE COMPANY",
  "contract_id": "8285271514355322",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "43527360.0"
}, {
  "vmware_agreement": "VMware Agreement # 00622767",
  "customer_name": "SAKURA CITY HALLE",
  "contract_id": "8285762347748458",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43366598.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379374",
  "customer_name": "DENSO CORPORATION",
  "contract_id": "8284948991006664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42994829.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393930",
  "customer_name": "SHIKOKU ELECTRIC POWER CO., INC.",
  "contract_id": "8284984462532751",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42856434.0"
}, {
  "vmware_agreement": "00692077",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8285493805977435",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00477834",
  "customer_name": "Sumitomo Rubber Industries LTD",
  "contract_id": "8285898163100143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "00589641",
  "customer_name": "NTT PC COMMUNICATIONS INCORPORATED",
  "contract_id": "8285537512217555",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356558",
  "customer_name": "Bukkyo University",
  "contract_id": "8286587742323142",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42377700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00443913",
  "customer_name": "NATIONAL DIET LIBRARY",
  "contract_id": "8286055690339894",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42050000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00439857",
  "customer_name": "PHARMACEUTICALS AND MEDICALDEVICES AGENCY",
  "contract_id": "8285626117692139",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41758992.0"
}, {
  "vmware_agreement": "00474657",
  "customer_name": "HIKONE CITY",
  "contract_id": "8285763312630637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41660076.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "JAPAN HADES CO LTD",
  "contract_id": "8286236017178425",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41527024.0"
}, {
  "vmware_agreement": "00469657",
  "customer_name": "JMS CO., LTD.",
  "contract_id": "8288190336987025",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41506300.0"
}, {
  "vmware_agreement": "114417",
  "customer_name": "TAK CO.,LTD.",
  "contract_id": "8286442435411264",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "41506300.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356559",
  "customer_name": "Fukuchiyama City",
  "contract_id": "8286006939348131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "40893157.0"
}, {
  "vmware_agreement": "00688996",
  "customer_name": "HAMAMATSU IWATA SHINKIN BANK",
  "contract_id": "8284984662572819",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "40600000.0"
}, {
  "vmware_agreement": "00674311",
  "customer_name": "JCOM CO., LTD",
  "contract_id": "8285801590176109",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "39760466.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387321",
  "customer_name": "AISIN SEIKI Co.,Ltd.",
  "contract_id": "8286698374508133",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "39447254.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KASAMA CITY",
  "contract_id": "8285791861756536",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38800682.0"
}, {
  "vmware_agreement": "VMware Agreement # 00569184",
  "customer_name": "SAKAI CITY",
  "contract_id": "8285992914793301",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38736811.0"
}, {
  "vmware_agreement": "VMware Agreement # 00391920",
  "customer_name": "FUKAYA CITY BOARD OF EDUCATION",
  "contract_id": "8285509950665330",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38705357.0"
}, {
  "vmware_agreement": "00620461",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8285345054240627",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38650000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373355",
  "customer_name": "Sapporo Information Network Co Ltd",
  "contract_id": "8285968961620558",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38199609.0"
}, {
  "vmware_agreement": "00600105",
  "customer_name": "DISH Purchasing Corporation",
  "contract_id": "8285234532205543",
  "contract_title": "Network License Agreement",
  "fees": "37500000.0"
}, {
  "vmware_agreement": "00492052",
  "customer_name": "DATE CITY HALLE",
  "contract_id": "8285641512678872",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "37307887.0"
}, {
  "vmware_agreement": "00631531",
  "customer_name": "SUMITOMO LIFE INFORMATION SYSTEMCO.,LTD.",
  "contract_id": "8285939832763703",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "37040500.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "H20 Retailing Corporation",
  "contract_id": "8285938988120636",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "36936900.0"
}, {
  "vmware_agreement": "00187815",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285317893638147",
  "contract_title": "SINGLE ELA RESALE AGR ENT",
  "fees": "36641960.0"
}, {
  "vmware_agreement": "00631498",
  "customer_name": "DOCOMO SYSTEMS, INC.",
  "contract_id": "8285538561277673",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "36592500.0"
}, {
  "vmware_agreement": "00709023",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8285993206907789",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "36293096.0"
}, {
  "vmware_agreement": "00693711",
  "customer_name": "HAAGEN-DAZS JAPAN, INC.",
  "contract_id": "8285814116452944",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35998777.0"
}, {
  "vmware_agreement": "VMware Agreement # 00503797",
  "customer_name": "USJ LLC",
  "contract_id": "8286748243736084",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35995080.0"
}, {
  "vmware_agreement": "00362989",
  "customer_name": "K-OPTICOM CORPORATION",
  "contract_id": "8285694265860622",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "35823901.0"
}, {
  "vmware_agreement": "00437904",
  "customer_name": "Toyota Motor Corporation",
  "contract_id": "8285456252974204",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35739172.0"
}, {
  "vmware_agreement": "VMware OEM Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "URBAN RENAISSANCE AGENCY",
  "contract_id": "8285221174970400",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35625756.0"
}, {
  "vmware_agreement": "00576309",
  "customer_name": "HARP Co., Ltd",
  "contract_id": "8285290838618743",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35458967.0"
}, {
  "vmware_agreement": "00442337",
  "customer_name": "OTA CITY",
  "contract_id": "8285563047799096",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35301504.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679768",
  "customer_name": "KITAKYUSHU CITY BOARD OF EDUCATION",
  "contract_id": "8285695092612400",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35039936.0"
}, {
  "vmware_agreement": "VMware Enterprise Agreement",
  "customer_name": "Rakuten Group, Inc.",
  "contract_id": "8286457438887400",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35029129.0"
}, {
  "vmware_agreement": "00646071",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8285707469877678",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34991484.0"
}, {
  "vmware_agreement": "005464041",
  "customer_name": "FUJITRANS CORPORATION",
  "contract_id": "8285495169816024",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34926320.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00581748",
  "customer_name": "Mitsubishi UFJ Financial Group Inc",
  "contract_id": "8287425450848955",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "34913667.0"
}, {
  "vmware_agreement": "VMware Agreement # 00187797.0",
  "customer_name": "JAPAN POST BANK CO.,LTD",
  "contract_id": "8286733158706893",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34650089.0"
}, {
  "vmware_agreement": "00373879",
  "customer_name": "US Army",
  "contract_id": "8286303939954534",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "34503645.64"
}, {
  "vmware_agreement": "VMware Agreement # 00432774",
  "customer_name": "NTT DATA MHI SYSTEMS CORPORATION",
  "contract_id": "8285508777181171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34180397.0"
}, {
  "vmware_agreement": "00636960",
  "customer_name": "LAC CO. LTD",
  "contract_id": "8285562900815630",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33695012.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549756",
  "customer_name": "KAJIMA CORPORATION",
  "contract_id": "8285318642182266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33593616.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386328",
  "customer_name": "Sumida City",
  "contract_id": "8286333656482080",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33138798.0"
}, {
  "vmware_agreement": "114417",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8286203174869894",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "32664600.0"
}, {
  "vmware_agreement": "00543941",
  "customer_name": "Ube city",
  "contract_id": "8285939314758735",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "32060722.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "ICHINOSEKI CITY",
  "contract_id": "8286121513024047",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31685060.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398255",
  "customer_name": "UNIVERSITY OF HYOGO",
  "contract_id": "8286441033234958",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31595669.0"
}, {
  "vmware_agreement": "00447768",
  "customer_name": "TOYOTA MOTOR CORPORATION",
  "contract_id": "8286687501990952",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "31452650.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "Ministry of Foreign Affairs",
  "contract_id": "8285843655812518",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "31243319.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "JAPAN RACING ASSOCIATION",
  "contract_id": "8286635850383543",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30190280.0"
}, {
  "vmware_agreement": "VMware Agreement # 00489851",
  "customer_name": "ZOZO TECHNOLOGIES INC",
  "contract_id": "8286320388398913",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30000000.0"
}, {
  "vmware_agreement": "00685130",
  "customer_name": "Boeing",
  "contract_id": "8284683603190343",
  "contract_title": "ELA ORDER FORM",
  "fees": "29207398.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Okayama Prefectural Government",
  "contract_id": "8285484795522577",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "29200238.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JX NIPPON MINING \u0026 METALS CORPORATION",
  "contract_id": "8286069419616239",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28757625.0"
}, {
  "vmware_agreement": "00179161 (VMware Authorized Distributor Program Agreement) 00179169 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "RECRUIT HOLDINGS CO.,LTD.",
  "contract_id": "8286685848382346",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28502871.0"
}, {
  "vmware_agreement": "00694816",
  "customer_name": "SONY GLOBAL SOLUTIONS INC.",
  "contract_id": "8840414587713057",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28391900.0"
}, {
  "vmware_agreement": "00545440",
  "customer_name": "MITSUI KNOWLEDGE INDUSTRY CO., LTD.",
  "contract_id": "8840554131838677",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28222190.0"
}, {
  "vmware_agreement": "VMware Agreement #00378149",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8286717599427399",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28001899.0"
}, {
  "vmware_agreement": "00494091",
  "customer_name": "SAKAI CITY",
  "contract_id": "8285291777451152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "26951814.0"
}, {
  "vmware_agreement": "00692629",
  "customer_name": "ASAHI KASEI NETWORKS CORPORATION",
  "contract_id": "8285604348793657",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "26492906.0"
}, {
  "vmware_agreement": "00474540",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8286785655592174",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "25310000.0"
}, {
  "vmware_agreement": "00690975",
  "customer_name": "Highmark, Inc.",
  "contract_id": "8285373173277149",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "25298252.57"
}, {
  "vmware_agreement": "VMware Agreement # 00431601",
  "customer_name": "Mitsui Fudosan Co., Ltd",
  "contract_id": "8840372570033962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "25101000.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "HITACHI SOLUTIONS, LTD",
  "contract_id": "8285899353219106",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24857051.0"
}, {
  "vmware_agreement": "00639432",
  "customer_name": "Cigna Corporate Services, LLC",
  "contract_id": "8284936422857369",
  "contract_title": "Amendment # 13 TO THE ELA ORDER FORM",
  "fees": "24488700.0"
}, {
  "vmware_agreement": "00639432",
  "customer_name": "Cigna Corporate Services, LLC",
  "contract_id": "8285203964208338",
  "contract_title": "Amendment # 13 TO THE ELA ORDER FORM",
  "fees": "24488700.0"
}, {
  "vmware_agreement": "00673507",
  "customer_name": "KOCHI CITY",
  "contract_id": "8286618969848244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24395500.0"
}, {
  "vmware_agreement": "VMware Agreement # 00179161 (VMware Authorized Distributor Program Agreement)1 No. 00179169 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "ZOZO TECHNOLOGIES INC",
  "contract_id": "8286086034616546",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24250000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00692773",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8285484806787761",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "23104224.0"
}, {
  "vmware_agreement": "VMware Agreement # 00588537",
  "customer_name": "Shizuoka City Office",
  "contract_id": "8285745343529727",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "23011907.0"
}, {
  "vmware_agreement": "00433220",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8285485038954937",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "22774176.0"
}, {
  "vmware_agreement": "00188977",
  "customer_name": "KOCHI UNIVERSITY OF TECHNOLOGY",
  "contract_id": "8285235265276800",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22682968.0"
}, {
  "vmware_agreement": "00545476",
  "customer_name": "SETAGAYA CITY BOARD OF EDUCATION",
  "contract_id": "8286223412323902",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22358500.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "ZOZO Technologies, Inc.",
  "contract_id": "8286768570124472",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22000000.0"
}, {
  "vmware_agreement": "00378302",
  "customer_name": "OPTAGE Inc.",
  "contract_id": "8840178787201507",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "21904701.0"
}, {
  "vmware_agreement": "00547256",
  "customer_name": "Shoko Chukin Bank Ltd.",
  "contract_id": "8285331484234806",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "21041155.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373204",
  "customer_name": "TepcoSystems",
  "contract_id": "8286805599968199",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "20257600.0"
}, {
  "vmware_agreement": "00672259",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8285418778541983",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "20169359.19"
}, {
  "vmware_agreement": "00378566",
  "customer_name": "OSAKA CITY BOARD OF EDUCATION",
  "contract_id": "8285663852826851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19516400.0"
}, {
  "vmware_agreement": "00477897",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285072551506028",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19464378.97"
}, {
  "vmware_agreement": "00428152",
  "customer_name": "Suntory Business Systems Limited",
  "contract_id": "8285719618772856",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "19040600.0"
}, {
  "vmware_agreement": "00435321",
  "customer_name": "TEPCO SYSTEMS CORPORATION",
  "contract_id": "8286804984057355",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "18677000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433357",
  "customer_name": "China Telecom Corporation Limited 中国电信",
  "contract_id": "8286716012273786",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "18572127.85"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "ORIENTAL LAND CO., LTD.",
  "contract_id": "8840482270664831",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "18043750.0"
}, {
  "vmware_agreement": "00547096",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8285969683452015",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement (OEM)",
  "fees": "17717778.0"
}, {
  "vmware_agreement": "VMware Agreement # 00498570",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8284521192806278",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "17471651.13"
}, {
  "vmware_agreement": "00671232",
  "customer_name": "Smarsh Inc.",
  "contract_id": "8285110100212755",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "16709537.8"
}, {
  "vmware_agreement": "00433671",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285495573249626",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "16376802.0"
}, {
  "vmware_agreement": "005431171",
  "customer_name": "Ricoh Company, Ltd.",
  "contract_id": "8286132072150539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16340000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00558798",
  "customer_name": "China Construction Bank Corporation",
  "contract_id": "8285455467606915",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "16332377.6"
}, {
  "vmware_agreement": "VMware Agreement # 00367892",
  "customer_name": "iret, Inc.",
  "contract_id": "8285344843759940",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16173600.0"
}, {
  "vmware_agreement": "00495536",
  "customer_name": "NTT DOCOMO INC.",
  "contract_id": "8286472890911599",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16000000.0"
}, {
  "vmware_agreement": "00433029",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286069628254007",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "15993839.0"
}, {
  "vmware_agreement": "00360871",
  "customer_name": "Zenhoren Co.,Ltd.",
  "contract_id": "8285730070128565",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "15280956.0"
}, {
  "vmware_agreement": "00330411",
  "customer_name": "NTT PC Communications Incorporated",
  "contract_id": "8285830264154753",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "15049819.0"
}, {
  "vmware_agreement": "00477310",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285762360370452",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "14973469.0"
}, {
  "vmware_agreement": "00675699",
  "customer_name": "AT\u0026T Services Inc.",
  "contract_id": "8285289667616392",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "14893490.56"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Kintetsu Group Holdings Co. Ltd",
  "contract_id": "8286927337518318",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14889300.0"
}, {
  "vmware_agreement": "VMware Agreement #00396861",
  "customer_name": "Australian Signals Directorate",
  "contract_id": "8285157088134027",
  "contract_title": "ELA ORDER FORM",
  "fees": "14830050.08"
}, {
  "vmware_agreement": "00610418",
  "customer_name": "DNP INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8286086324293077",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14804389.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00554095",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8285992937741211",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "14660540.0"
}, {
  "vmware_agreement": "00430089",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8286717389071539",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14466425.0"
}, {
  "vmware_agreement": "00567798",
  "customer_name": "NIPPON COMSYS CORPORATION",
  "contract_id": "8286054289492265",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14300000.0"
}, {
  "vmware_agreement": "00497142",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8284957166902881",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14283635.0"
}, {
  "vmware_agreement": "VMware Agreement # 00425762",
  "customer_name": "China Mobile Information Technology Company Limited 中移信息技术有限公司",
  "contract_id": "8284936431122523",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14049405.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357295",
  "customer_name": "Oriental Land Co.,Ltd.",
  "contract_id": "8285536789337141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14040810.0"
}, {
  "vmware_agreement": "VMware Agreement #00362529",
  "customer_name": "JPMorgan Chase Bank, National Association",
  "contract_id": "8285564156341094",
  "contract_title": "Amendment No. 16 to the ELA and the Software License and Maintenance Agreement",
  "fees": "14032202.8"
}, {
  "vmware_agreement": "00631902",
  "customer_name": "Charles Schwab \u0026 Co., Inc.",
  "contract_id": "8284816531515027",
  "contract_title": "Amendment no. 13 to ELA Order Form between Charles Schwab and VMware, Inc.",
  "fees": "14001812.0"
}, {
  "vmware_agreement": "VMware Agreement # 00434232",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8286456968378837",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14000000.0"
}, {
  "vmware_agreement": "00477243",
  "customer_name": "State Of Oklahoma / Office Of Management and Enterprise Services",
  "contract_id": "8285858246399397",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "13210396.65"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "SONY GLOBAL SOLUTIONS INC.",
  "contract_id": "8286202467054278",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "13104700.0"
}, {
  "vmware_agreement": "00425090",
  "customer_name": "ADP, LLC",
  "contract_id": "8286717080574359",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "12999414.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357107",
  "customer_name": "CHINA NATIONAL PETROLEUM CORPORATION 中国石油天然气集团有限公司",
  "contract_id": "8285471107451970",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "12864693.48"
}, {
  "vmware_agreement": "00393903",
  "customer_name": "Oriental Land Co., Ltd.",
  "contract_id": "8286570958796625",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "12863532.0"
}, {
  "vmware_agreement": "00447770",
  "customer_name": "THE CHIBA CITY BOARD OF EDUCATION",
  "contract_id": "8286172517697682",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12854044.0"
}, {
  "vmware_agreement": "00544077",
  "customer_name": "NET ONE SYSTEMS CO., LTD.",
  "contract_id": "8285493803213564",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12819485.0"
}, {
  "vmware_agreement": "00435604",
  "customer_name": "FEDEX CORPORATE SERVICES, INC.",
  "contract_id": "8840482647496279",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "12683936.17"
}, {
  "vmware_agreement": "00430588",
  "customer_name": "Salesforce.com, Inc.",
  "contract_id": "8285111229027699",
  "contract_title": "Amendment No. 12 to the ELA Order",
  "fees": "12379800.0"
}, {
  "vmware_agreement": "00495348",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8286717090611905",
  "contract_title": "Enterprise License Agreement Schedule #1 to the Master Agreement",
  "fees": "11981327.0"
}, {
  "vmware_agreement": "00680129",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8287408367010845",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "11863945.0"
}, {
  "vmware_agreement": "00565340",
  "customer_name": "DBS BANK LTD.",
  "contract_id": "8285617815363353",
  "contract_title": "ELA ORDER FORM",
  "fees": "11640000.0"
}, {
  "vmware_agreement": "00215901",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8284973389012039",
  "contract_title": "AMENDMENT NO. 3 TO ADDENDUM NO.11 TO ELA ORDER",
  "fees": "11545668.0"
}, {
  "vmware_agreement": "00588018",
  "customer_name": "Johnson Controls, Inc",
  "contract_id": "8286928158773937",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "11465900.98"
}, {
  "vmware_agreement": "VMware Agreement # 00401340",
  "customer_name": "Resources Link Software Systems(Shenzhen) Company Limited",
  "contract_id": "8285407688203664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "11190633.79"
}, {
  "vmware_agreement": "VMware Agreement # 00474081",
  "customer_name": "Uniadex Ltd",
  "contract_id": "8286333602300037",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "11140000.0"
}, {
  "vmware_agreement": "00486935",
  "customer_name": "FUJI SOFT INC",
  "contract_id": "8286204235153182",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "10790000.0"
}, {
  "vmware_agreement": "00360744.0",
  "customer_name": "Banco Santander Brasil S/A",
  "contract_id": "8840421963064776",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "10236610.0"
}, {
  "vmware_agreement": "00678371",
  "customer_name": "Fidelity Technology Group, LLC",
  "contract_id": "8285059424125902",
  "contract_title": "VMware Enterprise Order",
  "fees": "10001001.82"
}, {
  "vmware_agreement": "00560511",
  "customer_name": "THE CAPITAL GROUP COMPANIES, INC.",
  "contract_id": "8284727211047113",
  "contract_title": "Amendment No. 11 to the ELA Order",
  "fees": "9847843.52"
}, {
  "vmware_agreement": "00497112",
  "customer_name": "CHUDEN CTI CO.,LTD.",
  "contract_id": "8285815591126047",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "9500250.0"
}, {
  "vmware_agreement": "00625957",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285952203400606",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "9487913.0"
}, {
  "vmware_agreement": "VMware Agreement #00398240.0",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8840294167088687",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "9427857.0"
}, {
  "vmware_agreement": "00551303",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8286174301884113",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "9253919.22"
}, {
  "vmware_agreement": "00692394",
  "customer_name": "Tesla Inc",
  "contract_id": "8285680087939156",
  "contract_title": "ELA ORDER FORM",
  "fees": "9126648.0"
}, {
  "vmware_agreement": "00488975",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8285008874780639",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "8883872.84"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Telefonica Brasil",
  "contract_id": "8285483562185219",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "7908750.66"
}, {
  "vmware_agreement": "VMware Agreement # 00392699",
  "customer_name": "FedEx",
  "contract_id": "8286008376190004",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "7769966.38"
}, {
  "vmware_agreement": "VMware Agreement # 00478982",
  "customer_name": "State Taxation Administration国家税务总局",
  "contract_id": "8286821438887585",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "7265386.72"
}, {
  "vmware_agreement": "00186613",
  "customer_name": "Federal Bureau of Investigation",
  "contract_id": "8285330529768606",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "7135584.0"
}, {
  "vmware_agreement": "00694083",
  "customer_name": "Jack Henry \u0026 Associates, Inc.",
  "contract_id": "8285306139552593",
  "contract_title": "Amendment 13 to the ELA Order Form",
  "fees": "7120190.93"
}, {
  "vmware_agreement": "00684829 \u0026 00691196",
  "customer_name": "New York Life Insurance Company",
  "contract_id": "8285495023424230",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "6957040.93"
}, {
  "vmware_agreement": "00368936",
  "customer_name": "Lyft, Inc.",
  "contract_id": "8840252263746323",
  "contract_title": "ELA ORDER FORM",
  "fees": "6426000.0"
}, {
  "vmware_agreement": "00425058",
  "customer_name": "PricewaterhouseCoopers Global Licensing Services Corporation",
  "contract_id": "8285587939504864",
  "contract_title": "ELA #5",
  "fees": "6381366.73"
}, {
  "vmware_agreement": "VMware Agreement # 00545439",
  "customer_name": "MITSUI KNOWLEDGE INDUSTRY CO., LTD.",
  "contract_id": "8285168431527591",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6347000.0"
}, {
  "vmware_agreement": "00493009",
  "customer_name": "KYOSAN ELECTRIC MANUFACTURING CO.,LTD.",
  "contract_id": "8284925186102461",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6246250.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00164670",
  "customer_name": "RYOKA SYSTEMS INC.",
  "contract_id": "8285813633325757",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "6185406.0"
}, {
  "vmware_agreement": "VMware Agreement # 00424123",
  "customer_name": "China Merchants Bank Co. Ltd 招商银行股份有限公司",
  "contract_id": "8285510080006747",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6158403.41"
}, {
  "vmware_agreement": "00607 190",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8285169331779220",
  "contract_title": "VMware ELA",
  "fees": "5772864.82"
}, {
  "vmware_agreement": "00567436",
  "customer_name": "Caisse De Depot Et Placement",
  "contract_id": "8284996493112662",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "5753659.53"
}, {
  "vmware_agreement": "VMware Agreement # 00674551",
  "customer_name": "Fairfax County Government",
  "contract_id": "8285663557593139",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "5522937.04"
}, {
  "vmware_agreement": "VMware Agreement # 00367434",
  "customer_name": "State Taxation Administration国家税务总局",
  "contract_id": "8285143735887717",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5349107.79"
}, {
  "vmware_agreement": "00360995",
  "customer_name": "MDA",
  "contract_id": "8285129113050799",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "5318750.51"
}, {
  "vmware_agreement": "00690997",
  "customer_name": "University of Texas At San Antonio (MAIN)",
  "contract_id": "8284682336835380",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "5294555.83"
}, {
  "vmware_agreement": "VMware Agreement # 00478016",
  "customer_name": "China Unicom Communication Ltd中国联合网络通信集团有限公司",
  "contract_id": "8286191786477192",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5263217.39"
}, {
  "vmware_agreement": "VMware Agreement #00356217",
  "customer_name": "California State Compensation Insurance Fund (SCIF)",
  "contract_id": "8286103494333661",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5115502.81"
}, {
  "vmware_agreement": "00591309",
  "customer_name": "Target Corporation",
  "contract_id": "8285616962324832",
  "contract_title": "ELA ORDER FORM",
  "fees": "5106620.41"
}, {
  "vmware_agreement": "00566800",
  "customer_name": "Australian Bureau Of Statistics (ABS)",
  "contract_id": "8284674748595017",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "5103301.82"
}, {
  "vmware_agreement": "00538732",
  "customer_name": "Department of Defence",
  "contract_id": "8285373220881269",
  "contract_title": "ELA ORDER FORM",
  "fees": "5052294.84"
}, {
  "vmware_agreement": "00308778",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285291123188841",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "5000000.0"
}, {
  "vmware_agreement": "00274824",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285908659683239",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "5000000.0"
}, {
  "vmware_agreement": "00188571",
  "customer_name": "Us Army Medical Command",
  "contract_id": "8285829674305962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4997589.17"
}, {
  "vmware_agreement": "VMware Agreement #00549612",
  "customer_name": "China International Capital Corporation Limited",
  "contract_id": "8286604303579551",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4908047.73"
}, {
  "vmware_agreement": "00473678",
  "customer_name": "DNP DIGITAL SOLUTIONS CO. LTD",
  "contract_id": "8286376493942811",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4900000.0"
}, {
  "vmware_agreement": "00592065",
  "customer_name": "Paypal, Inc. (Main)",
  "contract_id": "8285470190428211",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "4699264.78"
}, {
  "vmware_agreement": "00188134",
  "customer_name": "Proquire LLC",
  "contract_id": "8284805602872320",
  "contract_title": "ELA ORDER FORM",
  "fees": "4652376.47"
}, {
  "vmware_agreement": "VMware Agreement # 00478753",
  "customer_name": "Ministry Of Ecology And Environment Of The People\u0027s Republic Of China",
  "contract_id": "8285086081174320",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4605651.0"
}, {
  "vmware_agreement": "VMware Agreement # 00486655",
  "customer_name": "Chickasaw Nation",
  "contract_id": "8285448036449254",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4445111.75"
}, {
  "vmware_agreement": "00620553",
  "customer_name": "California State Compensation Insurance Fund (SCIF)",
  "contract_id": "8286346505983084",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4422138.12"
}, {
  "vmware_agreement": "VMW-11857",
  "customer_name": "Toyota Motor Credit Corporation",
  "contract_id": "8286717099803750",
  "contract_title": "Amendment No. 1 (Contract ID: REQ1243157) to the ELA Order Form (REQ1068444) to Master Software License Agreement (Contract ID: VMW-11857)",
  "fees": "4386055.47"
}, {
  "vmware_agreement": "VMware Agreement # 00589146",
  "customer_name": "China Development Bank(国家开发银行)",
  "contract_id": "8840494659349754",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4363729.72"
}, {
  "vmware_agreement": "00691269",
  "customer_name": "Q2 Software, Inc.",
  "contract_id": "8284937031837839",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "4278714.73"
}, {
  "vmware_agreement": "VMware Agreement # 00359841",
  "customer_name": "State of Missouri",
  "contract_id": "8286471849317119",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4179258.5"
}, {
  "vmware_agreement": "VMware Agreement # 00551308",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8284842232331367",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4088217.6"
}, {
  "vmware_agreement": "00540348",
  "customer_name": "Northrop Grumman Corp",
  "contract_id": "8286254563935870",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4074380.0"
}, {
  "vmware_agreement": "00673276",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8284120177040702",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "4066383.6"
}, {
  "vmware_agreement": "00610367",
  "customer_name": "Capital One Services LLC, formerly known as Capital One Services, Inc., on behalf of itself and its Affiliates",
  "contract_id": "8285128826390699",
  "contract_title": "Capital One Services LLC, formerly known as Capital One Services, Inc., on behalf of itself and its Affiliates",
  "fees": "4065952.0"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Employment Development",
  "contract_id": "8285178395714221",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4046449.13"
}, {
  "vmware_agreement": "00572171",
  "customer_name": "Federal Bureau of Investigation",
  "contract_id": "8284658957448052",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4043047.76"
}, {
  "vmware_agreement": "00706724",
  "customer_name": "Teledyne Technologies Incorporated",
  "contract_id": "8285178522424280",
  "contract_title": "VMware Enterprise Order",
  "fees": "4009096.56"
}, {
  "vmware_agreement": "VMware Agreement # 00492417",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285721267842887",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4000000.0"
}, {
  "vmware_agreement": "00397705",
  "customer_name": "SB C\u0026S CORP.",
  "contract_id": "8284391764446993",
  "contract_title": "ELA ORDER FORM",
  "fees": "4000000.0"
}, {
  "vmware_agreement": "VMware OEM Agreement",
  "customer_name": "Ningde Times Shanghai Intelligent Technology Co., Ltd.",
  "contract_id": "8286854012962260",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "3995326.2"
}, {
  "vmware_agreement": "00375654",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285194783097536",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3990000.0"
}, {
  "vmware_agreement": "00551811",
  "customer_name": "Universal Health Services, Inc",
  "contract_id": "8284972303178714",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "3972872.0"
}, {
  "vmware_agreement": "00424454",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8285693887904581",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3957600.0"
}, {
  "vmware_agreement": "00494464",
  "customer_name": "Alabama Community College System",
  "contract_id": "8288272605460777",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "3864339.12"
}, {
  "vmware_agreement": "VMware Agreement #00391993",
  "customer_name": "FAW-Volkswagen Automotive Co. Ltd一汽-大众汽车有限公司",
  "contract_id": "8286305081257632",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3862293.48"
}, {
  "vmware_agreement": "00545223",
  "customer_name": "FUJISOFT INCORPORATED",
  "contract_id": "8840399481613209",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3850000.0"
}, {
  "vmware_agreement": "00388962",
  "customer_name": "E-Government National Centre",
  "contract_id": "8284499182696494",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "3809552.5"
}, {
  "vmware_agreement": "00216361",
  "customer_name": "Ebay Inc.",
  "contract_id": "8285158544572155",
  "contract_title": "VMware ELA Order Form",
  "fees": "3763935.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478509",
  "customer_name": "Credit Reference Center Pboc中国人民银行征信中心",
  "contract_id": "8285693449278385",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3745251.41"
}, {
  "vmware_agreement": "00218210",
  "customer_name": "EPIC HOSTING LLC",
  "contract_id": "8284900324412439",
  "contract_title": "ELA ORDER FORM",
  "fees": "3709541.0"
}, {
  "vmware_agreement": "00671271",
  "customer_name": "Musarubra US LLC",
  "contract_id": "8285694845459659",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "3673307.03"
}, {
  "vmware_agreement": "00545005",
  "customer_name": "Inova Health System",
  "contract_id": "8284889337287787",
  "contract_title": "ELA ORDER FORM",
  "fees": "3656023.24"
}, {
  "vmware_agreement": "00674590 \u0026 00673609",
  "customer_name": "GRUPO PETERSEN - NUEVO BANCO DE SANTA FE",
  "contract_id": "8840420351334455",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3581431.08"
}, {
  "vmware_agreement": "00572280",
  "customer_name": "USJ LLC",
  "contract_id": "8286748746459854",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "3560000.0"
}, {
  "vmware_agreement": "00637816",
  "customer_name": "Shelter Mutual Insurance Company",
  "contract_id": "8284754069990543",
  "contract_title": "AMENDMENT NO. 3 TO THE ELA ORDER FORM",
  "fees": "3549956.73"
}, {
  "vmware_agreement": "00360869",
  "customer_name": "Bank Of Beijing北京银行",
  "contract_id": "8285456605218974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3526778.25"
}, {
  "vmware_agreement": "00373891",
  "customer_name": "Marriott International Administrative Services, Inc.",
  "contract_id": "8284557045529800",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "3503537.2"
}, {
  "vmware_agreement": "00373891",
  "customer_name": "Marriott International Administrative Services, Inc.",
  "contract_id": "8840321769647060",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "3503537.2"
}, {
  "vmware_agreement": "VMware Agreement # 00688925.0",
  "customer_name": "Inner Mongolia Yili Industrial Group Ltd By Share Ltd Neimenggu 010080, China",
  "contract_id": "8285868687780008",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3452220.8"
}, {
  "vmware_agreement": "00362329",
  "customer_name": "Childrens National Medical Center",
  "contract_id": "8284120198105139",
  "contract_title": "ELA ORDER FORM",
  "fees": "3445205.83"
}, {
  "vmware_agreement": "VMware Agreement # 00543533",
  "customer_name": "NEC Corporation",
  "contract_id": "8286146686247236",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3420000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368312",
  "customer_name": "China Guangfa bank广发银行",
  "contract_id": "8285639957760534",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3376756.79"
}, {
  "vmware_agreement": "VMware Agreement # 00360865",
  "customer_name": "China Merchants Bank Co. Ltd 招商银行股份有限公司",
  "contract_id": "8840500438727666",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3375060.78"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8285909208732696",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3355866.2"
}, {
  "vmware_agreement": "00358936",
  "customer_name": "China Everbright Bank中国光大银行",
  "contract_id": "8840404190499366",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3283860.89"
}, {
  "vmware_agreement": "00445944",
  "customer_name": "BB - Banco do Brasil",
  "contract_id": "8285448220582338",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3280882.53"
}, {
  "vmware_agreement": "VMware Agreement # 00551324",
  "customer_name": "Kanematsu Electronics Ltd.",
  "contract_id": "8286768514767525",
  "contract_title": "VMware ELA",
  "fees": "3254000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00544889",
  "customer_name": "Shanghai Rural Commercial Bank",
  "contract_id": "8286202937604946",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3224832.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MITSUBISHI ELECTRIC BUILDING TECHNO-SERVICE CO.,LTD.",
  "contract_id": "8286283384879694",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3180000.0"
}, {
  "vmware_agreement": "00565320",
  "customer_name": "Thryv",
  "contract_id": "8286070840821570",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3030848.94"
}, {
  "vmware_agreement": "00358902",
  "customer_name": "Baystate Health",
  "contract_id": "8284659516286281",
  "contract_title": "ELA ORDER FORM",
  "fees": "2998481.94"
}, {
  "vmware_agreement": "00499638",
  "customer_name": "Singapore Telecommunications Limited",
  "contract_id": "8284984436107664",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2961120.09"
}, {
  "vmware_agreement": "00438058",
  "customer_name": "Brother Industries, Ltd.",
  "contract_id": "8285222739614261",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2950000.0"
}, {
  "vmware_agreement": "00622787",
  "customer_name": "KITAMURA CO., LTD.",
  "contract_id": "8284741902530872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2880000.0"
}, {
  "vmware_agreement": "00188752",
  "customer_name": "Bank of America Corporation",
  "contract_id": "8285378245148449",
  "contract_title": "Addendum No. 4 to Schedule A-27 to Master Software License and Maintenance Agreement",
  "fees": "2872298.88"
}, {
  "vmware_agreement": "00636166",
  "customer_name": "SINGTEL OPTUS PTY LTD",
  "contract_id": "8840411133703637",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2829818.21"
}, {
  "vmware_agreement": "00540521",
  "customer_name": "Northwell Health Inc",
  "contract_id": "8286008298369604",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2814574.97"
}, {
  "vmware_agreement": "00492041",
  "customer_name": "Southwest Airlines Co.",
  "contract_id": "8288388589628445",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2803083.0"
}, {
  "vmware_agreement": "00684716",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285625804866516",
  "contract_title": "ELA ORDER FORM",
  "fees": "2791692.9"
}, {
  "vmware_agreement": "00544051",
  "customer_name": "United Airlines",
  "contract_id": "8285008880714033",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2764301.29"
}, {
  "vmware_agreement": "00430118",
  "customer_name": "TURKCELL ILETISIM HIZMETLERI AS",
  "contract_id": "8285746198422261",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2705914.69"
}, {
  "vmware_agreement": "00682066",
  "customer_name": "Kaiser Foundation Hospitals",
  "contract_id": "8285378288834448",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "2661258.71"
}, {
  "vmware_agreement": "00426656",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8284804969199744",
  "contract_title": "ELA ORDER FORM",
  "fees": "2600000.0"
}, {
  "vmware_agreement": "00691624",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8284428209010322",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "2570048.18"
}, {
  "vmware_agreement": "00543903",
  "customer_name": "The Commonwealth of Australia, represented by the Department Of Home Affairs",
  "contract_id": "8284888370620147",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2559173.76"
}, {
  "vmware_agreement": "00429881",
  "customer_name": "The Williams Companies, Inc",
  "contract_id": "8287391781316130",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2552294.71"
}, {
  "vmware_agreement": "00216189",
  "customer_name": "Proquire LLC",
  "contract_id": "8840413976204944",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "2543976.2"
}, {
  "vmware_agreement": "00631312",
  "customer_name": "PepsiCo, Inc.",
  "contract_id": "8840425000899351",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "2514439.96"
}, {
  "vmware_agreement": "00598773",
  "customer_name": "Alaska Airlines Inc",
  "contract_id": "8284514082950025",
  "contract_title": "ELA ORDER FORM",
  "fees": "2490330.71"
}, {
  "vmware_agreement": "00539136",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8284950275082125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2483602.86"
}, {
  "vmware_agreement": "00549056",
  "customer_name": "Computer Sciences Corporation",
  "contract_id": "8285651076159166",
  "contract_title": "ELA Order Form",
  "fees": "2410348.0"
}, {
  "vmware_agreement": "00672243",
  "customer_name": "World Wide Technology, LLC",
  "contract_id": "8285586323661924",
  "contract_title": "ELA ORDER FORM",
  "fees": "2402717.4"
}, {
  "vmware_agreement": "0069323300007728.0",
  "customer_name": "World Wide Technology, Inc",
  "contract_id": "8285145545931659",
  "contract_title": "ELA ORDER FORM",
  "fees": "2354322.46"
}, {
  "vmware_agreement": "00628100",
  "customer_name": "California Public Utilities Commission",
  "contract_id": "8286102767597922",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "2300355.16"
}, {
  "vmware_agreement": "00218902",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285145446422455",
  "contract_title": "Enterprise License Agreement",
  "fees": "2228492.36"
}, {
  "vmware_agreement": "00556169",
  "customer_name": "Lembaga Hasil Dalam Negeri Malaysia (LHDN)",
  "contract_id": "8285970280088850",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2204320.99"
}, {
  "vmware_agreement": "00545857",
  "customer_name": "Republic Services Procurement, Inc.",
  "contract_id": "8285603593142293",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "2187617.08"
}, {
  "vmware_agreement": "00478511",
  "customer_name": "PICC Property and Casualty Company Limited",
  "contract_id": "8286539384763918",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2159103.88"
}, {
  "vmware_agreement": "00692043",
  "customer_name": "CNO SERVICES, LLC",
  "contract_id": "8284119329908860",
  "contract_title": "VMware vCloud Suite+ Standard (per Core)- 36 month Prepaid",
  "fees": "2147975.19"
}, {
  "vmware_agreement": "00498207",
  "customer_name": "Target Corporation",
  "contract_id": "8285828719760149",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "2131647.77"
}, {
  "vmware_agreement": "00559396",
  "customer_name": "Government Technology Agency Of Singapore",
  "contract_id": "8285563537730386",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2118817.44"
}, {
  "vmware_agreement": "00497117",
  "customer_name": "IBARAKI PREFECTURE",
  "contract_id": "8285857427506824",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "2083761.0"
}, {
  "vmware_agreement": "00549805",
  "customer_name": "Contemporary Amperex Technology Co Ltd",
  "contract_id": "8285144046921020",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2047907.15"
}, {
  "vmware_agreement": "00588345",
  "customer_name": "Choctaw Nation of Oklahoma",
  "contract_id": "8285605113706572",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2038651.72"
}, {
  "vmware_agreement": "00678713",
  "customer_name": "Franciscan Missionaries of Our Lady Health System, Inc*",
  "contract_id": "8285586989068311",
  "contract_title": "VMware Enterprise Order",
  "fees": "2031822.4"
}, {
  "vmware_agreement": "00431887",
  "customer_name": "Evergrande Tourism Group恒大旅游集团",
  "contract_id": "8285858028342449",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2013118.14"
}, {
  "vmware_agreement": "00551726",
  "customer_name": "BNSF Railway",
  "contract_id": "8840339332375036",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2011932.0"
}, {
  "vmware_agreement": "00493994",
  "customer_name": "Lyft, Inc.",
  "contract_id": "8284232035890752",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000640.0"
}, {
  "vmware_agreement": "00572429",
  "customer_name": "Auckland Council",
  "contract_id": "8284972240916025",
  "contract_title": "VMware Enterprise Agreement",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "00438169",
  "customer_name": "Networld Corporation",
  "contract_id": "8285258112051001",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "VMware International Unlimited Company",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285167723932175",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00429206",
  "customer_name": "Grupo Petersen - Nuevo Banco de Santa Fe",
  "contract_id": "8285603519978982",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1992420.05"
}, {
  "vmware_agreement": "00697146",
  "customer_name": "California Environmental Protection Agency",
  "contract_id": "8285447154378563",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "1990291.54"
}, {
  "vmware_agreement": "00684694",
  "customer_name": "LinkedIn Corporation",
  "contract_id": "8285650546975703",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1988878.36"
}, {
  "vmware_agreement": "00657483",
  "customer_name": "MURAMOTO CORPORATION",
  "contract_id": "8285731563959298",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1980000.0"
}, {
  "vmware_agreement": "00596259",
  "customer_name": "Adobe Inc.",
  "contract_id": "8840211181188971",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1960257.47"
}, {
  "vmware_agreement": "00503243",
  "customer_name": "S\u0026P Global",
  "contract_id": "8285624967859043",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1948992.5"
}, {
  "vmware_agreement": "VMware Agreement #00436694",
  "customer_name": "Childrens Hospital of New Orleans*(LCMC)",
  "contract_id": "8285898751182186",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1947938.54"
}, {
  "vmware_agreement": "00433737",
  "customer_name": "MGMC",
  "contract_id": "8285447509859929",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1932106.25"
}, {
  "vmware_agreement": "00391966",
  "customer_name": "Banco Bradesco S.A.",
  "contract_id": "8286412899321115",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1914285.4"
}, {
  "vmware_agreement": "00498272",
  "customer_name": "VF Services, LLC on behalf of VF Corporation",
  "contract_id": "8285720052471681",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "1882951.03"
}, {
  "vmware_agreement": "00680557",
  "customer_name": "Delaware Department Of Technology \u0026 Information",
  "contract_id": "8286358006633044",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1866094.42"
}, {
  "vmware_agreement": "00670769",
  "customer_name": "Vizient Inc",
  "contract_id": "8285707893531669",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "1855484.16"
}, {
  "vmware_agreement": "00631576",
  "customer_name": "Netapp",
  "contract_id": "8284956980126740",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1852721.95"
}, {
  "vmware_agreement": "00476808",
  "customer_name": "Iret Inc.",
  "contract_id": "8285509898534107",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1850000.0"
}, {
  "vmware_agreement": "00388953",
  "customer_name": "Nec Solution Innovators Ltd",
  "contract_id": "8286223335291679",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1843000.0"
}, {
  "vmware_agreement": "00677811",
  "customer_name": "City of Scottsdale",
  "contract_id": "8285405612275319",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1842770.82"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "Santa Clara Valley Medical Center",
  "contract_id": "8286377490930400",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1834515.88"
}, {
  "vmware_agreement": "00360426",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285087399861067",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1831456.46"
}, {
  "vmware_agreement": "00433808",
  "customer_name": "Temple University Health System, Inc.(TUHS)",
  "contract_id": "8286555276256502",
  "contract_title": "AM3",
  "fees": "1820774.2"
}, {
  "vmware_agreement": "00693719",
  "customer_name": "FOXTEL MANAGEMENT PTY LTD FOR AND ON BEHALF OF THE FOXTEL PARTNERSHIP",
  "contract_id": "8285087627653005",
  "contract_title": "Vmware Enterprise Licensing Agreement",
  "fees": "1817763.14"
}, {
  "vmware_agreement": "00669524",
  "customer_name": "Global Payments Inc",
  "contract_id": "8285241295380366",
  "contract_title": "Amendment No. 13 to the ELA Order Form",
  "fees": "1815840.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382162",
  "customer_name": "JR East Japan Information Systems Company",
  "contract_id": "8285455629030193",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8286335056368944",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00376019",
  "customer_name": "NEC Corporation",
  "contract_id": "8286993806757910",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360309",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8285899517585390",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360280",
  "customer_name": "Kanematsu Electronics Ltd",
  "contract_id": "8285829681148019",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368144",
  "customer_name": "Hitachi Systems, Ltd.",
  "contract_id": "8286269094748994",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "00370893",
  "customer_name": "NTT DATA INTELLILINK CORPORATION",
  "contract_id": "8285306491297637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, April 3, 2018",
  "customer_name": "LINE Bank Taiwan Limited",
  "contract_id": "8840569498079212",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1811280.27"
}, {
  "vmware_agreement": "00493632",
  "customer_name": "University of Texas At San Antonio",
  "contract_id": "8285939623095872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1795551.8"
}, {
  "vmware_agreement": "VMware Agreement # 00215775",
  "customer_name": "Navy Engineering Logistics Office (NELO)",
  "contract_id": "8840277655692021",
  "contract_title": "NELO ISAT Deferred v10",
  "fees": "1792030.48"
}, {
  "vmware_agreement": "00362069",
  "customer_name": "Uber Technologies, Inc.",
  "contract_id": "8285909063174376",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1775893.9"
}, {
  "vmware_agreement": "00641414",
  "customer_name": "University of Pittsburgh-Of the Commonwealth System of Higher Education",
  "contract_id": "8285130231648386",
  "contract_title": "ELA ORDER FORM",
  "fees": "1773344.85"
}, {
  "vmware_agreement": "00645994",
  "customer_name": "University of Pittsburgh-Of the Commonwealth System of Higher Education",
  "contract_id": "8284958261056252",
  "contract_title": "ELA ORDER FORM",
  "fees": "1773344.85"
}, {
  "vmware_agreement": "00188559",
  "customer_name": "Australian Bureau Of Statistics (ABN 26 331 428522), an Australian Commonwealth Government entity",
  "contract_id": "8284910850489758",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1765905.16"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285085827417093",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "fees": "1765358.57"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285420169337493",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "fees": "1765358.57"
}, {
  "vmware_agreement": "VMware Agreement # 00426647",
  "customer_name": "MUFG BANK, LTD",
  "contract_id": "8286022099939143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1754313.63"
}, {
  "vmware_agreement": "VMware Agreement # 00574455",
  "customer_name": "National University of Singapore",
  "contract_id": "8286604498982858",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1747514.55"
}, {
  "vmware_agreement": "00503177",
  "customer_name": "GoDaddy.com, LLC",
  "contract_id": "8285167531015969",
  "contract_title": "ELA ORDER FORM",
  "fees": "1725683.37"
}, {
  "vmware_agreement": "00559950",
  "customer_name": "A Toda Hora - ATH S.A.",
  "contract_id": "8285908899144731",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "1714882.51"
}, {
  "vmware_agreement": "VMware Agreement # 00380691",
  "customer_name": "Department Of Jobs And Small Business",
  "contract_id": "8285280306859341",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1712541.48"
}, {
  "vmware_agreement": "VMware Agreement # 00380691",
  "customer_name": "Department Of Jobs And Small Business",
  "contract_id": "8285279500324817",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1712541.48"
}, {
  "vmware_agreement": "VMware Agreement # 00339442, 00274954",
  "customer_name": "SAMSUNG ELECTRONICS CO.,LTD",
  "contract_id": "8840554476105293",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1711841.98"
}, {
  "vmware_agreement": "006797521",
  "customer_name": "TELEKOM MALAYSIA BERHAD",
  "contract_id": "8285693417444356",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1665664.08"
}, {
  "vmware_agreement": "00677129",
  "customer_name": "UT Southwestern Medical Center",
  "contract_id": "8286377323134218",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1661020.31"
}, {
  "vmware_agreement": "00492320",
  "customer_name": "Marsh \u0026 McLennan Companies, Inc.",
  "contract_id": "8284794458013989",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1657110.54"
}, {
  "vmware_agreement": "00591964",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285071759278840",
  "contract_title": "ELA ORDER FORM",
  "fees": "1653490.06"
}, {
  "vmware_agreement": "00485665",
  "customer_name": "JPMorgan Chase Bank, National Association",
  "contract_id": "8285563623918212",
  "contract_title": "SCHEDULE # 3 TO THE MASTER AGREEMENT",
  "fees": "1648734.58"
}, {
  "vmware_agreement": "00484913",
  "customer_name": "Ally Financial Inc",
  "contract_id": "8284753996610905",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1645397.23"
}, {
  "vmware_agreement": "00501854",
  "customer_name": "Lowe\u0027s Companies Inc",
  "contract_id": "8840321769432560",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1644280.56"
}, {
  "vmware_agreement": "006091481",
  "customer_name": "Department of Health",
  "contract_id": "8285121000290894",
  "contract_title": "ELA ORDER FORM",
  "fees": "1643950.0"
}, {
  "vmware_agreement": "00476973",
  "customer_name": "LG CNS Co Ltd",
  "contract_id": "8284958887649952",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1638400.0"
}, {
  "vmware_agreement": "00641164",
  "customer_name": "Miami-Dade County Public Schools-158",
  "contract_id": "8285058951377368",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1621142.24"
}, {
  "vmware_agreement": "00495141",
  "customer_name": "Sinclair Services Company",
  "contract_id": "8285640378110075",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1608371.45"
}, {
  "vmware_agreement": "00684962",
  "customer_name": "Cabela\u0027s LLC",
  "contract_id": "8285145718309586",
  "contract_title": "Amendment No. 13 to the ELA Order",
  "fees": "1601639.44"
}, {
  "vmware_agreement": "00499225",
  "customer_name": "Sinclair Services Company",
  "contract_id": "8285731060634426",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1599392.43"
}, {
  "vmware_agreement": "00190710",
  "customer_name": "American Airlines, Inc.",
  "contract_id": "8285193001555058",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1597530.74"
}, {
  "vmware_agreement": "VMware Agreement #00364338",
  "customer_name": "UCLA Health Systems",
  "contract_id": "8285510256192822",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1589855.01"
}, {
  "vmware_agreement": "00690775",
  "customer_name": "Servicenow Inc",
  "contract_id": "8284209036944057",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1574944.0"
}, {
  "vmware_agreement": "00498992",
  "customer_name": "West Virginia University Wvu Extension Service",
  "contract_id": "8285096400611251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1564499.73"
}, {
  "vmware_agreement": "VMware Agreement # 00380012",
  "customer_name": "Ping An Insurance (Group) Company OfChina Ltd.中国平安保险集团股份有限公司",
  "contract_id": "8285650982137253",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1562959.92"
}, {
  "vmware_agreement": "00219424",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8285194585135244",
  "contract_title": "ELA",
  "fees": "1553900.47"
}, {
  "vmware_agreement": "VMware Agreement # 004995371",
  "customer_name": "OSDE - Organizacion de Servicios DirectosEmpresarios",
  "contract_id": "8286767784460668",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1553238.15"
}, {
  "vmware_agreement": "00375580",
  "customer_name": "Carnival Corporation (Main)",
  "contract_id": "8283905882817997",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1550027.01"
}, {
  "vmware_agreement": "006794111",
  "customer_name": "Suruhanjaya Syarikat Malaysia",
  "contract_id": "8284983158697574",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1545159.74"
}, {
  "vmware_agreement": "VMware Agreement # 00375089",
  "customer_name": "California Department Of Public Health",
  "contract_id": "8286269923260013",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1529189.42"
}, {
  "vmware_agreement": "VMware Agreement # 00609932",
  "customer_name": "COMFORTDELGRO CORPORATION LIMITED",
  "contract_id": "8284973435253251",
  "contract_title": "VMware Single Resales ELA Agreement",
  "fees": "1510000.0"
}, {
  "vmware_agreement": "00672939",
  "customer_name": "Het CA",
  "contract_id": "8284714879940765",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1503487.03"
}, {
  "vmware_agreement": "VMware Agreement # 00430304",
  "customer_name": "AXA Equitable Life Insurance Company",
  "contract_id": "8286203467001911",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1500000.0"
}, {
  "vmware_agreement": "00393718",
  "customer_name": "Department Of Social Services",
  "contract_id": "8284602435512412",
  "contract_title": "ELA ORDER FORM",
  "fees": "1499469.0"
}, {
  "vmware_agreement": "VMware Agreement # 00394276",
  "customer_name": "Lower Colorado River Authority (Austin)",
  "contract_id": "8285510109620436",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1480315.2"
}, {
  "vmware_agreement": "VMware Agreement # 00484388",
  "customer_name": "Dfw International Airport",
  "contract_id": "8285356376763164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1474380.6"
}, {
  "vmware_agreement": "VMware Agreement# 00690390",
  "customer_name": "Ministry Of Education (lm)",
  "contract_id": "8286378383748553",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1471148.59"
}, {
  "vmware_agreement": "00637945",
  "customer_name": "SouthState Bank, N.A.",
  "contract_id": "8285471608601653",
  "contract_title": "VMWARE ELA 3YRS",
  "fees": "1465876.14"
}, {
  "vmware_agreement": "00492936",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285746610877411",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1460000.0"
}, {
  "vmware_agreement": "00479677",
  "customer_name": "AUTOMAGI INC",
  "contract_id": "8284888893671279",
  "contract_title": "ELA ORDER FORM",
  "fees": "1460000.0"
}, {
  "vmware_agreement": "VMware Agreement #00358077",
  "customer_name": "CENTRAL PROVIDENT FUND BOARD",
  "contract_id": "8286822342796460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1451329.4"
}, {
  "vmware_agreement": "00218219",
  "customer_name": "Cabela\u0027s Incorporated",
  "contract_id": "8284972705554560",
  "contract_title": "VMware ELA Renewal",
  "fees": "1448142.25"
}, {
  "vmware_agreement": "00549355",
  "customer_name": "Micron Technology, Inc.",
  "contract_id": "8285707616859467",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "fees": "1441428.3"
}, {
  "vmware_agreement": "00611032",
  "customer_name": "Intuit Inc.",
  "contract_id": "8284911497114039",
  "contract_title": "ELA ORDER FORM",
  "fees": "1431316.6"
}, {
  "vmware_agreement": "VMware Agreement # 00433772",
  "customer_name": "Superior Court - Maricopa County",
  "contract_id": "8286734098172789",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1428798.5"
}, {
  "vmware_agreement": "00378783",
  "customer_name": "DHS - CISE",
  "contract_id": "8286038111677301",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1420641.58"
}, {
  "vmware_agreement": "00380880",
  "customer_name": "Ford Motor Company",
  "contract_id": "8285017568697980",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1403777.99"
}, {
  "vmware_agreement": "VMware Agreement # 00495607",
  "customer_name": "FUJITSU FSAS INC",
  "contract_id": "8286685986057496",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1400000.0"
}, {
  "vmware_agreement": "00356672",
  "customer_name": "COMMONWEALTH OF AUSTRALIA AS REPRESENTED BY DEPARTMENT OF FOREIGN AFFAIRS AND TRADE (DEAT) REGISTRY P",
  "contract_id": "8285290453822302",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1399736.22"
}, {
  "vmware_agreement": "00190486",
  "customer_name": "Eaton Vance Management",
  "contract_id": "8284754346239797",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "1386703.0"
}, {
  "vmware_agreement": "00598772",
  "customer_name": "Abbott Laboratories",
  "contract_id": "8283843682366114",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1383768.31"
}, {
  "vmware_agreement": "00682905",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8840260085331932",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "1372446.07"
}, {
  "vmware_agreement": "00679899",
  "customer_name": "MARS",
  "contract_id": "8285495548394257",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1371888.31"
}, {
  "vmware_agreement": "00544065",
  "customer_name": "Government of Nunavut as represented by the Minister of Community and Government Services",
  "contract_id": "8286686409433138",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "1363369.14"
}, {
  "vmware_agreement": "VMware Agreement #00378472",
  "customer_name": "Petrobras - Petroleo Brasileiro",
  "contract_id": "8285429341404750",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1324483.29"
}, {
  "vmware_agreement": "00592981",
  "customer_name": "Allegis Group, Inc.",
  "contract_id": "8285059835578163",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1302176.68"
}, {
  "vmware_agreement": "00694662",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8840487719187012",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "1296320.74"
}, {
  "vmware_agreement": "00619807",
  "customer_name": "Jefferson County Commission (main)",
  "contract_id": "8285471459240054",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1290100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00380834",
  "customer_name": "Confed Interestadual das coop ligadas ao sicredi",
  "contract_id": "8285969143195766",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1286526.08"
}, {
  "vmware_agreement": "00497909",
  "customer_name": "Tarrant County College District",
  "contract_id": "8285719473664575",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1285444.03"
}, {
  "vmware_agreement": "00684064",
  "customer_name": "Washoe County",
  "contract_id": "8285763453617442",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1279904.0"
}, {
  "vmware_agreement": "00373227",
  "customer_name": "OMRON CORPORATION",
  "contract_id": "8840279683780653",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1270700.0"
}, {
  "vmware_agreement": "00495255",
  "customer_name": "Tata Consultancy Services Ltd",
  "contract_id": "8286854217921123",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1266997.92"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, VMware Partner Agreement No. 00274954",
  "customer_name": "Ebay Korea CO Ltd",
  "contract_id": "8285471144753030",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1263223.12"
}, {
  "vmware_agreement": "VMware Agreement # 00387286",
  "customer_name": "Electric Power Board",
  "contract_id": "8286442775168244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1263112.48"
}, {
  "vmware_agreement": "00386769",
  "customer_name": "PricewaterhouseCoopers Global Licensing Services Corporation",
  "contract_id": "8285033833082754",
  "contract_title": "Enterprise License Agreement Order Form #5",
  "fees": "1262640.0"
}, {
  "vmware_agreement": "VMware Agreement # 00378548",
  "customer_name": "Delaware Health and Social Services(DHSS)",
  "contract_id": "8286425806336557",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1260537.2"
}, {
  "vmware_agreement": "00573764",
  "customer_name": "Terrier Media Buyer, Inc., d/b/a Cox Media Group",
  "contract_id": "8284405051853876",
  "contract_title": "Enterprise License Agreement",
  "fees": "1256095.81"
}, {
  "vmware_agreement": "00394016",
  "customer_name": "Thomas Jefferson University",
  "contract_id": "8840321756810494",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1256000.0"
}, {
  "vmware_agreement": "00562167",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286928085811842",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1254400.0"
}, {
  "vmware_agreement": "VMware Agreement # 00401310",
  "customer_name": "Banco Original",
  "contract_id": "8288272684886564",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1251354.05"
}, {
  "vmware_agreement": "00246621",
  "customer_name": "Sandata Technologies",
  "contract_id": "8285605189991081",
  "contract_title": "ELA ORDER FORM",
  "fees": "1250000.0"
}, {
  "vmware_agreement": "00390489",
  "customer_name": "Orlando Utilities Commission",
  "contract_id": "8285790608247420",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1249729.33"
}, {
  "vmware_agreement": "VMware Agreement # 00672804",
  "customer_name": "Inland Revenue Department",
  "contract_id": "8286022121485152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1244960.64"
}, {
  "vmware_agreement": "00635408",
  "customer_name": "W.L. Gore \u0026 Associates Inc",
  "contract_id": "8284674843441431",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1236488.93"
}, {
  "vmware_agreement": "00635408",
  "customer_name": "W.L. Gore \u0026 Associates Inc",
  "contract_id": "8284842548649393",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1236488.93"
}, {
  "vmware_agreement": "00573799",
  "customer_name": "Berkshire Hathaway Energy Company f/k/a MidAmerican Energy Holdings Company",
  "contract_id": "8285306386118951",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1232493.04"
}, {
  "vmware_agreement": "VMware Agreement # 00359192",
  "customer_name": "Kirtland AFB (AFMC)",
  "contract_id": "8285562745595158",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1213843.05"
}, {
  "vmware_agreement": "00674220",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8286378022666939",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1209498.0"
}, {
  "vmware_agreement": "00187804",
  "customer_name": "Whirlpool Corporation (Main Account)",
  "contract_id": "8285195029748355",
  "contract_title": "Enterprise License Agreement",
  "fees": "1204974.91"
}, {
  "vmware_agreement": "00559980",
  "customer_name": "Premera Blue Cross",
  "contract_id": "8285537000297387",
  "contract_title": "VMWARE INC - Amendment 3 to Enterprise License Agreement and Quote 5679925.1",
  "fees": "1204358.6"
}, {
  "vmware_agreement": "006785591",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8283960730973742",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1200000.0"
}, {
  "vmware_agreement": "00566756",
  "customer_name": "National Marrow Donor Program",
  "contract_id": "8285538242755567",
  "contract_title": "ELA - VMWare (Eplus) NMDP",
  "fees": "1195974.87"
}, {
  "vmware_agreement": "00362091",
  "customer_name": "Q-Den Business Solutions Co. Inc.",
  "contract_id": "8286994178877853",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "1187500.0"
}, {
  "vmware_agreement": "00442428",
  "customer_name": "BERKSHIRE HATHAWAY ENERGY F/K/A MIDAMERICAN ENERGY HOLDINGS COMPANY",
  "contract_id": "8284768866902245",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1182970.7"
}, {
  "vmware_agreement": "00429211",
  "customer_name": "BERKSHIRE HATHAWAY ENERGY COMPANY F/K/AMIDAMERICA ENERGY HOLDINGS COMPANY",
  "contract_id": "8284413685593694",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1181636.9"
}, {
  "vmware_agreement": "00435509",
  "customer_name": "AXIS BANK LTD",
  "contract_id": "8285087682429592",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1175014.93"
}, {
  "vmware_agreement": "00397675",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8285418720719501",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "1173810.58"
}, {
  "vmware_agreement": "VMware Agreement # 00475561",
  "customer_name": "City of Riverside",
  "contract_id": "8285586791130782",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1164474.0"
}, {
  "vmware_agreement": "00595854",
  "customer_name": "MARS",
  "contract_id": "8285178456284345",
  "contract_title": "ELA ORDER FORM",
  "fees": "1154821.05"
}, {
  "vmware_agreement": "00361401",
  "customer_name": "David Yurman Enterprises LLC",
  "contract_id": "8840487711559655",
  "contract_title": "ELA ORDER FORM",
  "fees": "1150318.84"
}, {
  "vmware_agreement": "00358517",
  "customer_name": "Itaipu Binacional",
  "contract_id": "8286193082896622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1150001.73"
}, {
  "vmware_agreement": "00362082",
  "customer_name": "TD Ameritrade",
  "contract_id": "8284806041310834",
  "contract_title": "Amendment No. 3 to the ELA Order",
  "fees": "1135485.27"
}, {
  "vmware_agreement": "00359045",
  "customer_name": "Kronos Incorporated",
  "contract_id": "8285546233201526",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1129521.59"
}, {
  "vmware_agreement": "VMware Agreement #00483326",
  "customer_name": "OXY Inc.",
  "contract_id": "8285694662358499",
  "contract_title": "Enterprise License Agreement Order No. 2",
  "fees": "1123610.0"
}, {
  "vmware_agreement": "00391696",
  "customer_name": "Hobby Lobby Stores, Inc.",
  "contract_id": "8285318596468977",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1123182.78"
}, {
  "vmware_agreement": "00357864.0",
  "customer_name": "Duke Energy Business Services LLC",
  "contract_id": "8285446629691567",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1120328.2"
}, {
  "vmware_agreement": "00188231",
  "customer_name": "Raymond James \u0026 Associates, Inc.",
  "contract_id": "8286717077819270",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1117390.5"
}, {
  "vmware_agreement": "00690504",
  "customer_name": "Soboba Casino",
  "contract_id": "8285344924838274",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1115862.75"
}, {
  "vmware_agreement": "00428124",
  "customer_name": "National Rural Utilities Cooperative Finance Corporation",
  "contract_id": "8285456150995514",
  "contract_title": "ELA ORDER FORM",
  "fees": "1112675.0"
}, {
  "vmware_agreement": "00488996",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8285205457974655",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1111530.0"
}, {
  "vmware_agreement": "00391849",
  "customer_name": "Sacramento Superior Court",
  "contract_id": "8285546956183565",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1107401.13"
}, {
  "vmware_agreement": "00361404",
  "customer_name": "Carnival Corporation (Main)",
  "contract_id": "8284984295104971",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1103643.82"
}, {
  "vmware_agreement": "VMware Agreement # 00676342",
  "customer_name": "KYOCERA CORPORATION",
  "contract_id": "8840260121166211",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1100000.0"
}, {
  "vmware_agreement": "00489360",
  "customer_name": "ServiceNow, Inc",
  "contract_id": "8285651193370287",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1088261.93"
}, {
  "vmware_agreement": "00691042",
  "customer_name": "AECOM",
  "contract_id": "8285204894065234",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1085068.65"
}, {
  "vmware_agreement": "00637791",
  "customer_name": "Temple University",
  "contract_id": "8285305665943062",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1061617.74"
}, {
  "vmware_agreement": "00386425",
  "customer_name": "Sisters of Mercy Health System-St. Louis/St. John\u0027s Mercy Hospital",
  "contract_id": "8285110100111993",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1056094.95"
}, {
  "vmware_agreement": "00368958",
  "customer_name": "Anheuser Busch Companies, LLC",
  "contract_id": "8840294149952591",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1054441.49"
}, {
  "vmware_agreement": "00706848",
  "customer_name": "State Of California Office Of The Governor",
  "contract_id": "8285731977621379",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "1053040.0"
}, {
  "vmware_agreement": "00498447",
  "customer_name": "Gilead Sciences, Inc.",
  "contract_id": "8285258896213863",
  "contract_title": "Amendment No. 8 to the ELA",
  "fees": "1048058.52"
}, {
  "vmware_agreement": "00215977",
  "customer_name": "AFR",
  "contract_id": "8840307761789033",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1047938.5"
}, {
  "vmware_agreement": "00379195",
  "customer_name": "Pima County",
  "contract_id": "8285664406266222",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1047000.0"
}, {
  "vmware_agreement": "00445241",
  "customer_name": "Navajo Tribal Utility Authority",
  "contract_id": "8286414017212141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1041694.83"
}, {
  "vmware_agreement": "00379397",
  "customer_name": "Land Transport Authority (LTA)",
  "contract_id": "8286457602323227",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1037194.79"
}, {
  "vmware_agreement": "00685395",
  "customer_name": "The Bank of New York Mellon Corporation",
  "contract_id": "9429038152457439",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1037112.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 15, 2017",
  "customer_name": "Maryland Department of Human Resources",
  "contract_id": "8286491847090917",
  "contract_title": "VMware Enterprise Order",
  "fees": "1036050.18"
}, {
  "vmware_agreement": "00691030",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8286455984852120",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68"
}, {
  "vmware_agreement": "00694680",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8285897742440913",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68"
}, {
  "vmware_agreement": "00373583",
  "customer_name": "Anheuser Busch Companies, LLC",
  "contract_id": "8285043071658508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1035431.58"
}, {
  "vmware_agreement": "00433733",
  "customer_name": "Quest Diagnostics Incorporated",
  "contract_id": "8285731440961545",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "1033528.38"
}, {
  "vmware_agreement": "00684060",
  "customer_name": "Berkley Technology Services, LLC",
  "contract_id": "8840113950674501",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "1025888.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433869",
  "customer_name": "Integrated Health Information SystemsPte Ltd",
  "contract_id": "8286358106826339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1023385.65"
}, {
  "vmware_agreement": "00378479",
  "customer_name": "Australian Federal Police (AFP)",
  "contract_id": "8285058132092404",
  "contract_title": "Enterprise License Agreement",
  "fees": "1022136.83"
}, {
  "vmware_agreement": "00685386",
  "customer_name": "GCI Communication Corp.",
  "contract_id": "8285483258581417",
  "contract_title": "VMware Enterprise Multi-Cloud Agreement",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00270917",
  "customer_name": "GlaxoSmithKline LLC",
  "contract_id": "8285405947474647",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00618642",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285616654132033",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "006458941",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285545972529533",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00730925",
  "customer_name": "Shenzhen BYD Property Insurance Co., Ltd",
  "contract_id": "8286255964339453",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "999306.24"
}, {
  "vmware_agreement": "00187954esm.10_12_2015",
  "customer_name": "INTEGRIS HEALTH",
  "contract_id": "8284338493231046",
  "contract_title": "ELA Order Form",
  "fees": "998528.26"
}, {
  "vmware_agreement": "00679557",
  "customer_name": "University of Texas at Rio Grande Valley",
  "contract_id": "8284489519799401",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "998201.45"
}, {
  "vmware_agreement": "VMware Australia Pty Ltd",
  "customer_name": "Australian Taxation Office",
  "contract_id": "8284957215988454",
  "contract_title": "Licence and Support Contract - Commercial off-the-shelf Software",
  "fees": "995540.35"
}, {
  "vmware_agreement": "00397668",
  "customer_name": "CDK Global, LLC.",
  "contract_id": "8283608382747812",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "992128.93"
}, {
  "vmware_agreement": "VMware Agreement #00424268",
  "customer_name": "Commonwealth Superannuation Corporation",
  "contract_id": "8284996592989960",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "991290.81"
}, {
  "vmware_agreement": "00477513",
  "customer_name": "The Bank of New York Mellon Corporation",
  "contract_id": "8286620836952470",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "990660.83"
}, {
  "vmware_agreement": "00545541",
  "customer_name": "Ameren Services Company",
  "contract_id": "8284405218385187",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "987306.71"
}, {
  "vmware_agreement": "00692534",
  "customer_name": "Citadel Enterprise Americas LLC",
  "contract_id": "8285143999952225",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "985025.96"
}, {
  "vmware_agreement": "00684260",
  "customer_name": "Serasa Experian",
  "contract_id": "8286102601318504",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "981881.25"
}, {
  "vmware_agreement": "00551048",
  "customer_name": "Altice USA DBA CSC Holdings",
  "contract_id": "8285130379547661",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "981446.38"
}, {
  "vmware_agreement": "00645989",
  "customer_name": "Bridgestone Americas, Inc.",
  "contract_id": "8285071994215894",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "979927.0"
}, {
  "vmware_agreement": "00645989",
  "customer_name": "Bridgestone Americas, Inc.",
  "contract_id": "8285156974063458",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "979927.0"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "City Of Philadelphia",
  "contract_id": "8285493947983189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "978395.42"
}, {
  "vmware_agreement": "00546878",
  "customer_name": "KTB Computer Services Co Ltd (KTBCS)",
  "contract_id": "8286926713461284",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "977816.77"
}, {
  "vmware_agreement": "00424491",
  "customer_name": "DC Office of the Chief Technology Officer",
  "contract_id": "8286023683652015",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "977800.0"
}, {
  "vmware_agreement": "00219090",
  "customer_name": "Horizon Healthcare Services Inc.",
  "contract_id": "8284614296594492",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "977347.74"
}, {
  "vmware_agreement": "00218971",
  "customer_name": "Consol Energy, Inc.",
  "contract_id": "8285087272907814",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "975000.0"
}, {
  "vmware_agreement": "00357582",
  "customer_name": "Acadian Ambulance Service, Inc(main)",
  "contract_id": "8284380591489332",
  "contract_title": "ELA ORDER FORM",
  "fees": "973028.31"
}, {
  "vmware_agreement": "VMware Agreement # 00680815",
  "customer_name": "State Of Florida Division Of Emergency Management",
  "contract_id": "8285968973857156",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "970093.16"
}, {
  "vmware_agreement": "00708408",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8840424987203109",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "969389.42"
}, {
  "vmware_agreement": "00218386",
  "customer_name": "Royal Bank Of Canada",
  "contract_id": "8285128615354195",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "967117.05"
}, {
  "vmware_agreement": "00369737",
  "customer_name": "Paychex Inc.",
  "contract_id": "8284498762399482",
  "contract_title": "Amendment No 6 to ELA",
  "fees": "961693.39"
}, {
  "vmware_agreement": "00433332.0",
  "customer_name": "Edward D. Jones \u0026 Co., L.P.",
  "contract_id": "8284910455034956",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "961204.5"
}, {
  "vmware_agreement": "00433332.0",
  "customer_name": "Edward D. Jones \u0026 Co., L.P.",
  "contract_id": "8284455753602091",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "961204.5"
}, {
  "vmware_agreement": "00694271",
  "customer_name": "PT Bank Central Asia Tbk",
  "contract_id": "8286734701839835",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "960000.0"
}, {
  "vmware_agreement": "00682464",
  "customer_name": "Washoe County",
  "contract_id": "8284873406590302",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "957424.38"
}, {
  "vmware_agreement": "00265819",
  "customer_name": "Tourism and Culture Authority Abu Dhabi (TCA)",
  "contract_id": "8284936790322862",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "954000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00589097",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285650887924460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953166.17"
}, {
  "vmware_agreement": "VMware Agreement #00611338",
  "customer_name": "USCYBERCOM",
  "contract_id": "8286055871732818",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953156.25"
}, {
  "vmware_agreement": "006331211",
  "customer_name": "Santa Clara Valley Water District",
  "contract_id": "8285471782162899",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "951844.4"
}, {
  "vmware_agreement": "00359769",
  "customer_name": "MacAndrews and Forbes Group LLC",
  "contract_id": "8284805735331473",
  "contract_title": "AMENDMENT NO. 2 TO THE ELA ORDER FORM",
  "fees": "951399.8"
}, {
  "vmware_agreement": "VMware Agreement #00382159",
  "customer_name": "FUJITSU FSAS INC.",
  "contract_id": "8286619304818027",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "950600.0"
}, {
  "vmware_agreement": "00439851",
  "customer_name": "California Department Of General Services",
  "contract_id": "8285679568160518",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "947274.13"
}, {
  "vmware_agreement": "00543302",
  "customer_name": "Zhongshan jiaofa Investment Co., Ltd",
  "contract_id": "8285495095846616",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "945397.93"
}, {
  "vmware_agreement": "00437704",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8286853626306215",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "944569.35"
}, {
  "vmware_agreement": "00361285",
  "customer_name": "Paychex Inc.",
  "contract_id": "8285195137002040",
  "contract_title": "Amendment No 5 to ELA",
  "fees": "942971.37"
}, {
  "vmware_agreement": "00387295",
  "customer_name": "El Dorado County",
  "contract_id": "8840209161472789",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "938274.05"
}, {
  "vmware_agreement": "00372377",
  "customer_name": "Ochsner Clinic Foundation",
  "contract_id": "8285510616217811",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "934401.63"
}, {
  "vmware_agreement": "00611207",
  "customer_name": "Department of Justice - BOP",
  "contract_id": "8284555990257698",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "933003.35"
}, {
  "vmware_agreement": "VMware Agreement # 00499105",
  "customer_name": "BHARTI AIRTEL LIMITED",
  "contract_id": "8285483730777484",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930680.34"
}, {
  "vmware_agreement": "00433451",
  "customer_name": "STANDARD CHARTERED BANK",
  "contract_id": "8285641673352445",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930586.12"
}, {
  "vmware_agreement": "VMware Agreement # 00188548",
  "customer_name": "Florida State University-NWRDC",
  "contract_id": "8286054801267396",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930284.98"
}, {
  "vmware_agreement": "00637017",
  "customer_name": "Akamai Technologies, Inc.",
  "contract_id": "8285371281681558",
  "contract_title": "Amendment No. 10 to the ELA Order Form",
  "fees": "928781.39"
}, {
  "vmware_agreement": "00433831",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8285205506815608",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "926423.13"
}, {
  "vmware_agreement": "VMware Agreement 00691574",
  "customer_name": "Mercadolibre S.r.l",
  "contract_id": "8285969492831737",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "924514.11"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Empresa Argentina de Soluciones Satelitales",
  "contract_id": "8286319977550086",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "921750.22"
}, {
  "vmware_agreement": "00187613.0",
  "customer_name": "Costco Wholesale Corporation (Main)",
  "contract_id": "8285419793825111",
  "contract_title": "2015-AMD-VMWAR-07038",
  "fees": "916233.34"
}, {
  "vmware_agreement": "VMware Agreement # 00561071",
  "customer_name": "DBS Bank Ltd",
  "contract_id": "8286588140278290",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "916000.0"
}, {
  "vmware_agreement": "00597756",
  "customer_name": "The Church of Jesus Christ of Latter-day Saints",
  "contract_id": "8284888546659905",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "915424.53"
}, {
  "vmware_agreement": "VMwareAuthorized Distributor Partner Program Agreement, June 15,2017",
  "customer_name": "SANEPAR - Companhia de Saneamentodo Parana",
  "contract_id": "8285571060838143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "911293.56"
}, {
  "vmware_agreement": "00557522",
  "customer_name": "Santander Consumer USA Inc.",
  "contract_id": "8285563560398431",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "911261.39"
}, {
  "vmware_agreement": "VMware Agreement # 00561494",
  "customer_name": "Renesas Electronics Corporation",
  "contract_id": "8286304348218331",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "910000.0"
}, {
  "vmware_agreement": "00558750",
  "customer_name": "AARP",
  "contract_id": "8284754456326386",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "908910.67"
}, {
  "vmware_agreement": "00558750",
  "customer_name": "AARP",
  "contract_id": "8285405932280956",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "908910.67"
}, {
  "vmware_agreement": "00681493",
  "customer_name": "Nordstrom, Inc.",
  "contract_id": "8285240685967508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "905622.05"
}, {
  "vmware_agreement": "VMware Agreement # 00543628",
  "customer_name": "Unimed Bauru Cooperativa de Trabalho Medico",
  "contract_id": "8285830258129908",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "900635.64"
}, {
  "vmware_agreement": "00222373",
  "customer_name": "Skadden, Arps, Slate, Meagher \u0026 Flom LLP",
  "contract_id": "8284996493987901",
  "contract_title": "Enterprise License Agreement",
  "fees": "900000.0"
}, {
  "vmware_agreement": "00444765",
  "customer_name": "Alticor Inc.",
  "contract_id": "8285017966257443",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "898173.87"
}, {
  "vmware_agreement": "00684943",
  "customer_name": "Prime Therapeutics LLC",
  "contract_id": "8284851556651503",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "897719.38"
}, {
  "vmware_agreement": "00606877",
  "customer_name": "USSF-GPS/OCX",
  "contract_id": "8285178862940502",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "897237.98"
}, {
  "vmware_agreement": "00445953",
  "customer_name": "Arthur J Gallagher \u0026 Co.",
  "contract_id": "8285241762335277",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "895310.35"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Independent System Operator Corporation",
  "contract_id": "8285110139849575",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "893739.74"
}, {
  "vmware_agreement": "00477226",
  "customer_name": "State of Tennessee (main)",
  "contract_id": "8285017250312992",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "893682.77"
}, {
  "vmware_agreement": "00689508",
  "customer_name": "Los Angeles County Metropolitan Transportation Authority",
  "contract_id": "9429038150277977",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891672.24"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8284910019159011",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8285259545800451",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62"
}, {
  "vmware_agreement": "00439083",
  "customer_name": "Texas Health Resources Inc",
  "contract_id": "8285586925634922",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "890437.77"
}, {
  "vmware_agreement": "00499043",
  "customer_name": "South Bihar Power Distribution Company Ltd",
  "contract_id": "8285707951297671",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "890000.0"
}, {
  "vmware_agreement": "00554217",
  "customer_name": "CareSource Management Services LLC",
  "contract_id": "8285018348687312",
  "contract_title": "VMware ELA",
  "fees": "888733.31"
}, {
  "vmware_agreement": "00569042",
  "customer_name": "Sentara Healthcare",
  "contract_id": "8285746581425207",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "887638.79"
}, {
  "vmware_agreement": "VMware Agreement # 00689882",
  "customer_name": "Grupo Ol - Ol Celular",
  "contract_id": "8286604312808814",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "885337.5"
}, {
  "vmware_agreement": "00675703",
  "customer_name": "Stony Brook University-Primary",
  "contract_id": "8840344592459529",
  "contract_title": "SINGLE ENTERPRISE ORDER RESALE AGREEMENT",
  "fees": "884520.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Sefaz-Rs - Secretaria DA Fazenda do Rio Grande do SUL",
  "contract_id": "8286634201895344",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "882878.9"
}, {
  "vmware_agreement": "VMware Agreement # 00397376",
  "customer_name": "Auburn University (main)",
  "contract_id": "8285484570284237",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "882527.32"
}, {
  "vmware_agreement": "VMware Agreement # 00673287",
  "customer_name": "Loma Linda University Medical Center (MainAccount)",
  "contract_id": "8285909864160415",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "881578.64"
}, {
  "vmware_agreement": "VMware Agreement # 00379172",
  "customer_name": "Nacional Monte de Piedad I.A.P.",
  "contract_id": "8285814720141152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "879141.49"
}, {
  "vmware_agreement": "00360730",
  "customer_name": "El Camino Hospital",
  "contract_id": "8284296504605918",
  "contract_title": "ELA Order Form",
  "fees": "878384.7"
}, {
  "vmware_agreement": "Software Licensing Agreement",
  "customer_name": "Ministerio de Hacienda",
  "contract_id": "8286131712713721",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "877314.46"
}, {
  "vmware_agreement": "00684127",
  "customer_name": "Bangladesh Bank",
  "contract_id": "8285830164844758",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "875163.61"
}, {
  "vmware_agreement": "VMware Agreement # 00574497",
  "customer_name": "CLP Great Wall Internet System Application Sichuan Co., Ltd",
  "contract_id": "8285289704395809",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "874115.38"
}, {
  "vmware_agreement": "00218237",
  "customer_name": "USAble Mutual Insurance Company d/b/a Arkansas Blue Cross \u0026 Blue Shield",
  "contract_id": "8284649465371257",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "872207.0"
}, {
  "vmware_agreement": "00477468",
  "customer_name": "Beijing Yundu Internet Technology Co.,Ltd北京云度互联科技有限公司",
  "contract_id": "8286255419230172",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "871510.06"
}, {
  "vmware_agreement": "00188522",
  "customer_name": "Province of Ontario, by the Treasury Board Secretariat",
  "contract_id": "8284842774910695",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "868481.57"
}, {
  "vmware_agreement": "00373440",
  "customer_name": "Childrens Hospital of New Orleans",
  "contract_id": "8840404698773341",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "865798.91"
}, {
  "vmware_agreement": "VMware Agreement # 00426654",
  "customer_name": "Trend Micro, Inc.趨勢科技股份有限公司",
  "contract_id": "8285405737364302",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "865456.75"
}, {
  "vmware_agreement": "00501947",
  "customer_name": "Discovery Communications, LLC",
  "contract_id": "8285379325779424",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "864854.52"
}, {
  "vmware_agreement": "00540840",
  "customer_name": "Sentara Healthcare",
  "contract_id": "8284888959427926",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "862977.29"
}, {
  "vmware_agreement": "VMware Agreement # 00589264",
  "customer_name": "Unified National Networks Sdn Bhd",
  "contract_id": "8286102958903408",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "862278.51"
}, {
  "vmware_agreement": "00545255",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285763175702182",
  "contract_title": "Amendment No. 11 to the ELA Order Form",
  "fees": "861754.6"
}, {
  "vmware_agreement": "00638094",
  "customer_name": "KANEMATSU ELECTRONICS CO., LTD",
  "contract_id": "8285087161652274",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "860000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00607568",
  "customer_name": "Singapore Police Force",
  "contract_id": "8286122087436315",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "858106.06"
}, {
  "vmware_agreement": "00357872",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8286940541683850",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "857767.6"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 5, 2019",
  "customer_name": "SAT - Servicio de Administracion Tributaria",
  "contract_id": "8286605659278424",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "853703.79"
}, {
  "vmware_agreement": "00374867",
  "customer_name": "Texas Health Resources",
  "contract_id": "8285059370336512",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "851199.39"
}, {
  "vmware_agreement": "00545445",
  "customer_name": "Chunghwa Telecom Co., Ltd中華電信",
  "contract_id": "8286749058000914",
  "contract_title": "中華電信(中華系整)VMware專案ELA-1101163_Contract for e-signature",
  "fees": "850685.93"
}, {
  "vmware_agreement": "VMware Agreement # 00682639",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8285279090245153",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "VMware Agreement # 00680353",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8284949661700534",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "00680104",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8285418662278399",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "00430300",
  "customer_name": "Citibank, N.A.",
  "contract_id": "8285144215457287",
  "contract_title": "VMware Smart Assurance Core Platform - 100 Device pack 400 Pack(s) (100 Pack)",
  "fees": "849420.0"
}, {
  "vmware_agreement": "00495232",
  "customer_name": "Wipro Limited",
  "contract_id": "8286101611895458",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849254.79"
}, {
  "vmware_agreement": "00692025",
  "customer_name": "L.A. Care",
  "contract_id": "8840242481042475",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "846230.0"
}, {
  "vmware_agreement": "00393865",
  "customer_name": "Alight Solutions LLC",
  "contract_id": "8284909689803366",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "846162.12"
}, {
  "vmware_agreement": "00390513",
  "customer_name": "Cognizant Technology Solutions U.S. Corporation",
  "contract_id": "8840211267292040",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "846117.53"
}, {
  "vmware_agreement": "VMware Agreement # 00430424",
  "customer_name": "Cielo",
  "contract_id": "8284900833359835",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "845522.46"
}, {
  "vmware_agreement": "00672851",
  "customer_name": "Fresenius Medical Care North America Inc:Lexington, MA",
  "contract_id": "8284983521164726",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "844878.12"
}, {
  "vmware_agreement": "VMware Agreement # 00489635",
  "customer_name": "CFE - Comision Federal de Electricidad",
  "contract_id": "8285745019536613",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "844151.05"
}, {
  "vmware_agreement": "00601007",
  "customer_name": "Leidos, Inc.",
  "contract_id": "8285259035656529",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "841511.93"
}, {
  "vmware_agreement": "00484165",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8284795664080567",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "841269.92"
}, {
  "vmware_agreement": "00694458",
  "customer_name": "Massachusetts Institute of Technology",
  "contract_id": "8285178456339141",
  "contract_title": "Amendment No. 6 to the Order Form",
  "fees": "839100.0"
}, {
  "vmware_agreement": "VMware Agreement, September 1, 2010",
  "customer_name": "Grupo Financiero Inbursa, S.A.B. de C.V.",
  "contract_id": "8286160113993982",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "835663.85"
}, {
  "vmware_agreement": "00636129",
  "customer_name": "Covered California",
  "contract_id": "8840546297216226",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "834753.54"
}, {
  "vmware_agreement": "00431292",
  "customer_name": "Potlatch Deltic Corporation",
  "contract_id": "8285522458592487",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "832865.0"
}, {
  "vmware_agreement": "00484166",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285547235359206",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "831497.72"
}, {
  "vmware_agreement": "00680664",
  "customer_name": "Permodalan Nasional Madani",
  "contract_id": "8285731677856138",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "831245.66"
}, {
  "vmware_agreement": "00499527",
  "customer_name": "Coppel SA de CV",
  "contract_id": "8286635014987642",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "828066.32"
}, {
  "vmware_agreement": "00503248",
  "customer_name": "Telenor Procurement Company Pte. Ltd",
  "contract_id": "8840375140702219",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "827000.77"
}, {
  "vmware_agreement": "00369253",
  "customer_name": "Dell Australia PTY LTD",
  "contract_id": "8285097930836636",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "826615.64"
}, {
  "vmware_agreement": "00220525",
  "customer_name": "Paradise Valley Unified School District",
  "contract_id": "8286172634082019",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "826563.25"
}, {
  "vmware_agreement": "00489120",
  "customer_name": "City Of Little Rock",
  "contract_id": "8285044556856650",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "824964.0"
}, {
  "vmware_agreement": "00392324",
  "customer_name": "Open Text Inc.",
  "contract_id": "8284909805896525",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "822384.63"
}, {
  "vmware_agreement": "00497604",
  "customer_name": "Select Medical Corporation",
  "contract_id": "8285625596075149",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "821978.37"
}, {
  "vmware_agreement": "002178831",
  "customer_name": "HGST, INC.",
  "contract_id": "8285178701964548",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "820146.98"
}, {
  "vmware_agreement": "00637658",
  "customer_name": "Bank Of Montreal*",
  "contract_id": "8285169123807414",
  "contract_title": "ELA ORDER FORM",
  "fees": "819521.953"
}, {
  "vmware_agreement": "00695857",
  "customer_name": "Servicenow Inc",
  "contract_id": "8288388589010790",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "819365.25"
}, {
  "vmware_agreement": "00188598",
  "customer_name": "Catholic Healthcare Partners",
  "contract_id": "8285241442459149",
  "contract_title": "Amendment No. 13 to the ELA Order Form",
  "fees": "818924.81"
}, {
  "vmware_agreement": "00189150",
  "customer_name": "Huron Legal, a Consilio Company",
  "contract_id": "8285563553845184",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "818616.9"
}, {
  "vmware_agreement": "00606889",
  "customer_name": "USSF - NISSC",
  "contract_id": "8285378715426410",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "816810.71"
}, {
  "vmware_agreement": "00376026",
  "customer_name": "Berkshire Hathaway Energy",
  "contract_id": "8284323347972498",
  "contract_title": "VMWARE INC - AMENDMENT 11 TO ELA ORDER",
  "fees": "816805.12"
}, {
  "vmware_agreement": "00626259",
  "customer_name": "National Grid UK LTD \u0026 National Grid USA Service Company Inc",
  "contract_id": "8284983091003888",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "816540.04"
}, {
  "vmware_agreement": "00684711",
  "customer_name": "Central SEQ Distributor-Retailer Authority",
  "contract_id": "8284982861775143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "815633.05"
}, {
  "vmware_agreement": "00433637",
  "customer_name": "City of Seattle",
  "contract_id": "8285951620729795",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "814638.96"
}, {
  "vmware_agreement": "VMware Agreement #00478908",
  "customer_name": "DBS BANK LTD.",
  "contract_id": "8285951233762589",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "811910.1"
}, {
  "vmware_agreement": "00430335",
  "customer_name": "Ellie Mae, Inc.",
  "contract_id": "8285044393611542",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "811468.31"
}, {
  "vmware_agreement": "00597765",
  "customer_name": "Avient Corporation",
  "contract_id": "8285418967208788",
  "contract_title": "Q-E00237584-00597765-Avient Corporation_ELA-2021-12-29",
  "fees": "810100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00359040",
  "customer_name": "Prince William County Service",
  "contract_id": "8284865859836266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "808968.4"
}, {
  "vmware_agreement": "VMware Agreement #00691573.0",
  "customer_name": "Meli Log Srl",
  "contract_id": "8285168582955198",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "807814.56"
}, {
  "vmware_agreement": "00677320",
  "customer_name": "Tri Met",
  "contract_id": "8285563370470420",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "805522.51"
}, {
  "vmware_agreement": "00497662",
  "customer_name": "PT Pertamina (Persero)",
  "contract_id": "8284714719938867",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "804500.69"
}, {
  "vmware_agreement": "00373512",
  "customer_name": "Shang Software Solutions",
  "contract_id": "8285290035928622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "801387.65"
}];
export const DummyData2: IDataSourceObj[] = [{
  "vmware_agreement": "VMware Agreement #00694098",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8840210340512889",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "873960388.0"
}, {
  "vmware_agreement": "00367819",
  "customer_name": "JAPAN POST INFORMATION TECHNOLOGYCO., LTD",
  "contract_id": "8286318369113889",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "806343232.0"
}, {
  "vmware_agreement": "00680134",
  "customer_name": "MITSUBISHI ELECTRIC INFORMATIONNETWORK CORPORATION",
  "contract_id": "8286671652568335",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "748716916.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "FUKOKU MUTUAL LIFE INSURANCE COMPANY",
  "contract_id": "8840569241500288",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "686987261.0"
}, {
  "vmware_agreement": "00433719",
  "customer_name": "JAL Information Technology Co., Ltd.",
  "contract_id": "8286304840966235",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "682356420.0"
}, {
  "vmware_agreement": "VMware Agreement # 00692456",
  "customer_name": "JAPAN RESEARCH INSTITUTE LIMITED",
  "contract_id": "8286347514559184",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "643929201.0"
}, {
  "vmware_agreement": "00571650",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8286939624452522",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "574134345.0"
}, {
  "vmware_agreement": "00626767",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8286303527712444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "573299329.0"
}, {
  "vmware_agreement": "00492389",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8286022647242333",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "553410000.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "RECRUIT CO., LTD.",
  "contract_id": "8840410314094746",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "503905388.0"
}, {
  "vmware_agreement": "00562209",
  "customer_name": "TKC CORPORATION",
  "contract_id": "8285455583074712",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "474219064.0"
}, {
  "vmware_agreement": "00431562",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285993521329304",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "433576100.0"
}, {
  "vmware_agreement": "00361954",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285926944264498",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "429928000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00361309",
  "customer_name": "SUZUKI MOTOR CORPORATION",
  "contract_id": "8286235131915694",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "413247716.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478544",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285978204637912",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "381611134.0"
}, {
  "vmware_agreement": "00693922",
  "customer_name": "NET ONE SYSTEMS CO., LTD.",
  "contract_id": "8284889744446444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "378969349.0"
}, {
  "vmware_agreement": "VMware Agreement # 00378987",
  "customer_name": "OSAKA PREFECTURAL BOARD OF EDUCATION",
  "contract_id": "8286588459011492",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "362728000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00477115",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285331249581006",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "360000000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00475195",
  "customer_name": "CHUDEN CTI CO.,LTD.",
  "contract_id": "8286131756775774",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "349017190.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367250",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8284949814124126",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "344335416.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MITSUBISHI UFJ NICOS CO., LTD.",
  "contract_id": "8286357813122891",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "335293426.0"
}, {
  "vmware_agreement": "VMware Agreement # 00474761",
  "customer_name": "NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES",
  "contract_id": "8286203379234339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "333739787.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433028",
  "customer_name": "NRI System Techno, Ltd.",
  "contract_id": "8286377969095315",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "320057214.0"
}, {
  "vmware_agreement": "VMware Agreement # 00369261",
  "customer_name": "Japan Racing Association",
  "contract_id": "8285484944557803",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "314529072.0"
}, {
  "vmware_agreement": "00589663",
  "customer_name": "TOSHIBA CORPORATION",
  "contract_id": "8285720422567531",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "313671439.0"
}, {
  "vmware_agreement": "VMware Agreement # 00558790",
  "customer_name": "NAGAN PREFECTURE",
  "contract_id": "8286204050215910",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "289466791.0"
}, {
  "vmware_agreement": "00548899",
  "customer_name": "SEKISUI HOUSE, LTD.",
  "contract_id": "8285791246011780",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "287112153.0"
}, {
  "vmware_agreement": "VMware Agreement # 00397243",
  "customer_name": "Toppan Printing CO., LTD.",
  "contract_id": "8285058165420383",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "285179121.0"
}, {
  "vmware_agreement": "VMware Agreement # 00638793",
  "customer_name": "HOKUHOKU FINANCIAL GROUP INC",
  "contract_id": "8287928861275136",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "282633402.0"
}, {
  "vmware_agreement": "00549089",
  "customer_name": "HITACHI, LTD.",
  "contract_id": "8286555268440805",
  "contract_title": "ELA ORDER FORM",
  "fees": "247107006.0"
}, {
  "vmware_agreement": "00492070",
  "customer_name": "MINISTRY OF INTERNAL AFFAIRS AND COMMUNICATIONS",
  "contract_id": "8285730731005416",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "246359169.0"
}, {
  "vmware_agreement": "00360437",
  "customer_name": "NS Solutions Corporation",
  "contract_id": "8285897659390773",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "246041806.0"
}, {
  "vmware_agreement": "00374229",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284804635896033",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "245369222.0"
}, {
  "vmware_agreement": "00675391",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8285495287676973",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "239527426.0"
}, {
  "vmware_agreement": "00622789",
  "customer_name": "TEPCO POWER GRID, INCORPORATED",
  "contract_id": "8285121623921023",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "231512296.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MIZUHO BANK, LTD.",
  "contract_id": "8286148414921874",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "227400000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679388",
  "customer_name": "JAPAN SECURITIES DEPOSITORY CENTER, INC.",
  "contract_id": "8285572279813193",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "222692098.0"
}, {
  "vmware_agreement": "00620662",
  "customer_name": "JR EAST INFORMATION SYSTEMS COMPANY",
  "contract_id": "8286202767113254",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "221238621.0"
}, {
  "vmware_agreement": "00476553",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8285033030397369",
  "contract_title": "ELA ORDER FORM",
  "fees": "210498569.0"
}, {
  "vmware_agreement": "00428826",
  "customer_name": "Mazda Motor Corporation",
  "contract_id": "8285800991363448",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "205092005.0"
}, {
  "vmware_agreement": "00542947",
  "customer_name": "TOPPAN PRINTING CO. LTD",
  "contract_id": "8285720052545848",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195888500.0"
}, {
  "vmware_agreement": "VMware Agreement # 00443552",
  "customer_name": "Koto City Board Of Education",
  "contract_id": "8286470849027828",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195680960.0"
}, {
  "vmware_agreement": "006933821",
  "customer_name": "MAZDA MOTOR CORPORATION",
  "contract_id": "8285814854369709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195185294.0"
}, {
  "vmware_agreement": "00398318",
  "customer_name": "HONDA MOTOR CO., LTD.",
  "contract_id": "8286838907997103",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "192215160.0"
}, {
  "vmware_agreement": "00620266",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8285641501497143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191749658.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "NTT DOCOMO Inc.",
  "contract_id": "8840546302321300",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191251338.0"
}, {
  "vmware_agreement": "VMware Agreement # 00693063",
  "customer_name": "FUJITSU LIMITED",
  "contract_id": "8286147993302855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191178000.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "The Bank of Yokohama, Ltd.",
  "contract_id": "8284648389335976",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "189766118.0"
}, {
  "vmware_agreement": "00433680",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8286426437631938",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "189552517.0"
}, {
  "vmware_agreement": "VMware Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "DAIDO LIFE INSURANCE COMPANY",
  "contract_id": "8285719433511069",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "184388378.0"
}, {
  "vmware_agreement": "00707340",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8286687434813847",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "183060354.0"
}, {
  "vmware_agreement": "00438143",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284715293795161",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "183008000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373995",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8285538604856905",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "172853000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387464",
  "customer_name": "National Police Agency",
  "contract_id": "8286472061031125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "172234100.0"
}, {
  "vmware_agreement": "00378557",
  "customer_name": "AEON CREDIT SERVICE CO., LTD.",
  "contract_id": "8284873380552189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "169538169.0"
}, {
  "vmware_agreement": "VMware Agreement # 00395608",
  "customer_name": "Hachioji City",
  "contract_id": "8285790843092371",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "158448985.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478025",
  "customer_name": "MIZUHO BANK, LTD.",
  "contract_id": "8285405824392026",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "158380984.0"
}, {
  "vmware_agreement": "00548910",
  "customer_name": "SUZUKI MOTOR CORPORATION",
  "contract_id": "8285719768120207",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "156245097.0"
}, {
  "vmware_agreement": "00476481",
  "customer_name": "SQUARE ENIX CO. LTD",
  "contract_id": "8285952061082156",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "153346900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00621210",
  "customer_name": "JAPANESE RED CROSS AICHI MEDICALCENTER NAGOYA DAINI HOSPITAL",
  "contract_id": "8285858515587034",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "149931807.0"
}, {
  "vmware_agreement": "00691906",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285009303991363",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "148511040.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393599",
  "customer_name": "Koriyama City",
  "contract_id": "8285720587902808",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "146299533.0"
}, {
  "vmware_agreement": "00684366",
  "customer_name": "Tokyu Fudosan Holdings Corporation",
  "contract_id": "8286395599739189",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "145015095.0"
}, {
  "vmware_agreement": "VMware Agreement # 00091933",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8284781975430421",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "144982265.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387000",
  "customer_name": "Bureau Of Waterworks Tokyo Metropolitan Government",
  "contract_id": "8286853951530054",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "142007954.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356153",
  "customer_name": "National Institute for Materials Science",
  "contract_id": "8601439566272512",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141408157.0"
}, {
  "vmware_agreement": "VMware Agreement # 00625643",
  "customer_name": "RECRUIT HOLDINGS CO.,LTD.",
  "contract_id": "8286396033282718",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141302981.0"
}, {
  "vmware_agreement": "VMware Agreement # 00567309",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285331885017096",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "139565374.0"
}, {
  "vmware_agreement": "VMware Agreement # 006787771",
  "customer_name": "HIROSHIMA CITY",
  "contract_id": "8285494634424434",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134770286.0"
}, {
  "vmware_agreement": "00358386",
  "customer_name": "Urban Renaissance Agency",
  "contract_id": "8286023247535867",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "134654390.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360759",
  "customer_name": "SUMITOMO MITSUI TRUST BANK LTD",
  "contract_id": "8285495346695793",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134442797.0"
}, {
  "vmware_agreement": "00433833",
  "customer_name": "JAL Information Technology Co., Ltd.",
  "contract_id": "8286121878134771",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132939760.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KOBE CITY BOARD OF EDUCATION",
  "contract_id": "8286357369705904",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "131989701.0"
}, {
  "vmware_agreement": "VMware Agreement # 00574833",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285720678403499",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128897181.0"
}, {
  "vmware_agreement": "00673154",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8285471605277851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0"
}, {
  "vmware_agreement": "00683650",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8286414546623922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00672781",
  "customer_name": "NATIONAL INSTITUTE OF INFORMATION AND COMMUNICATIONS TECHNOLOGY",
  "contract_id": "8285129615180808",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "127434025.0"
}, {
  "vmware_agreement": "00549090",
  "customer_name": "HITACHI, LTD.",
  "contract_id": "8285763231677663",
  "contract_title": "ELA ORDER FORM",
  "fees": "127041000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00494498",
  "customer_name": "THE UNIVERSITY OF TOKYO",
  "contract_id": "8285897665575932",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "126079427.0"
}, {
  "vmware_agreement": "00480180",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285679230025205",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "121221554.0"
}, {
  "vmware_agreement": "00540574",
  "customer_name": "NTT Plala Inc.",
  "contract_id": "8840162022872235",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "120963808.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MINISTRY OF THE ENVIRONMENT GOVERNMENT OF JAPAN",
  "contract_id": "8286270421429244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118839208.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433240",
  "customer_name": "Sumitomo Mitsui Trust Bank, Limited",
  "contract_id": "8286357534735480",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118826344.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368386",
  "customer_name": "Toyota Industries Corporation",
  "contract_id": "8840546304177855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "118121612.0"
}, {
  "vmware_agreement": "VMware Agreement # 00391740",
  "customer_name": "The Chugoku Electric Power Co., Inc.",
  "contract_id": "8285746939479406",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "117846741.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398077",
  "customer_name": "Yokosuka City",
  "contract_id": "8286193358628335",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "111407319.0"
}, {
  "vmware_agreement": "VMware Agreement # 00700433",
  "customer_name": "JAPAN RACING ASSOCIATION",
  "contract_id": "8285829357824215",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "109488305.0"
}, {
  "vmware_agreement": "VMware Agreement # 00358915",
  "customer_name": "Osaka Municipal Waterworks Bureau",
  "contract_id": "8286023128056862",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "109007017.0"
}, {
  "vmware_agreement": "VMware Agreement # 00569483",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8285547104516441",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "105981777.0"
}, {
  "vmware_agreement": "00361046",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285940011840868",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "104914960.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382979",
  "customer_name": "Daiwa Lifenext Co. Ltd",
  "contract_id": "8286085346164631",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "104433117.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387319",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8286173708438988",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103745123.0"
}, {
  "vmware_agreement": "00489492",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8286805740186235",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "99637590.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367214",
  "customer_name": "ISID-AO, Ltd.",
  "contract_id": "8285813625955633",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98500000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379497",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8285802630868778",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98474405.0"
}, {
  "vmware_agreement": "00698116",
  "customer_name": "NATIONAL MUTUAL INSURANCE FEDERATION OF AGRICULTURAL COOPERATIVES",
  "contract_id": "8285777240557140",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "97873033.0"
}, {
  "vmware_agreement": "00378608",
  "customer_name": "NEC Corporation",
  "contract_id": "8285651775962743",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "96611840.0"
}, {
  "vmware_agreement": "VMware Agreement # 00575908",
  "customer_name": "JAPAN PENSION SERVICE",
  "contract_id": "8284972574021266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "93563985.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373743",
  "customer_name": "K-Opticom Corporation",
  "contract_id": "8285390677469596",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "92778400.0"
}, {
  "vmware_agreement": "VMware OEM Agreement",
  "customer_name": "Metropolitan Police Department",
  "contract_id": "8285157796065083",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "92309909.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549408",
  "customer_name": "TAIYO LIFE INSURANCE COMPANY",
  "contract_id": "8285868859427701",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "89967997.0"
}, {
  "vmware_agreement": "VMware Agreement # 00687761",
  "customer_name": "ROYAL PARK HOTELS AND RESORTS COMPANY, LIMITED.",
  "contract_id": "8286529292097859",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "89868455.0"
}, {
  "vmware_agreement": "VMware Agreement # 00498590",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8286038025949776",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88714429.0"
}, {
  "vmware_agreement": "VMware Agreement # 00362322",
  "customer_name": "Shinshu University Hospital",
  "contract_id": "8285484062436876",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88221526.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679421",
  "customer_name": "ASAHI HOSPITAL",
  "contract_id": "8284795292268974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "86507613.0"
}, {
  "vmware_agreement": "00559988",
  "customer_name": "EHIME PREFECTURE",
  "contract_id": "8285221842810155",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "85854769.0"
}, {
  "vmware_agreement": "VMware Agreement # 00383782",
  "customer_name": "Simplex Inc",
  "contract_id": "8286784284620871",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "85550493.0"
}, {
  "vmware_agreement": "VMware Agreement # 00444834",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8285694529244311",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "83727813.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KAGOSHIMA PREFECTURE",
  "contract_id": "8285344970897200",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "83302456.0"
}, {
  "vmware_agreement": "00391570",
  "customer_name": "SEKISUI PLASTICS CO., LTD.",
  "contract_id": "8286270324638052",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "81405405.0"
}, {
  "vmware_agreement": "00638988",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8840304764720246",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "79659346.0"
}, {
  "vmware_agreement": "VMware Agreement # 00551529",
  "customer_name": "City of Nagoya",
  "contract_id": "8285640784186691",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79566926.0"
}, {
  "vmware_agreement": "00545876",
  "customer_name": "TAKII \u0026 CO., LTD.",
  "contract_id": "8284873435726368",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79523600.0"
}, {
  "vmware_agreement": "00378305",
  "customer_name": "SECOM TRUST SYSTEMS CO., LTD.",
  "contract_id": "8285695225216160",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "79495100.0"
}, {
  "vmware_agreement": "00444706",
  "customer_name": "EBARA CORPORATION",
  "contract_id": "8285992244377377",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78794480.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433855",
  "customer_name": "MITSUBISHI MOTORS CORPORATION",
  "contract_id": "8285651211145545",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78184311.0"
}, {
  "vmware_agreement": "00543300",
  "customer_name": "JAPAN POST Co., Ltd.",
  "contract_id": "8286395981234161",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78060831.0"
}, {
  "vmware_agreement": "VMware OEM Agreement; VMware Contract No. 00091933",
  "customer_name": "Ministry of Economy, Trade and Industry",
  "contract_id": "8286715802846307",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "77963777.0"
}, {
  "vmware_agreement": "VMware Agreement # 005452291",
  "customer_name": "NISHI IBURI WIDE AREA UNION",
  "contract_id": "8285764166691856",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "77184000.0"
}, {
  "vmware_agreement": "00569843",
  "customer_name": "TOKAI CORP.",
  "contract_id": "8285926578194598",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "76967182.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JAPAN METEOROLOGICAL AGENCY",
  "contract_id": "8286651206089275",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "75772536.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549580",
  "customer_name": "YURIHONJO CITY",
  "contract_id": "8285746983893931",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75500000.0"
}, {
  "vmware_agreement": "VMware Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "Ministry of Justice",
  "contract_id": "8286071000271649",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75427386.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386370",
  "customer_name": "Recruit Co. Ltd",
  "contract_id": "8285563687119123",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75009677.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "NATIONAL ASSOCIATION OF RACING",
  "contract_id": "8285306534856772",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "74563089.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "HEIWADO CO., LTD",
  "contract_id": "8285665269994587",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "74120063.0"
}, {
  "vmware_agreement": "VMware Agreement #00375413",
  "customer_name": "MITSUBISHI MOTORS CORPORATION",
  "contract_id": "8286202888872855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "73195000.0"
}, {
  "vmware_agreement": "00689806",
  "customer_name": "THE IYO BANK LTD",
  "contract_id": "8285679898526631",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "72019500.0"
}, {
  "vmware_agreement": "00375593",
  "customer_name": "SAGA-KEN MEDICAL CENTRE KOSEIKANE",
  "contract_id": "8285617638292962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "71612419.0"
}, {
  "vmware_agreement": "00367352",
  "customer_name": "Simplex Inc",
  "contract_id": "8285791209145066",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70839563.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "Ministry of Internal Affairs and Communications",
  "contract_id": "8285378174070673",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70774770.0"
}, {
  "vmware_agreement": "00492962",
  "customer_name": "OPTAGE Inc.",
  "contract_id": "8840498152216883",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70497531.0"
}, {
  "vmware_agreement": "VMware OEM Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "JAPAN POST BANK Co., Ltd.",
  "contract_id": "8286132785015043",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "69390324.0"
}, {
  "vmware_agreement": "VMware Agreement # 00561537",
  "customer_name": "KAGOSHIMA PREFECTURE",
  "contract_id": "8285694103179485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68825576.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386694",
  "customer_name": "Bureau Of Sewerage Tokyo Metropolitan Government",
  "contract_id": "8284828783974485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68511150.0"
}, {
  "vmware_agreement": "00444298",
  "customer_name": "DOCOMO Systems, Inc.",
  "contract_id": "8286022606258162",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68507482.0"
}, {
  "vmware_agreement": "VMware Agreement # 00567804",
  "customer_name": "NIPPON COMSYS CORPORATION",
  "contract_id": "8286716684380645",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67557873.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357046",
  "customer_name": "BRIDGESTONE CORPORATION",
  "contract_id": "8286022941891362",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67393128.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393668",
  "customer_name": "ICHIKAWA CITY BOARD OF EDUCATION",
  "contract_id": "8285678792960035",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67277700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00542977",
  "customer_name": "SQUARE ENIX CO. LTD.",
  "contract_id": "8288224434078472",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "66293800.0"
}, {
  "vmware_agreement": "VMware Agreement # 00397291",
  "customer_name": "TOKAI Corp.",
  "contract_id": "8285625442690168",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "66159732.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393657",
  "customer_name": "NIPPON CHEMI-CON CORPORATION",
  "contract_id": "8840485812780311",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65504736.0"
}, {
  "vmware_agreement": "00378053",
  "customer_name": "GlobalWafers Japan Co., Ltd.",
  "contract_id": "8285745823033713",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65115865.0"
}, {
  "vmware_agreement": "VMware Agreement # 00694313",
  "customer_name": "TSUBAKIMOTO KOGYO CO.,LTD.",
  "contract_id": "8285970337290088",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "62475500.0"
}, {
  "vmware_agreement": "00557224",
  "customer_name": "TOKYU FUDOSAN HOLDINGS CORPORATION",
  "contract_id": "8285110867806695",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "61878924.0"
}, {
  "vmware_agreement": "00494018",
  "customer_name": "SOFTBANK CORP.",
  "contract_id": "8840404707336179",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "60946781.0"
}, {
  "vmware_agreement": "VMware Agreement # 00492599",
  "customer_name": "MIYAZAKI PREFECTURE",
  "contract_id": "8285603271749539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60871356.0"
}, {
  "vmware_agreement": "00486149",
  "customer_name": "OGIS-RI Co.,Ltd.",
  "contract_id": "8286203827476781",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60622595.0"
}, {
  "vmware_agreement": "00679778",
  "customer_name": "TOYOTA SYSTEMS CORPORATION",
  "contract_id": "8285993237314702",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "60461000.0"
}, {
  "vmware_agreement": "00362990",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8286838880788539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "60217144.0"
}, {
  "vmware_agreement": "00493392",
  "customer_name": "Gifu Prefectural Government",
  "contract_id": "8285587021122447",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59697972.0"
}, {
  "vmware_agreement": "VMware Agreement # 00381283",
  "customer_name": "ADVANTEST CORPORATION",
  "contract_id": "8286538635446665",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59602725.0"
}, {
  "vmware_agreement": "VMware Agreement # 00551301",
  "customer_name": "THE NORINCHUKIN BANK",
  "contract_id": "8286319220752175",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59438184.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382195",
  "customer_name": "Matsumoto City",
  "contract_id": "8285405908881280",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "59027527.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393075",
  "customer_name": "Toyota Motor Corporation",
  "contract_id": "8286222192395043",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "58599151.0"
}, {
  "vmware_agreement": "00216417",
  "customer_name": "CHUBU ELECTRIC POWER CO., INC.",
  "contract_id": "8285470127172912",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "58324078.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00378990",
  "customer_name": "Asahi Hospital Service Corp.",
  "contract_id": "8285546467903411",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "57855249.0"
}, {
  "vmware_agreement": "VMware Agreement # 00442337",
  "customer_name": "Ota City",
  "contract_id": "8286235761295223",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57709943.0"
}, {
  "vmware_agreement": "VMware Agreement #00358123",
  "customer_name": "YAMAZAKI MAZAK CORPORATION",
  "contract_id": "8285868414515242",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57531393.0"
}, {
  "vmware_agreement": "VMware Agreement #00378142",
  "customer_name": "Yashima Denki Co., Ltd.",
  "contract_id": "8286768615717709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "57379388.0"
}, {
  "vmware_agreement": "005456851",
  "customer_name": "HAKUHODO DY HOLDINGS INCORPORATED",
  "contract_id": "8285679806459126",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56744202.0"
}, {
  "vmware_agreement": "00637982",
  "customer_name": "JR EAST INFORMATION SYSTEMS",
  "contract_id": "8286822744226807",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "56657700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00474899",
  "customer_name": "SENDAI CITY",
  "contract_id": "8285926791641481",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56643000.0"
}, {
  "vmware_agreement": "00492054",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8285790954730763",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56264689.0"
}, {
  "vmware_agreement": "VMware Agreement #00379217",
  "customer_name": "JR Kyushu System Solutions Inc.",
  "contract_id": "8285815110715533",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55946859.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386047",
  "customer_name": "BUREAU OF TRANSPORTATION TOKYOMETROPOLITAN GOVERNMENT",
  "contract_id": "8285616349730185",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55434395.0"
}, {
  "vmware_agreement": "00557460",
  "customer_name": "OPTAGE INC",
  "contract_id": "8285641229722569",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55000000.0"
}, {
  "vmware_agreement": "00519708",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8284815186702304",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "55000000.0"
}, {
  "vmware_agreement": "00497632",
  "customer_name": "Abiko city office",
  "contract_id": "8286193304534798",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54749461.0"
}, {
  "vmware_agreement": "VMware Agreement # 00494700",
  "customer_name": "ITEC HANKYU HANSHIN CO.,LTD.",
  "contract_id": "8285234991095545",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54673169.0"
}, {
  "vmware_agreement": "00494547",
  "customer_name": "OPTAGE INC",
  "contract_id": "8286173448884947",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54363302.0"
}, {
  "vmware_agreement": "00498756",
  "customer_name": "NIPPON STEEL CORPORATION",
  "contract_id": "8286055444616445",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54360197.0"
}, {
  "vmware_agreement": "00693028",
  "customer_name": "JB ADVANCED TECHNOLOGY CORPORATION",
  "contract_id": "8285097586178847",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54207000.0"
}, {
  "vmware_agreement": "00567179",
  "customer_name": "TOYOTA MOTOR CORPORATION",
  "contract_id": "8286122772227815",
  "contract_title": "Single ELA Resale Agreement OEM",
  "fees": "53998103.0"
}, {
  "vmware_agreement": "00706891",
  "customer_name": "EBARA CORPORATION",
  "contract_id": "8285909355023139",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "53776800.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360102",
  "customer_name": "Japan Post Trading Service Co., Ltd.",
  "contract_id": "8285572364477081",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "53341103.0"
}, {
  "vmware_agreement": "VMware Agreement # 00374639",
  "customer_name": "SUNDRUG CO., LTD.",
  "contract_id": "8286007604664897",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "53192128.0"
}, {
  "vmware_agreement": "95654 (Partner Master Terms) 95656 (VMware Distributor Exhibit) 129974 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "NOMURA SECURITIES CO. LTD",
  "contract_id": "8286084265699098",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "52494750.0"
}, {
  "vmware_agreement": "00545636",
  "customer_name": "J-POWER BUSINESS SERVICE CORPORATION",
  "contract_id": "9429076991245914",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "52210865.0"
}, {
  "vmware_agreement": "VMware Agreement # 00367373",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286281820825714",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51548177.0"
}, {
  "vmware_agreement": "00561516",
  "customer_name": "OMUTA CITY OFFICE",
  "contract_id": "8285157351931237",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51538042.0"
}, {
  "vmware_agreement": "00544106",
  "customer_name": "Sapporo City",
  "contract_id": "8285678970791283",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51245885.0"
}, {
  "vmware_agreement": "VMware Agreement # 00645889",
  "customer_name": "Suntory Business Systems Ltd.",
  "contract_id": "8286619698724280",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50676888.0"
}, {
  "vmware_agreement": "VMware Agreement # 00678948",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286874809046061",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50104935.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Oji Holdings Corporation",
  "contract_id": "8285791515863459",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "49017342.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356450",
  "customer_name": "Mizuho Bank, Ltd.",
  "contract_id": "8285617726173382",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "48807985.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KYUSHU ELECTRIC POWER CO., INC.",
  "contract_id": "8285968452928590",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47000005.0"
}, {
  "vmware_agreement": "00187463",
  "customer_name": "SUMITOMO MITSUI TRUST BANK, LIMITED",
  "contract_id": "8286414203035463",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46599825.0"
}, {
  "vmware_agreement": "VMware Agreement # 00550984",
  "customer_name": "YUZAWA CITY",
  "contract_id": "8285378732666635",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46507350.0"
}, {
  "vmware_agreement": "VMware Agreement # 00432298",
  "customer_name": "TOPPAN PRINTING CO. LTD",
  "contract_id": "8285537149943916",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46038436.0"
}, {
  "vmware_agreement": "00491939",
  "customer_name": "Freddie Mac",
  "contract_id": "8286132798851430",
  "contract_title": "Amendment No. 4 to Single ELA Resale Agreement",
  "fees": "45992149.58"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO., LTD.",
  "contract_id": "8285650579169461",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "45935170.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373590",
  "customer_name": "Oita City Office",
  "contract_id": "8285129039925947",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45772919.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Jupiter Telecommunications Co. Ltd.",
  "contract_id": "8286769245711424",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45640630.0"
}, {
  "vmware_agreement": "00441480",
  "customer_name": "INES CORPORATION",
  "contract_id": "8285880732331206",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "45430522.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398555",
  "customer_name": "Daiwa Institute of Research BusinessInnovation Ltd.",
  "contract_id": "8286203304903102",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "45233760.0"
}, {
  "vmware_agreement": "00431611",
  "customer_name": "Chuden CTI CO.,Ltd.",
  "contract_id": "8285680150219737",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "45165732.0"
}, {
  "vmware_agreement": "00497108",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8285121487131967",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "45110757.0"
}, {
  "vmware_agreement": "00443845",
  "customer_name": "Minato City",
  "contract_id": "8285940008309036",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "44550038.0"
}, {
  "vmware_agreement": "00497801",
  "customer_name": "JECC Corporation",
  "contract_id": "8285640063426659",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "44514288.0"
}, {
  "vmware_agreement": "00680122",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8840403890037821",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "44320027.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8285471704142805",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43897347.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379395",
  "customer_name": "JECC Corporation",
  "contract_id": "8286161176323050",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "43897347.0"
}, {
  "vmware_agreement": "00339482",
  "customer_name": "JECC Corporation",
  "contract_id": "8285880553346390",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "00363007",
  "customer_name": "JECC Corporation",
  "contract_id": "8286172402890379",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8285969483069130",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JECC Corporation",
  "contract_id": "8286768341166726",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "43863768.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386445",
  "customer_name": "Ishinomaki-City",
  "contract_id": "8285447991349841",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43752325.0"
}, {
  "vmware_agreement": "VMware Agreement # 00687708",
  "customer_name": "J-POWER BUSINESS SERVICE CORPORATION",
  "contract_id": "8285858540733727",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43685481.0"
}, {
  "vmware_agreement": "00358518",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8284935399941643",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "43567590.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "TAIYO LIFE INSURANCE COMPANY",
  "contract_id": "8285271514355322",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "43527360.0"
}, {
  "vmware_agreement": "VMware Agreement # 00622767",
  "customer_name": "SAKURA CITY HALLE",
  "contract_id": "8285762347748458",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "43366598.0"
}, {
  "vmware_agreement": "VMware Agreement # 00379374",
  "customer_name": "DENSO CORPORATION",
  "contract_id": "8284948991006664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42994829.0"
}, {
  "vmware_agreement": "VMware Agreement # 00393930",
  "customer_name": "SHIKOKU ELECTRIC POWER CO., INC.",
  "contract_id": "8284984462532751",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42856434.0"
}, {
  "vmware_agreement": "00692077",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8285493805977435",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00477834",
  "customer_name": "Sumitomo Rubber Industries LTD",
  "contract_id": "8285898163100143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "00589641",
  "customer_name": "NTT PC COMMUNICATIONS INCORPORATED",
  "contract_id": "8285537512217555",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356558",
  "customer_name": "Bukkyo University",
  "contract_id": "8286587742323142",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42377700.0"
}, {
  "vmware_agreement": "VMware Agreement # 00443913",
  "customer_name": "NATIONAL DIET LIBRARY",
  "contract_id": "8286055690339894",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42050000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00439857",
  "customer_name": "PHARMACEUTICALS AND MEDICALDEVICES AGENCY",
  "contract_id": "8285626117692139",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41758992.0"
}, {
  "vmware_agreement": "00474657",
  "customer_name": "HIKONE CITY",
  "contract_id": "8285763312630637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41660076.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "JAPAN HADES CO LTD",
  "contract_id": "8286236017178425",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41527024.0"
}, {
  "vmware_agreement": "00469657",
  "customer_name": "JMS CO., LTD.",
  "contract_id": "8288190336987025",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "41506300.0"
}, {
  "vmware_agreement": "114417",
  "customer_name": "TAK CO.,LTD.",
  "contract_id": "8286442435411264",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "41506300.0"
}, {
  "vmware_agreement": "VMware Agreement # 00356559",
  "customer_name": "Fukuchiyama City",
  "contract_id": "8286006939348131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "40893157.0"
}, {
  "vmware_agreement": "00688996",
  "customer_name": "HAMAMATSU IWATA SHINKIN BANK",
  "contract_id": "8284984662572819",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "40600000.0"
}, {
  "vmware_agreement": "00674311",
  "customer_name": "JCOM CO., LTD",
  "contract_id": "8285801590176109",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "39760466.0"
}, {
  "vmware_agreement": "VMware Agreement # 00387321",
  "customer_name": "AISIN SEIKI Co.,Ltd.",
  "contract_id": "8286698374508133",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "39447254.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KASAMA CITY",
  "contract_id": "8285791861756536",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38800682.0"
}, {
  "vmware_agreement": "VMware Agreement # 00569184",
  "customer_name": "SAKAI CITY",
  "contract_id": "8285992914793301",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38736811.0"
}, {
  "vmware_agreement": "VMware Agreement # 00391920",
  "customer_name": "FUKAYA CITY BOARD OF EDUCATION",
  "contract_id": "8285509950665330",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38705357.0"
}, {
  "vmware_agreement": "00620461",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8285345054240627",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38650000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373355",
  "customer_name": "Sapporo Information Network Co Ltd",
  "contract_id": "8285968961620558",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38199609.0"
}, {
  "vmware_agreement": "00600105",
  "customer_name": "DISH Purchasing Corporation",
  "contract_id": "8285234532205543",
  "contract_title": "Network License Agreement",
  "fees": "37500000.0"
}, {
  "vmware_agreement": "00492052",
  "customer_name": "DATE CITY HALLE",
  "contract_id": "8285641512678872",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "37307887.0"
}, {
  "vmware_agreement": "00631531",
  "customer_name": "SUMITOMO LIFE INFORMATION SYSTEMCO.,LTD.",
  "contract_id": "8285939832763703",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "37040500.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "H20 Retailing Corporation",
  "contract_id": "8285938988120636",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "36936900.0"
}, {
  "vmware_agreement": "00187815",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285317893638147",
  "contract_title": "SINGLE ELA RESALE AGR ENT",
  "fees": "36641960.0"
}, {
  "vmware_agreement": "00631498",
  "customer_name": "DOCOMO SYSTEMS, INC.",
  "contract_id": "8285538561277673",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "36592500.0"
}, {
  "vmware_agreement": "00709023",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8285993206907789",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "36293096.0"
}, {
  "vmware_agreement": "00693711",
  "customer_name": "HAAGEN-DAZS JAPAN, INC.",
  "contract_id": "8285814116452944",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35998777.0"
}, {
  "vmware_agreement": "VMware Agreement # 00503797",
  "customer_name": "USJ LLC",
  "contract_id": "8286748243736084",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35995080.0"
}, {
  "vmware_agreement": "00362989",
  "customer_name": "K-OPTICOM CORPORATION",
  "contract_id": "8285694265860622",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "35823901.0"
}, {
  "vmware_agreement": "00437904",
  "customer_name": "Toyota Motor Corporation",
  "contract_id": "8285456252974204",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35739172.0"
}, {
  "vmware_agreement": "VMware OEM Agreement VMware Contract No. 00187797.0 effective as of July 1, 2015",
  "customer_name": "URBAN RENAISSANCE AGENCY",
  "contract_id": "8285221174970400",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35625756.0"
}, {
  "vmware_agreement": "00576309",
  "customer_name": "HARP Co., Ltd",
  "contract_id": "8285290838618743",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35458967.0"
}, {
  "vmware_agreement": "00442337",
  "customer_name": "OTA CITY",
  "contract_id": "8285563047799096",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35301504.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679768",
  "customer_name": "KITAKYUSHU CITY BOARD OF EDUCATION",
  "contract_id": "8285695092612400",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35039936.0"
}, {
  "vmware_agreement": "VMware Enterprise Agreement",
  "customer_name": "Rakuten Group, Inc.",
  "contract_id": "8286457438887400",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35029129.0"
}, {
  "vmware_agreement": "00646071",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8285707469877678",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34991484.0"
}, {
  "vmware_agreement": "005464041",
  "customer_name": "FUJITRANS CORPORATION",
  "contract_id": "8285495169816024",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34926320.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00581748",
  "customer_name": "Mitsubishi UFJ Financial Group Inc",
  "contract_id": "8287425450848955",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "34913667.0"
}, {
  "vmware_agreement": "VMware Agreement # 00187797.0",
  "customer_name": "JAPAN POST BANK CO.,LTD",
  "contract_id": "8286733158706893",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34650089.0"
}, {
  "vmware_agreement": "00373879",
  "customer_name": "US Army",
  "contract_id": "8286303939954534",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "34503645.64"
}, {
  "vmware_agreement": "VMware Agreement # 00432774",
  "customer_name": "NTT DATA MHI SYSTEMS CORPORATION",
  "contract_id": "8285508777181171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34180397.0"
}, {
  "vmware_agreement": "00636960",
  "customer_name": "LAC CO. LTD",
  "contract_id": "8285562900815630",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33695012.0"
}, {
  "vmware_agreement": "VMware Agreement # 00549756",
  "customer_name": "KAJIMA CORPORATION",
  "contract_id": "8285318642182266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33593616.0"
}, {
  "vmware_agreement": "VMware Agreement # 00386328",
  "customer_name": "Sumida City",
  "contract_id": "8286333656482080",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "33138798.0"
}, {
  "vmware_agreement": "114417",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8286203174869894",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "32664600.0"
}, {
  "vmware_agreement": "00543941",
  "customer_name": "Ube city",
  "contract_id": "8285939314758735",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "32060722.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "ICHINOSEKI CITY",
  "contract_id": "8286121513024047",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31685060.0"
}, {
  "vmware_agreement": "VMware Agreement # 00398255",
  "customer_name": "UNIVERSITY OF HYOGO",
  "contract_id": "8286441033234958",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31595669.0"
}, {
  "vmware_agreement": "00447768",
  "customer_name": "TOYOTA MOTOR CORPORATION",
  "contract_id": "8286687501990952",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "31452650.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "Ministry of Foreign Affairs",
  "contract_id": "8285843655812518",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "31243319.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "JAPAN RACING ASSOCIATION",
  "contract_id": "8286635850383543",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30190280.0"
}, {
  "vmware_agreement": "VMware Agreement # 00489851",
  "customer_name": "ZOZO TECHNOLOGIES INC",
  "contract_id": "8286320388398913",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30000000.0"
}, {
  "vmware_agreement": "00685130",
  "customer_name": "Boeing",
  "contract_id": "8284683603190343",
  "contract_title": "ELA ORDER FORM",
  "fees": "29207398.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Okayama Prefectural Government",
  "contract_id": "8285484795522577",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "29200238.0"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "JX NIPPON MINING \u0026 METALS CORPORATION",
  "contract_id": "8286069419616239",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28757625.0"
}, {
  "vmware_agreement": "00179161 (VMware Authorized Distributor Program Agreement) 00179169 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "RECRUIT HOLDINGS CO.,LTD.",
  "contract_id": "8286685848382346",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28502871.0"
}, {
  "vmware_agreement": "00694816",
  "customer_name": "SONY GLOBAL SOLUTIONS INC.",
  "contract_id": "8840414587713057",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28391900.0"
}, {
  "vmware_agreement": "00545440",
  "customer_name": "MITSUI KNOWLEDGE INDUSTRY CO., LTD.",
  "contract_id": "8840554131838677",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28222190.0"
}, {
  "vmware_agreement": "VMware Agreement #00378149",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8286717599427399",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28001899.0"
}, {
  "vmware_agreement": "00494091",
  "customer_name": "SAKAI CITY",
  "contract_id": "8285291777451152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "26951814.0"
}, {
  "vmware_agreement": "00692629",
  "customer_name": "ASAHI KASEI NETWORKS CORPORATION",
  "contract_id": "8285604348793657",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "26492906.0"
}, {
  "vmware_agreement": "00474540",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8286785655592174",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "25310000.0"
}, {
  "vmware_agreement": "00690975",
  "customer_name": "Highmark, Inc.",
  "contract_id": "8285373173277149",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "25298252.57"
}, {
  "vmware_agreement": "VMware Agreement # 00431601",
  "customer_name": "Mitsui Fudosan Co., Ltd",
  "contract_id": "8840372570033962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "25101000.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "HITACHI SOLUTIONS, LTD",
  "contract_id": "8285899353219106",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24857051.0"
}, {
  "vmware_agreement": "00639432",
  "customer_name": "Cigna Corporate Services, LLC",
  "contract_id": "8284936422857369",
  "contract_title": "Amendment # 13 TO THE ELA ORDER FORM",
  "fees": "24488700.0"
}, {
  "vmware_agreement": "00639432",
  "customer_name": "Cigna Corporate Services, LLC",
  "contract_id": "8285203964208338",
  "contract_title": "Amendment # 13 TO THE ELA ORDER FORM",
  "fees": "24488700.0"
}, {
  "vmware_agreement": "00673507",
  "customer_name": "KOCHI CITY",
  "contract_id": "8286618969848244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24395500.0"
}, {
  "vmware_agreement": "VMware Agreement # 00179161 (VMware Authorized Distributor Program Agreement)1 No. 00179169 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "ZOZO TECHNOLOGIES INC",
  "contract_id": "8286086034616546",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24250000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00692773",
  "customer_name": "NTT DOCOMO INC",
  "contract_id": "8285484806787761",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "23104224.0"
}, {
  "vmware_agreement": "VMware Agreement # 00588537",
  "customer_name": "Shizuoka City Office",
  "contract_id": "8285745343529727",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "23011907.0"
}, {
  "vmware_agreement": "00433220",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8285485038954937",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "22774176.0"
}, {
  "vmware_agreement": "00188977",
  "customer_name": "KOCHI UNIVERSITY OF TECHNOLOGY",
  "contract_id": "8285235265276800",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22682968.0"
}, {
  "vmware_agreement": "00545476",
  "customer_name": "SETAGAYA CITY BOARD OF EDUCATION",
  "contract_id": "8286223412323902",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22358500.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "ZOZO Technologies, Inc.",
  "contract_id": "8286768570124472",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "22000000.0"
}, {
  "vmware_agreement": "00378302",
  "customer_name": "OPTAGE Inc.",
  "contract_id": "8840178787201507",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "21904701.0"
}, {
  "vmware_agreement": "00547256",
  "customer_name": "Shoko Chukin Bank Ltd.",
  "contract_id": "8285331484234806",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "21041155.0"
}, {
  "vmware_agreement": "VMware Agreement # 00373204",
  "customer_name": "TepcoSystems",
  "contract_id": "8286805599968199",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "20257600.0"
}, {
  "vmware_agreement": "00672259",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8285418778541983",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "20169359.19"
}, {
  "vmware_agreement": "00378566",
  "customer_name": "OSAKA CITY BOARD OF EDUCATION",
  "contract_id": "8285663852826851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19516400.0"
}, {
  "vmware_agreement": "00477897",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285072551506028",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19464378.97"
}, {
  "vmware_agreement": "00428152",
  "customer_name": "Suntory Business Systems Limited",
  "contract_id": "8285719618772856",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "19040600.0"
}, {
  "vmware_agreement": "00435321",
  "customer_name": "TEPCO SYSTEMS CORPORATION",
  "contract_id": "8286804984057355",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "18677000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433357",
  "customer_name": "China Telecom Corporation Limited 中国电信",
  "contract_id": "8286716012273786",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "18572127.85"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of July 1, 2015",
  "customer_name": "ORIENTAL LAND CO., LTD.",
  "contract_id": "8840482270664831",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "18043750.0"
}, {
  "vmware_agreement": "00547096",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8285969683452015",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement (OEM)",
  "fees": "17717778.0"
}, {
  "vmware_agreement": "VMware Agreement # 00498570",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8284521192806278",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "17471651.13"
}, {
  "vmware_agreement": "00671232",
  "customer_name": "Smarsh Inc.",
  "contract_id": "8285110100212755",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "16709537.8"
}, {
  "vmware_agreement": "00433671",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285495573249626",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "16376802.0"
}, {
  "vmware_agreement": "005431171",
  "customer_name": "Ricoh Company, Ltd.",
  "contract_id": "8286132072150539",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16340000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00558798",
  "customer_name": "China Construction Bank Corporation",
  "contract_id": "8285455467606915",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "16332377.6"
}, {
  "vmware_agreement": "VMware Agreement # 00367892",
  "customer_name": "iret, Inc.",
  "contract_id": "8285344843759940",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16173600.0"
}, {
  "vmware_agreement": "00495536",
  "customer_name": "NTT DOCOMO INC.",
  "contract_id": "8286472890911599",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "16000000.0"
}, {
  "vmware_agreement": "00433029",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286069628254007",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "15993839.0"
}, {
  "vmware_agreement": "00360871",
  "customer_name": "Zenhoren Co.,Ltd.",
  "contract_id": "8285730070128565",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "15280956.0"
}, {
  "vmware_agreement": "00330411",
  "customer_name": "NTT PC Communications Incorporated",
  "contract_id": "8285830264154753",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "15049819.0"
}, {
  "vmware_agreement": "00477310",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285762360370452",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "14973469.0"
}, {
  "vmware_agreement": "00675699",
  "customer_name": "AT\u0026T Services Inc.",
  "contract_id": "8285289667616392",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "14893490.56"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Kintetsu Group Holdings Co. Ltd",
  "contract_id": "8286927337518318",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14889300.0"
}, {
  "vmware_agreement": "VMware Agreement #00396861",
  "customer_name": "Australian Signals Directorate",
  "contract_id": "8285157088134027",
  "contract_title": "ELA ORDER FORM",
  "fees": "14830050.08"
}, {
  "vmware_agreement": "00610418",
  "customer_name": "DNP INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8286086324293077",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14804389.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00554095",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8285992937741211",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "14660540.0"
}, {
  "vmware_agreement": "00430089",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8286717389071539",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14466425.0"
}, {
  "vmware_agreement": "00567798",
  "customer_name": "NIPPON COMSYS CORPORATION",
  "contract_id": "8286054289492265",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14300000.0"
}, {
  "vmware_agreement": "00497142",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8284957166902881",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14283635.0"
}, {
  "vmware_agreement": "VMware Agreement # 00425762",
  "customer_name": "China Mobile Information Technology Company Limited 中移信息技术有限公司",
  "contract_id": "8284936431122523",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14049405.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357295",
  "customer_name": "Oriental Land Co.,Ltd.",
  "contract_id": "8285536789337141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14040810.0"
}, {
  "vmware_agreement": "VMware Agreement #00362529",
  "customer_name": "JPMorgan Chase Bank, National Association",
  "contract_id": "8285564156341094",
  "contract_title": "Amendment No. 16 to the ELA and the Software License and Maintenance Agreement",
  "fees": "14032202.8"
}, {
  "vmware_agreement": "00631902",
  "customer_name": "Charles Schwab \u0026 Co., Inc.",
  "contract_id": "8284816531515027",
  "contract_title": "Amendment no. 13 to ELA Order Form between Charles Schwab and VMware, Inc.",
  "fees": "14001812.0"
}, {
  "vmware_agreement": "VMware Agreement # 00434232",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8286456968378837",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14000000.0"
}, {
  "vmware_agreement": "00477243",
  "customer_name": "State Of Oklahoma / Office Of Management and Enterprise Services",
  "contract_id": "8285858246399397",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "13210396.65"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "SONY GLOBAL SOLUTIONS INC.",
  "contract_id": "8286202467054278",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "13104700.0"
}, {
  "vmware_agreement": "00425090",
  "customer_name": "ADP, LLC",
  "contract_id": "8286717080574359",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "12999414.0"
}, {
  "vmware_agreement": "VMware Agreement # 00357107",
  "customer_name": "CHINA NATIONAL PETROLEUM CORPORATION 中国石油天然气集团有限公司",
  "contract_id": "8285471107451970",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "12864693.48"
}, {
  "vmware_agreement": "00393903",
  "customer_name": "Oriental Land Co., Ltd.",
  "contract_id": "8286570958796625",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "12863532.0"
}, {
  "vmware_agreement": "00447770",
  "customer_name": "THE CHIBA CITY BOARD OF EDUCATION",
  "contract_id": "8286172517697682",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12854044.0"
}, {
  "vmware_agreement": "00544077",
  "customer_name": "NET ONE SYSTEMS CO., LTD.",
  "contract_id": "8285493803213564",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12819485.0"
}, {
  "vmware_agreement": "00435604",
  "customer_name": "FEDEX CORPORATE SERVICES, INC.",
  "contract_id": "8840482647496279",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "12683936.17"
}, {
  "vmware_agreement": "00430588",
  "customer_name": "Salesforce.com, Inc.",
  "contract_id": "8285111229027699",
  "contract_title": "Amendment No. 12 to the ELA Order",
  "fees": "12379800.0"
}, {
  "vmware_agreement": "00495348",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8286717090611905",
  "contract_title": "Enterprise License Agreement Schedule #1 to the Master Agreement",
  "fees": "11981327.0"
}, {
  "vmware_agreement": "00680129",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8287408367010845",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "11863945.0"
}, {
  "vmware_agreement": "00565340",
  "customer_name": "DBS BANK LTD.",
  "contract_id": "8285617815363353",
  "contract_title": "ELA ORDER FORM",
  "fees": "11640000.0"
}, {
  "vmware_agreement": "00215901",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8284973389012039",
  "contract_title": "AMENDMENT NO. 3 TO ADDENDUM NO.11 TO ELA ORDER",
  "fees": "11545668.0"
}, {
  "vmware_agreement": "00588018",
  "customer_name": "Johnson Controls, Inc",
  "contract_id": "8286928158773937",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "11465900.98"
}, {
  "vmware_agreement": "VMware Agreement # 00401340",
  "customer_name": "Resources Link Software Systems(Shenzhen) Company Limited",
  "contract_id": "8285407688203664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "11190633.79"
}, {
  "vmware_agreement": "VMware Agreement # 00474081",
  "customer_name": "Uniadex Ltd",
  "contract_id": "8286333602300037",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "11140000.0"
}, {
  "vmware_agreement": "00486935",
  "customer_name": "FUJI SOFT INC",
  "contract_id": "8286204235153182",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "10790000.0"
}, {
  "vmware_agreement": "00360744.0",
  "customer_name": "Banco Santander Brasil S/A",
  "contract_id": "8840421963064776",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "10236610.0"
}, {
  "vmware_agreement": "00678371",
  "customer_name": "Fidelity Technology Group, LLC",
  "contract_id": "8285059424125902",
  "contract_title": "VMware Enterprise Order",
  "fees": "10001001.82"
}, {
  "vmware_agreement": "00560511",
  "customer_name": "THE CAPITAL GROUP COMPANIES, INC.",
  "contract_id": "8284727211047113",
  "contract_title": "Amendment No. 11 to the ELA Order",
  "fees": "9847843.52"
}, {
  "vmware_agreement": "00497112",
  "customer_name": "CHUDEN CTI CO.,LTD.",
  "contract_id": "8285815591126047",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "9500250.0"
}, {
  "vmware_agreement": "00625957",
  "customer_name": "NTT DOCOMO, INC.",
  "contract_id": "8285952203400606",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "9487913.0"
}, {
  "vmware_agreement": "VMware Agreement #00398240.0",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8840294167088687",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "9427857.0"
}, {
  "vmware_agreement": "00551303",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8286174301884113",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "9253919.22"
}, {
  "vmware_agreement": "00692394",
  "customer_name": "Tesla Inc",
  "contract_id": "8285680087939156",
  "contract_title": "ELA ORDER FORM",
  "fees": "9126648.0"
}, {
  "vmware_agreement": "00488975",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8285008874780639",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "8883872.84"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Telefonica Brasil",
  "contract_id": "8285483562185219",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "7908750.66"
}, {
  "vmware_agreement": "VMware Agreement # 00392699",
  "customer_name": "FedEx",
  "contract_id": "8286008376190004",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "7769966.38"
}, {
  "vmware_agreement": "VMware Agreement # 00478982",
  "customer_name": "State Taxation Administration国家税务总局",
  "contract_id": "8286821438887585",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "7265386.72"
}, {
  "vmware_agreement": "00186613",
  "customer_name": "Federal Bureau of Investigation",
  "contract_id": "8285330529768606",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "7135584.0"
}, {
  "vmware_agreement": "00694083",
  "customer_name": "Jack Henry \u0026 Associates, Inc.",
  "contract_id": "8285306139552593",
  "contract_title": "Amendment 13 to the ELA Order Form",
  "fees": "7120190.93"
}, {
  "vmware_agreement": "00684829 \u0026 00691196",
  "customer_name": "New York Life Insurance Company",
  "contract_id": "8285495023424230",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "6957040.93"
}, {
  "vmware_agreement": "00368936",
  "customer_name": "Lyft, Inc.",
  "contract_id": "8840252263746323",
  "contract_title": "ELA ORDER FORM",
  "fees": "6426000.0"
}, {
  "vmware_agreement": "00425058",
  "customer_name": "PricewaterhouseCoopers Global Licensing Services Corporation",
  "contract_id": "8285587939504864",
  "contract_title": "ELA #5",
  "fees": "6381366.73"
}, {
  "vmware_agreement": "VMware Agreement # 00545439",
  "customer_name": "MITSUI KNOWLEDGE INDUSTRY CO., LTD.",
  "contract_id": "8285168431527591",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6347000.0"
}, {
  "vmware_agreement": "00493009",
  "customer_name": "KYOSAN ELECTRIC MANUFACTURING CO.,LTD.",
  "contract_id": "8284925186102461",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6246250.0"
}, {
  "vmware_agreement": "VMware Agreement No. 00164670",
  "customer_name": "RYOKA SYSTEMS INC.",
  "contract_id": "8285813633325757",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "6185406.0"
}, {
  "vmware_agreement": "VMware Agreement # 00424123",
  "customer_name": "China Merchants Bank Co. Ltd 招商银行股份有限公司",
  "contract_id": "8285510080006747",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "6158403.41"
}, {
  "vmware_agreement": "00607 190",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8285169331779220",
  "contract_title": "VMware ELA",
  "fees": "5772864.82"
}, {
  "vmware_agreement": "00567436",
  "customer_name": "Caisse De Depot Et Placement",
  "contract_id": "8284996493112662",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "5753659.53"
}, {
  "vmware_agreement": "VMware Agreement # 00674551",
  "customer_name": "Fairfax County Government",
  "contract_id": "8285663557593139",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "5522937.04"
}, {
  "vmware_agreement": "VMware Agreement # 00367434",
  "customer_name": "State Taxation Administration国家税务总局",
  "contract_id": "8285143735887717",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5349107.79"
}, {
  "vmware_agreement": "00360995",
  "customer_name": "MDA",
  "contract_id": "8285129113050799",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "5318750.51"
}, {
  "vmware_agreement": "00690997",
  "customer_name": "University of Texas At San Antonio (MAIN)",
  "contract_id": "8284682336835380",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "5294555.83"
}, {
  "vmware_agreement": "VMware Agreement # 00478016",
  "customer_name": "China Unicom Communication Ltd中国联合网络通信集团有限公司",
  "contract_id": "8286191786477192",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5263217.39"
}, {
  "vmware_agreement": "VMware Agreement #00356217",
  "customer_name": "California State Compensation Insurance Fund (SCIF)",
  "contract_id": "8286103494333661",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "5115502.81"
}, {
  "vmware_agreement": "00591309",
  "customer_name": "Target Corporation",
  "contract_id": "8285616962324832",
  "contract_title": "ELA ORDER FORM",
  "fees": "5106620.41"
}, {
  "vmware_agreement": "00566800",
  "customer_name": "Australian Bureau Of Statistics (ABS)",
  "contract_id": "8284674748595017",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "5103301.82"
}, {
  "vmware_agreement": "00538732",
  "customer_name": "Department of Defence",
  "contract_id": "8285373220881269",
  "contract_title": "ELA ORDER FORM",
  "fees": "5052294.84"
}, {
  "vmware_agreement": "00274824",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285908659683239",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "5000000.0"
}, {
  "vmware_agreement": "00308778",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285291123188841",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "5000000.0"
}, {
  "vmware_agreement": "00188571",
  "customer_name": "Us Army Medical Command",
  "contract_id": "8285829674305962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4997589.17"
}, {
  "vmware_agreement": "VMware Agreement #00549612",
  "customer_name": "China International Capital Corporation Limited",
  "contract_id": "8286604303579551",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4908047.73"
}, {
  "vmware_agreement": "00473678",
  "customer_name": "DNP DIGITAL SOLUTIONS CO. LTD",
  "contract_id": "8286376493942811",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4900000.0"
}, {
  "vmware_agreement": "00592065",
  "customer_name": "Paypal, Inc. (Main)",
  "contract_id": "8285470190428211",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "4699264.78"
}, {
  "vmware_agreement": "00188134",
  "customer_name": "Proquire LLC",
  "contract_id": "8284805602872320",
  "contract_title": "ELA ORDER FORM",
  "fees": "4652376.47"
}, {
  "vmware_agreement": "VMware Agreement # 00478753",
  "customer_name": "Ministry Of Ecology And Environment Of The People\u0027s Republic Of China",
  "contract_id": "8285086081174320",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4605651.0"
}, {
  "vmware_agreement": "VMware Agreement # 00486655",
  "customer_name": "Chickasaw Nation",
  "contract_id": "8285448036449254",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4445111.75"
}, {
  "vmware_agreement": "00620553",
  "customer_name": "California State Compensation Insurance Fund (SCIF)",
  "contract_id": "8286346505983084",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4422138.12"
}, {
  "vmware_agreement": "VMW-11857",
  "customer_name": "Toyota Motor Credit Corporation",
  "contract_id": "8286717099803750",
  "contract_title": "Amendment No. 1 (Contract ID: REQ1243157) to the ELA Order Form (REQ1068444) to Master Software License Agreement (Contract ID: VMW-11857)",
  "fees": "4386055.47"
}, {
  "vmware_agreement": "VMware Agreement # 00589146",
  "customer_name": "China Development Bank(国家开发银行)",
  "contract_id": "8840494659349754",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4363729.72"
}, {
  "vmware_agreement": "00691269",
  "customer_name": "Q2 Software, Inc.",
  "contract_id": "8284937031837839",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "4278714.73"
}, {
  "vmware_agreement": "VMware Agreement # 00359841",
  "customer_name": "State of Missouri",
  "contract_id": "8286471849317119",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4179258.5"
}, {
  "vmware_agreement": "VMware Agreement # 00551308",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8284842232331367",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4088217.6"
}, {
  "vmware_agreement": "00540348",
  "customer_name": "Northrop Grumman Corp",
  "contract_id": "8286254563935870",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4074380.0"
}, {
  "vmware_agreement": "00673276",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8284120177040702",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "4066383.6"
}, {
  "vmware_agreement": "00610367",
  "customer_name": "Capital One Services LLC, formerly known as Capital One Services, Inc., on behalf of itself and its Affiliates",
  "contract_id": "8285128826390699",
  "contract_title": "Capital One Services LLC, formerly known as Capital One Services, Inc., on behalf of itself and its Affiliates",
  "fees": "4065952.0"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Employment Development",
  "contract_id": "8285178395714221",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4046449.13"
}, {
  "vmware_agreement": "00572171",
  "customer_name": "Federal Bureau of Investigation",
  "contract_id": "8284658957448052",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "4043047.76"
}, {
  "vmware_agreement": "00706724",
  "customer_name": "Teledyne Technologies Incorporated",
  "contract_id": "8285178522424280",
  "contract_title": "VMware Enterprise Order",
  "fees": "4009096.56"
}, {
  "vmware_agreement": "VMware Agreement # 00492417",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285721267842887",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4000000.0"
}, {
  "vmware_agreement": "00397705",
  "customer_name": "SB C\u0026S CORP.",
  "contract_id": "8284391764446993",
  "contract_title": "ELA ORDER FORM",
  "fees": "4000000.0"
}, {
  "vmware_agreement": "VMware OEM Agreement",
  "customer_name": "Ningde Times Shanghai Intelligent Technology Co., Ltd.",
  "contract_id": "8286854012962260",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "3995326.2"
}, {
  "vmware_agreement": "00375654",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285194783097536",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3990000.0"
}, {
  "vmware_agreement": "00551811",
  "customer_name": "Universal Health Services, Inc",
  "contract_id": "8284972303178714",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "3972872.0"
}, {
  "vmware_agreement": "00424454",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8285693887904581",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3957600.0"
}, {
  "vmware_agreement": "00494464",
  "customer_name": "Alabama Community College System",
  "contract_id": "8288272605460777",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "3864339.12"
}, {
  "vmware_agreement": "VMware Agreement #00391993",
  "customer_name": "FAW-Volkswagen Automotive Co. Ltd一汽-大众汽车有限公司",
  "contract_id": "8286305081257632",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3862293.48"
}, {
  "vmware_agreement": "00545223",
  "customer_name": "FUJISOFT INCORPORATED",
  "contract_id": "8840399481613209",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3850000.0"
}, {
  "vmware_agreement": "00388962",
  "customer_name": "E-Government National Centre",
  "contract_id": "8284499182696494",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "3809552.5"
}, {
  "vmware_agreement": "00216361",
  "customer_name": "Ebay Inc.",
  "contract_id": "8285158544572155",
  "contract_title": "VMware ELA Order Form",
  "fees": "3763935.0"
}, {
  "vmware_agreement": "VMware Agreement # 00478509",
  "customer_name": "Credit Reference Center Pboc中国人民银行征信中心",
  "contract_id": "8285693449278385",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3745251.41"
}, {
  "vmware_agreement": "00218210",
  "customer_name": "EPIC HOSTING LLC",
  "contract_id": "8284900324412439",
  "contract_title": "ELA ORDER FORM",
  "fees": "3709541.0"
}, {
  "vmware_agreement": "00671271",
  "customer_name": "Musarubra US LLC",
  "contract_id": "8285694845459659",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "3673307.03"
}, {
  "vmware_agreement": "00545005",
  "customer_name": "Inova Health System",
  "contract_id": "8284889337287787",
  "contract_title": "ELA ORDER FORM",
  "fees": "3656023.24"
}, {
  "vmware_agreement": "00674590 \u0026 00673609",
  "customer_name": "GRUPO PETERSEN - NUEVO BANCO DE SANTA FE",
  "contract_id": "8840420351334455",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3581431.08"
}, {
  "vmware_agreement": "00572280",
  "customer_name": "USJ LLC",
  "contract_id": "8286748746459854",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "3560000.0"
}, {
  "vmware_agreement": "00637816",
  "customer_name": "Shelter Mutual Insurance Company",
  "contract_id": "8284754069990543",
  "contract_title": "AMENDMENT NO. 3 TO THE ELA ORDER FORM",
  "fees": "3549956.73"
}, {
  "vmware_agreement": "00360869",
  "customer_name": "Bank Of Beijing北京银行",
  "contract_id": "8285456605218974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3526778.25"
}, {
  "vmware_agreement": "00373891",
  "customer_name": "Marriott International Administrative Services, Inc.",
  "contract_id": "8284557045529800",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "3503537.2"
}, {
  "vmware_agreement": "00373891",
  "customer_name": "Marriott International Administrative Services, Inc.",
  "contract_id": "8840321769647060",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "3503537.2"
}, {
  "vmware_agreement": "VMware Agreement # 00688925.0",
  "customer_name": "Inner Mongolia Yili Industrial Group Ltd By Share Ltd Neimenggu 010080, China",
  "contract_id": "8285868687780008",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3452220.8"
}, {
  "vmware_agreement": "00362329",
  "customer_name": "Childrens National Medical Center",
  "contract_id": "8284120198105139",
  "contract_title": "ELA ORDER FORM",
  "fees": "3445205.83"
}, {
  "vmware_agreement": "VMware Agreement # 00543533",
  "customer_name": "NEC Corporation",
  "contract_id": "8286146686247236",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3420000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368312",
  "customer_name": "China Guangfa bank广发银行",
  "contract_id": "8285639957760534",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3376756.79"
}, {
  "vmware_agreement": "VMware Agreement # 00360865",
  "customer_name": "China Merchants Bank Co. Ltd 招商银行股份有限公司",
  "contract_id": "8840500438727666",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3375060.78"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8285909208732696",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3355866.2"
}, {
  "vmware_agreement": "00358936",
  "customer_name": "China Everbright Bank中国光大银行",
  "contract_id": "8840404190499366",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3283860.89"
}, {
  "vmware_agreement": "00445944",
  "customer_name": "BB - Banco do Brasil",
  "contract_id": "8285448220582338",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3280882.53"
}, {
  "vmware_agreement": "VMware Agreement # 00551324",
  "customer_name": "Kanematsu Electronics Ltd.",
  "contract_id": "8286768514767525",
  "contract_title": "VMware ELA",
  "fees": "3254000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00544889",
  "customer_name": "Shanghai Rural Commercial Bank",
  "contract_id": "8286202937604946",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3224832.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "MITSUBISHI ELECTRIC BUILDING TECHNO-SERVICE CO.,LTD.",
  "contract_id": "8286283384879694",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3180000.0"
}, {
  "vmware_agreement": "00565320",
  "customer_name": "Thryv",
  "contract_id": "8286070840821570",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3030848.94"
}, {
  "vmware_agreement": "00358902",
  "customer_name": "Baystate Health",
  "contract_id": "8284659516286281",
  "contract_title": "ELA ORDER FORM",
  "fees": "2998481.94"
}, {
  "vmware_agreement": "00499638",
  "customer_name": "Singapore Telecommunications Limited",
  "contract_id": "8284984436107664",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2961120.09"
}, {
  "vmware_agreement": "00438058",
  "customer_name": "Brother Industries, Ltd.",
  "contract_id": "8285222739614261",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2950000.0"
}, {
  "vmware_agreement": "00622787",
  "customer_name": "KITAMURA CO., LTD.",
  "contract_id": "8284741902530872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2880000.0"
}, {
  "vmware_agreement": "00188752",
  "customer_name": "Bank of America Corporation",
  "contract_id": "8285378245148449",
  "contract_title": "Addendum No. 4 to Schedule A-27 to Master Software License and Maintenance Agreement",
  "fees": "2872298.88"
}, {
  "vmware_agreement": "00636166",
  "customer_name": "SINGTEL OPTUS PTY LTD",
  "contract_id": "8840411133703637",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2829818.21"
}, {
  "vmware_agreement": "00540521",
  "customer_name": "Northwell Health Inc",
  "contract_id": "8286008298369604",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2814574.97"
}, {
  "vmware_agreement": "00492041",
  "customer_name": "Southwest Airlines Co.",
  "contract_id": "8288388589628445",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2803083.0"
}, {
  "vmware_agreement": "00684716",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285625804866516",
  "contract_title": "ELA ORDER FORM",
  "fees": "2791692.9"
}, {
  "vmware_agreement": "00544051",
  "customer_name": "United Airlines",
  "contract_id": "8285008880714033",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2764301.29"
}, {
  "vmware_agreement": "00430118",
  "customer_name": "TURKCELL ILETISIM HIZMETLERI AS",
  "contract_id": "8285746198422261",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2705914.69"
}, {
  "vmware_agreement": "00682066",
  "customer_name": "Kaiser Foundation Hospitals",
  "contract_id": "8285378288834448",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "2661258.71"
}, {
  "vmware_agreement": "00426656",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8284804969199744",
  "contract_title": "ELA ORDER FORM",
  "fees": "2600000.0"
}, {
  "vmware_agreement": "00691624",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8284428209010322",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "2570048.18"
}, {
  "vmware_agreement": "00543903",
  "customer_name": "The Commonwealth of Australia, represented by the Department Of Home Affairs",
  "contract_id": "8284888370620147",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "2559173.76"
}, {
  "vmware_agreement": "00429881",
  "customer_name": "The Williams Companies, Inc",
  "contract_id": "8287391781316130",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2552294.71"
}, {
  "vmware_agreement": "00216189",
  "customer_name": "Proquire LLC",
  "contract_id": "8840413976204944",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "2543976.2"
}, {
  "vmware_agreement": "00631312",
  "customer_name": "PepsiCo, Inc.",
  "contract_id": "8840425000899351",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "2514439.96"
}, {
  "vmware_agreement": "00598773",
  "customer_name": "Alaska Airlines Inc",
  "contract_id": "8284514082950025",
  "contract_title": "ELA ORDER FORM",
  "fees": "2490330.71"
}, {
  "vmware_agreement": "00539136",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8284950275082125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2483602.86"
}, {
  "vmware_agreement": "00549056",
  "customer_name": "Computer Sciences Corporation",
  "contract_id": "8285651076159166",
  "contract_title": "ELA Order Form",
  "fees": "2410348.0"
}, {
  "vmware_agreement": "00672243",
  "customer_name": "World Wide Technology, LLC",
  "contract_id": "8285586323661924",
  "contract_title": "ELA ORDER FORM",
  "fees": "2402717.4"
}, {
  "vmware_agreement": "0069323300007728.0",
  "customer_name": "World Wide Technology, Inc",
  "contract_id": "8285145545931659",
  "contract_title": "ELA ORDER FORM",
  "fees": "2354322.46"
}, {
  "vmware_agreement": "00628100",
  "customer_name": "California Public Utilities Commission",
  "contract_id": "8286102767597922",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "2300355.16"
}, {
  "vmware_agreement": "00218902",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285145446422455",
  "contract_title": "Enterprise License Agreement",
  "fees": "2228492.36"
}, {
  "vmware_agreement": "00556169",
  "customer_name": "Lembaga Hasil Dalam Negeri Malaysia (LHDN)",
  "contract_id": "8285970280088850",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2204320.99"
}, {
  "vmware_agreement": "00545857",
  "customer_name": "Republic Services Procurement, Inc.",
  "contract_id": "8285603593142293",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "2187617.08"
}, {
  "vmware_agreement": "00478511",
  "customer_name": "PICC Property and Casualty Company Limited",
  "contract_id": "8286539384763918",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2159103.88"
}, {
  "vmware_agreement": "00692043",
  "customer_name": "CNO SERVICES, LLC",
  "contract_id": "8284119329908860",
  "contract_title": "VMware vCloud Suite+ Standard (per Core)- 36 month Prepaid",
  "fees": "2147975.19"
}, {
  "vmware_agreement": "00498207",
  "customer_name": "Target Corporation",
  "contract_id": "8285828719760149",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "2131647.77"
}, {
  "vmware_agreement": "00559396",
  "customer_name": "Government Technology Agency Of Singapore",
  "contract_id": "8285563537730386",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2118817.44"
}, {
  "vmware_agreement": "00497117",
  "customer_name": "IBARAKI PREFECTURE",
  "contract_id": "8285857427506824",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "2083761.0"
}, {
  "vmware_agreement": "00549805",
  "customer_name": "Contemporary Amperex Technology Co Ltd",
  "contract_id": "8285144046921020",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2047907.15"
}, {
  "vmware_agreement": "00588345",
  "customer_name": "Choctaw Nation of Oklahoma",
  "contract_id": "8285605113706572",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2038651.72"
}, {
  "vmware_agreement": "00678713",
  "customer_name": "Franciscan Missionaries of Our Lady Health System, Inc*",
  "contract_id": "8285586989068311",
  "contract_title": "VMware Enterprise Order",
  "fees": "2031822.4"
}, {
  "vmware_agreement": "00431887",
  "customer_name": "Evergrande Tourism Group恒大旅游集团",
  "contract_id": "8285858028342449",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2013118.14"
}, {
  "vmware_agreement": "00551726",
  "customer_name": "BNSF Railway",
  "contract_id": "8840339332375036",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2011932.0"
}, {
  "vmware_agreement": "00493994",
  "customer_name": "Lyft, Inc.",
  "contract_id": "8284232035890752",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000640.0"
}, {
  "vmware_agreement": "00438169",
  "customer_name": "Networld Corporation",
  "contract_id": "8285258112051001",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "VMware International Unlimited Company",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285167723932175",
  "contract_title": "ELA ORDER FORM",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "00572429",
  "customer_name": "Auckland Council",
  "contract_id": "8284972240916025",
  "contract_title": "VMware Enterprise Agreement",
  "fees": "2000000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00429206",
  "customer_name": "Grupo Petersen - Nuevo Banco de Santa Fe",
  "contract_id": "8285603519978982",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1992420.05"
}, {
  "vmware_agreement": "00697146",
  "customer_name": "California Environmental Protection Agency",
  "contract_id": "8285447154378563",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "1990291.54"
}, {
  "vmware_agreement": "00684694",
  "customer_name": "LinkedIn Corporation",
  "contract_id": "8285650546975703",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1988878.36"
}, {
  "vmware_agreement": "00657483",
  "customer_name": "MURAMOTO CORPORATION",
  "contract_id": "8285731563959298",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1980000.0"
}, {
  "vmware_agreement": "00596259",
  "customer_name": "Adobe Inc.",
  "contract_id": "8840211181188971",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1960257.47"
}, {
  "vmware_agreement": "00503243",
  "customer_name": "S\u0026P Global",
  "contract_id": "8285624967859043",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1948992.5"
}, {
  "vmware_agreement": "VMware Agreement #00436694",
  "customer_name": "Childrens Hospital of New Orleans*(LCMC)",
  "contract_id": "8285898751182186",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1947938.54"
}, {
  "vmware_agreement": "00433737",
  "customer_name": "MGMC",
  "contract_id": "8285447509859929",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1932106.25"
}, {
  "vmware_agreement": "00391966",
  "customer_name": "Banco Bradesco S.A.",
  "contract_id": "8286412899321115",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1914285.4"
}, {
  "vmware_agreement": "00498272",
  "customer_name": "VF Services, LLC on behalf of VF Corporation",
  "contract_id": "8285720052471681",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "1882951.03"
}, {
  "vmware_agreement": "00680557",
  "customer_name": "Delaware Department Of Technology \u0026 Information",
  "contract_id": "8286358006633044",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1866094.42"
}, {
  "vmware_agreement": "00670769",
  "customer_name": "Vizient Inc",
  "contract_id": "8285707893531669",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "1855484.16"
}, {
  "vmware_agreement": "00631576",
  "customer_name": "Netapp",
  "contract_id": "8284956980126740",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1852721.95"
}, {
  "vmware_agreement": "00476808",
  "customer_name": "Iret Inc.",
  "contract_id": "8285509898534107",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1850000.0"
}, {
  "vmware_agreement": "00388953",
  "customer_name": "Nec Solution Innovators Ltd",
  "contract_id": "8286223335291679",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1843000.0"
}, {
  "vmware_agreement": "00677811",
  "customer_name": "City of Scottsdale",
  "contract_id": "8285405612275319",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1842770.82"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "Santa Clara Valley Medical Center",
  "contract_id": "8286377490930400",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1834515.88"
}, {
  "vmware_agreement": "00360426",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285087399861067",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1831456.46"
}, {
  "vmware_agreement": "00433808",
  "customer_name": "Temple University Health System, Inc.(TUHS)",
  "contract_id": "8286555276256502",
  "contract_title": "AM3",
  "fees": "1820774.2"
}, {
  "vmware_agreement": "00693719",
  "customer_name": "FOXTEL MANAGEMENT PTY LTD FOR AND ON BEHALF OF THE FOXTEL PARTNERSHIP",
  "contract_id": "8285087627653005",
  "contract_title": "Vmware Enterprise Licensing Agreement",
  "fees": "1817763.14"
}, {
  "vmware_agreement": "00669524",
  "customer_name": "Global Payments Inc",
  "contract_id": "8285241295380366",
  "contract_title": "Amendment No. 13 to the ELA Order Form",
  "fees": "1815840.0"
}, {
  "vmware_agreement": "VMware Agreement # 00382162",
  "customer_name": "JR East Japan Information Systems Company",
  "contract_id": "8285455629030193",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8286335056368944",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00376019",
  "customer_name": "NEC Corporation",
  "contract_id": "8286993806757910",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360309",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8285899517585390",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00360280",
  "customer_name": "Kanematsu Electronics Ltd",
  "contract_id": "8285829681148019",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Agreement # 00368144",
  "customer_name": "Hitachi Systems, Ltd.",
  "contract_id": "8286269094748994",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "00370893",
  "customer_name": "NTT DATA INTELLILINK CORPORATION",
  "contract_id": "8285306491297637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1813900.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, April 3, 2018",
  "customer_name": "LINE Bank Taiwan Limited",
  "contract_id": "8840569498079212",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1811280.27"
}, {
  "vmware_agreement": "00493632",
  "customer_name": "University of Texas At San Antonio",
  "contract_id": "8285939623095872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1795551.8"
}, {
  "vmware_agreement": "VMware Agreement # 00215775",
  "customer_name": "Navy Engineering Logistics Office (NELO)",
  "contract_id": "8840277655692021",
  "contract_title": "NELO ISAT Deferred v10",
  "fees": "1792030.48"
}, {
  "vmware_agreement": "00362069",
  "customer_name": "Uber Technologies, Inc.",
  "contract_id": "8285909063174376",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1775893.9"
}, {
  "vmware_agreement": "00641414",
  "customer_name": "University of Pittsburgh-Of the Commonwealth System of Higher Education",
  "contract_id": "8285130231648386",
  "contract_title": "ELA ORDER FORM",
  "fees": "1773344.85"
}, {
  "vmware_agreement": "00645994",
  "customer_name": "University of Pittsburgh-Of the Commonwealth System of Higher Education",
  "contract_id": "8284958261056252",
  "contract_title": "ELA ORDER FORM",
  "fees": "1773344.85"
}, {
  "vmware_agreement": "00188559",
  "customer_name": "Australian Bureau Of Statistics (ABN 26 331 428522), an Australian Commonwealth Government entity",
  "contract_id": "8284910850489758",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1765905.16"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285085827417093",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "fees": "1765358.57"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285420169337493",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "fees": "1765358.57"
}, {
  "vmware_agreement": "VMware Agreement # 00426647",
  "customer_name": "MUFG BANK, LTD",
  "contract_id": "8286022099939143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1754313.63"
}, {
  "vmware_agreement": "VMware Agreement # 00574455",
  "customer_name": "National University of Singapore",
  "contract_id": "8286604498982858",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1747514.55"
}, {
  "vmware_agreement": "00503177",
  "customer_name": "GoDaddy.com, LLC",
  "contract_id": "8285167531015969",
  "contract_title": "ELA ORDER FORM",
  "fees": "1725683.37"
}, {
  "vmware_agreement": "00559950",
  "customer_name": "A Toda Hora - ATH S.A.",
  "contract_id": "8285908899144731",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "1714882.51"
}, {
  "vmware_agreement": "VMware Agreement # 00380691",
  "customer_name": "Department Of Jobs And Small Business",
  "contract_id": "8285280306859341",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1712541.48"
}, {
  "vmware_agreement": "VMware Agreement # 00380691",
  "customer_name": "Department Of Jobs And Small Business",
  "contract_id": "8285279500324817",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1712541.48"
}, {
  "vmware_agreement": "VMware Agreement # 00339442, 00274954",
  "customer_name": "SAMSUNG ELECTRONICS CO.,LTD",
  "contract_id": "8840554476105293",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1711841.98"
}, {
  "vmware_agreement": "006797521",
  "customer_name": "TELEKOM MALAYSIA BERHAD",
  "contract_id": "8285693417444356",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1665664.08"
}, {
  "vmware_agreement": "00677129",
  "customer_name": "UT Southwestern Medical Center",
  "contract_id": "8286377323134218",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1661020.31"
}, {
  "vmware_agreement": "00492320",
  "customer_name": "Marsh \u0026 McLennan Companies, Inc.",
  "contract_id": "8284794458013989",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1657110.54"
}, {
  "vmware_agreement": "00591964",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285071759278840",
  "contract_title": "ELA ORDER FORM",
  "fees": "1653490.06"
}, {
  "vmware_agreement": "00485665",
  "customer_name": "JPMorgan Chase Bank, National Association",
  "contract_id": "8285563623918212",
  "contract_title": "SCHEDULE # 3 TO THE MASTER AGREEMENT",
  "fees": "1648734.58"
}, {
  "vmware_agreement": "00484913",
  "customer_name": "Ally Financial Inc",
  "contract_id": "8284753996610905",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1645397.23"
}, {
  "vmware_agreement": "00501854",
  "customer_name": "Lowe\u0027s Companies Inc",
  "contract_id": "8840321769432560",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1644280.56"
}, {
  "vmware_agreement": "006091481",
  "customer_name": "Department of Health",
  "contract_id": "8285121000290894",
  "contract_title": "ELA ORDER FORM",
  "fees": "1643950.0"
}, {
  "vmware_agreement": "00476973",
  "customer_name": "LG CNS Co Ltd",
  "contract_id": "8284958887649952",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1638400.0"
}, {
  "vmware_agreement": "00641164",
  "customer_name": "Miami-Dade County Public Schools-158",
  "contract_id": "8285058951377368",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1621142.24"
}, {
  "vmware_agreement": "00495141",
  "customer_name": "Sinclair Services Company",
  "contract_id": "8285640378110075",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1608371.45"
}, {
  "vmware_agreement": "00684962",
  "customer_name": "Cabela\u0027s LLC",
  "contract_id": "8285145718309586",
  "contract_title": "Amendment No. 13 to the ELA Order",
  "fees": "1601639.44"
}, {
  "vmware_agreement": "00499225",
  "customer_name": "Sinclair Services Company",
  "contract_id": "8285731060634426",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1599392.43"
}, {
  "vmware_agreement": "00190710",
  "customer_name": "American Airlines, Inc.",
  "contract_id": "8285193001555058",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1597530.74"
}, {
  "vmware_agreement": "VMware Agreement #00364338",
  "customer_name": "UCLA Health Systems",
  "contract_id": "8285510256192822",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1589855.01"
}, {
  "vmware_agreement": "00690775",
  "customer_name": "Servicenow Inc",
  "contract_id": "8284209036944057",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1574944.0"
}, {
  "vmware_agreement": "00498992",
  "customer_name": "West Virginia University Wvu Extension Service",
  "contract_id": "8285096400611251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1564499.73"
}, {
  "vmware_agreement": "VMware Agreement # 00380012",
  "customer_name": "Ping An Insurance (Group) Company OfChina Ltd.中国平安保险集团股份有限公司",
  "contract_id": "8285650982137253",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1562959.92"
}, {
  "vmware_agreement": "00219424",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8285194585135244",
  "contract_title": "ELA",
  "fees": "1553900.47"
}, {
  "vmware_agreement": "VMware Agreement # 004995371",
  "customer_name": "OSDE - Organizacion de Servicios DirectosEmpresarios",
  "contract_id": "8286767784460668",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1553238.15"
}, {
  "vmware_agreement": "00375580",
  "customer_name": "Carnival Corporation (Main)",
  "contract_id": "8283905882817997",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1550027.01"
}, {
  "vmware_agreement": "006794111",
  "customer_name": "Suruhanjaya Syarikat Malaysia",
  "contract_id": "8284983158697574",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1545159.74"
}, {
  "vmware_agreement": "VMware Agreement # 00375089",
  "customer_name": "California Department Of Public Health",
  "contract_id": "8286269923260013",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1529189.42"
}, {
  "vmware_agreement": "VMware Agreement # 00609932",
  "customer_name": "COMFORTDELGRO CORPORATION LIMITED",
  "contract_id": "8284973435253251",
  "contract_title": "VMware Single Resales ELA Agreement",
  "fees": "1510000.0"
}, {
  "vmware_agreement": "00672939",
  "customer_name": "Het CA",
  "contract_id": "8284714879940765",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1503487.03"
}, {
  "vmware_agreement": "VMware Agreement # 00430304",
  "customer_name": "AXA Equitable Life Insurance Company",
  "contract_id": "8286203467001911",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1500000.0"
}, {
  "vmware_agreement": "00393718",
  "customer_name": "Department Of Social Services",
  "contract_id": "8284602435512412",
  "contract_title": "ELA ORDER FORM",
  "fees": "1499469.0"
}, {
  "vmware_agreement": "VMware Agreement # 00394276",
  "customer_name": "Lower Colorado River Authority (Austin)",
  "contract_id": "8285510109620436",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1480315.2"
}, {
  "vmware_agreement": "VMware Agreement # 00484388",
  "customer_name": "Dfw International Airport",
  "contract_id": "8285356376763164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1474380.6"
}, {
  "vmware_agreement": "VMware Agreement# 00690390",
  "customer_name": "Ministry Of Education (lm)",
  "contract_id": "8286378383748553",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1471148.59"
}, {
  "vmware_agreement": "00637945",
  "customer_name": "SouthState Bank, N.A.",
  "contract_id": "8285471608601653",
  "contract_title": "VMWARE ELA 3YRS",
  "fees": "1465876.14"
}, {
  "vmware_agreement": "00492936",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285746610877411",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1460000.0"
}, {
  "vmware_agreement": "00479677",
  "customer_name": "AUTOMAGI INC",
  "contract_id": "8284888893671279",
  "contract_title": "ELA ORDER FORM",
  "fees": "1460000.0"
}, {
  "vmware_agreement": "VMware Agreement #00358077",
  "customer_name": "CENTRAL PROVIDENT FUND BOARD",
  "contract_id": "8286822342796460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1451329.4"
}, {
  "vmware_agreement": "00218219",
  "customer_name": "Cabela\u0027s Incorporated",
  "contract_id": "8284972705554560",
  "contract_title": "VMware ELA Renewal",
  "fees": "1448142.25"
}, {
  "vmware_agreement": "00549355",
  "customer_name": "Micron Technology, Inc.",
  "contract_id": "8285707616859467",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "fees": "1441428.3"
}, {
  "vmware_agreement": "00611032",
  "customer_name": "Intuit Inc.",
  "contract_id": "8284911497114039",
  "contract_title": "ELA ORDER FORM",
  "fees": "1431316.6"
}, {
  "vmware_agreement": "VMware Agreement # 00433772",
  "customer_name": "Superior Court - Maricopa County",
  "contract_id": "8286734098172789",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1428798.5"
}, {
  "vmware_agreement": "00378783",
  "customer_name": "DHS - CISE",
  "contract_id": "8286038111677301",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1420641.58"
}, {
  "vmware_agreement": "00380880",
  "customer_name": "Ford Motor Company",
  "contract_id": "8285017568697980",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1403777.99"
}, {
  "vmware_agreement": "VMware Agreement # 00495607",
  "customer_name": "FUJITSU FSAS INC",
  "contract_id": "8286685986057496",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1400000.0"
}, {
  "vmware_agreement": "00356672",
  "customer_name": "COMMONWEALTH OF AUSTRALIA AS REPRESENTED BY DEPARTMENT OF FOREIGN AFFAIRS AND TRADE (DEAT) REGISTRY P",
  "contract_id": "8285290453822302",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1399736.22"
}, {
  "vmware_agreement": "00190486",
  "customer_name": "Eaton Vance Management",
  "contract_id": "8284754346239797",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "1386703.0"
}, {
  "vmware_agreement": "00598772",
  "customer_name": "Abbott Laboratories",
  "contract_id": "8283843682366114",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1383768.31"
}, {
  "vmware_agreement": "00682905",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8840260085331932",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "1372446.07"
}, {
  "vmware_agreement": "00679899",
  "customer_name": "MARS",
  "contract_id": "8285495548394257",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1371888.31"
}, {
  "vmware_agreement": "00544065",
  "customer_name": "Government of Nunavut as represented by the Minister of Community and Government Services",
  "contract_id": "8286686409433138",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "1363369.14"
}, {
  "vmware_agreement": "VMware Agreement #00378472",
  "customer_name": "Petrobras - Petroleo Brasileiro",
  "contract_id": "8285429341404750",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1324483.29"
}, {
  "vmware_agreement": "00592981",
  "customer_name": "Allegis Group, Inc.",
  "contract_id": "8285059835578163",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1302176.68"
}, {
  "vmware_agreement": "00694662",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8840487719187012",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "1296320.74"
}, {
  "vmware_agreement": "00619807",
  "customer_name": "Jefferson County Commission (main)",
  "contract_id": "8285471459240054",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1290100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00380834",
  "customer_name": "Confed Interestadual das coop ligadas ao sicredi",
  "contract_id": "8285969143195766",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1286526.08"
}, {
  "vmware_agreement": "00497909",
  "customer_name": "Tarrant County College District",
  "contract_id": "8285719473664575",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1285444.03"
}, {
  "vmware_agreement": "00684064",
  "customer_name": "Washoe County",
  "contract_id": "8285763453617442",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1279904.0"
}, {
  "vmware_agreement": "00373227",
  "customer_name": "OMRON CORPORATION",
  "contract_id": "8840279683780653",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1270700.0"
}, {
  "vmware_agreement": "00495255",
  "customer_name": "Tata Consultancy Services Ltd",
  "contract_id": "8286854217921123",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1266997.92"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, VMware Partner Agreement No. 00274954",
  "customer_name": "Ebay Korea CO Ltd",
  "contract_id": "8285471144753030",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1263223.12"
}, {
  "vmware_agreement": "VMware Agreement # 00387286",
  "customer_name": "Electric Power Board",
  "contract_id": "8286442775168244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1263112.48"
}, {
  "vmware_agreement": "00386769",
  "customer_name": "PricewaterhouseCoopers Global Licensing Services Corporation",
  "contract_id": "8285033833082754",
  "contract_title": "Enterprise License Agreement Order Form #5",
  "fees": "1262640.0"
}, {
  "vmware_agreement": "VMware Agreement # 00378548",
  "customer_name": "Delaware Health and Social Services(DHSS)",
  "contract_id": "8286425806336557",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1260537.2"
}, {
  "vmware_agreement": "00573764",
  "customer_name": "Terrier Media Buyer, Inc., d/b/a Cox Media Group",
  "contract_id": "8284405051853876",
  "contract_title": "Enterprise License Agreement",
  "fees": "1256095.81"
}, {
  "vmware_agreement": "00394016",
  "customer_name": "Thomas Jefferson University",
  "contract_id": "8840321756810494",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1256000.0"
}, {
  "vmware_agreement": "00562167",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286928085811842",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1254400.0"
}, {
  "vmware_agreement": "VMware Agreement # 00401310",
  "customer_name": "Banco Original",
  "contract_id": "8288272684886564",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1251354.05"
}, {
  "vmware_agreement": "00246621",
  "customer_name": "Sandata Technologies",
  "contract_id": "8285605189991081",
  "contract_title": "ELA ORDER FORM",
  "fees": "1250000.0"
}, {
  "vmware_agreement": "00390489",
  "customer_name": "Orlando Utilities Commission",
  "contract_id": "8285790608247420",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1249729.33"
}, {
  "vmware_agreement": "VMware Agreement # 00672804",
  "customer_name": "Inland Revenue Department",
  "contract_id": "8286022121485152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1244960.64"
}, {
  "vmware_agreement": "00635408",
  "customer_name": "W.L. Gore \u0026 Associates Inc",
  "contract_id": "8284674843441431",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1236488.93"
}, {
  "vmware_agreement": "00635408",
  "customer_name": "W.L. Gore \u0026 Associates Inc",
  "contract_id": "8284842548649393",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1236488.93"
}, {
  "vmware_agreement": "00573799",
  "customer_name": "Berkshire Hathaway Energy Company f/k/a MidAmerican Energy Holdings Company",
  "contract_id": "8285306386118951",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "1232493.04"
}, {
  "vmware_agreement": "VMware Agreement # 00359192",
  "customer_name": "Kirtland AFB (AFMC)",
  "contract_id": "8285562745595158",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1213843.05"
}, {
  "vmware_agreement": "00674220",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8286378022666939",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1209498.0"
}, {
  "vmware_agreement": "00187804",
  "customer_name": "Whirlpool Corporation (Main Account)",
  "contract_id": "8285195029748355",
  "contract_title": "Enterprise License Agreement",
  "fees": "1204974.91"
}, {
  "vmware_agreement": "00559980",
  "customer_name": "Premera Blue Cross",
  "contract_id": "8285537000297387",
  "contract_title": "VMWARE INC - Amendment 3 to Enterprise License Agreement and Quote 5679925.1",
  "fees": "1204358.6"
}, {
  "vmware_agreement": "006785591",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8283960730973742",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1200000.0"
}, {
  "vmware_agreement": "00566756",
  "customer_name": "National Marrow Donor Program",
  "contract_id": "8285538242755567",
  "contract_title": "ELA - VMWare (Eplus) NMDP",
  "fees": "1195974.87"
}, {
  "vmware_agreement": "00362091",
  "customer_name": "Q-Den Business Solutions Co. Inc.",
  "contract_id": "8286994178877853",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "1187500.0"
}, {
  "vmware_agreement": "00442428",
  "customer_name": "BERKSHIRE HATHAWAY ENERGY F/K/A MIDAMERICAN ENERGY HOLDINGS COMPANY",
  "contract_id": "8284768866902245",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1182970.7"
}, {
  "vmware_agreement": "00429211",
  "customer_name": "BERKSHIRE HATHAWAY ENERGY COMPANY F/K/AMIDAMERICA ENERGY HOLDINGS COMPANY",
  "contract_id": "8284413685593694",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1181636.9"
}, {
  "vmware_agreement": "00435509",
  "customer_name": "AXIS BANK LTD",
  "contract_id": "8285087682429592",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1175014.93"
}, {
  "vmware_agreement": "00397675",
  "customer_name": "Oncor Electric Delivery Company LLC",
  "contract_id": "8285418720719501",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "1173810.58"
}, {
  "vmware_agreement": "VMware Agreement # 00475561",
  "customer_name": "City of Riverside",
  "contract_id": "8285586791130782",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1164474.0"
}, {
  "vmware_agreement": "00595854",
  "customer_name": "MARS",
  "contract_id": "8285178456284345",
  "contract_title": "ELA ORDER FORM",
  "fees": "1154821.05"
}, {
  "vmware_agreement": "00361401",
  "customer_name": "David Yurman Enterprises LLC",
  "contract_id": "8840487711559655",
  "contract_title": "ELA ORDER FORM",
  "fees": "1150318.84"
}, {
  "vmware_agreement": "00358517",
  "customer_name": "Itaipu Binacional",
  "contract_id": "8286193082896622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1150001.73"
}, {
  "vmware_agreement": "00362082",
  "customer_name": "TD Ameritrade",
  "contract_id": "8284806041310834",
  "contract_title": "Amendment No. 3 to the ELA Order",
  "fees": "1135485.27"
}, {
  "vmware_agreement": "00359045",
  "customer_name": "Kronos Incorporated",
  "contract_id": "8285546233201526",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1129521.59"
}, {
  "vmware_agreement": "VMware Agreement #00483326",
  "customer_name": "OXY Inc.",
  "contract_id": "8285694662358499",
  "contract_title": "Enterprise License Agreement Order No. 2",
  "fees": "1123610.0"
}, {
  "vmware_agreement": "00391696",
  "customer_name": "Hobby Lobby Stores, Inc.",
  "contract_id": "8285318596468977",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1123182.78"
}, {
  "vmware_agreement": "00357864.0",
  "customer_name": "Duke Energy Business Services LLC",
  "contract_id": "8285446629691567",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1120328.2"
}, {
  "vmware_agreement": "00188231",
  "customer_name": "Raymond James \u0026 Associates, Inc.",
  "contract_id": "8286717077819270",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "1117390.5"
}, {
  "vmware_agreement": "00690504",
  "customer_name": "Soboba Casino",
  "contract_id": "8285344924838274",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1115862.75"
}, {
  "vmware_agreement": "00428124",
  "customer_name": "National Rural Utilities Cooperative Finance Corporation",
  "contract_id": "8285456150995514",
  "contract_title": "ELA ORDER FORM",
  "fees": "1112675.0"
}, {
  "vmware_agreement": "00488996",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8285205457974655",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1111530.0"
}, {
  "vmware_agreement": "00391849",
  "customer_name": "Sacramento Superior Court",
  "contract_id": "8285546956183565",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1107401.13"
}, {
  "vmware_agreement": "00361404",
  "customer_name": "Carnival Corporation (Main)",
  "contract_id": "8284984295104971",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1103643.82"
}, {
  "vmware_agreement": "VMware Agreement # 00676342",
  "customer_name": "KYOCERA CORPORATION",
  "contract_id": "8840260121166211",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1100000.0"
}, {
  "vmware_agreement": "00489360",
  "customer_name": "ServiceNow, Inc",
  "contract_id": "8285651193370287",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1088261.93"
}, {
  "vmware_agreement": "00691042",
  "customer_name": "AECOM",
  "contract_id": "8285204894065234",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1085068.65"
}, {
  "vmware_agreement": "00637791",
  "customer_name": "Temple University",
  "contract_id": "8285305665943062",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1061617.74"
}, {
  "vmware_agreement": "00386425",
  "customer_name": "Sisters of Mercy Health System-St. Louis/St. John\u0027s Mercy Hospital",
  "contract_id": "8285110100111993",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "1056094.95"
}, {
  "vmware_agreement": "00368958",
  "customer_name": "Anheuser Busch Companies, LLC",
  "contract_id": "8840294149952591",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1054441.49"
}, {
  "vmware_agreement": "00706848",
  "customer_name": "State Of California Office Of The Governor",
  "contract_id": "8285731977621379",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "1053040.0"
}, {
  "vmware_agreement": "00498447",
  "customer_name": "Gilead Sciences, Inc.",
  "contract_id": "8285258896213863",
  "contract_title": "Amendment No. 8 to the ELA",
  "fees": "1048058.52"
}, {
  "vmware_agreement": "00215977",
  "customer_name": "AFR",
  "contract_id": "8840307761789033",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1047938.5"
}, {
  "vmware_agreement": "00379195",
  "customer_name": "Pima County",
  "contract_id": "8285664406266222",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1047000.0"
}, {
  "vmware_agreement": "00445241",
  "customer_name": "Navajo Tribal Utility Authority",
  "contract_id": "8286414017212141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1041694.83"
}, {
  "vmware_agreement": "00379397",
  "customer_name": "Land Transport Authority (LTA)",
  "contract_id": "8286457602323227",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1037194.79"
}, {
  "vmware_agreement": "00685395",
  "customer_name": "The Bank of New York Mellon Corporation",
  "contract_id": "9429038152457439",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1037112.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 15, 2017",
  "customer_name": "Maryland Department of Human Resources",
  "contract_id": "8286491847090917",
  "contract_title": "VMware Enterprise Order",
  "fees": "1036050.18"
}, {
  "vmware_agreement": "00691030",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8286455984852120",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68"
}, {
  "vmware_agreement": "00694680",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8285897742440913",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68"
}, {
  "vmware_agreement": "00373583",
  "customer_name": "Anheuser Busch Companies, LLC",
  "contract_id": "8285043071658508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "1035431.58"
}, {
  "vmware_agreement": "00433733",
  "customer_name": "Quest Diagnostics Incorporated",
  "contract_id": "8285731440961545",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "1033528.38"
}, {
  "vmware_agreement": "00684060",
  "customer_name": "Berkley Technology Services, LLC",
  "contract_id": "8840113950674501",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "1025888.0"
}, {
  "vmware_agreement": "VMware Agreement # 00433869",
  "customer_name": "Integrated Health Information SystemsPte Ltd",
  "contract_id": "8286358106826339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1023385.65"
}, {
  "vmware_agreement": "00378479",
  "customer_name": "Australian Federal Police (AFP)",
  "contract_id": "8285058132092404",
  "contract_title": "Enterprise License Agreement",
  "fees": "1022136.83"
}, {
  "vmware_agreement": "00270917",
  "customer_name": "GlaxoSmithKline LLC",
  "contract_id": "8285405947474647",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00618642",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285616654132033",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "006458941",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285545972529533",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00685386",
  "customer_name": "GCI Communication Corp.",
  "contract_id": "8285483258581417",
  "contract_title": "VMware Enterprise Multi-Cloud Agreement",
  "fees": "1000000.0"
}, {
  "vmware_agreement": "00730925",
  "customer_name": "Shenzhen BYD Property Insurance Co., Ltd",
  "contract_id": "8286255964339453",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "999306.24"
}, {
  "vmware_agreement": "00187954esm.10_12_2015",
  "customer_name": "INTEGRIS HEALTH",
  "contract_id": "8284338493231046",
  "contract_title": "ELA Order Form",
  "fees": "998528.26"
}, {
  "vmware_agreement": "00679557",
  "customer_name": "University of Texas at Rio Grande Valley",
  "contract_id": "8284489519799401",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "998201.45"
}, {
  "vmware_agreement": "VMware Australia Pty Ltd",
  "customer_name": "Australian Taxation Office",
  "contract_id": "8284957215988454",
  "contract_title": "Licence and Support Contract - Commercial off-the-shelf Software",
  "fees": "995540.35"
}, {
  "vmware_agreement": "00397668",
  "customer_name": "CDK Global, LLC.",
  "contract_id": "8283608382747812",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "992128.93"
}, {
  "vmware_agreement": "VMware Agreement #00424268",
  "customer_name": "Commonwealth Superannuation Corporation",
  "contract_id": "8284996592989960",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "991290.81"
}, {
  "vmware_agreement": "00477513",
  "customer_name": "The Bank of New York Mellon Corporation",
  "contract_id": "8286620836952470",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "990660.83"
}, {
  "vmware_agreement": "00545541",
  "customer_name": "Ameren Services Company",
  "contract_id": "8284405218385187",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "987306.71"
}, {
  "vmware_agreement": "00692534",
  "customer_name": "Citadel Enterprise Americas LLC",
  "contract_id": "8285143999952225",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "985025.96"
}, {
  "vmware_agreement": "00684260",
  "customer_name": "Serasa Experian",
  "contract_id": "8286102601318504",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "981881.25"
}, {
  "vmware_agreement": "00551048",
  "customer_name": "Altice USA DBA CSC Holdings",
  "contract_id": "8285130379547661",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "981446.38"
}, {
  "vmware_agreement": "00645989",
  "customer_name": "Bridgestone Americas, Inc.",
  "contract_id": "8285071994215894",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "979927.0"
}, {
  "vmware_agreement": "00645989",
  "customer_name": "Bridgestone Americas, Inc.",
  "contract_id": "8285156974063458",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "979927.0"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "City Of Philadelphia",
  "contract_id": "8285493947983189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "978395.42"
}, {
  "vmware_agreement": "00546878",
  "customer_name": "KTB Computer Services Co Ltd (KTBCS)",
  "contract_id": "8286926713461284",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "977816.77"
}, {
  "vmware_agreement": "00424491",
  "customer_name": "DC Office of the Chief Technology Officer",
  "contract_id": "8286023683652015",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "977800.0"
}, {
  "vmware_agreement": "00219090",
  "customer_name": "Horizon Healthcare Services Inc.",
  "contract_id": "8284614296594492",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "977347.74"
}, {
  "vmware_agreement": "00218971",
  "customer_name": "Consol Energy, Inc.",
  "contract_id": "8285087272907814",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "975000.0"
}, {
  "vmware_agreement": "00357582",
  "customer_name": "Acadian Ambulance Service, Inc(main)",
  "contract_id": "8284380591489332",
  "contract_title": "ELA ORDER FORM",
  "fees": "973028.31"
}, {
  "vmware_agreement": "VMware Agreement # 00680815",
  "customer_name": "State Of Florida Division Of Emergency Management",
  "contract_id": "8285968973857156",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "970093.16"
}, {
  "vmware_agreement": "00708408",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8840424987203109",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "969389.42"
}, {
  "vmware_agreement": "00218386",
  "customer_name": "Royal Bank Of Canada",
  "contract_id": "8285128615354195",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "967117.05"
}, {
  "vmware_agreement": "00369737",
  "customer_name": "Paychex Inc.",
  "contract_id": "8284498762399482",
  "contract_title": "Amendment No 6 to ELA",
  "fees": "961693.39"
}, {
  "vmware_agreement": "00433332.0",
  "customer_name": "Edward D. Jones \u0026 Co., L.P.",
  "contract_id": "8284910455034956",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "961204.5"
}, {
  "vmware_agreement": "00433332.0",
  "customer_name": "Edward D. Jones \u0026 Co., L.P.",
  "contract_id": "8284455753602091",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "961204.5"
}, {
  "vmware_agreement": "00694271",
  "customer_name": "PT Bank Central Asia Tbk",
  "contract_id": "8286734701839835",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "960000.0"
}, {
  "vmware_agreement": "00682464",
  "customer_name": "Washoe County",
  "contract_id": "8284873406590302",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "957424.38"
}, {
  "vmware_agreement": "00265819",
  "customer_name": "Tourism and Culture Authority Abu Dhabi (TCA)",
  "contract_id": "8284936790322862",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "954000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00589097",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285650887924460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953166.17"
}, {
  "vmware_agreement": "VMware Agreement #00611338",
  "customer_name": "USCYBERCOM",
  "contract_id": "8286055871732818",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953156.25"
}, {
  "vmware_agreement": "006331211",
  "customer_name": "Santa Clara Valley Water District",
  "contract_id": "8285471782162899",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "951844.4"
}, {
  "vmware_agreement": "00359769",
  "customer_name": "MacAndrews and Forbes Group LLC",
  "contract_id": "8284805735331473",
  "contract_title": "AMENDMENT NO. 2 TO THE ELA ORDER FORM",
  "fees": "951399.8"
}, {
  "vmware_agreement": "VMware Agreement #00382159",
  "customer_name": "FUJITSU FSAS INC.",
  "contract_id": "8286619304818027",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "950600.0"
}, {
  "vmware_agreement": "00439851",
  "customer_name": "California Department Of General Services",
  "contract_id": "8285679568160518",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "947274.13"
}, {
  "vmware_agreement": "00543302",
  "customer_name": "Zhongshan jiaofa Investment Co., Ltd",
  "contract_id": "8285495095846616",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "945397.93"
}, {
  "vmware_agreement": "00437704",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8286853626306215",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "944569.35"
}, {
  "vmware_agreement": "00361285",
  "customer_name": "Paychex Inc.",
  "contract_id": "8285195137002040",
  "contract_title": "Amendment No 5 to ELA",
  "fees": "942971.37"
}, {
  "vmware_agreement": "00387295",
  "customer_name": "El Dorado County",
  "contract_id": "8840209161472789",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "938274.05"
}, {
  "vmware_agreement": "00372377",
  "customer_name": "Ochsner Clinic Foundation",
  "contract_id": "8285510616217811",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "934401.63"
}, {
  "vmware_agreement": "00611207",
  "customer_name": "Department of Justice - BOP",
  "contract_id": "8284555990257698",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "933003.35"
}, {
  "vmware_agreement": "VMware Agreement # 00499105",
  "customer_name": "BHARTI AIRTEL LIMITED",
  "contract_id": "8285483730777484",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930680.34"
}, {
  "vmware_agreement": "00433451",
  "customer_name": "STANDARD CHARTERED BANK",
  "contract_id": "8285641673352445",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930586.12"
}, {
  "vmware_agreement": "VMware Agreement # 00188548",
  "customer_name": "Florida State University-NWRDC",
  "contract_id": "8286054801267396",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "930284.98"
}, {
  "vmware_agreement": "00637017",
  "customer_name": "Akamai Technologies, Inc.",
  "contract_id": "8285371281681558",
  "contract_title": "Amendment No. 10 to the ELA Order Form",
  "fees": "928781.39"
}, {
  "vmware_agreement": "00433831",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8285205506815608",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "926423.13"
}, {
  "vmware_agreement": "VMware Agreement 00691574",
  "customer_name": "Mercadolibre S.r.l",
  "contract_id": "8285969492831737",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "924514.11"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Empresa Argentina de Soluciones Satelitales",
  "contract_id": "8286319977550086",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "921750.22"
}, {
  "vmware_agreement": "00187613.0",
  "customer_name": "Costco Wholesale Corporation (Main)",
  "contract_id": "8285419793825111",
  "contract_title": "2015-AMD-VMWAR-07038",
  "fees": "916233.34"
}, {
  "vmware_agreement": "VMware Agreement # 00561071",
  "customer_name": "DBS Bank Ltd",
  "contract_id": "8286588140278290",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "916000.0"
}, {
  "vmware_agreement": "00597756",
  "customer_name": "The Church of Jesus Christ of Latter-day Saints",
  "contract_id": "8284888546659905",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "915424.53"
}, {
  "vmware_agreement": "VMwareAuthorized Distributor Partner Program Agreement, June 15,2017",
  "customer_name": "SANEPAR - Companhia de Saneamentodo Parana",
  "contract_id": "8285571060838143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "911293.56"
}, {
  "vmware_agreement": "00557522",
  "customer_name": "Santander Consumer USA Inc.",
  "contract_id": "8285563560398431",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "911261.39"
}, {
  "vmware_agreement": "VMware Agreement # 00561494",
  "customer_name": "Renesas Electronics Corporation",
  "contract_id": "8286304348218331",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "910000.0"
}, {
  "vmware_agreement": "00558750",
  "customer_name": "AARP",
  "contract_id": "8284754456326386",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "908910.67"
}, {
  "vmware_agreement": "00558750",
  "customer_name": "AARP",
  "contract_id": "8285405932280956",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "908910.67"
}, {
  "vmware_agreement": "00681493",
  "customer_name": "Nordstrom, Inc.",
  "contract_id": "8285240685967508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "905622.05"
}, {
  "vmware_agreement": "VMware Agreement # 00543628",
  "customer_name": "Unimed Bauru Cooperativa de Trabalho Medico",
  "contract_id": "8285830258129908",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "900635.64"
}, {
  "vmware_agreement": "00222373",
  "customer_name": "Skadden, Arps, Slate, Meagher \u0026 Flom LLP",
  "contract_id": "8284996493987901",
  "contract_title": "Enterprise License Agreement",
  "fees": "900000.0"
}, {
  "vmware_agreement": "00444765",
  "customer_name": "Alticor Inc.",
  "contract_id": "8285017966257443",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "898173.87"
}, {
  "vmware_agreement": "00684943",
  "customer_name": "Prime Therapeutics LLC",
  "contract_id": "8284851556651503",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "897719.38"
}, {
  "vmware_agreement": "00606877",
  "customer_name": "USSF-GPS/OCX",
  "contract_id": "8285178862940502",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "897237.98"
}, {
  "vmware_agreement": "00445953",
  "customer_name": "Arthur J Gallagher \u0026 Co.",
  "contract_id": "8285241762335277",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "895310.35"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Independent System Operator Corporation",
  "contract_id": "8285110139849575",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "893739.74"
}, {
  "vmware_agreement": "00477226",
  "customer_name": "State of Tennessee (main)",
  "contract_id": "8285017250312992",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "893682.77"
}, {
  "vmware_agreement": "00689508",
  "customer_name": "Los Angeles County Metropolitan Transportation Authority",
  "contract_id": "9429038150277977",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891672.24"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8284910019159011",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8285259545800451",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62"
}, {
  "vmware_agreement": "00439083",
  "customer_name": "Texas Health Resources Inc",
  "contract_id": "8285586925634922",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "890437.77"
}, {
  "vmware_agreement": "00499043",
  "customer_name": "South Bihar Power Distribution Company Ltd",
  "contract_id": "8285707951297671",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "890000.0"
}, {
  "vmware_agreement": "00554217",
  "customer_name": "CareSource Management Services LLC",
  "contract_id": "8285018348687312",
  "contract_title": "VMware ELA",
  "fees": "888733.31"
}, {
  "vmware_agreement": "00569042",
  "customer_name": "Sentara Healthcare",
  "contract_id": "8285746581425207",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "887638.79"
}, {
  "vmware_agreement": "VMware Agreement # 00689882",
  "customer_name": "Grupo Ol - Ol Celular",
  "contract_id": "8286604312808814",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "885337.5"
}, {
  "vmware_agreement": "00675703",
  "customer_name": "Stony Brook University-Primary",
  "contract_id": "8840344592459529",
  "contract_title": "SINGLE ENTERPRISE ORDER RESALE AGREEMENT",
  "fees": "884520.0"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Sefaz-Rs - Secretaria DA Fazenda do Rio Grande do SUL",
  "contract_id": "8286634201895344",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "882878.9"
}, {
  "vmware_agreement": "VMware Agreement # 00397376",
  "customer_name": "Auburn University (main)",
  "contract_id": "8285484570284237",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "882527.32"
}, {
  "vmware_agreement": "VMware Agreement # 00673287",
  "customer_name": "Loma Linda University Medical Center (MainAccount)",
  "contract_id": "8285909864160415",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "881578.64"
}, {
  "vmware_agreement": "VMware Agreement # 00379172",
  "customer_name": "Nacional Monte de Piedad I.A.P.",
  "contract_id": "8285814720141152",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "879141.49"
}, {
  "vmware_agreement": "00360730",
  "customer_name": "El Camino Hospital",
  "contract_id": "8284296504605918",
  "contract_title": "ELA Order Form",
  "fees": "878384.7"
}, {
  "vmware_agreement": "Software Licensing Agreement",
  "customer_name": "Ministerio de Hacienda",
  "contract_id": "8286131712713721",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "877314.46"
}, {
  "vmware_agreement": "00684127",
  "customer_name": "Bangladesh Bank",
  "contract_id": "8285830164844758",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "875163.61"
}, {
  "vmware_agreement": "VMware Agreement # 00574497",
  "customer_name": "CLP Great Wall Internet System Application Sichuan Co., Ltd",
  "contract_id": "8285289704395809",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "874115.38"
}, {
  "vmware_agreement": "00218237",
  "customer_name": "USAble Mutual Insurance Company d/b/a Arkansas Blue Cross \u0026 Blue Shield",
  "contract_id": "8284649465371257",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "872207.0"
}, {
  "vmware_agreement": "00477468",
  "customer_name": "Beijing Yundu Internet Technology Co.,Ltd北京云度互联科技有限公司",
  "contract_id": "8286255419230172",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "871510.06"
}, {
  "vmware_agreement": "00188522",
  "customer_name": "Province of Ontario, by the Treasury Board Secretariat",
  "contract_id": "8284842774910695",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "868481.57"
}, {
  "vmware_agreement": "00373440",
  "customer_name": "Childrens Hospital of New Orleans",
  "contract_id": "8840404698773341",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "865798.91"
}, {
  "vmware_agreement": "VMware Agreement # 00426654",
  "customer_name": "Trend Micro, Inc.趨勢科技股份有限公司",
  "contract_id": "8285405737364302",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "865456.75"
}, {
  "vmware_agreement": "00501947",
  "customer_name": "Discovery Communications, LLC",
  "contract_id": "8285379325779424",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "864854.52"
}, {
  "vmware_agreement": "00540840",
  "customer_name": "Sentara Healthcare",
  "contract_id": "8284888959427926",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "862977.29"
}, {
  "vmware_agreement": "VMware Agreement # 00589264",
  "customer_name": "Unified National Networks Sdn Bhd",
  "contract_id": "8286102958903408",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "862278.51"
}, {
  "vmware_agreement": "00545255",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285763175702182",
  "contract_title": "Amendment No. 11 to the ELA Order Form",
  "fees": "861754.6"
}, {
  "vmware_agreement": "00638094",
  "customer_name": "KANEMATSU ELECTRONICS CO., LTD",
  "contract_id": "8285087161652274",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "860000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00607568",
  "customer_name": "Singapore Police Force",
  "contract_id": "8286122087436315",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "858106.06"
}, {
  "vmware_agreement": "00357872",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8286940541683850",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "857767.6"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 5, 2019",
  "customer_name": "SAT - Servicio de Administracion Tributaria",
  "contract_id": "8286605659278424",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "853703.79"
}, {
  "vmware_agreement": "00374867",
  "customer_name": "Texas Health Resources",
  "contract_id": "8285059370336512",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "851199.39"
}, {
  "vmware_agreement": "00545445",
  "customer_name": "Chunghwa Telecom Co., Ltd中華電信",
  "contract_id": "8286749058000914",
  "contract_title": "中華電信(中華系整)VMware專案ELA-1101163_Contract for e-signature",
  "fees": "850685.93"
}, {
  "vmware_agreement": "VMware Agreement # 00682639",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8285279090245153",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "VMware Agreement # 00680353",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8284949661700534",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "00680104",
  "customer_name": "Indialdeas.com Limited",
  "contract_id": "8285418662278399",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849956.83"
}, {
  "vmware_agreement": "00430300",
  "customer_name": "Citibank, N.A.",
  "contract_id": "8285144215457287",
  "contract_title": "VMware Smart Assurance Core Platform - 100 Device pack 400 Pack(s) (100 Pack)",
  "fees": "849420.0"
}, {
  "vmware_agreement": "00495232",
  "customer_name": "Wipro Limited",
  "contract_id": "8286101611895458",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849254.79"
}, {
  "vmware_agreement": "00692025",
  "customer_name": "L.A. Care",
  "contract_id": "8840242481042475",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "846230.0"
}, {
  "vmware_agreement": "00393865",
  "customer_name": "Alight Solutions LLC",
  "contract_id": "8284909689803366",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "846162.12"
}, {
  "vmware_agreement": "00390513",
  "customer_name": "Cognizant Technology Solutions U.S. Corporation",
  "contract_id": "8840211267292040",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "846117.53"
}, {
  "vmware_agreement": "VMware Agreement # 00430424",
  "customer_name": "Cielo",
  "contract_id": "8284900833359835",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "845522.46"
}, {
  "vmware_agreement": "00672851",
  "customer_name": "Fresenius Medical Care North America Inc:Lexington, MA",
  "contract_id": "8284983521164726",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "844878.12"
}, {
  "vmware_agreement": "VMware Agreement # 00489635",
  "customer_name": "CFE - Comision Federal de Electricidad",
  "contract_id": "8285745019536613",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "844151.05"
}, {
  "vmware_agreement": "00601007",
  "customer_name": "Leidos, Inc.",
  "contract_id": "8285259035656529",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "841511.93"
}, {
  "vmware_agreement": "00484165",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8284795664080567",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "841269.92"
}, {
  "vmware_agreement": "00694458",
  "customer_name": "Massachusetts Institute of Technology",
  "contract_id": "8285178456339141",
  "contract_title": "Amendment No. 6 to the Order Form",
  "fees": "839100.0"
}, {
  "vmware_agreement": "VMware Agreement, September 1, 2010",
  "customer_name": "Grupo Financiero Inbursa, S.A.B. de C.V.",
  "contract_id": "8286160113993982",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "835663.85"
}, {
  "vmware_agreement": "00636129",
  "customer_name": "Covered California",
  "contract_id": "8840546297216226",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "834753.54"
}, {
  "vmware_agreement": "00431292",
  "customer_name": "Potlatch Deltic Corporation",
  "contract_id": "8285522458592487",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "832865.0"
}, {
  "vmware_agreement": "00484166",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285547235359206",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "831497.72"
}, {
  "vmware_agreement": "00680664",
  "customer_name": "Permodalan Nasional Madani",
  "contract_id": "8285731677856138",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "831245.66"
}, {
  "vmware_agreement": "00499527",
  "customer_name": "Coppel SA de CV",
  "contract_id": "8286635014987642",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "828066.32"
}, {
  "vmware_agreement": "00503248",
  "customer_name": "Telenor Procurement Company Pte. Ltd",
  "contract_id": "8840375140702219",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "827000.77"
}, {
  "vmware_agreement": "00369253",
  "customer_name": "Dell Australia PTY LTD",
  "contract_id": "8285097930836636",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "826615.64"
}, {
  "vmware_agreement": "00220525",
  "customer_name": "Paradise Valley Unified School District",
  "contract_id": "8286172634082019",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "826563.25"
}, {
  "vmware_agreement": "00489120",
  "customer_name": "City Of Little Rock",
  "contract_id": "8285044556856650",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "824964.0"
}, {
  "vmware_agreement": "00392324",
  "customer_name": "Open Text Inc.",
  "contract_id": "8284909805896525",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "822384.63"
}, {
  "vmware_agreement": "00497604",
  "customer_name": "Select Medical Corporation",
  "contract_id": "8285625596075149",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "821978.37"
}, {
  "vmware_agreement": "002178831",
  "customer_name": "HGST, INC.",
  "contract_id": "8285178701964548",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "820146.98"
}, {
  "vmware_agreement": "00637658",
  "customer_name": "Bank Of Montreal*",
  "contract_id": "8285169123807414",
  "contract_title": "ELA ORDER FORM",
  "fees": "819521.953"
}, {
  "vmware_agreement": "00695857",
  "customer_name": "Servicenow Inc",
  "contract_id": "8288388589010790",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "819365.25"
}, {
  "vmware_agreement": "00188598",
  "customer_name": "Catholic Healthcare Partners",
  "contract_id": "8285241442459149",
  "contract_title": "Amendment No. 13 to the ELA Order Form",
  "fees": "818924.81"
}, {
  "vmware_agreement": "00189150",
  "customer_name": "Huron Legal, a Consilio Company",
  "contract_id": "8285563553845184",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "818616.9"
}, {
  "vmware_agreement": "00606889",
  "customer_name": "USSF - NISSC",
  "contract_id": "8285378715426410",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "816810.71"
}, {
  "vmware_agreement": "00376026",
  "customer_name": "Berkshire Hathaway Energy",
  "contract_id": "8284323347972498",
  "contract_title": "VMWARE INC - AMENDMENT 11 TO ELA ORDER",
  "fees": "816805.12"
}, {
  "vmware_agreement": "00626259",
  "customer_name": "National Grid UK LTD \u0026 National Grid USA Service Company Inc",
  "contract_id": "8284983091003888",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "816540.04"
}, {
  "vmware_agreement": "00684711",
  "customer_name": "Central SEQ Distributor-Retailer Authority",
  "contract_id": "8284982861775143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "815633.05"
}, {
  "vmware_agreement": "00433637",
  "customer_name": "City of Seattle",
  "contract_id": "8285951620729795",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "814638.96"
}, {
  "vmware_agreement": "VMware Agreement #00478908",
  "customer_name": "DBS BANK LTD.",
  "contract_id": "8285951233762589",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "811910.1"
}, {
  "vmware_agreement": "00430335",
  "customer_name": "Ellie Mae, Inc.",
  "contract_id": "8285044393611542",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "811468.31"
}, {
  "vmware_agreement": "00597765",
  "customer_name": "Avient Corporation",
  "contract_id": "8285418967208788",
  "contract_title": "Q-E00237584-00597765-Avient Corporation_ELA-2021-12-29",
  "fees": "810100.0"
}, {
  "vmware_agreement": "VMware Agreement # 00359040",
  "customer_name": "Prince William County Service",
  "contract_id": "8284865859836266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "808968.4"
}, {
  "vmware_agreement": "VMware Agreement #00691573.0",
  "customer_name": "Meli Log Srl",
  "contract_id": "8285168582955198",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "807814.56"
}, {
  "vmware_agreement": "00677320",
  "customer_name": "Tri Met",
  "contract_id": "8285563370470420",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "805522.51"
}, {
  "vmware_agreement": "00497662",
  "customer_name": "PT Pertamina (Persero)",
  "contract_id": "8284714719938867",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "804500.69"
}, {
  "vmware_agreement": "00373512",
  "customer_name": "Shang Software Solutions",
  "contract_id": "8285290035928622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "801387.65"
}, {
  "vmware_agreement": "00492933",
  "customer_name": "FUJI SOFT INCORPORATED",
  "contract_id": "8285745575620512",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "800000.0"
}, {
  "vmware_agreement": "00541645",
  "customer_name": "FUJISOFT INCORPORATED",
  "contract_id": "8285939813204264",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "800000.0"
}, {
  "vmware_agreement": "00565334",
  "customer_name": "HEWLETT-PACKARD JAPAN, LTD.",
  "contract_id": "8285270469228428",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "800000.0"
}, {
  "vmware_agreement": "VMware Agreement # 00679393",
  "customer_name": "Pusat Sistem Informasi Dan Teknologi Keuangan(Pusintek)",
  "contract_id": "8286529030342119",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "797780.54"
}, {
  "vmware_agreement": "00542933",
  "customer_name": "Athenahealth, Inc.",
  "contract_id": "8285043533706973",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "796803.84"
}, {
  "vmware_agreement": "VMware Agreement # 00356537",
  "customer_name": "Coast Community College District",
  "contract_id": "8286941007060489",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "795441.81"
}, {
  "vmware_agreement": "VMware Agreement # 00431525",
  "customer_name": "Banco CSF S/A",
  "contract_id": "8285746122744463",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "794245.66"
}, {
  "vmware_agreement": "006723921",
  "customer_name": "Arizona Department of Economic Security",
  "contract_id": "8286359036249850",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "794229.84"
}, {
  "vmware_agreement": "00360949",
  "customer_name": "eBay Korea Co., Ltd",
  "contract_id": "8285604851268965",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "794107.58"
}, {
  "vmware_agreement": "00216394",
  "customer_name": "Southwest Airlines Co",
  "contract_id": "8285071639933322",
  "contract_title": "ELA ORDER FORM",
  "fees": "793615.5"
}, {
  "vmware_agreement": "00188451",
  "customer_name": "Select Medical Corporation",
  "contract_id": "8285678371907433",
  "contract_title": "Enterprise License Agreement",
  "fees": "793325.0"
}, {
  "vmware_agreement": "00689452",
  "customer_name": "Idaho State Dept Of The Cio",
  "contract_id": "8286283183211529",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "793161.53"
}, {
  "vmware_agreement": "00355361",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8285331317113405",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "791360.82"
}, {
  "vmware_agreement": "00216185",
  "customer_name": "salesforce.com, Inc.",
  "contract_id": "8284899139916610",
  "contract_title": "Amendment No. 7 to the ELA Order",
  "fees": "789857.1"
}];
export const DummyData3: IDataSourceObj[] = [{
  "vmware_agreement": "00540850",
  "customer_name": "Coppel SA de CV",
  "contract_id": "8840452035819166",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "317530.26",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00694816",
  "customer_name": "SONY GLOBAL SOLUTIONS INC.",
  "contract_id": "8840414587713057",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28391900.0",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "VMware Agreement # 00373355",
  "customer_name": "Sapporo Information Network Co Ltd",
  "contract_id": "8285968961620558",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38199609.0",
  "expiration_date": "2024-03-22"
}, {
  "vmware_agreement": "VMware Agreement # 00492417",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285721267842887",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4000000.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00387319",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8286173708438988",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103745123.0",
  "expiration_date": "2024-02-29"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "KYUSHU ELECTRIC POWER CO., INC.",
  "contract_id": "8285968452928590",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47000005.0",
  "expiration_date": "2024-02-20"
}, {
  "vmware_agreement": "VMware Agreement # 00374639",
  "customer_name": "SUNDRUG CO., LTD.",
  "contract_id": "8286007604664897",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "53192128.0",
  "expiration_date": "2024-03-20"
}, {
  "vmware_agreement": "00680594",
  "customer_name": "Urban One Inc",
  "contract_id": "8286821546019953",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132600.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 004950771",
  "customer_name": "Bangladesh Bank",
  "contract_id": "8286008124780266",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68504.11",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00675213",
  "customer_name": "PHILIPPINE NATIONAL BANK",
  "contract_id": "8285951374812712",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "475333.06",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00673154",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8285471605277851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00367352",
  "customer_name": "Simplex Inc",
  "contract_id": "8285791209145066",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "70839563.0",
  "expiration_date": "2024-01-23"
}, {
  "vmware_agreement": "VMware Agreement # 00360759",
  "customer_name": "SUMITOMO MITSUI TRUST BANK LTD",
  "contract_id": "8285495346695793",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134442797.0",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00673507",
  "customer_name": "KOCHI CITY",
  "contract_id": "8286618969848244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24395500.0",
  "expiration_date": "2023-09-27"
}, {
  "vmware_agreement": "00646071",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8285707469877678",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34991484.0",
  "expiration_date": "2023-10-14"
}, {
  "vmware_agreement": "00693028",
  "customer_name": "JB ADVANCED TECHNOLOGY CORPORATION",
  "contract_id": "8285097586178847",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54207000.0",
  "expiration_date": "2024-01-25"
}, {
  "vmware_agreement": "VMware Agreement # 00367214",
  "customer_name": "ISID-AO, Ltd.",
  "contract_id": "8285813625955633",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98500000.0",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00543300",
  "customer_name": "JAPAN POST Co., Ltd.",
  "contract_id": "8286395981234161",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78060831.0",
  "expiration_date": "2024-03-17"
}, {
  "vmware_agreement": "00492054",
  "customer_name": "NATIONAL POLICE AGENCY",
  "contract_id": "8285790954730763",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56264689.0",
  "expiration_date": "2024-02-29"
}, {
  "vmware_agreement": "VMware Agreement # 00693063",
  "customer_name": "FUJITSU LIMITED",
  "contract_id": "8286147993302855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191178000.0",
  "expiration_date": "2024-01-24"
}, {
  "vmware_agreement": "VMware Agreement # 00373743",
  "customer_name": "K-Opticom Corporation",
  "contract_id": "8285390677469596",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "92778400.0",
  "expiration_date": "2024-03-12"
}, {
  "vmware_agreement": "00626767",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8286303527712444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "573299329.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00683650",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8286414546623922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00433833",
  "customer_name": "JAL Information Technology Co., Ltd.",
  "contract_id": "8286121878134771",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132939760.0",
  "expiration_date": "2024-01-24"
}, {
  "vmware_agreement": "VMware Agreement # 00356450",
  "customer_name": "Mizuho Bank, Ltd.",
  "contract_id": "8285617726173382",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "48807985.0",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00434344",
  "customer_name": "Halton Healthcare",
  "contract_id": "8286822815198130",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "133304.97",
  "expiration_date": "2024-03-26"
}, {
  "vmware_agreement": "00532016",
  "customer_name": "TATA COMMUNICATIONS LIMITED",
  "contract_id": "8286909010454321",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "20076.84",
  "expiration_date": "2024-01-29"
}, {
  "vmware_agreement": "VMware Agreement # 00679251",
  "customer_name": "GREAT EASTERN LIFE ASSURANCE(MALAYSIA) BERHAD",
  "contract_id": "8286505461715251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "143600.84",
  "expiration_date": "2023-11-27"
}, {
  "vmware_agreement": "VMware Agreement # 00498116",
  "customer_name": "NCSOFT",
  "contract_id": "8286283254142110",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "402670.93",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00499038",
  "customer_name": "Woowahan Brothers",
  "contract_id": "8285679079098856",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "148800.89",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00497967",
  "customer_name": "Eulji Medical Center",
  "contract_id": "8285814186469331",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "326164.11",
  "expiration_date": "2024-02-29"
}, {
  "vmware_agreement": "VMware Agreement # 004995371",
  "customer_name": "OSDE - Organizacion de Servicios DirectosEmpresarios",
  "contract_id": "8286767784460668",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1553238.15",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement #00373202",
  "customer_name": "O-Bank Co., Ltd王道商業銀行股份有限公司",
  "contract_id": "8285730238878307",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "538996.78",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00357034",
  "customer_name": "KGI Bank凱基商業銀行股份有限公司",
  "contract_id": "8286303756093897",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300000.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00357032",
  "customer_name": "Ctbc Financial Holding Co. Ltd中國信託金融控股股份有限公司",
  "contract_id": "8285762894558131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "599433.52",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00691899",
  "customer_name": "Nan Shan Life Insurance Co. Ltd",
  "contract_id": "8286854671078167",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "147725.0",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00673054",
  "customer_name": "Ek-Chai Distribution System Co. Ltd.",
  "contract_id": "8286147370617986",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "100237.03",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00689966",
  "customer_name": "Dtac Trinet Company Ltd",
  "contract_id": "8286102603852665",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "153800.0",
  "expiration_date": "2024-01-25"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, April 25, 2018",
  "customer_name": "Cgs-Cimb Securities (Singapore) Pte Ltd",
  "contract_id": "8285731184258202",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "71981.0",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00690549",
  "customer_name": "Trend Micro, Inc. (GEntitlement Account EA#:522109020",
  "contract_id": "8285857682302546",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "288135.0",
  "expiration_date": "2024-02-03"
}, {
  "vmware_agreement": "VMware Agreement # 00690869",
  "customer_name": "M/S. B. G. Chitale Dairy",
  "contract_id": "8286055563562436",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30772.06",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "VMware Agreement # 00679911",
  "customer_name": "UltraTech Cement Limited",
  "contract_id": "8285951957363267",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00684075",
  "customer_name": "Bank Tabungan Pensiunan Nasional",
  "contract_id": "8285707124183310",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "212222.75",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00694271",
  "customer_name": "PT Bank Central Asia Tbk",
  "contract_id": "8286734701839835",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "960000.0",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00690345",
  "customer_name": "PT. Telekomunikasi Indonesia, Tbk",
  "contract_id": "8285625555736051",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "494453.1",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00486024",
  "customer_name": "PT Pegadaian (Persero)",
  "contract_id": "8288190277606702",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "284419.56",
  "expiration_date": "2024-02-29"
}, {
  "vmware_agreement": "00684074",
  "customer_name": "PT Bank Mandiri Persero TBK",
  "contract_id": "8285071483448430",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "343944.64",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "VMware Agreement # 00498609",
  "customer_name": "Bank Central Asia TBK",
  "contract_id": "8286538286042067",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "203340.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00478749",
  "customer_name": "PT. Bank Rakyat Indonesia (Persero)Tbk",
  "contract_id": "8286055930478576",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300533.44",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00358915",
  "customer_name": "Osaka Municipal Waterworks Bureau",
  "contract_id": "8286023128056862",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "109007017.0",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "VMware Agreement # 00357295",
  "customer_name": "Oriental Land Co.,Ltd.",
  "contract_id": "8285536789337141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14040810.0",
  "expiration_date": "2023-12-06"
}, {
  "vmware_agreement": "VMware Agreement # 00357046",
  "customer_name": "BRIDGESTONE CORPORATION",
  "contract_id": "8286022941891362",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67393128.0",
  "expiration_date": "2023-12-07"
}, {
  "vmware_agreement": "VMware Agreement # 00369261",
  "customer_name": "Japan Racing Association",
  "contract_id": "8285484944557803",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "314529072.0",
  "expiration_date": "2024-02-14"
}, {
  "vmware_agreement": "VMware Agreement # 00361309",
  "customer_name": "SUZUKI MOTOR CORPORATION",
  "contract_id": "8286235131915694",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "413247716.0",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00681381",
  "customer_name": "Idfc First Bank Ltd",
  "contract_id": "8286132810852503",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00690817",
  "customer_name": "Evalueserve",
  "contract_id": "8285881506770587",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00656736",
  "customer_name": "Bank of Ceylon",
  "contract_id": "8285880471516979",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "416539.09",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "VMware PartnerConnect Agreement, January 25, 2022",
  "customer_name": "Route Mobile Ltd",
  "contract_id": "8285679691910137",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-10-04"
}, {
  "vmware_agreement": "VMware Agreement # 00681165",
  "customer_name": "The Hong Kong Jockey Club",
  "contract_id": "8286269612737467",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "625662.93",
  "expiration_date": "2023-11-02"
}, {
  "vmware_agreement": "00545615",
  "customer_name": "HCL Technologies Limited",
  "contract_id": "8285406883468680",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "278898.05",
  "expiration_date": "2024-03-26"
}, {
  "vmware_agreement": "00360315",
  "customer_name": "STN - SECRETARIA DO TESOURONACIONAL",
  "contract_id": "8285430487154231",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "267849.45",
  "expiration_date": "2024-01-09"
}, {
  "vmware_agreement": "VMware Agreement # 00680048",
  "customer_name": "Imesp - Imprensa Oficial do Estado de Sao Paulo",
  "contract_id": "8286282354900479",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "298364.25",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00373203",
  "customer_name": "Thai Life Insurance Public Company Limited",
  "contract_id": "8286174255006281",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "255786.91",
  "expiration_date": "2024-02-28"
}, {
  "vmware_agreement": "00675687",
  "customer_name": "Verse Innovation Pvt. Ltd",
  "contract_id": "8286191716384056",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103760.33",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "00541802",
  "customer_name": "CENTRAL BANK OF INDIA",
  "contract_id": "8285842403597778",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "188941.99",
  "expiration_date": "2024-03-10"
}, {
  "vmware_agreement": "VMware Agreement # 00684071",
  "customer_name": "The South Indian Bank Ltd",
  "contract_id": "8840505259186565",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "105011.57",
  "expiration_date": "2023-11-25"
}, {
  "vmware_agreement": "VMware Agreement # 00690913",
  "customer_name": "Genpact India",
  "contract_id": "8286805178902164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "138644.75",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00690473",
  "customer_name": "Wipro GE Healthcare Pvt. Ltd",
  "contract_id": "8286587849591333",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "193515.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00680120",
  "customer_name": "Airtel Payment Bank Ltd",
  "contract_id": "8286734411010986",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "279577.75",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00691093",
  "customer_name": "Wipro GE Healthcare Pvt. Ltd",
  "contract_id": "8285842073868663",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "193515.0",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00607409",
  "customer_name": "Compania Dominicana De Telefonos",
  "contract_id": "8286333582202294",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "68598.41",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00691215",
  "customer_name": "Compania Dominicana De Telefonos",
  "contract_id": "8286085788177258",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "110968.43",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00431453",
  "customer_name": "Centre For Strategic Infocomm Technologies (CSIT)",
  "contract_id": "8286055226253507",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00494018",
  "customer_name": "SOFTBANK CORP.",
  "contract_id": "8840404707336179",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "60946781.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00381082",
  "customer_name": "TATA COMMUNICATIONS LIMITED",
  "contract_id": "8286347568597371",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "53389.58",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00551145",
  "customer_name": "Ncsoft Corporation",
  "contract_id": "8286505206396976",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "150009.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00362108",
  "customer_name": "eHealth Ontario",
  "contract_id": "8286270159906210",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00688538",
  "customer_name": "LCBO",
  "contract_id": "8284794075167439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00497141",
  "customer_name": "Taishin International Bank Co., Ltd.",
  "contract_id": "8285830255475391",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "657540.27",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00673032",
  "customer_name": "PT. Bank Rakyat Indonesia (Persero) Tbk",
  "contract_id": "8286768710628328",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "125290.8",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00476475",
  "customer_name": "Small and Medium Enterprise Development Bank of Thailand (SME Bank)",
  "contract_id": "8285720485391210",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "105446.25",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00327236",
  "customer_name": "PT. Adira Dinamika Multi Finance Tbk.",
  "contract_id": "8285663742115794",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "10080.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00393903",
  "customer_name": "Oriental Land Co., Ltd.",
  "contract_id": "8286570958796625",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "12863532.0",
  "expiration_date": "2023-12-06"
}, {
  "vmware_agreement": "VMware Agreement No. 00581748",
  "customer_name": "Mitsubishi UFJ Financial Group Inc",
  "contract_id": "8287425450848955",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "34913667.0",
  "expiration_date": "2023-09-27"
}, {
  "vmware_agreement": "00588601",
  "customer_name": "NTT Malaysia Solutions Sdn Bhd",
  "contract_id": "8286976243722522",
  "contract_title": "Amendment No. 4 to Single ELA Resale Agreement",
  "fees": "277271.7",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00539136",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8284950275082125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2483602.86",
  "expiration_date": "2024-03-09"
}, {
  "vmware_agreement": "VMware Distributor Agreement (United States)",
  "customer_name": "Sonora Quest Laboratories",
  "contract_id": "8285357992731496",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "37499.41",
  "expiration_date": "2024-03-01"
}, {
  "vmware_agreement": "VMware Agreement # 00598778",
  "customer_name": "T. Rowe Price Associates Inc",
  "contract_id": "8285086259238353",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "762636.74",
  "expiration_date": "2024-02-03"
}, {
  "vmware_agreement": "00607085",
  "customer_name": "United Nations Federal Credit Union",
  "contract_id": "8285523453617293",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "268186.48",
  "expiration_date": "2024-01-25"
}, {
  "vmware_agreement": "00684817",
  "customer_name": "City Of Arlington Texas",
  "contract_id": "8286007554198171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "84313.45",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "VMware Agreement # 00681340",
  "customer_name": "Medical Faculty Associates Inc",
  "contract_id": "8285640836700562",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "563448.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00683729",
  "customer_name": "University Of North Texas",
  "contract_id": "8285390609308263",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "150780.31",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "00683744",
  "customer_name": "L.A. Care",
  "contract_id": "8285545602234557",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "574338.44",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Employment Development",
  "contract_id": "8285178395714221",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4046449.13",
  "expiration_date": "2023-11-04"
}, {
  "vmware_agreement": "00681236",
  "customer_name": "City of Cedar Park",
  "contract_id": "8285120974987552",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88712.76",
  "expiration_date": "2023-11-08"
}, {
  "vmware_agreement": "00680936",
  "customer_name": "Dallas County (TX)",
  "contract_id": "8286427289236196",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "133621.4",
  "expiration_date": "2023-12-05"
}, {
  "vmware_agreement": "00674769",
  "customer_name": "Vanderbilt University (main)",
  "contract_id": "8285484004952761",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "350936.86",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00684064",
  "customer_name": "Washoe County",
  "contract_id": "8285763453617442",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1279904.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "VMware Agreement # 00677153",
  "customer_name": "City of Austin-CTECC",
  "contract_id": "8285572129448885",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "192417.76",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "00674208",
  "customer_name": "LA County Public Health",
  "contract_id": "8285291406641524",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "347349.21",
  "expiration_date": "2023-12-08"
}, {
  "vmware_agreement": "00674220",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8286378022666939",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1209498.0",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00688517",
  "customer_name": "Stromnetz Hamburg GmbH",
  "contract_id": "8285572281094206",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "331691.16",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "VMware Agreement # 00688541",
  "customer_name": "Light Servicos de Eletrecidade S/A",
  "contract_id": "8285456942350155",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "81194.82",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "VMware Agreement # 00394153",
  "customer_name": "Uniminuto",
  "contract_id": "8286022788008526",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "268186.37",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00674881",
  "customer_name": "Singapore Telecommunications Ltd",
  "contract_id": "8285546686698769",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "473210.8",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00671905",
  "customer_name": "City of Wichita",
  "contract_id": "8284141958337637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31260.34",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00482590",
  "customer_name": "Maybank Singapore Limited",
  "contract_id": "8286022502553654",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "360186.82",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00684685 and 00681442",
  "customer_name": "Serasa Experian",
  "contract_id": "8286282206426088",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "380000.65",
  "expiration_date": "2023-12-02"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Banco Industrial, S.A.",
  "contract_id": "8285471850006213",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "387423.24",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "BAC Costa Rica - Banco de America Central",
  "contract_id": "8285156862232827",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "113405.51",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "VMware Agreement # 00685473",
  "customer_name": "Brunei Shell Petroleum Co Sdn Bhd",
  "contract_id": "8284996723338617",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "220095.81",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00430089",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8286717389071539",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14466425.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00370542",
  "customer_name": "Hyundai Card Co., Ltd",
  "contract_id": "8840359581289484",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "209334.25",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "VMware Agreement # 00690800",
  "customer_name": "E-Infochips Ltd.",
  "contract_id": "8285746269294552",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "20093.33",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "VMware Agreement # 00691585",
  "customer_name": "Birla Management Centre Services Ltd",
  "contract_id": "8287391072436973",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65075.11",
  "expiration_date": "2024-01-04"
}, {
  "vmware_agreement": "00692268",
  "customer_name": "Secure Meters Ltd",
  "contract_id": "8286086218014968",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14000.0",
  "expiration_date": "2024-01-12"
}, {
  "vmware_agreement": "VMware Agreement # 00690107",
  "customer_name": "Hubergroup India Private Limited",
  "contract_id": "8285604214317114",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19990.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00690812",
  "customer_name": "BirlaSoft Limited",
  "contract_id": "9428987357992964",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132942.51",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "VMware Agreement # 00688203",
  "customer_name": "Tata Consultancy Services Ltd",
  "contract_id": "8285650454199260",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "139405.24",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "VMware Agreement # 00677442",
  "customer_name": "Vellore Institute of Technology",
  "contract_id": "8285778835409104",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "108071.11",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00690887",
  "customer_name": "Schlumberger India Technology Center Pvt., LTD.",
  "contract_id": "8285097841873684",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "35909.2",
  "expiration_date": "2024-01-03"
}, {
  "vmware_agreement": "VMware Agreement # 00691599",
  "customer_name": "The Farrer Park Company",
  "contract_id": "8285926547497962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47800.55",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00369550",
  "customer_name": "WEBHELP",
  "contract_id": "8284950076645328",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "20537.56",
  "expiration_date": "2024-02-22"
}, {
  "vmware_agreement": "VMware Agreement #0069051.0",
  "customer_name": "Grupo Nacional Provincial, S.A.",
  "contract_id": "8285483538102189",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "387545.62",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "VMware Agreement # 00542666",
  "customer_name": "Sumitomo Mitsui Banking Corporation",
  "contract_id": "8285603690701371",
  "contract_title": "VMware ELA",
  "fees": "258000.0",
  "expiration_date": "2024-03-19"
}, {
  "vmware_agreement": "VMware Agreement # 00498584",
  "customer_name": "CENTRAL PROVIDENT FUND BOARD",
  "contract_id": "8840436381596922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "543807.45",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00501320",
  "customer_name": "T. Rowe Price Associates Inc",
  "contract_id": "8285586216398804",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "69116.17",
  "expiration_date": "2023-12-03"
}, {
  "vmware_agreement": "00679751",
  "customer_name": "Tata Consultancy Services",
  "contract_id": "8285523026198922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-10-20"
}, {
  "vmware_agreement": "00547096",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8285969683452015",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement (OEM)",
  "fees": "17717778.0",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00494245",
  "customer_name": "Texas Association Of Counties",
  "contract_id": "8285650804066784",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "289627.65",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00675089",
  "customer_name": "Virginia Information Technology Agency",
  "contract_id": "8285270759294244",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "268568.34",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00689817",
  "customer_name": "Institut Gustave Roussy",
  "contract_id": "8285373339581406",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "122693.42",
  "expiration_date": "2024-01-01"
}, {
  "vmware_agreement": "00681888",
  "customer_name": "Chester County",
  "contract_id": "8285471061038828",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "215620.84",
  "expiration_date": "2024-01-18"
}, {
  "vmware_agreement": "VMware Agreement # 00688925.0",
  "customer_name": "Inner Mongolia Yili Industrial Group Ltd By Share Ltd Neimenggu 010080, China",
  "contract_id": "8285868687780008",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3452220.8",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00361046",
  "customer_name": "KDDI CORPORATION",
  "contract_id": "8285940011840868",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "104914960.0",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "VMware Agreement # 00358858",
  "customer_name": "Jacksonville Electric Authority",
  "contract_id": "8285373268803765",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "637415.12",
  "expiration_date": "2024-02-06"
}, {
  "vmware_agreement": "VMware Agreement # 00359192",
  "customer_name": "Kirtland AFB (AFMC)",
  "contract_id": "8285562745595158",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1213843.05",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "00359224",
  "customer_name": "Atento Mexicana",
  "contract_id": "8285493985893280",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "206547.56",
  "expiration_date": "2023-12-17"
}, {
  "vmware_agreement": "00368980",
  "customer_name": "Banco Compartamos - Institucion de Banca Multiple",
  "contract_id": "8840404189715359",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00373879",
  "customer_name": "US Army",
  "contract_id": "8286303939954534",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "34503645.64",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00382215",
  "customer_name": "Grupo Ol - Ol Movel",
  "contract_id": "8285992007136733",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "329608.84",
  "expiration_date": "2023-12-11"
}, {
  "vmware_agreement": "00424379",
  "customer_name": "Los Rios Community College District",
  "contract_id": "8285992385634185",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "237952.36",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "VMware Agreement # 00424078",
  "customer_name": "Suprema Corte de Justicia de la Nacion",
  "contract_id": "8285868095218540",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "501869.97",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "VMware Agreement # 00433499",
  "customer_name": "Asesoras Larrain Vial Ltda",
  "contract_id": "8285345294009598",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "427517.36",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00433220",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8285485038954937",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "22774176.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00434782",
  "customer_name": "VODAFONE IDEA LTD",
  "contract_id": "8284889557640177",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "392338.76",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00430646",
  "customer_name": "A T \u0026 T Comunicaciones Digitales",
  "contract_id": "8285909028727774",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "114360.0",
  "expiration_date": "2023-10-15"
}, {
  "vmware_agreement": "00424046",
  "customer_name": "WOM",
  "contract_id": "8284675173571649",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "280999.79",
  "expiration_date": "2024-01-22"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Uninove - Universidade Nove de Julho",
  "contract_id": "8286506975256586",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "527380.0",
  "expiration_date": "2024-03-26"
}, {
  "vmware_agreement": "00447764",
  "customer_name": "Network for Spectrum (NFS) Cell, DG of Signals",
  "contract_id": "8286269190988655",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "386530.89",
  "expiration_date": "2024-03-27"
}, {
  "vmware_agreement": "00441425",
  "customer_name": "Seminole Tribe of Florida",
  "contract_id": "8285617170188834",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "112271.23",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "VMware Partner Agreement No. 00274954",
  "customer_name": "Ministerio de la Producción",
  "contract_id": "8285843634254308",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "726154.43",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00478509",
  "customer_name": "Credit Reference Center Pboc中国人民银行征信中心",
  "contract_id": "8285693449278385",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3745251.41",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "VMware Agreement # 00484388",
  "customer_name": "Dfw International Airport",
  "contract_id": "8285356376763164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1474380.6",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "VMware Agreement # 00485897",
  "customer_name": "Clark County",
  "contract_id": "8285523652913720",
  "contract_title": "SINGLE RESALE AGREEMENT",
  "fees": "162925.0",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00495526",
  "customer_name": "RNP - Rede Nacional De Ensino E Pesquisa",
  "contract_id": "8840359580028485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "205417.02",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00497083",
  "customer_name": "Tj-Rj - Tribunal de Justica do Rio de Janeiro",
  "contract_id": "8285679219485452",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "268014.79",
  "expiration_date": "2023-10-16"
}, {
  "vmware_agreement": "VMware Agreement # 00499699",
  "customer_name": "Pagseguro",
  "contract_id": "8285484446807093",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "611211.14",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00488993",
  "customer_name": "T. Rowe Price Associates, Inc.",
  "contract_id": "8286672484624735",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "151759.52",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00478435",
  "customer_name": "Independent Colleges \u0026 Universities of Florida",
  "contract_id": "8285678530971028",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "169607.74",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00488996",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8285205457974655",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1111530.0",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "VMware Agreement # 00493675",
  "customer_name": "Adelaide Primary Health Network",
  "contract_id": "8285456552244735",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "574425.99",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00498306",
  "customer_name": "Department of Highways",
  "contract_id": "8285624721909766",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "153000.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00498992",
  "customer_name": "West Virginia University Wvu Extension Service",
  "contract_id": "8285096400611251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1564499.73",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00485352",
  "customer_name": "PCS SECURITY PTE LTD",
  "contract_id": "8286070489672622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "299113.0",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00498307",
  "customer_name": "NATIONAL LIBRARY BOARD",
  "contract_id": "8286038563288327",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191394.21",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00492930",
  "customer_name": "Naval Sea Systems Command",
  "contract_id": "8285279116393012",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "655476.39",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00494485",
  "customer_name": "DCB Bank Ltd",
  "contract_id": "8286490954556749",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "80980.35",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00493005",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8284840809160864",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "337342.32",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00509300",
  "customer_name": "California Employment Development Department (EDD)",
  "contract_id": "8286414343773557",
  "contract_title": "Amendment No. 7 to Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00503188",
  "customer_name": "BRB - Banco Regional de Brasilia",
  "contract_id": "8285145365334417",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "579182.4",
  "expiration_date": "2023-12-11"
}, {
  "vmware_agreement": "00531725",
  "customer_name": "Union Bank of India",
  "contract_id": "8285392502000125",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "538812.97",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "00531614",
  "customer_name": "MSN Laboratories Pvt. Ltd",
  "contract_id": "8285641684113880",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "218263.52",
  "expiration_date": "2024-01-29"
}, {
  "vmware_agreement": "00545428",
  "customer_name": "Banco CSF S/A",
  "contract_id": "9428986620415717",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "31012.57",
  "expiration_date": "2024-03-25"
}, {
  "vmware_agreement": "VMware Agreement # 00544889",
  "customer_name": "Shanghai Rural Commercial Bank",
  "contract_id": "8286202937604946",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3224832.0",
  "expiration_date": "2024-03-25"
}, {
  "vmware_agreement": "00543312",
  "customer_name": "MobiFone Corporation",
  "contract_id": "8285537921063353",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "311023.25",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00538254",
  "customer_name": "WNS Global Services Private Ltd",
  "contract_id": "8286084432594607",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88500.0",
  "expiration_date": "2024-02-11"
}, {
  "vmware_agreement": "VMware Agreement # 00589054",
  "customer_name": "China Mobile Information Technology Company Limited (RAD)",
  "contract_id": "8284582851631081",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "416415.72",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00589097",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285650887924460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953166.17",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00592333",
  "customer_name": "CLARO Argentina",
  "contract_id": "8286427319803066",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "526252.54",
  "expiration_date": "2024-01-05"
}, {
  "vmware_agreement": "00592075",
  "customer_name": "Maryland Administrative Office Of The Courts",
  "contract_id": "8286022087635476",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2024-01-08"
}, {
  "vmware_agreement": "00607209",
  "customer_name": "Telecom Argentina S.A.",
  "contract_id": "8285910105654203",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "651860.81",
  "expiration_date": "2023-12-10"
}, {
  "vmware_agreement": "VMware Agreement # 00594394",
  "customer_name": "Banco Multiple Ademi Sa",
  "contract_id": "8840108427586479",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195425.42",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "00595747",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286223068756908",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "263709.2",
  "expiration_date": "2023-12-17"
}, {
  "vmware_agreement": "00603098",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286491338252402",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "243574.94",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00606905",
  "customer_name": "Asesoras Larrain Vial Ltda",
  "contract_id": "8285587986765439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "116036.74",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00611207",
  "customer_name": "Department of Justice - BOP",
  "contract_id": "8284555990257698",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "933003.35",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00595740",
  "customer_name": "DELL USA",
  "contract_id": "8286131613866352",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "407080.46",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "00679266",
  "customer_name": "UNITED OVERSEAS BANK LIMITED",
  "contract_id": "8285909855125608",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141458.0",
  "expiration_date": "2023-10-19"
}, {
  "vmware_agreement": "VMware Agreement # 00678554",
  "customer_name": "Aguas Argentinas S.A.",
  "contract_id": "8840543114958420",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "462806.83",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00679519",
  "customer_name": "AURORA PUBLIC SCHOOLS",
  "contract_id": "8284593106867138",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "96261.75",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00678948",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286874809046061",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50104935.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00676697",
  "customer_name": "Midland County",
  "contract_id": "8285586487014499",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "113649.92",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00679513",
  "customer_name": "Tyler Junior College",
  "contract_id": "8285406659151289",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "175761.76",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679110",
  "customer_name": "Retirement Systems of Alabama",
  "contract_id": "8285538582278664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "281479.44",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Agreement # 00678924",
  "customer_name": "Palm Beach County Sheriff\u0027s Office",
  "contract_id": "8285086274871167",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "206929.62",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00680516",
  "customer_name": "Pinellas County Business Tech",
  "contract_id": "8840252217191806",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "332719.68",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672406",
  "customer_name": "State Of New Jersey",
  "contract_id": "8285707960568339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "762811.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00672596",
  "customer_name": "Azusa Pacific University",
  "contract_id": "8286538625375983",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "137970.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00674587",
  "customer_name": "Albuquerque Bernalillo County Water Utility Authority",
  "contract_id": "8285746546047131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46661.87",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "City Of Philadelphia",
  "contract_id": "8285493947983189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "978395.42",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00672980",
  "customer_name": "Idaho Department of Health and Welfare",
  "contract_id": "8285145500502791",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "145550.0",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679678",
  "customer_name": "Collier County Sheriff\u0027s Office",
  "contract_id": "8285898387929728",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "131227.41",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00680786",
  "customer_name": "Dallas College",
  "contract_id": "8286023647633739",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300600.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672107",
  "customer_name": "Oklahoma City Community College",
  "contract_id": "8286396156561146",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "203477.12",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00680170",
  "customer_name": "University Of Alabama The Center For Advanced Public Safety - UA Caps",
  "contract_id": "8285977850143621",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "93172.26",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00676512",
  "customer_name": "Douglas Omaha Technology Commission-DOTCOMM",
  "contract_id": "8285679793773642",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56147.63",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00677103",
  "customer_name": "Arkansas State University",
  "contract_id": "9428987909474821",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47700.0",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00674229",
  "customer_name": "Yavapai County",
  "contract_id": "8286070980992664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "241335.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00680327",
  "customer_name": "Broward County Board of Commissioners-OIT",
  "contract_id": "8285867331773592",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "387150.16",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00676478",
  "customer_name": "Nevada Department of Transportation",
  "contract_id": "8286396628831549",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "683622.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00678382",
  "customer_name": "Southern Ute Indian Tribe (Main)",
  "contract_id": "8284958771231709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "106482.91",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "006723921",
  "customer_name": "Arizona Department of Economic Security",
  "contract_id": "8286359036249850",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "794229.84",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00676596",
  "customer_name": "FLEURY",
  "contract_id": "8285537389373903",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "312720.87",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00679639",
  "customer_name": "Maryland Department of Public Safety and Correctional Services",
  "contract_id": "8285680088447923",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "368722.44",
  "expiration_date": "2023-11-04"
}, {
  "vmware_agreement": "VMware Agreement # 00671754",
  "customer_name": "Availity, LLC",
  "contract_id": "8286037323899340",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "230434.65",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672270",
  "customer_name": "Collin County",
  "contract_id": "8840546992690185",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "91513.98",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Agreement # 00680033",
  "customer_name": "SUNAT",
  "contract_id": "8286269679619032",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "540033.12",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00673590",
  "customer_name": "Korea Expressway Corporation",
  "contract_id": "8285745884722478",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "545321.78",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00678671",
  "customer_name": "LATAM Airlines Group S.A.",
  "contract_id": "8285693424465024",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "263802.36",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00680735",
  "customer_name": "FIRST MEDICAL HEALTH PLAN",
  "contract_id": "8286471759726974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "665344.42",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00671981",
  "customer_name": "MINISTERIO DE SALUD DEL PERU",
  "contract_id": "8286748466497325",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "547894.08",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00681004",
  "customer_name": "Liberty Cablevision Of Puerto Rico",
  "contract_id": "8285815694201133",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "133337.4",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00680796",
  "customer_name": "BAC Costa Rica - Banco de America Central",
  "contract_id": "8285471131851171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "150365.4",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00671778",
  "customer_name": "Banco Bolivariano",
  "contract_id": "8287695393119685",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "142956.8",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "00673021",
  "customer_name": "Hdfc Bank Ltd",
  "contract_id": "8286147471592981",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134904.0",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00679954",
  "customer_name": "Aditya Birla Fashion And Retail Ltd",
  "contract_id": "8286172942141114",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "69508.1",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00680062",
  "customer_name": "Puerto Rico Department of Education",
  "contract_id": "8286347011493261",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "102036.94",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00675151",
  "customer_name": "U.S. Department of Transportation",
  "contract_id": "8285563699549654",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "624615.27",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00678600",
  "customer_name": "The Kalupur Commercial Co-Op Bank",
  "contract_id": "8286586942740138",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "6317.71",
  "expiration_date": "2024-01-02"
}, {
  "vmware_agreement": "00679699",
  "customer_name": "Maryland State Highway Administration (Main)",
  "contract_id": "8285969437328256",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "385598.38",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00680619",
  "customer_name": "Banco ITAU Paraguay S.A.",
  "contract_id": "8285802004068531",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "226396.5",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00677838",
  "customer_name": "Judicial Council of California",
  "contract_id": "8840540376614989",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "254654.81",
  "expiration_date": "2023-11-14"
}, {
  "vmware_agreement": "00691231",
  "customer_name": "Digital 14",
  "contract_id": "8286055828940907",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "646880.19",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00690777",
  "customer_name": "Puerto Rico Telephone Company Inc",
  "contract_id": "8286698275559395",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "335901.59",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00689950",
  "customer_name": "Armstrong World Industries, Inc",
  "contract_id": "8285651905317728",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "391500.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00691047",
  "customer_name": "KPMG Consulting and Assurance services LLP",
  "contract_id": "8285510014952863",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42735.28",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00691030",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8286455984852120",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68",
  "expiration_date": "2024-01-04"
}, {
  "vmware_agreement": "VMware Agreement, VMware Contract No. 59242",
  "customer_name": "Servicios Administrativos Wal-Mart S de RL de CV",
  "contract_id": "8285952296096317",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "240282.24",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "00690738",
  "customer_name": "Arizona State Retirement System",
  "contract_id": "8285939620181912",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "194940.0",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00679773",
  "customer_name": "State of Nebraska",
  "contract_id": "8285707527700808",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "209111.41",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00689452",
  "customer_name": "Idaho State Dept Of The Cio",
  "contract_id": "8286283183211529",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "793161.53",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "VMware Agreement # 00691864",
  "customer_name": "Idemia",
  "contract_id": "8285814371938513",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "766618.68",
  "expiration_date": "2024-01-10"
}, {
  "vmware_agreement": "00692770",
  "customer_name": "California State Teachers Retirement System (STRS)",
  "contract_id": "8840549416801855",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "456767.93",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "Chesterfield County",
  "contract_id": "8286007701195980",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54110.55",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00691320",
  "customer_name": "Akwesasne Mohawk Casino",
  "contract_id": "8285588116939833",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "453120.0",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00690242",
  "customer_name": "ALBUQUERQUE PUBLIC SCHOOLS",
  "contract_id": "8286414742391852",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "250991.58",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "VMware Agreement # 00691661",
  "customer_name": "Coast Community College District",
  "contract_id": "8840310159854741",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "117596.56",
  "expiration_date": "2024-01-11"
}, {
  "vmware_agreement": "00688532",
  "customer_name": "Texas Tech University Health Sciences Center El Paso",
  "contract_id": "8285779245380002",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103304.6",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "00692373",
  "customer_name": "Colorado Springs Utilities",
  "contract_id": "8285344516386412",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "730835.0",
  "expiration_date": "2024-02-02"
}, {
  "vmware_agreement": "VMware Agreement # 00692977",
  "customer_name": "Alaska Railroad Corporation",
  "contract_id": "8288190208871821",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "110475.4",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00692015",
  "customer_name": "Marion County - Oregon",
  "contract_id": "8286357330274622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "65250.62",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00684951",
  "customer_name": "Klein Independent School District",
  "contract_id": "8286334790120385",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "87084.76",
  "expiration_date": "2024-01-13"
}, {
  "vmware_agreement": "VMware Agreement 00691574",
  "customer_name": "Mercadolibre S.r.l",
  "contract_id": "8285969492831737",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "924514.11",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00692737",
  "customer_name": "Claro Argentina",
  "contract_id": "8285745679432117",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "577470.68",
  "expiration_date": "2024-02-12"
}, {
  "vmware_agreement": "VMware Agreement #00691573.0",
  "customer_name": "Meli Log Srl",
  "contract_id": "8285168582955198",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "807814.56",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00681621",
  "customer_name": "Superior Court - Maricopa County",
  "contract_id": "8285071509154642",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "192000.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00693507",
  "customer_name": "Tgs - Transportadora De Gas Del Sur",
  "contract_id": "8840433886717797",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "196616.7",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00689703",
  "customer_name": "Indusind Bank Limited",
  "contract_id": "8285470805006908",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "712800.0",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "VMware Agreement # 00684045",
  "customer_name": "Evertec Puerto Rico",
  "contract_id": "8285280899749168",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "256383.6",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "VMware Agreement # 00689882",
  "customer_name": "Grupo Ol - Ol Celular",
  "contract_id": "8286604312808814",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "885337.5",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00690533",
  "customer_name": "Lotte Capital Co. Ltd",
  "contract_id": "8285447199578595",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "138485.39",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "VMware Agreement # 00691666",
  "customer_name": "Tata Consultancy Services Ltd",
  "contract_id": "8285456307442669",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "212346.64",
  "expiration_date": "2024-01-04"
}, {
  "vmware_agreement": "VMware Agreement # 00694540",
  "customer_name": "NANYANG TECHNOLOGICAL UNIVERSITY",
  "contract_id": "8840485870070062",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "295914.34",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00691208",
  "customer_name": "Company General de Electricidad",
  "contract_id": "8285939556573399",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "218924.5",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00684819 and 00682879",
  "customer_name": "Universidad Andres Bello",
  "contract_id": "8285586275383772",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "208710.0",
  "expiration_date": "2023-12-02"
}, {
  "vmware_agreement": "00692706",
  "customer_name": "Antel",
  "contract_id": "8285272103379385",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "676094.53",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00694254",
  "customer_name": "Banco Bradesco",
  "contract_id": "8286055793567490",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "749991.24",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00684662",
  "customer_name": "Claro Argentina",
  "contract_id": "8284261719223925",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "565823.9",
  "expiration_date": "2024-01-05"
}, {
  "vmware_agreement": "00684424",
  "customer_name": "Southern California Edison Company",
  "contract_id": "9428987988460282",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "258234.78",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00685346",
  "customer_name": "US Department of State IRM/OPS/SIO",
  "contract_id": "8285678546147438",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "134348.45",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00692025",
  "customer_name": "L.A. Care",
  "contract_id": "8840242481042475",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "846230.0",
  "expiration_date": "2024-01-25"
}, {
  "vmware_agreement": "00690769",
  "customer_name": "Municipality of Anchorage",
  "contract_id": "8285882243575236",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "243503.96",
  "expiration_date": "2024-01-01"
}, {
  "vmware_agreement": "00688528",
  "customer_name": "MOHELA",
  "contract_id": "8285343353966775",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "258592.38",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "VMware OEM Agreement",
  "customer_name": "Ningde Times Shanghai Intelligent Technology Co., Ltd.",
  "contract_id": "8286854012962260",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "3995326.2",
  "expiration_date": "2024-03-24"
}, {
  "vmware_agreement": "00708925",
  "customer_name": "Qatar National Bank",
  "contract_id": "8286652993888197",
  "contract_title": "SINGLE ENTERPRISE ORDER RESALE AGREEMENT",
  "fees": "250066.95",
  "expiration_date": "2024-01-31"
}, {
  "vmware_agreement": "00675703",
  "customer_name": "Stony Brook University-Primary",
  "contract_id": "8840344592459529",
  "contract_title": "SINGLE ENTERPRISE ORDER RESALE AGREEMENT",
  "fees": "884520.0",
  "expiration_date": "2024-03-27"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Banco de Guayaquil, S.A.",
  "contract_id": "8285746534375374",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "181911.7",
  "expiration_date": "2024-03-17"
}, {
  "vmware_agreement": "00353136",
  "customer_name": "Pine Labs Pvt. Ltd",
  "contract_id": "8285829234122825",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "44560.0",
  "expiration_date": "2024-03-23"
}, {
  "vmware_agreement": "00485892",
  "customer_name": "Sirius Computer Solutions, Inc (main)",
  "contract_id": "8284625741038535",
  "contract_title": "Order Form",
  "fees": "7140.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00672983",
  "customer_name": "Federated Mutual Insurance Companies",
  "contract_id": "8285330924348966",
  "contract_title": "VMware ELA",
  "fees": "273277.35",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00689545",
  "customer_name": "Morgan \u0026 Morgan",
  "contract_id": "8284370478906361",
  "contract_title": "Q-E00462260",
  "fees": "0.0",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00489258",
  "customer_name": "Vistra Corporate Services Company",
  "contract_id": "8285814127943584",
  "contract_title": "ELA ORDER FORM",
  "fees": "185380.88",
  "expiration_date": "2023-10-02"
}, {
  "vmware_agreement": "00675157",
  "customer_name": "Major Hospital",
  "contract_id": "8285679793061560",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-04"
}, {
  "vmware_agreement": "00483156",
  "customer_name": "Budage Pty Ltd ATF Tonclay Services Trust",
  "contract_id": "8285330513832121",
  "contract_title": "ELA ORDER FORM",
  "fees": "74088.75",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00493093",
  "customer_name": "VIRGIN AUSTRALIA",
  "contract_id": "8285270950361734",
  "contract_title": "ELA ORDER FORM",
  "fees": "64277.14",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00494931",
  "customer_name": "DHI Computing Service",
  "contract_id": "8284162209865579",
  "contract_title": "ELA ORDER FORM",
  "fees": "75107.54",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "00676335",
  "customer_name": "Monash Health",
  "contract_id": "8285304836336956",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-13"
}, {
  "vmware_agreement": "00494673",
  "customer_name": "Eating Recovery Center",
  "contract_id": "8285008570074491",
  "contract_title": "ELA ORDER FORM",
  "fees": "110882.02",
  "expiration_date": "2023-10-16"
}, {
  "vmware_agreement": "00675155",
  "customer_name": "Westat, Inc",
  "contract_id": "8285570970662030",
  "contract_title": "ELA ORDER FORM",
  "fees": "3575.92",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679586",
  "customer_name": "Monash Health",
  "contract_id": "8285456352207682",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-20"
}, {
  "vmware_agreement": "00493651",
  "customer_name": "Keysight Technologies. Inc",
  "contract_id": "8284497575772843",
  "contract_title": "ELA ORDER FORM",
  "fees": "355053.92",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00494975",
  "customer_name": "MSC, Inc",
  "contract_id": "8284957527850972",
  "contract_title": "ELA ORDER FORM",
  "fees": "74875.81",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00679346",
  "customer_name": "Adient",
  "contract_id": "8284368975829357",
  "contract_title": "ELA ORDER FORM",
  "fees": "161711.63",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00497605",
  "customer_name": "Catalyst Corporate Federal Credit Union",
  "contract_id": "8284935642730213",
  "contract_title": "ELA ORDER FORM",
  "fees": "106286.57",
  "expiration_date": "2023-10-23"
}, {
  "vmware_agreement": "00491705",
  "customer_name": "Hanger, Inc.",
  "contract_id": "8285258948814610",
  "contract_title": "ELA ORDER FORM",
  "fees": "250060.66",
  "expiration_date": "2023-10-23"
}, {
  "vmware_agreement": "00673306 \u0026 00673122",
  "customer_name": "Rubrik Inc",
  "contract_id": "8285043058373209",
  "contract_title": "ELA ORDER FORM",
  "fees": "265000.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00493288",
  "customer_name": "National Education Association",
  "contract_id": "8285418517837267",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00492592",
  "customer_name": "Pacific National",
  "contract_id": "8284949981943756",
  "contract_title": "ELA ORDER FORM",
  "fees": "96361.7",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00678575",
  "customer_name": "NEW ZEALAND CUSTOMS SERVICE",
  "contract_id": "8283931184374994",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00488988",
  "customer_name": "Bessemer Trust Co",
  "contract_id": "8285043389782026",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00673639",
  "customer_name": "Washington Gas Light Company",
  "contract_id": "8284949978939100",
  "contract_title": "ELA ORDER FORM",
  "fees": "139484.59",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00493648",
  "customer_name": "AM GENERAL LLC",
  "contract_id": "8284829712141788",
  "contract_title": "ELA ORDER FORM",
  "fees": "334500.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00590065",
  "customer_name": "Boart Longyear Company",
  "contract_id": "8840294150386995",
  "contract_title": "ELA ORDER FORM",
  "fees": "52543.73",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00498108",
  "customer_name": "Powerschool Group LLC",
  "contract_id": "8285640768989759",
  "contract_title": "ELA ORDER FORM",
  "fees": "250046.44",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00500430",
  "customer_name": "Bendigo Kangan Institute",
  "contract_id": "8285429666764391",
  "contract_title": "ELA ORDER FORM",
  "fees": "54116.01",
  "expiration_date": "2023-11-17"
}, {
  "vmware_agreement": "00503225",
  "customer_name": "Weta Digital Limited",
  "contract_id": "8285790850049324",
  "contract_title": "ELA ORDER FORM",
  "fees": "30210.91",
  "expiration_date": "2023-11-17"
}, {
  "vmware_agreement": "00683187",
  "customer_name": "Navitas Limited",
  "contract_id": "8286686397149708",
  "contract_title": "ELA ORDER FORM",
  "fees": "8365.86",
  "expiration_date": "2023-11-21"
}, {
  "vmware_agreement": "00677150",
  "customer_name": "Snap Inc",
  "contract_id": "8285563084709537",
  "contract_title": "ELA ORDER FORM",
  "fees": "82320.67",
  "expiration_date": "2023-11-22"
}, {
  "vmware_agreement": "005060121",
  "customer_name": "Neuberger Berman",
  "contract_id": "8284995564747645",
  "contract_title": "ELA ORDER FORM",
  "fees": "29627.11",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "00503177",
  "customer_name": "GoDaddy.com, LLC",
  "contract_id": "8285167531015969",
  "contract_title": "ELA ORDER FORM",
  "fees": "1725683.37",
  "expiration_date": "2023-11-24"
}, {
  "vmware_agreement": "00679106",
  "customer_name": "FATCO Holdings, LLC",
  "contract_id": "8284781092970263",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00683770",
  "customer_name": "Ringcentral Inc",
  "contract_id": "8285746503724836",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00507476",
  "customer_name": "TBC Corporation",
  "contract_id": "8285897663413291",
  "contract_title": "ELA ORDER FORM",
  "fees": "327573.09",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00681901",
  "customer_name": "WESTCHESTER COUNTY DOIT",
  "contract_id": "8286159597307065",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-05"
}, {
  "vmware_agreement": "00518262",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284971816724795",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-07"
}, {
  "vmware_agreement": "00500281",
  "customer_name": "Albright-Knox Art Gallery",
  "contract_id": "8285143976595179",
  "contract_title": "ELA ORDER FORM",
  "fees": "31467.85",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00683422",
  "customer_name": "POSTMEDIA BUSINESS TECHNOLOGY",
  "contract_id": "8285058220160617",
  "contract_title": "ELA ORDER FORM",
  "fees": "20908.94",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00505979",
  "customer_name": "Old Republic National Title Holding Company",
  "contract_id": "8840183845593901",
  "contract_title": "ELA ORDER FORM",
  "fees": "232949.54",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00359052",
  "customer_name": "Device Technologies Australia Pty. Ltd.",
  "contract_id": "8285279597357152",
  "contract_title": "ELA ORDER FORM",
  "fees": "38723.52",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00684716",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285625804866516",
  "contract_title": "ELA ORDER FORM",
  "fees": "2791692.9",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00689685",
  "customer_name": "Cornerstone OnDemand (main)",
  "contract_id": "8284957400941671",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00684990",
  "customer_name": "Advantage Sales \u0026 Marketing LLC",
  "contract_id": "8284476558156024",
  "contract_title": "ELA ORDER FORM",
  "fees": "982.39",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00675143",
  "customer_name": "PROS INC., a Delaware Corporation",
  "contract_id": "8285814130358013",
  "contract_title": "ELA ORDER FORM",
  "fees": "1259.7",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00489797",
  "customer_name": "Cedars-Sinai Medical Center",
  "contract_id": "8284134617227133",
  "contract_title": "ELA ORDER FORM",
  "fees": "263679.82",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00522803",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284368893000495",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-08"
}, {
  "vmware_agreement": "00683652",
  "customer_name": "Qube Ports \u0026 Bulk",
  "contract_id": "8284556274081100",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-12"
}, {
  "vmware_agreement": "00689544",
  "customer_name": "Jaggaer, LLC",
  "contract_id": "8284842235283423",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "00362329",
  "customer_name": "Childrens National Medical Center",
  "contract_id": "8284120198105139",
  "contract_title": "ELA ORDER FORM",
  "fees": "3445205.83",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "00609765",
  "customer_name": "Department of Health \u0026 Human Services",
  "contract_id": "8285121000271646",
  "contract_title": "ELA ORDER FORM",
  "fees": "364929.83",
  "expiration_date": "2024-01-19"
}, {
  "vmware_agreement": "00693000",
  "customer_name": "Qube Ports \u0026 Bulk",
  "contract_id": "8285678422508701",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-19"
}, {
  "vmware_agreement": "00693708",
  "customer_name": "Citic Pacific Mining",
  "contract_id": "8286587589664207",
  "contract_title": "ELA ORDER FORM",
  "fees": "23632.38",
  "expiration_date": "2024-01-20"
}, {
  "vmware_agreement": "00689557",
  "customer_name": "First Sentier Investors (Australia) Services Pty Limited",
  "contract_id": "8286587578985890",
  "contract_title": "ELA ORDER FORM",
  "fees": "2050.92",
  "expiration_date": "2024-01-23"
}, {
  "vmware_agreement": "00689800",
  "customer_name": "Geisinger Health System Foundation",
  "contract_id": "8285379267675728",
  "contract_title": "ELA ORDER FORM",
  "fees": "197600.0",
  "expiration_date": "2024-01-24"
}, {
  "vmware_agreement": "00685367",
  "customer_name": "Csaa Insurance Group",
  "contract_id": "8285059224103702",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00690541",
  "customer_name": "ESS Super",
  "contract_id": "8840424984691659",
  "contract_title": "ELA ORDER FORM",
  "fees": "16426.41",
  "expiration_date": "2024-01-29"
}, {
  "vmware_agreement": "00694498",
  "customer_name": "JWS Services Pty Ltd ATF the JWS Services Trust",
  "contract_id": "8285651067388917",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00357897",
  "customer_name": "Arkansas Department of Information Systems(main)",
  "contract_id": "8285222889907661",
  "contract_title": "ELA ORDER FORM",
  "fees": "202218.14",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00692394",
  "customer_name": "Tesla Inc",
  "contract_id": "8285680087939156",
  "contract_title": "ELA ORDER FORM",
  "fees": "9126648.0",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00367999",
  "customer_name": "Samvardhana Motherson International Limited (SAMIL)",
  "contract_id": "8285777290053517",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00690984",
  "customer_name": "NBCUniversal Media, LLC",
  "contract_id": "8284567166911358",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-02"
}, {
  "vmware_agreement": "00695034",
  "customer_name": "NEW ZEALAND QUALIFICATIONS AUTHORITY",
  "contract_id": "8285720407590468",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-02"
}, {
  "vmware_agreement": "00535218",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284795158663354",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-10"
}, {
  "vmware_agreement": "00681893",
  "customer_name": "Havergal College (k-12)",
  "contract_id": "8284973238328006",
  "contract_title": "ELA ORDER FORM",
  "fees": "26000.0",
  "expiration_date": "2024-02-23"
}, {
  "vmware_agreement": "00540023",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284556789996597",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-26"
}, {
  "vmware_agreement": "00540228",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284682741699730",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-26"
}, {
  "vmware_agreement": "00540577",
  "customer_name": "Tata Consultancy Services Ltd.",
  "contract_id": "8284956802782032",
  "contract_title": "ELA ORDER FORM",
  "fees": "232569.36",
  "expiration_date": "2024-03-10"
}, {
  "vmware_agreement": "00538732",
  "customer_name": "Department of Defence",
  "contract_id": "8285373220881269",
  "contract_title": "ELA ORDER FORM",
  "fees": "5052294.84",
  "expiration_date": "2024-03-11"
}, {
  "vmware_agreement": "00545005",
  "customer_name": "Inova Health System",
  "contract_id": "8284889337287787",
  "contract_title": "ELA ORDER FORM",
  "fees": "3656023.24",
  "expiration_date": "2024-03-25"
}, {
  "vmware_agreement": "00545588",
  "customer_name": "Tessy Plastics Corporation",
  "contract_id": "8285071999788983",
  "contract_title": "ELA ORDER FORM",
  "fees": "48995.1",
  "expiration_date": "2024-03-26"
}, {
  "vmware_agreement": "00549056",
  "customer_name": "Computer Sciences Corporation",
  "contract_id": "8285651076159166",
  "contract_title": "ELA Order Form",
  "fees": "2410348.0",
  "expiration_date": "2023-11-06"
}, {
  "vmware_agreement": "00482107",
  "customer_name": "Weatlh Enhancement Group",
  "contract_id": "8285563177821180",
  "contract_title": "VMware SPP Agreement",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00485464",
  "customer_name": "Harvard University",
  "contract_id": "8285447240416317",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00503161",
  "customer_name": "ClearStructure Financial Technology",
  "contract_id": "8285897640145704",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00540181",
  "customer_name": "Red Ventures, LLC",
  "contract_id": "8285830340350378",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2024-03-11"
}, {
  "vmware_agreement": "00493253",
  "customer_name": "Alpine School District",
  "contract_id": "8285168311198795",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "00678369",
  "customer_name": "Siteone Landscape Supply",
  "contract_id": "8284648707603353",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00677203",
  "customer_name": "Blue Origin LLC",
  "contract_id": "8284414423724767",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "179584.24",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00497108",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8285121487131967",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "45110757.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "006785591",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8283960730973742",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1200000.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00496687",
  "customer_name": "New York City Health and Hospitals Corporation",
  "contract_id": "9428934098353041",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00498100",
  "customer_name": "Fidelity Information Services, LLC",
  "contract_id": "8284752912294912",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00494247",
  "customer_name": "METROPLUS HEALTH PLAN INC",
  "contract_id": "8285641391497410",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00394278",
  "customer_name": "Valley National Bank",
  "contract_id": "8840294155775144",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "100000.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00681753",
  "customer_name": "Medidata Solutions",
  "contract_id": "8284694309373333",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "556629.57",
  "expiration_date": "2023-11-08"
}, {
  "vmware_agreement": "00672861",
  "customer_name": "Wellstar Health Systems, Inc.",
  "contract_id": "8285179164893471",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-11"
}, {
  "vmware_agreement": "00497759",
  "customer_name": "CBC-Radio Canada",
  "contract_id": "8285257656629404",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00684649",
  "customer_name": "City Of Philadelphia DHS",
  "contract_id": "8285319262983429",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00499466",
  "customer_name": "The Bank of Nova Scotia",
  "contract_id": "8285841884161126",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00493919",
  "customer_name": "Cooley LLP",
  "contract_id": "8285145256955029",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "448075.39",
  "expiration_date": "2024-01-18"
}, {
  "vmware_agreement": "00674183",
  "customer_name": "Pinnacle Financial Partners Inc.",
  "contract_id": "8840425001087493",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00607412",
  "customer_name": "Hawaii Medical Service Association (HMSA)",
  "contract_id": "8285510758935387",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00693225",
  "customer_name": "Kohl\u0027s, Inc. (f/k/a Kohl\u0027s Department Stores, Inc.)",
  "contract_id": "8284997074020521",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-02"
}, {
  "vmware_agreement": "006734221",
  "customer_name": "Baystate Health, Inc.",
  "contract_id": "8284874028320818",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-02-03"
}, {
  "vmware_agreement": "00672866",
  "customer_name": "Costco Wholesale Corporation",
  "contract_id": "8284768385279877",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-03-15"
}, {
  "vmware_agreement": "00691568",
  "customer_name": "Winstead PC",
  "contract_id": "8285271754992504",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2024-01-06"
}, {
  "vmware_agreement": "00692217",
  "customer_name": "Sunflower Bank N.A.",
  "contract_id": "8284241502318093",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00678542",
  "customer_name": "HRB Tax Group, Inc.",
  "contract_id": "8284852666444121",
  "contract_title": "H\u0026R Block \u0026 VMware - ELA",
  "fees": "0.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00678895",
  "customer_name": "Environmental System Research Institute, Inc.",
  "contract_id": "8285378186295660",
  "contract_title": "A - 10 - Procurement Agmt",
  "fees": "231985.14",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00676698",
  "customer_name": "Stonex Group Inc.",
  "contract_id": "8284873265704425",
  "contract_title": "Amendment #6 to ELA Order",
  "fees": "156836.97",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00358127",
  "customer_name": "Hydro-Quebec-Parent *",
  "contract_id": "8285494402760936",
  "contract_title": "VMC ON AWS ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-01"
}, {
  "vmware_agreement": "00498447",
  "customer_name": "Gilead Sciences, Inc.",
  "contract_id": "8285258896213863",
  "contract_title": "Amendment No. 8 to the ELA",
  "fees": "1048058.52",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00683157",
  "customer_name": "Gilead Sciences Inc",
  "contract_id": "8285318438890933",
  "contract_title": "Amendment No. 9 TO THE ELA",
  "fees": "292423.82",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00685149",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "8284613943562515",
  "contract_title": "VMWare Credits Organon TSA",
  "fees": "0.0",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00495148",
  "customer_name": "Mercy Health",
  "contract_id": "8285456833539670",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "71824.62",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00588512",
  "customer_name": "State of Louisiana Division of Administration",
  "contract_id": "8285222106216439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "528980.25",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00684227",
  "customer_name": "Nationale Politie",
  "contract_id": "9429038153647456",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "687047.42",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00674210",
  "customer_name": "Global Companies LLC",
  "contract_id": "8285258318122316",
  "contract_title": "Enterprise License Agreement",
  "fees": "32342.28",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00679670",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8285279691395703",
  "contract_title": "Enterprise License Agreement",
  "fees": "400000.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00680515",
  "customer_name": "YRC Enterprise Services Inc",
  "contract_id": "8285059539312492",
  "contract_title": "Enterprise License Agreement",
  "fees": "249997.78",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00680357",
  "customer_name": "Apple Inc",
  "contract_id": "8285305277723892",
  "contract_title": "Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-11-07"
}, {
  "vmware_agreement": "00595004",
  "customer_name": "Signet Jewelers",
  "contract_id": "8285813626087980",
  "contract_title": "Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-10"
}, {
  "vmware_agreement": "00483560",
  "customer_name": "Neighborhood Health Plan of Rhode Island",
  "contract_id": "8840321755253656",
  "contract_title": "Enterprise License Agreement",
  "fees": "27524.33",
  "expiration_date": "2024-01-15"
}, {
  "vmware_agreement": "00622007",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8840080428099162",
  "contract_title": "Enterprise License Agreement",
  "fees": "112800.0",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "00540059",
  "customer_name": "Charlotte Eye, Ear, Nose and Throat Associates, PA",
  "contract_id": "8284780978761257",
  "contract_title": "Enterprise License Agreement",
  "fees": "32099.02",
  "expiration_date": "2024-03-18"
}, {
  "vmware_agreement": "00685106",
  "customer_name": "American Multi-Cinema, Inc.",
  "contract_id": "8284972243631318",
  "contract_title": "AMC - VMWare - ELA Order Form",
  "fees": "74134.86",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00529235",
  "customer_name": "Blackrock, Inc.",
  "contract_id": "8284681906152173",
  "contract_title": "Amendment No. 6 to the ELA Order",
  "fees": "0.0",
  "expiration_date": "2024-01-29"
}, {
  "vmware_agreement": "00676129",
  "customer_name": "Bath \u0026 Body Works, Inc.",
  "contract_id": "8284346945021289",
  "contract_title": "Amendment No. 7 to the ELA Order",
  "fees": "758751.38",
  "expiration_date": "2023-11-21"
}, {
  "vmware_agreement": "00671884",
  "customer_name": "PTC Inc, a Massachusetts corporation (f/k/a Parametric Technology Corporation)",
  "contract_id": "8285109504224438",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "293018.84",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00671884",
  "customer_name": "PTC Inc, a Massachusetts corporation (f/k/a Parametric Technology Corporation)",
  "contract_id": "8285289635719434",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "293018.84",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00670266",
  "customer_name": "Memorial Sloan-Kettering Cancer Center",
  "contract_id": "8284794907312152",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "757501.37",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00694662",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8840487719187012",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "1296320.74",
  "expiration_date": "2023-11-22"
}, {
  "vmware_agreement": "00481955",
  "customer_name": "Piedmont Health, Inc.",
  "contract_id": "8285509067644453",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00492904",
  "customer_name": "Starbucks Corporation",
  "contract_id": "8285546118464378",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00497585",
  "customer_name": "Alaska USA Federal Credit Union",
  "contract_id": "8285271468660775",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00689777",
  "customer_name": "Adobe Inc.",
  "contract_id": "8284624764707008",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "637623.28",
  "expiration_date": "2024-01-05"
}, {
  "vmware_agreement": "00696170",
  "customer_name": "FedEx Corporate Services, Inc.",
  "contract_id": "8285391256962495",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "276256.5",
  "expiration_date": "2024-03-23"
}, {
  "vmware_agreement": "00491957",
  "customer_name": "AutoZone Parts, Inc.",
  "contract_id": "8284865179517613",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "148017.37",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00678737",
  "customer_name": "Mitek Industries",
  "contract_id": "8284805010168977",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "53536.94",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00679109",
  "customer_name": "Nutrien (Canada) Holdings ULC formerly doing business as Agrium",
  "contract_id": "8285087195127633",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "17965.8",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00688943",
  "customer_name": "Local Government Association of South Australia (LGA)",
  "contract_id": "8286733911106474",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-18"
}, {
  "vmware_agreement": "00684060",
  "customer_name": "Berkley Technology Services, LLC",
  "contract_id": "8840113950674501",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "1025888.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00683896",
  "customer_name": "US Medical management",
  "contract_id": "8285058918393096",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "17822.38",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00691624",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8284428209010322",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "2570048.18",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00498209",
  "customer_name": "Montefiore Medicine Academic Health System, Inc.",
  "contract_id": "8285604907234615",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "91773.33",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00689518",
  "customer_name": "Quest Diagnostics Inc",
  "contract_id": "8285009312912749",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "221318.6",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00363033",
  "customer_name": "Australia Pacific Airports (Melbourne) Pty Ltd",
  "contract_id": "8285178132267004",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "244887.24",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00189150",
  "customer_name": "Huron Legal, a Consilio Company",
  "contract_id": "8285563553845184",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "818616.9",
  "expiration_date": "2024-03-26"
}, {
  "vmware_agreement": "00693264",
  "customer_name": "The Pennsylvania State University",
  "contract_id": "8284983729318885",
  "contract_title": "VMware vCloud Suite 2019 Enterprise",
  "fees": "469378.9",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "00670267",
  "customer_name": "Hartford Health Care Corporation",
  "contract_id": "8284262304390105",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "91033.47",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00690260",
  "customer_name": "The Prudential Insurance Company of America",
  "contract_id": "8285897664074131",
  "contract_title": "Amendment No. 21 To The Exhibit A-11",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00607197",
  "customer_name": "ZenPayroll, Inc. dba Gusto",
  "contract_id": "8285842765684234",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00679169",
  "customer_name": "Taxslayer, LLC, A Marlin Portfolio Company",
  "contract_id": "8284842565209786",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "118873.15",
  "expiration_date": "2023-10-19"
}, {
  "vmware_agreement": "00679726",
  "customer_name": "Switch Communications Group",
  "contract_id": "8285086400335691",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "298709.34",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00495506",
  "customer_name": "Texas Instruments Incorporated",
  "contract_id": "8285801000655528",
  "contract_title": "AMENDMENT NO. 6 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00486838",
  "customer_name": "OhioHealth Corporation",
  "contract_id": "8285522067182586",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "446205.5",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00498272",
  "customer_name": "VF Services, LLC on behalf of VF Corporation",
  "contract_id": "8285720052471681",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "1882951.03",
  "expiration_date": "2023-11-13"
}, {
  "vmware_agreement": "00689200",
  "customer_name": "Bell Canada",
  "contract_id": "8284694084418064",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "648748.6",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00681237",
  "customer_name": "University of Massachusetts (MA)",
  "contract_id": "8285651070392412",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "369737.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00685816",
  "customer_name": "Shearman \u0026 Sterling LLP",
  "contract_id": "8285664668001676",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "259911.89",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00606253",
  "customer_name": "Loblaw Inc.",
  "contract_id": "8285032065194380",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "00692407",
  "customer_name": "Sinclair Broadcast Group, Inc.",
  "contract_id": "8285110673185190",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00606686",
  "customer_name": "Qorvo US, Inc.",
  "contract_id": "8284593394830865",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "277247.92",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00678752",
  "customer_name": "Viasat, Inc",
  "contract_id": "8285801725373188",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00393715",
  "customer_name": "Las Vegas Sands Corp.",
  "contract_id": "9429038150265418",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "232383.79",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00558984",
  "customer_name": "Virginia Commonwealth University Health System Authority",
  "contract_id": "8285071902632464",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "455856.39",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00681493",
  "customer_name": "Nordstrom, Inc.",
  "contract_id": "8285240685967508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "905622.05",
  "expiration_date": "2023-12-08"
}, {
  "vmware_agreement": "00679895",
  "customer_name": "Valley National Bank",
  "contract_id": "8840130877305363",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "254927.19",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00680200",
  "customer_name": "Comenity Servicing LLC",
  "contract_id": "8284476257154924",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "431334.7",
  "expiration_date": "2023-11-29"
}, {
  "vmware_agreement": "00688310",
  "customer_name": "Allegis Group, Inc.",
  "contract_id": "8285033747704686",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "777653.19",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00690775",
  "customer_name": "Servicenow Inc",
  "contract_id": "8284209036944057",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "1574944.0",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00708422",
  "customer_name": "Kohl\u0027s Inc. (f/k/a Kohl\u0027s Department Stores, Inc.)",
  "contract_id": "8286717076410212",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00683156",
  "customer_name": "Valley National Bank",
  "contract_id": "8284899277016924",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "356733.1",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00692534",
  "customer_name": "Citadel Enterprise Americas LLC",
  "contract_id": "8285143999952225",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "985025.96",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00695857",
  "customer_name": "Servicenow Inc",
  "contract_id": "8288388589010790",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "819365.25",
  "expiration_date": "2024-03-01"
}, {
  "vmware_agreement": "00490611",
  "customer_name": "Santander Bank NA.",
  "contract_id": "8284873715388531",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "773634.06",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00691269",
  "customer_name": "Q2 Software, Inc.",
  "contract_id": "8284937031837839",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "4278714.73",
  "expiration_date": "2024-01-20"
}, {
  "vmware_agreement": "00645758",
  "customer_name": "Advance Auto Business Support, LLC",
  "contract_id": "8285097124243032",
  "contract_title": "Amendment No. 8 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2024-01-01"
}, {
  "vmware_agreement": "00689206",
  "customer_name": "Pure storage Inc",
  "contract_id": "8284338655504788",
  "contract_title": "Amendment No. 10 to the ELA Order Form",
  "fees": "274091.52",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00492562",
  "customer_name": "Kaiser Foundation Hospitals",
  "contract_id": "8840178777190864",
  "contract_title": "Amendment No. 11 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-11-12"
}, {
  "vmware_agreement": "00492562",
  "customer_name": "Kaiser Foundation Hospitals",
  "contract_id": "8285379708939428",
  "contract_title": "Amendment No. 11 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-11-12"
}, {
  "vmware_agreement": "00552867",
  "customer_name": "Pacific Gas and Electric Company",
  "contract_id": "8285494658988181",
  "contract_title": "Amendment No. 11 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00541762",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285572923326487",
  "contract_title": "Amendment No. 14 to the ELA Order Form",
  "fees": "700000.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00677809",
  "customer_name": "Carnival Corporation",
  "contract_id": "8284853024751985",
  "contract_title": "Amendment No. 15 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-19"
}, {
  "vmware_agreement": "00677850",
  "customer_name": "Nvidia Corporation",
  "contract_id": "8285537028202929",
  "contract_title": "Amendment No. 16 to the ELA Order Form",
  "fees": "605266.58",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00479759",
  "customer_name": "Piedmont Health, Inc.",
  "contract_id": "8285639958877152",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00484494",
  "customer_name": "Blue Cross and Blue Shield of Vermont",
  "contract_id": "8285305535751100",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "166685.15",
  "expiration_date": "2023-10-04"
}, {
  "vmware_agreement": "00493047",
  "customer_name": "Independent Purchasing Cooperative, Inc",
  "contract_id": "8285086300018851",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "250000.0",
  "expiration_date": "2023-10-09"
}, {
  "vmware_agreement": "00673649",
  "customer_name": "Firstbank Data Corp",
  "contract_id": "8284957453709116",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "00494050",
  "customer_name": "IG Services, LLC",
  "contract_id": "8284794250631376",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "64880.71",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "00679104",
  "customer_name": "American Axle \u0026 Manufacturing Inc",
  "contract_id": "8284232455969571",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "64490.98",
  "expiration_date": "2023-10-20"
}, {
  "vmware_agreement": "00495767",
  "customer_name": "Dolby Laboratories Inc.",
  "contract_id": "8285193684987653",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "342273.28",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00678918",
  "customer_name": "Enmax Corporation",
  "contract_id": "8285194891669746",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00674209",
  "customer_name": "IBG LLC",
  "contract_id": "8285145449272607",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "303478.77",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00495141",
  "customer_name": "Sinclair Services Company",
  "contract_id": "8285640378110075",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1608371.45",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00671739",
  "customer_name": "Health Care Service Corporation (HCSC)",
  "contract_id": "8285043907668510",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "179460.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00588736",
  "customer_name": "Molson Coors Beverage Company USA LLC",
  "contract_id": "8284521871433355",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00679899",
  "customer_name": "MARS",
  "contract_id": "8285495548394257",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "1371888.31",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00501872",
  "customer_name": "PA Office of Attorney General",
  "contract_id": "8284728220988165",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "120152.29",
  "expiration_date": "2023-11-19"
}, {
  "vmware_agreement": "00682899",
  "customer_name": "Midland Credit Management, Inc",
  "contract_id": "8284950036439879",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00596236",
  "customer_name": "Bechtel Global Corporation",
  "contract_id": "8284949342887184",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "257882.73",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00428825",
  "customer_name": "The Washington Post",
  "contract_id": "8284740332232843",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "627956.78",
  "expiration_date": "2023-12-26"
}, {
  "vmware_agreement": "00358350",
  "customer_name": "Safety Insurance Company",
  "contract_id": "8284347542575129",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "101965.84",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00688287",
  "customer_name": "Evolent Health",
  "contract_id": "8840222301194656",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "21948.06",
  "expiration_date": "2024-01-17"
}, {
  "vmware_agreement": "006846181",
  "customer_name": "Precision Castparts Corp.",
  "contract_id": "8285032245912192",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "775555.97",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00360558.0",
  "customer_name": "Citadel Securities Americas LLC",
  "contract_id": "8285110291657189",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "203971.36",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00357870",
  "customer_name": "Co-Operators Financial Services Limited",
  "contract_id": "8284296622855610",
  "contract_title": "Enterprise License Agreement Order Form",
  "fees": "192034.52",
  "expiration_date": "2024-02-28"
}, {
  "vmware_agreement": "00607180.0",
  "customer_name": "Unum Group",
  "contract_id": "8284741300647324",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "62404.8",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00672022",
  "customer_name": "Centene Corporation",
  "contract_id": "8285221929291119",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER",
  "fees": "0.0",
  "expiration_date": "2024-01-28"
}, {
  "vmware_agreement": "00581570",
  "customer_name": "CBRE, Inc.",
  "contract_id": "8285032636991480",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "225000.0",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00680551",
  "customer_name": "On Semiconductor Corp",
  "contract_id": "8284889987211569",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "267687.49",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00493933",
  "customer_name": "Schneider National, Inc",
  "contract_id": "8840258245257515",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "285099.41",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00493650",
  "customer_name": "Indian Hills Community College on behalf of the lowa Community Colleges Network Consortium",
  "contract_id": "8284889337277970",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8284910019159011",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00497917",
  "customer_name": "Hoag Hospital",
  "contract_id": "8285259545800451",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891245.62",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00497936",
  "customer_name": "The Children\u0027s Hospital of Alabama",
  "contract_id": "8284741981211148",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00671228",
  "customer_name": "The New York and Presbyterian Hospital",
  "contract_id": "8285679407716246",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-14"
}, {
  "vmware_agreement": "00673638",
  "customer_name": "Piedmont Healthcare, Inc.",
  "contract_id": "8285663698276998",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00684550",
  "customer_name": "IBG LLC",
  "contract_id": "8284852072027603",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-07"
}, {
  "vmware_agreement": "00682066",
  "customer_name": "Kaiser Foundation Hospitals",
  "contract_id": "8285378288834448",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "2661258.71",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00540365",
  "customer_name": "Shared Health Inc",
  "contract_id": "8284888959463730",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-17"
}, {
  "vmware_agreement": "00674245",
  "customer_name": "Gopher Resource Corp",
  "contract_id": "8285258733400717",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "39409.2",
  "expiration_date": "2023-12-26"
}, {
  "vmware_agreement": "00691040",
  "customer_name": "Textron Inc",
  "contract_id": "8285289882526502",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-03"
}, {
  "vmware_agreement": "00689508",
  "customer_name": "Los Angeles County Metropolitan Transportation Authority",
  "contract_id": "9429038150277977",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "891672.24",
  "expiration_date": "2024-01-26"
}, {
  "vmware_agreement": "00425065",
  "customer_name": "Pinnacle Financial Partners Inc.",
  "contract_id": "8285179543431127",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "260563.39",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00693902",
  "customer_name": "Tyson Shared Service",
  "contract_id": "8285978201887900",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2024-01-27"
}, {
  "vmware_agreement": "00684943",
  "customer_name": "Prime Therapeutics LLC",
  "contract_id": "8284851556651503",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "897719.38",
  "expiration_date": "2024-01-30"
}, {
  "vmware_agreement": "00684993",
  "customer_name": "Kohlberg Kravis Roberts \u0026 Co. L.P.",
  "contract_id": "8840107544305501",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "64719.21",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00695575",
  "customer_name": "Microchip Technology Inc *",
  "contract_id": "8285978175662694",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "131832.46",
  "expiration_date": "2024-02-01"
}, {
  "vmware_agreement": "00681544",
  "customer_name": "University Health Systems of Eastern Carolina,Inc. d/b/a ECU Health",
  "contract_id": "8284582483413747",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "80917.87",
  "expiration_date": "2024-02-08"
}, {
  "vmware_agreement": "00681544",
  "customer_name": "University Health Systems of Eastern Carolina,Inc. d/b/a ECU Health",
  "contract_id": "8284889932091682",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "80917.87",
  "expiration_date": "2024-02-08"
}, {
  "vmware_agreement": "00540869",
  "customer_name": "iQor US Inc.",
  "contract_id": "8284184299887252",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "fees": "283556.98",
  "expiration_date": "2024-03-09"
}, {
  "vmware_agreement": "VMware Agreement #00546122",
  "customer_name": "Verizon",
  "contract_id": "8285664297948557",
  "contract_title": "Verizon Agreement # CW2355752/VMware Agreement #00546122",
  "fees": "0.0",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00673271",
  "customer_name": "City Of Philadelphia DHS",
  "contract_id": "8285096913718184",
  "contract_title": "Updated VMware SRA Agreement #00673271.0 with City Of Philadelphia DHS",
  "fees": "0.0",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00679918",
  "customer_name": "SUNCORP CORPORATE SERVICES P/L",
  "contract_id": "8840424987396134",
  "contract_title": "VMware Success 360 Add-On Support Services Manager - Tier 1, ELA \u0026 Non-ELA, 1 Year. Restricted VMware Approval and Quote.",
  "fees": "1.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00592266",
  "customer_name": "SQUAREPOINT OPS LLC",
  "contract_id": "8285168031912322",
  "contract_title": "VMware Horizon 8 Enterprise Term Edition: 10 NamedUser Pack for 1 year term license; includes ProductionSupport/Subscription",
  "fees": "0.0",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00592266",
  "customer_name": "SQUAREPOINT OPS LLC",
  "contract_id": "8285663732669925",
  "contract_title": "VMware Horizon 8 Enterprise Term Edition: 10 NamedUser Pack for 1 year term license; includes ProductionSupport/Subscription",
  "fees": "0.0",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00696176",
  "customer_name": "Department of Defence",
  "contract_id": "8284674337554069",
  "contract_title": "VMware Horizon 8 Enterprise Term Edition: 10 Concurrent User Pack for 1year term license; includes Production Support/Subscription",
  "fees": "31454.68",
  "expiration_date": "2024-03-21"
}];

export const DummyData4: IDataSourceObj[] = [{
  "vmware_agreement": "00545510",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285485009553750",
  "contract_title": "ORDER FORM",
  "spp_credits": "10377"
}, {
  "vmware_agreement": "00333645",
  "customer_name": "Tektronix, Inc",
  "contract_id": "8285909919937808",
  "contract_title": "Order Form",
  "spp_credits": "12000"
}, {
  "vmware_agreement": "00368261",
  "customer_name": "Koopman Lumber",
  "contract_id": "8285144201633022",
  "contract_title": "Order Form",
  "spp_credits": "36094"
}, {
  "vmware_agreement": "00610801",
  "customer_name": "Asurint",
  "contract_id": "8285071934567889",
  "contract_title": "Order Form",
  "spp_credits": "19124"
}, {
  "vmware_agreement": "00360005",
  "customer_name": "Centura Health",
  "contract_id": "8285072224942636",
  "contract_title": "Centura Health",
  "spp_credits": "18085"
}, {
  "vmware_agreement": "00187823esm.9_23_2015.v5",
  "customer_name": "Wayne Fueling Systems LLC",
  "contract_id": "8284615045378368",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10200"
}, {
  "vmware_agreement": "003757561",
  "customer_name": "JB Advanced Technology Corporation",
  "contract_id": "8285072334350543",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00397234",
  "customer_name": "Department Of Communities",
  "contract_id": "8285279575954565",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "412375"
}, {
  "vmware_agreement": "00361401",
  "customer_name": "David Yurman Enterprises LLC",
  "contract_id": "8840487711559655",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "11812"
}, {
  "vmware_agreement": "00541090",
  "customer_name": "NSW Department Of Customer Service",
  "contract_id": "8840209632908205",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "31100"
}, {
  "vmware_agreement": "00543648",
  "customer_name": "Offensive Security",
  "contract_id": "8285745690393783",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00545754",
  "customer_name": "AGCO Corporation",
  "contract_id": "8284134343738897",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "14525"
}, {
  "vmware_agreement": "00591482 \u0026 00591379",
  "customer_name": "National Research Corp",
  "contract_id": "9429038174254204",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "44649"
}, {
  "vmware_agreement": "00607217",
  "customer_name": "Avalara Inc.",
  "contract_id": "8286555252404354",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "40074"
}, {
  "vmware_agreement": "006091481",
  "customer_name": "Department of Health",
  "contract_id": "8285121000290894",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "11344"
}, {
  "vmware_agreement": "00610646",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285372864114861",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "8451"
}, {
  "vmware_agreement": "00607221",
  "customer_name": "Advanced Energy Industries, Inc. (Main)",
  "contract_id": "8284445241216088",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "33444"
}, {
  "vmware_agreement": "00610373",
  "customer_name": "EARLY WARNING SERVICES, LLC *",
  "contract_id": "8285043482127305",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "67997"
}, {
  "vmware_agreement": "00617847 \u0026 # 00612020",
  "customer_name": "Eisai, Inc.",
  "contract_id": "8285358126686170",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "14219"
}, {
  "vmware_agreement": "00631913",
  "customer_name": "Matrix Applications",
  "contract_id": "8286686396742459",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "32250"
}, {
  "vmware_agreement": "00494975",
  "customer_name": "MSC, Inc",
  "contract_id": "8284957527850972",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "111201"
}, {
  "vmware_agreement": "00673306 \u0026 00673122",
  "customer_name": "Rubrik Inc",
  "contract_id": "8285043058373209",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "42650"
}, {
  "vmware_agreement": "00683770",
  "customer_name": "Ringcentral Inc",
  "contract_id": "8285746503724836",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "8500"
}, {
  "vmware_agreement": "00549405",
  "customer_name": "SANS Institute",
  "contract_id": "8284543974316498",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "43126"
}, {
  "vmware_agreement": "00631930",
  "customer_name": "Matrix Applications",
  "contract_id": "8285240892919055",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "36522"
}, {
  "vmware_agreement": "00676017",
  "customer_name": "Conway Medical Center",
  "contract_id": "8284806419785684",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "55062"
}, {
  "vmware_agreement": "00679379",
  "customer_name": "BROCK SOLUTIONS INC",
  "contract_id": "8284815238193325",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "20230180"
}, {
  "vmware_agreement": "00541783",
  "customer_name": "PaySpan",
  "contract_id": "8285651254281035",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "18827"
}, {
  "vmware_agreement": "00587649",
  "customer_name": "AMB Group LLC",
  "contract_id": "8284864723112467",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "12623"
}, {
  "vmware_agreement": "00685130",
  "customer_name": "Boeing",
  "contract_id": "8284683603190343",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "9000"
}, {
  "vmware_agreement": "00186818",
  "customer_name": "California Highway Patrol (CHP)",
  "contract_id": "8284427555827875",
  "contract_title": "CHP ELA FY16Q1 v1",
  "spp_credits": "11000"
}, {
  "vmware_agreement": "00362272",
  "customer_name": "Health-Quest Systems, Inc.",
  "contract_id": "8285059532376641",
  "contract_title": "VMC AWS ORDER FORM",
  "spp_credits": "9500"
}, {
  "vmware_agreement": "00509312",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8284958153796450",
  "contract_title": "VMware SPP Agreement",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "00566977",
  "customer_name": "Citrix Systems",
  "contract_id": "8840413967320726",
  "contract_title": "VMware SPP Agreement",
  "spp_credits": "41040"
}, {
  "vmware_agreement": "00446289",
  "customer_name": "CenlTex",
  "contract_id": "8285179342652808",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "12195"
}, {
  "vmware_agreement": "00447888",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285233954938027",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "11927"
}, {
  "vmware_agreement": "00360335",
  "customer_name": "Eisai",
  "contract_id": "8284638064408381",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "9181"
}, {
  "vmware_agreement": "00552323",
  "customer_name": "Dealerbuilt Lightyear Dealer",
  "contract_id": "8285455801144785",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "27700"
}, {
  "vmware_agreement": "00554889",
  "customer_name": "Summitt Energy L.P.",
  "contract_id": "8285791316651941",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "19700"
}, {
  "vmware_agreement": "00590551",
  "customer_name": "TECHSOUP GLOBAL",
  "contract_id": "8284829115202680",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "61000"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8284195515360653",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "18000"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8285071112513777",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "18000"
}, {
  "vmware_agreement": "00431989",
  "customer_name": "TECHSOUP GLOBAL",
  "contract_id": "8284935597053185",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "57994"
}, {
  "vmware_agreement": "00494799",
  "customer_name": "Merck \u0026 Co. Inc",
  "contract_id": "8285640772996290",
  "contract_title": "AMENDED ELA ORDER FORM",
  "spp_credits": "18720"
}, {
  "vmware_agreement": "00496687",
  "customer_name": "New York City Health and Hospitals Corporation",
  "contract_id": "9428934098353041",
  "contract_title": "AMENDED ELA ORDER FORM",
  "spp_credits": "9200"
}, {
  "vmware_agreement": "00708800",
  "customer_name": "Fortinet Inc",
  "contract_id": "8284865924472930",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "400000"
}, {
  "vmware_agreement": "00678371",
  "customer_name": "Fidelity Technology Group, LLC",
  "contract_id": "8285059424125902",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "300000"
}, {
  "vmware_agreement": "00708776",
  "customer_name": "Catalyst Corporate Federal Credit Union",
  "contract_id": "8840444582507431",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "11554"
}, {
  "vmware_agreement": "00600105",
  "customer_name": "DISH Purchasing Corporation",
  "contract_id": "8285234532205543",
  "contract_title": "Network License Agreement",
  "spp_credits": "23185"
}, {
  "vmware_agreement": "00358127",
  "customer_name": "Hydro-Quebec-Parent *",
  "contract_id": "8285494402760936",
  "contract_title": "VMC ON AWS ELA ORDER FORM",
  "spp_credits": "38552"
}, {
  "vmware_agreement": "00685149",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "8284613943562515",
  "contract_title": "VMWare Credits Organon TSA",
  "spp_credits": "15730"
}, {
  "vmware_agreement": "00388043",
  "customer_name": "DaVita Inc.",
  "contract_id": "8285071062849957",
  "contract_title": "Amendment No. 9 to ELA Order",
  "spp_credits": "164210"
}, {
  "vmware_agreement": "00218902",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285145446422455",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "11962"
}, {
  "vmware_agreement": "00188249",
  "customer_name": "American Tire Distributors",
  "contract_id": "8284566056050455",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "005402171",
  "customer_name": "Toll Holdings Limited",
  "contract_id": "8285034038384498",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "17132"
}, {
  "vmware_agreement": "00679670",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8285279691395703",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "44000"
}, {
  "vmware_agreement": "00483560",
  "customer_name": "Neighborhood Health Plan of Rhode Island",
  "contract_id": "8840321755253656",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "13783"
}, {
  "vmware_agreement": "00622007",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8840080428099162",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "31128"
}, {
  "vmware_agreement": "00679546",
  "customer_name": "U.S.Venture, Inc.",
  "contract_id": "8286733898432528",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "28228211"
}, {
  "vmware_agreement": "00557751",
  "customer_name": "Canada Pension Plan Investment Board",
  "contract_id": "8285098479283436",
  "contract_title": "Amendment No. 6 to the ELA Order",
  "spp_credits": "27263"
}, {
  "vmware_agreement": "00694458",
  "customer_name": "Massachusetts Institute of Technology",
  "contract_id": "8285178456339141",
  "contract_title": "Amendment No. 6 to the Order Form",
  "spp_credits": "8391"
}, {
  "vmware_agreement": "00431292",
  "customer_name": "Potlatch Deltic Corporation",
  "contract_id": "8285522458592487",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "8767"
}, {
  "vmware_agreement": "00689518",
  "customer_name": "Quest Diagnostics Inc",
  "contract_id": "8285009312912749",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00646049",
  "customer_name": "Agilysys Inc",
  "contract_id": "8284816831051487",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "300000"
}, {
  "vmware_agreement": "00372377",
  "customer_name": "Ochsner Clinic Foundation",
  "contract_id": "8285510616217811",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "32810"
}, {
  "vmware_agreement": "00274824",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285908659683239",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "spp_credits": "193948"
}, {
  "vmware_agreement": "00361009",
  "customer_name": "Data Recognition Corporation",
  "contract_id": "8840424984395680",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "spp_credits": "9784"
}, {
  "vmware_agreement": "00360740",
  "customer_name": "Navigant Consulting Inc",
  "contract_id": "8285272220312963",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "11247"
}, {
  "vmware_agreement": "00694696",
  "customer_name": "Deloitte Touche Tohmatsu Services, LLC as agent for its parent, Deloitte Global Services Limited",
  "contract_id": "8840081049514445",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "25323"
}, {
  "vmware_agreement": "00641318",
  "customer_name": "CommunityAmerica Credit Union",
  "contract_id": "8840413958480196",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "12024"
}, {
  "vmware_agreement": "00686992",
  "customer_name": "OneOK, Inc",
  "contract_id": "8286587598863776",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "161816107"
}, {
  "vmware_agreement": "005901771",
  "customer_name": "ExlService Holdings, Inc.",
  "contract_id": "8285429919641226",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "spp_credits": "12410"
}, {
  "vmware_agreement": "00591804",
  "customer_name": "Mitchell International Inc",
  "contract_id": "8285484035403565",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "spp_credits": "10221"
}, {
  "vmware_agreement": "00691269",
  "customer_name": "Q2 Software, Inc.",
  "contract_id": "8284937031837839",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "spp_credits": "8279"
}, {
  "vmware_agreement": "00707408",
  "customer_name": "Baylor Health Care System by its agent Baylor Scott \u0026 White Health",
  "contract_id": "8285179392650458",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "spp_credits": "58524"
}, {
  "vmware_agreement": "00643363",
  "customer_name": "Factset Research Systems Inc",
  "contract_id": "9429038154124119",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "spp_credits": "158514512"
}, {
  "vmware_agreement": "00643363",
  "customer_name": "Factset Research Systems Inc",
  "contract_id": "8285897652717493",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "spp_credits": "158514512"
}, {
  "vmware_agreement": "00541762",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285572923326487",
  "contract_title": "Amendment No. 14 to the ELA Order Form",
  "spp_credits": "8750"
}, {
  "vmware_agreement": "00369106",
  "customer_name": "Mgmc",
  "contract_id": "8284815732887271",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "53078"
}, {
  "vmware_agreement": "00497604",
  "customer_name": "Select Medical Corporation",
  "contract_id": "8285625596075149",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14434082"
}, {
  "vmware_agreement": "00607801",
  "customer_name": "Adventist Health System Sunbelt Healthcare Corporation",
  "contract_id": "8284348645929871",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "10300"
}, {
  "vmware_agreement": "00679899",
  "customer_name": "MARS",
  "contract_id": "8285495548394257",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14500000"
}, {
  "vmware_agreement": "006846181",
  "customer_name": "Precision Castparts Corp.",
  "contract_id": "8285032245912192",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "41000"
}, {
  "vmware_agreement": "00551811",
  "customer_name": "Universal Health Services, Inc",
  "contract_id": "8284972303178714",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "11500"
}, {
  "vmware_agreement": "00684829 \u0026 00691196",
  "customer_name": "New York Life Insurance Company",
  "contract_id": "8285495023424230",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14024"
}, {
  "vmware_agreement": "00679122",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "9429038173673108",
  "contract_title": "2022 VMware 3 Yr Q-E00431219-00679122-Merck",
  "spp_credits": "10500"
}, {
  "vmware_agreement": "00539357",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8285483619345449",
  "contract_title": "VMware Subscription Purchasing Program Credits",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "002178831",
  "customer_name": "HGST, INC.",
  "contract_id": "8285178701964548",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "12001"
}, {
  "vmware_agreement": "00392960",
  "customer_name": "Kronos Incorporated",
  "contract_id": "8286733895981603",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "755100"
}, {
  "vmware_agreement": "00308778",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285291123188841",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "193948"
}, {
  "vmware_agreement": "00374696",
  "customer_name": "EQUINIX, INC.",
  "contract_id": "8284359383124928",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "150683"
}, {
  "vmware_agreement": "00503243",
  "customer_name": "S\u0026P Global",
  "contract_id": "8285624967859043",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "23065"
}, {
  "vmware_agreement": "00597044",
  "customer_name": "Merck \u0026 Co Inc",
  "contract_id": "8285205819597410",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "15500"
}, {
  "vmware_agreement": "00489383",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284740992439926",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "10943"
}, {
  "vmware_agreement": "00606272 \u0026 00606220",
  "customer_name": "BROADRIDGE FINANCIAL SOLUTIONS INC.",
  "contract_id": "8285377807560281",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "8830"
}, {
  "vmware_agreement": "00609870",
  "customer_name": "Western Australian Local Government Association",
  "contract_id": "8285204059758992",
  "contract_title": "Western Australian Local Government Association",
  "spp_credits": "91980"
}, {
  "vmware_agreement": "00682896",
  "customer_name": "Texas Children\u0027s Hospital",
  "contract_id": "8285830456706240",
  "contract_title": "Amendment No. 6 to the Enterprise License Agreement",
  "spp_credits": "15000"
}, {
  "vmware_agreement": "00209726",
  "customer_name": "Whirlpool Corporation",
  "contract_id": "8285763283526775",
  "contract_title": "REVISED AND RESTATED AMENDMENT NO. 4 TO THE ELA ORDER",
  "spp_credits": "220000"
}, {
  "vmware_agreement": "00567291",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8284614003355790",
  "contract_title": "VMware on AWS Subscription Purchasing Program Credits",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "00480713",
  "customer_name": "Cenitex",
  "contract_id": "8285144423273019",
  "contract_title": "VMware Order Form - 822076-Cenitex-00480713.0_CLEAN V1",
  "spp_credits": "18365"
}, {
  "vmware_agreement": "00608686",
  "customer_name": "Verizon",
  "contract_id": "8840304065185227",
  "contract_title": "Verizon Agreement # CW2362316 / VMware Agreement #00608686",
  "spp_credits": "58375"
}, {
  "vmware_agreement": "00672898",
  "customer_name": "Seek Limited",
  "contract_id": "8285071405246592",
  "contract_title": "Quote_3990864_\u0026_Payment_Terms_VMware ELA - Option 2 - vCloud Suite",
  "spp_credits": "10542"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285085827417093",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "spp_credits": "19661"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285420169337493",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "spp_credits": "19661"
}, {
  "vmware_agreement": "VMW-11857",
  "customer_name": "Toyota Motor Credit Corporation",
  "contract_id": "8286717099803750",
  "contract_title": "Amendment No. 1 (Contract ID: REQ1243157) to the ELA Order Form (REQ1068444) to Master Software License Agreement (Contract ID: VMW-11857)",
  "spp_credits": "200000"
}];
export const DummyData5: IDataSourceObj[] = [{
  "vmware_agreement": "00545510",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285485009553750",
  "contract_title": "ORDER FORM",
  "spp_credits": "10377"
}, {
  "vmware_agreement": "00333645",
  "customer_name": "Tektronix, Inc",
  "contract_id": "8285909919937808",
  "contract_title": "Order Form",
  "spp_credits": "12000"
}, {
  "vmware_agreement": "00368261",
  "customer_name": "Koopman Lumber",
  "contract_id": "8285144201633022",
  "contract_title": "Order Form",
  "spp_credits": "36094"
}, {
  "vmware_agreement": "00610801",
  "customer_name": "Asurint",
  "contract_id": "8285071934567889",
  "contract_title": "Order Form",
  "spp_credits": "19124"
}, {
  "vmware_agreement": "00360005",
  "customer_name": "Centura Health",
  "contract_id": "8285072224942636",
  "contract_title": "Centura Health",
  "spp_credits": "18085"
}, {
  "vmware_agreement": "00187823esm.9_23_2015.v5",
  "customer_name": "Wayne Fueling Systems LLC",
  "contract_id": "8284615045378368",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10200"
}, {
  "vmware_agreement": "003757561",
  "customer_name": "JB Advanced Technology Corporation",
  "contract_id": "8285072334350543",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00397234",
  "customer_name": "Department Of Communities",
  "contract_id": "8285279575954565",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "412375"
}, {
  "vmware_agreement": "00361401",
  "customer_name": "David Yurman Enterprises LLC",
  "contract_id": "8840487711559655",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "11812"
}, {
  "vmware_agreement": "00541090",
  "customer_name": "NSW Department Of Customer Service",
  "contract_id": "8840209632908205",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "31100"
}, {
  "vmware_agreement": "00543648",
  "customer_name": "Offensive Security",
  "contract_id": "8285745690393783",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00545754",
  "customer_name": "AGCO Corporation",
  "contract_id": "8284134343738897",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "14525"
}, {
  "vmware_agreement": "00591482 \u0026 00591379",
  "customer_name": "National Research Corp",
  "contract_id": "9429038174254204",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "44649"
}, {
  "vmware_agreement": "00607217",
  "customer_name": "Avalara Inc.",
  "contract_id": "8286555252404354",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "40074"
}, {
  "vmware_agreement": "006091481",
  "customer_name": "Department of Health",
  "contract_id": "8285121000290894",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "11344"
}, {
  "vmware_agreement": "00610646",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285372864114861",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "8451"
}, {
  "vmware_agreement": "00607221",
  "customer_name": "Advanced Energy Industries, Inc. (Main)",
  "contract_id": "8284445241216088",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "33444"
}, {
  "vmware_agreement": "00610373",
  "customer_name": "EARLY WARNING SERVICES, LLC *",
  "contract_id": "8285043482127305",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "67997"
}, {
  "vmware_agreement": "00617847 \u0026 # 00612020",
  "customer_name": "Eisai, Inc.",
  "contract_id": "8285358126686170",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "14219"
}, {
  "vmware_agreement": "00631913",
  "customer_name": "Matrix Applications",
  "contract_id": "8286686396742459",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "32250"
}, {
  "vmware_agreement": "00494975",
  "customer_name": "MSC, Inc",
  "contract_id": "8284957527850972",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "111201"
}, {
  "vmware_agreement": "00673306 \u0026 00673122",
  "customer_name": "Rubrik Inc",
  "contract_id": "8285043058373209",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "42650"
}, {
  "vmware_agreement": "00683770",
  "customer_name": "Ringcentral Inc",
  "contract_id": "8285746503724836",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "8500"
}, {
  "vmware_agreement": "00549405",
  "customer_name": "SANS Institute",
  "contract_id": "8284543974316498",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "43126"
}, {
  "vmware_agreement": "00631930",
  "customer_name": "Matrix Applications",
  "contract_id": "8285240892919055",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "36522"
}, {
  "vmware_agreement": "00676017",
  "customer_name": "Conway Medical Center",
  "contract_id": "8284806419785684",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "55062"
}, {
  "vmware_agreement": "00679379",
  "customer_name": "BROCK SOLUTIONS INC",
  "contract_id": "8284815238193325",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "20230180"
}, {
  "vmware_agreement": "00541783",
  "customer_name": "PaySpan",
  "contract_id": "8285651254281035",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "18827"
}, {
  "vmware_agreement": "00587649",
  "customer_name": "AMB Group LLC",
  "contract_id": "8284864723112467",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "12623"
}, {
  "vmware_agreement": "00685130",
  "customer_name": "Boeing",
  "contract_id": "8284683603190343",
  "contract_title": "ELA ORDER FORM",
  "spp_credits": "9000"
}, {
  "vmware_agreement": "00186818",
  "customer_name": "California Highway Patrol (CHP)",
  "contract_id": "8284427555827875",
  "contract_title": "CHP ELA FY16Q1 v1",
  "spp_credits": "11000"
}, {
  "vmware_agreement": "00362272",
  "customer_name": "Health-Quest Systems, Inc.",
  "contract_id": "8285059532376641",
  "contract_title": "VMC AWS ORDER FORM",
  "spp_credits": "9500"
}, {
  "vmware_agreement": "00509312",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8284958153796450",
  "contract_title": "VMware SPP Agreement",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "00566977",
  "customer_name": "Citrix Systems",
  "contract_id": "8840413967320726",
  "contract_title": "VMware SPP Agreement",
  "spp_credits": "41040"
}, {
  "vmware_agreement": "00446289",
  "customer_name": "CenlTex",
  "contract_id": "8285179342652808",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "12195"
}, {
  "vmware_agreement": "00447888",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285233954938027",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "11927"
}, {
  "vmware_agreement": "00360335",
  "customer_name": "Eisai",
  "contract_id": "8284638064408381",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "9181"
}, {
  "vmware_agreement": "00552323",
  "customer_name": "Dealerbuilt Lightyear Dealer",
  "contract_id": "8285455801144785",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "27700"
}, {
  "vmware_agreement": "00554889",
  "customer_name": "Summitt Energy L.P.",
  "contract_id": "8285791316651941",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "19700"
}, {
  "vmware_agreement": "00590551",
  "customer_name": "TECHSOUP GLOBAL",
  "contract_id": "8284829115202680",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "61000"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8284195515360653",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "18000"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8285071112513777",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "18000"
}, {
  "vmware_agreement": "00431989",
  "customer_name": "TECHSOUP GLOBAL",
  "contract_id": "8284935597053185",
  "contract_title": "VMware SPP Order Form",
  "spp_credits": "57994"
}, {
  "vmware_agreement": "00494799",
  "customer_name": "Merck \u0026 Co. Inc",
  "contract_id": "8285640772996290",
  "contract_title": "AMENDED ELA ORDER FORM",
  "spp_credits": "18720"
}, {
  "vmware_agreement": "00496687",
  "customer_name": "New York City Health and Hospitals Corporation",
  "contract_id": "9428934098353041",
  "contract_title": "AMENDED ELA ORDER FORM",
  "spp_credits": "9200"
}, {
  "vmware_agreement": "00708800",
  "customer_name": "Fortinet Inc",
  "contract_id": "8284865924472930",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "400000"
}, {
  "vmware_agreement": "00678371",
  "customer_name": "Fidelity Technology Group, LLC",
  "contract_id": "8285059424125902",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "300000"
}, {
  "vmware_agreement": "00708776",
  "customer_name": "Catalyst Corporate Federal Credit Union",
  "contract_id": "8840444582507431",
  "contract_title": "VMware Enterprise Order",
  "spp_credits": "11554"
}, {
  "vmware_agreement": "00600105",
  "customer_name": "DISH Purchasing Corporation",
  "contract_id": "8285234532205543",
  "contract_title": "Network License Agreement",
  "spp_credits": "23185"
}, {
  "vmware_agreement": "00358127",
  "customer_name": "Hydro-Quebec-Parent *",
  "contract_id": "8285494402760936",
  "contract_title": "VMC ON AWS ELA ORDER FORM",
  "spp_credits": "38552"
}, {
  "vmware_agreement": "00685149",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "8284613943562515",
  "contract_title": "VMWare Credits Organon TSA",
  "spp_credits": "15730"
}, {
  "vmware_agreement": "00388043",
  "customer_name": "DaVita Inc.",
  "contract_id": "8285071062849957",
  "contract_title": "Amendment No. 9 to ELA Order",
  "spp_credits": "164210"
}, {
  "vmware_agreement": "00218902",
  "customer_name": "American Airlines, Inc",
  "contract_id": "8285145446422455",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "11962"
}, {
  "vmware_agreement": "00188249",
  "customer_name": "American Tire Distributors",
  "contract_id": "8284566056050455",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "005402171",
  "customer_name": "Toll Holdings Limited",
  "contract_id": "8285034038384498",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "17132"
}, {
  "vmware_agreement": "00679670",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8285279691395703",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "44000"
}, {
  "vmware_agreement": "00483560",
  "customer_name": "Neighborhood Health Plan of Rhode Island",
  "contract_id": "8840321755253656",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "13783"
}, {
  "vmware_agreement": "00622007",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8840080428099162",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "31128"
}, {
  "vmware_agreement": "00679546",
  "customer_name": "U.S.Venture, Inc.",
  "contract_id": "8286733898432528",
  "contract_title": "Enterprise License Agreement",
  "spp_credits": "28228211"
}, {
  "vmware_agreement": "00557751",
  "customer_name": "Canada Pension Plan Investment Board",
  "contract_id": "8285098479283436",
  "contract_title": "Amendment No. 6 to the ELA Order",
  "spp_credits": "27263"
}, {
  "vmware_agreement": "00694458",
  "customer_name": "Massachusetts Institute of Technology",
  "contract_id": "8285178456339141",
  "contract_title": "Amendment No. 6 to the Order Form",
  "spp_credits": "8391"
}, {
  "vmware_agreement": "00431292",
  "customer_name": "Potlatch Deltic Corporation",
  "contract_id": "8285522458592487",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "8767"
}, {
  "vmware_agreement": "00689518",
  "customer_name": "Quest Diagnostics Inc",
  "contract_id": "8285009312912749",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "10000"
}, {
  "vmware_agreement": "00646049",
  "customer_name": "Agilysys Inc",
  "contract_id": "8284816831051487",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "300000"
}, {
  "vmware_agreement": "00372377",
  "customer_name": "Ochsner Clinic Foundation",
  "contract_id": "8285510616217811",
  "contract_title": "VMware Enterprise License Agreement",
  "spp_credits": "32810"
}, {
  "vmware_agreement": "00274824",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285908659683239",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "spp_credits": "193948"
}, {
  "vmware_agreement": "00361009",
  "customer_name": "Data Recognition Corporation",
  "contract_id": "8840424984395680",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "spp_credits": "9784"
}, {
  "vmware_agreement": "00360740",
  "customer_name": "Navigant Consulting Inc",
  "contract_id": "8285272220312963",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "11247"
}, {
  "vmware_agreement": "00694696",
  "customer_name": "Deloitte Touche Tohmatsu Services, LLC as agent for its parent, Deloitte Global Services Limited",
  "contract_id": "8840081049514445",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "25323"
}, {
  "vmware_agreement": "00641318",
  "customer_name": "CommunityAmerica Credit Union",
  "contract_id": "8840413958480196",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "12024"
}, {
  "vmware_agreement": "00686992",
  "customer_name": "OneOK, Inc",
  "contract_id": "8286587598863776",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "spp_credits": "161816107"
}, {
  "vmware_agreement": "005901771",
  "customer_name": "ExlService Holdings, Inc.",
  "contract_id": "8285429919641226",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "spp_credits": "12410"
}, {
  "vmware_agreement": "00591804",
  "customer_name": "Mitchell International Inc",
  "contract_id": "8285484035403565",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "spp_credits": "10221"
}, {
  "vmware_agreement": "00691269",
  "customer_name": "Q2 Software, Inc.",
  "contract_id": "8284937031837839",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "spp_credits": "8279"
}, {
  "vmware_agreement": "00707408",
  "customer_name": "Baylor Health Care System by its agent Baylor Scott \u0026 White Health",
  "contract_id": "8285179392650458",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "spp_credits": "58524"
}, {
  "vmware_agreement": "00643363",
  "customer_name": "Factset Research Systems Inc",
  "contract_id": "9429038154124119",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "spp_credits": "158514512"
}, {
  "vmware_agreement": "00643363",
  "customer_name": "Factset Research Systems Inc",
  "contract_id": "8285897652717493",
  "contract_title": "Amendment No. 12 to the ELA Order Form",
  "spp_credits": "158514512"
}, {
  "vmware_agreement": "00541762",
  "customer_name": "Service Benefit Plan Administration Services Corporation",
  "contract_id": "8285572923326487",
  "contract_title": "Amendment No. 14 to the ELA Order Form",
  "spp_credits": "8750"
}, {
  "vmware_agreement": "00369106",
  "customer_name": "Mgmc",
  "contract_id": "8284815732887271",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "53078"
}, {
  "vmware_agreement": "00497604",
  "customer_name": "Select Medical Corporation",
  "contract_id": "8285625596075149",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14434082"
}, {
  "vmware_agreement": "00607801",
  "customer_name": "Adventist Health System Sunbelt Healthcare Corporation",
  "contract_id": "8284348645929871",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "10300"
}, {
  "vmware_agreement": "00679899",
  "customer_name": "MARS",
  "contract_id": "8285495548394257",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14500000"
}, {
  "vmware_agreement": "006846181",
  "customer_name": "Precision Castparts Corp.",
  "contract_id": "8285032245912192",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "41000"
}, {
  "vmware_agreement": "00551811",
  "customer_name": "Universal Health Services, Inc",
  "contract_id": "8284972303178714",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "11500"
}, {
  "vmware_agreement": "00684829 \u0026 00691196",
  "customer_name": "New York Life Insurance Company",
  "contract_id": "8285495023424230",
  "contract_title": "Enterprise License Agreement Order Form",
  "spp_credits": "14024"
}, {
  "vmware_agreement": "00679122",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "9429038173673108",
  "contract_title": "2022 VMware 3 Yr Q-E00431219-00679122-Merck",
  "spp_credits": "10500"
}, {
  "vmware_agreement": "00539357",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8285483619345449",
  "contract_title": "VMware Subscription Purchasing Program Credits",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "002178831",
  "customer_name": "HGST, INC.",
  "contract_id": "8285178701964548",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "12001"
}, {
  "vmware_agreement": "00392960",
  "customer_name": "Kronos Incorporated",
  "contract_id": "8286733895981603",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "755100"
}, {
  "vmware_agreement": "00308778",
  "customer_name": "The Ohio State University on behalf of Ohio Academic Resources Network (OARnet)",
  "contract_id": "8285291123188841",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "193948"
}, {
  "vmware_agreement": "00374696",
  "customer_name": "EQUINIX, INC.",
  "contract_id": "8284359383124928",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "150683"
}, {
  "vmware_agreement": "00503243",
  "customer_name": "S\u0026P Global",
  "contract_id": "8285624967859043",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "23065"
}, {
  "vmware_agreement": "00597044",
  "customer_name": "Merck \u0026 Co Inc",
  "contract_id": "8285205819597410",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "15500"
}, {
  "vmware_agreement": "00489383",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284740992439926",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "10943"
}, {
  "vmware_agreement": "00606272 \u0026 00606220",
  "customer_name": "BROADRIDGE FINANCIAL SOLUTIONS INC.",
  "contract_id": "8285377807560281",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT ORDER FORM",
  "spp_credits": "8830"
}, {
  "vmware_agreement": "00609870",
  "customer_name": "Western Australian Local Government Association",
  "contract_id": "8285204059758992",
  "contract_title": "Western Australian Local Government Association",
  "spp_credits": "91980"
}, {
  "vmware_agreement": "00682896",
  "customer_name": "Texas Children\u0027s Hospital",
  "contract_id": "8285830456706240",
  "contract_title": "Amendment No. 6 to the Enterprise License Agreement",
  "spp_credits": "15000"
}, {
  "vmware_agreement": "00209726",
  "customer_name": "Whirlpool Corporation",
  "contract_id": "8285763283526775",
  "contract_title": "REVISED AND RESTATED AMENDMENT NO. 4 TO THE ELA ORDER",
  "spp_credits": "220000"
}, {
  "vmware_agreement": "00567291",
  "customer_name": "Crowley Maritime Corporation",
  "contract_id": "8284614003355790",
  "contract_title": "VMware on AWS Subscription Purchasing Program Credits",
  "spp_credits": "41560"
}, {
  "vmware_agreement": "00480713",
  "customer_name": "Cenitex",
  "contract_id": "8285144423273019",
  "contract_title": "VMware Order Form - 822076-Cenitex-00480713.0_CLEAN V1",
  "spp_credits": "18365"
}, {
  "vmware_agreement": "00608686",
  "customer_name": "Verizon",
  "contract_id": "8840304065185227",
  "contract_title": "Verizon Agreement # CW2362316 / VMware Agreement #00608686",
  "spp_credits": "58375"
}, {
  "vmware_agreement": "00672898",
  "customer_name": "Seek Limited",
  "contract_id": "8285071405246592",
  "contract_title": "Quote_3990864_\u0026_Payment_Terms_VMware ELA - Option 2 - vCloud Suite",
  "spp_credits": "10542"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285085827417093",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "spp_credits": "19661"
}, {
  "vmware_agreement": "00600117",
  "customer_name": "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
  "contract_id": "8285420169337493",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT SCHEDULE #1 TO THE MASTER AGREEMENT",
  "spp_credits": "19661"
}, {
  "vmware_agreement": "VMW-11857",
  "customer_name": "Toyota Motor Credit Corporation",
  "contract_id": "8286717099803750",
  "contract_title": "Amendment No. 1 (Contract ID: REQ1243157) to the ELA Order Form (REQ1068444) to Master Software License Agreement (Contract ID: VMW-11857)",
  "spp_credits": "200000"
}];
export const DummyData6: IDataSourceObj[] = [{
  "contract_count": "450",
  "contract_type": "ELA",
  "year": "2020"
}, {
  "contract_count": "58",
  "contract_type": "ELA",
  "year": "2016"
}, {
  "contract_count": "91",
  "contract_type": "ELA",
  "year": "2015"
}, {
  "contract_count": "469",
  "contract_type": "ELA",
  "year": "2021"
}, {
  "contract_count": "418",
  "contract_type": "ELA",
  "year": "2019"
}, {
  "contract_count": "67",
  "contract_type": "ELA",
  "year": "2018"
}, {
  "contract_count": "382",
  "contract_type": "ELA",
  "year": "2022"
}, {
  "contract_count": "166",
  "contract_type": "ELA",
  "year": "2023"
}, {
  "contract_count": "8",
  "contract_type": "ELA",
  "year": "2017"
}, {
  "contract_count": "373",
  "contract_type": "SRA",
  "year": "2021"
}, {
  "contract_count": "418",
  "contract_type": "SRA",
  "year": "2020"
}, {
  "contract_count": "339",
  "contract_type": "SRA",
  "year": "2019"
}, {
  "contract_count": "179",
  "contract_type": "SRA",
  "year": "2023"
}, {
  "contract_count": "399",
  "contract_type": "SRA",
  "year": "2022"
}, {
  "contract_count": "54",
  "contract_type": "SRA",
  "year": "2018"
}, {
  "contract_count": "26",
  "contract_type": "SRA",
  "year": "2015"
}, {
  "contract_count": "22",
  "contract_type": "SRA",
  "year": "2016"
}, {
  "contract_count": "2",
  "contract_type": "SRA",
  "year": "2017"
}];
export const DummyData7: IDataSourceObj[] = [{
  "vmware_agreement": "VMware Agreement # 00551324",
  "customer_name": "Kanematsu Electronics Ltd.",
  "contract_id": "8286768514767525",
  "contract_title": "VMware ELA",
  "fees": "3254000.0",
  "expiration_date": "2023-04-27"
}, {
  "vmware_agreement": "00441330",
  "customer_name": "Sterling Infosystems (Main)",
  "contract_id": "8285129461838287",
  "contract_title": "ELA ORDER FORM",
  "fees": "199244.7",
  "expiration_date": "2023-03-25"
}, {
  "vmware_agreement": "00540850",
  "customer_name": "Coppel SA de CV",
  "contract_id": "8840452035819166",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "317530.26",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00638532",
  "customer_name": "FUJITSU FSAS INC",
  "contract_id": "8285586500996044",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "600000.0",
  "expiration_date": "2023-07-04"
}, {
  "vmware_agreement": "VMware Agreement # 00492417",
  "customer_name": "The Shizuoka Bank Ltd",
  "contract_id": "8285721267842887",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4000000.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00379497",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8285802630868778",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "98474405.0",
  "expiration_date": "2023-05-31"
}, {
  "vmware_agreement": "00620461",
  "customer_name": "RICOH COMPANY LTD",
  "contract_id": "8285345054240627",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "38650000.0",
  "expiration_date": "2023-03-11"
}, {
  "vmware_agreement": "VMware Agreement #00378149",
  "customer_name": "JR WEST IT SOLUTIONS COMPANY",
  "contract_id": "8286717599427399",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "28001899.0",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "VMware Authorized Distributor Program Agreement effective as of June 1, 2015 and VMware Distributor Authorization for VMware Subscription Services effective as of June 1, 2015",
  "customer_name": "NISSAN MOTOR CO.,LTD.",
  "contract_id": "8284702542683141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "700000.0",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00680594",
  "customer_name": "Urban One Inc",
  "contract_id": "8286821546019953",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132600.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00375593",
  "customer_name": "SAGA-KEN MEDICAL CENTRE KOSEIKANE",
  "contract_id": "8285617638292962",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "71612419.0",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "VMware Agreement # 00611264",
  "customer_name": "East West Banking Corporation",
  "contract_id": "8286236532407405",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "190889.86",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00457435",
  "customer_name": "Banco de Oro Universal Bank",
  "contract_id": "8286070180899511",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "472894.53",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00401194",
  "customer_name": "International Centre For DiarrhoealDisease Research Bangladesh",
  "contract_id": "8285938466972488",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30915.25",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00675213",
  "customer_name": "PHILIPPINE NATIONAL BANK",
  "contract_id": "8285951374812712",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "475333.06",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00503230",
  "customer_name": "Resorts World at Sentosa Pte Ltd",
  "contract_id": "8286161015215722",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "89361.78",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00631498",
  "customer_name": "DOCOMO SYSTEMS, INC.",
  "contract_id": "8285538561277673",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "36592500.0",
  "expiration_date": "2023-04-27"
}, {
  "vmware_agreement": "00638094",
  "customer_name": "KANEMATSU ELECTRONICS CO., LTD",
  "contract_id": "8285087161652274",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "860000.0",
  "expiration_date": "2023-06-02"
}, {
  "vmware_agreement": "00673154",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8285471605277851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00673507",
  "customer_name": "KOCHI CITY",
  "contract_id": "8286618969848244",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "24395500.0",
  "expiration_date": "2023-09-27"
}, {
  "vmware_agreement": "00646071",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8285707469877678",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "34991484.0",
  "expiration_date": "2023-10-14"
}, {
  "vmware_agreement": "00657483",
  "customer_name": "MURAMOTO CORPORATION",
  "contract_id": "8285731563959298",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1980000.0",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00622787",
  "customer_name": "KITAMURA CO., LTD.",
  "contract_id": "8284741902530872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "2880000.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00631531",
  "customer_name": "SUMITOMO LIFE INFORMATION SYSTEMCO.,LTD.",
  "contract_id": "8285939832763703",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "37040500.0",
  "expiration_date": "2023-04-28"
}, {
  "vmware_agreement": "00622158",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286620624303440",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "147000.0",
  "expiration_date": "2023-03-23"
}, {
  "vmware_agreement": "00626767",
  "customer_name": "CABINET OFFICE, GOVERNMENT OF JAPAN",
  "contract_id": "8286303527712444",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "573299329.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00610418",
  "customer_name": "DNP INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8286086324293077",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14804389.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00683650",
  "customer_name": "JAPAN AIRLINES CO., LTD.",
  "contract_id": "8286414546623922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "128845100.0",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00589663",
  "customer_name": "TOSHIBA CORPORATION",
  "contract_id": "8285720422567531",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "313671439.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00424454",
  "customer_name": "Net One Systems Co., Ltd.",
  "contract_id": "8285693887904581",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3957600.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "VMware Agreement # 00430304",
  "customer_name": "AXA Equitable Life Insurance Company",
  "contract_id": "8286203467001911",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1500000.0",
  "expiration_date": "2023-01-01"
}, {
  "vmware_agreement": "00434839",
  "customer_name": "TATA COMMUNICATIONS LIMITED",
  "contract_id": "8285109730736506",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "27350.63",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00434729",
  "customer_name": "TATA COMMUNICATIONS LIMITED",
  "contract_id": "8286734419520180",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "13456.53",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00671791",
  "customer_name": "Lizardbear Tasking Inc",
  "contract_id": "8286346455121415",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "180144.75",
  "expiration_date": "2023-09-08"
}, {
  "vmware_agreement": "VMware Agreement # 00672179",
  "customer_name": "Sarawak Energy Berhad",
  "contract_id": "8286270896937028",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "242606.11",
  "expiration_date": "2023-09-15"
}, {
  "vmware_agreement": "VMware Agreement # 00679251",
  "customer_name": "GREAT EASTERN LIFE ASSURANCE(MALAYSIA) BERHAD",
  "contract_id": "8286505461715251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "143600.84",
  "expiration_date": "2023-11-27"
}, {
  "vmware_agreement": "VMware Agreement # 00498116",
  "customer_name": "NCSOFT",
  "contract_id": "8286283254142110",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "402670.93",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00499038",
  "customer_name": "Woowahan Brothers",
  "contract_id": "8285679079098856",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "148800.89",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00482348",
  "customer_name": "Lottecard",
  "contract_id": "8286160515577961",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "288100.0",
  "expiration_date": "2023-08-18"
}, {
  "vmware_agreement": "VMware Agreement # 004995371",
  "customer_name": "OSDE - Organizacion de Servicios DirectosEmpresarios",
  "contract_id": "8286767784460668",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1553238.15",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00433271",
  "customer_name": "eQ-Technologic India Private Limited",
  "contract_id": "8286320011745176",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "179213.0",
  "expiration_date": "2023-01-20"
}, {
  "vmware_agreement": "VMware Agreement # 00362559",
  "customer_name": "INFOSYS LTD.",
  "contract_id": "8285156796990448",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "248082.38",
  "expiration_date": "2023-04-22"
}, {
  "vmware_agreement": "00434465",
  "customer_name": "Indusind Bank Ltd",
  "contract_id": "8285867325083014",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "240165.67",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "VMware Agreement # 00434409",
  "customer_name": "Indusind Bank Ltd",
  "contract_id": "8285868535100587",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "VMware Agreement # 00574229",
  "customer_name": "Korea Exchange",
  "contract_id": "8286007759123136",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103912.0",
  "expiration_date": "2023-07-26"
}, {
  "vmware_agreement": "VMware Agreement #00373202",
  "customer_name": "O-Bank Co., Ltd王道商業銀行股份有限公司",
  "contract_id": "8285730238878307",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "538996.78",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00357034",
  "customer_name": "KGI Bank凱基商業銀行股份有限公司",
  "contract_id": "8286303756093897",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300000.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00357032",
  "customer_name": "Ctbc Financial Holding Co. Ltd中國信託金融控股股份有限公司",
  "contract_id": "8285762894558131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "599433.52",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, April 3, 2018",
  "customer_name": "LINE Bank Taiwan Limited",
  "contract_id": "8840569498079212",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1811280.27",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "VMware PartnerConnect Agreement, December 11, 2019",
  "customer_name": "MUFG BANK, LTD.",
  "contract_id": "8286876209398908",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "80100.08",
  "expiration_date": "2023-03-05"
}, {
  "vmware_agreement": "00673054",
  "customer_name": "Ek-Chai Distribution System Co. Ltd.",
  "contract_id": "8286147370617986",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "100237.03",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "VMware Agreement # 00433869",
  "customer_name": "Integrated Health Information SystemsPte Ltd",
  "contract_id": "8286358106826339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1023385.65",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "VMware Agreement # 00486510",
  "customer_name": "Singapore Health Services",
  "contract_id": "8286334198110255",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "392098.24",
  "expiration_date": "2023-09-11"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, April 25, 2018",
  "customer_name": "Cgs-Cimb Securities (Singapore) Pte Ltd",
  "contract_id": "8285731184258202",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "71981.0",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00690869",
  "customer_name": "M/S. B. G. Chitale Dairy",
  "contract_id": "8286055563562436",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "30772.06",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "VMware Agreement # 00679911",
  "customer_name": "UltraTech Cement Limited",
  "contract_id": "8285951957363267",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "VMware Agreement # 00434228",
  "customer_name": "Bajaj Allianz General Insurance Company Ltd",
  "contract_id": "8286854142007077",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "VMware Agreement # 004401871",
  "customer_name": "SerComm Corporation中磊電子股份有限公司",
  "contract_id": "8286976627324918",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "291445.88",
  "expiration_date": "2023-03-09"
}, {
  "vmware_agreement": "00684075",
  "customer_name": "Bank Tabungan Pensiunan Nasional",
  "contract_id": "8285707124183310",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "212222.75",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00647060",
  "customer_name": "Komisi Pemberantasan Korupsi KPK",
  "contract_id": "8286159712967474",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "150000.0",
  "expiration_date": "2023-06-29"
}, {
  "vmware_agreement": "00690345",
  "customer_name": "PT. Telekomunikasi Indonesia, Tbk",
  "contract_id": "8285625555736051",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "494453.1",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00684074",
  "customer_name": "PT Bank Mandiri Persero TBK",
  "contract_id": "8285071483448430",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "343944.64",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "VMware Agreement # 00498609",
  "customer_name": "Bank Central Asia TBK",
  "contract_id": "8286538286042067",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "203340.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00478749",
  "customer_name": "PT. Bank Rakyat Indonesia (Persero)Tbk",
  "contract_id": "8286055930478576",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300533.44",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00357295",
  "customer_name": "Oriental Land Co.,Ltd.",
  "contract_id": "8285536789337141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "14040810.0",
  "expiration_date": "2023-12-06"
}, {
  "vmware_agreement": "VMware Agreement # 00357046",
  "customer_name": "BRIDGESTONE CORPORATION",
  "contract_id": "8286022941891362",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "67393128.0",
  "expiration_date": "2023-12-07"
}, {
  "vmware_agreement": "00681381",
  "customer_name": "Idfc First Bank Ltd",
  "contract_id": "8286132810852503",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00690817",
  "customer_name": "Evalueserve",
  "contract_id": "8285881506770587",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "VMware Agreement # 00645889",
  "customer_name": "Suntory Business Systems Ltd.",
  "contract_id": "8286619698724280",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50676888.0",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00656736",
  "customer_name": "Bank of Ceylon",
  "contract_id": "8285880471516979",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "416539.09",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00532097",
  "customer_name": "Infosys Limited",
  "contract_id": "8285178257637095",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "66983.07",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "VMware PartnerConnect Agreement, January 25, 2022",
  "customer_name": "Route Mobile Ltd",
  "contract_id": "8285679691910137",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-10-04"
}, {
  "vmware_agreement": "00423206",
  "customer_name": "Route Mobile Ltd.",
  "contract_id": "8286939912339789",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "VMware Agreement # 00437919",
  "customer_name": "Route Mobile Ltd",
  "contract_id": "8287461109839872",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "VMware Agreement # 00681165",
  "customer_name": "The Hong Kong Jockey Club",
  "contract_id": "8286269612737467",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "625662.93",
  "expiration_date": "2023-11-02"
}, {
  "vmware_agreement": "VMware Agreement # 00632197",
  "customer_name": "EMBRAER - Empresa Brasileira de Aeronautica",
  "contract_id": "8286318438073248",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "496724.43",
  "expiration_date": "2023-04-29"
}, {
  "vmware_agreement": "VMware Agreement # 00680048",
  "customer_name": "Imesp - Imprensa Oficial do Estado de Sao Paulo",
  "contract_id": "8286282354900479",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "298364.25",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Agreement # 00433501",
  "customer_name": "TSE - Tribunal Superior Eleitoral",
  "contract_id": "8286069596660661",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "253971.34",
  "expiration_date": "2023-04-24"
}, {
  "vmware_agreement": "00675687",
  "customer_name": "Verse Innovation Pvt. Ltd",
  "contract_id": "8286191716384056",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "103760.33",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "VMware Agreement # 00684071",
  "customer_name": "The South Indian Bank Ltd",
  "contract_id": "8840505259186565",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "105011.57",
  "expiration_date": "2023-11-25"
}, {
  "vmware_agreement": "VMware Agreement # 00690913",
  "customer_name": "Genpact India",
  "contract_id": "8286805178902164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "138644.75",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00690473",
  "customer_name": "Wipro GE Healthcare Pvt. Ltd",
  "contract_id": "8286587849591333",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "193515.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00680120",
  "customer_name": "Airtel Payment Bank Ltd",
  "contract_id": "8286734411010986",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "279577.75",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00550970",
  "customer_name": "Symphony EYC India Private Limited",
  "contract_id": "8285639988136530",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "219999.08",
  "expiration_date": "2023-05-26"
}, {
  "vmware_agreement": "00691093",
  "customer_name": "Wipro GE Healthcare Pvt. Ltd",
  "contract_id": "8285842073868663",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "193515.0",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00607409",
  "customer_name": "Compania Dominicana De Telefonos",
  "contract_id": "8286333582202294",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "68598.41",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00691215",
  "customer_name": "Compania Dominicana De Telefonos",
  "contract_id": "8286085788177258",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "110968.43",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00431453",
  "customer_name": "Centre For Strategic Infocomm Technologies (CSIT)",
  "contract_id": "8286055226253507",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00477310",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285762360370452",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "14973469.0",
  "expiration_date": "2023-07-28"
}, {
  "vmware_agreement": "00637982",
  "customer_name": "JR EAST INFORMATION SYSTEMS",
  "contract_id": "8286822744226807",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "56657700.0",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00489492",
  "customer_name": "NIKKO SYSTEMS SOLUTIONS LTD",
  "contract_id": "8286805740186235",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "99637590.0",
  "expiration_date": "2023-03-27"
}, {
  "vmware_agreement": "00433372",
  "customer_name": "XL AXIATA PT TBK",
  "contract_id": "8284842425358884",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "235034.87",
  "expiration_date": "2023-08-27"
}, {
  "vmware_agreement": "00434020",
  "customer_name": "BDO Unibank, Inc.",
  "contract_id": "8286348005331852",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "396996.21",
  "expiration_date": "2023-03-30"
}, {
  "vmware_agreement": "00434355",
  "customer_name": "XP Investimentos",
  "contract_id": "8285546758808524",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "388974.83",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00494018",
  "customer_name": "SOFTBANK CORP.",
  "contract_id": "8840404707336179",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "60946781.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00330411",
  "customer_name": "NTT PC Communications Incorporated",
  "contract_id": "8285830264154753",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "15049819.0",
  "expiration_date": "2023-06-13"
}, {
  "vmware_agreement": "00442337",
  "customer_name": "OTA CITY",
  "contract_id": "8285563047799096",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35301504.0",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "00545569",
  "customer_name": "Trillium Health Partners",
  "contract_id": "8286994304054291",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "112811.98",
  "expiration_date": "2023-03-30"
}, {
  "vmware_agreement": "00391542",
  "customer_name": "Shang Software Solutions",
  "contract_id": "8286734053145869",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "125216.83",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00445292",
  "customer_name": "Malayan Banking Berhad",
  "contract_id": "8286977162581203",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "405730.43",
  "expiration_date": "2023-03-06"
}, {
  "vmware_agreement": "00553814",
  "customer_name": "SAMSUNG ELECTRONICS",
  "contract_id": "8285763777669327",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "261655.08",
  "expiration_date": "2023-01-20"
}, {
  "vmware_agreement": "00551145",
  "customer_name": "Ncsoft Corporation",
  "contract_id": "8286505206396976",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "150009.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00497761",
  "customer_name": "Banco Galicia",
  "contract_id": "8285357508667550",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "163311.93",
  "expiration_date": "2023-01-15"
}, {
  "vmware_agreement": "00359930",
  "customer_name": "AGD - Aceitera General Deheza",
  "contract_id": "8286121012282038",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "170084.38",
  "expiration_date": "2023-05-03"
}, {
  "vmware_agreement": "00362108",
  "customer_name": "eHealth Ontario",
  "contract_id": "8286270159906210",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00688538",
  "customer_name": "LCBO",
  "contract_id": "8284794075167439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00497141",
  "customer_name": "Taishin International Bank Co., Ltd.",
  "contract_id": "8285830255475391",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "657540.27",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00601997",
  "customer_name": "KBZ Bank",
  "contract_id": "8285977818284982",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "163249.55",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00673032",
  "customer_name": "PT. Bank Rakyat Indonesia (Persero) Tbk",
  "contract_id": "8286768710628328",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "125290.8",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00576033",
  "customer_name": "Badan Siber Dan Sandi Negara",
  "contract_id": "8285720048941713",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "200238.3",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00430835",
  "customer_name": "XL AXIATA PT TBK",
  "contract_id": "8286939758248112",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "108145.68",
  "expiration_date": "2023-07-11"
}, {
  "vmware_agreement": "00476475",
  "customer_name": "Small and Medium Enterprise Development Bank of Thailand (SME Bank)",
  "contract_id": "8285720485391210",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "105446.25",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00491829",
  "customer_name": "QuickPlay Media Pvt Ltd",
  "contract_id": "8286304300109270",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "36732.31",
  "expiration_date": "2023-06-17"
}, {
  "vmware_agreement": "00495350",
  "customer_name": "Banco Mercantil del Norte, Sa",
  "contract_id": "8287391833720907",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "509447.77",
  "expiration_date": "2023-07-27"
}, {
  "vmware_agreement": "00437704",
  "customer_name": "Southern California Edison Company",
  "contract_id": "8286853626306215",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "944569.35",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00433671",
  "customer_name": "TOHOKU ELECTRIC POWER COMPANY, INCORPORATED",
  "contract_id": "8285495573249626",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "16376802.0",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00544077",
  "customer_name": "NET ONE SYSTEMS CO., LTD.",
  "contract_id": "8285493803213564",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12819485.0",
  "expiration_date": "2023-03-27"
}, {
  "vmware_agreement": "00447770",
  "customer_name": "THE CHIBA CITY BOARD OF EDUCATION",
  "contract_id": "8286172517697682",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "12854044.0",
  "expiration_date": "2023-09-19"
}, {
  "vmware_agreement": "00391855",
  "customer_name": "XP Investimentos",
  "contract_id": "9429096848231818",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "285201.37",
  "expiration_date": "2023-09-03"
}, {
  "vmware_agreement": "00497117",
  "customer_name": "IBARAKI PREFECTURE",
  "contract_id": "8285857427506824",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "2083761.0",
  "expiration_date": "2023-07-21"
}, {
  "vmware_agreement": "00358518",
  "customer_name": "NTT COMMUNICATIONS CORPORATION",
  "contract_id": "8284935399941643",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "43567590.0",
  "expiration_date": "2023-03-27"
}, {
  "vmware_agreement": "00433029",
  "customer_name": "NEC CORPORATION",
  "contract_id": "8286069628254007",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "15993839.0",
  "expiration_date": "2023-04-23"
}, {
  "vmware_agreement": "00378302",
  "customer_name": "OPTAGE Inc.",
  "contract_id": "8840178787201507",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "21904701.0",
  "expiration_date": "2023-07-25"
}, {
  "vmware_agreement": "00589641",
  "customer_name": "NTT PC COMMUNICATIONS INCORPORATED",
  "contract_id": "8285537512217555",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "42800000.0",
  "expiration_date": "2023-06-13"
}, {
  "vmware_agreement": "00691680",
  "customer_name": "NTT Malaysia Solutions Sdn Bhd",
  "contract_id": "8285446579917919",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "284739.48",
  "expiration_date": "2023-01-09"
}, {
  "vmware_agreement": "00545560",
  "customer_name": "Banco Galicia",
  "contract_id": "8286255505711414",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "236603.1",
  "expiration_date": "2023-01-15"
}, {
  "vmware_agreement": "00433340",
  "customer_name": "CLARO Argentina",
  "contract_id": "8840423191055035",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "168727.68",
  "expiration_date": "2023-01-04"
}, {
  "vmware_agreement": "00370248",
  "customer_name": "CLARO Argentina",
  "contract_id": "8285993429366665",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "218444.62",
  "expiration_date": "2023-03-05"
}, {
  "vmware_agreement": "00442395",
  "customer_name": "CLARO Argentina",
  "contract_id": "8285815573364354",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "94173.3",
  "expiration_date": "2023-03-20"
}, {
  "vmware_agreement": "00380943",
  "customer_name": "CLARO Argentina",
  "contract_id": "8285841775046832",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "152588.93",
  "expiration_date": "2023-05-03"
}, {
  "vmware_agreement": "00360861",
  "customer_name": "National Payment Corporation Of India (NPCI)",
  "contract_id": "8286021941362888",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "335253.15",
  "expiration_date": "2023-08-01"
}, {
  "vmware_agreement": "00401183",
  "customer_name": "National Payments Corporation Of India (NPCI)",
  "contract_id": "8286854501579655",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "323866.28",
  "expiration_date": "2023-08-01"
}, {
  "vmware_agreement": "00682834",
  "customer_name": "Iveco S.p.A.",
  "contract_id": "8286956879569404",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "450304.65",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "00327236",
  "customer_name": "PT. Adira Dinamika Multi Finance Tbk.",
  "contract_id": "8285663742115794",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "10080.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00393903",
  "customer_name": "Oriental Land Co., Ltd.",
  "contract_id": "8286570958796625",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "12863532.0",
  "expiration_date": "2023-12-06"
}, {
  "vmware_agreement": "00638988",
  "customer_name": "The Japan Research Institute, Limited",
  "contract_id": "8840304764720246",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "79659346.0",
  "expiration_date": "2023-07-12"
}, {
  "vmware_agreement": "VMware Agreement No. 00378990",
  "customer_name": "Asahi Hospital Service Corp.",
  "contract_id": "8285546467903411",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "57855249.0",
  "expiration_date": "2023-09-25"
}, {
  "vmware_agreement": "VMware Agreement No. 00581748",
  "customer_name": "Mitsubishi UFJ Financial Group Inc",
  "contract_id": "8287425450848955",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "34913667.0",
  "expiration_date": "2023-09-27"
}, {
  "vmware_agreement": "00619162",
  "customer_name": "City of San Diego",
  "contract_id": "8286202831174765",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-03-01"
}, {
  "vmware_agreement": "00362989",
  "customer_name": "K-OPTICOM CORPORATION",
  "contract_id": "8285694265860622",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "35823901.0",
  "expiration_date": "2023-07-25"
}, {
  "vmware_agreement": "00447768",
  "customer_name": "TOYOTA MOTOR CORPORATION",
  "contract_id": "8286687501990952",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "31452650.0",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00545246",
  "customer_name": "NTT Malaysia Solutions Sdn Bhd",
  "contract_id": "8285790810426390",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "196017.9",
  "expiration_date": "2023-03-29"
}, {
  "vmware_agreement": "00588601",
  "customer_name": "NTT Malaysia Solutions Sdn Bhd",
  "contract_id": "8286976243722522",
  "contract_title": "Amendment No. 4 to Single ELA Resale Agreement",
  "fees": "277271.7",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "VMware Agreement # 00606680",
  "customer_name": "Cambridge Investments Research, Inc",
  "contract_id": "8285867548748924",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-01-01"
}, {
  "vmware_agreement": "00360744.0",
  "customer_name": "Banco Santander Brasil S/A",
  "contract_id": "8840421963064776",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "10236610.0",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00430118",
  "customer_name": "TURKCELL ILETISIM HIZMETLERI AS",
  "contract_id": "8285746198422261",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "2705914.69",
  "expiration_date": "2023-03-27"
}, {
  "vmware_agreement": "00444706",
  "customer_name": "EBARA CORPORATION",
  "contract_id": "8285992244377377",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "78794480.0",
  "expiration_date": "2023-03-28"
}, {
  "vmware_agreement": "95654 (Partner Master Terms) 95656 (VMware Distributor Exhibit) 129974 (VMware Distributor Authorization for VMware Subscription Services)",
  "customer_name": "NOMURA SECURITIES CO. LTD",
  "contract_id": "8286084265699098",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "52494750.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00444298",
  "customer_name": "DOCOMO Systems, Inc.",
  "contract_id": "8286022606258162",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "68507482.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00684817",
  "customer_name": "City Of Arlington Texas",
  "contract_id": "8286007554198171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "84313.45",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00445241",
  "customer_name": "Navajo Tribal Utility Authority",
  "contract_id": "8286414017212141",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1041694.83",
  "expiration_date": "2023-04-29"
}, {
  "vmware_agreement": "VMware Agreement # 00681340",
  "customer_name": "Medical Faculty Associates Inc",
  "contract_id": "8285640836700562",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "563448.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00683729",
  "customer_name": "University Of North Texas",
  "contract_id": "8285390609308263",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "150780.31",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "00115424.0",
  "customer_name": "California Department Employment Development",
  "contract_id": "8285178395714221",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4046449.13",
  "expiration_date": "2023-11-04"
}, {
  "vmware_agreement": "VMware Agreement # 00691793",
  "customer_name": "ATF Bureau of Alcohol, Tobacco, Firearms and Explosives",
  "contract_id": "8284973120469452",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "86345.48",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "VMware Agreement # 00622027",
  "customer_name": "Maryland Department Of Information Technology",
  "contract_id": "8285169009790532",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "600663.34",
  "expiration_date": "2023-03-11"
}, {
  "vmware_agreement": "VMware Agreement #00615822",
  "customer_name": "Maryland Department Of Information Technology",
  "contract_id": "8285993626249486",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "352443.13",
  "expiration_date": "2023-02-15"
}, {
  "vmware_agreement": "00681236",
  "customer_name": "City of Cedar Park",
  "contract_id": "8285120974987552",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "88712.76",
  "expiration_date": "2023-11-08"
}, {
  "vmware_agreement": "VMware Agreement # 00637890",
  "customer_name": "Tennessee Technological University (main)",
  "contract_id": "8285970174569723",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "75008.9",
  "expiration_date": "2023-06-13"
}, {
  "vmware_agreement": "00680936",
  "customer_name": "Dallas County (TX)",
  "contract_id": "8286427289236196",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "133621.4",
  "expiration_date": "2023-12-05"
}, {
  "vmware_agreement": "VMware Agreement # 00625761",
  "customer_name": "Trident Technical College",
  "contract_id": "8285563411717278",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "110535.89",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00674769",
  "customer_name": "Vanderbilt University (main)",
  "contract_id": "8285484004952761",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "350936.86",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00684064",
  "customer_name": "Washoe County",
  "contract_id": "8285763453617442",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1279904.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "VMware Agreement # 00677153",
  "customer_name": "City of Austin-CTECC",
  "contract_id": "8285572129448885",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "192417.76",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "VMware Agreement # 00394012",
  "customer_name": "Pharr San Juan Alamo ISD",
  "contract_id": "8285868965187627",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "270081.33",
  "expiration_date": "2023-05-15"
}, {
  "vmware_agreement": "00674208",
  "customer_name": "LA County Public Health",
  "contract_id": "8285291406641524",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "347349.21",
  "expiration_date": "2023-12-08"
}, {
  "vmware_agreement": "VMware Distributor Exhibit",
  "customer_name": "Central Arizona Project",
  "contract_id": "8285495118511193",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "90723.25",
  "expiration_date": "2023-04-05"
}, {
  "vmware_agreement": "VMware OEM Agreement effective as of 1 November 2014 made between the Partner, VMware, and VMware, Inc.",
  "customer_name": "TAIYO LIFE INSURANCE COMPANY",
  "contract_id": "8285271514355322",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "43527360.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00540348",
  "customer_name": "Northrop Grumman Corp",
  "contract_id": "8286254563935870",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "4074380.0",
  "expiration_date": "2023-03-12"
}, {
  "vmware_agreement": "00674220",
  "customer_name": "California Department Of Corrections and Rehabilitation",
  "contract_id": "8286378022666939",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1209498.0",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00688517",
  "customer_name": "Stromnetz Hamburg GmbH",
  "contract_id": "8285572281094206",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "331691.16",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 15, 2017",
  "customer_name": "Maryland Department of Human Resources",
  "contract_id": "8286491847090917",
  "contract_title": "VMware Enterprise Order",
  "fees": "1036050.18",
  "expiration_date": "2023-04-04"
}, {
  "vmware_agreement": "VMware Agreement # 00688541",
  "customer_name": "Light Servicos de Eletrecidade S/A",
  "contract_id": "8285456942350155",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "81194.82",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00445944",
  "customer_name": "BB - Banco do Brasil",
  "contract_id": "8285448220582338",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3280882.53",
  "expiration_date": "2023-05-22"
}, {
  "vmware_agreement": "00636166",
  "customer_name": "SINGTEL OPTUS PTY LTD",
  "contract_id": "8840411133703637",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2829818.21",
  "expiration_date": "2023-03-19"
}, {
  "vmware_agreement": "00674881",
  "customer_name": "Singapore Telecommunications Ltd",
  "contract_id": "8285546686698769",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "473210.8",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 15, 2017",
  "customer_name": "UNITED OVERSEAS BANK LIMITED",
  "contract_id": "8286347969211868",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "200440.0",
  "expiration_date": "2023-07-16"
}, {
  "vmware_agreement": "VMware Agreement # 00622499",
  "customer_name": "Metropolitan Government of Nashville and Davidson County",
  "contract_id": "8285241114076681",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "119794.64",
  "expiration_date": "2023-03-17"
}, {
  "vmware_agreement": "00671905",
  "customer_name": "City of Wichita",
  "contract_id": "8284141958337637",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "31260.34",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00482590",
  "customer_name": "Maybank Singapore Limited",
  "contract_id": "8286022502553654",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "360186.82",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement # 00633931",
  "customer_name": "Virginia Community College System",
  "contract_id": "9429027837042178",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "676116.0",
  "expiration_date": "2023-05-03"
}, {
  "vmware_agreement": "00693745",
  "customer_name": "Saudi Basic Industries Co. (SABIC)",
  "contract_id": "8285571571230510",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "510004.51",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "006468021",
  "customer_name": "Tennessee Attorney General\u0027s Office",
  "contract_id": "8285279580403204",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "70632.4",
  "expiration_date": "2023-06-27"
}, {
  "vmware_agreement": "VMware Agreement # 00632421",
  "customer_name": "City Of Atlanta Department Of Aviation",
  "contract_id": "8286698206971560",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "283507.97",
  "expiration_date": "2023-05-11"
}, {
  "vmware_agreement": "00629478",
  "customer_name": "The Board of Trustees of the Leland Stanford Junior University",
  "contract_id": "8285815209044157",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "189955.0",
  "expiration_date": "2023-04-29"
}, {
  "vmware_agreement": "00684685 and 00681442",
  "customer_name": "Serasa Experian",
  "contract_id": "8286282206426088",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "380000.65",
  "expiration_date": "2023-12-02"
}, {
  "vmware_agreement": "VMware Agreement #00477237",
  "customer_name": "Brighton School District 27J",
  "contract_id": "8840194936148881",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "626626.34",
  "expiration_date": "2023-08-06"
}, {
  "vmware_agreement": "00435509",
  "customer_name": "AXIS BANK LTD",
  "contract_id": "8285087682429592",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1175014.93",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00559950",
  "customer_name": "A Toda Hora - ATH S.A.",
  "contract_id": "8285908899144731",
  "contract_title": "VMware Single ELA Resale Agreement",
  "fees": "1714882.51",
  "expiration_date": "2023-05-24"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "BAC Costa Rica - Banco de America Central",
  "contract_id": "8285156862232827",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "113405.51",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "VMware Agreement # 00609585",
  "customer_name": "A Toda Hora S.A.",
  "contract_id": "8285270417522383",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "110000.0",
  "expiration_date": "2023-01-14"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Banco Alfa de Investimento S/A.",
  "contract_id": "8286132937026481",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "231956.04",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00359299",
  "customer_name": "Department of Labor",
  "contract_id": "8285745477012478",
  "contract_title": "AMENDMENT NO. 1 TO SINGLE ELA RESALE AGREEMENT",
  "fees": "314976.66",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "VMware Agreement # 00685473",
  "customer_name": "Brunei Shell Petroleum Co Sdn Bhd",
  "contract_id": "8284996723338617",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "220095.81",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00430089",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8286717389071539",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "14466425.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00370542",
  "customer_name": "Hyundai Card Co., Ltd",
  "contract_id": "8840359581289484",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "209334.25",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00651326",
  "customer_name": "Axis Bank Ltd",
  "contract_id": "8285110783562512",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "120000.0",
  "expiration_date": "2023-07-07"
}, {
  "vmware_agreement": "VMware Agreement # 00690107",
  "customer_name": "Hubergroup India Private Limited",
  "contract_id": "8285604214317114",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "19990.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00690812",
  "customer_name": "BirlaSoft Limited",
  "contract_id": "9428987357992964",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "132942.51",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "VMware Agreement # 00688203",
  "customer_name": "Tata Consultancy Services Ltd",
  "contract_id": "8285650454199260",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "139405.24",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "VMware Agreement # 00677442",
  "customer_name": "Vellore Institute of Technology",
  "contract_id": "8285778835409104",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "108071.11",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "VMware Agreement # 00567023",
  "customer_name": "Land Transport Authority (LTA)",
  "contract_id": "8285332078346951",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "86068.72",
  "expiration_date": "2023-08-31"
}, {
  "vmware_agreement": "00672068",
  "customer_name": "Sarawak Energy Berhad",
  "contract_id": "8285731615222464",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "76320.0",
  "expiration_date": "2023-09-15"
}, {
  "vmware_agreement": "006222051",
  "customer_name": "Sonora Quest Labs",
  "contract_id": "8286160665343459",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "357147.35",
  "expiration_date": "2023-03-23"
}, {
  "vmware_agreement": "00445695",
  "customer_name": "Qatar National Bank",
  "contract_id": "8285455456143249",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "394235.37",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "VMware Agreement #0069051.0",
  "customer_name": "Grupo Nacional Provincial, S.A.",
  "contract_id": "8285483538102189",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "387545.62",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "VMware Agreement # 00498584",
  "customer_name": "CENTRAL PROVIDENT FUND BOARD",
  "contract_id": "8840436381596922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "543807.45",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00484922",
  "customer_name": "HHS - Office of the Secretary",
  "contract_id": "8284900681668585",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "18749.86",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00444730",
  "customer_name": "California Public Utilities Commission",
  "contract_id": "8285471663630520",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "424203.98",
  "expiration_date": "2023-03-20"
}, {
  "vmware_agreement": "00501320",
  "customer_name": "T. Rowe Price Associates Inc",
  "contract_id": "8285586216398804",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "69116.17",
  "expiration_date": "2023-12-03"
}, {
  "vmware_agreement": "00495232",
  "customer_name": "Wipro Limited",
  "contract_id": "8286101611895458",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "849254.79",
  "expiration_date": "2023-07-28"
}, {
  "vmware_agreement": "00485844",
  "customer_name": "SPAWAR",
  "contract_id": "8840273309651436",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "536934.47",
  "expiration_date": "2023-09-07"
}, {
  "vmware_agreement": "00486157",
  "customer_name": "State Bank Of India",
  "contract_id": "8285927182486491",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "51940.84",
  "expiration_date": "2023-09-10"
}, {
  "vmware_agreement": "00679751",
  "customer_name": "Tata Consultancy Services",
  "contract_id": "8285523026198922",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-10-20"
}, {
  "vmware_agreement": "00547096",
  "customer_name": "PANASONIC INFORMATION SYSTEMS CO.,LTD.",
  "contract_id": "8285969683452015",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement (OEM)",
  "fees": "17717778.0",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00494245",
  "customer_name": "Texas Association Of Counties",
  "contract_id": "8285650804066784",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "289627.65",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00559383",
  "customer_name": "MobiFone Corporation",
  "contract_id": "8285720048361610",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "215932.77",
  "expiration_date": "2023-03-18"
}, {
  "vmware_agreement": "00675089",
  "customer_name": "Virginia Information Technology Agency",
  "contract_id": "8285270759294244",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "268568.34",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00697719",
  "customer_name": "U.S. Department of Health and Human Services",
  "contract_id": "8286537857708610",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-02-17"
}, {
  "vmware_agreement": "VMware Agreement # 00688925.0",
  "customer_name": "Inner Mongolia Yili Industrial Group Ltd By Share Ltd Neimenggu 010080, China",
  "contract_id": "8285868687780008",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "3452220.8",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00367185",
  "customer_name": "The College of New Jersey",
  "contract_id": "8285157467084826",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "353339.9",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "VMware Agreement # 00359192",
  "customer_name": "Kirtland AFB (AFMC)",
  "contract_id": "8285562745595158",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1213843.05",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "00360214",
  "customer_name": "Enap-Empresa Nacional Del Petroleo",
  "contract_id": "8285470685486373",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "137078.98",
  "expiration_date": "2023-01-02"
}, {
  "vmware_agreement": "00359224",
  "customer_name": "Atento Mexicana",
  "contract_id": "8285493985893280",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "206547.56",
  "expiration_date": "2023-12-17"
}, {
  "vmware_agreement": "VMware Agreement # 00375089",
  "customer_name": "California Department Of Public Health",
  "contract_id": "8286269923260013",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1529189.42",
  "expiration_date": "2023-04-25"
}, {
  "vmware_agreement": "VMware Agreement #00378472",
  "customer_name": "Petrobras - Petroleo Brasileiro",
  "contract_id": "8285429341404750",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1324483.29",
  "expiration_date": "2023-04-11"
}, {
  "vmware_agreement": "00380098",
  "customer_name": "Little Rock School District",
  "contract_id": "8285572920876929",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "279438.79",
  "expiration_date": "2023-02-02"
}, {
  "vmware_agreement": "00382215",
  "customer_name": "Grupo Ol - Ol Movel",
  "contract_id": "8285992007136733",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "329608.84",
  "expiration_date": "2023-12-11"
}, {
  "vmware_agreement": "00375605",
  "customer_name": "Telefonica Brasil",
  "contract_id": "8285018336970110",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "394617.12",
  "expiration_date": "2023-07-11"
}, {
  "vmware_agreement": "00401277",
  "customer_name": "Seminole Tribe of Florida",
  "contract_id": "8840420802566501",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "128274.64",
  "expiration_date": "2023-07-30"
}, {
  "vmware_agreement": "VMware Agreement #00424268",
  "customer_name": "Commonwealth Superannuation Corporation",
  "contract_id": "8284996592989960",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "991290.81",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00433447",
  "customer_name": "Bangladesh Police Headquarter",
  "contract_id": "8287680389339780",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "684899.94",
  "expiration_date": "2023-05-31"
}, {
  "vmware_agreement": "00434229",
  "customer_name": "YES BANK LIMITED",
  "contract_id": "8284949552195870",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "166240.03",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00430236",
  "customer_name": "Oman Oil Company Saoc",
  "contract_id": "8286441241028006",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "678822.34",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00434602",
  "customer_name": "Bank Of Maharashtra",
  "contract_id": "8840294189772601",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "51238.94",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00433987",
  "customer_name": "University of Virginia-IT",
  "contract_id": "8285587822799022",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "747792.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00424491",
  "customer_name": "DC Office of the Chief Technology Officer",
  "contract_id": "8286023683652015",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "977800.0",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00424090",
  "customer_name": "Oklahoma District Attorneys Council",
  "contract_id": "8285882083101540",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "263938.85",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00433990",
  "customer_name": "Reno-Tahoe International Airport",
  "contract_id": "8286335253338213",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "614537.56",
  "expiration_date": "2023-02-19"
}, {
  "vmware_agreement": "VMware Agreement # 00433998",
  "customer_name": "Chester County",
  "contract_id": "8285624772964218",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "588426.73",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00423946",
  "customer_name": "University Of Texas Rio Grande Valley",
  "contract_id": "8285617173295232",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "763184.25",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "VMware Agreement # 00425771",
  "customer_name": "Southern New Hampshire University",
  "contract_id": "8285829738107719",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "747665.97",
  "expiration_date": "2023-01-06"
}, {
  "vmware_agreement": "00424379",
  "customer_name": "Los Rios Community College District",
  "contract_id": "8285992385634185",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "237952.36",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "VMware Agreement # 00424078",
  "customer_name": "Suprema Corte de Justicia de la Nacion",
  "contract_id": "8285868095218540",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "501869.97",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "VMware Agreement #00434393",
  "customer_name": "Big Hit Entertainment",
  "contract_id": "8285538072395578",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "121176.0",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "VMwareAuthorized Distributor Partner Program Agreement, May 29,2018",
  "customer_name": "Banque De L Union Haitienne",
  "contract_id": "8285641056335057",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "273500.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "VMware Agreement # 00434347",
  "customer_name": "CCA Chile - Centro de CompensacionAutomatizado",
  "contract_id": "8285318153292983",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "551482.5",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "VMware Agreement # 00430461",
  "customer_name": "SUNAT",
  "contract_id": "8286587760484191",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "234191.52",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 14, 2018",
  "customer_name": "Ministerio Publico Federal-MPF",
  "contract_id": "8286147087389437",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "380908.53",
  "expiration_date": "2023-04-14"
}, {
  "vmware_agreement": "VMware Agreement # 00430346",
  "customer_name": "TSE - Tribunal Superior Eleitoral",
  "contract_id": "8285456262627509",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "714235.86",
  "expiration_date": "2023-04-24"
}, {
  "vmware_agreement": "00433220",
  "customer_name": "NEC FIELDING,LTD.",
  "contract_id": "8285485038954937",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "22774176.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00431304",
  "customer_name": "Carahsoft Technology Corporation",
  "contract_id": "8285745847914432",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "747404.34",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00434782",
  "customer_name": "VODAFONE IDEA LTD",
  "contract_id": "8284889557640177",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "392338.76",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00430646",
  "customer_name": "A T \u0026 T Comunicaciones Digitales",
  "contract_id": "8285909028727774",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "114360.0",
  "expiration_date": "2023-10-15"
}, {
  "vmware_agreement": "VMware Agreement # 00426647",
  "customer_name": "MUFG BANK, LTD",
  "contract_id": "8286022099939143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1754313.63",
  "expiration_date": "2023-03-05"
}, {
  "vmware_agreement": "VMware Agreement # 00438822",
  "customer_name": "TOT PUBLIC COMPANY LIMITED",
  "contract_id": "8285813678976861",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "380421.6",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00437904",
  "customer_name": "Toyota Motor Corporation",
  "contract_id": "8285456252974204",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "35739172.0",
  "expiration_date": "2023-07-19"
}, {
  "vmware_agreement": "00441480",
  "customer_name": "INES CORPORATION",
  "contract_id": "8285880732331206",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "45430522.0",
  "expiration_date": "2023-09-11"
}, {
  "vmware_agreement": "00436311",
  "customer_name": "T. Rowe Price Associates, Inc.",
  "contract_id": "8285032918006730",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "578200.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00446328",
  "customer_name": "Dutch-Bangla Bank Ltd",
  "contract_id": "8285564381498881",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "VMware Agreement #00436694",
  "customer_name": "Childrens Hospital of New Orleans*(LCMC)",
  "contract_id": "8285898751182186",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1947938.54",
  "expiration_date": "2023-03-27"
}, {
  "vmware_agreement": "VMware Agreement # 00436816",
  "customer_name": "Instituto de Seguridad laboral",
  "contract_id": "8284956858606827",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "302529.0",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00431949",
  "customer_name": "State of Washington Department of Social and Health Services (DSHS)",
  "contract_id": "8286472885164415",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "681957.2",
  "expiration_date": "2023-03-16"
}, {
  "vmware_agreement": "VMware Agreement # 00436706",
  "customer_name": "CAL POLY POMONA",
  "contract_id": "8286334316531286",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "435295.88",
  "expiration_date": "2023-04-23"
}, {
  "vmware_agreement": "VMware Partner Agreement No. 00274954",
  "customer_name": "Great Eastern Life Assurance Co Ltd",
  "contract_id": "8285483787811753",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "253484.0",
  "expiration_date": "2023-03-26"
}, {
  "vmware_agreement": "VMware Agreement #00444304",
  "customer_name": "Singapore Telecommunications Ltd",
  "contract_id": "8286456014937342",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "578457.44",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "00445299",
  "customer_name": "Grupo Safra - Banco Safra",
  "contract_id": "8285407220812125",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "171818.53",
  "expiration_date": "2023-05-01"
}, {
  "vmware_agreement": "00440843",
  "customer_name": "Palm Beach County School Board",
  "contract_id": "8840540379912406",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "112574.88",
  "expiration_date": "2023-09-21"
}, {
  "vmware_agreement": "00447857",
  "customer_name": "Interbank",
  "contract_id": "8285680445011900",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "497871.36",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "00436213",
  "customer_name": "Wipro Ltd.",
  "contract_id": "8285603228126461",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "159409.2",
  "expiration_date": "2023-02-18"
}, {
  "vmware_agreement": "00441425",
  "customer_name": "Seminole Tribe of Florida",
  "contract_id": "8285617170188834",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "112271.23",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00436200",
  "customer_name": "Virginia Information Technology Agency",
  "contract_id": "8285800789856358",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "336200.36",
  "expiration_date": "2023-02-19"
}, {
  "vmware_agreement": "00442782",
  "customer_name": "Independent Colleges \u0026 Universities Of Florida",
  "contract_id": "8286172998339136",
  "contract_title": "Amendment No. 2 to Single ELA Resale Agreement",
  "fees": "222905.65",
  "expiration_date": "2023-03-18"
}, {
  "vmware_agreement": "VMware Agreement, September 1, 2010",
  "customer_name": "Grupo Financiero Inbursa, S.A.B. de C.V.",
  "contract_id": "8286160113993982",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "835663.85",
  "expiration_date": "2023-09-15"
}, {
  "vmware_agreement": "VMware Agreement # 00394276",
  "customer_name": "Lower Colorado River Authority (Austin)",
  "contract_id": "8285510109620436",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1480315.2",
  "expiration_date": "2023-07-15"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement, June 15, 2017",
  "customer_name": "Banco Supervielle",
  "contract_id": "8285193921531332",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "748178.48",
  "expiration_date": "2023-07-13"
}, {
  "vmware_agreement": "00476072",
  "customer_name": "AT\u0026T Comunicaciones Digitales, S. de R.L. de C.V.",
  "contract_id": "8840349433882448",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "194992.35",
  "expiration_date": "2023-07-20"
}, {
  "vmware_agreement": "00477243",
  "customer_name": "State Of Oklahoma / Office Of Management and Enterprise Services",
  "contract_id": "8285858246399397",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "13210396.65",
  "expiration_date": "2023-08-31"
}, {
  "vmware_agreement": "00387295",
  "customer_name": "El Dorado County",
  "contract_id": "8840209161472789",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "938274.05",
  "expiration_date": "2023-08-03"
}, {
  "vmware_agreement": "00477092",
  "customer_name": "Horry County Schools",
  "contract_id": "8285270906818739",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "636996.04",
  "expiration_date": "2023-07-27"
}, {
  "vmware_agreement": "VMware Agreement # 00477769",
  "customer_name": "Texas Tech University",
  "contract_id": "8285639922606722",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "570960.94",
  "expiration_date": "2023-09-01"
}, {
  "vmware_agreement": "VMwareAuthorized Distributor Partner Program Agreement, June 15,2017",
  "customer_name": "Banestes - Banco do Estado do EspiritoSanto",
  "contract_id": "8285509063120098",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "457090.18",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "VMware Partner Agreement No. 00274954",
  "customer_name": "Ministerio de la Producción",
  "contract_id": "8285843634254308",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "726154.43",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement #00478053",
  "customer_name": "Garena Online Pte. Ltd.",
  "contract_id": "8286147864670330",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "451900.03",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "VMware Agreement #00477451",
  "customer_name": "SK Siltron",
  "contract_id": "8284794476459676",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "185374.2",
  "expiration_date": "2023-07-24"
}, {
  "vmware_agreement": "VMware Agreement # 00478982",
  "customer_name": "State Taxation Administration国家税务总局",
  "contract_id": "8286821438887585",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "7265386.72",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00477468",
  "customer_name": "Beijing Yundu Internet Technology Co.,Ltd北京云度互联科技有限公司",
  "contract_id": "8286255419230172",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "871510.06",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00478505",
  "customer_name": "RHB Bank Berhad",
  "contract_id": "8285940190364676",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "0.0",
  "expiration_date": "2023-05-10"
}, {
  "vmware_agreement": "00478935",
  "customer_name": "National Stock Exchange India Ltd.",
  "contract_id": "8286203008753930",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "96021.52",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00476885",
  "customer_name": "SPAWAR",
  "contract_id": "8285330759077731",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "2799.86",
  "expiration_date": "2023-07-20"
}, {
  "vmware_agreement": "00488431",
  "customer_name": "Army \u0026 Air Force Exchange Service",
  "contract_id": "8285720502128930",
  "contract_title": "SINGLE RESALE AGREEMENT",
  "fees": "638100.0",
  "expiration_date": "2023-09-25"
}, {
  "vmware_agreement": "VMware Agreement # 00484388",
  "customer_name": "Dfw International Airport",
  "contract_id": "8285356376763164",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1474380.6",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "VMware Agreement # 00482236",
  "customer_name": "State of Alaska DHSS",
  "contract_id": "8286008103963732",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "528516.6",
  "expiration_date": "2023-08-25"
}, {
  "vmware_agreement": "VMware Agreement # 00485897",
  "customer_name": "Clark County",
  "contract_id": "8285523652913720",
  "contract_title": "SINGLE RESALE AGREEMENT",
  "fees": "162925.0",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00495526",
  "customer_name": "RNP - Rede Nacional De Ensino E Pesquisa",
  "contract_id": "8840359580028485",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "205417.02",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00497083",
  "customer_name": "Tj-Rj - Tribunal de Justica do Rio de Janeiro",
  "contract_id": "8285679219485452",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "268014.79",
  "expiration_date": "2023-10-16"
}, {
  "vmware_agreement": "00486862",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286427656708667",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "555502.63",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "VMware Agreement # 00499699",
  "customer_name": "Pagseguro",
  "contract_id": "8285484446807093",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "611211.14",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00485134",
  "customer_name": "Banco Bolivariano",
  "contract_id": "8285430617221763",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "150938.98",
  "expiration_date": "2023-09-01"
}, {
  "vmware_agreement": "00488993",
  "customer_name": "T. Rowe Price Associates, Inc.",
  "contract_id": "8286672484624735",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "151759.52",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00478435",
  "customer_name": "Independent Colleges \u0026 Universities of Florida",
  "contract_id": "8285678530971028",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "169607.74",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00488996",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8285205457974655",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1111530.0",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "VMware Agreement # 00493675",
  "customer_name": "Adelaide Primary Health Network",
  "contract_id": "8285456552244735",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "574425.99",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00485810",
  "customer_name": "Adelaide Primary Health Network",
  "contract_id": "8285587053268616",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "574425.99",
  "expiration_date": "2023-09-21"
}, {
  "vmware_agreement": "00498306",
  "customer_name": "Department of Highways",
  "contract_id": "8285624721909766",
  "contract_title": "SINGLE ELA RESALE AGREEMENT OEM",
  "fees": "153000.0",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00498992",
  "customer_name": "West Virginia University Wvu Extension Service",
  "contract_id": "8285096400611251",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "1564499.73",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement #00478908",
  "customer_name": "DBS BANK LTD.",
  "contract_id": "8285951233762589",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "811910.1",
  "expiration_date": "2023-08-11"
}, {
  "vmware_agreement": "00493923",
  "customer_name": "Lockheed Martin",
  "contract_id": "8286131615360183",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-05-15"
}, {
  "vmware_agreement": "00489018",
  "customer_name": "Axis Bank Ltd",
  "contract_id": "8285680041314306",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "328909.08",
  "expiration_date": "2023-02-06"
}, {
  "vmware_agreement": "VMware Agreement # 00485352",
  "customer_name": "PCS SECURITY PTE LTD",
  "contract_id": "8286070489672622",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "299113.0",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00498307",
  "customer_name": "NATIONAL LIBRARY BOARD",
  "contract_id": "8286038563288327",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "191394.21",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00492930",
  "customer_name": "Naval Sea Systems Command",
  "contract_id": "8285279116393012",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "655476.39",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00484318",
  "customer_name": "Millicom International Cellular SA",
  "contract_id": "8285719595134009",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-09-17"
}, {
  "vmware_agreement": "00494485",
  "customer_name": "DCB Bank Ltd",
  "contract_id": "8286490954556749",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "80980.35",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00494001",
  "customer_name": "Maricopa County Sheriff\u0027s Office",
  "contract_id": "8840208688001372",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "235911.6",
  "expiration_date": "2023-07-28"
}, {
  "vmware_agreement": "00493005",
  "customer_name": "Progressive Casualty Insurance Company (OH)",
  "contract_id": "8284840809160864",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "337342.32",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00509300",
  "customer_name": "California Employment Development Department (EDD)",
  "contract_id": "8286414343773557",
  "contract_title": "Amendment No. 7 to Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00503188",
  "customer_name": "BRB - Banco Regional de Brasilia",
  "contract_id": "8285145365334417",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "579182.4",
  "expiration_date": "2023-12-11"
}, {
  "vmware_agreement": "VMware Agreement # 00531728",
  "customer_name": "Axis Bank Ltd",
  "contract_id": "8286588658034851",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "417969.9",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00550927",
  "customer_name": "Banco Popular y de Desarrollo Comunal",
  "contract_id": "8285730979714122",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "403998.94",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00545479",
  "customer_name": "Rhenus Logistics Asia Pacific Pte. Ltd",
  "contract_id": "8286007976554079",
  "contract_title": "Single ELA Resale Agreement OEM",
  "fees": "25050.41",
  "expiration_date": "2023-01-16"
}, {
  "vmware_agreement": "00540222",
  "customer_name": "Delaware Health and Social Services",
  "contract_id": "8285222671186683",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "99617.23",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "00546206",
  "customer_name": "Garena Online Pte. Ltd.",
  "contract_id": "8285279521766718",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "513880.42",
  "expiration_date": "2023-04-22"
}, {
  "vmware_agreement": "00551303",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8286174301884113",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "9253919.22",
  "expiration_date": "2023-04-21"
}, {
  "vmware_agreement": "00543312",
  "customer_name": "MobiFone Corporation",
  "contract_id": "8285537921063353",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "311023.25",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "VMware Agreement #00545638",
  "customer_name": "Ek-Chai Distribution System Co. Ltd",
  "contract_id": "8286038770609195",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "585822.4",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00546371",
  "customer_name": "Mahindra \u0026 Mahindra Ltd",
  "contract_id": "8840554478994273",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "148399.94",
  "expiration_date": "2023-04-01"
}, {
  "vmware_agreement": "00566810",
  "customer_name": "Rhenus Logistics Asia Pacific Pte. Ltd",
  "contract_id": "8285731464907080",
  "contract_title": "Single ELA Resale Agreement OEM",
  "fees": "21408.9",
  "expiration_date": "2023-01-16"
}, {
  "vmware_agreement": "00557081",
  "customer_name": "ebay Korea Co., Ltd",
  "contract_id": "8285241589605869",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "512070.45",
  "expiration_date": "2023-05-27"
}, {
  "vmware_agreement": "00560788",
  "customer_name": "Southeastern Community College",
  "contract_id": "8285625041691349",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "83977.78",
  "expiration_date": "2023-06-14"
}, {
  "vmware_agreement": "00574648",
  "customer_name": "Hdfc Bank Ltd",
  "contract_id": "8284851999532657",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "87264.82",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00563096",
  "customer_name": "HDFC Bank Ltd",
  "contract_id": "8285241242766294",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "407235.81",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00556118",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286173067763672",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "103999.68",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00574416",
  "customer_name": "HHS - Office of the Secretary",
  "contract_id": "8285603486181288",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "131874.33",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "00553943",
  "customer_name": "Pima County",
  "contract_id": "8284958469431930",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "200934.5",
  "expiration_date": "2023-06-28"
}, {
  "vmware_agreement": "00559526",
  "customer_name": "Prisma medios de Pago SA",
  "contract_id": "8285830582522960",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "245298.24",
  "expiration_date": "2023-05-28"
}, {
  "vmware_agreement": "00553893",
  "customer_name": "WOM",
  "contract_id": "8284815590104371",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "513612.73",
  "expiration_date": "2023-06-03"
}, {
  "vmware_agreement": "00561448",
  "customer_name": "T. Rowe Price Associates Inc",
  "contract_id": "8285406063884448",
  "contract_title": "Amendment No. 8 to Single ELA Resale Agreement",
  "fees": "197984.01",
  "expiration_date": "2023-07-27"
}, {
  "vmware_agreement": "VMware Agreement # 00589054",
  "customer_name": "China Mobile Information Technology Company Limited (RAD)",
  "contract_id": "8284582851631081",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "416415.72",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00589097",
  "customer_name": "China Mobile Information Technology Company Limited",
  "contract_id": "8285650887924460",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953166.17",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00575910",
  "customer_name": "Badan Siber Dan Sandi Negara",
  "contract_id": "8840348035551954",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "196090.34",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00543106",
  "customer_name": "County of San Bernardino Innovation and Technology",
  "contract_id": "8285868077430465",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "434435.01",
  "expiration_date": "2023-03-30"
}, {
  "vmware_agreement": "00593562",
  "customer_name": "Progressive Insurance (OH)",
  "contract_id": "8286103376615712",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "651720.01",
  "expiration_date": "2023-01-25"
}, {
  "vmware_agreement": "00606282",
  "customer_name": "Aldine ISD",
  "contract_id": "8285897545840143",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "277926.6",
  "expiration_date": "2023-07-28"
}, {
  "vmware_agreement": "VMware Agreement # 00610598",
  "customer_name": "United Nations Federal Credit Union",
  "contract_id": "8286412924300689",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "263331.7",
  "expiration_date": "2023-01-25"
}, {
  "vmware_agreement": "VMware Agreement # 00602554",
  "customer_name": "Mmm Healthcare Inc",
  "contract_id": "8285483395461834",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "450000.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "VMware Agreement # 00609547",
  "customer_name": "American Express Global Business Travel",
  "contract_id": "8285731170078228",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "469097.79",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00607209",
  "customer_name": "Telecom Argentina S.A.",
  "contract_id": "8285910105654203",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "651860.81",
  "expiration_date": "2023-12-10"
}, {
  "vmware_agreement": "VMware Agreement #00611338",
  "customer_name": "USCYBERCOM",
  "contract_id": "8286055871732818",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "953156.25",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "VMware Agreement # 00611553",
  "customer_name": "North Carolina Department Of Public Safety",
  "contract_id": "8286570660064720",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "135000.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "VMware Agreement # 00596397",
  "customer_name": "Servicios Y Administracion De Creditos Comerciales Presto S.A.",
  "contract_id": "8285694199279560",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "237044.0",
  "expiration_date": "2023-03-15"
}, {
  "vmware_agreement": "VMware Agreement # 00594394",
  "customer_name": "Banco Multiple Ademi Sa",
  "contract_id": "8840108427586479",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "195425.42",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "VMware Agreement # 00606254",
  "customer_name": "A T \u0026 T Comunicaciones Digitales",
  "contract_id": "8284674000036708",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "313782.71",
  "expiration_date": "2023-01-19"
}, {
  "vmware_agreement": "00595722",
  "customer_name": "State of NM - Dept. of Information Technology (DOIT)",
  "contract_id": "8286070166092237",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "560835.0",
  "expiration_date": "2023-01-01"
}, {
  "vmware_agreement": "00595747",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286223068756908",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "263709.2",
  "expiration_date": "2023-12-17"
}, {
  "vmware_agreement": "00603098",
  "customer_name": "Banco Pichincha",
  "contract_id": "8286491338252402",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "243574.94",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00606877",
  "customer_name": "USSF-GPS/OCX",
  "contract_id": "8285178862940502",
  "contract_title": "Amendment No. 3 to Single ELA Resale Agreement",
  "fees": "897237.98",
  "expiration_date": "2023-05-29"
}, {
  "vmware_agreement": "00606905",
  "customer_name": "Asesoras Larrain Vial Ltda",
  "contract_id": "8285587986765439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "116036.74",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00595351",
  "customer_name": "Pfizer Inc",
  "contract_id": "8286441190287828",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "619457.26",
  "expiration_date": "2023-01-10"
}, {
  "vmware_agreement": "00607172",
  "customer_name": "City of Portland",
  "contract_id": "8840542192840134",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "660508.54",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "00611207",
  "customer_name": "Department of Justice - BOP",
  "contract_id": "8284555990257698",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "933003.35",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00619925",
  "customer_name": "The George Washington University",
  "contract_id": "8285523693560770",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "37800.0",
  "expiration_date": "2023-03-01"
}, {
  "vmware_agreement": "00630941",
  "customer_name": "Claro Argentina",
  "contract_id": "8285343648910117",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "236041.58",
  "expiration_date": "2023-04-13"
}, {
  "vmware_agreement": "00622009",
  "customer_name": "California State University, East Bay",
  "contract_id": "8285280223245391",
  "contract_title": "Amendment No. 1 to Single ELA Resale Agreement",
  "fees": "457209.0",
  "expiration_date": "2023-04-15"
}, {
  "vmware_agreement": "00622451",
  "customer_name": "Streamco Distribuicao de Video Brasil LTDA",
  "contract_id": "8285762467701130",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "309000.0",
  "expiration_date": "2023-03-18"
}, {
  "vmware_agreement": "00632431",
  "customer_name": "Fulton County School System",
  "contract_id": "8286304648567514",
  "contract_title": "Amendment No. 2 to Single ELA Resale Agreement",
  "fees": "468150.24",
  "expiration_date": "2023-04-27"
}, {
  "vmware_agreement": "00632207",
  "customer_name": "California Department Of Technology Services",
  "contract_id": "8286071301124722",
  "contract_title": "SINGLE RESALE AGREEMENT",
  "fees": "134100.0",
  "expiration_date": "2023-05-17"
}, {
  "vmware_agreement": "00634864",
  "customer_name": "Virginia Housing Development Authority",
  "contract_id": "8286457047519139",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "343187.07",
  "expiration_date": "2023-05-11"
}, {
  "vmware_agreement": "00634344",
  "customer_name": "Virginia Housing Development Authority",
  "contract_id": "8285663909328518",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "351360.0",
  "expiration_date": "2023-06-29"
}, {
  "vmware_agreement": "VMware Agreement # 00646036",
  "customer_name": "Florida State University",
  "contract_id": "8285746537936073",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "76466.25",
  "expiration_date": "2023-07-25"
}, {
  "vmware_agreement": "00641150",
  "customer_name": "Virginia International Terminals Inc (Main)",
  "contract_id": "8285881729601452",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "131845.04",
  "expiration_date": "2023-06-13"
}, {
  "vmware_agreement": "VMware Agreement # 00634062",
  "customer_name": "Virginia Retirement System (Main)",
  "contract_id": "8285318136064155",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "61293.8",
  "expiration_date": "2023-05-05"
}, {
  "vmware_agreement": "VMware Agreement # 00637792",
  "customer_name": "County of Henrico",
  "contract_id": "8285470599716179",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "64331.85",
  "expiration_date": "2023-05-26"
}, {
  "vmware_agreement": "00634885",
  "customer_name": "Salisbury University (Main)",
  "contract_id": "8285814953082082",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "20340.0",
  "expiration_date": "2023-06-29"
}, {
  "vmware_agreement": "VMware Agreement # 00638699",
  "customer_name": "Electric Power Board",
  "contract_id": "8285071317312220",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "151048.0",
  "expiration_date": "2023-06-06"
}, {
  "vmware_agreement": "00646738",
  "customer_name": "Orange County Public Schools",
  "contract_id": "8286069308864561",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "242765.31",
  "expiration_date": "2023-06-24"
}, {
  "vmware_agreement": "00639109",
  "customer_name": "Jefferson County Commission",
  "contract_id": "8285604306864603",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "131920.0",
  "expiration_date": "2023-06-09"
}, {
  "vmware_agreement": "00679266",
  "customer_name": "UNITED OVERSEAS BANK LIMITED",
  "contract_id": "8285909855125608",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "141458.0",
  "expiration_date": "2023-10-19"
}, {
  "vmware_agreement": "VMware Agreement # 00678554",
  "customer_name": "Aguas Argentinas S.A.",
  "contract_id": "8840543114958420",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "462806.83",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00679519",
  "customer_name": "AURORA PUBLIC SCHOOLS",
  "contract_id": "8284593106867138",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "96261.75",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00678948",
  "customer_name": "NTT COMWARE CORPORATION",
  "contract_id": "8286874809046061",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "50104935.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00676697",
  "customer_name": "Midland County",
  "contract_id": "8285586487014499",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "113649.92",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00679513",
  "customer_name": "Tyler Junior College",
  "contract_id": "8285406659151289",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "175761.76",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679110",
  "customer_name": "Retirement Systems of Alabama",
  "contract_id": "8285538582278664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "281479.44",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Agreement # 00678924",
  "customer_name": "Palm Beach County Sheriff\u0027s Office",
  "contract_id": "8285086274871167",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "206929.62",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00680516",
  "customer_name": "Pinellas County Business Tech",
  "contract_id": "8840252217191806",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "332719.68",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672406",
  "customer_name": "State Of New Jersey",
  "contract_id": "8285707960568339",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "762811.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00672596",
  "customer_name": "Azusa Pacific University",
  "contract_id": "8286538625375983",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "137970.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00674587",
  "customer_name": "Albuquerque Bernalillo County Water Utility Authority",
  "contract_id": "8285746546047131",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "46661.87",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "City Of Philadelphia",
  "contract_id": "8285493947983189",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "978395.42",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00672980",
  "customer_name": "Idaho Department of Health and Welfare",
  "contract_id": "8285145500502791",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "145550.0",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679678",
  "customer_name": "Collier County Sheriff\u0027s Office",
  "contract_id": "8285898387929728",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "131227.41",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00680786",
  "customer_name": "Dallas College",
  "contract_id": "8286023647633739",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "300600.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672107",
  "customer_name": "Oklahoma City Community College",
  "contract_id": "8286396156561146",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "203477.12",
  "expiration_date": "2023-10-03"
}, {
  "vmware_agreement": "00672260",
  "customer_name": "US Navy Navsea",
  "contract_id": "8285144668974254",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "305803.37",
  "expiration_date": "2023-09-13"
}, {
  "vmware_agreement": "00680170",
  "customer_name": "University Of Alabama The Center For Advanced Public Safety - UA Caps",
  "contract_id": "8285977850143621",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "93172.26",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00676512",
  "customer_name": "Douglas Omaha Technology Commission-DOTCOMM",
  "contract_id": "8285679793773642",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "56147.63",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00677103",
  "customer_name": "Arkansas State University",
  "contract_id": "9428987909474821",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "47700.0",
  "expiration_date": "2023-10-17"
}, {
  "vmware_agreement": "00674229",
  "customer_name": "Yavapai County",
  "contract_id": "8286070980992664",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "241335.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00680327",
  "customer_name": "Broward County Board of Commissioners-OIT",
  "contract_id": "8285867331773592",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "387150.16",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00676478",
  "customer_name": "Nevada Department of Transportation",
  "contract_id": "8286396628831549",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "683622.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "VMware Agreement # 00678382",
  "customer_name": "Southern Ute Indian Tribe (Main)",
  "contract_id": "8284958771231709",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "106482.91",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "006723921",
  "customer_name": "Arizona Department of Economic Security",
  "contract_id": "8286359036249850",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "794229.84",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00671993",
  "customer_name": "Northrop Grumman",
  "contract_id": "8286022403034600",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "485236.28",
  "expiration_date": "2023-09-09"
}, {
  "vmware_agreement": "VMware Agreement # 00676596",
  "customer_name": "FLEURY",
  "contract_id": "8285537389373903",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "312720.87",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "VMware Agreement # 00679639",
  "customer_name": "Maryland Department of Public Safety and Correctional Services",
  "contract_id": "8285680088447923",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "368722.44",
  "expiration_date": "2023-11-04"
}, {
  "vmware_agreement": "VMware Agreement # 00671754",
  "customer_name": "Availity, LLC",
  "contract_id": "8286037323899340",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "230434.65",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00672270",
  "customer_name": "Collin County",
  "contract_id": "8840546992690185",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "91513.98",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "VMware Agreement # 00680033",
  "customer_name": "SUNAT",
  "contract_id": "8286269679619032",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "540033.12",
  "expiration_date": "2023-11-03"
}, {
  "vmware_agreement": "00673590",
  "customer_name": "Korea Expressway Corporation",
  "contract_id": "8285745884722478",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "545321.78",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement # 00678671",
  "customer_name": "LATAM Airlines Group S.A.",
  "contract_id": "8285693424465024",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "263802.36",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00680735",
  "customer_name": "FIRST MEDICAL HEALTH PLAN",
  "contract_id": "8286471759726974",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "665344.42",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00671981",
  "customer_name": "MINISTERIO DE SALUD DEL PERU",
  "contract_id": "8286748466497325",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "547894.08",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00681004",
  "customer_name": "Liberty Cablevision Of Puerto Rico",
  "contract_id": "8285815694201133",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "133337.4",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "VMware Agreement # 00680796",
  "customer_name": "BAC Costa Rica - Banco de America Central",
  "contract_id": "8285471131851171",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "150365.4",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00671778",
  "customer_name": "Banco Bolivariano",
  "contract_id": "8287695393119685",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "142956.8",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "VMware Agreement # 00672293",
  "customer_name": "Vanderlande Industries Private Limited",
  "contract_id": "9428987865956558",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "26960.12",
  "expiration_date": "2023-09-19"
}, {
  "vmware_agreement": "00673021",
  "customer_name": "Hdfc Bank Ltd",
  "contract_id": "8286147471592981",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "134904.0",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00679954",
  "customer_name": "Aditya Birla Fashion And Retail Ltd",
  "contract_id": "8286172942141114",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "69508.1",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00680062",
  "customer_name": "Puerto Rico Department of Education",
  "contract_id": "8286347011493261",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "102036.94",
  "expiration_date": "2023-10-25"
}, {
  "vmware_agreement": "00675151",
  "customer_name": "U.S. Department of Transportation",
  "contract_id": "8285563699549654",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "624615.27",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00678350",
  "customer_name": "Omaha Public Power District",
  "contract_id": "8286539377461799",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "69570.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "00672878",
  "customer_name": "Red Link S.A.",
  "contract_id": "8286234751153408",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "231935.55",
  "expiration_date": "2023-09-20"
}, {
  "vmware_agreement": "00594734",
  "customer_name": "Maricopa County Sheriff\u0027s Office",
  "contract_id": "8284781775176703",
  "contract_title": "Amendment No. 4 to Single ELA Resale Agreement",
  "fees": "311543.0",
  "expiration_date": "2023-07-28"
}, {
  "vmware_agreement": "00679699",
  "customer_name": "Maryland State Highway Administration (Main)",
  "contract_id": "8285969437328256",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "385598.38",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00680619",
  "customer_name": "Banco ITAU Paraguay S.A.",
  "contract_id": "8285802004068531",
  "contract_title": "AMENDED SINGLE ELA RESALE AGREEMENT",
  "fees": "226396.5",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00677838",
  "customer_name": "Judicial Council of California",
  "contract_id": "8840540376614989",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "254654.81",
  "expiration_date": "2023-11-14"
}, {
  "vmware_agreement": "00691231",
  "customer_name": "Digital 14",
  "contract_id": "8286055828940907",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "646880.19",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00690777",
  "customer_name": "Puerto Rico Telephone Company Inc",
  "contract_id": "8286698275559395",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "335901.59",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00689950",
  "customer_name": "Armstrong World Industries, Inc",
  "contract_id": "8285651905317728",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "391500.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00691047",
  "customer_name": "KPMG Consulting and Assurance services LLP",
  "contract_id": "8285510014952863",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "42735.28",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "Software License Agreement January 28, 2004",
  "customer_name": "Registro Nacional De Las Personas(Renap)",
  "contract_id": "8285791546385672",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "323962.86",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "Software Licensing Agreement",
  "customer_name": "Ministerio de Hacienda",
  "contract_id": "8286131712713721",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "877314.46",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "00694680",
  "customer_name": "Tucson Electric Power Company",
  "contract_id": "8285897742440913",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "1035552.68",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "VMware Agreement, VMware Contract No. 59242",
  "customer_name": "Servicios Administrativos Wal-Mart S de RL de CV",
  "contract_id": "8285952296096317",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "240282.24",
  "expiration_date": "2023-12-14"
}, {
  "vmware_agreement": "VMware Distributor Exhibit, June 10, 2014",
  "customer_name": "Chesterfield County",
  "contract_id": "8286007701195980",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "54110.55",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00691320",
  "customer_name": "Akwesasne Mohawk Casino",
  "contract_id": "8285588116939833",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "453120.0",
  "expiration_date": "2023-12-29"
}, {
  "vmware_agreement": "00690242",
  "customer_name": "ALBUQUERQUE PUBLIC SCHOOLS",
  "contract_id": "8286414742391852",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "250991.58",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "VMware Agreement 00691574",
  "customer_name": "Mercadolibre S.r.l",
  "contract_id": "8285969492831737",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "924514.11",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Agreement #00691573.0",
  "customer_name": "Meli Log Srl",
  "contract_id": "8285168582955198",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "807814.56",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Midway Financeira S.A",
  "contract_id": "8285043660707439",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "318000.57",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "00681621",
  "customer_name": "Superior Court - Maricopa County",
  "contract_id": "8285071509154642",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "192000.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00692746",
  "customer_name": "EANA - Empresa Argentina de NavegacionAerea",
  "contract_id": "8284438415442332",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "282547.29",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "00693460",
  "customer_name": "GOBIERNO DE LA PROVINCIA DE ENTRE RIOS",
  "contract_id": "8285939020829110",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "291346.25",
  "expiration_date": "2023-01-18"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Claro",
  "contract_id": "8286069995704806",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "251000.0",
  "expiration_date": "2023-09-24"
}, {
  "vmware_agreement": "00689703",
  "customer_name": "Indusind Bank Limited",
  "contract_id": "8285470805006908",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "712800.0",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "VMware Agreement # 00684045",
  "customer_name": "Evertec Puerto Rico",
  "contract_id": "8285280899749168",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "256383.6",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Mmm Healthcare Inc",
  "contract_id": "8285908832184138",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "769502.68",
  "expiration_date": "2023-01-06"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Mmm Healthcare Inc",
  "contract_id": "8286222004542061",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "693682.68",
  "expiration_date": "2023-01-18"
}, {
  "vmware_agreement": "VMware Agreement # 00689882",
  "customer_name": "Grupo Ol - Ol Celular",
  "contract_id": "8286604312808814",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "885337.5",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00690533",
  "customer_name": "Lotte Capital Co. Ltd",
  "contract_id": "8285447199578595",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "138485.39",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00691208",
  "customer_name": "Company General de Electricidad",
  "contract_id": "8285939556573399",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "218924.5",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00684819 and 00682879",
  "customer_name": "Universidad Andres Bello",
  "contract_id": "8285586275383772",
  "contract_title": "SINGLE ELA RESALE AGREEMENT",
  "fees": "208710.0",
  "expiration_date": "2023-12-02"
}, {
  "vmware_agreement": "00675088",
  "customer_name": "Board of Governors of The Federal Reserve System",
  "contract_id": "8840252295681725",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "361173.33",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "00692706",
  "customer_name": "Antel",
  "contract_id": "8285272103379385",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "676094.53",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00690999",
  "customer_name": "Camara De Senadores",
  "contract_id": "8284874937209211",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "315046.61",
  "expiration_date": "2023-02-03"
}, {
  "vmware_agreement": "00683903",
  "customer_name": "Lockheed Martin",
  "contract_id": "8286347304165320",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "472979.16",
  "expiration_date": "2023-08-30"
}, {
  "vmware_agreement": "00684424",
  "customer_name": "Southern California Edison Company",
  "contract_id": "9428987988460282",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "258234.78",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00685346",
  "customer_name": "US Department of State IRM/OPS/SIO",
  "contract_id": "8285678546147438",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "134348.45",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00690710",
  "customer_name": "Advanced Semiconductor Engineering Inc. (日月光半導體製造股份有限公司)",
  "contract_id": "8285234044322280",
  "contract_title": "Single ELA Resale Agreement OEM",
  "fees": "114429.31",
  "expiration_date": "2023-07-19"
}, {
  "vmware_agreement": "00692811",
  "customer_name": "Children\u0027s Hospital of Wisconsin",
  "contract_id": "8285258212228221",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "313995.5",
  "expiration_date": "2023-07-19"
}, {
  "vmware_agreement": "Vmware Distributor Agreement",
  "customer_name": "MN Metropolitan Airports Commission",
  "contract_id": "8286426186846906",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "513630.0",
  "expiration_date": "2023-05-05"
}, {
  "vmware_agreement": "00621988",
  "customer_name": "Program Executive Office",
  "contract_id": "8286282952210130",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "0.0",
  "expiration_date": "2023-03-15"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "City of Chicago",
  "contract_id": "8285509082621365",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "163172.52",
  "expiration_date": "2023-04-14"
}, {
  "vmware_agreement": "VMware Authorized Distributor Partner Program Agreement",
  "customer_name": "Virginia Community College System",
  "contract_id": "8285650627785938",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "272316.07",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "DistributorAgreement",
  "customer_name": "Ncsoft Corporation",
  "contract_id": "8285604850090791",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "166186.56",
  "expiration_date": "2023-04-13"
}, {
  "vmware_agreement": "00730925",
  "customer_name": "Shenzhen BYD Property Insurance Co., Ltd",
  "contract_id": "8286255964339453",
  "contract_title": "Single Enterprise Order Resale Agreement",
  "fees": "999306.24",
  "expiration_date": "2023-08-04"
}, {
  "vmware_agreement": "00433808",
  "customer_name": "Temple University Health System, Inc.(TUHS)",
  "contract_id": "8286555276256502",
  "contract_title": "AM3",
  "fees": "1820774.2",
  "expiration_date": "2023-03-26"
}, {
  "vmware_agreement": "00445427",
  "customer_name": "OneOK",
  "contract_id": "8285746930451732",
  "contract_title": "3Y ELA",
  "fees": "216504.2",
  "expiration_date": "2023-04-24"
}, {
  "vmware_agreement": "00610801",
  "customer_name": "Asurint",
  "contract_id": "8285071934567889",
  "contract_title": "Order Form",
  "fees": "191200.0",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00450827",
  "customer_name": "Wealth Enhancement Group",
  "contract_id": "8285096823608861",
  "contract_title": "Order Form",
  "fees": "0.0",
  "expiration_date": "2023-04-08"
}, {
  "vmware_agreement": "00485892",
  "customer_name": "Sirius Computer Solutions, Inc (main)",
  "contract_id": "8284625741038535",
  "contract_title": "Order Form",
  "fees": "7140.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00607 190",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8285169331779220",
  "contract_title": "VMware ELA",
  "fees": "5772864.82",
  "expiration_date": "2023-01-12"
}, {
  "vmware_agreement": "00672983",
  "customer_name": "Federated Mutual Insurance Companies",
  "contract_id": "8285330924348966",
  "contract_title": "VMware ELA",
  "fees": "273277.35",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00458019",
  "customer_name": "Dentons US LLP",
  "contract_id": "8286686394591319",
  "contract_title": "AMENDED ELA",
  "fees": "0.0",
  "expiration_date": "2023-06-15"
}, {
  "vmware_agreement": "00689545",
  "customer_name": "Morgan \u0026 Morgan",
  "contract_id": "8284370478906361",
  "contract_title": "Q-E00462260",
  "fees": "0.0",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00424572",
  "customer_name": "BAPTIST HEALTH",
  "contract_id": "8284080906306928",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-01"
}, {
  "vmware_agreement": "00591482 \u0026 00591379",
  "customer_name": "National Research Corp",
  "contract_id": "9429038174254204",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-12"
}, {
  "vmware_agreement": "00609703",
  "customer_name": "Allied Integrated Marketing",
  "contract_id": "8840194373036060",
  "contract_title": "ELA ORDER FORM",
  "fees": "119340.0",
  "expiration_date": "2023-01-13"
}, {
  "vmware_agreement": "00607217",
  "customer_name": "Avalara Inc.",
  "contract_id": "8286555252404354",
  "contract_title": "ELA ORDER FORM",
  "fees": "44405.08",
  "expiration_date": "2023-01-19"
}, {
  "vmware_agreement": "006091481",
  "customer_name": "Department of Health",
  "contract_id": "8285121000290894",
  "contract_title": "ELA ORDER FORM",
  "fees": "1643950.0",
  "expiration_date": "2023-01-19"
}, {
  "vmware_agreement": "00596093",
  "customer_name": "City Of Philadelphia DHS",
  "contract_id": "8284658579663738",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00431952",
  "customer_name": "CMG Mortgage Inc.",
  "contract_id": "8840482644202301",
  "contract_title": "ELA ORDER FORM",
  "fees": "22545.31",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00426642",
  "customer_name": "Paytel Communications",
  "contract_id": "8285762567965206",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00427890",
  "customer_name": "Echo Global Logistics",
  "contract_id": "8285317967969271",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-22"
}, {
  "vmware_agreement": "00608397",
  "customer_name": "Continental Resources, Inc.",
  "contract_id": "8285651065782430",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-24"
}, {
  "vmware_agreement": "00610199",
  "customer_name": "Transportation Alliance Bank",
  "contract_id": "8284582142191291",
  "contract_title": "ELA ORDER FORM",
  "fees": "35269.42",
  "expiration_date": "2023-01-24"
}, {
  "vmware_agreement": "00609517",
  "customer_name": "Evolent Health",
  "contract_id": "8285563200493100",
  "contract_title": "ELA ORDER FORM",
  "fees": "16999.84",
  "expiration_date": "2023-01-25"
}, {
  "vmware_agreement": "00610646",
  "customer_name": "iSelect Ltd",
  "contract_id": "8285372864114861",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-25"
}, {
  "vmware_agreement": "00608648",
  "customer_name": "SGA Design Group",
  "contract_id": "8285616812687274",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-25"
}, {
  "vmware_agreement": "00610942",
  "customer_name": "Tideworks Technology",
  "contract_id": "8285259085245087",
  "contract_title": "ELA ORDER FORM",
  "fees": "36277.12",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00611197",
  "customer_name": "Tri-State Generation and Transmission Association, Inc.",
  "contract_id": "8284781674702778",
  "contract_title": "ELA ORDER FORM",
  "fees": "158270.4",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00607221",
  "customer_name": "Advanced Energy Industries, Inc. (Main)",
  "contract_id": "8284445241216088",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00603062",
  "customer_name": "Eversana Life Science Services",
  "contract_id": "8285278895976827",
  "contract_title": "ELA ORDER FORM",
  "fees": "51498.36",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00431545",
  "customer_name": "Calumet Lubricants CO",
  "contract_id": "8284217225433575",
  "contract_title": "ELA ORDER FORM",
  "fees": "57592.59",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "00611189",
  "customer_name": "S\u0026B Engineers and Constructors",
  "contract_id": "8285194348111676",
  "contract_title": "ELA ORDER FORM",
  "fees": "104052.48",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00610373",
  "customer_name": "EARLY WARNING SERVICES, LLC *",
  "contract_id": "8285043482127305",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00596100",
  "customer_name": "Fusion BPO Services",
  "contract_id": "8285331517097191",
  "contract_title": "ELA ORDER FORM",
  "fees": "91467.29",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00610613",
  "customer_name": "INFINERA CORPORATION",
  "contract_id": "8285420099184845",
  "contract_title": "ELA ORDER FORM",
  "fees": "18197.81",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00431513",
  "customer_name": "Amtrust North America, Inc",
  "contract_id": "8285651064937772",
  "contract_title": "ELA ORDER FORM",
  "fees": "292161.91",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00431391",
  "customer_name": "American University",
  "contract_id": "8285270797322050",
  "contract_title": "ELA ORDER FORM",
  "fees": "86556.86",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00434753",
  "customer_name": "Eli Research India Private Ltd",
  "contract_id": "8285017035797468",
  "contract_title": "ELA ORDER FORM",
  "fees": "12419.97",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "004310661",
  "customer_name": "Baker Hughes Oilfield Operations LLC",
  "contract_id": "8284909631215436",
  "contract_title": "ELA ORDER FORM",
  "fees": "201250.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00428103",
  "customer_name": "Matrix Medical Network",
  "contract_id": "8285204709213930",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00434590",
  "customer_name": "MCMILLAN SHAKESPEARE LTD",
  "contract_id": "8285429652383535",
  "contract_title": "ELA ORDER FORM",
  "fees": "108988.61",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00431728",
  "customer_name": "Retail Solutions, Inc.",
  "contract_id": "8285842847291402",
  "contract_title": "ELA ORDER FORM",
  "fees": "109352.96",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00615527 \u0026 00618986",
  "customer_name": "Apple Federal Credit Union",
  "contract_id": "8286686392870336",
  "contract_title": "ELA ORDER FORM",
  "fees": "1259.7",
  "expiration_date": "2023-02-22"
}, {
  "vmware_agreement": "006103801",
  "customer_name": "GolfNow",
  "contract_id": "8840288996815625",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-03"
}, {
  "vmware_agreement": "00614194",
  "customer_name": "Philips Healthcare",
  "contract_id": "8285096905426646",
  "contract_title": "ELA ORDER FORM",
  "fees": "357000.0",
  "expiration_date": "2023-03-08"
}, {
  "vmware_agreement": "00618642",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285616654132033",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0",
  "expiration_date": "2023-03-08"
}, {
  "vmware_agreement": "00617847 \u0026 # 00612020",
  "customer_name": "Eisai, Inc.",
  "contract_id": "8285358126686170",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-10"
}, {
  "vmware_agreement": "00437711",
  "customer_name": "Firsthealth Of The Carolinas - Main",
  "contract_id": "8285332127656241",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-17"
}, {
  "vmware_agreement": "00619014",
  "customer_name": "El Camino Hospital",
  "contract_id": "8285032543108587",
  "contract_title": "ELA ORDER FORM",
  "fees": "83400.0",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "00538559",
  "customer_name": "ACT Government Shared Services (InTACT)",
  "contract_id": "8285057793959738",
  "contract_title": "ELA ORDER FORM",
  "fees": "479752.19",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "00544987",
  "customer_name": "Sugar Creek Packing Co.",
  "contract_id": "8284815516352761",
  "contract_title": "ELA ORDER FORM",
  "fees": "723060.67",
  "expiration_date": "2023-03-28"
}, {
  "vmware_agreement": "00625064",
  "customer_name": "SB C\u0026S CORP.",
  "contract_id": "8284909980166258",
  "contract_title": "ELA ORDER FORM",
  "fees": "300000.0",
  "expiration_date": "2023-03-29"
}, {
  "vmware_agreement": "00425792",
  "customer_name": "NEW ZEALAND QUALIFICATIONS AUTHORITY",
  "contract_id": "8285494068207587",
  "contract_title": "ELA ORDER FORM",
  "fees": "79349.0",
  "expiration_date": "2023-03-29"
}, {
  "vmware_agreement": "00356663",
  "customer_name": "CHU Sainte-Justine",
  "contract_id": "8285371648590520",
  "contract_title": "ELA ORDER FORM",
  "fees": "231559.54",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00442812",
  "customer_name": "Xilinx, Inc.",
  "contract_id": "8285829590809980",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-07"
}, {
  "vmware_agreement": "00437586",
  "customer_name": "Unitedlex",
  "contract_id": "8284957273679346",
  "contract_title": "ELA ORDER FORM",
  "fees": "221082.84",
  "expiration_date": "2023-04-08"
}, {
  "vmware_agreement": "00442418",
  "customer_name": "Docupace Technologies",
  "contract_id": "8284427493457538",
  "contract_title": "ELA ORDER FORM",
  "fees": "35783.64",
  "expiration_date": "2023-04-14"
}, {
  "vmware_agreement": "00629472",
  "customer_name": "Synergent",
  "contract_id": "8284638893123364",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-15"
}, {
  "vmware_agreement": "00444778",
  "customer_name": "Catholic Health Services of Long Island",
  "contract_id": "8284438529140189",
  "contract_title": "ELA ORDER FORM",
  "fees": "33013.56",
  "expiration_date": "2023-04-16"
}, {
  "vmware_agreement": "004398471",
  "customer_name": "Ford Bacon and Davis LLC",
  "contract_id": "8285279711968434",
  "contract_title": "ELA ORDER FORM",
  "fees": "34756.56",
  "expiration_date": "2023-04-20"
}, {
  "vmware_agreement": "00443545",
  "customer_name": "IBG LLC",
  "contract_id": "8286555254582665",
  "contract_title": "ELA ORDER FORM",
  "fees": "217783.22",
  "expiration_date": "2023-04-22"
}, {
  "vmware_agreement": "00631913",
  "customer_name": "Matrix Applications",
  "contract_id": "8286686396742459",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-25"
}, {
  "vmware_agreement": "00619135",
  "customer_name": "14 W ADMINISTRATIVE SERVICES, LLC (FORMERLY KNOWN ASAGORA, INC.)",
  "contract_id": "8284936520955048",
  "contract_title": "ELA ORDER FORM",
  "fees": "19698.3",
  "expiration_date": "2023-04-27"
}, {
  "vmware_agreement": "00457424",
  "customer_name": "Mount Sinai Hospital Medical Center of Chicago",
  "contract_id": "8840147339918274",
  "contract_title": "ELA ORDER FORM",
  "fees": "59301.0",
  "expiration_date": "2023-04-29"
}, {
  "vmware_agreement": "00637658",
  "customer_name": "Bank Of Montreal*",
  "contract_id": "8285169123807414",
  "contract_title": "ELA ORDER FORM",
  "fees": "819521.953",
  "expiration_date": "2023-05-31"
}, {
  "vmware_agreement": "00641229",
  "customer_name": "Cholamandalam Investment and Finance Company Ltd",
  "contract_id": "8840444582633795",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-06-15"
}, {
  "vmware_agreement": "00645747",
  "customer_name": "WOLVERINE WORLD WIDE, INC.",
  "contract_id": "8285317925644712",
  "contract_title": "ELA ORDER FORM",
  "fees": "164822.93",
  "expiration_date": "2023-06-29"
}, {
  "vmware_agreement": "00472532",
  "customer_name": "WIRB-Copernicus Group, Inc.",
  "contract_id": "8285158135025387",
  "contract_title": "ELA ORDER FORM",
  "fees": "200004.72",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00637336",
  "customer_name": "Kyndryl Australia Pty Ltd",
  "contract_id": "8284889662552618",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-07-04"
}, {
  "vmware_agreement": "00638488",
  "customer_name": "Canadian Tolling Company International Inc.",
  "contract_id": "8284003587869361",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-07-05"
}, {
  "vmware_agreement": "006458941",
  "customer_name": "NETWORLD CORPORATION",
  "contract_id": "8285545972529533",
  "contract_title": "ELA ORDER FORM",
  "fees": "1000000.0",
  "expiration_date": "2023-07-07"
}, {
  "vmware_agreement": "00611032",
  "customer_name": "Intuit Inc.",
  "contract_id": "8284911497114039",
  "contract_title": "ELA ORDER FORM",
  "fees": "1431316.6",
  "expiration_date": "2023-07-11"
}, {
  "vmware_agreement": "00478159",
  "customer_name": "Deluxe Entertainment Services Inc.",
  "contract_id": "8285258144112677",
  "contract_title": "ELA ORDER FORM",
  "fees": "104266.94",
  "expiration_date": "2023-07-30"
}, {
  "vmware_agreement": "00476355",
  "customer_name": "Utah Education Network",
  "contract_id": "8285707019409389",
  "contract_title": "ELA ORDER FORM",
  "fees": "121687.68",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00482766",
  "customer_name": "Holmesglen Institute",
  "contract_id": "8285546082407091",
  "contract_title": "ELA ORDER FORM",
  "fees": "310033.2",
  "expiration_date": "2023-08-25"
}, {
  "vmware_agreement": "00669854",
  "customer_name": "General Electric",
  "contract_id": "8284909885442618",
  "contract_title": "ELA ORDER FORM",
  "fees": "163800.0",
  "expiration_date": "2023-08-31"
}, {
  "vmware_agreement": "00484343",
  "customer_name": "United Services Automobile Association",
  "contract_id": "8284853251743963",
  "contract_title": "ELA ORDER FORM",
  "fees": "236026.56",
  "expiration_date": "2023-09-22"
}, {
  "vmware_agreement": "00482975",
  "customer_name": "Ethoca Technologies Inc.",
  "contract_id": "8285446844544202",
  "contract_title": "ELA ORDER FORM",
  "fees": "178835.28",
  "expiration_date": "2023-09-23"
}, {
  "vmware_agreement": "00490916",
  "customer_name": "State Of Vermont",
  "contract_id": "8284888121673572",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-09-23"
}, {
  "vmware_agreement": "00491961",
  "customer_name": "Robert W Baird \u0026 Co Incorporated",
  "contract_id": "8284983433291392",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-09-25"
}, {
  "vmware_agreement": "00489258",
  "customer_name": "Vistra Corporate Services Company",
  "contract_id": "8285814127943584",
  "contract_title": "ELA ORDER FORM",
  "fees": "185380.88",
  "expiration_date": "2023-10-02"
}, {
  "vmware_agreement": "00675157",
  "customer_name": "Major Hospital",
  "contract_id": "8285679793061560",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-04"
}, {
  "vmware_agreement": "00483156",
  "customer_name": "Budage Pty Ltd ATF Tonclay Services Trust",
  "contract_id": "8285330513832121",
  "contract_title": "ELA ORDER FORM",
  "fees": "74088.75",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00493093",
  "customer_name": "VIRGIN AUSTRALIA",
  "contract_id": "8285270950361734",
  "contract_title": "ELA ORDER FORM",
  "fees": "64277.14",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00494931",
  "customer_name": "DHI Computing Service",
  "contract_id": "8284162209865579",
  "contract_title": "ELA ORDER FORM",
  "fees": "75107.54",
  "expiration_date": "2023-10-12"
}, {
  "vmware_agreement": "00676335",
  "customer_name": "Monash Health",
  "contract_id": "8285304836336956",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-13"
}, {
  "vmware_agreement": "00494673",
  "customer_name": "Eating Recovery Center",
  "contract_id": "8285008570074491",
  "contract_title": "ELA ORDER FORM",
  "fees": "110882.02",
  "expiration_date": "2023-10-16"
}, {
  "vmware_agreement": "00675155",
  "customer_name": "Westat, Inc",
  "contract_id": "8285570970662030",
  "contract_title": "ELA ORDER FORM",
  "fees": "3575.92",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00679586",
  "customer_name": "Monash Health",
  "contract_id": "8285456352207682",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-20"
}, {
  "vmware_agreement": "00493651",
  "customer_name": "Keysight Technologies. Inc",
  "contract_id": "8284497575772843",
  "contract_title": "ELA ORDER FORM",
  "fees": "355053.92",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00494975",
  "customer_name": "MSC, Inc",
  "contract_id": "8284957527850972",
  "contract_title": "ELA ORDER FORM",
  "fees": "74875.81",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00679346",
  "customer_name": "Adient",
  "contract_id": "8284368975829357",
  "contract_title": "ELA ORDER FORM",
  "fees": "161711.63",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "00497605",
  "customer_name": "Catalyst Corporate Federal Credit Union",
  "contract_id": "8284935642730213",
  "contract_title": "ELA ORDER FORM",
  "fees": "106286.57",
  "expiration_date": "2023-10-23"
}, {
  "vmware_agreement": "00491705",
  "customer_name": "Hanger, Inc.",
  "contract_id": "8285258948814610",
  "contract_title": "ELA ORDER FORM",
  "fees": "250060.66",
  "expiration_date": "2023-10-23"
}, {
  "vmware_agreement": "00673306 \u0026 00673122",
  "customer_name": "Rubrik Inc",
  "contract_id": "8285043058373209",
  "contract_title": "ELA ORDER FORM",
  "fees": "265000.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00493288",
  "customer_name": "National Education Association",
  "contract_id": "8285418517837267",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00492592",
  "customer_name": "Pacific National",
  "contract_id": "8284949981943756",
  "contract_title": "ELA ORDER FORM",
  "fees": "96361.7",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00678575",
  "customer_name": "NEW ZEALAND CUSTOMS SERVICE",
  "contract_id": "8283931184374994",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00488988",
  "customer_name": "Bessemer Trust Co",
  "contract_id": "8285043389782026",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00673639",
  "customer_name": "Washington Gas Light Company",
  "contract_id": "8284949978939100",
  "contract_title": "ELA ORDER FORM",
  "fees": "139484.59",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00493648",
  "customer_name": "AM GENERAL LLC",
  "contract_id": "8284829712141788",
  "contract_title": "ELA ORDER FORM",
  "fees": "334500.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00590065",
  "customer_name": "Boart Longyear Company",
  "contract_id": "8840294150386995",
  "contract_title": "ELA ORDER FORM",
  "fees": "52543.73",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00498108",
  "customer_name": "Powerschool Group LLC",
  "contract_id": "8285640768989759",
  "contract_title": "ELA ORDER FORM",
  "fees": "250046.44",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00500430",
  "customer_name": "Bendigo Kangan Institute",
  "contract_id": "8285429666764391",
  "contract_title": "ELA ORDER FORM",
  "fees": "54116.01",
  "expiration_date": "2023-11-17"
}, {
  "vmware_agreement": "00503225",
  "customer_name": "Weta Digital Limited",
  "contract_id": "8285790850049324",
  "contract_title": "ELA ORDER FORM",
  "fees": "30210.91",
  "expiration_date": "2023-11-17"
}, {
  "vmware_agreement": "00683187",
  "customer_name": "Navitas Limited",
  "contract_id": "8286686397149708",
  "contract_title": "ELA ORDER FORM",
  "fees": "8365.86",
  "expiration_date": "2023-11-21"
}, {
  "vmware_agreement": "00677150",
  "customer_name": "Snap Inc",
  "contract_id": "8285563084709537",
  "contract_title": "ELA ORDER FORM",
  "fees": "82320.67",
  "expiration_date": "2023-11-22"
}, {
  "vmware_agreement": "005060121",
  "customer_name": "Neuberger Berman",
  "contract_id": "8284995564747645",
  "contract_title": "ELA ORDER FORM",
  "fees": "29627.11",
  "expiration_date": "2023-11-23"
}, {
  "vmware_agreement": "00503177",
  "customer_name": "GoDaddy.com, LLC",
  "contract_id": "8285167531015969",
  "contract_title": "ELA ORDER FORM",
  "fees": "1725683.37",
  "expiration_date": "2023-11-24"
}, {
  "vmware_agreement": "00679106",
  "customer_name": "FATCO Holdings, LLC",
  "contract_id": "8284781092970263",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00683770",
  "customer_name": "Ringcentral Inc",
  "contract_id": "8285746503724836",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00507476",
  "customer_name": "TBC Corporation",
  "contract_id": "8285897663413291",
  "contract_title": "ELA ORDER FORM",
  "fees": "327573.09",
  "expiration_date": "2023-11-30"
}, {
  "vmware_agreement": "00681901",
  "customer_name": "WESTCHESTER COUNTY DOIT",
  "contract_id": "8286159597307065",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-05"
}, {
  "vmware_agreement": "00518262",
  "customer_name": "FIRSTSOURCE SOLUTIONS LIMITED",
  "contract_id": "8284971816724795",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-07"
}, {
  "vmware_agreement": "00500281",
  "customer_name": "Albright-Knox Art Gallery",
  "contract_id": "8285143976595179",
  "contract_title": "ELA ORDER FORM",
  "fees": "31467.85",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00683422",
  "customer_name": "POSTMEDIA BUSINESS TECHNOLOGY",
  "contract_id": "8285058220160617",
  "contract_title": "ELA ORDER FORM",
  "fees": "20908.94",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00505979",
  "customer_name": "Old Republic National Title Holding Company",
  "contract_id": "8840183845593901",
  "contract_title": "ELA ORDER FORM",
  "fees": "232949.54",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00359052",
  "customer_name": "Device Technologies Australia Pty. Ltd.",
  "contract_id": "8285279597357152",
  "contract_title": "ELA ORDER FORM",
  "fees": "38723.52",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00684716",
  "customer_name": "Department of Foreign Affairs and Trade (DFAT)",
  "contract_id": "8285625804866516",
  "contract_title": "ELA ORDER FORM",
  "fees": "2791692.9",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00689685",
  "customer_name": "Cornerstone OnDemand (main)",
  "contract_id": "8284957400941671",
  "contract_title": "ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00684990",
  "customer_name": "Advantage Sales \u0026 Marketing LLC",
  "contract_id": "8284476558156024",
  "contract_title": "ELA ORDER FORM",
  "fees": "982.39",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00675143",
  "customer_name": "PROS INC., a Delaware Corporation",
  "contract_id": "8285814130358013",
  "contract_title": "ELA ORDER FORM",
  "fees": "1259.7",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00657476",
  "customer_name": "CompuCom Systems, Inc.",
  "contract_id": "8285129504695844",
  "contract_title": "ELA Order Form",
  "fees": "353099.26",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00549056",
  "customer_name": "Computer Sciences Corporation",
  "contract_id": "8285651076159166",
  "contract_title": "ELA Order Form",
  "fees": "2410348.0",
  "expiration_date": "2023-11-06"
}, {
  "vmware_agreement": "00362272",
  "customer_name": "Health-Quest Systems, Inc.",
  "contract_id": "8285059532376641",
  "contract_title": "VMC AWS ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "00610594",
  "customer_name": "Larkin Hoffman Daly \u0026 Lindgren",
  "contract_id": "8285097492541234",
  "contract_title": "VMware SPP Agreement",
  "fees": "0.0",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00435668",
  "customer_name": "Sterling Computers",
  "contract_id": "8285017743504964",
  "contract_title": "VMware SPP Agreement",
  "fees": "35140.0",
  "expiration_date": "2023-02-18"
}, {
  "vmware_agreement": "00550662",
  "customer_name": "Red Ventures LLC",
  "contract_id": "8285790479076540",
  "contract_title": "VMware SPP Agreement",
  "fees": "0.0",
  "expiration_date": "2023-04-28"
}, {
  "vmware_agreement": "00482107",
  "customer_name": "Weatlh Enhancement Group",
  "contract_id": "8285563177821180",
  "contract_title": "VMware SPP Agreement",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8284195515360653",
  "contract_title": "VMware SPP Order Form",
  "fees": "18000.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00597075",
  "customer_name": "Quality Bicycle Products, GBC.",
  "contract_id": "8285071112513777",
  "contract_title": "VMware SPP Order Form",
  "fees": "18000.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00611205 \u0026 # 00611185",
  "customer_name": "GlobalSpec, LLC",
  "contract_id": "8286587579261032",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "00611205 \u0026 # 00611185",
  "customer_name": "GlobalSpec, LLC",
  "contract_id": "8285456335779724",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-01-27"
}, {
  "vmware_agreement": "00431989",
  "customer_name": "TECHSOUP GLOBAL",
  "contract_id": "8284935597053185",
  "contract_title": "VMware SPP Order Form",
  "fees": "562503.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00436206",
  "customer_name": "Oroville Hospital",
  "contract_id": "8840498005692064",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-04-01"
}, {
  "vmware_agreement": "00475205",
  "customer_name": "Geovera Insurance Company",
  "contract_id": "8284080753976274",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-23"
}, {
  "vmware_agreement": "00477274",
  "customer_name": "National Research Corp",
  "contract_id": "8284910110344203",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-27"
}, {
  "vmware_agreement": "00485464",
  "customer_name": "Harvard University",
  "contract_id": "8285447240416317",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00503161",
  "customer_name": "ClearStructure Financial Technology",
  "contract_id": "8285897640145704",
  "contract_title": "VMware SPP Order Form",
  "fees": "0.0",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00607487",
  "customer_name": "Sheetz, Inc.",
  "contract_id": "8284924675305798",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "110217.14",
  "expiration_date": "2023-01-10"
}, {
  "vmware_agreement": "00431567",
  "customer_name": "Quotient Technology Inc.",
  "contract_id": "8285233846443853",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "207839.99",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "00618930",
  "customer_name": "The Commonwealth of Australia, represented by the Department Of Home Affairs",
  "contract_id": "8284950310707782",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "136657.5",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00695746",
  "customer_name": "Hyatt Corporation",
  "contract_id": "8284702736873005",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-03"
}, {
  "vmware_agreement": "00695746",
  "customer_name": "Hyatt Corporation",
  "contract_id": "8285043378385894",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-03"
}, {
  "vmware_agreement": "00435305",
  "customer_name": "El Camino Hospital",
  "contract_id": "8284154308559072",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-06"
}, {
  "vmware_agreement": "00444331",
  "customer_name": "Citco Technology Management, Inc",
  "contract_id": "8284995686437036",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-17"
}, {
  "vmware_agreement": "00431181",
  "customer_name": "The Bank of Nova Scotia",
  "contract_id": "8284873580805078",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-23"
}, {
  "vmware_agreement": "00435621",
  "customer_name": "Microsoft",
  "contract_id": "8284694501085331",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00435621",
  "customer_name": "Microsoft",
  "contract_id": "8284513406687782",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00541796",
  "customer_name": "LATHAM \u0026WATKINS LLP",
  "contract_id": "8285044570535988",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "662857.12",
  "expiration_date": "2023-04-19"
}, {
  "vmware_agreement": "00631576",
  "customer_name": "Netapp",
  "contract_id": "8284956980126740",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1852721.95",
  "expiration_date": "2023-04-22"
}, {
  "vmware_agreement": "00691026",
  "customer_name": "VAN ECK GLOBAL",
  "contract_id": "8284781449065641",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-26"
}, {
  "vmware_agreement": "00673661",
  "customer_name": "VAN ECK GLOBAL",
  "contract_id": "8285122002272751",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-28"
}, {
  "vmware_agreement": "00546266",
  "customer_name": "International Flavors \u0026 Fragrances Inc.",
  "contract_id": "8285280446387343",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "00436039",
  "customer_name": "Biotronik Corporate Services U.S., Inc.",
  "contract_id": "8284984140685187",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-05-01"
}, {
  "vmware_agreement": "00634116",
  "customer_name": "CW Retail Services Pty Ltd",
  "contract_id": "8284727829836244",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-05-13"
}, {
  "vmware_agreement": "00631725",
  "customer_name": "Total Quality Logistics, LLC",
  "contract_id": "8284875040260812",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "132603.49",
  "expiration_date": "2023-06-17"
}, {
  "vmware_agreement": "00543219",
  "customer_name": "Catholic Health Services of Long Island",
  "contract_id": "8285130426311889",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-06-25"
}, {
  "vmware_agreement": "00639437",
  "customer_name": "Compass Health Network",
  "contract_id": "8285240826400401",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-07-18"
}, {
  "vmware_agreement": "00650756",
  "customer_name": "Total Quality Logistics, LLC",
  "contract_id": "8285032318083949",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "175504.21",
  "expiration_date": "2023-07-24"
}, {
  "vmware_agreement": "00477102",
  "customer_name": "Montage Health",
  "contract_id": "8285470430923181",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00476973",
  "customer_name": "LG CNS Co Ltd",
  "contract_id": "8284958887649952",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1638400.0",
  "expiration_date": "2023-08-20"
}, {
  "vmware_agreement": "00559511",
  "customer_name": "Northwell Health. Inc.",
  "contract_id": "8284715931948551",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-08-29"
}, {
  "vmware_agreement": "00671752",
  "customer_name": "IPC Systems, Inc.",
  "contract_id": "8285372238753531",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-09-14"
}, {
  "vmware_agreement": "00493253",
  "customer_name": "Alpine School District",
  "contract_id": "8285168311198795",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-05"
}, {
  "vmware_agreement": "00678369",
  "customer_name": "Siteone Landscape Supply",
  "contract_id": "8284648707603353",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00677203",
  "customer_name": "Blue Origin LLC",
  "contract_id": "8284414423724767",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "179584.24",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00497108",
  "customer_name": "Hitachi, Ltd.",
  "contract_id": "8285121487131967",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "45110757.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "006785591",
  "customer_name": "CONOCOPHILLIPS COMPANY",
  "contract_id": "8283960730973742",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "1200000.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00496687",
  "customer_name": "New York City Health and Hospitals Corporation",
  "contract_id": "9428934098353041",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-29"
}, {
  "vmware_agreement": "00498100",
  "customer_name": "Fidelity Information Services, LLC",
  "contract_id": "8284752912294912",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00494247",
  "customer_name": "METROPLUS HEALTH PLAN INC",
  "contract_id": "8285641391497410",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00394278",
  "customer_name": "Valley National Bank",
  "contract_id": "8840294155775144",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "100000.0",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00681753",
  "customer_name": "Medidata Solutions",
  "contract_id": "8284694309373333",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "556629.57",
  "expiration_date": "2023-11-08"
}, {
  "vmware_agreement": "00672861",
  "customer_name": "Wellstar Health Systems, Inc.",
  "contract_id": "8285179164893471",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-11"
}, {
  "vmware_agreement": "00497759",
  "customer_name": "CBC-Radio Canada",
  "contract_id": "8285257656629404",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00684649",
  "customer_name": "City Of Philadelphia DHS",
  "contract_id": "8285319262983429",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-09"
}, {
  "vmware_agreement": "00499466",
  "customer_name": "The Bank of Nova Scotia",
  "contract_id": "8285841884161126",
  "contract_title": "AMENDED ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-12-30"
}, {
  "vmware_agreement": "00706826",
  "customer_name": "Albermarle Corporation",
  "contract_id": "8284889816695982",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-04-17"
}, {
  "vmware_agreement": "00706947",
  "customer_name": "Peel Regional Police",
  "contract_id": "8284899120077299",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-04-18"
}, {
  "vmware_agreement": "00708774",
  "customer_name": "Omni Hotels",
  "contract_id": "8284804634667945",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-04-20"
}, {
  "vmware_agreement": "00708780",
  "customer_name": "Yavapai Regional Medical Center Foundation",
  "contract_id": "8285178894901455",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-05-03"
}, {
  "vmware_agreement": "00706938",
  "customer_name": "Pure storage Inc",
  "contract_id": "8284936403115805",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-08-03"
}, {
  "vmware_agreement": "00706938",
  "customer_name": "Pure storage Inc",
  "contract_id": "8285483167612291",
  "contract_title": "VMware Enterprise Order",
  "fees": "0.0",
  "expiration_date": "2023-08-03"
}, {
  "vmware_agreement": "00729178",
  "customer_name": "Leonardo DRS",
  "contract_id": "8286686396398481",
  "contract_title": "VMware Enterprise Order",
  "fees": "150000.0",
  "expiration_date": "2023-08-04"
}, {
  "vmware_agreement": "00678542",
  "customer_name": "HRB Tax Group, Inc.",
  "contract_id": "8284852666444121",
  "contract_title": "H\u0026R Block \u0026 VMware - ELA",
  "fees": "0.0",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00631323",
  "customer_name": "PODS ENTERPRISES LLC",
  "contract_id": "8285603850704244",
  "contract_title": "PODS VMware Renewal 2022",
  "fees": "11317.92",
  "expiration_date": "2023-04-19"
}, {
  "vmware_agreement": "00678895",
  "customer_name": "Environmental System Research Institute, Inc.",
  "contract_id": "8285378186295660",
  "contract_title": "A - 10 - Procurement Agmt",
  "fees": "231985.14",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00676698",
  "customer_name": "Stonex Group Inc.",
  "contract_id": "8284873265704425",
  "contract_title": "Amendment #6 to ELA Order",
  "fees": "156836.97",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00358127",
  "customer_name": "Hydro-Quebec-Parent *",
  "contract_id": "8285494402760936",
  "contract_title": "VMC ON AWS ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-11-01"
}, {
  "vmware_agreement": "00431540",
  "customer_name": "Express LLC",
  "contract_id": "8285455124864091",
  "contract_title": "VMware ELA 3 Year Renewal",
  "fees": "303394.27",
  "expiration_date": "2023-04-03"
}, {
  "vmware_agreement": "00498447",
  "customer_name": "Gilead Sciences, Inc.",
  "contract_id": "8285258896213863",
  "contract_title": "Amendment No. 8 to the ELA",
  "fees": "1048058.52",
  "expiration_date": "2023-10-30"
}, {
  "vmware_agreement": "00683157",
  "customer_name": "Gilead Sciences Inc",
  "contract_id": "8285318438890933",
  "contract_title": "Amendment No. 9 TO THE ELA",
  "fees": "292423.82",
  "expiration_date": "2023-12-13"
}, {
  "vmware_agreement": "00685149",
  "customer_name": "Merck Sharp \u0026 Dohme LLC",
  "contract_id": "8284613943562515",
  "contract_title": "VMWare Credits Organon TSA",
  "fees": "0.0",
  "expiration_date": "2023-12-16"
}, {
  "vmware_agreement": "00373603",
  "customer_name": "Ecopetrol S.A",
  "contract_id": "8284996595922166",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "102450.38",
  "expiration_date": "2023-09-07"
}, {
  "vmware_agreement": "00576291",
  "customer_name": "United Services Automobile Association",
  "contract_id": "8285455040172673",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "310887.35",
  "expiration_date": "2023-09-13"
}, {
  "vmware_agreement": "00495148",
  "customer_name": "Mercy Health",
  "contract_id": "8285456833539670",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "71824.62",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00588512",
  "customer_name": "State of Louisiana Division of Administration",
  "contract_id": "8285222106216439",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "528980.25",
  "expiration_date": "2023-12-15"
}, {
  "vmware_agreement": "00684227",
  "customer_name": "Nationale Politie",
  "contract_id": "9429038153647456",
  "contract_title": "Single ELA Resale Agreement",
  "fees": "687047.42",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00609816",
  "customer_name": "The Procter \u0026 Gamble U.S. Business Services Company",
  "contract_id": "8285130422851601",
  "contract_title": "VMware Agreement # 00609816",
  "fees": "0.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00594371",
  "customer_name": "Accruent Inc",
  "contract_id": "8284601643314754",
  "contract_title": "Enterprise License Agreement",
  "fees": "233784.17",
  "expiration_date": "2023-01-05"
}, {
  "vmware_agreement": "005402171",
  "customer_name": "Toll Holdings Limited",
  "contract_id": "8285034038384498",
  "contract_title": "Enterprise License Agreement",
  "fees": "31625.28",
  "expiration_date": "2023-03-18"
}, {
  "vmware_agreement": "00639430",
  "customer_name": "United Healthcare Services, Inc.",
  "contract_id": "8285271935913669",
  "contract_title": "Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-08-15"
}, {
  "vmware_agreement": "00674210",
  "customer_name": "Global Companies LLC",
  "contract_id": "8285258318122316",
  "contract_title": "Enterprise License Agreement",
  "fees": "32342.28",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00679670",
  "customer_name": "Brink\u0027s Incorporated",
  "contract_id": "8285279691395703",
  "contract_title": "Enterprise License Agreement",
  "fees": "400000.0",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00680515",
  "customer_name": "YRC Enterprise Services Inc",
  "contract_id": "8285059539312492",
  "contract_title": "Enterprise License Agreement",
  "fees": "249997.78",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00680357",
  "customer_name": "Apple Inc",
  "contract_id": "8285305277723892",
  "contract_title": "Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-11-07"
}, {
  "vmware_agreement": "00595004",
  "customer_name": "Signet Jewelers",
  "contract_id": "8285813626087980",
  "contract_title": "Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-10"
}, {
  "vmware_agreement": "00705722",
  "customer_name": "SINGTEL OPTUS PTY LTD",
  "contract_id": "8284958771843284",
  "contract_title": "vmware®Quote #: Q-E00564836",
  "fees": "0.0",
  "expiration_date": "2023-03-29"
}, {
  "vmware_agreement": "00595541",
  "customer_name": "Mazda North America",
  "contract_id": "8284415482108174",
  "contract_title": "12-Month VMware ELA Extension",
  "fees": "540755.21",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00685106",
  "customer_name": "American Multi-Cinema, Inc.",
  "contract_id": "8284972243631318",
  "contract_title": "AMC - VMWare - ELA Order Form",
  "fees": "74134.86",
  "expiration_date": "2023-12-19"
}, {
  "vmware_agreement": "00441841",
  "customer_name": "Sony Pictures Entertainment Procurement Services Inc.",
  "contract_id": "8285641516423436",
  "contract_title": "VMware Cloud on AWS SPP Credits",
  "fees": "0.0",
  "expiration_date": "2023-04-15"
}, {
  "vmware_agreement": "00433225",
  "customer_name": "Liberty Mutual Insurance Company",
  "contract_id": "8285279992414400",
  "contract_title": "VMware Master License Agreement",
  "fees": "88506.25",
  "expiration_date": "2023-01-29"
}, {
  "vmware_agreement": "00619594",
  "customer_name": "Ringcentral, Inc.",
  "contract_id": "8285678551471118",
  "contract_title": "Amendment No. 7 to the ELA Order",
  "fees": "46107.82",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "00676129",
  "customer_name": "Bath \u0026 Body Works, Inc.",
  "contract_id": "8284346945021289",
  "contract_title": "Amendment No. 7 to the ELA Order",
  "fees": "758751.38",
  "expiration_date": "2023-11-21"
}, {
  "vmware_agreement": "00671884",
  "customer_name": "PTC Inc, a Massachusetts corporation (f/k/a Parametric Technology Corporation)",
  "contract_id": "8285109504224438",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "293018.84",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00671884",
  "customer_name": "PTC Inc, a Massachusetts corporation (f/k/a Parametric Technology Corporation)",
  "contract_id": "8285289635719434",
  "contract_title": "Amendment No. 9 to the ELA Order",
  "fees": "293018.84",
  "expiration_date": "2023-09-29"
}, {
  "vmware_agreement": "00607521",
  "customer_name": "Ally Financial Inc",
  "contract_id": "8285167367267769",
  "contract_title": "VMware Cloud Activation Standard",
  "fees": "308000.0",
  "expiration_date": "2023-02-17"
}, {
  "vmware_agreement": "00596399",
  "customer_name": "Blackrock Inc",
  "contract_id": "8840294150309254",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "0.0",
  "expiration_date": "2023-02-02"
}, {
  "vmware_agreement": "00425090",
  "customer_name": "ADP, LLC",
  "contract_id": "8286717080574359",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "12999414.0",
  "expiration_date": "2023-03-31"
}, {
  "vmware_agreement": "00670266",
  "customer_name": "Memorial Sloan-Kettering Cancer Center",
  "contract_id": "8284794907312152",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "757501.37",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00694662",
  "customer_name": "Navy Federal Credit Union",
  "contract_id": "8840487719187012",
  "contract_title": "Amendment No. 10 to the ELA Order",
  "fees": "1296320.74",
  "expiration_date": "2023-11-22"
}, {
  "vmware_agreement": "00430588",
  "customer_name": "Salesforce.com, Inc.",
  "contract_id": "8285111229027699",
  "contract_title": "Amendment No. 12 to the ELA Order",
  "fees": "12379800.0",
  "expiration_date": "2023-02-28"
}, {
  "vmware_agreement": "00619390",
  "customer_name": "THE CAPITAL GROUP COMPANIES, INC.",
  "contract_id": "8285720489283766",
  "contract_title": "Amendment No. 12 to the ELA Order",
  "fees": "352000.0",
  "expiration_date": "2023-03-24"
}, {
  "vmware_agreement": "VMware Agreement # 00637654",
  "customer_name": "The Depository Trust \u0026 Clearing Corporation",
  "contract_id": "8284889557695315",
  "contract_title": "Amendment No. 12 to the ELA Order",
  "fees": "333102.0",
  "expiration_date": "2023-03-30"
}, {
  "vmware_agreement": "00684962",
  "customer_name": "Cabela\u0027s LLC",
  "contract_id": "8285145718309586",
  "contract_title": "Amendment No. 13 to the ELA Order",
  "fees": "1601639.44",
  "expiration_date": "2023-01-13"
}, {
  "vmware_agreement": "00692940",
  "customer_name": "Ringcentral Inc",
  "contract_id": "8285222637667169",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-02-13"
}, {
  "vmware_agreement": "00435457",
  "customer_name": "Sunovion Pharmaceuticals Inc.",
  "contract_id": "8285071381853420",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "276510.68",
  "expiration_date": "2023-02-14"
}, {
  "vmware_agreement": "00481955",
  "customer_name": "Piedmont Health, Inc.",
  "contract_id": "8285509067644453",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00492904",
  "customer_name": "Starbucks Corporation",
  "contract_id": "8285546118464378",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-06"
}, {
  "vmware_agreement": "00497585",
  "customer_name": "Alaska USA Federal Credit Union",
  "contract_id": "8285271468660775",
  "contract_title": "Enterprise License Agreement Order",
  "fees": "0.0",
  "expiration_date": "2023-10-22"
}, {
  "vmware_agreement": "0067 1966",
  "customer_name": "Southern Illinois Hospital Services",
  "contract_id": "8840294154845044",
  "contract_title": "Amendment # 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-09-18"
}, {
  "vmware_agreement": "00609411",
  "customer_name": "VeriFone, Inc.",
  "contract_id": "8284972649565311",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "291112.36",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00501884",
  "customer_name": "Providence Health and services",
  "contract_id": "8285537437285945",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00431292",
  "customer_name": "Potlatch Deltic Corporation",
  "contract_id": "8285522458592487",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "832865.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00620861",
  "customer_name": "Guardian Life",
  "contract_id": "8284582843759380",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-04-05"
}, {
  "vmware_agreement": "00542750",
  "customer_name": "Servicenow Inc",
  "contract_id": "8285829028112694",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "3700.0",
  "expiration_date": "2023-04-27"
}, {
  "vmware_agreement": "00478128",
  "customer_name": "ALLIED SOLUTIONS",
  "contract_id": "8284683811119528",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-07-30"
}, {
  "vmware_agreement": "00491957",
  "customer_name": "AutoZone Parts, Inc.",
  "contract_id": "8284865179517613",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "148017.37",
  "expiration_date": "2023-10-21"
}, {
  "vmware_agreement": "00678737",
  "customer_name": "Mitek Industries",
  "contract_id": "8284805010168977",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "53536.94",
  "expiration_date": "2023-10-26"
}, {
  "vmware_agreement": "00679109",
  "customer_name": "Nutrien (Canada) Holdings ULC formerly doing business as Agrium",
  "contract_id": "8285087195127633",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "17965.8",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00688943",
  "customer_name": "Local Government Association of South Australia (LGA)",
  "contract_id": "8286733911106474",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "0.0",
  "expiration_date": "2023-12-18"
}, {
  "vmware_agreement": "00684060",
  "customer_name": "Berkley Technology Services, LLC",
  "contract_id": "8840113950674501",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "1025888.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00683896",
  "customer_name": "US Medical management",
  "contract_id": "8285058918393096",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "17822.38",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00691624",
  "customer_name": "Charter Communications Operating, LLC",
  "contract_id": "8284428209010322",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "2570048.18",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00498209",
  "customer_name": "Montefiore Medicine Academic Health System, Inc.",
  "contract_id": "8285604907234615",
  "contract_title": "VMware Enterprise License Agreement",
  "fees": "91773.33",
  "expiration_date": "2023-12-31"
}, {
  "vmware_agreement": "00445950",
  "customer_name": "Blue Cross Blue Shield of Kansas, Inc.",
  "contract_id": "8285032292018348",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "94395.68",
  "expiration_date": "2023-04-20"
}, {
  "vmware_agreement": "00670267",
  "customer_name": "Hartford Health Care Corporation",
  "contract_id": "8284262304390105",
  "contract_title": "AMENDED ENTERPRISE LICENSE AGREEMENT",
  "fees": "91033.47",
  "expiration_date": "2023-09-28"
}, {
  "vmware_agreement": "00690260",
  "customer_name": "The Prudential Insurance Company of America",
  "contract_id": "8285897664074131",
  "contract_title": "Amendment No. 21 To The Exhibit A-11",
  "fees": "0.0",
  "expiration_date": "2023-12-22"
}, {
  "vmware_agreement": "00558542",
  "customer_name": "Regie de l\u0027assurance maladie du Quebec (RAMQ)",
  "contract_id": "8285097339785030",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00433627",
  "customer_name": "Régie de l\u0027assurance maladie du Québec (RAMQ)",
  "contract_id": "8283895109870771",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00433627",
  "customer_name": "Régie de l\u0027assurance maladie du Québec (RAMQ)",
  "contract_id": "8285356230943682",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00427876",
  "customer_name": "Revenu Québec",
  "contract_id": "8285626768485111",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-01-31"
}, {
  "vmware_agreement": "00544982",
  "customer_name": "CIUSSS DU CENTRE-SUD-DE-L\u0027ILE-DE-MONTREAL",
  "contract_id": "8284637839677862",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-07-03"
}, {
  "vmware_agreement": "00559340",
  "customer_name": "Societe de transport de Montreal (STM)",
  "contract_id": "8285110673250577",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-07-03"
}, {
  "vmware_agreement": "00479721",
  "customer_name": "Universite de Montreal",
  "contract_id": "8285072537544413",
  "contract_title": "CONTRACT DE LICENCE ENTREPRISE (CLE)",
  "fees": "0.0",
  "expiration_date": "2023-07-31"
}, {
  "vmware_agreement": "00426418",
  "customer_name": "Spirit Airlines Inc.",
  "contract_id": "8285456061989811",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-10"
}, {
  "vmware_agreement": "00431302",
  "customer_name": "METROPLUS HEALTH PLAN INC",
  "contract_id": "8284781151732626",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-24"
}, {
  "vmware_agreement": "00433002",
  "customer_name": "Grange Insurance Company",
  "contract_id": "8284911500914914",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-01-28"
}, {
  "vmware_agreement": "00358356",
  "customer_name": "Intact Financial Corporation",
  "contract_id": "8285157991267321",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "586329.49",
  "expiration_date": "2023-02-01"
}, {
  "vmware_agreement": "00607197",
  "customer_name": "ZenPayroll, Inc. dba Gusto",
  "contract_id": "8285842765684234",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-01"
}, {
  "vmware_agreement": "00679169",
  "customer_name": "Taxslayer, LLC, A Marlin Portfolio Company",
  "contract_id": "8284842565209786",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "118873.15",
  "expiration_date": "2023-10-19"
}, {
  "vmware_agreement": "00679726",
  "customer_name": "Switch Communications Group",
  "contract_id": "8285086400335691",
  "contract_title": "AMENDMENT NO. 1 TO THE ELA ORDER FORM",
  "fees": "298709.34",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00431372",
  "customer_name": "Kohler Co",
  "contract_id": "8285650909085665",
  "contract_title": "AMENDMENT NO. 3 TO THE ELA ORDER FORM",
  "fees": "233106.35",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00478472",
  "customer_name": "Saputo Inc.",
  "contract_id": "8285651069389567",
  "contract_title": "AMENDMENT NO. 3 TO THE ELA ORDER FORM",
  "fees": "403700.0",
  "expiration_date": "2023-08-20"
}, {
  "vmware_agreement": "00495506",
  "customer_name": "Texas Instruments Incorporated",
  "contract_id": "8285801000655528",
  "contract_title": "AMENDMENT NO. 6 TO THE ELA ORDER FORM",
  "fees": "0.0",
  "expiration_date": "2023-10-18"
}, {
  "vmware_agreement": "00606690",
  "customer_name": "WIRB - Copernicus Group, Inc.",
  "contract_id": "8284312535349604",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "217924.37",
  "expiration_date": "2023-01-11"
}, {
  "vmware_agreement": "00431073",
  "customer_name": "Mary Washington Healthcare",
  "contract_id": "8285429950662759",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "597823.55",
  "expiration_date": "2023-01-26"
}, {
  "vmware_agreement": "00433299",
  "customer_name": "Foot Locker Corporate Services, Inc.",
  "contract_id": "8285222753441434",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "291758.75",
  "expiration_date": "2023-02-19"
}, {
  "vmware_agreement": "00436684",
  "customer_name": "LG\u0026E and KU Services Company",
  "contract_id": "8285562503280697",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "352403.71",
  "expiration_date": "2023-03-09"
}, {
  "vmware_agreement": "00633898",
  "customer_name": "Blue Yonder, Inc",
  "contract_id": "8283939501700831",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "37326.0",
  "expiration_date": "2023-03-30"
}, {
  "vmware_agreement": "00631599",
  "customer_name": "Pinterest",
  "contract_id": "8284514067456643",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "77617.07",
  "expiration_date": "2023-04-12"
}, {
  "vmware_agreement": "00634101",
  "customer_name": "Assurant, Inc.",
  "contract_id": "8284949954000843",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-05-25"
}, {
  "vmware_agreement": "00466905",
  "customer_name": "loof Service Co Pty Ltd",
  "contract_id": "8284935354661049",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00634584",
  "customer_name": "Howard Center Inc.",
  "contract_id": "8284924606297949",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-13"
}, {
  "vmware_agreement": "00646776",
  "customer_name": "AGCO Corporation",
  "contract_id": "8283842355127720",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00486838",
  "customer_name": "OhioHealth Corporation",
  "contract_id": "8285522067182586",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "446205.5",
  "expiration_date": "2023-09-30"
}, {
  "vmware_agreement": "00498272",
  "customer_name": "VF Services, LLC on behalf of VF Corporation",
  "contract_id": "8285720052471681",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "1882951.03",
  "expiration_date": "2023-11-13"
}, {
  "vmware_agreement": "00689200",
  "customer_name": "Bell Canada",
  "contract_id": "8284694084418064",
  "contract_title": "Amendment No. 1 to the ELA Order Form",
  "fees": "648748.6",
  "expiration_date": "2023-12-21"
}, {
  "vmware_agreement": "00641496",
  "customer_name": "Putnam Investments, LLC",
  "contract_id": "8284531002494428",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "552900.0",
  "expiration_date": "2023-01-02"
}, {
  "vmware_agreement": "00559808",
  "customer_name": "Kyriba Corporation",
  "contract_id": "8285572321795700",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "110689.74",
  "expiration_date": "2023-03-09"
}, {
  "vmware_agreement": "00437454",
  "customer_name": "Capital Blue Cross",
  "contract_id": "8285110696676656",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "221514.32",
  "expiration_date": "2023-05-21"
}, {
  "vmware_agreement": "00628054",
  "customer_name": "American Family Insurance",
  "contract_id": "8285018438522342",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "370000.0",
  "expiration_date": "2023-06-30"
}, {
  "vmware_agreement": "00432996",
  "customer_name": "NCCI Holdings, Inc.",
  "contract_id": "8285545280648253",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "376643.69",
  "expiration_date": "2023-07-29"
}, {
  "vmware_agreement": "00681237",
  "customer_name": "University of Massachusetts (MA)",
  "contract_id": "8285651070392412",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "369737.0",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00685816",
  "customer_name": "Shearman \u0026 Sterling LLP",
  "contract_id": "8285664668001676",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "259911.89",
  "expiration_date": "2023-12-20"
}, {
  "vmware_agreement": "00549014",
  "customer_name": "The Methodist Hospital",
  "contract_id": "8285472025959314",
  "contract_title": "Amendment No. 2 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-01-28T00:00:00-07:00"
}, {
  "vmware_agreement": "00595733",
  "customer_name": "Expedia Inc.",
  "contract_id": "8284437589933412",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-01-04"
}, {
  "vmware_agreement": "00595733",
  "customer_name": "Expedia Inc.",
  "contract_id": "8285430555711926",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-01-04"
}, {
  "vmware_agreement": "00427830",
  "customer_name": "Teledyne Technologies Incorporated",
  "contract_id": "8285129021992492",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "117000.0",
  "expiration_date": "2023-01-13"
}, {
  "vmware_agreement": "00592065",
  "customer_name": "Paypal, Inc. (Main)",
  "contract_id": "8285470190428211",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "4699264.78",
  "expiration_date": "2023-01-16"
}, {
  "vmware_agreement": "00486663",
  "customer_name": "Foot Locker Corporate Services, Inc.",
  "contract_id": "8285222545573261",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "363711.26",
  "expiration_date": "2023-02-18"
}, {
  "vmware_agreement": "00425489",
  "customer_name": "Valero Services, Inc.",
  "contract_id": "8284694338922315",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "455968.08",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "00436192",
  "customer_name": "Arkansas Children\u0027s Hospital",
  "contract_id": "8284997196657816",
  "contract_title": "Amendment No. 3 to the ELA Order Form",
  "fees": "430804.61",
  "expiration_date": "2023-05-22"
}, {
  "vmware_agreement": "00607465",
  "customer_name": "Se2, LLC",
  "contract_id": "8285625032527598",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "195633.79",
  "expiration_date": "2023-01-07"
}, {
  "vmware_agreement": "00362589",
  "customer_name": "RingCentral, Inc.",
  "contract_id": "8285572265835667",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "500125.65",
  "expiration_date": "2023-02-06"
}, {
  "vmware_agreement": "00602531",
  "customer_name": "NCCI Holdings, Inc",
  "contract_id": "8285706840482807",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "440384.48",
  "expiration_date": "2023-02-10"
}, {
  "vmware_agreement": "00628037",
  "customer_name": "Government of Nunavut as represented by the Minister of Community and Government Services",
  "contract_id": "8285391783129414",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "307425.65",
  "expiration_date": "2023-04-28"
}, {
  "vmware_agreement": "00628144",
  "customer_name": "Avaya Inc",
  "contract_id": "8285096926817819",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "706080.67",
  "expiration_date": "2023-04-29"
}, {
  "vmware_agreement": "VMware Agreement #00398240.0",
  "customer_name": "IHS Global, Inc.",
  "contract_id": "8840294167088687",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "9427857.0",
  "expiration_date": "2023-07-30"
}, {
  "vmware_agreement": "00606686",
  "customer_name": "Qorvo US, Inc.",
  "contract_id": "8284593394830865",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "277247.92",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00678752",
  "customer_name": "Viasat, Inc",
  "contract_id": "8285801725373188",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-10-24"
}, {
  "vmware_agreement": "00393715",
  "customer_name": "Las Vegas Sands Corp.",
  "contract_id": "9429038150265418",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "232383.79",
  "expiration_date": "2023-10-28"
}, {
  "vmware_agreement": "00558984",
  "customer_name": "Virginia Commonwealth University Health System Authority",
  "contract_id": "8285071902632464",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "455856.39",
  "expiration_date": "2023-10-31"
}, {
  "vmware_agreement": "00681493",
  "customer_name": "Nordstrom, Inc.",
  "contract_id": "8285240685967508",
  "contract_title": "Amendment No. 4 to the ELA Order Form",
  "fees": "905622.05",
  "expiration_date": "2023-12-08"
}, {
  "vmware_agreement": "00551707",
  "customer_name": "Health Quest Systems, Inc.",
  "contract_id": "8284828173147760",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "342403.77",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00617612",
  "customer_name": "StoneX Group, Inc",
  "contract_id": "8285144740339944",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-03-10"
}, {
  "vmware_agreement": "00617612",
  "customer_name": "StoneX Group, Inc",
  "contract_id": "8285679518217909",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-03-10"
}, {
  "vmware_agreement": "00589322",
  "customer_name": "USAble Mutual Insurance Company d/b/a Arkansas Blue Cross \u0026 Blue Shield",
  "contract_id": "8285306366632241",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-30"
}, {
  "vmware_agreement": "00678404",
  "customer_name": "St Jude Children\u0027s Research Hospital, Inc.",
  "contract_id": "8285086186112171",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "318380.54",
  "expiration_date": "2023-08-30"
}, {
  "vmware_agreement": "00679895",
  "customer_name": "Valley National Bank",
  "contract_id": "8840130877305363",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "254927.19",
  "expiration_date": "2023-10-27"
}, {
  "vmware_agreement": "00680200",
  "customer_name": "Comenity Servicing LLC",
  "contract_id": "8284476257154924",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "431334.7",
  "expiration_date": "2023-11-29"
}, {
  "vmware_agreement": "00688310",
  "customer_name": "Allegis Group, Inc.",
  "contract_id": "8285033747704686",
  "contract_title": "Amendment No. 5 to the ELA Order Form",
  "fees": "777653.19",
  "expiration_date": "2023-12-23"
}, {
  "vmware_agreement": "00431706",
  "customer_name": "Marriott International, Inc.",
  "contract_id": "8285378614220170",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "199807.24",
  "expiration_date": "2023-01-30"
}, {
  "vmware_agreement": "00494807",
  "customer_name": "Pure Storage, Inc.",
  "contract_id": "8840413961444893",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "572312.9",
  "expiration_date": "2023-04-30"
}, {
  "vmware_agreement": "00673086",
  "customer_name": "USAble Mutual Insurance Company d/b/a Arkansas Blue Cross \u0026 Blue Shield",
  "contract_id": "8285221753732286",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-07-17"
}, {
  "vmware_agreement": "00669828",
  "customer_name": "Vicar Operating, Inc",
  "contract_id": "8285222848201372",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "300000.0",
  "expiration_date": "2023-08-23"
}, {
  "vmware_agreement": "00683156",
  "customer_name": "Valley National Bank",
  "contract_id": "8284899277016924",
  "contract_title": "Amendment No. 6 to the ELA Order Form",
  "fees": "356733.1",
  "expiration_date": "2023-12-01"
}, {
  "vmware_agreement": "00621482",
  "customer_name": "Uber Technologies Inc.",
  "contract_id": "8840498011319696",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "0.0",
  "expiration_date": "2023-04-28"
}, {
  "vmware_agreement": "00482208",
  "customer_name": "Marriott International, Inc.",
  "contract_id": "8284983028892243",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "450000.0",
  "expiration_date": "2023-09-02"
}, {
  "vmware_agreement": "00490611",
  "customer_name": "Santander Bank NA.",
  "contract_id": "8284873715388531",
  "contract_title": "Amendment No. 7 to the ELA Order Form",
  "fees": "773634.06",
  "expiration_date": "2023-10-07"
}, {
  "vmware_agreement": "00607649",
  "customer_name": "Quest Diagnostics Incorporated",
  "contract_id": "8284996965248074",
  "contract_title": "Amendment No. 8 to the ELA Order Form",
  "fees": "610229.0",
  "expiration_date": "2023-01-21"
}, {
  "vmware_agreement": "00631312",
  "customer_name": "PepsiCo, Inc.",
  "contract_id": "8840425000899351",
  "contract_title": "Amendment No. 9 to the ELA Order Form",
  "fees": "2514439.96",
  "expiration_date": "2023-04-30"
}];


export const fetchData = (query: string) => {
  switch (query) {
    case '1':
      return DummyData1;
    case '2':
      return DummyData2;
    case '3':
      return DummyData3;
    case '4':
      return DummyData4;
    case '5':
      return DummyData5;
    case '6':
      return DummyData6;
    case '7' :
      return DummyData7
    case '8' : 
      return DummyData7
    default :
      return DummyData1;
  }
};
const tabHeaders1 : ColumnsType<IDataSourceObj>= [
      {
        title: 'Vmware Agreement',
        key: 'vmware_agreement',
        dataIndex: 'vmware_agreement',
        // sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
      },
      {
        title: 'Customer Name',
        key: 'customer_name',
        dataIndex: 'customer_name',
        filters: [
          {
            text: 'Boeing',
            value: 'Boeing',
          },
        ],
        // eslint-disable-next-line consistent-return
        onFilter: (value: string | number | boolean, record: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          if (record.customer_name === value) return record;
        },
      },
      {
        title: 'Contract Id',
        key: 'contract_id',
        dataIndex: 'contract_id',
      },
      {
        title: 'Contract Title',
        key: 'contract_title',
        dataIndex: 'contract_title',
      },
      {
        title: 'Fees',
        key: 'fees',
        dataIndex: 'fees',
      }
]
const tabHeaders2 : ColumnsType<IDataSourceObj>= [
  {
    title: 'Vmware Agreement',
    key: 'vmware_agreement',
    dataIndex: 'vmware_agreement',
    sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
  },
  {
    title: 'Customer Name',
    key: 'customer_name',
    dataIndex: 'customer_name',
    filters: [
      {
        text: 'Boeing',
        value: 'Boeing',
      },
    ],
    // eslint-disable-next-line consistent-return
    onFilter: (value: string | number | boolean, record: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (record.customer_name === value) return record;
    },
  },
  {
    title: 'Contract Id',
    key: 'contract_id',
    dataIndex: 'contract_id',
  },
  {
    title: 'Contract Title',
    key: 'contract_title',
    dataIndex: 'contract_title',
  },
  {
    title: 'Fees',
    key: 'fees',
    dataIndex: 'fees',
  }
]
const tabHeaders3 : ColumnsType<IDataSourceObj>= [
  {
    title: 'Vmware Agreement',
    key: 'vmware_agreement',
    dataIndex: 'vmware_agreement',
    sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
  },
  {
    title: 'Customer Name',
    key: 'customer_name',
    dataIndex: 'customer_name',
    filters: [
      {
        text: 'Boeing',
        value: 'Boeing',
      },
    ],
    // eslint-disable-next-line consistent-return
    onFilter: (value: string | number | boolean, record: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (record.customer_name === value) return record;
    },
  },
  {
    title: 'Contract Id',
    key: 'contract_id',
    dataIndex: 'contract_id',
  },
  {
    title: 'Contract Title',
    key: 'contract_title',
    dataIndex: 'contract_title',
  },
  {
    title: 'Fees',
    key: 'fees',
    dataIndex: 'fees',
  }
]
const tabHeaders4 : ColumnsType<IDataSourceObj>= [
  {
    title: 'Vmware Agreement',
    key: 'vmware_agreement',
    dataIndex: 'vmware_agreement',
    sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
  },
  {
    title: 'Customer Name',
    key: 'customer_name',
    dataIndex: 'customer_name',
    filters: [
      {
        text: 'Boeing',
        value: 'Boeing',
      },
    ],
    // eslint-disable-next-line consistent-return
    onFilter: (value: string | number | boolean, record: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (record.customer_name === value) return record;
    },
  },
  {
    title: 'Contract Id',
    key: 'contract_id',
    dataIndex: 'contract_id',
  },
  {
    title: 'Contract Title',
    key: 'contract_title',
    dataIndex: 'contract_title',
  },
  {
    title: 'SPP Credits',
    key: 'spp_credits',
    dataIndex: 'spp_credits',
  }
]
const tabHeaders5 : ColumnsType<IDataSourceObj>= [
  {
    title: 'contract count',
    key: 'contract_count',
    dataIndex: 'contract_count',
    sorter: (a, b) => Number(a.contract_count) - Number(b.contract_count),
  },
  {
    title: 'Contract Type',
    key: 'contract_type',
    dataIndex: 'contract_type'
  },
  {
    title: 'year',
    key: 'year',
    dataIndex: 'year',
  }
]
const tabHeaders6 : ColumnsType<IDataSourceObj>= [
  {
    title: 'Vmware Agreement',
    key: 'vmware_agreement',
    dataIndex: 'vmware_agreement',
    sorter: (a, b) => Number(a.vmware_agreement) - Number(b.vmware_agreement),
  },
  {
    title: 'Customer Name',
    key: 'customer_name',
    dataIndex: 'customer_name',
    filters: [
      {
        text: 'Boeing',
        value: 'Boeing',
      },
    ],
    // eslint-disable-next-line consistent-return
    onFilter: (value: string | number | boolean, record: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      if (record.customer_name === value) return record;
    },
  },
  {
    title: 'Contract Id',
    key: 'contract_id',
    dataIndex: 'contract_id',
  },
  {
    title: 'Contract Title',
    key: 'contract_title',
    dataIndex: 'contract_title',
  },
  {
    title: 'Fees',
    key: 'fees',
    dataIndex: 'fees',
  },
  {
    title: 'Expiration Date',
    key: 'expiration_date',
    dataIndex: 'expiration_date',
  }
]
export const fetchTableHeaders = (key: string) : ColumnsType<IDataSourceObj>=> {
  switch(key){
    case '1':
      return tabHeaders1;
    case '2':
      return tabHeaders2;
    case '3':
      return tabHeaders3;
    case '4':
      return tabHeaders4;
    case '5':
      return tabHeaders5;
    case '6':
      return tabHeaders6;
    default :
      return tabHeaders2;
  }
}











export const SearchButtonLabel = {
  gen_ai : "Keyword",
  keyword : "Filter"
};

export const entityOptions : IContractType= {
  'ALL' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  // {
  //   label : "Contract Type",
  //   value : "contract_type",
  // },
  // {
  //   label : "SPP Period Expiration Date",
  //   value : "SPP Period Expiration Date"
  // },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  // {
  //   label : "Partner Name",
  //   value : "partner_name"
  // },
  // {
  //   label : "MAXIMUM SPP CREDITS ALLOWANCE",
  //   value : "spp_credits"
  // },
  // {
  //   label : "Partner Agreement Effective Date",
  //   value : "partner_agreement_effective_date"
  // },
  // {
  //   label : "Partner Signature Effective Date",
  //   value : "partner_signature_effective_date"
  // },
  {
    label : "Fees",
    value : "fees"
  }
],
'ELA' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  // {
  //   label : "Contract Type",
  //   value : "contract_type",
  // },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  {
    label : "Fees",
    value : "fees"
  },
  {
    label : "MAXIMUM SPP CREDITS ALLOWANCE",
    value : "spp_credits"
  },
],
'SRA' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  // {
  //   label : "Contract Type",
  //   value : "contract_type",
  // },
  {
    label : "SPP Period Expiration Date",
    value : "SPP Period Expiration Date"
  },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  {
    label : "Partner Name",
    value : "partner_name"
  },
  {
    label : "MAXIMUM SPP CREDITS ALLOWANCE",
    value : "spp_credits"
  },
  {
    label : "Partner Agreement Effective Date",
    value : "partner_agreement_effective_date"
  },
  {
    label : "Partner Signature Effective Date",
    value : "partner_signature_effective_date"
  },
  {
    label : "Fees",
    value : "fees"
  }
],
'MSLA' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  {
    label : "Fees",
    value : "fees"
  }
],
'MLA' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  {
    label : "Fees",
    value : "fees"
  }
],
'EULA' : [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "Customer Name",
    value : "customer_name",
  },
  {
    label : "Entitlement Account (EA) No.",
    value : "entitlement_account"
  },
  {
    label : "Amendment Effective Date",
    value : "effective_date"
  },
  {
    label : "VMWare Agreement No.",
    value : "vmware_agreement"
  },
  {
    label : "Expiration Date",
    value : "expiration_date"
  },
  {
    label : "Fees",
    value : "fees"
  }
],
}
export const operatorOptions = [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "equals",
    value : "=",
  },
  {
    label : "not equals",
    value : "!=",
  },
  {
    label : "greater than",
    value : ">"
  },
  {
    label : "less than",
    value : "<"
  },
  {
    label : "contains",
    value : "like"
  }
]

export const AndOptions = [
  {
    label : "Select",
    value : "select",
  },
  {
    label : "AND",
    value : "and",
  },
  {
    label : "OR",
    value : "or",
  }
]
export const ContractOptions = [
  {
    label : "ALL",
    value : "ALL",
  },
  {
    label : "SRA",
    value : "SRA",
  },
  {
    label : "ELA",
    value : "ELA",
  },
  {
    label : "MSLA",
    value : "MSLA",
  },
  {
    label : "MLA",
    value : "MLA",
  },
  {
    label : "EULA",
    value : "EULA",
  }
]
