import React from 'react';
import { Col, Row } from 'antd';
import YTDPerformance from '../../../assets/images/overall-ytd-performance.png';
import RisingCostGraph from '../../../assets/images/rising-cost-graph.png';
import ChipShortageGraph from '../../../assets/images/supply-chain-issues-graph.png';
import NetPremiumChart from '../../../assets/images/new-premium-graph.png';
import ProductLine from '../../../assets/images/product-line-graph.png';
import GeographyGraph from '../../../assets/images/geography-graph.png';
import Legends from '../../../assets/images/new-premium-legends.png';
import YTDIcon from '../../../assets/icons/health-icon.svg';

const RightWidget = () => {
  return (
    <div className="px-1">
      <Row>
        <Col>
          <div className="d-flex">
            {/* <img src={YTDIcon} alt="" className="mr-1" /> */}
            <h3 className="sub-heading-styling">Net Premiums Written </h3>
          </div>
          <img src={NetPremiumChart} className="w-100" alt="" />
          <img src={Legends} className="w-100" alt="" />
        </Col>
      </Row>
      <hr className="my-2" />
      <Row>
        <Col>
          {/* <h3 className="sub-heading-styling">Rising construction / labor costs</h3> */}
          <img src={ProductLine} className="w-100" alt="" />
          <img src={Legends} className="w-100" alt="" />
        </Col>
      </Row>
      <hr className="my-2" />
      <Row>
        <Col>
          <h3 className="sub-heading-styling">By Geography</h3>
          <img src={GeographyGraph} className="w-100" alt="" />
          <img src={Legends} className="w-100" alt="" />
        </Col>
      </Row>
    </div>
  );
};

export default RightWidget;
