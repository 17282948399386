import { IUserResponse } from '../../models/UserModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class UserService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getUser(): Promise<IUserResponse> | IUserResponse {
    return this.service.get({ route: [`${API_ROUTE.GET_USER_PERMISSIONS}`] });
  }
}

const userService = new UserService();
export default userService;
