import { Reducer } from 'redux';
import MetadataGuardrailState from '../state/MetadataGuardrailState';
import {
  MetadataGuardrailAction,
  MetadataGuardrailsActionTypes,
} from '../actions/MetadataGuardrailsActions';
import { IGuardrailRespObj, IMetadataObj } from '../../models/MetadataGuardrailModels';

export const initialState: MetadataGuardrailState = {
  error: '',
  loading: false,
  metadataDetails: undefined,
  metadataFormlist: undefined,
  guardrailDetails: undefined,
};

export const metadataGuardrailReducer: Reducer<MetadataGuardrailState> = (
  state = initialState,
  action: MetadataGuardrailAction,
) => {
  switch (action.type) {
    case MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        metadataDetails: action.data as IMetadataObj[],
      };
    case MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        metadataDetails: [],
      };

    case MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        metadataFormlist: action.data as string[],
      };
    case MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        guardrailDetails: action.data as IGuardrailRespObj[],
      };
    case MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
};
