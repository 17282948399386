import { Reducer } from 'redux';
import {
  ICreatePipelineResponse,
  IDeliveryPipelineState,
  IPipelineHistory,
  IPipelineInstancePayload,
  IRunPipelineResponse,
  ISelectedPipelineDetails,
  IUpdatePipelineResponse,
} from '../../models/PipelineModels';
import { PipelineAction, PipelineActionTypes } from '../actions/PipelineActions';
import PipelineState from '../state/PipelineState';

export const initialState: PipelineState = {
  error: '',
  pipelines: [],
  loading: false,
  createPipelineDetails: {},
  updatePipelineDetails: {},
  currentPipelineHistory: [],
  currentPipelineHistoryDetails: {},
  runPipelineDetails: {},
};

export const pipelineReducer: Reducer<PipelineState> = (
  state = initialState,
  action: PipelineAction,
) => {
  switch (action.type) {
    case PipelineActionTypes.CREATE_PIPELINE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PipelineActionTypes.CREATE_PIPELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        createPipelineDetails: action.data as ICreatePipelineResponse,
      };
    case PipelineActionTypes.CREATE_PIPELINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PipelineActionTypes.GET_PIPELINES_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PipelineActionTypes.GET_PIPELINES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        pipelines: action.data as IDeliveryPipelineState[],
      };
    case PipelineActionTypes.GET_PIPELINES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PipelineActionTypes.UPDATE_PIPELINE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PipelineActionTypes.UPDATE_PIPELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        updatePipelineDetails: action.data as IUpdatePipelineResponse,
      };
    case PipelineActionTypes.UPDATE_PIPELINE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PipelineActionTypes.UPDATE_PIPELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        updatePipelineDetails: action.data as IUpdatePipelineResponse,
      };
    case PipelineActionTypes.UPDATE_PIPELINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PipelineActionTypes.RUN_PIPELINE_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case PipelineActionTypes.RUN_PIPELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        runPipelineDetails: action.data as IRunPipelineResponse,
      };
    case PipelineActionTypes.RUN_PIPELINE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_SUCCESS: {
      const { details, instanceId } = action.data as IPipelineInstancePayload;
      return {
        ...state,
        currentPipelineHistoryDetails: {
          ...state.currentPipelineHistoryDetails,
          [instanceId]: details,
        },
      };
    }

    case PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    case PipelineActionTypes.GET_PIPELINE_INSTANCES_START:
      return {
        ...state,
        loading: true,
      };

    case PipelineActionTypes.GET_PIPELINE_INSTANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentPipelineHistory: action.data as IPipelineHistory[],
      };

    case PipelineActionTypes.GET_PIPELINE_INSTANCES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    case PipelineActionTypes.SET_SELECTED_PIPELINE_DETAILS:
      return {
        ...state,
        selectedPipelineDetails: action.data as ISelectedPipelineDetails,
      };

    default:
      return state;
  }
};
