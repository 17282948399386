import { Badge, Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import Mail from '../../assets/icons/mail-white.svg';
import Notification from '../../assets/icons/bell-white.svg';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './OverViewHeaderMobileStyle.less';
import { NotificationActions } from '../../libs/store/actions/NotificationActions';
import EmailNotificationDrawer from '../../containers/EmailNotificationDrawer.tsx/EmailNotificationDrawer';
import NotificationMobileDrawer from '../NotificationsMobile/NotificationsMobileDrawer';

const OverviewHeaderMobile = () => {
  const email = storage.getItem(STORAGE_CONSTANTS.email);

  const dispatch = useDispatch();
  const { logoutState, userDetails, emailNotificationDot, isEmailNotificationVisible, landingZoneNotificationDot, isVisible } =
    useAppSelector(
      ({
        user: { userDetails },
        sidebar: { logout },
        notification: { emailNotificationDot, isEmailNotificationVisible, landingZoneNotificationDot, isVisible },
      }: IApplicationState) => ({
        logoutState: logout,
        userDetails,
        emailNotificationDot,
        isEmailNotificationVisible,
        landingZoneNotificationDot,
        isVisible
      }),
    );

  const dispatcher = {
    toggleLogout: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
    toggleSidebar: (data: boolean) => dispatch(SidebarActions.toggleSidebar(data)),
    showEmailDrawer: (data: boolean) =>
      dispatch(NotificationActions.toggleEmailNotificationStart(data)),
    showNotificationsDrawerMobile: (data: boolean) =>
    dispatch(NotificationActions.toggleLandingZoneNotificationStart(data)),
  };

  const showEmailDrawer = (data: boolean) => dispatcher.showEmailDrawer(data);

  const toggleSidebar = () => dispatcher.toggleSidebar(true);

  const toggleLogout = (data: boolean) => dispatcher.toggleLogout(data);

  const showNotificationsDrawerMobile = (data: boolean) => dispatcher.showNotificationsDrawerMobile(data);

  if (logoutState) {
    toggleLogout(false);
    storage.clearAll();
    window.location.reload();
  }

  const handleDrawerOpener = () => {
    showNotificationsDrawerMobile(true);
  };
  const handleEmailDrawerOpener = () => {
    showEmailDrawer(true);
  };
  const handleSideBar = () => {
    toggleSidebar();
  };
  return (
    <div className="overview-header-container-mobile-main">
      <div className="overview-tab-mobile-header">
        {/* Condition to be change later */}
        <div className="header-logo-mobile-container">
          <img className='header-logo-mobile' src={`${process.env.PUBLIC_URL}/dca_logo_white.png`} alt='tangra-logo-mobile' />
        </div>
        <div>
          <Button
            className="overview-icon-mobile-btn  overview-message-btn"
            onClick={handleEmailDrawerOpener}
          >
            <Badge dot={emailNotificationDot} offset={[-3, 1]} className="notification-icon-badge">
              <img src={Mail} alt="notification-icon" />
            </Badge>
          </Button>
          {userDetails?.user_permissions.add_account && (
            <Button
              className="notification-icon-mobile-btn overview-message-btn"
              onClick={handleDrawerOpener}
            >
              <Badge dot={landingZoneNotificationDot} offset={[-3, 1]} className="notification-icon-badge">
                <img src={Notification} alt="notification-icon" />
              </Badge>
            </Button>
          )}
          <Button
            className="overview-icon-mobile-btn  overview-notification-btn"
            onClick={handleSideBar}
          >
            <Badge dot={false} offset={[-8, 8]} className="profile-icon-badge">
              <img src={`${process.env.PUBLIC_URL}/config-icons/mobile/sidebar-white.svg`} alt="Hamburger menu" />
            </Badge>
          </Button>
        </div>
      </div>
      {isEmailNotificationVisible && <EmailNotificationDrawer />}
      {isVisible && <NotificationMobileDrawer />}

      {/* Code To Display the Notification Drawer Needs to Be added here*/}
    </div>
  );
};

export default OverviewHeaderMobile;
