import { isNil } from 'lodash';
import { IInitialNotificationStatus } from '../../models/NotificationModels';
import {
  IAddAccountPayload,
  ICreateAccountPayload,
  ICreateProjectPayload,
  IEditProjectPayload,
} from '../../models/OverviewModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class OverViewService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public createAccount(payload: ICreateAccountPayload): Promise<string> | string {
    const route: string[] = [`${API_ROUTE.ADD_ACCOUNT}`];
    return this.service.post({ route: route }, { ...payload });
  }

  public addAccount(payload: IAddAccountPayload): Promise<string> | string {
    const route: string[] = [`${API_ROUTE.ADD_ONLY_ACCOUNT}`];
    return this.service.post({ route: route }, { ...payload });
  }

  public addProject(payload: ICreateProjectPayload): Promise<string> | string {
    const { accountId, addProjectPayload } = payload;
    const route: string[] = [`${API_ROUTE.ADD_PROJECT}`];
    return this.service.post({ route: route }, { ...addProjectPayload, accountId });
  }

  public editProject(payload: IEditProjectPayload): Promise<string> | string {
    const { projectId, status, projectName } = payload;
    const route: string[] = [`${API_ROUTE.ADD_PROJECT}/${projectId}`];
    return this.service.post({ route: route }, projectName ? { projectName } : { status });
  }
  public getNotificationStatus(): Promise<IInitialNotificationStatus> | IInitialNotificationStatus {
    return this.service.get({ route: [API_ROUTE.notificationStatus] });
  }
}

const overViewService = new OverViewService();
export default overViewService;
