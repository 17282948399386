import { camelCase } from 'lodash';
import {
  ICreatePipelinePayload,
  ICreatePipelineResponse,
  IUpdatePipelineStatusPayload,
} from '../../models/PipelineModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class CreatePipelineService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public createPipeline(
    payload: ICreatePipelinePayload,
    landinZoneId: string,
  ): Promise<ICreatePipelineResponse> | ICreatePipelineResponse {
    return this.service.post(
      { route: [`${API_ROUTE.CREATE_PIPELINE}`] },
      {
        ...payload,
        landingZoneId: landinZoneId,
      },
    );
  }

  public updatePipelineStatus(
    pipelineId: string,
    payload: IUpdatePipelineStatusPayload,
  ): Promise<ICreatePipelineResponse> | ICreatePipelineResponse {
    return this.service.patch(
      { route: [`${API_ROUTE.UPDATE_PIPELINE_STATUS}`] },
      { status: camelCase(payload.status), pipelineId: pipelineId },
    );
  }
}

const pipelineService = new CreatePipelineService();
export default pipelineService;
