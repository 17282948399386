import { Button, Col, Dropdown, Input, Modal, Row, Table, Typography } from 'antd';
import React, { useState } from 'react';
import './Diligence.less';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import DownIcon from '../../../assets/icons/dropdown-down-arrow.svg';
import Cross from '../../../assets/icons/cross.svg';
import EmailImg from '../../../assets/images/email-popup.png';
import { pageSizeOptions } from '../../../AppConstants';

const Diligence = () => {
  const [selectedActivity, setSelectedActivity] = useState('Form Re-Upload');
  const [comment, setComment] = useState('');
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const emailBody = `
Dear ${'Yashasvi Awasthi'}, %0d%0a
I hope this email finds you well.%0d%0a
I'm writing to you today to follow up on the property insurance application for Toyota of Richardson Inc. I'm still missing the proposed effective date and proposed expiration date, so I can't process the application yet.%0d%0a
Could you please re-submit the application with the following information filled in:%0d%0a
    • Proposed effective date%0d%0a
    • Proposed expiration date%0d%0a
Once I have this information, I can process the application and get you a quote.%0d%0a
Thank you for your time and attention to this matter.%0d%0a
Sincerely, ${'Akshay Choudhary'} %0d%0a`;

  const activityOverlay = (
    <DropdownMenu
      keys={['Form Re-Upload', 'Missing Data', 'Data Verification', 'Additional Details']}
      selectedKey={selectedActivity}
      onClick={setSelectedActivity}
    />
  );
  const handleChange = (searchText: React.ChangeEvent<HTMLInputElement>) => {
    setComment(searchText.target.value);
  };
  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', width: '20%' },
    { title: 'From', dataIndex: 'from', key: 'from', width: '22%' },
    { title: 'To', dataIndex: 'to', key: 'to', width: '22%' },
    { title: 'Comment', dataIndex: 'comment', key: 'comment', width: '20%' },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: () => {
        return (
          <>
            <Button
              type="text"
              className="action-btn"
              href="mailto:broker@stdservicesltd.com?subject=Auto-insurance_Form CP385"
              // onClick={() => {
              //   setEmailModalOpen(true);
              // }}
            >
              View Email
            </Button>
            {/* <Button type="text" className="action-btn">
              View Response
            </Button> */}
          </>
        );
      },
    },
  ];
  const dataSource = [
    {
      date: '23 June, 2023',
      comment: 'Data Incomplete',
      to: 'Awasthi, Yashasvi',
      from: 'Choudhary, Akshay',
    },
    // { activity: 'Quote sent', comment: 'Details sent' },
    // { activity: 'Form re-upload', comment: 'Details sent' },
    // { activity: 'Quote sent', comment: 'Data Incomplete' },
    // { activity: 'Form re-upload', comment: 'Details sent' },
    // { activity: 'Quote sent', comment: 'Details sent' },
    // { activity: 'Quote sent', comment: 'Data Incomplete' },
  ];
  const handleGenerateEmail = () => {};
  return (
    <Row className="diligence-tab-container w-100 overflow-auto h-fit-content">
      <Col span={24} className="d-flex justify-end mb-1-dot-5">
        <Button className="sap-button-tab-style">Mark as completed</Button>
        {/* <Button className="sap-button-tab-style">Re-Upload form</Button> */}
      </Col>
      <Col span={24} className="border-solid-rounded mb-1-dot-5">
        <Row gutter={[16, 16]} className="p-1">
          <Col span={6} className="gutter-row">
            <p>Select Activity</p>
            <Dropdown
              overlay={activityOverlay}
              trigger={['click']}
              //   getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              className=""
            >
              <Button className="dropdown-btn">
                <Typography className="select-button-text">{selectedActivity}</Typography>
                <img src={DownIcon} className="select-button-down-icon" alt="Dropdown Icon" />
              </Button>
            </Dropdown>
          </Col>
          <Col span={12} className="gutter-row">
            <p>Enter Comments</p>
            <Input onChange={handleChange} className="comment" />
          </Col>
          <div className="d-flex align-items-end">
            {/* <Button className="action-btn send-btn" onClick={handleGenerateEmail}>
              Generate Email
            </Button> */}
            <Button
              type="text"
              className="action-btn bg-white send-btn"
              // className="action-btn"
              href={`mailto:yawasthi@deloitte.com?subject=${selectedActivity}: ${comment}&body=${emailBody}`}
              // onClick={() => {
              //   setEmailModalOpen(true);
              // }}
              content-type=""
            >
              Generate Email
            </Button>
          </div>
        </Row>
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </Col>
      {emailModalOpen && (
        <Modal
          width={1000}
          visible={emailModalOpen}
          footer={false}
          className="email-popup"
          closable={true}
          onCancel={() => setEmailModalOpen(false)}
        >
          <img className="w-100" src={EmailImg} alt="asset_modal_image" />
        </Modal>
      )}
    </Row>
  );
};

export default Diligence;
