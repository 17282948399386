import { congnitoConfiguration } from '../../configs/AWS';
import { getEnv } from '../../libs/helper/HelperFunctions';

export const LoginLabels = {
  header: 'Welcome to Tangra',
  loginAsExternalUser: 'Login as External User',
  needHelp: 'Need Help?',
  signUpText: 'SignUp for External user',
  ssoLoginText: 'Login with SSO',
  externalUserLoginText: 'Login as External user',
  requiredEmailErrorMessage: 'Please input your username!',
  requiredPasswordErrorMessage: 'Please input your password!',
  succesfulLoginText: 'Logged in Successfully',
  success: 'Success!!',
  error: 'Error!!',
  loginFailed: 'Login Failed',
  loaderName: 'Authenticating!!',
};
export const FooterLabels = {
  year: ' © 2022. See',
  terms: ' Terms of Use',
  privacy: ' Privacy',
  forMore: ' for more information. | ',
};

export const getAuthURL = () => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment);
  const url = `${COGNITO.DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO.REDIRECT_SIGN_IN}&response_type=${COGNITO.RESPONSE_TYPE}&client_id=${COGNITO.APP_CLIENT_ID}`;
  return url;
};

export const getCwbAuthUrl = () => {
  const environment = getEnv();
  const COGNITO = congnitoConfiguration(environment).CWB;
  const url = `${COGNITO.DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO.REDIRECT_SIGN_IN}&response_type=${COGNITO.RESPONSE_TYPE}&client_id=${COGNITO.APP_CLIENT_ID}`;
  return url;
};
