import { Col, Row } from 'antd';
import React from 'react';
import SubmissionInsights from './SubmissionInsights';

const LeftWidget = () => {
  return (
    <>
      <Row className='submission-insights-container'>
        <Col span={24}>
          <SubmissionInsights />
        </Col>
      </Row>
    </>
  );
};

export default LeftWidget;
