import { CWBDrawerType } from '../../containers/FinancialReportDrawer/FinancialReportDrawerConstants';
import { IMultipartFormData } from '../services/MultipartFormData.data';
import { ProjectStatus } from './OverviewModels';

export interface IAccountDetailsResponse {
  accountId: string;
  accountName: string;
  assetType: AssetType;
  projects: IProjectEntity[];
}

export interface IProjectEntity {
  projectId: string;
  projectName: string;
  partner?: string;
  wbsCode?: string;
  applications?: string[];
  accessLevel?: string;
  createdOn?: string;
  createdBy?: string;
  status?: ProjectStatus;
}

export interface IAccountDetails {
  accountId: string;
  accountName: string;
  assetType: AssetType;
  projects: IProjectDetails[];
}

export interface IProjectDetails {
  projectId: string;
  projectName: string;
  createdOn?: string;
  createdBy?: string;
  status?: ProjectStatus;

  // applications are the CWB modules that are enabled for a project
  // This data in incoming from CWB side
  applications?: string[];
}

export interface IProjectOwnersPayload {
  accountId: string | undefined;
  projectId: string | undefined;
  assetType: string | undefined;
}

export interface IProjectOwnersResponse {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IAssessmentStateResponse {
  devsecops: boolean;
  governance: boolean;
  health: boolean;
  strategicGovernance: boolean;
  operationalGovernance: boolean;
  financialGovernance: boolean;
  devops: boolean;
}
export interface IAuditHistoryResponse {
  auditHistory?: IAuditHistory;
}

interface IAuditHistory {
  dashboard?: IAccountAuditHistory;
}

interface IAccountAuditHistory {
  account?: IAuditHistoryAccountDetails;
  project?: IDetails;
  enabledDiscoverAssessItems?: number[];
}

interface IDetails {
  id: string;
  name: string;
}

interface IAuditHistoryAccountDetails extends IDetails {
  assetType: AssetType;
}

export type IAuditHistoryPayload = IAuditHistory;

export interface IDiscoverAssessListItem {
  id: number;
  label: string;
  isEnabled: boolean;
  icon: string;
  popoverContent: string;
  cwbDrawerType: CWBDrawerType;
  reportCount: number;
  applicationName: string;
  isMoreOptions?: boolean;
  section?: AssessJourneySection;
  isDiscoverCard?: boolean;
  isCloudStrategy?: boolean;
  isFoundationSummaryCard?: boolean;
}

export interface IConfigureWorkLoadsPattern {
  landingZoneName: string;
  uploadData: IConfigureWorkLoadPayload;
}

export interface IConfigureMicroServiceError {
  error: Error;
  landingZoneName: string;
}

export interface IConfigureWorkLoadPayload {
  userdata: IMultipartFormData;
  file: IMultipartFormData;
}

export interface ICheckLandingZoneStatus {
  landingZoneName: string;
  landingZoneId: string;
}

export interface ICheckMicroServicesPatternStatus {
  landingZoneName: string;
  microServiceId: string;
}

export enum AssetType {
  TANGRA = 'Tangra',
  CWB = 'CWB',
}

export enum AssessJourneySection {
  ANALYSIS = 'Assess',
  PRE_CLOUD = 'Discover',
  POST_CLOUD = 'Plan',
}
