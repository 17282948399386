import { put, takeEvery } from 'redux-saga/effects';
import { IUserResponse } from '../../models/UserModels';
import userService from '../../services/user/UserService';
import { UserActions, UserActionTypes } from '../actions/UserActions';

export function* getUserWorker() {
  try {
    const response: IUserResponse = (yield userService.getUser())!;

    yield put(UserActions.getUserSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(UserActions.getUserError(error));
    }
  }
}

export function* userWatcher() {
  yield takeEvery(UserActionTypes.USER_REQUEST, getUserWorker);
}
