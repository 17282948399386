import React, { useState } from 'react';
import { Button, Input, Modal, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import PlusIcon from '../../assets/icons/plus-icon.svg';
import DashboardCards from './DashboardCards';
import { ICardsArrayProps } from './SubHubInsuranceDashboardConstants';

interface IDashboardLeftSideProps {
  breadCrumbRequired: boolean; // if true show viewDetails
}

const DashboardLeftSide = ({ breadCrumbRequired }: IDashboardLeftSideProps) => {
  const [searchText, setSearchText] = useState('');
  const [selectedTab, setSelectedTab] = useState('Retail');
  const [createCaseModalOpen, setCreateCaseModalOpen] = useState(false);
  const history = useHistory();
  // generate random 8 digit case Id
  const randomCaseId = Math.floor(10000000 + Math.random() * 90000000);
  const [caseId, setCaseId] = useState(randomCaseId);
  const [brokerName, setBrokerName] = useState('');

  const onSearch = (searchText: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(searchText.target.value);
  };

  const handleCaseIdChange = (searchText: React.ChangeEvent<HTMLInputElement>) => {
    // setCaseId(searchText.target.value);
  };
  const handleBrokerNameChange = (searchText: React.ChangeEvent<HTMLInputElement>) => {
    setBrokerName(searchText.target.value);
  };

  return (
    <>
      <div className="d-flex justify-space-between header-ins-cards">
        {/* <div className="tabs-container">
          {['Retail', 'Corporate'].map((tab) => (
            <Button
              type="text"
              key={tab}
              className={`tab ${selectedTab === tab ? 'selected' : ''}`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </Button>
          ))}
        </div> */}
        <h2>New Business</h2>
        <div>
          <Input
            placeholder={'Search...'}
            onChange={onSearch}
            prefix={<SearchOutlined className="project-search-icon" />}
            className="search-style mr-1"
          />
          <Button
            type="text"
            className="create-btn-style"
            // onClick={() => setCreateCaseModalOpen(true)}
            onClick={() => history.replace(`/newCaseForm?caseId=${caseId}`)}
          >
            <img src={PlusIcon} alt="" className="mr-1 filter-inv" /> Create New Case
          </Button>
        </div>
      </div>

      <DashboardCards searchText={searchText} breadCrumbRequired={breadCrumbRequired} />
      <Modal
        title="Enter Details"
        centered
        visible={createCaseModalOpen}
        footer={null}
        closable
        onCancel={() => setCreateCaseModalOpen(false)}
        className="case-details-modal"
      >
        <div>
          <p>Case Id Number</p>
          <Input disabled className="details-input" value={caseId} />

          <p>Broker Name</p>
          <Input onChange={handleBrokerNameChange} className="details-input" />
        </div>
        <div className="details-modal-footer">
          <Button
            type="text"
            className="flourescent-green-button"
            onClick={() => {
              setCreateCaseModalOpen(false);
              history.replace(`/newCaseForm?caseId=${caseId}`);
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DashboardLeftSide;
