import { Breadcrumb, Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import './BreadCrumb.less';

export interface IBreadCrumb {
  title: string;
  path?: string
}
export interface IBreadCrumbItems {
  items: IBreadCrumb[];
}
const BreadCrumb = ({ items }: IBreadCrumbItems) => {
  const history = useHistory();
  return (
    <div className="breadcrumb-container">
      <Breadcrumb separator="/">
        {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
        {items?.map(({ title, path }: IBreadCrumb, idx) => (
          <Breadcrumb.Item key={title}>
            {/* <a
              href=""
              onClick={() => {
                history.replace(path);
              }}
            >
              {title}
            </a> */}
            <Button
              type="text"
              onClick={() => {
                path && history.replace(path);
              }}
              className={`breadcrumb-item-style ${
                idx !== items.length - 1 ? 'ancestors-style' : ''
              }`}
            >
              {title}
            </Button>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumb;
