import { Reducer } from 'redux';
import { ISelectAccountPayload, ISelectProjectPayload } from '../../models/OverviewModels';
import { OverviewAction, OverviewActionTypes } from '../actions/OverviewActions';
import OverviewState from '../state/OverviewState';

export const initialState: OverviewState = {
  error: '',
  loading: false,
  selectedAccount: undefined,
  selectedProject: undefined,
  selectedAccountId: undefined,
  selectedProjectId: undefined,
  selectedAssetType: undefined,
};

export const overviewReducer: Reducer<OverviewState> = (
  state = initialState,
  action: OverviewAction,
) => {
  switch (action.type) {
    case OverviewActionTypes.SELECT_PROJECT: {
      const { projectId, projectName } = action.data as ISelectProjectPayload;
      return {
        ...state,
        selectedProject: projectName,
        selectedProjectId: projectId,
      };
    }

    case OverviewActionTypes.SELECT_ACCOUNT: {
      const { accountId, accountName, assetType } = action.data as ISelectAccountPayload;
      return {
        ...state,
        selectedAccountId: accountId,
        selectedAccount: accountName,
        selectedAssetType: assetType,
        selectedProjectId: undefined,
        selectedProject: undefined,
      };
    }
    case OverviewActionTypes.CREATE_ACCOUNT_START:
      return {
        ...state,
        loading: true,
      };

    case OverviewActionTypes.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case OverviewActionTypes.CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case OverviewActionTypes.ADD_ACCOUNT_START:
      return {
        ...state,
        loading: true,
      };

    case OverviewActionTypes.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case OverviewActionTypes.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case OverviewActionTypes.CREATE_PROJECT_START:
      return {
        ...state,
        loading: true,
      };

    case OverviewActionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case OverviewActionTypes.CREATE_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case OverviewActionTypes.EDIT_PROJECT_START:
      return {
        ...state,
        loading: true,
      };

    case OverviewActionTypes.EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case OverviewActionTypes.EDIT_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_STARTED:
      return {
        ...state,
      };

    case OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
      };

    case OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_ERROR:
      return {
        ...state,
        error: action.data,
      };

    default:
      return state;
  }
};
