import { put, takeEvery } from 'redux-saga/effects';
import {
  IAppInscopePayload,
  IAppInscopeResponse,
  IAppScopeAdjustmentPayload,
  IAppScopeAdjustmentResponse,
  ICombineAttributeCountResponse,
  IHeatmapPayload,
  IHeatmapResponse,
} from '../../models/CloudSuitabilityModels';
import CloudSuitabilityService from '../../services/CloudSuitabilityService/CloudSuitabilityService';
import {
  CloudSuitabilityAction,
  CloudSuitabilityActions,
  CloudSuitabilityActionTypes,
} from '../actions/CloudSuitabilityActions';

export function* getHeatmapDataWorker(action: CloudSuitabilityAction) {
  try {
    const data = action.data as IHeatmapPayload;
    const response: IHeatmapResponse = (yield CloudSuitabilityService.getHeatmapData(data))!;
    yield put(CloudSuitabilityActions.getHeatmapDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CloudSuitabilityActions.getHeatmapDataError(error));
    }
  }
}

export function* getAppScopeAdjustmentWorker(action: CloudSuitabilityAction) {
  try {
    const data = action.data as IAppScopeAdjustmentPayload;
    const response: IAppScopeAdjustmentResponse =
      (yield CloudSuitabilityService.getAppScopeAdjustment(data))!;
    yield put(CloudSuitabilityActions.getAppScopeAdjustmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CloudSuitabilityActions.getAppScopeAdjustmentError(error));
    }
  }
}
export function* getAppInScopeWorker(action: CloudSuitabilityAction) {
  try {
    const data = action.data as IAppInscopePayload;
    const response: IAppInscopeResponse = (yield CloudSuitabilityService.getAppInscope(data))!;
    yield put(CloudSuitabilityActions.getAppInScopeSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CloudSuitabilityActions.getAppInScopeError(error));
    }
  }
}
export function* getAppAdjustmentCountWorker(action: CloudSuitabilityAction) {
  try {
    const data = action.data as IAppScopeAdjustmentPayload;
    const response: ICombineAttributeCountResponse =
      (yield CloudSuitabilityService.getAppAdjustmentCount(data))!;
    yield put(CloudSuitabilityActions.getAppAdjustmentCountSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(CloudSuitabilityActions.getAppAdjustmentCountError(error));
    }
  }
}
export function* cloudSuitabilityWatcher() {
  yield takeEvery(CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED, getHeatmapDataWorker);
  yield takeEvery(
    CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_STARTED,
    getAppScopeAdjustmentWorker,
  );
  yield takeEvery(CloudSuitabilityActionTypes.GET_APP_INSCOPE_STARTED, getAppInScopeWorker);
  yield takeEvery(
    CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_STARTED,
    getAppAdjustmentCountWorker,
  );
}
