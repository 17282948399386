import { IAnalyticsPieChartResponse } from '../../store/actions/WebAnalytics/HitsAnalyticsActions';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.ANALYTICS;

export class HitsAnalyticsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getUserVsReturningUsers():
    | Promise<IAnalyticsPieChartResponse[]>
    | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.NEW_VS_RETURNING_USERS}`];
    return this.service.get({
      route: route,
    });
  }
  public getUsageLevelRoleData():
    | Promise<IAnalyticsPieChartResponse[]>
    | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.GET_ROLE_BASED_USAGE}`];
    return this.service.get({
      route: route,
    });
  }
  public getCloudStatusData():
    | Promise<IAnalyticsPieChartResponse[]>
    | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.GET_CLOUD_STATUS_DATA}`];
    return this.service.get({
      route: route,
    });
  }
  public getActiveUsersData():
    | Promise<IAnalyticsPieChartResponse[]>
    | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.GET_ACTIVE_USERS_DATA}`];
    return this.service.get({
      route: route,
    });
  }
  public getUserVisitsData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.GET_USERS_VISITS_DATA}`];
    return this.service.get({
      route: route,
    });
  }
  public getPageViewsData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.GET_PAGE_VIEWS_DATA}`];
    return this.service.get({
      route: route,
    });
  }

  public getAvgTimeData(): Promise<IAnalyticsPieChartResponse[]> | IAnalyticsPieChartResponse[] {
    const route: string[] = [`${API_ROUTE.AVG_TIME_SESSION_DATA}`];
    return this.service.get({
      route: route,
    });
  }
}

const hitsAnalyticsService = new HitsAnalyticsService();
export default hitsAnalyticsService;
