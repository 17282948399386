import React from 'react';
import './App.less';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Layout from './containers/Layout/Layout';

const App = () => {
  return (
    <ErrorBoundary>
      <Layout />
    </ErrorBoundary>
  );
};

export default App;
