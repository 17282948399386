export const SidebarMobileLabels = {
  overview: 'Cloud Journey',
  logout: 'Logout',
  asset: 'Asset Authorization',
  help: 'Help',
  operations: 'Deployments',
  analytics: 'Web Analytics',
  keyContacts: 'Key Contacts',
  quickLinks: 'Quick Links',
  feedback: 'Feedback',
  feedbackResponse: 'Feedback',
  adminPanelLogout: 'Logout from DCA',
  profileSettings: 'My Profile Settings',
  userManagement: 'User Management',
  projectManagement: 'Account & Project Management',
  adminPanelTitle: 'Welcome Admin!',
  assessment: 'Assessments',
  discover: 'Discover & Access',
  build: 'Build & Deploy',
  security: 'Security & Governance',
};
