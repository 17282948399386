import { put, takeEvery } from 'redux-saga/effects';
import {
  MetadataGuardrailAction,
  MetadataGuardrailsActionTypes,
  MetadataGuardrailsActions,
} from '../actions/MetadataGuardrailsActions';
import metadataGuardrailService from '../../services/MetadataGuardrailService/MetadataGuardrailService';
import {
  IFormListResp,
  IGuardrailDetailsResponse,
  IGuardrailPayload,
  IMetadataDetailResp,
  IMetadataPayload,
} from '../../models/MetadataGuardrailModels';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';

export function* getGuardDetailWorker(action: MetadataGuardrailAction) {
  try {
    const response: IGuardrailDetailsResponse =
      (yield metadataGuardrailService.getGuardrailsDetails(action.data as IGuardrailPayload))!;
    yield put(MetadataGuardrailsActions.getGuardrailDetailsSuccess(response.response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(MetadataGuardrailsActions.getGuardrailDetailsError(error));
    }
  }
}

export function* getMetadataDetailWorker(action: MetadataGuardrailAction) {
  try {
    const response: IMetadataDetailResp = (yield metadataGuardrailService.getMetadataDetails(
      action.data as IMetadataPayload,
    ))!;

    yield put(MetadataGuardrailsActions.getMetadataDetailsSuccess(response.response));
  } catch (error) {
    yield put(
      ToastActions.showToast({
        description: `Something went wrong, please try selecting other form type.`,
        type: NotificationTypes.ERROR,
      }),
    );
    if (error instanceof Error) {
      yield put(MetadataGuardrailsActions.getMetadataDetailsError(error));
    }
  }
}

export function* getMetadataFormlistWorker(action: MetadataGuardrailAction) {
  try {
    const response: IFormListResp = (yield metadataGuardrailService.getMetadataFormlist(
      action.data as IMetadataPayload,
    ))!;

    yield put(MetadataGuardrailsActions.getMetadataFormListSuccess(response.form_types));
  } catch (error) {
    if (error instanceof Error) {
      yield put(MetadataGuardrailsActions.getMetadataFormListError(error));
    }
  }
}

export function* metadataGuardrailWatcher() {
  yield takeEvery(MetadataGuardrailsActionTypes.GET_GUARDRAILS_DETAILS_START, getGuardDetailWorker);
  yield takeEvery(
    MetadataGuardrailsActionTypes.GET_METADATA_DETAILS_START,
    getMetadataDetailWorker,
  );
  yield takeEvery(
    MetadataGuardrailsActionTypes.GET_METADATA_FORM_LIST_START,
    getMetadataFormlistWorker,
  );
}
