import { Reducer } from 'redux';
import {
  IEditJobRequestParams,
  IJobRuleSetState,
  ILandingJobState,
  IRulesetHistory,
  IRunJobRequestSagaResponse,
} from '../../models/OperationsModels';
import { OperationsAction, OperationsActionTypes } from '../actions/OperationsActions';
import OperationsState from '../state/OperationsState';

export const initialState: OperationsState = {
  error: '',
  loading: false,
  jobRuleSets: [],
  currentRuleSetHistory: [],
  loadingJobRun: false,
  landingZones: [],
};

export const operationsReducer: Reducer<OperationsState> = (
  state = initialState,
  action: OperationsAction,
) => {
  switch (action.type) {
    case OperationsActionTypes.GET_JOB_LANDING_ZONE_START:
      return {
        ...state,
        loadingJobRun: true,
      };

    case OperationsActionTypes.GET_JOB_LANDING_ZONE_SUCCESS:
      return {
        ...state,
        landingZones: action.data as ILandingJobState[],
        loadingJobRun: false,
      };
    case OperationsActionTypes.GET_JOB_LANDING_ZONE_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loadingJobRun: false,
      };
    case OperationsActionTypes.RUN_JOB_RULESET_START:
      return {
        ...state,
        loadingJobRun: true,
      };

    case OperationsActionTypes.RUN_JOB_RULESET_SUCCESS: {
      const currentJobRunData = action.data as IRunJobRequestSagaResponse;
      return {
        ...state,
        jobRuleSets: state.jobRuleSets.map((jobRuleSet) => {
          if (jobRuleSet.ruleSetId === currentJobRunData.ruleSetId) {
            jobRuleSet.parameters = currentJobRunData.parameters;
            jobRuleSet.lastExecuted = currentJobRunData.lastExecuted;
          }
          return jobRuleSet;
        }),
        loadingJobRun: false,
      };
    }
    case OperationsActionTypes.RUN_JOB_RULESET_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loadingJobRun: false,
      };
    case OperationsActionTypes.EDIT_JOB_RULESET_START:
      return {
        ...state,
        loadingJobRun: true,
      };

    case OperationsActionTypes.EDIT_JOB_RULESET_SUCCESS: {
      const currentJobEditedData = action.data as IEditJobRequestParams;
      return {
        ...state,
        jobRuleSets: state.jobRuleSets.map((jobRuleSet) => {
          if (jobRuleSet.ruleSetId === currentJobEditedData.ruleSetId) {
            jobRuleSet.parameters = currentJobEditedData.parameters;
          }
          return jobRuleSet;
        }),
        loadingJobRun: false,
      };
    }
    case OperationsActionTypes.EDIT_JOB_RULESET_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loadingJobRun: false,
      };
    case OperationsActionTypes.GET_JOB_RULESET_START:
      return {
        ...state,
        loading: true,
      };

    case OperationsActionTypes.GET_JOB_RULESET_SUCCESS:
      return {
        ...state,
        jobRuleSets: action.data as IJobRuleSetState[],
        loading: false,
      };
    case OperationsActionTypes.GET_JOB_RULESET_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };
    case OperationsActionTypes.GET_RULESET_HISTORY_START:
      return {
        ...state,
        currentRuleSetHistory: action.data as IRulesetHistory[],
        loading: true,
      };

    case OperationsActionTypes.GET_RULESET_HISTORY_SUCCESS:
      return {
        ...state,
        currentRuleSetHistory: action.data as IRulesetHistory[],
        loading: false,
      };
    case OperationsActionTypes.GET_RULESET_HISTORY_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };
    case OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_START:
      return {
        ...state,
        loading: true,
      };

    case OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_SUCCESS:
      return {
        ...state,
        loading: false,
        jobRuleSets: [action.data as IJobRuleSetState, ...state.jobRuleSets],
      };
    case OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };

    default:
      return state;
  }
};
