import { put, takeEvery } from 'redux-saga/effects';
import {
  ISecurityAssessmentGetObj,
  IUploadAssessmentState,
} from '../../../models/DevopsAnalyticsModels';
import devOpsAnalyticsService from '../../../services/TrafficAnalyticsService/DevOpsAnalyticsService';
import { DashboardActions } from '../../actions/DashboardActions';
import {
  DevOpsAnalyticsAction,
  DevOpsAnalyticsActions,
  DevOpsAnalyticsActionTypes,
} from '../../actions/WebAnalytics/DevopsAnalytics';
import { HitsAnalyticsAction } from '../../actions/WebAnalytics/HitsAnalyticsActions';
import {
  IAssessmentQuestionsResponse,
  IAssessmentResultPayload,
  IDevopsDataResponse,
  IGovAnalysisDataResponse,
} from '../../state/DevOpsAnalyticsState';

export function* getDevopsData(action: HitsAnalyticsAction) {
  try {
    const { data } = action;
    const response: IDevopsDataResponse = (yield devOpsAnalyticsService.getCapabilityData(
      data as string,
    ))!;
    yield put(DevOpsAnalyticsActions.getAnalyticsAssessmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getAnalyticsAssessmentError(error));
    }
  }
}

export function* getGovernanceAnalysisData(action: HitsAnalyticsAction) {
  try {
    const { data } = action;
    const response: IGovAnalysisDataResponse = (yield devOpsAnalyticsService.getGovernanceData(
      data as string,
    ))!;
    yield put(DevOpsAnalyticsActions.getGovernanceAssessmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getGovernanceAssessmentError(error));
    }
  }
}

export function* postGovernanceAnalysisData(action: DevOpsAnalyticsAction) {
  try {
    const { data } = action;
    const response: IGovAnalysisDataResponse = (yield devOpsAnalyticsService.postGovernanceData(
      data as IUploadAssessmentState,
    ))!;
    yield put(DevOpsAnalyticsActions.postGovernanceAssessmentSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.postGovernanceAssessmentError(error));
    }
  }
}

export function* getAssessmentQuestionsWorker(action: DevOpsAnalyticsAction) {
  try {
    const { data } = action;
    const response: IAssessmentQuestionsResponse[] =
      (yield devOpsAnalyticsService.getAssessmentQuestionsService(data as string))!;
    yield put(DevOpsAnalyticsActions.getAssessmentQuestionsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getAssessmentQuestionsError(error));
    }
  }
}
export function* setAssessmentResultWorker(action: DevOpsAnalyticsAction) {
  try {
    const data = action.data as IAssessmentResultPayload;
    const response: string = (yield devOpsAnalyticsService.setAssessmentResultService(
      data as IAssessmentResultPayload,
    ))!;
    yield put(DashboardActions.getAssessmentDataStart(data.projectId));
    switch (data.assessmentType) {
      case 'devops':
        yield put(
          DevOpsAnalyticsActions.getDiscoverAssessmentStart({
            projectId: data.projectId,
            assessmentType: data.assessmentType,
          }),
        );
        break;
      case 'health':
        yield put(DevOpsAnalyticsActions.getBuildDeployAssessmentStart(data.projectId));
        break;
      case 'financialGovernance':
        yield put(
          DevOpsAnalyticsActions.getSecurityGovernanceAssessmentDataStart({
            projectId: data.projectId,
            assessmentType: data.assessmentType,
          }),
        );

        break;

      case 'operationalGovernance':
        yield put(
          DevOpsAnalyticsActions.getSecurityGovernanceAssessmentDataStart({
            projectId: data.projectId,
            assessmentType: data.assessmentType,
          }),
        );
        break;
      case 'strategicGovernance':
        yield put(
          DevOpsAnalyticsActions.getSecurityGovernanceAssessmentDataStart({
            projectId: data.projectId,
            assessmentType: data.assessmentType,
          }),
        );
        break;

      default:
        break;
    }

    yield put(DevOpsAnalyticsActions.setAssessmentResultSSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.setAssessmentResultError(error));
    }
  }
}

export function* getBuildDeployAssessmentData(action: HitsAnalyticsAction) {
  try {
    const { data } = action;
    const response: IGovAnalysisDataResponse =
      (yield devOpsAnalyticsService.getBuildDeployAssessmentData({
        assessmentType: 'health',
        projectId: data as string,
      }))!;
    yield put(DevOpsAnalyticsActions.getBuildDeployAssessmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getBuildDeployAssessmentError(error));
    }
  }
}

export function* getSecurityAssessmentData(action: DevOpsAnalyticsAction) {
  try {
    const { data } = action;

    const response: IGovAnalysisDataResponse =
      (yield devOpsAnalyticsService.getBuildDeployAssessmentData(
        data as ISecurityAssessmentGetObj,
      ))!;
    yield put(DevOpsAnalyticsActions.getSecurityGovernanceAssessmentDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getBuildDeployAssessmentError(error));
    }
  }
}

export function* getDiscoverDevopsAssessmentData(action: DevOpsAnalyticsAction) {
  try {
    const { data } = action;

    const response: IDevopsDataResponse =
      (yield devOpsAnalyticsService.getBuildDeployAssessmentData(
        data as ISecurityAssessmentGetObj,
      ))!;
    yield put(DevOpsAnalyticsActions.getDiscoverAssessmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(DevOpsAnalyticsActions.getDiscoverAssessmentError(error));
    }
  }
}

export function* devOpsAnalyticsWatcher() {
  yield takeEvery(DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_START, getDevopsData);
  yield takeEvery(
    DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_START,
    getGovernanceAnalysisData,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_START,
    postGovernanceAnalysisData,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_START,
    getAssessmentQuestionsWorker,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_START,
    setAssessmentResultWorker,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_START,
    getBuildDeployAssessmentData,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_START,
    getSecurityAssessmentData,
  );
  yield takeEvery(
    DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_START,
    getDiscoverDevopsAssessmentData,
  );
}
