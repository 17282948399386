import { Button, Modal, Upload } from 'antd';
import React from 'react';
import { RcFile } from 'antd/lib/upload/interface';
// import XLSX from 'sheetjs-style';
import { useDispatch } from 'react-redux';
import Close from '../../../src/assets/icons/cross.svg';
import UploadIcon from '../../../src/assets/icons/Uploadred.svg';
import {
  ContractActionTypes,
  ContractActions,
} from '../../libs/store/actions/UploadContractActions';
import { IContractCard } from './MNACIDashboard';
import contractService from '../../libs/services/ContractService/ContractService';
import { IUploadContracts } from '../../libs/store/state/ContractState';

export const allowedExcelFileTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

interface IUploadFileProps {
  show: boolean;
  setShow: (state: boolean) => void;
}
const UploadFileModal = ({ show, setShow }: IUploadFileProps) => {
  const [error, setError] = React.useState(false);
  const [contracts, setContracts] = React.useState<IContractCard[]>();
  const [fileBlob, setFileBlob] = React.useState<File | any>();

  const dispatch = useDispatch();
  const dispatcher = {
    addContract: (payload: IUploadContracts) =>
      dispatch(ContractActions.uploadContractStart(payload))
  };

  return (
    <Modal
      visible={show}
      width={'60vw'}
      onCancel={() => setShow(false)}
      centered
      footer={null}
      closeIcon={<img src={Close} alt="close" className="close-icon" />}
      className="conf-arc-modal"
      destroyOnClose={true}
    >
      <h3>
        <b>Upload File</b>
      </h3>
      <Upload.Dragger
        name="file"
        accept=".pdf"
        multiple={false}
        beforeUpload={(file: RcFile) => {
          // const { type } = file;
          // if (!allowedExcelFileTypes.includes(type)) {
          //   setError(true);
          // }
          // return !allowedExcelFileTypes.includes(type);
          return false;
        }}
        // maxCount={1}
        onChange={(e) => {
          const userDetails = localStorage?.userDetails && JSON.parse(localStorage?.userDetails);
          const createdBy = userDetails?.given_name
            ? userDetails?.given_name + ' ' + userDetails?.family_name
            : 'Raheja Malikk';
          setFileBlob(e?.file);
          // const { type } = e?.fileList?.[e?.fileList.length - 1];
          // console.log(type)
          // e?.fileList
          // setFile(e?.fileList?.[e?.fileList.length - 1].originFileObj as RcFile);
          // if (allowedExcelFileTypes.includes(type || '')) {
          //   const reader = new FileReader();
          //   reader.onload = function (ev) {
          //     const data = ev.target?.result;
          //     const workbook = XLSX.read(data, {
          //       type: 'binary',
          //     });
          //     const sheetName = workbook.SheetNames[1];
          //     const worksheet = workbook.Sheets[sheetName];
          //     const excelData = XLSX.utils.sheet_to_json(worksheet, {
          //       header: 1,
          //       blankrows: false,
          //     });
          //     setFileData(excelData);
          //   };
          //   reader.readAsBinaryString(
          //     e?.fileList?.[e?.fileList.length - 1].originFileObj as RcFile,
          //   );
          //   setShow(false);
          //   if (!showDrawer) {
          //     setShowDrawer(true);
          //   }
          //   return;
          // }
          // openNotification(e.fileList?.[e.fileList.length - 1].name);
        }}
      >
        <p className="ant-upload-drag-icon">
          <img src={UploadIcon} alt="" />
        </p>
        <p className="ant-upload-text">
          Drag and drop, or <span className="browse-text">Browse</span> your emails
        </p>
      </Upload.Dragger>
      {/* {error && <p className="error-message">Please upload a valid file.</p>} */}
      <div className="d-flex justify-content-end">
        <Button
          type="primary"
          className="save-btn-style-mnaci"
          onClick={() => {
            dispatcher.addContract({
              file: fileBlob,
            });
            setShow(false)
          }}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
