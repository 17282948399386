import {
  IEditJobRequestParams,
  IGetJobLandingZoneRequest,
  IJobRuleSetState,
  ILandingJobState,
  IRulesetHistory,
  IUploadYamlParams,
} from '../../models/OperationsModels';
import { IPipelineInstanceDetailsResponse } from '../../models/PipelineModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { storage, STORAGE_CONSTANTS } from '../LocalStorage';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.DCP;
const serviceTypeLocal = ServiceType.None;

export class OperationsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.dcp);

  private readonly serviceLocal: ApiServiceBase = new ApiService(
    serviceTypeLocal,
    ServerType.tangra,
  );

  public getJobLandingZones(
    data: IGetJobLandingZoneRequest,
  ): Promise<ILandingJobState[]> | ILandingJobState[] {
    return this.serviceLocal.get({
      route: [`${API_ROUTE.GET_JOB_LANDING_ZONES}/${data.projectId}`],
    });
  }

  public getJobRuleSet(
    projectId: string,
    landingZoneId?: string,
  ): Promise<IJobRuleSetState[]> | IJobRuleSetState[] {
    return this.serviceLocal.get({
      route: [`${API_ROUTE.GET_LANDING_ZONES_RULESETS}/${projectId}`],
      query: { landingZoneId },
    });
  }

  public editJobRuleSet(
    editJobParams: IEditJobRequestParams,
  ): Promise<IJobRuleSetState[]> | IJobRuleSetState[] {
    return this.serviceLocal.post(
      { route: [`${API_ROUTE.EDIT_RULESETS_PARAMS}/${editJobParams.ruleSetId}`] },
      editJobParams.parameters,
    );
  }

  public runJobRuleSet(
    runJobParams: IEditJobRequestParams,
  ): Promise<IJobRuleSetState[]> | IJobRuleSetState[] {
    return this.serviceLocal.post(
      { route: [`${API_ROUTE.RUN_RULESET}/${runJobParams.ruleSetId}`] },
      runJobParams.parameters,
    );
  }

  public getRulesetHistory(rulesetId: string): Promise<IRulesetHistory[]> | IRulesetHistory[] {
    return this.service.get({ route: [`${API_ROUTE.RULESET_HISTORY}/${rulesetId}`] });
  }

  public getWorkflowDetails(
    workflowId: string,
  ): Promise<IPipelineInstanceDetailsResponse> | IPipelineInstanceDetailsResponse {
    return this.service.get({ route: [`${API_ROUTE.GET_WORKFLOW_DETAILS}/${workflowId}`] });
  }

  public uploadCreateYaml(uploadCreateRuleSetParams: IUploadYamlParams): Promise<string> | string {
    const { uploadYamlPayload } = uploadCreateRuleSetParams;
    return this.serviceLocal.postMultipart(
      {
        route: [`${API_ROUTE.UPLOAD_CREATE_RULE_SET_YAML}`],
      },
      [
        uploadYamlPayload.ruleSetYamlFile,
        uploadYamlPayload.jobYamlFile,
        uploadYamlPayload.projectId,
        uploadYamlPayload.cloudServiceProvider,
        uploadYamlPayload.landingZoneId,
      ],
    );
  }
}

const operationsService = new OperationsService();
export default operationsService;
