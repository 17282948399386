import {
  IEditJobRequestParams,
  IGetJobLandingZoneRequest,
  IJobRuleSetState,
  ILandingJobState,
  IRulesetHistory,
  IRuleSetQueryParameter,
  IRuleYamlPayload,
  IRunJobRequestSagaResponse,
  IUploadYamlParams,
} from '../../models/OperationsModels';
import { IStoreAction } from '../StoreHelper';

export enum OperationsActionTypes {
  GET_JOB_LANDING_ZONE_START = 'GET_JOB_LANDING_ZONE_START',
  GET_JOB_LANDING_ZONE_SUCCESS = 'GET_JOB_LANDING_ZONE_SUCCESS',
  GET_JOB_LANDING_ZONE_ERROR = 'GET_JOB_LANDING_ZONE_ERROR',
  GET_JOB_RULESET_START = 'GET_JOB_RULESET_START',
  GET_JOB_RULESET_SUCCESS = 'GET_JOB_RULESET_SUCCESS',
  GET_JOB_RULESET_ERROR = 'GET_JOB_RULESET_ERROR',
  EDIT_JOB_RULESET_START = 'EDIT_JOB_RULESET_START',
  EDIT_JOB_RULESET_SUCCESS = 'EDIT_JOB_RULESET_SUCCESS',
  EDIT_JOB_RULESET_ERROR = 'EDIT_JOB_RULESET_ERROR',
  RUN_JOB_RULESET_START = 'RUN_JOB_RULESET_START',
  RUN_JOB_RULESET_SUCCESS = 'RUN_JOB_RULESET_SUCCESS',
  RUN_JOB_RULESET_ERROR = 'RUN_JOB_RULESET_ERROR',
  GET_RULESET_HISTORY_START = 'GET_RULESET_HISTORY_START',
  GET_RULESET_HISTORY_SUCCESS = 'GET_RULESET_HISTORY_SUCCESS',
  GET_RULESET_HISTORY_ERROR = 'GET_RULESET_HISTORY_ERROR',
  UPLOAD_CREATE_RULESET_YAML_START = 'UPLOAD_CREATE_RULESET_YAML_START',
  UPLOAD_CREATE_RULESET_YAML_SUCCESS = 'UPLOAD_CREATE_RULESET_YAML_SUCCESS',
  UPLOAD_CREATE_RULESET_YAML_ERROR = 'UPLOAD_CREATE_RULESET_YAML_ERROR',
}

export type OperationsActionPayload =
  | string
  | IRulesetHistory[]
  | Error
  | IJobRuleSetState[]
  | IEditJobRequestParams
  | IGetJobLandingZoneRequest
  | ILandingJobState[]
  | IRuleYamlPayload
  | IUploadYamlParams
  | IRuleSetQueryParameter;

export type OperationsAction = IStoreAction<OperationsActionTypes, OperationsActionPayload>;
export class OperationsActions {
  public static getJobLandingZones(data: IGetJobLandingZoneRequest): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_LANDING_ZONE_START,
      data,
    };
  }

  public static getJobLandingZoneSuccess(data: ILandingJobState[]): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_LANDING_ZONE_SUCCESS,
      data,
    };
  }

  public static getJobLandingZoneError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_LANDING_ZONE_ERROR,
      data,
    };
  }
  public static runJobRuleSetStart(data: IEditJobRequestParams): OperationsAction {
    return {
      type: OperationsActionTypes.RUN_JOB_RULESET_START,
      data,
    };
  }

  public static runJobRuleSetSuccess(data: IRunJobRequestSagaResponse): OperationsAction {
    return {
      type: OperationsActionTypes.RUN_JOB_RULESET_SUCCESS,
      data,
    };
  }

  public static runJobRuleSetError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.RUN_JOB_RULESET_ERROR,
      data,
    };
  }
  public static editJobRuleSetStart(data: IEditJobRequestParams): OperationsAction {
    return {
      type: OperationsActionTypes.EDIT_JOB_RULESET_START,
      data,
    };
  }

  public static editJobRuleSetSuccess(data: IEditJobRequestParams): OperationsAction {
    return {
      type: OperationsActionTypes.EDIT_JOB_RULESET_SUCCESS,
      data,
    };
  }

  public static editJobRuleSetError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.EDIT_JOB_RULESET_ERROR,
      data,
    };
  }
  public static getJobRuleSetStart(data: IRuleSetQueryParameter): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_RULESET_START,
      data,
    };
  }

  public static getJobRuleSetSuccess(data: IJobRuleSetState[]): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_RULESET_SUCCESS,
      data,
    };
  }

  public static getJobRuleSetError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.GET_JOB_RULESET_ERROR,
      data,
    };
  }
  public static getRulesetHistoryStart(data: string): OperationsAction {
    return {
      type: OperationsActionTypes.GET_RULESET_HISTORY_START,
      data,
    };
  }

  public static getRulesetHistorySuccess(data: IRulesetHistory[]): OperationsAction {
    return {
      type: OperationsActionTypes.GET_RULESET_HISTORY_SUCCESS,
      data,
    };
  }

  public static getRulesetHistoryError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.GET_RULESET_HISTORY_ERROR,
      data,
    };
  }

  public static uploadYamlStart(data: IUploadYamlParams): OperationsAction {
    return {
      type: OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_START,
      data,
    };
  }
  public static uploadYamlSuccess(data: IJobRuleSetState): OperationsAction {
    return {
      type: OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_SUCCESS,
      data,
    };
  }
  public static uploadYamlError(data: Error): OperationsAction {
    return {
      type: OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_ERROR,
      data,
    };
  }
}
