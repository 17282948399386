import {
  IDataAugmentationPayload,
  IDataAugmentationResponse,
  IStageResponse,
} from '../../models/PersonaModels';
import { IStoreAction } from '../StoreHelper';

export enum PersonaActionTypes {
  DATA_INGESTION_STAGE_START = 'DATA_INGESTION_STAGE_START',
  DATA_INGESTION_STAGE_SUCCESS = 'DATA_INGESTION_STAGE_SUCCESS',
  DATA_INGESTION_STAGE_ERROR = 'DATA_INGESTION_STAGE_ERROR',

  // for getting data for data augmentation tab
  DATA_AUGMENTATION_START = 'DATA_AUGMENTATION_START',
  DATA_AUGMENTATION_SUCCESS = 'DATA_AUGMENTATION_SUCCESS',
  DATA_AUGMENTATION_ERROR = 'DATA_AUGMENTATION_ERROR',
}

export type PersonaActionPayload =
  | string
  | IStageResponse[]
  | IDataAugmentationResponse[]
  | IDataAugmentationPayload
  | IDataAugmentationResponse[]
  | Error
  | null;

export type PersonaAction = IStoreAction<PersonaActionTypes, PersonaActionPayload>;

export class PersonaActions {
  public static getStageDetails(submissionId: string): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_INGESTION_STAGE_START,
      data: submissionId,
    };
  }
  public static getStageDetailsSuccess(data: IStageResponse[]): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_INGESTION_STAGE_SUCCESS,
      data,
    };
  }
  public static getStageDetailsError(error: Error): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_INGESTION_STAGE_ERROR,
      data: error,
    };
  }

  // for getting data for data augmentation tab
  public static getAugmentationData(payload: IDataAugmentationPayload): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_AUGMENTATION_START,
      data: payload,
    };
  }
  public static getAugmentationDataSuccess(data: IDataAugmentationResponse[]): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_AUGMENTATION_SUCCESS,
      data,
    };
  }
  public static getAugmentationDataError(error: Error): PersonaAction {
    return {
      type: PersonaActionTypes.DATA_AUGMENTATION_ERROR,
      data: error,
    };
  }
}
