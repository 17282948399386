import { put, takeEvery } from 'redux-saga/effects';
import { ChatbotAction, ChatbotActionTypes, ChatbotActions } from '../actions/ChatbotActions';
import chatbotService from '../../services/ChatbotService';
import { IChatbotResponseType } from '../../models/ChatbotModels';
import {
  ContractAction,
  ContractActions,
  ContractActionTypes,
} from '../actions/UploadContractActions';
import contractService from '../../services/ContractService';
import { IPdfUrlResponse } from '../../../containers/MNACIDashboard/MNACIDashboard';
import {
  IPostContractsPayload,
  IPostContractsResponse,
  IUploadContracts,
} from '../state/ContractState';
import {
  IContractDetails,
  IContractDetailsRequestBody,
  IContractDetailsResponse,
} from '../../../containers/MNACIDashboard/MNACIDashboardModel';
import { ToastActions } from '../actions/ToastActions';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';

export function* getPdfUrlWorker(action: ContractAction) {
  try {
    const response: IPdfUrlResponse = (yield contractService.getPdfUrl(action.data as string))!;
    yield put(ContractActions.getPdfUrlSuccess(response.url));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ContractActions.getPdfUrlError(error));
    }
  }
}
export function* postSearchContractWorker(action: ContractAction) {
  try {
    const response: IPostContractsResponse = (yield contractService.postSearchContract(
      action.data as IPostContractsPayload,
    ))!;
    yield put(ContractActions.postSearchContractSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ContractActions.postSearchContractError(error));
    }
  }
}

export function* getContracListWorker(action: ContractAction) {
  try {
    const response: IContractDetailsResponse = (yield contractService.getContractList(
      action.data as IContractDetailsRequestBody,
    ))!;
    yield put(ContractActions.getContractDetailsListSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ContractActions.getContractDetailsListError(error));
    }
  }
}

export function* getContractDataWorker(action: ContractAction) {
  try {
    const response: IContractDetails[] = (yield contractService.getContractData(
      action.data as string,
    ))!;
    yield put(ContractActions.getContractDetailsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ContractActions.getContractDetailsDataError(error));
    }
  }
}
export function* uploadContractWroker(action: ContractAction) {
  try {
    const data = action.data as IUploadContracts;
    const response: string = (yield contractService.uploadContract(data))!;
    yield put(
      ToastActions.showToast({
        description: 'Contract uploaded successfully',
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(ContractActions.uploadContractSuccess());
  } catch (error) {
    yield put(
      ToastActions.showToast({
        description: 'Failed to upload the contract. Please try again.',
        type: NotificationTypes.ERROR,
      }),
    );
    if (error instanceof Error) {
      yield put(ContractActions.uploadContractError(error));
    }
  }
}

export function* contractWatcher() {
  yield takeEvery(ContractActionTypes.GET_PDF_URL_START, getPdfUrlWorker);
  yield takeEvery(ContractActionTypes.POST_SEARCH_CONTRACTS_START, postSearchContractWorker);
  yield takeEvery(ContractActionTypes.GET_CONTRACT_DETAILS_LIST_STARTED, getContracListWorker);
  yield takeEvery(ContractActionTypes.GET_CONTRACT_DETAILS_DATA_STARTED, getContractDataWorker);
  yield takeEvery(ContractActionTypes.UPLOAD_CONTRACT_START, uploadContractWroker);
}
