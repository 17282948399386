import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { routes } from '../../navigation/Routes';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { SideBarKey } from '../Sidebar/SidebarConstants';
import RightWidget from './RightWidget/RightWidget';
import './SubHubInsuranceDashboard.less';
import DashboardLeftSide from './DashboardLeftSide';
import BreadCrumb from './BreadCrumbNav/BreadCrumb';
import { ICardsArrayProps } from './SubHubInsuranceDashboardConstants';


interface ISubHubInsProp {
  breadCrumbRequired: boolean;
}
const SubHubInsuranceDashboard = ({ breadCrumbRequired }: ISubHubInsProp) => {
  const [isViewDetailsVisible, setViewDetailsVisible] = useState<boolean>(false);

  const dispatch = useDispatch();

  const dispatcher = {
    setActiveKey: (data: string) => dispatch(SidebarActions.setSidebarKey(data)),
  };

  const setActiveKey = (data: string) => dispatcher.setActiveKey(data); 

  const items = useMemo(() => {
    if (!breadCrumbRequired) {
      return [
        { title: 'Policy Analytics', path: routes.dashboard },
        { title: 'Submission', path: routes.submissions },
      ];
    }
    const items = [{ title: 'Policy Analytics', path: routes.dashboard }];

    if (isViewDetailsVisible) {
      const obj = {
        title: 'Submission',
        path: routes.submissions,
        onClick: () => {
          setViewDetailsVisible(false);
        },
      };
      // items.push({
      //   title: 'Submission',
      //   path: routes.submissions,
      //   onClick: () => {
      //     setViewDetailsVisible(false);
      //   },
      // });
      items.push(obj);
      items.push({ title: 'Prevodic Auto Insurance', path: routes.submissions });
    } else {
      items.push({ title: 'Submission', path: routes.submissions });
    }
    return items;
  }, [isViewDetailsVisible]);

  useEffect(() => {
    if(breadCrumbRequired){
      // setActiveKey(SideBarKey.BUSINESS_INTELLIGENCE);
    }
    else
      setActiveKey(SideBarKey.UNDERWRITER);
  }, [breadCrumbRequired])
  

  return (
    <div className="container">
      {breadCrumbRequired && <Row className="mb-1">{<BreadCrumb items={items} />}</Row>}
      <Row>
      <Col span={16}>
        <DashboardLeftSide
          breadCrumbRequired={breadCrumbRequired}
        />
      </Col>
      <Col span={8}>
        <RightWidget />
      </Col>
    </Row>
    </div>
  );
};

export default SubHubInsuranceDashboard;
