import { Button, Col, Input, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import './Chatbot.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ChatbotIcon from '../../assets/icons/MNACI/chatbot_icon.svg';
import UserIcon from '../../assets/icons/UserChatbot.svg';
import FilterIcon from '../../assets/icons/MNACI/filter-funnel.svg';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { ChatbotActions } from '../../libs/store/actions/ChatbotActions';
import Loader from '../../components/Loader/Loader';
import { FormOptions } from './ChatbotConstants';

interface IMessageFormat {
  sender: string;
  query: string;
  key: React.Key;
  responseTime?: number;
}

const Chatbot = () => {
  const { Title } = Typography;
  const [query, setQuery] = React.useState('');
  const [quesQuery, setQuesQuery] = React.useState('');
  const [messages, setMessages] = React.useState<IMessageFormat[]>([]);
  const [questionKey, setQuestionKey] = React.useState<number>(0);
  const [formSelected, setFormSelected] = React.useState<string>(FormOptions[0].value);
  const [responseTimeStart, setResponseTimeStart] = React.useState<number>(0);
  const dispatch = useDispatch();
  const dispatcher = {
    sendQuery: (data: string) => dispatch(ChatbotActions.sendQueryStart(data)),
  };
  const { response, loading } = useAppSelector(
    ({ chatbotResponse: { response, loading } }: IApplicationState) => ({
      response,
      loading,
    }),
  );

  React.useEffect(() => {
    if (response?.response !== undefined)
      setMessages((old) => [
        ...old,
        {
          sender: 'ChatBot',
          query: response?.response || 'Please try again...',
          key: Date.now(),
          responseTime: (Date.now() - responseTimeStart) / 1000,
        },
      ]);
    setResponseTimeStart(0);
  }, [response]);

  useEffect(() => {
    setMessages([]);
  }, [])
  
  
  const conversationCards = [
    {
      title: 'Conversation 1',
      id: '1',
      text: 'Conversation 1',
    },
    // {
    //   title: 'Lörem ipsum divis relig munat min...',
    //   id: '2',
    //   text: 'Lörem ipsum divis relig munat san min antivibelt. Gåligt byhugt megan subede fast gåskap. Lörem ipsum',
    // },
    // {
    //   id: '3',
    //   title: 'Lörem ipsum divis relig munat min...',
    //   text: 'Lörem ipsum divis relig munat san min antivibelt. Gåligt byhugt megan subede fast gåskap. Lörem ipsum',
    // },
    // {
    //   id: '4',
    //   title: 'Lörem ipsum divis relig munat min...',
    //   text: 'Lörem ipsum divis relig munat san min antivibelt. Gåligt byhugt megan subede fast gåskap. Lörem ipsum',
    // },
    // {
    //   id: '5',
    //   title: 'Lörem ipsum divis relig munat min...',
    //   text: 'Lörem ipsum divis relig munat san min antivibelt. Gåligt byhugt megan subede fast gåskap. Lörem ipsum',
    // },
  ];
  const chatContainerRef = React.useRef(null);
  //   React.useEffect(() => {
  //     // Scroll to the bottom of the chat container when a new message is added
  //     if(chatContainerRef?.current && chatContainerRef?.current.scrollTop)
  //     chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight;
  //   }, []);

  const [selectedRecentConversation, setSelectedRecentConversation] = React.useState('1');
  const handleSendMessage = () => {
    if (query) {
      setResponseTimeStart(Date.now());
      dispatcher.sendQuery(query);
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          sender: 'User',
          query: query,
          key: Date.now(),
        },
      ]);
      setQuery('');
    }

    //     if(chatContainerRef?.current && chatContainerRef?.current.scrollTop)
    // chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight;
  };
  useEffect(() => {
    handleSendMessage();
  }, [quesQuery]);
  const quickQuestion = (e: any, key: number) => {
    let quickQuestionQuery = e?.target?.innerText;
    setQuery(quickQuestionQuery);
    setQuesQuery(quickQuestionQuery);
    setQuestionKey(key);
  };

  const handleFormChange = (value: string) => {
    setFormSelected(value);
  };
  return (
    <div className="chatbot-wrapper">
      {/* <div>
        <Typography className="chat-desc">
          <b>Hello, I am contracts Chatbot.</b> Please ask me any questions related to contracts
        </Typography>
      </div> */}
      <Row gutter={[16, 16]}>
        <Col span={6} className="overflow-hidden">
          <div className="recent-conversation">
            <div className="header">
              <Typography className="title">Suggested Questions</Typography>
              <img src={FilterIcon} alt="" />
            </div>
            <div className="conversation-container">
              {conversationCards.map((card) => (
                <div key={card.id} className="conversation-card d-flex">
                  {/* <img src={ChatbotIcon} alt="" /> */}
                  <div>
                    {/* <Typography className="title">{card.title}</Typography>
                    <Typography className="text">{card.text}</Typography> */}
                    <Title
                      className={questionKey === 1 ? 'checked' : ''}
                      level={5}
                      onClick={(e) => quickQuestion(e, 1)}
                    >
                      Can you give me some idea of terms and conditions on VMWare contracts?
                    </Title>
                    <Title
                      className={questionKey === 2 ? 'checked' : ''}
                      key={2}
                      level={5}
                      onClick={(e) => quickQuestion(e, 2)}
                    >
                      Can you tell me what is SPP credits?
                    </Title>
                    <Title
                      className={questionKey === 3 ? 'checked' : ''}
                      key={3}
                      level={5}
                      onClick={(e) => quickQuestion(e, 3)}
                    >
                      What are the Invoicing and Payment Terms in VMWare contracts?
                    </Title>
                    <Title
                      className={questionKey === 4 ? 'checked' : ''}
                      key={4}
                      level={5}
                      onClick={(e) => quickQuestion(e, 4)}
                    >
                      Can you summarize the risks in the agreement?
                    </Title>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col span={18}>
          <Spin spinning={loading}>
            <div className="chat-window">
              <div className="form-drop-down-container">
                <div className='form-drop-down-label'><b>Select Contract From Type:</b></div><Select value={formSelected} onChange={handleFormChange} options={FormOptions} />
              </div>
              {messages.length === 0 ? (
                <div className="no-chats-placeholder-container">
                  <div className="no-chats-placeholder">
                    <div className="chatbot-no-chat-icon">
                      <img className="chatbot-icon" src={ChatbotIcon} alt="Chatbot Icon" />
                    </div>
                    <div className="no-chat-message">
                      <div>
                        <b>Hello</b>, I am contracts Chatbot.
                      </div>
                      <div>Please ask me any questions related to contracts.</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="chat-messages-container" ref={chatContainerRef}>
                  {messages.map((message) => {
                    return (
                      <>
                        <div
                          key={message.key}
                          className={`message ${
                            message.sender === 'User' ? 'message-sender-user' : ''
                          }`}
                        >
                          <img src={message.sender === 'User' ? UserIcon : ChatbotIcon} alt="" />
                          <Typography className="query-response">{message.query}</Typography>
                        </div>
                        {message.sender !== 'User' && message?.responseTime && (
                          <div className="response-time-container">
                            Response Time: <b>{(message.responseTime).toFixed(2)} sec</b>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              <div className="footer">
                <div className="word-length-container" style={{ float: 'right' }}>
                  Word limit : <b>{500 - query.length}</b>
                </div>
                <Input
                  style={{
                    minHeight: '3rem',
                    fontSize: '1rem',
                    padding: '0 1rem',
                  }}
                  className="question-input-container"
                  maxLength={500}
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  status={query.length === 200 ? 'error' : ''}
                  placeholder="Hello, I am contracts Chatbot. Please ask me any questions related to contracts"
                  suffix={
                    // <ArrowRightOutlined
                    //   className="cursor-pointer aero-style"
                    //   style={{ fontSize: '150%' }}
                    //   onClick={handleSendMessage}
                    // />
                    <Button
                      onClick={handleSendMessage}
                      disabled={query.length === 0}
                      className="generate-btn"
                    >
                      Generate
                    </Button>
                  }
                  onKeyDown={(k) => {
                    if (k.key === 'Enter') {
                      handleSendMessage();
                    }
                  }}
                ></Input>
              </div>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default Chatbot;
