import { Card, CardProps } from 'antd';
import React from 'react';
import './CardContainerStyles.less';

// Can't use customClassName as it's a restricted keyword
type CardContainerProps = { customclassname?: string } & CardProps;

const CardContainer = (props: CardContainerProps) => {
  const { customclassname } = props;
  return <Card {...props} className={`card-container ${customclassname || ''}`} />;
};

export default CardContainer;
