import { Button, Modal } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import AccountIcon from '../../assets/icons/account-and-project-management.svg';
import Logout from '../../assets/icons/logout.svg';
import ProfileIcon from '../../assets/icons/my-profile-settings.svg';
import UserIcon from '../../assets/icons/user-management.svg';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import { SideBarKey, SidebarLabels as LABELS } from './SidebarConstants';
import './SidebarStyles.less';
interface ISidebarOptionsModalProps {
  onLogout: () => void;
  isAdminPopoverOpen: boolean;
  setIsAdminPopoverOpen: (value: boolean) => void;
}

const SidebarOptionsModal = ({
  onLogout,
  isAdminPopoverOpen,
  setIsAdminPopoverOpen,
}: ISidebarOptionsModalProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatcher = {
    setActiveKey: (data: string) => dispatch(SidebarActions.setSidebarKey(data)),
  };
  const { userDetails, logoutState } = useAppSelector(
    ({ sidebar: { logout }, user: { userDetails } }: IApplicationState) => ({
      userDetails,
      logoutState: logout,
    }),
  );
  return (
    <Modal
      visible
      width="19rem"
      wrapClassName="admin-popover-modal-wrap"
      className="admin-popover-modal"
      onCancel={() => setIsAdminPopoverOpen(!isAdminPopoverOpen)}
      closable={false}
      footer={null}
    >
      <div className="admin-popover-menu">
        {/* <Button
          icon={<img src={UserIcon} alt="User Icon" className="admin-panel-icon" />}
          type="text"
          className="admin-popover-menu-item"
          //To check whether user is allowed to access user management or not
          disabled={!userDetails?.user_permissions.user_management}
          onClick={() => {
            dispatcher.setActiveKey(SideBarKey.USER_MANAGEMENT);
            history.push(routes.userManagement);
            setIsAdminPopoverOpen(false);
          }}
        >
          {LABELS.userManagement}
        </Button>
        <Button
          icon={<img src={AccountIcon} alt="Account Icon" className="admin-panel-icon" />}
          type="text"
          className="admin-popover-menu-item"
          onClick={() => {
            dispatcher.setActiveKey(SideBarKey.ACCOUNT_MANAGEMENT);
            history.push(routes.accountManagement);
            setIsAdminPopoverOpen(!isAdminPopoverOpen);
          }}
        >
          {LABELS.projectManagement}
        </Button>
        <Button
          icon={<img src={ProfileIcon} alt="Profile Icon" className="admin-panel-icon" />}
          type="text"
          className="admin-popover-menu-item"
          disabled
        >
          {LABELS.profileSettings}
        </Button> */}
        <Button
          type="text"
          className="admin-popover-menu-item admin-popover-menu-item-logout "
          icon={<img src={Logout} alt="User Icon" className="admin-panel-icon" />}
          onClick={() => {
            setIsAdminPopoverOpen(!isAdminPopoverOpen);
            onLogout();
          }}
        >
          {LABELS.adminPanelLogout}
        </Button>
      </div>
    </Modal>
  );
};

export default SidebarOptionsModal;
