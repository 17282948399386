import { Button, Typography } from 'antd';
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { useDispatch } from 'react-redux';
import CloseIcon from '../../assets/icons/cross.svg';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { SideBarKey } from '../Sidebar/SidebarConstants';
import { TooltipButtonLabel } from './ProductTourConstants';
import './TooltipStyles.less';

const Tooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}: TooltipRenderProps) => {
  const dispatch = useDispatch();

  const dispatcher = {
    setActiveKey: (data: string) => dispatch(SidebarActions.setSidebarKey(data)),
  };

  const setActiveKey = (data: string) => dispatcher.setActiveKey(data);

  return (
    <div {...tooltipProps} className="tooltip-container">
      <div className="tooltip-header-container">
        <div className="tooltip-header">
          <span className="tooltip-index">{index + 1}</span>
          <Typography className="tooltip-title">{step.title && <div>{step.title}</div>}</Typography>
        </div>
        <img
          {...skipProps}
          onClick={(e) => {
            skipProps.onClick(e);
            setActiveKey(SideBarKey.DASHBOAARD);
          }}
          src={CloseIcon}
          alt="Close"
          className="tooltip-close-button"
        />
      </div>
      <Typography className="tooltip-content">{step.content}</Typography>
      <div className="tooltip-footer">
        {index > 0 ? (
          <Button {...backProps} className="tooltip-button">
            {TooltipButtonLabel.BACK}
          </Button>
        ) : (
          <div className="tooltip-ghost-box" />
        )}
        <div className="tooltip-progress-bar">
          {Array.from(Array(size)).map((x, i) => (
            <span
              className={i <= index ? `filled-progress-dot` : `progress-dot`}
              key={`tooltip-progress-dot-${i}`}
            />
          ))}
        </div>
        {continuous && (
          <Button
            {...primaryProps}
            onClick={(e) => {
              primaryProps.onClick(e);
              if (isLastStep) setActiveKey(SideBarKey.DASHBOAARD);
            }}
            className="tooltip-button"
          >
            {isLastStep ? TooltipButtonLabel.CLOSE : TooltipButtonLabel.NEXT}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
