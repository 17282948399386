import { Typography } from 'antd';
import React from 'react';
import { NotificationDrawerLabels as LABELS } from '../../components/NotificationDrawer/NotificationDrawerConstants';
import { iconSelectorLandingZone } from '../../containers/BuildDeploy/BuildDeployConstants';
import { getFormattedTimestamp } from '../../libs/helper/HelperFunctions';
import { LandingZoneStatus } from '../../libs/models/BuildDeployModels';
import { ILandingZoneNotification } from '../../libs/models/NotificationModels';
import './NotificationMobileDrawerCardStyles.less';

const getLandingZoneStatus = (landingZoneStatus: LandingZoneStatus | undefined) => {
  switch (landingZoneStatus) {
    case LandingZoneStatus.CREATED:
      return <Typography className="status-tag completed-tag">{landingZoneStatus}</Typography>;
    case LandingZoneStatus.IN_PROGRESS:
      return <Typography className="status-tag inprogress-tag">{landingZoneStatus}</Typography>;
    case LandingZoneStatus.ERROR:
      return <Typography className="status-tag error-tag">{landingZoneStatus}</Typography>;
    default:
      return <Typography
      className='status-tag inprogress-tag' >
      {landingZoneStatus}
    </Typography>;
  }
};

const NotificationsMobileDrawerCard = (notificationData : ILandingZoneNotification) => {
  return (
    <div className="notification-card boxShadow">
      <div className="flex-row-space-between">
        <div>
          {getFormattedTimestamp(notificationData.dateTime)}
        </div>
        <div className="user-table-status-column">
          {getLandingZoneStatus(notificationData.status)}
        </div>
      </div>

      <div className="flex-row">
        <img src={iconSelectorLandingZone(notificationData.cloudProvider)} alt="cloud-icon" />
        <div className="flex-column cloud-title-box">
          <Typography className='notification-row-label'>{LABELS.environment}</Typography>
          <Typography className='notification-row-value'>{notificationData.landingZoneName}</Typography>
        </div>
      </div>

      <div className="flex-row-space-between">
        <div className="flex-column border-right-dashed notice-board">
          <div className='notification-row-label'>{LABELS.account}</div>
          <div className='notification-row-value'>{notificationData.account}</div>
        </div>
        <div className="flex-column border-right-dashed notice-board">
          <div className='notification-row-label'>{LABELS.project}</div>
          <div className='notification-row-value'>{notificationData.project}</div>
        </div>
        <div className="flex-column notice-board">
          <div className='notification-row-label'>{LABELS.username}</div>
          <div className='notification-row-value'>{notificationData.username}</div>
        </div>
      </div>

    </div>
  );
};

export default NotificationsMobileDrawerCard;
