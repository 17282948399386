export enum NavMenuOptions {
  OVERVIEW = 'Deloitte Cloud Accelerator... Accelerating Multi Cloud Foundation',
  OPERATIONS = 'Operations - Jobs Ruleset',
  ASSET_AUTHORISATION = 'Asset Authorization',
  WEB_ANALYTICS = 'Web Analytics',
  DOCUMENTATION = 'Documentation',
  QUICK_LINKS = 'Quick Links',
  KEY_CONTACTS = 'Key Contacts',
  FEEDBACK = 'Feedback',
  FEEDBACK_RESPONSE = 'Admin - Feedback',
  USER_MANAGEMENT = 'User Management',
  ACCOUNT_MANAGEMENT = 'Account & Project Management',
  ASSESSMENT = 'Assessment',
  CBOSUMMARY = 'CBO Summary',
  CONFIGUREJOURNEY = 'Configure Journey',
}

export const pageSizeOptions = ['5', '10', '15', '20'];

export const devEnvironments = [
  'tangradev.deloitte.com',
  'dev.dca.deloitte.com',
  'insurance-dev.d33ofjbeahvu9w.amplifyapp.com',
  'uwai.dca.deloitte.com',
  'mnaci.dca.deloitte.com'
];
export const localhostEnvironments = ['localhost'];
export const demoEnvironments = ['demo.dca.deloitte.com'];
export const termsandConditionsUrl =
  'https://www2.deloitte.com/us/en/footerlinks1/deloitte-application-privacy-notice.html';
export enum Environment {
  DEV = 'dev',
  PROD = 'prod',
  LOCALHOST = 'localhost',
  DEMO = 'demo',
}
export enum PipelineType {
  ADD = 'add',
  EDIT = 'edit',
}

export enum CloudServiceProvider {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
  ORACLE = 'oracle',
  OPENSHIFT = 'openshift',
  MULTICLOUD = 'multicloud',
}

export const CloudServiceProviderTemplate = {
  aws: [
    {
      label: 'Terraform',
      value: 'terraform',
    },
    {
      label: 'CloudFormation',
      value: 'cloudformation',
    },
  ],
  gcp: [
    {
      label: 'Terraform',
      value: 'terraform',
    },
    {
      label: 'Deployment Manager',
      value: 'deploymentmanager',
    },
  ],
  azure: [
    {
      label: 'Terraform',
      value: 'terraform',
    },
    {
      label: 'Azure Resource Manager',
      value: 'arm',
    },
    {
      label: 'Bicep Template',
      value: 'bicep',
    },
  ],
  oracle: [
    {
      label: 'Terraform',
      value: 'terraform',
    },
    {
      label: 'Resource Manager',
      value: 'resourcemanager',
    },
  ],
  openshift: [],
  multicloud: [],
};

export const DUMMY_ACCESS_TOKEN = '1';

export const fileExtensions = {
  png: '.png',
  yaml: '.yaml',
  xlsx: '.xlsx',
};

export const mobileScreenConstant = 720;

export enum StatusType {
  active = 'Active',
  inactive = 'Inactive',
  upcoming = 'Upcoming',
}
export const cmsPortalUrls = 'https://deloitteauthqa.opencloudportal.com/';
export const cb360PortalUrl = 'https://frontend-qa.cb360foundation.ml/';
export const cboLoginRedirectionLink = 'https://corebusinessone.dca.deloitte.com/cbo-login';

export const logoutModalText =
  'Due to User Inactivity you have been logout of the system. Please login again.';
export const timeOutTime = 1000 * 60 * 25; // 25 mins
