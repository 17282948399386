import {
  IEmailNotification,
  ILandingZoneNotification,
  ILandingZoneNotificationReqPayload,
} from '../../models/NotificationModels';
import { IStoreAction } from '../StoreHelper';

export enum NotificationActionTypes {
  TOGGLE_LANDING_ZONE_NOTIFICATION_DOT = 'TOGGLE_LANDING_ZONE_NOTIFICATION_DOT',
  TOGGLE_LANDING_ZONE_NOTIFICATION = 'TOGGLE_LANDING_ZONE_NOTIFICATION',
  TOGGLE_EMAIL_NOTIFICATION_DOT = 'TOGGLE_EMAIL_NOTIFICATION_DOT',
  TOGGLE_EMAIL_NOTIFICATION = 'TOGGLE_EMAIL_NOTIFICATION',
  GET_LANDING_ZONE_NOTIFICATIONS_STARTED = 'GET_LANDING_ZONE_NOTIFICATIONS',
  GET_LANDING_ZONE_NOTIFICATIONS_SUCCESS = 'GET_LANDING_ZONE_NOTIFICATIONS_SUCCESS',
  GET_LANDING_ZONE_NOTIFICATIONS_ERROR = 'GET_LANDING_ZONE_NOTIFICATIONS_ERROR',
  GET_EMAIL_NOTIFICATIONS_STARTED = 'GET_EMAIL_NOTIFICATIONS',
  GET_EMAIL_NOTIFICATIONS_SUCCESS = 'GET_EMAIL_NOTIFICATIONS_SUCCESS',
  GET_EMAIL_NOTIFICATIONS_ERROR = 'GET_EMAIL_NOTIFICATIONS_ERROR',
}

export type NotificationActionPayload =
  | ILandingZoneNotification[]
  | ILandingZoneNotificationReqPayload
  | boolean
  | Error
  | IEmailNotification[]
  | null;

export type NotificationAction = IStoreAction<NotificationActionTypes, NotificationActionPayload>;
export class NotificationActions {
  public static toggleLandingZoneNotificationStart(data: boolean): NotificationAction {
    return {
      type: NotificationActionTypes.TOGGLE_LANDING_ZONE_NOTIFICATION,
      data,
    };
  }
  public static toggleLandingZoneNotificationDot(data: boolean): NotificationAction {
    return {
      type: NotificationActionTypes.TOGGLE_LANDING_ZONE_NOTIFICATION_DOT,
      data,
    };
  }
  public static toggleEmailNotificationStart(data: boolean): NotificationAction {
    return {
      type: NotificationActionTypes.TOGGLE_EMAIL_NOTIFICATION,
      data,
    };
  }
  public static toggleEmailNotificationBadge(data: boolean): NotificationAction {
    return {
      type: NotificationActionTypes.TOGGLE_EMAIL_NOTIFICATION_DOT,
      data,
    };
  }
  public static getLandingZoneNotificationStart(
    data: ILandingZoneNotificationReqPayload,
  ): NotificationAction {
    return {
      type: NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_STARTED,
      data,
    };
  }
  public static getLandingZoneNotificationSuccess(
    data: ILandingZoneNotification[],
  ): NotificationAction {
    return {
      type: NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_SUCCESS,
      data,
    };
  }
  public static getLandingZoneNotificationError(error: Error): NotificationAction {
    return {
      type: NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_ERROR,
      data: error,
    };
  }
  public static getEmailNotificationStart(): NotificationAction {
    return {
      type: NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_STARTED,
    };
  }
  public static getEmailNotificationSuccess(data: IEmailNotification[]): NotificationAction {
    return {
      type: NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_SUCCESS,
      data,
    };
  }
  public static getEmailNotificationError(error: Error): NotificationAction {
    return {
      type: NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_ERROR,
      data: error,
    };
  }
}
