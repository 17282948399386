import { Step } from 'react-joyride';
export const adminTourStepsOnly = ['Messages'];
export const tourSteps: Step[] = [
  {
    disableBeacon: true,
    title: 'Account & Project Selection',
    target: '.overview-header-container',
    content:
      'Critical information they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.',
  },
  {
    title: 'Messages',
    target: '.overview-message-btn',
    content:
      'Critical information they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.',
  },
  {
    title: 'Notifications',
    target: '.overview-notification-btn',
    content:
      'Critical information they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.',
  },
  {
    target: '.cloud-summary-dashboard-header',
    title: 'Cloud Journey',
    content: `Critical information they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.`,
  },
  {
    title: `Discover & Assess`,
    target: '.discover-assess-dashboard-header',
    content:
      'DCA enables discovery and assessment across cloud suitability, governance, and spend optimization. You can view the assessments like Cloud Financial Analysis, Cloud Spend Optimization, Op Model Assessment, CTVBR and Green Cloud',
  },
  {
    title: `Build Foundation`,
    target: '.build-deploy-dashboard-header',
    content:
      'DCA enables build and deploy of cloud landing zones across cloud service providers (AWS, Azure, GCP) while providing seamless integrations with several native and 3rd party services',
  },
  {
    title: `Migrate & Modernize`,
    target: '.migrate-modernize-dashboard-header',
    content:
      'DCA’s approach to modernization starts with breaking up the monolithic to enable a variety of possible target states from on-prem virtualization to hybrid cloud',
  },
  {
    title: `Operate`,
    target: '.operate-dashboard-header',
    content:
      'DCA Operate capabilities can accelerate operations of cloud applications which provide the scalability, agility, and rapid deployment capabilities that can help you realize the transformative value of the cloud',
  },
  {
    title: `Security & Governance`,
    target: '.security-governance-dashboard-header',
    content:
      'Critical information they are engaged in time-sensitive tasks. The main goals for users quickly and clearly and display their status.',
  },
];
