import React from 'react'
import CummulativeNetValue from '../../../assets/images/cum-net-value.png'
import { Col, Row } from 'antd';
import IndustryDistChart from '../../../assets/images/industry-distribution-chart.png'
import IndustryDistLegends from '../../../assets/images/industry-dist-legend.png'
import AccountRiskChart from '../../../assets/images/account-risk-chart.png'
import AccountRiskLegends from '../../../assets/images/acc-risk-legend.png'
import BusinessPipelineChart from '../../../assets/images/new-business-pipeline.png'
import "./DashboardAnalytics.less"

const DashboardAnalytics = () => {
  return (
    <Row gutter={[32,32]} className='container-border-style h-100 mx-0 dashboard-analytics'>
      <Col span={24} className='gutter-row'>
        <h2>Cumulative Net Written Values</h2>
        <img src={CummulativeNetValue} alt=""  className='w-100'/>
      </Col>
      <Col span={12} className='gutter-row'>
        <h2>Industry Distribution</h2>
        <img src={IndustryDistChart} alt="" className='w-100'/>
        <img src={IndustryDistLegends} alt="" className='w-100'/>
      </Col>
      <Col span={12} className='gutter-row'>
        <h2>Account Risk Quality</h2>
        <img src={AccountRiskChart} alt="" className='w-100'/>
        <img src={AccountRiskLegends} alt="" className='w-100'/>
      </Col>
      <Col span={24} className='gutter-row'>
        <h2>New Business Pipeline</h2>
        <img src={BusinessPipelineChart} alt="" className='w-100'/>
      </Col>
    </Row>
  )
}

export default DashboardAnalytics;
