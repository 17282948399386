import { IPipelineTemplate, IUpdatePipelineResponse } from '../../models/PipelineModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class UpdatePipelineService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public updatePipeline(
    payload: IPipelineTemplate,
    pipelineId: string,
  ): Promise<IUpdatePipelineResponse> | IUpdatePipelineResponse {
    return this.service.patch(
      { route: [`${API_ROUTE.UPDATE_PIPELINE}`] },
      { ...payload, pipelineId: pipelineId },
    );
  }
}

const updatePipelineService = new UpdatePipelineService();
export default updatePipelineService;
