import React from 'react';
import RiskAnalysis from './RiskAnalysis';
import DesicionGuidanceWidget from './DecisionGuidanceWidget';

const DashboardDetails = () => {
  return (
    <>
      <RiskAnalysis />
      <DesicionGuidanceWidget />
    </>
  );
};

export default DashboardDetails;
