import { put, takeEvery } from 'redux-saga/effects';
import TrafficAnalyticsService from '../../../services/TrafficAnalyticsService/TrafficAnalyticsService';
import {
  ICloudAnalysisResponse,
  IMostVisitedArchPayload,
  IMostVisitedArchResponse,
  IMostVisitedSectionPayload,
  IMostVisitedSectionResponse,
  IVisitedPagePayload,
  TrafficAnalyticsAction,
  TrafficAnalyticsActions,
  TrafficAnalyticsActionTypes,
} from '../../actions/WebAnalytics/TrafficAnalyticsActions';

export function* getMostVisitedChartDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IMostVisitedSectionPayload;
    const response: IMostVisitedSectionResponse[] =
      (yield TrafficAnalyticsService.getMostVisitedMenu(data))!;
    yield put(TrafficAnalyticsActions.getVisitedSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getVisitedError(error));
    }
  }
}

export function* getMostVisitedArchDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IMostVisitedArchPayload;
    const response: IMostVisitedArchResponse[] = (yield TrafficAnalyticsService.getMostVisitedArch(
      data,
    ))!;
    yield put(TrafficAnalyticsActions.getArchVisitedSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getArchVisitedError(error));
    }
  }
}

export function* getMostVisitedCloudAssessmentDataWorker(action: TrafficAnalyticsAction) {
  try {
    const response: IMostVisitedArchResponse[] =
      (yield TrafficAnalyticsService.getMostVisitedCloudAssessment())!;
    yield put(TrafficAnalyticsActions.getCloudAssessmentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getCloudAssessmentError(error));
    }
  }
}

export function* postVisitedDataWorker(action: TrafficAnalyticsAction) {
  try {
    const data = action.data as IVisitedPagePayload;
    yield TrafficAnalyticsService.postVisitedMenu(data)!;
    yield put(TrafficAnalyticsActions.postVisitedPageSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.postVisitedPageError(error));
    }
  }
}

export function* getCloudAnalyticsWorker() {
  try {
    const response: ICloudAnalysisResponse[] =
      (yield TrafficAnalyticsService.getCloudProviderAnalytics())!;
    yield put(TrafficAnalyticsActions.getCloudProviderAnalyticsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(TrafficAnalyticsActions.getCloudProviderAnalyticsError(error));
    }
  }
}

export function* trafficAnalyticsWatcher() {
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_START,
    getMostVisitedChartDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.POST_VISITED_PAGE_ACTION_START,
    postVisitedDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_STARTED,
    getCloudAnalyticsWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_START,
    getMostVisitedArchDataWorker,
  );
  yield takeEvery(
    TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_START,
    getMostVisitedCloudAssessmentDataWorker,
  );
}
