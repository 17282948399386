/* eslint-disable max-lines */
import { CloudServiceProvider } from '../../../AppConstants';
import { ICountLandingZone } from '../../../containers/HomePage/HomePageConstants';
import {
  ICheckLandingZoneStatus,
  ICheckMicroServicesPatternStatus,
  IConfigureMicroServiceError,
  IConfigureWorkLoadsPattern,
} from '../../models/DashboardModels';
import {
  IArchitectureCataloguePayload,
  IArchitectureCatalogueResponse,
  ICloudLandingZoneKeysPayload,
  ICloudLandingZoneKeysResponse,
  IConfigureDeployCloudPayload,
  IConfigureDeployCloudThroughConsolePayload,
  IConfigureLandingZonePayload,
  IConfigureLandingZoneRequestPayload,
  IConfigureLZLoadingPayload,
  ICreateYamlPayload,
  IDeleteLandingZonePayload,
  IDeleteWorkLoadPatternPayload,
  IDeploymentLogsPayload,
  IDeploymentLogsResponse,
  IDeploymentsResponse,
  IEnvironmentsDeployed,
  ILandingZoneDeploymentStatus,
  ILandingZoneState,
  IPipelinesAnalyticsResponse,
  IPipelinesCummulativeAnalyticsResponse,
  IYamlContent,
  IDeployValueState,
  ILandingZoneDeploymentResponse,
  IUpdateDeployActivityHistoryPayload,
  IDeployActivityHistory,
  IUpdateLandingZoneDeployDetails,
  IFormInterface,
  IIndustryYamlPayload,
  IGetKeysRequestBody,
} from '../state/BuildDeployState';
import { IStoreAction } from '../StoreHelper';








export enum BuildDeployActionTypes {
  ADD_LANDING_ZONE = 'ADD_LANDING_ZONE',
  GET_BUILD_DEPLOY_DETAILS_STARTED = 'GET_BUILD_DEPLOY_DETAILS_STARTED',
  GET_BUILD_DEPLOY_DETAILS_SUCCESS = 'GET_BUILD_DEPLOY_DETAILS_SUCCESS',
  GET_BUILD_DEPLOY_DETAILS_ERROR = 'GET_BUILD_DEPLOY_DETAILS_ERROR',
  ENABLE_LANDING_ZONE_STARTED = 'ENABLE_LANDING_ZONE_STARTED',
  ENABLE_LANDING_ZONE_SUCCESS = 'ENABLE_LANDING_ZONE_SUCCESS',
  ENABLE_LANDING_ZONE_ERROR = 'ENABLE_LANDING_ZONE_ERROR',
  DISABLE_LANDING_ZONE = 'DISABLE_LANDING_ZONE',
  CONFIGURE_LANDING_ZONE_STARTED = 'CONFIGURE_LANDING_ZONE_STARTED',
  CONFIGURE_LANDING_ZONE_SUCCESS = 'CONFIGURE_LANDING_ZONE_SUCCESS',
  CONFIGURE_LANDING_ZONE_LOADING = 'CONFIGURE_LANDING_ZONE_LOADING',
  CHECK_LANDING_ZONE_STATUS = 'CHECK_LANDING_ZONE_STATUS',
  CONFIGURE_LANDING_ZONE_ERROR = 'CONFIGURE_LANDING_ZONE_ERROR',
  DELETE_LANDING_ZONE_STARTED = 'DELETE_LANDING_ZONE',
  DELETE_LANDING_ZONE_SUCCESS = 'DELETE_LANDING_ZONE_SUCCESS',
  DELETE_LANDING_ZONE_ERROR = 'DELETE_LANDING_ZONE_ERROR',
  ENABLE_MICROSERVIVES_PATTERN_STARTED = 'ENABLE_MICROSERVIVES_PATTERN_STARTED',
  ENABLE_MICROSERVIVES_PATTERN_SUCCESS = 'ENABLE_MICROSERVIVES_PATTERN_SUCCESS',
  ENABLE_MICROSERVIVES_PATTERN_ERROR = 'ENABLE_MICROSERVIVES_PATTERN_ERROR',
  CONFIGURE_MICROSERVIVES_PATTERN_STARTED = 'CONFIGURE_MICROSERVIVES_PATTERN_STARTED',
  CONFIGURE_MICROSERVIVES_PATTERN_ERROR = 'CONFIGURE_MICROSERVIVES_PATTERN_ERROR',
  CONFIGURE_MICROSERVIVES_PATTERN_LOADING = 'CONFIGURE_MICROSERVIVES_PATTERN_LOADING',
  CHECK_MICROSERVIVES_PATTERN_STATUS = 'CHECK_MICROSERVIVES_PATTERN_STATUS',
  CONFIGURE_MICROSERVIVES_PATTERN_SUCCESS = 'CONFIGURE_MICROSERVIVES_PATTERN_SUCCESS',
  CONFIGURE_MICROSERVIVES_PATTERN_FAILED = 'CONFIGURE_MICROSERVIVES_PATTERN_FAILED',
  ENABLE_ETL_PATTERN_STARTED = 'ENABLE_ETL_PATTERN_STARTED',
  ENABLE_ETL_PATTERN_SUCCESS = 'ENABLE_ETL_PATTERN_SUCCESS',
  ENABLE_ETL_PATTERN_ERROR = 'ENABLE_ETL_PATTERN_ERROR',
  CONFIGURE_ETL_PATTERN_STARTED = 'CONFIGURE_ETL_PATTERN_STARTED',
  CONFIGURE_ETL_PATTERN_SUCCESS = 'CONFIGURE_ETL_PATTERN_SUCCESS',
  CONFIGURE_ETL_PATTERN_ERROR = 'CONFIGURE_ETL_PATTERN_ERROR',
  DESTROY_WORKLOAD_PATTERN_SUCCESS = 'DESTROY_WORKLOAD_PATTERN_SUCCESS',
  DESTROY_WORKLOAD_PATTERN_STARTED = 'DESTROY_WORKLOAD_PATTERN_STARTED',
  DESTROY_WORKLOAD_PATTERN_ERROR = 'DESTROY_WORKLOAD_PATTERN_ERROR',
  GET_LANDING_ZONE_ERRORS_STARTED = 'GET_LANDING_ZONE_ERRORS_STARTED',
  GET_LANDING_ZONE_ERRORS_SUCCESS = 'GET_LANDING_ZONE_ERRORS_SUCCESS',
  GET_LANDING_ZONE_ERRORS_FAILED = 'GET_LANDING_ZONE_ERRORS_FAILED',
  CONFIGURE_LANDING_ERROR_SUCCESS = 'CONFIGURE_LANDING_ERROR_SUCCESS',
  SET_TOTAL_ENVIRONMENTS = 'SET_TOTAL_ENVIRONMENTS',
  SET_CATALOGUE_TYPE = 'SET_CATALOGUE_TYPE',
  CREATE_LANDING_ZONE_YAML_START = 'CREATE_LANDING_ZONE_YAML_START',
  CREATE_LANDING_ZONE_YAML_SUCCESS = 'CREATE_LANDING_ZONE_YAML_SUCCESS',
  CREATE_LANDING_ZONE_YAML_ERROR = 'CREATE_LANDING_ZONE_YAML_ERROR',
  ENVIRONMENTS_DEPLOYED_START = 'ENVIRONMENTS_DEPLOYED_START',
  ENVIRONMENTS_DEPLOYED_SUCCESS = 'ENVIRONMENTS_DEPLOYED_SUCCESS',
  ENVIRONMENTS_DEPLOYED_ERROR = 'ENVIRONMENTS_DEPLOYED_ERROR',
  SET_SELECTED_TAB = ' SET_SELECTED_TAB',
  LANDING_ZONES_DEPLOYMENT_STATUS_START = 'LANDING_ZONES_DEPLOYMENT_STATUS_START',
  LANDING_ZONES_DEPLOYMENT_STATUS_SUCCESS = 'LANDING_ZONES_DEPLOYMENT_STATUS_SUCCESS',
  LANDING_ZONES_DEPLOYMENT_STATUS_ERROR = 'LANDING_ZONES_DEPLOYMENT_STATUS_ERROR',
  GET_ARCHITECTURE_LIST_STARTED = 'GET_ARCHITECTURE_LIST_STARTED',
  GET_ARCHITECTURE_LIST_SUCCESS = 'GET_ARCHITECTURE_LIST_SUCCESS',
  GET_ARCHITECTURE_LIST_ERROR = 'GET_ARCHITECTURE_LIST_ERROR',
  SET_ARCHTECTURE_VIEW_COUNT_STARTED = 'SET_ARCHTECTURE_VIEW_COUNT_STARTED',
  SET_ARCHTECTURE_VIEW_COUNT_SUCCESS = 'SET_ARCHTECTURE_VIEW_COUNT_SUCCESS',
  SET_ARCHTECTURE_VIEW_COUNT_ERROR = 'SET_ARCHTECTURE_VIEW_COUNT_ERROR',
  SET_ARCHTECTURE_DIAGRAM_STARTED = 'SET_ARCHTECTURE_DIAGRAM_STARTED',
  SET_ARCHTECTURE_DIAGRAM_SUCCESS = 'SET_ARCHTECTURE_DIAGRAM_SUCCESS',
  SET_ARCHTECTURE_DIAGRAM_ERROR = 'SET_ARCHTECTURE_DIAGRAM_ERROR',
  GET_PIPELINES_ANALYTICS_STARTED = 'GET_PIPELINES_ANALYTICS_STARTED',
  GET_PIPELINES_ANALYTICS_SUCCESS = 'GET_PIPELINES_ANALYTICS_SUCCESS',
  GET_PIPELINES_ANALYTICS_ERROR = 'GET_PIPELINES_ANALYTICS_ERROR',
  GET_PIPELINES_CUMMULATIVE_ANALYTICS_STARTED = 'GET_PIPELINES_CUMMULATIVE_ANALYTICS_STARTED',
  GET_PIPELINES_CUMMULATIVE_ANALYTICS_SUCCESS = 'GET_PIPELINES_CUMMULATIVE_ANALYTICS_SUCCESS',
  GET_PIPELINES_CUMMULATIVE_ANALYTICS_ERROR = 'GET_PIPELINES_CUMMULATIVE_ANALYTICS_ERROR',
  GET_YAML_CONTENT_STARTED = 'GET_YAML_CONTENT_STARTED',
  GET_YAML_CONTENT_SUCCESS = 'GET_YAML_CONTENT_SUCCESS',
  GET_YAML_CONTENT_ERROR = 'GET_YAML_CONTENT_ERROR',
  GET_INDUSTRY_YAML_CONTENT_STARTED = 'GET_INDUSTRY_YAML_CONTENT_STARTED',
  GET_INDUSTRY_YAML_CONTENT_SUCCESS = 'GET_INDUSTRY_YAML_CONTENT_SUCCESS',
  GET_INDUSTRY_YAML_CONTENT_ERROR = 'GET_INDUSTRY_YAML_CONTENT_ERROR',
  GET_CLOUD_LANDING_ZONE_KEYS_STARTED = 'GET_CLOUD_LANDING_ZONE_KEYS_STARTED',
  GET_CLOUD_LANDING_ZONE_KEYS_SUCCESS = 'GET_CLOUD_LANDING_ZONE_KEYS_SUCCESS',
  GET_CLOUD_LANDING_ZONE_KEYS_ERROR = 'GET_CLOUD_LANDING_ZONE_KEYS_ERROR',
  SET_CLOUD_LANDING_ZONE_KEYS_STARTED = 'SET_CLOUD_LANDING_ZONE_KEYS_STARTED',
  SET_CLOUD_LANDING_ZONE_KEYS_SUCCESS = 'SET_CLOUD_LANDING_ZONE_KEYS_SUCCESS',
  SET_CLOUD_LANDING_ZONE_KEYS_ERROR = 'SET_CLOUD_LANDING_ZONE_KEYS_ERROR',
  GET_DEPLOYMENT_DETAILS_STARTED = 'GET_DEPLOYMENT_DETAILS_STARTED',
  GET_DEPLOYMENT_DETAILS_SUCCESS = 'GET_DEPLOYMENT_DETAILS_SUCCESS',
  GET_DEPLOYMENT_DETAILS_ERROR = 'GET_DEPLOYMENT_DETAILS_ERROR',
  CONFIGURE_DEPLOY_CLOUD_STARTED = 'CONFIGURE_DEPLOY_CLOUD_STARTED',
  CONFIGURE_DEPLOY_CLOUD_SUCCESS = 'CONFIGURE_DEPLOY_CLOUD_SUCCESS',
  CONFIGURE_DEPLOY_CLOUD_ERROR = 'CONFIGURE_DEPLOY_CLOUD_ERROR',
  CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED = 'CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED',
  CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_SUCCESS = 'CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_SUCCESS',
  CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_ERROR = 'CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_ERROR',
  CLOUD_LANDING_ZONE_DEPLOY_STATUS_STARTED = 'CLOUD_LANDING_ZONE_DEPLOY_STATUS_STARTED',
  CLOUD_LANDING_ZONE_DEPLOY_STATUS_SUCCESS = 'CLOUD_LANDING_ZONE_DEPLOY_STATUS_SUCCESS',
  CLOUD_LANDING_ZONE_DEPLOY_STATUS_ERROR = 'CLOUD_LANDING_ZONE_DEPLOY_STATUS_ERROR',
  GET_DEPLOYMENT_AUDIT_STARTED = 'GET_DEPLOYMENT_AUDIT_STARTED',
  GET_DEPLOYMENT_AUDIT_SUCCESS = 'GET_DEPLOYMENT_AUDIT_SUCCESS',
  GET_DEPLOYMENT_AUDIT_ERROR = 'GET_DEPLOYMENT_AUDIT_ERROR',
  GET_DEPLOY_INPUTS = 'GET_DEPLOY_INPUTS',
  CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_STARTED = 'CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_STARTED',
  CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_SUCCESS = 'CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_SUCCESS',
  CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_ERROR = 'CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_ERROR',
  GET_MULTICLOUD_YAML_CONTENT_STARTED = 'GET_MULTICLOUD_YAML_CONTENT_STARTED',
  GET_MULTICLOUD_YAML_CONTENT_SUCCESS = 'GET_MULTICLOUD_YAML_CONTENT_SUCCESS',
  GET_MULTICLOUD_YAML_CONTENT_ERROR = 'GET_MULTICLOUD_YAML_CONTENT_ERROR',
  GET_DEFAULT_TEMPLATE_YAML_CONTENT_STARTED = 'GET_DEFAULT_TEMPLATE_YAML_CONTENT_STARTED',
  GET_DEFAULT_TEMPLATE_YAML_CONTENT_SUCCESS = 'GET_DEFAULT_TEMPLATE_YAML_CONTENT_SUCCESS',
  GET_DEFAULT_TEMPLATE_YAML_CONTENT_ERROR = 'GET_DEFAULT_TEMPLATE_YAML_CONTENT_ERROR',
}

export interface IConfigureLandingZoneError {
  error: Error;
  index: number;
}
export interface ILandingZoneErrorResponse {
  [error: string]: string[];
}

export type BuildDeployActionPayload =
  | boolean
  | Error
  | ILandingZoneState
  | IConfigureLandingZoneRequestPayload
  | IConfigureLandingZonePayload
  | number
  | string
  | ILandingZoneState[]
  | IConfigureLandingZoneError
  | IDeleteLandingZonePayload
  | IConfigureWorkLoadsPattern
  | ICheckLandingZoneStatus
  | IConfigureLZLoadingPayload
  | IDeleteWorkLoadPatternPayload
  | ILandingZoneErrorResponse
  | IConfigureMicroServiceError
  | ICheckMicroServicesPatternStatus
  | ICreateYamlPayload
  | IEnvironmentsDeployed[]
  | ILandingZoneDeploymentStatus[]
  | ICountLandingZone
  | ICountLandingZone
  | IArchitectureCatalogueResponse[]
  | IPipelinesAnalyticsResponse[]
  | IPipelinesCummulativeAnalyticsResponse[]
  | IArchitectureCataloguePayload
  | IYamlContent
  | ICloudLandingZoneKeysResponse[]
  | ICloudLandingZoneKeysPayload
  | IDeploymentsResponse[]
  | IConfigureDeployCloudPayload
  | IConfigureDeployCloudThroughConsolePayload
  | IDeploymentLogsPayload
  | IDeploymentLogsResponse[]
  | IDeployValueState
  | ILandingZoneDeploymentResponse
  | IUpdateDeployActivityHistoryPayload
  | IFormInterface
  | IGetKeysRequestBody
  | IIndustryYamlPayload;

export type BuildDeployAction = IStoreAction<BuildDeployActionTypes, BuildDeployActionPayload>;
export class BuildDeployActions {
  public static addLandingZone(data: ILandingZoneState): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ADD_LANDING_ZONE,
      data,
    };
  }
  public static getBuildDeployDetailStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED,
      data,
    };
  }
  public static getBuildDeployDetailsSuccess(data: ILandingZoneState[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_SUCCESS,
      data,
    };
  }
  public static getBuildDeployDetailsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_ERROR,
      data,
    };
  }
  public static enableLandingZoneStarted(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_LANDING_ZONE_STARTED,
      data,
    };
  }

  public static disableLandingZone(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DISABLE_LANDING_ZONE,
      data,
    };
  }

  public static enableLandingZoneSuccess(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_LANDING_ZONE_SUCCESS,
      data,
    };
  }
  public static enableLandingZoneError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_LANDING_ZONE_ERROR,
      data,
    };
  }
  public static enableMicroServicesPatternStarted(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_STARTED,
      data,
    };
  }
  public static enableMicroServicesPatternSuccess(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_SUCCESS,
      data,
    };
  }
  public static enableMicroServicesPatternError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_ERROR,
      data,
    };
  }
  public static enableEtlPatternStarted(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_ETL_PATTERN_STARTED,
      data,
    };
  }
  public static enableEtlPatternSuccess(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_ETL_PATTERN_SUCCESS,
      data,
    };
  }
  public static enableEtlPatterError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENABLE_ETL_PATTERN_ERROR,
      data,
    };
  }
  public static configureLandingZoneStarted(data: IConfigureLandingZonePayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_STARTED,
      data,
    };
  }
  public static configureLandingZoneSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_SUCCESS,
      data,
    };
  }
  public static configureLandingZoneLoading(data: IConfigureLZLoadingPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_LOADING,
      data,
    };
  }
  public static configureLandingZoneError(data: IConfigureLandingZoneError): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_ERROR,
      data,
    };
  }
  public static configureMicroServicesPatternStarted(
    data: IConfigureWorkLoadsPattern,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_STARTED,
      data,
    };
  }
  public static configureMicroServicesLoading(
    data: ICheckMicroServicesPatternStatus,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_LOADING,
      data,
    };
  }
  public static configureMicroServicesPatternSuccess(
    data: ICheckMicroServicesPatternStatus,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_SUCCESS,
      data,
    };
  }
  public static configureMicroServicesPatternError(
    data: IConfigureMicroServiceError,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_ERROR,
      data,
    };
  }
  public static configureMicroServicesPatternFailed(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_FAILED,
      data,
    };
  }
  public static configureEtlPatternStarted(data: IConfigureWorkLoadsPattern): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_STARTED,
      data,
    };
  }
  public static configureEtlPatternSuccess(data: number): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_SUCCESS,
      data,
    };
  }
  public static configureEtlPatternError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_ERROR,
      data,
    };
  }
  public static deleteLandingZoneStarted(data: IDeleteLandingZonePayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DELETE_LANDING_ZONE_STARTED,
      data,
    };
  }

  public static deleteLandingZoneSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DELETE_LANDING_ZONE_SUCCESS,
      data,
    };
  }
  public static deleteLandingZoneError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DELETE_LANDING_ZONE_ERROR,
      data,
    };
  }
  public static destroyWorkLoadPatternStarted(
    data: IDeleteWorkLoadPatternPayload,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_STARTED,
      data,
    };
  }
  public static destroyWorkLoadPatternSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_SUCCESS,
      data,
    };
  }
  public static destroyWorkLoadPatternError(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_ERROR,
      data,
    };
  }
  public static checkLandingZoneStatus(data: ICheckLandingZoneStatus): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CHECK_LANDING_ZONE_STATUS,
      data,
    };
  }
  public static getLandingZoneErrorStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_STARTED,
      data,
    };
  }
  public static getLandingZoneErrorSuccess(data: ILandingZoneErrorResponse): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_SUCCESS,
      data,
    };
  }
  public static getLandingZoneErrorFailed(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_FAILED,
      data,
    };
  }
  public static configureLandingZoneFailed(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_LANDING_ERROR_SUCCESS,
      data,
    };
  }
  public static checkMicroServicesPatternStatus(
    data: ICheckMicroServicesPatternStatus,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CHECK_MICROSERVIVES_PATTERN_STATUS,
      data,
    };
  }
  public static setTotalEnvironmentsCount(data: ICountLandingZone): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_TOTAL_ENVIRONMENTS,
      data,
    };
  }
  public static setSelectedTab(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_SELECTED_TAB,
      data,
    };
  }
  public static createLandingZoneYamlStarted(data: ICreateYamlPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_START,
      data,
    };
  }
  public static createLandingZoneYamlSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_SUCCESS,
      data,
    };
  }

  public static createLandingZoneYamlError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_ERROR,
      data,
    };
  }
  public static getDeployedEnvironmentsDataStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_START,
      data,
    };
  }
  public static getDeployedEnvironmentsDataSuccess(
    data: IEnvironmentsDeployed[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_SUCCESS,
      data,
    };
  }
  public static getDeployedEnvironmentsDataError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_ERROR,
      data,
    };
  }
  public static getArchitectureListStarted(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED,
    };
  }
  public static getArchitectureListSuccess(
    data: IArchitectureCatalogueResponse[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_SUCCESS,
      data,
    };
  }

  public static landingZoneDeploymentStatusStart(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_START,
      data,
    };
  }
  public static landingZoneDeploymentStatusSuccess(
    data: ILandingZoneDeploymentStatus[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_SUCCESS,
      data,
    };
  }

  public static landingZoneDeploymentStatusError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_ERROR,
      data,
    };
  }
  public static getArchitectureListError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_ARCHITECTURE_LIST_ERROR,
      data,
    };
  }
  public static onArchitectureViewCountStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_VIEW_COUNT_STARTED,
      data,
    };
  }
  public static onArchitectureViewCountSuccess(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_VIEW_COUNT_SUCCESS,
    };
  }
  public static onArchitectureViewCountError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_VIEW_COUNT_ERROR,
      data,
    };
  }
  public static setArchitectureDiagramStarted(
    data: IArchitectureCataloguePayload,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_STARTED,
      data,
    };
  }
  public static setArchitectureDiagramSuccess(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_SUCCESS,
    };
  }

  public static setArchitectureDiagramError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_ERROR,
      data,
    };
  }
  public static getPipelinesAnalyticsStarted(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_STARTED,
    };
  }
  public static getPipelinesAnalyticsSuccess(
    data: IPipelinesAnalyticsResponse[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_SUCCESS,
      data,
    };
  }

  public static getPipelinesAnalyticsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_ERROR,
      data,
    };
  }
  public static getPipelinesCummulativeAnalyticsStarted(): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_STARTED,
    };
  }
  public static getPipelinesCummulativeAnalyticsSuccess(
    data: IPipelinesCummulativeAnalyticsResponse[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_SUCCESS,
      data,
    };
  }

  public static getPipelinesCummulativeAnalyticsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_ERROR,
      data,
    };
  }
  public static getCloudLandingZoneKeysStarted(
    data: IGetKeysRequestBody,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_STARTED,
      data,
    };
  }
  public static getCloudLandingZoneKeysSuccess(
    data: ICloudLandingZoneKeysResponse[],
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_SUCCESS,
      data,
    };
  }

  public static getCloudLandingZoneKeysError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_ERROR,
      data,
    };
  }
  public static setCloudLandingZoneKeysStarted(
    data: ICloudLandingZoneKeysPayload,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_STARTED,
      data,
    };
  }
  public static setCloudLandingZoneKeysSuccess(data: ILandingZoneState): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_SUCCESS,
      data,
    };
  }

  public static setCloudLandingZoneKeysError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_ERROR,
      data,
    };
  }
  public static getYamlContentStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_YAML_CONTENT_STARTED,
      data,
    };
  }
  public static getYamlContentSuccess(data: IYamlContent): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_YAML_CONTENT_SUCCESS,
      data,
    };
  }

  public static getYamlContentError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_YAML_CONTENT_ERROR,
      data,
    };
  }
  public static getDefaultTemplateYamlContentStarted(data: ICloudLandingZoneKeysPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_STARTED,
      data,
    };
  }
  public static getDefaultTemplateYamlContentSuccess(data: any): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_SUCCESS,
      data,
    };
  }

  public static getDefaultTemplateYamlContentError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_ERROR,
      data,
    };
  }
  public static getIndustryYamlContentStarted(data: IIndustryYamlPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_STARTED,
      data,
    };
  }
  public static getIndustryYamlContentSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_SUCCESS,
      data,
    };
  }

  public static getIndustryYamlContentError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_ERROR,
      data,
    };
  }

  public static getDeploymentDetailStarted(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED,
      data,
    };
  }
  public static getDeploymentDetailsSuccess(data: IDeploymentsResponse[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_SUCCESS,
      data,
    };
  }
  public static getDeploymentDetailsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_ERROR,
      data,
    };
  }
  public static cloudLandingZoneDeployStatusStarted(data: string): BuildDeployAction {
    //Takes ID as argument
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_STARTED,
      data,
    };
  }
  public static cloudLandingZoneDeployStatusSuccess(
    data: ILandingZoneDeploymentResponse,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_SUCCESS,
      data,
    };
  }

  public static cloudLandingZoneDeployStatusError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_ERROR,
      data,
    };
  }
  public static configureDeployCloudStarted(data: IConfigureDeployCloudPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_STARTED,
      data,
    };
  }
  public static configureDeployCloudSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_SUCCESS,
      data,
    };
  }
  public static configureDeployCloudError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_ERROR,
      data,
    };
  }

  public static configureDeployCloudThroughConsoleStarted(data: IConfigureDeployCloudThroughConsolePayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED,
      data,
    }
  }
  public static configureDeployCloudThroughConsoleSuccess(data: string): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_SUCCESS,
      data,
    };
  }
  public static configureDeployCloudThroughConsoleError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED,
      data,
    };
  }

  public static getDeploymentAuditStarted(data: IDeploymentLogsPayload): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_STARTED,
      data,
    };
  }
  public static getDeploymentAuditSuccess(data: IDeploymentLogsResponse[]): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_SUCCESS,
      data,
    };
  }
  public static getDeploymentAuditError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_ERROR,
      data,
    };
  }

  public static getDeployInputs(data: IDeployValueState): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_DEPLOY_INPUTS,
      data,
    };
  }

  public static cloudLandingZoneDeployDetailedLogsStarted(
    data: IUpdateLandingZoneDeployDetails,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_STARTED,
      data,
    };
  }
  public static cloudLandingZoneDeployDetailedLogsSuccess(
    data: IDeployActivityHistory,
  ): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_SUCCESS,
      data,
    };
  }
  public static cloudLandingZoneDeployDetailedLogsError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_ERROR,
      data,
    };
  }
  public static getMultiCloudYamlContentStarted(data: IFormInterface): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED,
      data,
    };
  }
  public static getMultiCloudYamlContentSuccess(data: any): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_SUCCESS,
      data,
    };
  }

  public static getMultiCloudYamlContentError(data: Error): BuildDeployAction {
    return {
      type: BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_ERROR,
      data,
    };
  }
}
