import { IUserResponse } from '../../models/UserModels';
import { IStoreAction } from '../StoreHelper';

export enum UserActionTypes {
  USER_REQUEST = 'USER_REQUEST',
  USER_SUCCESS = 'USER_SUCCESS',
  USER_ERROR = 'USER_ERROR',
}

export type UserActionPayload = string | IUserResponse | Error | null;

export type UserAction = IStoreAction<UserActionTypes, UserActionPayload>;

export class UserActions {
  public static getUserStart(): UserAction {
    return {
      type: UserActionTypes.USER_REQUEST,
    };
  }
  public static getUserSuccess(data: IUserResponse): UserAction {
    return {
      type: UserActionTypes.USER_SUCCESS,
      data,
    };
  }
  public static getUserError(error: Error): UserAction {
    return {
      type: UserActionTypes.USER_ERROR,
      data: error,
    };
  }
}
