import { IDataAugmentationPayload, IStageResponse } from '../../models/PersonaModels';
import { IUserResponse } from '../../models/UserModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class PersonaService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getStageDetails(submissionId: string): Promise<IStageResponse[]> | IStageResponse[] {
    return this.service.get({ route: [`${API_ROUTE.GET_STAGE_DETAILS}${submissionId}`] });
  }
  public getAugmentationData({
    submissionID,
    eventID,
    formType,
  }: IDataAugmentationPayload): Promise<IStageResponse[]> | IStageResponse[] {
    return this.service.get({
      route: [
        `${API_ROUTE.GET_AUGMENTATION_DATA}${submissionID}/documents/${eventID}?formType=${formType}`,
      ],
    });
  }
}

const personaService = new PersonaService();
export default personaService;
