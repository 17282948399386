import React, { useState } from 'react';
import { mobileScreenConstant } from '../AppConstants';

//Custom Hook to detect a mobile device
export default function useDeviceDetect() {
  // 720 is most mobile and Tablet device Thershold.

  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= mobileScreenConstant ? true : false,
  );

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= mobileScreenConstant) setIsMobile(true);
    else setIsMobile(false);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { isMobile };
}
