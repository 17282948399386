import { PipelineLabels } from '../BuildPipeline/CreatePipelineConstants';

export const DeliveryPipelineLabels = {
  heading: 'Delivery Pipelines',
  listLandingZoneLabel: 'List of Cloud Environment',
  pipelineTitle: 'Pipeline Name',
  createdByTitle: 'Created By',
  modifiedTitle: 'Modified',
  lastExecutedTitle: 'Last Executed',
  statusTitle: 'Status',
  actionsLabel: 'Actions',
  createPipelineButton: 'Create Pipeline',
  uploadYamlButton: 'Upload YAML File',
  success: 'SUCCESS!!',
  runPipelineSuccessMsg: ' Successfully Started!',
  loaderName: 'Getting Pipelines!!',
  landingZonesEmptyText: 'No Cloud Environments Available',
};

export const initialEditState = {
  pipelineId: '',
  pipelineName: '',
  template: {
    templateName: PipelineLabels.pipelineTemplates[0],
    attributes: {
      srcCodeURL: '',
      imageName: '',
      imageVersion: '',
      envSrcCodeURL: '',
      environmentName: '',
      imageRepoURL: '',
      imageVersionURL: '',
      blueVersion: '',
      greenVersion: '',
    },
  },
  status: '',
  modified: '',
  createdBy: '',
  lastExecuted: '',
};
