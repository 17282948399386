import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import {
  IEditJobRequestParams,
  IJobRuleSetState,
  IRulesetHistory,
  IRuleSetQueryParameter,
  IRunJobRequestResponse,
  IUploadYamlParams,
} from '../../models/OperationsModels';
import OperationsService from '../../services/OperationsService/OperationsService';
import {
  OperationsAction,
  OperationsActions,
  OperationsActionTypes,
} from '../actions/OperationsActions';
import { ToastActions } from '../actions/ToastActions';

export function* getJobRuleSetWorker(action: OperationsAction) {
  try {
    const { data } = action;
    const { landingZoneId, projectId } = data as IRuleSetQueryParameter;
    const response: IJobRuleSetState[] = yield OperationsService.getJobRuleSet(
      projectId,
      landingZoneId,
    );
    yield put(OperationsActions.getJobRuleSetSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(OperationsActions.getJobRuleSetError(error));
    }
  }
}

export function* editJobRuleSetWorker(action: OperationsAction) {
  try {
    const data = action.data as IEditJobRequestParams;
    const response: IJobRuleSetState[] = yield OperationsService.editJobRuleSet(data);
    yield put(OperationsActions.editJobRuleSetSuccess(data));
    yield put(
      ToastActions.showToast({
        description: 'Job Details Saved',
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(OperationsActions.editJobRuleSetError(error));
      yield put(
        ToastActions.showToast({
          description: 'Job Run Failed',
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* runJobRuleSetWorker(action: OperationsAction) {
  try {
    const data = action.data as IEditJobRequestParams;
    const response: IRunJobRequestResponse = yield OperationsService.runJobRuleSet(data);
    yield put(
      OperationsActions.runJobRuleSetSuccess({
        ruleSetId: data.ruleSetId,
        parameters: data.parameters,
        lastExecuted: response.lastExecuted,
      }),
    );
    yield put(
      ToastActions.showToast({
        description: 'Job Run Started',
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(OperationsActions.runJobRuleSetError(error));
      yield put(
        ToastActions.showToast({
          description: 'Job Run Failed',
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* getRulesetHistoryWorker(action: OperationsAction) {
  try {
    const data = action.data as string;
    const response: IRulesetHistory[] = yield OperationsService.getRulesetHistory(data);
    yield put(OperationsActions.getRulesetHistorySuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(OperationsActions.getRulesetHistoryError(error));
    }
  }
}

export function* uploadRuleSetYamlWorker(action: OperationsAction) {
  try {
    const data = action.data as IUploadYamlParams;
    const response: IJobRuleSetState = yield OperationsService.uploadCreateYaml(data);
    yield put(OperationsActions.uploadYamlSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(OperationsActions.uploadYamlError(error));
    }
  }
}

export function* operationsWatcher() {
  yield takeEvery(OperationsActionTypes.GET_RULESET_HISTORY_START, getRulesetHistoryWorker);
  yield takeEvery(OperationsActionTypes.GET_JOB_RULESET_START, getJobRuleSetWorker);
  yield takeEvery(OperationsActionTypes.EDIT_JOB_RULESET_START, editJobRuleSetWorker);
  yield takeEvery(OperationsActionTypes.RUN_JOB_RULESET_START, runJobRuleSetWorker);
  yield takeEvery(OperationsActionTypes.UPLOAD_CREATE_RULESET_YAML_START, uploadRuleSetYamlWorker);
}
