import { Button, Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import SubmissionInsightsImg from '../../../assets/icons/submission-insights.svg';
import TotalQuotes from '../../../assets/icons/total-quotes.svg';
import PendingQuotes from '../../../assets/icons/pending-quotes.svg';
import InProgress from '../../../assets/icons/in-progress.svg';
import Target from '../../../assets/icons/target.svg';
import AverageTimeSpent from '../../../assets/images/avg-time-spent.png';
import BILegend from '../../../assets/images/bi-legend.png';
import BusinessInsuranceGraph from '../../../assets/images/bi-graph.png';
import Rising from '../../../assets/icons/document-upload.svg';
import RightAero from '../../../assets/icons/right-aero.svg';
import CommercialIcon from '../../../assets/icons/cloud-summary-icon.svg';
import RightFilledAero from '../../../assets/icons/right-filled-aero.svg';
import Error from '../../../assets/icons/attention.svg';

const SubmissionInsights = () => {
  const history = useHistory();
  const progressData = [
    { header: 'Total Submissions', value: '500', src: TotalQuotes, bgColor: 'total-quotes-bg' },
    { header: 'Submission Processed', value: '29', src: Target, bgColor: 'target-bg' },
    {
      header: 'Pending for Human Review',
      value: '120',
      src: PendingQuotes,
      bgColor: 'in-progress-bg',
    },
    { header: 'Total Errors', value: '400', src: Error, bgColor: 'pending-quotes-bg' },
  ];
  const cardData = [
    { h1: 'Rising construction / labor costs', h2: 'increase commercial property losses​' },
    { h1: 'Chip shortages / supply chain issues', h2: 'Increase commercial auto losses' },
  ];
  const renderCard = () =>
    cardData.map((card) => {
      return (
        <Col span={12} className="gutter-row" key={card.h1}>
          <div className="warning-radar-card">
            <header>Current Trend / Potential Impact</header>
            <div className="body-top flex-row-space-between">
              <div className="flex-center">
                <img src={Rising} alt="" className="category-icon" />
                <p className="mb-0">{card.h1}</p>
              </div>
              <div className="flex-center">
                <img src={RightFilledAero} alt="" className="mx-1" />
              </div>
              <div className="flex-center">
                <img src={CommercialIcon} alt="" className="category-icon" />
                <p className="mb-0">{card.h2}</p>
              </div>
            </div>
            <div className="body-bottom">
              <div className="flex-row-space-between">
                <p>Review Pricing</p>
                <img src={RightAero} alt="" />
              </div>
              <div className="flex-row-space-between">
                <p>Update coverage guidance</p>
                <img src={RightAero} alt="" />
              </div>
            </div>
          </div>
        </Col>
      );
    });

  return (
    <div>
      <div className="border-style mb-1">
        <Row className="mb-1-dot-5">
          <Col span={24} className="analytics-heading-container">
            <div className="heading-img">
              <img src={SubmissionInsightsImg} alt="submission insights" className="mr-1" />
              <h2 className="heading">Submission Insights</h2>
            </div>
            <Button
              type="text"
              className="view-btn"
              onClick={() => history.push('/dashboard/submissions')}
            >
              View Submissions
            </Button>
          </Col>
        </Row>

        <div className="progress-cards-container">
          {progressData.map((eachData) => (
            <div key={eachData.header}>
              <div className="progress-card">
                <div className={`progress-icon flex-center ${eachData.bgColor} mr-1`}>
                  <img src={eachData.src} alt="" />
                </div>
                <div>
                  <h3 className="prog-card-heading">{eachData.header}</h3>
                  <span className="card-value">{eachData.value}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Row className="border-style mb-1">
        <Col span={24} className="business-ins-performance">
          <div className="flex-row-space-between align-item-center">
            <h2 className='heading'>Business Insurance Performance</h2>
            <img src={BILegend} alt="" className="bi-legends" />
          </div>
          <img src={BusinessInsuranceGraph} alt="avg time spent" className="w-100" />
        </Col>
      </Row>

      <Row className="geo-market-dist-container" gutter={[16, 16]}>
        <Col className="header gutter-row" span={24}>
          {/* <img src={MarketDistIcon} alt="geographic market icon" className="mr-1" /> */}
          <h2 className='heading'>Early Warning Radar</h2>
        </Col>

        {renderCard()}
      </Row>
    </div>
  );
};

export default SubmissionInsights;
