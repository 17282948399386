import {
  IAppAttributeCountPayload,
  IAppInscopePayload,
  IAppInscopeResponse,
  IAppScopeAdjustmentPayload,
  IAppScopeAdjustmentResponse,
  ICombineAttributeCountResponse,
  IHeatmapPayload,
  IHeatmapResponse,
} from '../../models/CloudSuitabilityModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.CSP;

export class CloudSuitabilityService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.cwb);

  public getHeatmapData(payload: IHeatmapPayload): Promise<IHeatmapResponse> | IHeatmapResponse {
    return this.service.post({ route: [API_ROUTE.CWB.GET_HEATMAP_DATA] }, payload);
  }

  public getAppScopeAdjustment(
    payload: IAppScopeAdjustmentPayload,
  ): IAppScopeAdjustmentResponse | Promise<IAppScopeAdjustmentResponse> {
    return this.service.post({ route: [API_ROUTE.CWB.APP_SCOPE_ADJUSTMENT_DATA] }, payload);
  }
  public getAppInscope(
    payload: IAppInscopePayload,
  ): IAppInscopeResponse | Promise<IAppInscopeResponse> {
    return this.service.post({ route: [API_ROUTE.CWB.APP_INSCOPE_DATA] }, payload);
  }
  public getAppAdjustmentCount(
    payload: IAppAttributeCountPayload,
  ): ICombineAttributeCountResponse | Promise<ICombineAttributeCountResponse> {
    return this.service.post({ route: [API_ROUTE.CWB.APP_ADJUSTMENT_COUNT] }, payload);
  }
}

const cloudSuitabilityService = new CloudSuitabilityService();
export default cloudSuitabilityService;
