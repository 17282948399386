import { Button, Drawer, Skeleton, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '../../assets/icons/close-icon.svg';
import { IEmailNotification } from '../../libs/models/NotificationModels';
import { NotificationActions } from '../../libs/store/actions/NotificationActions';
import { useAppSelector } from '../../libs/store/reducers';
import EmailNotificationCard from './EmailNotificationCard';
import './EmailNotificationDrawerStyles.less';

interface IEmailNotificationDrawerProps {
  isMobile?: boolean;
}

const EmailNotificationDrawer = ({ isMobile = false }: IEmailNotificationDrawerProps) => {
  const dispatch = useDispatch();

  const { loading, emailNotifications } = useAppSelector(
    ({ notification: { emailNotifications, loading } }) => ({
      emailNotifications,
      loading,
    }),
  );

  const dispatcher = {
    getEmailNotifications: () => dispatch(NotificationActions.getEmailNotificationStart()),
    showEmailNotificationsDrawer: (data: boolean) =>
      dispatch(NotificationActions.toggleEmailNotificationStart(data)),
    toggleNotificationDot: (data: boolean) =>
      dispatch(NotificationActions.toggleEmailNotificationBadge(data)),
  };

  useEffect(() => {
    dispatcher.toggleNotificationDot(false);
    dispatcher.getEmailNotifications();
  }, []);

  return (
    <>
      <Drawer
        className="email-notification-drawer"
        title={`Email Notifications (${emailNotifications?.length})`}
        placement={!isMobile ? 'left' : 'right'}
        width={!isMobile ? '100vw' : '30rem'}
        visible={true}
        extra={
          <Space>
            <Button onClick={() => dispatcher.showEmailNotificationsDrawer(false)} type="text">
              <img src={CloseIcon} alt="close-icon" className="notification-drawer__close__btn" />
            </Button>
          </Space>
        }
      >
        {!loading ? (
          emailNotifications?.map((item: IEmailNotification, index) => (
            <EmailNotificationCard notification={item} key={index} />
          ))
        ) : (
          <Skeleton active className="skeleton-container" />
        )}
      </Drawer>
    </>
  );
};

export default EmailNotificationDrawer;
