import {
  ICompliancePolicies,
  IIndustryCompliancePolicies,
} from '../../models/SecurityGovernanceModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class SecurityGovernanceService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getComplianceDetails(
    landingZoneID: string,
  ): Promise<ICompliancePolicies> | ICompliancePolicies {
    const route: string[] = [`${API_ROUTE.GET_COMPLIANCE_DETAILS}/${landingZoneID}`];
    return this.service.get({ route: route });
  }

  public getIndustryComplianceDetails(
    landingZoneID: string,
  ): Promise<IIndustryCompliancePolicies> | IIndustryCompliancePolicies {
    const route: string[] = [`${API_ROUTE.INDUSTRY_COMPLIANCE_DETAILS}`];
    return this.service.get({ route: route, query: { landingzone_id: landingZoneID } });
  }

  public getComplianceSummary(
    projectId: string,
  ): Promise<ICompliancePolicies> | ICompliancePolicies {
    const route: string[] = [`${API_ROUTE.SUMMARY.GET_COMPLIANCE_SUMMARY_DETAILS}`];
    return this.service.get({ route: route, query: { cumulative: true, projectId } });
  }
}

const securityGovernanceService = new SecurityGovernanceService();
export default securityGovernanceService;
