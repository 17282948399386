
import {
    IUploadThemePayload,
    IUploadThemeResponse
  } from '../../models/ThemeModel';
import { IStoreAction } from '../StoreHelper';



export enum ThemeActionTypes {
    POST_THEME_DATA_STARTED = 'POST_THEME_DATA_STARTED',
    POST_THEME_DATA_SUCCESS = 'POST_THEME_DATA_SUCCESS',
    POST_THEME_DATA_ERROR = 'POST_THEME_DATA_ERROR',
}


export type ThemeActionPayload =
  | IUploadThemePayload
  | IUploadThemeResponse
  | Error
  | string
  | null;

export type ThemeAction = IStoreAction<ThemeActionTypes, ThemeActionPayload>;

export class ThemeActions {
    
    public static themeStart(data: IUploadThemePayload): ThemeAction {
        console.log("reached here theme Start",data);
      return {
        type: ThemeActionTypes.POST_THEME_DATA_STARTED,
        data,
      };
    }
    public static themeSuccess(data: IUploadThemeResponse): ThemeAction {
      return {
        type: ThemeActionTypes.POST_THEME_DATA_SUCCESS,
        data,
      };
    }
    public static themeError(error: Error): ThemeAction {
      return {
        type: ThemeActionTypes.POST_THEME_DATA_ERROR,
        data: error,
      };
    }
}
