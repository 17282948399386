import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import {
  IUploadThemePayload,
  IUploadThemeResponse
} from '../../models/ThemeModel';
import ThemeService from '../../services/theme/ThemeService';
import {
  ThemeAction,
  ThemeActions,
  ThemeActionTypes,
} from '../actions/ThemeSettingsActions';
import { ToastActions } from '../actions/ToastActions';

export function* postThemeUploadWorker(action: ThemeAction) {
  try {
    const data = action.data as IUploadThemePayload;
    const response: IUploadThemeResponse = (yield ThemeService.postThemeData(data))!;
    yield put(ThemeActions.themeSuccess(response));
    yield put(
      ToastActions.showToast({
        description: `Theme settings updated successfully`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(ThemeActions.themeError(error));
    }
  }
}


export function* themeWatcher() {
  yield takeEvery(ThemeActionTypes.POST_THEME_DATA_STARTED, postThemeUploadWorker);
}
