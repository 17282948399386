import React from 'react';
import './FormSubmission.less';
import { Button, Col, notification, Row, Table, Timeline, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
// import { RcFile } from 'antd/lib/upload';
import { RcFile } from 'antd/lib/upload/interface';
import { useDispatch } from 'react-redux';
import AttachmentClip from '../../../assets/icons/atachment-clip.svg';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { IShowToastPayload } from '../../../libs/models/ToastModels';
import { ToastActions } from '../../../libs/store/actions/ToastActions';
import GreenTick from '../../../assets/icons/green-tick.svg';
import RedCross from '../../../assets/icons/cross-red.svg';

interface IFormSubProp {
  setActiveItem: (item: string) => void;
}
const FormSubmission = ({ setActiveItem }: IFormSubProp) => {
  const dispatch = useDispatch();
  const dispatcher = {
    showToast: (data: IShowToastPayload) => dispatch(ToastActions.showToast(data)),
  };
  const showToast = (data: IShowToastPayload) => dispatcher.showToast(data);
  const timelineItems = [
    { title: 'Open your inbox ', pillStyle: 'email-pill-bg' },
    { title: 'Select & save your emails on your desktop', pillStyle: 'classification-pill-bg' },
    { title: 'Drag & drop to the “SubHub/ Form Submission” ', pillStyle: 'extraction-pill-bg' },
  ];
  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return (
          <p className="mb-0">
            {status}{' '}
            {status === 'Successfully Uploaded' ? (
              <img src={GreenTick} alt="" className="status-icon" />
            ) : (
              <img src={RedCross} alt="" className="status-icon" />
            )}
          </p>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (dateTime: string) => {
        return <p className="mb-0 remove-file">Remove File</p>;
      },
    },
  ];

  const openNotification = (fileName: string) => {
    notification.open({
      message: <b>Success</b>,
      description: (
        <p>
          <b>{fileName}</b> was uploaded successfully
        </p>
      ),
      icon: <img src={GreenTick} alt="" />,
      onClick: () => {
        // TBD:
      },
    });
  };
  const data = [
    { file_name: 'test_submission1.msg', status: 'Successfully Uploaded' },
    { file_name: 'Test Submission 3.eml', status: 'Failed to Upload' },
    { file_name: 'Test Submission 4.msg', status: 'Successfully Uploaded' },
    { file_name: 'Test Submission 6.msg', status: 'Successfully Uploaded' },
    { file_name: 'Test_submission 7.eml', status: 'Failed to Upload' },
  ];

  return (
    <>
      <div className="flex-row-space-between mb-0-dot-5">
        <h3 className="mb-0 align-item-center">
          <b>Upload Email</b>
        </h3>
        <Button type="text" className="submit-btn" onClick={() => setActiveItem('Data Ingestion')}>
          Submit
        </Button>
      </div>
      <Row gutter={[16, 16]} className="upload-row form-table-info-row">
        <Col className="upload-col" span={24}>
          <div className='bg-white'>
            <Upload.Dragger
              name="file"
              multiple={true}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file: RcFile) => {
                // openNotification("file")
                return false;
              }}
              // onDrop={(e) => {
              //   // openNotification("file")
              // }}
              onChange={(e) => {
                openNotification(e.fileList?.[e.fileList.length - 1].name);
              }}
            >
              <p className="ant-upload-drag-icon">
                <img src={AttachmentClip} alt="" />
              </p>
              <p className="ant-upload-text">
                Drag and drop, or <span className="browse-text">Browse</span> your emails
              </p>
            </Upload.Dragger>
          </div>
        </Col>

        <Col span={18}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Col>
        <Col span={6} className="form-submission-info">
          <Timeline>
            {timelineItems.map((item, idx) => (
              <Timeline.Item
                key={item.title}
                dot={<span className={`timeline-pill `}>{idx + 1}</span>}
              >
                <Row>
                  <Col span={24}>
                    <p className="timeline-item-title">
                      <b>{item.title} </b>
                    </p>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
      {false && (
        <Row gutter={[16, 16]} className="form-table-info-row">
          <Col span={18}>
            <Table columns={columns} />
          </Col>
          <Col span={6} className="form-submission-info">
            <Timeline>
              {timelineItems.map((item, idx) => (
                <Timeline.Item
                  key={item.title}
                  dot={<span className={`timeline-pill `}>{idx + 1}</span>}
                >
                  <Row>
                    <Col span={24}>
                      <p className="timeline-item-title">
                        <b>{item.title} </b>
                      </p>
                    </Col>
                  </Row>
                </Timeline.Item>
              ))}
            </Timeline>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormSubmission;
