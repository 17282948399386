import {
    IUploadThemePayload,
    IUploadThemeResponse
  } from '../../models/ThemeModel';
  import { API_ROUTE } from '../ApiRoute';
  import ApiService from '../ApiService';
  import ApiServiceBase from '../ApiServiceBase';
  import { ServiceType, ServerType } from '../ServiceType.data';
  
  const serviceType = ServiceType.None;
  
  export class ThemeService {
    private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);
    
  
    public postThemeData(
      payload: IUploadThemePayload,
    ): Promise<IUploadThemeResponse> | IUploadThemeResponse {
        console.log("theme Service",payload);
      return this.service.post({ route: [API_ROUTE.POST_CLIENT_THEME] }, payload);
    }

  }
  
  const themeService = new ThemeService();
  export default themeService;
