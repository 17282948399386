import { API_ROUTE } from './ApiRoute';
import ApiService from './ApiService';
import ApiServiceBase from './ApiServiceBase';
import { ServerType, ServiceType } from './ServiceType.data';

const serviceType = ServiceType.None;

export class ChatbotService {
  // private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.MNACIBE);

  public sendQueryUser(payload: string): Promise<string> | string {
    return this.service.post(
      { route: [`${API_ROUTE.SEND_QUERY}`] },
      { query_string : payload, query_type:"chat"},
    );
  }
}

const chatbotService = new ChatbotService();
export default chatbotService;
