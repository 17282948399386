import { IUploadAssessmentState } from '../../models/DevopsAnalyticsModels';
import {
  IAssessmentQuestionsResponse,
  IAssessmentResultPayload,
  IDevopsDataResponse,
  IGovAnalysisDataResponse,
  IGovDataType,
} from '../../store/state/DevOpsAnalyticsState';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.ANALYTICS;

export class DevOpsAnalyticsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);
  private readonly service2: ApiServiceBase = new ApiService(ServiceType.None, ServerType.tangra);

  public getCapabilityData(data: string): Promise<IDevopsDataResponse> | IDevopsDataResponse {
    const route: string[] = [`${API_ROUTE.DEV_OPS_ANALYTICS.GET_CAPABLILTIY_DATA}/${data}`];
    return this.service.get({
      route: route,
    });
  }

  public getGovernanceData(
    data: string,
  ): Promise<IGovAnalysisDataResponse> | IGovAnalysisDataResponse {
    const route: string[] = [`${API_ROUTE.DEV_OPS_ANALYTICS.GET_GOVERNANCE_DATA}/${data}`];
    return this.service.get({
      route: route,
    });
  }

  public getBuildDeployAssessmentData(data: {
    assessmentType: string;
    projectId: string;
  }): Promise<IGovDataType | IDevopsDataResponse> | IGovDataType | IDevopsDataResponse {
    const route: string[] = [`${API_ROUTE.GET_QUESTIONNAIRE.GET_CHART_DATA}`];
    return this.service2.get({
      route: route,
      query: data,
    });
  }

  public postGovernanceData(
    data: IUploadAssessmentState,
  ): Promise<IGovAnalysisDataResponse> | IGovAnalysisDataResponse {
    const route: string[] = [`${API_ROUTE.DEV_OPS_ANALYTICS.GET_ASSESSMENT_UPLOAD_DATA}`];
    return this.service2.post(
      {
        route: route,
      },
      data,
    );
  }
  public getAssessmentQuestionsService(
    data: string,
  ): Promise<IAssessmentQuestionsResponse[]> | IAssessmentQuestionsResponse[] {
    const route: string[] = [`${API_ROUTE.QUESTIONNAIRE}`];
    return this.service2.get({
      route: route,
      query: { assessmentType: data },
    });
  }
  public setAssessmentResultService(data: IAssessmentResultPayload): Promise<string> | string {
    const route: string[] = [`${API_ROUTE.QUESTIONNAIRE}`];
    return this.service2.post(
      {
        route: route,
      },
      data,
    );
  }
}

const devOpsAnalyticsService = new DevOpsAnalyticsService();
export default devOpsAnalyticsService;
