import { Reducer } from 'redux';
import {
  DevOpsAnalyticsAction,
  DevOpsAnalyticsActionTypes,
} from '../../actions/WebAnalytics/DevopsAnalytics';
import IDevOpsAnalytics, {
  IAssessmentQuestionsResponse,
  ICloudHealthDataResponse,
  IDevopsDataResponse,
  IGovAnalysisDataResponse,
  IGovDataType,
} from '../../state/DevOpsAnalyticsState';

export const initialState: IDevOpsAnalytics = {
  error: '',
  loading: false,
  toggleAssessmentUpload: false,
  governanceAnalysis: {
    financialGovernance: {
      barChart: [],
      spiderChart: [],
    },
    operationalGovernance: {
      barChart: [],
      spiderChart: [],
    },
    strategicGovernance: {
      barChart: [],
      spiderChart: [],
    },
  },
  cloudHealthAnalysis: {
    architectedFramework: {
      barChart: [],
      spiderChart: [],
    },
    additionalFramework: {
      barChart: [],
      spiderChart: [],
    },
    toolingAutomation: {
      barChart: [],
      spiderChart: [],
    },
  },
  governanceAssessmentAnalysis: {
    barChart: [],
    spiderChart: [],
  },
  devOpAnalysisData: {
    capabilityAnalysis: [],
    prioritizationMatrix: [],
  },
  devOpAssessmentAnalysisData: {
    capabilityAnalysis: [],
    prioritizationMatrix: [],
  },
  assessmentQuestions: [] as IAssessmentQuestionsResponse[],
};

export const DevOpsAnalyticsReducer: Reducer<IDevOpsAnalytics> = (
  state = initialState,
  action: DevOpsAnalyticsAction,
) => {
  switch (action.type) {
    case DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        devOpAnalysisData: action.data as IDevopsDataResponse,
      };
    case DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        assessmentQuestions: action.data as IAssessmentQuestionsResponse[],
      };
    case DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        governanceAnalysis: action.data as IGovAnalysisDataResponse,
      };
    case DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        cloudHealthAnalysis: action.data as ICloudHealthDataResponse,
      };
    case DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        governanceAssessmentAnalysis: action.data as IGovDataType,
      };
    case DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        toggleAssessmentUpload: !state.toggleAssessmentUpload,
      };
    case DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_START:
      return {
        ...state,
        loading: true,
      };
    case DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_SUCCESS:
      return {
        ...state,
        loading: false,
        devOpAssessmentAnalysisData: action.data as IDevopsDataResponse,
      };
    case DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
