import React from 'react';
import JoyRide, { Step } from 'react-joyride';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { joyRideGlobalStyles } from './ProductTourConstants';
import Tooltip from './Tooltip';
import { adminTourStepsOnly, tourSteps } from './TourSteps';

interface IProductTourProps {
  startTour: boolean;
}

const ProductTour = ({ startTour }: IProductTourProps) => {
  const { userDetails } = useAppSelector(({ user: { userDetails } }: IApplicationState) => ({
    userDetails,
  }));
  let userTourSteps: Step[];
  if (!userDetails?.user_permissions.add_account) {
    userTourSteps = tourSteps.filter((toursteps) =>
      adminTourStepsOnly.every((steps) => steps !== toursteps.title),
    );
  } else {
    userTourSteps = tourSteps;
  }

  return (
    <>
      <JoyRide
        steps={userTourSteps}
        continuous
        tooltipComponent={Tooltip}
        showProgress
        styles={joyRideGlobalStyles}
        run={startTour}
      />
    </>
  );
};

export default ProductTour;
