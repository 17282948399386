import { IShowToastPayload } from '../../models/ToastModels';
import { IStoreAction } from '../StoreHelper';

export enum ToastActionTypes {
  SHOW_TOAST = 'SHOW_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
}

export type ToastActionPayload = IShowToastPayload;

export type ToastAction = IStoreAction<ToastActionTypes, ToastActionPayload>;
export class ToastActions {
  public static showToast(data: IShowToastPayload): ToastAction {
    return {
      type: ToastActionTypes.SHOW_TOAST,
      data,
    };
  }

  public static hideToast(): ToastAction {
    return {
      type: ToastActionTypes.HIDE_TOAST,
    };
  }
}
