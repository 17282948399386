export interface IGuardrailRespObj {
  Description: string;
  Guardrails: string;
  Type: string;
}

export interface IGuardrailObj {
  Description: string;
  Guardrails: string[];
  Type: string;
}

export interface IGuardrailDetailsResponse {
  response: IGuardrailRespObj[];
}
export enum MetadataGuardrailMethod {
  METADATA_VIEW = 'metadata_view',
  GUARDRAIL_VIEW = 'guardrail_view',
  FORM_LIST = 'form_list',
}

export interface IGuardrailPayload {
  method: MetadataGuardrailMethod;
}
enum FormTypes {
  ELA = 'ELA',
  SRA = 'SRA',
  MLA = 'MLA',
  EULA = 'EULA',
  SOF = 'SOF - Service Order Form',
  ROF = 'ROF - Resale Order Form',
  MSLA = 'MSLA - Master Software License Agreement',
}
export interface IMetadataPayload extends IGuardrailPayload {
  form_type: string;
}

export interface IFormListResp {
  form_types: string[];
}
export interface IMetadataDetailResp {
  response: IMetadataObj[];
}
export interface IMetadataObj {
  'Column Name': string;
  'Data Type (Big Query)': string;
  'Field Description': string;
  'Metadata Field': string;
  'Sample Value': string;
}
