import React from 'react';
import { Drawer, Button, Row, Col, Tabs, Spin } from 'antd';
import './MetadataGuardrailDrawer.less';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import Metadata from './MetadataGuardrails/Metadata';
import Guardrails from './MetadataGuardrails/Guardrails';

interface IContractsDrawer {
  visible: boolean;
  onClose: (value: any) => void;
}
const MetadataGuardrailDrawer = ({ visible, onClose }: IContractsDrawer) => {
  const { guardrailLoading } = useAppSelector((state: IApplicationState) => ({
    guardrailLoading: state.metadataGuardrailDetails.loading,
  }));

  return (
    <Drawer
      visible={visible}
      placement={'right'}
      width={'100%'}
      closable={true}
      onClose={onClose}
      title={'Metadata & Guardrails'}
      className="metadata-guardrail-drawer"
    >
      <Row>
        <Col span={24}>
          <Spin spinning={guardrailLoading}>
            <div className="navigation">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Metadata" key="1">
                  <Metadata />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Guardrails" key="2">
                  <Guardrails />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Spin>
        </Col>
      </Row>
    </Drawer>
  );
};

export default MetadataGuardrailDrawer;
