import Drawer from 'rc-drawer';
import React, { useState } from 'react';
import { Button, Col, Modal, Row, Typography, Upload, notification } from 'antd';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader/Loader';
import CloseIcon from '../../assets/icons/cross.svg';
import BrokerImg from '../../assets/images/broker-dp.png';
import './Drawer.less';
import { RcFile } from 'antd/lib/upload';
import MailIcon from '../../assets/icons/mail-icon.svg';
import RightAeroIcon from '../../assets/icons/right-aero.svg';
import DataEnrichmentTab from '../../components/DataEnrichment/DataEnrichmentTab';
import EmailExtraction from './EmailExtraction/EmailExtraction';
import AttachmentsTable from '../Personas/DataIngestion/AttachmentsTable';
import { ArrowRightOutlined, BellOutlined } from '@ant-design/icons';
import NewCaseFormSubmission from '../../components/NewCaseFormSubmission/NewCaseFormSubmission';
import Diligence from '../Personas/Diligence/Diligence';
import { formItems } from '../MNACIDashboard/ContractsFormConstant';

export const emailList = [
  {
    id: '7675544544060923',
    status: 'Inprogress',
    emailName: 'Select renewal policy - Toyota of Richardson Inc',
    updatedOn: '23 June’23',
  },
  // {
  //   id: '7675544544060905',
  //   status: 'Inprogress',
  //   emailName: 'Dealer-insurance.msg',
  //   updatedOn: '12 Mar’23',
  // },
  // {
  //   id: '7675544544060912',
  //   status: 'Inprogress',
  //   emailName: 'Renewal-insurance.msg',
  //   updatedOn: '12 Mar’23',
  // },
  // {id:'',status:'', emailName:'',updatedOn:''},
];

interface INewCaseDrawerProps {
  visible?: boolean;
  setVisible?: () => void;
}
const NewCaseDrawer = ({ visible, setVisible }: INewCaseDrawerProps) => {
  const searchUrl = window.location.search;
  const urlParams = new URLSearchParams(searchUrl);

  const [activeItem, setActiveItem] = useState('Form Details');
  const [emailUploadTabs, setEmailUploadTabs] = useState('Email Extraction');
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [isEmailUploaded, setIsEmailUploaded] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [activeAttachmentTab, setActiveAttachmentTab] = useState('Form Details');
  const [activeForm, setActiveForm] = useState<string>('Toyota-CP-8538.pdf');
  const [loading, setLoading] = useState(false);


  const [selectedCard, setSelectedCard] = useState('7675544544060923');
  const tabs = [
    { title: 'Email Extraction' },
    { title: 'Attachments' },
    // { title: 'Data Enrichment' },
  ];

  const attchmentTabs = [
    { title: 'Form Details' },
    { title: 'Data Enrichment' },
    { title: 'Diligence / Pre-quote' },
    { title: 'Deal Analysis' },
  ];

  const toggleViewAttachementClick = () => {
    setViewAttachments(true);
  };

  const history = useHistory();

  const openNotification = (fileName: string) => {
    notification.open({
      message: <b>Success</b>,
      description: (
        <p>
          <b>{fileName}</b> was uploaded successfully
        </p>
      ),
      // icon: <img src={GreenTick} alt="" />,
      onClick: () => {
        // TBD:
      },
    });
  };
  return (
    <Drawer placement={'right'} width={'100%'} open={true} key={'right'} className="persona-drawer">
      <div>
        <div className="drawer-header-container">
          <div className="drawer-title">
            <div onClick={() => history.replace('/underwriter')}>
              <img src={CloseIcon} className="close-icon" alt="close-icon" />
            </div>
            <div className="drawer-title-text">
              {`Case Reference Number - ${urlParams.get('caseId')}`}{' '}
              {viewAttachments && (
                <Button
                  onClick={() => setViewAttachments(!viewAttachments)}
                  className="back-button"
                >
                  Back
                </Button>
              )}
            </div>
            {/* <span className="status-pill">Pending for Underwriter Review</span> */}
          </div>
          {/* <div className="d-flex">
            <div>
              <img src={BrokerImg} alt="" className="mr-1" />
            </div>
            <div>
              <p className="mb-0 white-text">Broker Name</p>
              <p className="mb-0 white-text font-16">{urlParams.get('brokerName')}</p>
            </div>
          </div> */}
        </div>
      </div>
      <Loader loaderName="" loading={loading}>
        {!viewAttachments ? (
          <Row className="drawer-body" gutter={[32, 32]}>
            <Col span={5} className="gutter-row section-outline">
              <div className="email-list-menu">
                <div className="email-list-menu-header flex-row-space-between">
                  <Typography className="email-list-menu-title">Emails</Typography>
                  <Button
                    type="text"
                    className="create-btn-style"
                    onClick={() => setUploadModalOpen(true)}
                  >
                    Browse
                  </Button>
                </div>
              </div>

              {isEmailUploaded &&
                emailList.map((email) => (
                  <div
                    className={`uploaded-email-cards cursor-pointer ${
                      selectedCard === email.id ? 'uploaded-email-cards-selected' : ''
                    }`}
                    key={email.id}
                    onClick={() => setSelectedCard(email.id)}
                  >
                    <div className="card-header">
                      <p className="id mb-0">ID: {email.id}</p>
                      <span className="status status-in-progress">{email.status}</span>
                    </div>
                    <div className="card-body">
                      <div className="flex-center">
                        <div className="mail-icon-container">
                          <img src={MailIcon} alt="" />
                        </div>
                        <div className="">
                          <p className="mb-0 email-name">{email.emailName}</p>
                          <p className="mb-0 email-updated-on">Updated on {email.updatedOn}</p>
                        </div>
                      </div>
                      <img src={RightAeroIcon} alt="" />
                    </div>
                  </div>
                ))}
            </Col>
            <Col span={19} className="gutter-row">
              {!isEmailUploaded ? (
                <div className="upload-email-message-container">
                  <div className="">
                    <img
                      src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/email-box-icon.svg`}
                      alt="email-box"
                    />
                  </div>
                  <Typography className="upload-email-message-text">
                    Please upload emails
                  </Typography>
                  <div className="submission-steps">
                    <div className="submission-steps-details">
                      <div className="submission-steps-container">1</div>
                      <Typography>Open your inbox</Typography>
                    </div>
                    <Typography>-------------</Typography>
                    <div className="submission-steps-details">
                      <div className="submission-steps-container">2</div>
                      <Typography>Select & save your emails on your desktop</Typography>
                    </div>
                    <Typography>-------------</Typography>
                    <div className="submission-steps-details">
                      <div className="submission-steps-container">3</div>
                      <Typography>Drag & drop in the email upload pop-up </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tabs-container">
                    {tabs.map(({ title }) => (
                      <Button
                        type="text"
                        key={title}
                        className={`tab ${emailUploadTabs === title ? 'selected' : ''}`}
                        onClick={() => setEmailUploadTabs(title)}
                      >
                        {title}
                      </Button>
                    ))}
                  </div>

                  {/* {content()} */}
                  {emailUploadTabs === tabs[0].title && <EmailExtraction />}
                  {emailUploadTabs === tabs[1].title && (
                    <AttachmentsTable toggleViewAttachementClick={toggleViewAttachementClick} />
                  )}
                  {/* {emailUploadTabs === tabs[2].title && <DataEnrichmentTab />} */}
                </>
              )}
            </Col>
          </Row>
        ) : (
          <Row className="view-attachments-conatiner" gutter={16}>
            <div className="tabs-container">
              {attchmentTabs.map(({ title }) => (
                <div key={title}>
                  <Button
                    type="text"
                    key={title}
                    className={activeAttachmentTab === title ? 'tab selected-tab' : 'tab'}
                    onClick={() => setActiveAttachmentTab(title)}
                    disabled={title === 'Deal Analysis'}
                  >
                    {title}{' '}
                    {title !== activeAttachmentTab && <BellOutlined className="notif-icon" />}
                  </Button>

                  {title !== 'Deal Analysis' && <ArrowRightOutlined className="right-icon" />}
                </div>
              ))}
            </div>
            <Row className="tabs-content-container">
              <Col span={24} className="h-100 overflow-auto">
                {activeAttachmentTab === attchmentTabs[0].title && <NewCaseFormSubmission formDetails={null} formItems1={formItems} />}
                {activeAttachmentTab === attchmentTabs[1].title && <DataEnrichmentTab />}
                {activeAttachmentTab === attchmentTabs[2].title && <Diligence />}
                {activeAttachmentTab === attchmentTabs[3].title}
              </Col>
            </Row>
          </Row>
        )}
        <Modal
          title={null}
          centered
          visible={uploadModalOpen}
          footer={null}
          onCancel={() => setUploadModalOpen(false)}
          className="upload-email-modal"
        >
          <Upload.Dragger
            name="file"
            accept=".eml, .msg"
            multiple={true}
            beforeUpload={(file: RcFile) => {
              setEnableSubmit(false);
              return false;
            }}
            // onDrop={(e) => {
            //   // openNotification("file")
            // }}
            onChange={(e) => {
              openNotification(e.fileList?.[e.fileList.length - 1].name);
              setEnableSubmit(true);
            }}
          >
            <div className="mb-1">
              <img
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/email-box.svg`}
                alt="email-box"
              />
            </div>
            <Typography>Click or drag file to this area to upload</Typography>
            <Typography>Support for a single or bulk upload.</Typography>
          </Upload.Dragger>
          {enableSubmit && (
            <div className="details-modal-footer mt-1">
              <Button
                type="text"
                className="flourescent-green-button"
                onClick={() => {
                  setUploadModalOpen(false);
                  setLoading(true)
                  setTimeout(() => {
                    setLoading(false)
                    setIsEmailUploaded(true);
                  }, 1500);
                  
                }}
              >
                Submit
              </Button>
            </div>
          )}
        </Modal>
      </Loader>
    </Drawer>
  );
};

export default NewCaseDrawer;
