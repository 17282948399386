import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Layout, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { NavMenuOptions } from '../../AppConstants';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import OverviewHeader from '../Overview/OverviewHeader';
import { SideBarKey } from '../Sidebar/SidebarConstants';
import './AppHeaderStyles.less';
import MNACILogo from '../../../src/assets/images/MNACI-logo.png';
import TopNav from '../TopNavigation/TopNav';
import SubHubHeader from './SubHubHeader/SubHubHeader';

const { Header } = Layout;

interface IAppHeaderProps {
  isScrolled: boolean;
}

const AppHeader = ({ isScrolled }: IAppHeaderProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log(location, location.pathname);

  const { isExpanded, activeKey } = useAppSelector((state: IApplicationState) => ({
    isExpanded: state.sidebar.isExpanded,
    activeKey: state.sidebar.activeKey,
  }));

  const dispatcher = {
    toggleSidebar: (data: boolean) => dispatch(SidebarActions.toggleSidebar(data)),
  };

  const toggleSidebar = () => dispatcher.toggleSidebar(!isExpanded);

  const toggleNavHeader = (key: string) => {
    switch (key) {
      case SideBarKey.DASHBOAARD:
        return <Typography className="header-title"> {NavMenuOptions.OVERVIEW}</Typography>;
      case SideBarKey.OPERATIONS:
        return <Typography className="header-title"> {NavMenuOptions.OPERATIONS}</Typography>;
      case SideBarKey.ASSET_AUTHORISATION:
        return (
          <Typography className="header-title"> {NavMenuOptions.ASSET_AUTHORISATION}</Typography>
        );
      case SideBarKey.QUICKLINKS:
        return <Typography className="header-title"> {NavMenuOptions.QUICK_LINKS}</Typography>;
      case SideBarKey.KEYCONTACTS:
        return <Typography className="header-title"> {NavMenuOptions.KEY_CONTACTS}</Typography>;
      case SideBarKey.WEB_ANALYTICS:
        return <Typography className="header-title"> {NavMenuOptions.WEB_ANALYTICS}</Typography>;
      case SideBarKey.DOCUMENTATION:
        return <Typography className="header-title"> {NavMenuOptions.DOCUMENTATION}</Typography>;
      case SideBarKey.FEEDBACK:
        return <Typography className="header-title"> {NavMenuOptions.FEEDBACK}</Typography>;
      case SideBarKey.USER_MANAGEMENT:
        return <Typography className="header-title"> {NavMenuOptions.USER_MANAGEMENT}</Typography>;
      case SideBarKey.ACCOUNT_MANAGEMENT:
        return (
          <Typography className="header-title"> {NavMenuOptions.ACCOUNT_MANAGEMENT}</Typography>
        );
      case SideBarKey.FEEDBACK_FORM_ADMIN:
        return (
          <Typography className="header-title"> {NavMenuOptions.FEEDBACK_RESPONSE}</Typography>
        );
      case SideBarKey.ASSESSMENT:
        return <Typography className="header-title"> {NavMenuOptions.ASSESSMENT}</Typography>;
      case SideBarKey.FEEDBACK_FORM_ADMIN:
        return <Typography className="header-title"> {NavMenuOptions.FEEDBACK}</Typography>;
      case SideBarKey.CBO_SUMMARY:
        return <Typography className="header-title"> {NavMenuOptions.CBOSUMMARY}</Typography>;
      case SideBarKey.CBO_CONFIGURE_JOURNEY:
        return <Typography className="header-title"> {NavMenuOptions.CONFIGUREJOURNEY}</Typography>;
      default:
        return <Typography className="header-title"> {NavMenuOptions.OVERVIEW}</Typography>;
    }
  };
  return (
    <Header className={`app-header-container`}>
      <div className="app-header-content d-flex justify-space-between">
        {/* <img src={MNACILogo} /> */}
        <div className="app-header-logo-container">
          <img
            className="broadcom-logo"
            src={`${process.env.PUBLIC_URL}/mnaci_logo.svg`}
            alt="braodcom logo"
          />
        </div>
        <TopNav />
        {/* <img src={MNACILogo} /> */}
        {/* <OverviewHeader /> */}
        <SubHubHeader />
      </div>
    </Header>
  );
};

export default AppHeader;
