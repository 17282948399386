/* eslint-disable max-lines */
import { CloudServiceProvider, fileExtensions } from '../../AppConstants';
import AWSIcon from '../../assets/icons/aws-icon.svg';
import GCPIcon from '../../assets/icons/gcp-icon.svg';
import OpenshiftIcon from '../../assets/icons/openshift-icon.svg';
import OracleIcon from '../../assets/icons/oracle-icon.png';
import AzureIcon from '../../assets/icons/azure-icon.svg';
import { ICloudLandingZoneKeysResponse } from '../../libs/store/state/BuildDeployState';
import { ConfigurationType } from './ConfigurationModalType';

export const ModalLabels: any = {
  workloadPatternETL: 'WorkLoad Pattern For ETL',
  multicloudValue: 'multicloud',
  multiCloudTitle: 'Multi Cloud',
  for: ' for ',
  modalMicroserviceTitle: 'WorkLoad Pattern For Microservices',
  landingZoneRequired: 'Please enter the Cloud Environment',
  valueRequired: 'Please enter the Value',
  landingZoneLable: 'Enter Cloud Environment Name',
  environmentLabel: 'Choose Environment',
  uploadHint:
    'Download Data via URL. You can get the download URL for a file by calling the getDownloadURL() method on a Cloud',
  uploadText: 'Click or drag file to this area to upload',
  downloadHint:
    'Download Data via URL. You can get the download URL for a file by calling the getDownloadURL() method on the Cloud configuration.',
  referETLHint: 'Refer the ETL Pipeline Workflow to configure the workload',
  referMSHint: 'Refer the Microservices Workflow to configure the workload',
  downloadBtnText: 'Download .zip file',
  configureBtnText: 'Configure',
  backBtnText: 'Back',
  nextBtnText: 'Next',
  previewBtnText: 'Preview',
  uploadRequiredLabel: 'Please Upload the yaml File',
  configExtension: '_config',
  ProviderLabel: 'Select Provider',
  selectedLabel: 'Selected Provider',
  createYamlContent: ['iam', 'networking', 'security', 'compute', 'storage', 'databases'],
  Manual: 'manual',
  create: 'Create',
  cancel: 'Cancel',
  back: 'Back',
  industry: 'industry',
  industry_azure: 'industry_azure',
  previewYaml: 'Preview - Yaml File',
  jsonLabelText: 'Please enter correct JSON  Value',
  wrongJson: 'Wrong Json Value',
  next: 'Next',
  preview: 'Preview',
  previous: 'Previous',
  fileMS: 'fileMS',
  fileETL: 'fileETL',
  chooseAny: 'Configure the Landing Zone manually or Choose any Architecture Flow below',
  configureManually: 'Configure Manually',
  folderName: 'architecture_diagram',
  configureDetails: 'Cloud Configuration Details for ',
  azureDomain: '**Additional Domains are coming soon.',
  proceedArchitecture: ' Proceed with this Architecture to Configure Landing Zone',
  chooseTemplate: 'Choose Template',
  cloudConfig: 'Cloud Configuration',
  gcp_industry_config: 'gcp_industry_config',
  azure_industry_config: 'gcp_industry_config',
  awsGlobalStatic: 'AWS_config_global_static',
  gcpGlobalStatic: 'gcp_global_static',
  ociGlobalStatic : 'oci_global_static',
  loading: 'Loading...',
  basicDetails: 'Basic Details',
  envType: 'Environment Type',
  configuration: 'Configuration',
  setCloudEnv: 'Select the type of Cloud Environment',
  defaultEnv: 'Default Environment Template',
  selectProjectName: 'Select Project Name',
  enterUsername: 'Enter Username',
  selectProjectId: 'Enter Project Id',
  selectRegion: 'Select Region',
  nameRequired: 'Name is required',
  passwordRequired: 'Password is required',
  idRequired: 'ID is required',
  regionRequired: 'Region is required',
  projectName: 'projectName',
  projectId: 'projectId',
  region: 'Region',
  username: 'Username',
  fieldisRequired: 'This field is required!',
  password: 'Password',
  database: 'database',
  azureConfig: 'global_config_azure',
  databases: 'databases',
  childrenRequired: 'Select one item.',
  star: '*',
  fieldRequired: 'Field is required',
  passwordlengthValidation: 'Password must be minimum 8 characters.',
  namelengthValidation: 'Name must be minimum 5 characters.',
  regionList: ['us-central1', 'us-east1', 'europe-west2', 'asia-south1'],
  businessDomainLabel: 'Select Business Domain',
  multicloudLabel : 'Select Multicloud Service',
  businessIcons: [
    'energy.svg',
    'financial.svg',
    'government.svg',
    'healthcare.svg',
    'retail.svg',
    'tech.svg',
  ],
  iso27001: 'ISO 27001',
  policies: 'Select Policies',
  configurations: 'Configurations',
  label: 'label',
  status: 'Status',
  enabled: 'enabled',
  yamlConfigurations: 'yamlConfigurations',
  securityDomain: 'Security Domain',
  policyName: 'Policy Name',
  description: 'Description',
  securityLabel: 'securityName',
  policyLabel: 'policyName',
  buildIndustry: 'build-industryLZ',
  imageURL: 'https://d3vb3iwfx9wew6.cloudfront.net/architecture_diagram/',
  ILZErrorEnum: {
    policyError: 'Please select atleast 1 policy in',
    domainError: 'Please select at least 1 domain',
  },
  multiCloudErrorEnum: {
    policyError: 'Please select atleast 1 cluster in',
    domainError: 'Please select at least 1 Cloud Provider Service',
  },
  multiCloudClusterEnum: {
    anthos_gcp: 'GKE Cluster',
    anthos_aws: 'EKS Cluster',
    anthos_azure: 'AKS Cluster',
    anthos_onprem: 'On Premise',
    aws_gke: 'GKE Cluster',
    aws_aks: 'AKS Cluster',
    aws_eks: 'EKS Cluster',
    aws_onprem: 'On Premise',
    azure_gke: 'GKE Cluster',
    azure_aks: 'AKS Cluster',
    azure_eks: 'EKS Cluster',
    azure_onprem: 'On Premise',
  },
  min4Required: 'Minimum 4 characters required.',
  max4Allowed: 'Maximum 30 characters allowed.',
  projectNameAllowedChars:
    'Only lowercase and uppercase letters, numbers, space and hyphens allowed',
  aws:'Choose AWS Template',
  gcp:'Choose GCP Template',
  azure:'Choose Azure Template',
  oracle:'Choose Oracle Template',
  openshift:'Choose OpenShift Template',
  multicloud:'Choose Multicloud Template'
};
export enum MulticloudClusterKeys {
  ANTHOS_GCP = 'anthos_gcp',
  ANTHOS_AWS = 'anthos_aws',
  ANTHOS_AZURE = 'anthos_azure',
  AWS_GKE = 'aws_gke',
  AWS_EKS = 'aws_eks',
  AWS_AKS = 'aws_aks',
  AZURE_GKE = 'azure_gke',
  AZURE_EKS = 'azure_aks',
  AZURE_AKS = 'azure_eks',
}
export const envTypes = [
  { name: 'Default Environment Template', icon: 'env-default.svg' },
  { name: 'Use Predefined Architecture Template', icon: 'env-predefined.svg' },
  {
    name: 'Custom Landing Zone',
    icon: 'env-custom.svg',
  },
  {
    name: 'Industry Landing Zone',
    icon: 'env-industry.svg',
  },
];
export const yamlOptions = [
  { name: 'Create Yaml File', value: 'create' },
  { name: 'Configure Manually', value: 'manual' },
];

export const EnvironmentOptions = [
  { label: 'Development', value: 'dev' },
  { label: 'Production', value: 'prod' },
  { label: 'Testing', value: 'test' },
];

export const CSPConfigFileName = 'CSP_CONFIG';

// TO generate the presigned URL
//args: Selected Cloud Provider configurationType: landingZone/microservices
export const preSignedURLGenerator = (selectedProvider: string, configurationType: string) => {
  if (configurationType === ConfigurationType.LANDING_ZONE_CREATED) {
    // eslint-disable-next-line no-param-reassign
    configurationType = ConfigurationType.LANDING_ZONE;
  }
  return `${CSPConfigFileName}${'/'}${configurationType}${
    ModalLabels.configExtension
  }${'/'}${selectedProvider}${ModalLabels.configExtension}${fileExtensions.yaml}`;
};

export const iconSelector = (name: string) => {
  switch (name) {
    case CloudServiceProvider.AWS:
      return AWSIcon;
    case CloudServiceProvider.GCP:
      return GCPIcon;
    case CloudServiceProvider.AZURE:
      return AzureIcon;
    case CloudServiceProvider.OPENSHIFT:
      return OpenshiftIcon;
    case CloudServiceProvider.ORACLE:
      return OracleIcon;
    default:
      return '';
  }
};

export const iconSelectorConfigureLandingZone = (name: string) => {
  switch (name) {
    case CloudServiceProvider.AWS:
      return 'aws@2x.png';
    case CloudServiceProvider.GCP:
      return 'gpc@2x.png';
    case CloudServiceProvider.AZURE:
      return 'azure@2x.png';
    case CloudServiceProvider.OPENSHIFT:
      return 'open-shift@2x.png';
    case CloudServiceProvider.ORACLE:
      return 'oracle@2x.svg';
    case CloudServiceProvider.MULTICLOUD:
      return `multicloud_icon.png`;
    case `gcp_anthos`:
      return `GCP_Anthos.jpg`;
    case `azure_arc`:
      return `Azure_ARC.jpg`;
    case `aws_outpost`:
      return `AWS_OutPost.jpg`;
    case `vm_ware`:
      return `vm_ware.png`;
    default:
      return '';
  }
};

export const iconSelectorProviderTemplate = (name: string | undefined) => {
  switch (name) {
    case 'terraform':
    case 'Terraform':
      return 'terraform-basic.svg';
    
    case 'deploymentmanager':
    case 'Deployment Manager':
      return 'deploymentManager.svg';

    case 'azureresourcemanager':
    case 'Azure Resource Manager':
      return 'azureResourceManager.svg';
    
    case 'bicep':
    case 'Bicep Template':
      return 'bicepTemplate.svg';  
    
    case 'cloudformation':
    case 'CloudFormation':
      return 'CloudFormation.svg';

    case 'resourcemanager':
    case 'Resource Manager':
      return 'ResourceManager.png';  
    default:
      return '';
  }
};

export const iconSelectorText = (name: string) => {
  switch (name) {
    case CloudServiceProvider.AWS:
      return 'Amazon Web Services';
    case CloudServiceProvider.GCP:
      return 'Google Cloud Platform';
    case CloudServiceProvider.AZURE:
      return 'Microsoft Azure';
    case CloudServiceProvider.ORACLE:
      return 'Oracle Cloud';
    case CloudServiceProvider.OPENSHIFT:
      return 'Open Shift';
    case CloudServiceProvider.MULTICLOUD:
      return 'Multi Cloud';
    default:
      return '';
  }
};
export const multiCloudKeys: ICloudLandingZoneKeysResponse[] = [
  {
    name: 'Google Cloud Anthos',
    key: 'Google Cloud Anthos',
    description: '',
    children: [
      { name: 'GKE Cluster', key: 'anthos_gcp', description: 'gke' },
      { name: 'AKS Cluster', key: 'anthos_azure', description: 'aks' },
      { name: 'EKS Cluster', key: 'anthos_aws', description: 'eks' },
      { name: 'On Premise', key: 'anthos_onprem', description: 'on prem' },
    ],
    required: false,
    imageIcon: iconSelectorConfigureLandingZone(`gcp_anthos`),
    enabled: false,
    label: '',
  },
  {
    name: 'AWS Outposts',
    key: 'aws outposts',
    description: '',
    children: [
      { name: 'GKE Cluster', key: 'aws_gke', description: 'gke' },
      { name: 'EKS Cluster', key: 'aws_eks', description: 'eks' },
      { name: 'On Premise', key: 'aws_onprem', description: 'on prem' },
    ],
    required: false,
    imageIcon: iconSelectorConfigureLandingZone(`aws_outpost`),
    enabled: false,
    label: '',
  },
  {
    name: 'Azure Arc',
    key: 'azure arc',
    description: '',
    children: [
      { name: 'GKE Cluster', key: 'azure_gke', description: 'gke' },
      { name: 'EKS Cluster', key: 'azure_eks', description: 'eks' },
      { name: 'On Premise', key: 'azure_onprem', description: 'on prem' },
    ],
    required: false,
    imageIcon: iconSelectorConfigureLandingZone(`azure_arc`),
    enabled: false,
    label: '',
  },
  {
    name: 'vm ware',
    key: 'vm ware',
    description: '',
    children: [
      { name: 'GKE Cluster', key: 'vmware_gke', description: 'gke' },
      { name: 'EKS Cluster', key: 'vmware_eks', description: 'eks' },
      { name: 'On Premise', key: 'vmware_onprem', description: 'on prem' },
    ],
    required: false,
    imageIcon: iconSelectorConfigureLandingZone(`vm_ware`),
    enabled: false,
    label: '',
  }
];

interface IDefaultYamlChildren {
  name: string;
  icon: string;
  enable: boolean;
  required: boolean;
  key: string;
}
export interface IDefaultYaml {
  name: string;
  key: string;
  icon: string;
  enable: boolean;
  required: boolean;
  children?: IDefaultYamlChildren[];
  description?: string;
}

export interface ISelectOptions {
  label: string,
  value: string,
}
export interface IAwsRegions {
  anthos: string,
  aws: ISelectOptions[]
}

export const AnthosRegions = [
  'asia-east2',
  'asia-northeast2',
  'asia-south1',
  'asia-southeast1',
  'asia-southeast2',
  'australia-southeast1',
  'europe-north1',
  'europe-west1',
  'europe-west2',
  'europe-west3',
  'europe-west9',
  'northamerica-northeast1',
  'southamerica-east1',
  'us-east4',
  'us-west1'
];

export const AwsRegionsAnthos = {
  'asia-east2' : [
    {label: 'ap-east-1', value: 'ap-east-1'}
  ],
  'asia-northeast2' : [
    {label: 'ap-northeast-1', value: 'ap-northeast-1'},
    {label: 'ap-northeast-3', value: 'ap-northeast-3'},
  ],
  'asia-south1' : [
    {label: 'ap-south-1', value: 'ap-south-1'},
  ],
  'asia-southeast1' : [
    {label: 'ap-east-1', value: 'ap-east-1'},
    {label: 'ap-northeast-1', value: 'ap-northeast-1'},
    {label: 'ap-southeast-1', value: 'ap-southeast-1'},
  ],
  'asia-southeast2' : [
    {label: 'ap-southeast-3', value: 'ap-southeast-3'},
  ],
  'australia-southeast1' : [
    {label: 'ap-southeast-2', value: 'ap-southeast-2'},
  ],
  'europe-north1' : [
    {label: 'eu-north-1', value: 'eu-north-1'},
  ],
  'europe-west1' : [
    {label: 'eu-central-1', value: 'eu-central-1'},
    {label: 'eu-north-1', value: 'eu-north-1'},
    {label: 'eu-south-1', value: 'eu-south-1'},
    {label: 'eu-west-1', value: 'eu-west-1'},
    {label: 'eu-west-2', value: 'eu-west-2'},
    {label: 'eu-west-3', value: 'eu-west-3'},
  ],
  'europe-west2' : [
    {label: 'eu-west-2', value: 'eu-west-2'},
  ],
  'europe-west3' : [
    {label: 'eu-central-1', value: 'eu-central-1'},
  ],
  'europe-west9' : [
    {label: 'eu-west-3', value: 'eu-west-3'},
  ],
  'northamerica-northeast1' : [
    {label: 'ca-central-1', value: 'ca-central-1'},
  ],
  'southamerica-east1' : [
    {label: 'sa-east-1', value: 'sa-east-1'},
  ],
  'us-east4' : [
    {label: 'ca-central-1', value: 'ca-central-1'},
    {label: 'us-east-1', value: 'us-east-1'},
    {label: 'us-east-2', value: 'us-east-2'},
  ],
  'us-west1' : [
    {label: 'us-west-1', value: 'us-west-1'},
    {label: 'us-west-2', value: 'us-west-2'},
  ]
};

export const AnthosSupportedAwsRegions: IAwsRegions[] = [
  {
    anthos: 'asia-east2',
    aws: [
      {label: 'ap-east-1', value: 'ap-east-1'}
    ]
  },
  {
    anthos: 'asia-northeast2',
    aws: [
      {label: 'ap-northeast-1', value: 'ap-northeast-1'},
      {label: 'ap-northeast-3', value: 'ap-northeast-3'},
    ]
  },
  {
    anthos: 'asia-south1',
    aws: [
      {label: 'ap-south-1', value: 'ap-south-1'},
    ]
  },
  {
    anthos: 'asia-southeast1',
    aws: [
      {label: 'ap-east-1', value: 'ap-east-1'},
      {label: 'ap-northeast-1', value: 'ap-northeast-1'},
      {label: 'ap-southeast-1', value: 'ap-southeast-1'},
    ]
  },
  {
    anthos: 'asia-southeast2',
    aws: [
      {label: 'ap-southeast-3', value: 'ap-southeast-3'},
    ]
  },
  {
    anthos: 'australia-southeast1',
    aws: [
      {label: 'ap-southeast-2', value: 'ap-southeast-2'},
    ]
  },
  {
    anthos: 'europe-north1',
    aws: [
      {label: 'eu-north-1', value: 'eu-north-1'},
    ]
  },
  {
    anthos: 'europe-west1',
    aws: [
      {label: 'eu-central-1', value: 'eu-central-1'},
      {label: 'eu-north-1', value: 'eu-north-1'},
      {label: 'eu-south-1', value: 'eu-south-1'},
      {label: 'eu-west-1', value: 'eu-west-1'},
      {label: 'eu-west-2', value: 'eu-west-2'},
      {label: 'eu-west-3', value: 'eu-west-3'},
    ]
  },
  {
    anthos: 'europe-west2',
    aws: [
      {label: 'eu-west-2', value: 'eu-west-2'},
    ]
  },
  {
    anthos: 'europe-west3',
    aws: [
      {label: 'eu-central-1', value: 'eu-central-1'},
    ]
  },
  {
    anthos: 'europe-west9',
    aws: [
      {label: 'eu-west-3', value: 'eu-west-3'},
    ]
  },
  {
    anthos: 'northamerica-northeast1',
    aws: [
      {label: 'ca-central-1', value: 'ca-central-1'},
    ]
  },
  {
    anthos: 'southamerica-east1',
    aws: [
      {label: 'sa-east-1', value: 'sa-east-1'},
    ]
  },
  {
    anthos: 'us-east4',
    aws: [
      {label: 'ca-central-1', value: 'ca-central-1'},
      {label: 'us-east-1', value: 'us-east-1'},
      {label: 'us-east-2', value: 'us-east-2'},
    ]
  },
  {
    anthos: 'us-west1',
    aws: [
      {label: 'us-west-1', value: 'us-west-1'},
      {label: 'us-west-2', value: 'us-west-2'},
    ]
  }
];
