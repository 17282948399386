import {
  ICreateUserRequestBody,
  IEditUserRequestBody,
  IUserManagementObject,
} from '../../models/UserManagementModel';
import { IStoreAction } from '../StoreHelper';

export enum UserManagementActionTypes {
  CREATE_USER_STARTED = 'CREATE_USER_STARTED',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  GET_USER_MANAGEMENT_STARTED = 'GET_USER_MANAGEMENT_STARTED',
  GET_USER_MANAGEMENT_SUCCESS = 'GET_USER_MANAGEMENT_SUCCESS',
  GET_USER_MANAGEMENT_ERROR = 'GET_USER_MANAGEMENT_ERROR',
  UPDATE_USER_MANAGEMENT_STARTED = 'UPDATE_USER_MANAGEMENT_STARTED',
  UPDATE_USER_MANAGEMENT_SUCCESS = 'UPDATE_USER_MANAGEMENT_SUCCESS',
  UPDATE_USER_MANAGEMENT_ERROR = 'UPDATE_USER_MANAGEMENT_ERROR',
}

export type UserManagementActionPayload =
  | Error
  | null
  | IUserManagementObject[]
  | ICreateUserRequestBody
  | IEditUserRequestBody;

export type UserManagementAction = IStoreAction<
  UserManagementActionTypes,
  UserManagementActionPayload
>;

export class UserManagementActions {
  public static createUserStart(data: ICreateUserRequestBody): UserManagementAction {
    return {
      type: UserManagementActionTypes.CREATE_USER_STARTED,
      data,
    };
  }
  public static createUserSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.CREATE_USER_SUCCESS,
    };
  }
  public static createUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.CREATE_USER_ERROR,
      data: error,
    };
  }
  public static getUserStart(): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_MANAGEMENT_STARTED,
    };
  }
  public static getUserSuccess(data: IUserManagementObject[]): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_MANAGEMENT_SUCCESS,
      data,
    };
  }
  public static getUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.GET_USER_MANAGEMENT_ERROR,
      data: error,
    };
  }
  public static editUserStart(data: IEditUserRequestBody): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_MANAGEMENT_STARTED,
      data,
    };
  }
  public static editUserSuccess(): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_MANAGEMENT_SUCCESS,
    };
  }
  public static editUserError(error: Error): UserManagementAction {
    return {
      type: UserManagementActionTypes.UPDATE_USER_MANAGEMENT_ERROR,
      data: error,
    };
  }
}
