import { IRunPipelinePayload, IRunPipelineResponse } from '../../models/PipelineModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class RunPipelineService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public runPipeline(
    payload: IRunPipelinePayload,
    pipelineId: string,
  ): Promise<IRunPipelineResponse> | IRunPipelineResponse {
    return this.service.get({ route: [`${API_ROUTE.RUN_PIPELINE}/${pipelineId}`] });
  }
}

const runPipelineService = new RunPipelineService();
export default runPipelineService;
