import {
  AssessJourneySection,
  IAccountDetailsResponse,
  IAssessmentStateResponse,
  IAuditHistoryPayload,
  IProjectOwnersPayload,
  IProjectOwnersResponse,
} from '../../models/DashboardModels';
import { IStoreAction } from '../StoreHelper';

export enum DashboardActionTypes {
  GET_ALL_ACCOUNTS_START = 'GET_ALL_ACCOUNTS_START',
  GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS',
  GET_ALL_ACCOUNTS_ERROR = 'GET_ALL_ACCOUNTS_ERROR',
  GET_AUDIT_HISTORY_START = 'GET_AUDIT_HISTORY_START',
  GET_AUDIT_HISTORY_SUCCESS = 'GET_AUDIT_HISTORY_SUCCESS',
  GET_AUDIT_HISTORY_ERROR = 'GET_AUDIT_HISTORY_ERROR',
  SAVE_AUDIT_HISTORY_START = 'SAVE_AUDIT_HISTORY_START',
  SAVE_AUDIT_HISTORY_SUCCESS = 'SAVE_AUDIT_HISTORY_SUCCESS',
  SAVE_AUDIT_HISTORY_ERROR = 'SAVE_AUDIT_HISTORY_ERROR',
  SET_DISCOVER_ASSESS_LIST = 'SET_DISCOVER_ASSESS_LIST',
  SET_ACTIVE_DASHBOARD_TITLE_NAME = 'SET_ACTIVE_DASHBOARD_TITLE_NAME',
  GET_PROJECT_OWNERS_START = 'GET_PROJECT_OWNERS_START',
  GET_PROJECT_OWNERS_SUCCESS = 'GET_PROJECT_OWNERS_SUCCESS',
  GET_PROJECT_OWNERS_ERROR = 'GET_PROJECT_OWNERS_ERROR',
  GET_ASSESSMENTS_STATE_START = 'GET_ASSESSMENTS_STATE_START',
  GET_ASSESSMENTS_STATE_SUCCESS = 'GET_ASSESSMENTS_STATE_SUCCESS',
  GET_ASSESSMENTS_STATE_ERROR = 'GET_ASSESSMENTS_STATE_ERROR',
  SET_SELECTED_ASSESS_TAB = 'SET_SELECTED_ASSESS_TAB',
}

export type DashboardActionPayload =
  | boolean
  | Error
  | IAccountDetailsResponse[]
  | IAuditHistoryPayload
  | number[]
  | number
  | IAssessmentStateResponse
  | string
  | IProjectOwnersPayload
  | IProjectOwnersResponse[]
  | AssessJourneySection;

export type DashboardAction = IStoreAction<DashboardActionTypes, DashboardActionPayload>;
export class DashboardActions {
  public static getAllAccountsStart(): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ALL_ACCOUNTS_START,
    };
  }

  public static getAllAccountsSuccess(data: IAccountDetailsResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ALL_ACCOUNTS_SUCCESS,
      data,
    };
  }

  public static getAllAccountsError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ALL_ACCOUNTS_ERROR,
      data,
    };
  }

  public static getAuditHistoryStart(): DashboardAction {
    return {
      type: DashboardActionTypes.GET_AUDIT_HISTORY_START,
    };
  }

  public static getAuditHistorySuccess(): DashboardAction {
    return {
      type: DashboardActionTypes.GET_AUDIT_HISTORY_SUCCESS,
    };
  }

  public static getAuditHistoryError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_AUDIT_HISTORY_ERROR,
      data,
    };
  }

  public static saveAuditHistoryStart(data: IAuditHistoryPayload): DashboardAction {
    return {
      type: DashboardActionTypes.SAVE_AUDIT_HISTORY_START,
      data,
    };
  }

  public static saveAuditHistorySuccess(): DashboardAction {
    return {
      type: DashboardActionTypes.SAVE_AUDIT_HISTORY_SUCCESS,
    };
  }

  public static saveAuditHistoryError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.SAVE_AUDIT_HISTORY_ERROR,
      data,
    };
  }

  public static setDiscoverAssessList(data: number[]): DashboardAction {
    return {
      type: DashboardActionTypes.SET_DISCOVER_ASSESS_LIST,
      data,
    };
  }

  public static setActiveDashboardTitleState(data: string): DashboardAction {
    return {
      type: DashboardActionTypes.SET_ACTIVE_DASHBOARD_TITLE_NAME,
      data,
    };
  }

  public static getProjectOwnersStart(data: IProjectOwnersPayload): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROJECT_OWNERS_START,
      data,
    };
  }

  public static getProjectOwnersSuccess(data: IProjectOwnersResponse[]): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROJECT_OWNERS_SUCCESS,
      data,
    };
  }

  public static getProjectOwnersError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_PROJECT_OWNERS_ERROR,
      data,
    };
  }

  public static getAssessmentDataStart(data: string): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ASSESSMENTS_STATE_START,
      data,
    };
  }

  public static getAssessmentDataSuccess(data: IAssessmentStateResponse): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ASSESSMENTS_STATE_SUCCESS,
      data,
    };
  }

  public static getAssessmentDataError(data: Error): DashboardAction {
    return {
      type: DashboardActionTypes.GET_ASSESSMENTS_STATE_ERROR,
      data,
    };
  }

  public static setSelectedAssessTab(data: AssessJourneySection): DashboardAction {
    return {
      type: DashboardActionTypes.SET_SELECTED_ASSESS_TAB,
      data,
    };
  }

}
