import { Button, Col, Form, Input, Popover, Row, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import PdfImg from '../../assets/images/MNACIPdf.jpg';
import PdfImg2 from '../../assets/images/MNACIPdf2.jpg';
// import PdfImg from '../../assets/images/Toyota-CP8538.jpg';
import { IFormItem, IInitialValue } from '../../containers/Personas/DiligenceOld/Diligence';
import './NewCaseFormSubmission.less';
// import { IContractCard } from '../../containers/MNACIDashboard/MNACIDashboard';
import { IFormFields1 } from '../../containers/MNACIDashboard/ContractsFormConstant';
import { useAppSelector, IApplicationState } from '../../libs/store/reducers';
import { ContractActions } from '../../libs/store/actions/UploadContractActions';
import { IContractsData } from '../../containers/MNACIDashboard/MNACIDashboardNew';
interface IFormDetailsProps {
  formDetails: IContractsData | null | undefined;
  formItems1: IFormFields1;
}

interface IFormFields {
  label: string;
  name: string;
  value: string;
  desc?: string;
}

const NewCaseFormSubmission = ({ formDetails, formItems1 }: IFormDetailsProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formView, setFormView] = useState<string>('plain');
  const [initialValue, setInitialValue] = useState<IInitialValue>();
  const [isValidateVisible, setIsValidatedVisible] = useState<boolean>(true);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [effectiveField, setEffectiveField] = useState<string>('');
  const [expirateionField, setExpirateionField] = useState<string>('');

  const [dynamicFormFields, setDynamicFormFields] = useState<IFormFields[]>([]);

  const tabs = [{ title: 'Toyota-CP-8538.pdf' }];

  const dispatcher = {
    getPdfUrl: (data: string) => dispatch(ContractActions.getPdfUrlStart(data)),
    getContractDetails: (data: string) =>
      dispatch(ContractActions.getContractDetailsDataStart(data)),
  };

  const { pdfUrl, loading, contractsDetailsLoading, contractsDetailsResponse } = useAppSelector(
    ({
      contracts: { pdfUrl, loading, contractsDetailsLoading, contractsDetailsResponse },
    }: IApplicationState) => ({
      pdfUrl,
      loading,
      contractsDetailsLoading,
      contractsDetailsResponse,
    }),
  );

  // const dummyData: Record<string, string> = {
  //   contract_content_type: 'application/odf',
  //   contract_id: '8285111229027699',
  //   contrect_name: 'Salesforce.com Inc. Arendrent 12',
  //   contract_title: 'jkbdfjkb',
  //   contract_type: 'jkdsfndsf',
  //   customer_name: 'Salesforce.com. Inc.',
  // };

  const formItems: IFormFields[] = formItems1['Order_Form_1.pdf'];
  // formItems1[
  //   ([
  //     'Order_Form_1.pdf',
  //     'Order_Form_2.pdf',
  //     'Order_Form_3.pdf',
  //     'Order_Form_4.pdf',
  //     'Order_Form_5.pdf',
  //     'Order_Form_6.pdf',
  //     'Order_Form_7.pdf',
  //     'Order_Form_8.pdf',
  //     'Order_Form_9.pdf',
  //     'Order_Form_10.pdf',
  //     'Order_Form_11.pdf',
  //     'Order_Form_12.pdf',
  //     'Order_Form_13.pdf',
  //     'Order_Form_14.pdf',
  //     'Order_Form_15.pdf',
  //   ].includes(formDetails?.title || 'Order_Form_1.pdf') &&
  //     formDetails?.title) ||
  //     'Order_Form_1.pdf'
  // ];
  const pdfJson = `{
    'Proposed First Names Insured and Other named Insured': 'Toyota of Richardson Inc',

    'Today’s Date (mm/dd/yyyy)': '05/30/2023',

    'Mailing Address': '1221 N Central Expy Richardson, Texas, 75080-4693 United States',

    'Telephone Number': '+1 9722019228',

    'Web Address': 'contactus@toyotaofrichardson.com',
    
    'Type of Legal Entity': 'Corporation',

    'Number of Years in Business': '30'
  }`;

  const handleVailidated = () => {
    setIsValidated(true);
  };

  const handleNotValidated = () => {
    setIsValidatedVisible(true);
    form.setFields([
      { name: 'number', value: '1221 N Central Expy Richardson, Texas, 75080-4693 United States' },
    ]);
  };

  const handleUndoAction = () => {
    setIsValidatedVisible(true);
    setIsValidated(false);
    form.setFields([
      { name: 'number', value: '1221 N Central Expy Richardson, Texas, 75080-4693 United States' },
    ]);
  };

  useEffect(() => {
    setIsLoading(true);
    console.log('form details contract id', formDetails?.contract_id);

    if (formDetails?.contract_id) {
      dispatcher.getPdfUrl(formDetails?.contract_id);
      dispatcher.getContractDetails(formDetails?.contract_id);
    }
  }, [formDetails?.contract_id]);

  const formatName = (inputName: string) => {
    const words = inputName.split('_');
    const formattedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedString;
  };

  useEffect(() => {
    const convertData: IFormFields[] = [];

    contractsDetailsResponse.forEach(({ key, value, desc }) => {
      if (key !== 'contract_content_type' && key !== 'contract_id') {
        convertData.push({
          label: formatName(key),
          desc,
          name: key,
          value: value ? value : '',
        });
      }
    });
    setDynamicFormFields(convertData);
  }, [JSON.stringify(contractsDetailsResponse)]);

  useEffect(() => {
    let obj = {
      ...dynamicFormFields?.reduce((acc, { name, value }: IFormItem) => {
        acc[name] = value;
        return acc;
      }, {} as IInitialValue),
    };
    form.resetFields();
    setInitialValue(obj);
  }, [JSON.stringify(dynamicFormFields)]);

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(initialValue)]);
  const renderFields = (item: IFormFields) => {
    switch (item.name) {
      default:
        return (
          <Col span={24} className="gutter-row" key={item.label}>
            <Form.Item
              label={
                <>
                  {item.label}
                  {item?.desc && (
                    <Popover
                      content={item.desc}
                      className="form-popover"
                      placement="topRight"
                      overlayStyle={{ width: '20vw' }}
                    >
                      <InfoCircleOutlined className="form-info-icon" style={{ paddingLeft: 10 }} />
                    </Popover>
                  )}
                </>
              }
              name={item.name}
              validateTrigger="onBlur"
            >
              <Input className="border-style-form-component" readOnly value={item.value} />
            </Form.Item>
          </Col>
        );
    }
  };

  return (
    <Row className="new-case-form-sub-container h-100">
      <Col span={24} className="attachment-details">
        <Spin spinning={isLoading} tip="Contract Details are Loading">
          <Row className="details-content w-100" gutter={[16, 16]}>
            <Col span={17} className="pl-0">
              {pdfUrl && (
                <object
                  data={pdfUrl + '#view=FitH'}
                  height="850"
                  width="100%"
                  onLoad={() => setIsLoading(false)}
                  aria-labelledby="pdf"
                />
              )}
            </Col>
            <Col span={7}>
              {formView === 'plain' ? (
                !contractsDetailsLoading && (
                  <Form
                    layout="vertical"
                    onFinish={(val) => {}}
                    form={form}
                    className="mt-1 case-details-form"
                    initialValues={initialValue}
                  >
                    <Row className="sub-hub-form-styles forms-style">
                      {dynamicFormFields.map((item) => renderFields(item))}
                    </Row>
                  </Form>
                )
              ) : (
                <pre className="json-style">{pdfJson}</pre>
              )}
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default NewCaseFormSubmission;
