import { Reducer } from 'redux';
import { IEmailNotification, ILandingZoneNotification } from '../../models/NotificationModels';
import { NotificationAction, NotificationActionTypes } from '../actions/NotificationActions';
import NotificationState from '../state/NotificationState';
import ILandingZoneNotificationState from '../state/NotificationState';

export const initialState: ILandingZoneNotificationState = {
  error: '',
  loading: false,
  landingZoneNotifications: [],
  landingZoneNotificationDot: false,
  emailNotifications: [],
  isEmailNotificationVisible: false,
  isVisible: false,
  emailNotificationDot: false,
};

export const NotificationReducer: Reducer<NotificationState> = (
  state = initialState,
  action: NotificationAction,
) => {
  switch (action.type) {
    case NotificationActionTypes.TOGGLE_LANDING_ZONE_NOTIFICATION:
      return {
        ...state,
        isVisible: (action.data as boolean) || false,
      };
    case NotificationActionTypes.TOGGLE_LANDING_ZONE_NOTIFICATION_DOT:
      return {
        ...state,
        landingZoneNotificationDot: (action.data as boolean) || false,
      };
    case NotificationActionTypes.TOGGLE_EMAIL_NOTIFICATION:
      return {
        ...state,
        isEmailNotificationVisible: (action.data as boolean) || false,
      };
    case NotificationActionTypes.TOGGLE_EMAIL_NOTIFICATION_DOT:
      return {
        ...state,
        emailNotificationDot: (action.data as boolean) || false,
      };
    case NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        landingZoneNotifications: [...(action.data as ILandingZoneNotification[])],
      };

    case NotificationActionTypes.GET_LANDING_ZONE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: [...(action.data as IEmailNotification[])],
      };

    case NotificationActionTypes.GET_EMAIL_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    default:
      return state;
  }
};
