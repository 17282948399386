import { Layout } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader/Loader';
import {
  ICountLandingZone,
  routesWithLoadingDisabled,
} from '../../containers/HomePage/HomePageConstants';
import { calculateLandingZones } from '../../containers/HomePage/HomePageHelperFunction';
import { BuildDeployActions } from '../../libs/store/actions/BuildDeployActions';
import { DashboardActions } from '../../libs/store/actions/DashboardActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import AppHeaderMobile from '../AppHeaderMobile/AppHeaderMobile';
import SidebarMobile from '../SidebarMobile/SidebarMobile';
import './HomePageMobileStyles.less';

const { Content } = Layout;

interface IHomePageMobileProps {
  children: ReactNode;
  showProjectSelector: boolean;
  pageTitle: string;
}

const HomePageMobile = ({ children, showProjectSelector, pageTitle }: IHomePageMobileProps) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // TODO: Set-up homepage state in redux to keep track of current page when more pages are added.

  const {
    isExpanded,
    hiddenSidebar,
    loginLoading,
    userLoading,
    dashboardLoading,
    jobRunLoading,
    selectedProjectId,
    overViewLoading,
    loading,
    landingZones,
  } = useAppSelector(
    ({
      sidebar: { isExpanded, hiddenSidebar },
      overview: { selectedProjectId },
      login,
      user,
      dashboard,
      operations,
      overview,
      buildDeploy: { loading, landingZones },
    }: IApplicationState) => ({
      landingZones,
      isExpanded,
      hiddenSidebar,
      loginLoading: login.loading,
      userLoading: user.loading,
      dashboardLoading: dashboard.loading,
      jobRunLoading: operations.loadingJobRun,
      overViewLoading: overview.loading,
      selectedProjectId,
      loading,
    }),
  );

  const dispatcher = {
    getBuildDeployDetails: (data: string) =>
      dispatch(BuildDeployActions.getBuildDeployDetailStarted(data)),
    setTotalEnvironments: (data: ICountLandingZone) =>
      dispatch(BuildDeployActions.setTotalEnvironmentsCount(data)),
    getAssessmentsState: (data: string) => dispatch(DashboardActions.getAssessmentDataStart(data)),
  };

  const getBuildDeployDetails = (projectId: string) => dispatcher.getBuildDeployDetails(projectId);

  useEffect(() => {
    selectedProjectId && getBuildDeployDetails(selectedProjectId);
    selectedProjectId && dispatcher.getAssessmentsState(selectedProjectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  useEffect(() => {
    const count = calculateLandingZones(landingZones);

    dispatcher.setTotalEnvironments(count);
  }, [landingZones]);

  const loadingDisabled = routesWithLoadingDisabled.includes(history.location.pathname);

  return (
    <>
      <Loader
        loading={
          loadingDisabled
            ? false
            : dashboardLoading ||
              loginLoading ||
              jobRunLoading ||
              overViewLoading ||
              userLoading ||
              // TODO: Add the build deploy loading back once the dca APIs are fixed
              loading
        }
        loaderName=""
      >
        <Layout>
          <SidebarMobile />
          {!(hiddenSidebar && isExpanded) && (
            <Layout className="main-content-container layout-mobile">
              <AppHeaderMobile isScrolled={scrollPosition > 15} showProjectSelector={showProjectSelector} pageTitle={pageTitle}/>
              <Content>{children}</Content>
            </Layout>
          )}
        </Layout>
      </Loader>
      {/*TODO: Need to change it to label rather than key */}
    </>
  );
};

export default HomePageMobile;
