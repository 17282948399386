import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NotificationTypes } from '../../components/Notifications/NotificationsConstants';
import { ICWBLoginResponse } from '../../libs/models/CWBLoginModels';
import { ITangraLoginPayload } from '../../libs/models/LoginModels';
import { IShowToastPayload } from '../../libs/models/ToastModels';
import { UserType } from '../../libs/models/UserModels';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { DashboardActions } from '../../libs/store/actions/DashboardActions';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { ToastActions } from '../../libs/store/actions/ToastActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import { LoginLabels as LABELS } from './LoginConstants';
import {  signInWithEmailAndPassword,createUserWithEmailAndPassword   } from 'firebase/auth';
import { auth } from '../../components/Auth/FireBaseAuth';
import jwt_decode from "jwt-decode";
import { useForm } from 'antd/lib/form/Form';

interface IExternalLoginProps {
  setLoginType: (loginType: string) => void;
}

// TODO: Need to discuss with team what data needs to be set in Login Success response
export const ExternalUserLogin = ({ setLoginType }: IExternalLoginProps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const history = useHistory();
  const dispatch = useDispatch();

  const { tangraLoginDetails, tangraLoginError, cwbLoginDetails, cwbLoginError } = useAppSelector(
    ({
      login: { tangraLoginDetails, tangraLoginError, cwbLoginDetails, cwbLoginError },
    }: IApplicationState) => ({
      tangraLoginDetails,
      tangraLoginError,
      cwbLoginDetails,
      cwbLoginError,
    }),
  );

  const dispatcher = {
    showToast: (data: IShowToastPayload) => dispatch(ToastActions.showToast(data)),
    getAuditHistory: () => dispatch(DashboardActions.getAuditHistoryStart()),
    postLogin: (data: ITangraLoginPayload) => dispatch(LoginActions.postTangraLoginStart(data)),
  };

  const displayToast = (description: string, message: string, type: NotificationTypes) =>
    dispatcher.showToast({ description, message, type });

  const getAuditHistory = () => dispatcher.getAuditHistory();
  const postLogin = (data: ITangraLoginPayload) => dispatcher.postLogin(data);

  const onSubmit = async (e : any) => {
    e.preventDefault()
   
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          // ...
      })
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          // ..
      });

 
  }
  const checkTokenIsValid = (access_token : string) => {
    const accessToken : any  = jwt_decode(access_token);
    console.log("access token is :",accessToken);
    return (accessToken?.exp > Math.round(Date.now()/1000));
    
  }
  const handleSubmit = (e: React.SyntheticEvent<Element, Event>) => {
    form.setFields([
      {
        name: 'Email',
        errors: []
      }
    ]);
    e.preventDefault();
    //setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user : any = userCredential.user;
            let userObj : any = Object.assign({},user);
            
            if(checkTokenIsValid(userObj?.accessToken)){
              storage.setItem(
                STORAGE_CONSTANTS.userDetails,
                `{"given_name" : "${userObj.email}",
                 "access_token": "${userObj?.accessToken}",
                 "email":"${userObj.email}",
                 "user_type":"external",
                 "group":["SG-US AD Admin"],"firstLogin":false,
                 "userId":"4ca6d41e-4588-4b0b-a6fe-5547d7817492","first_login_response":false}`,
              );
              history.replace(routes.ingestion);
            }
            //console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if(errorMessage.includes('email')){
              form.setFields([
                {
                  name: 'Email',
                  errors: ['Please enter a valid email'],
                },
              ]);
            }
            else if(errorMessage.includes('invalid-login')){
              form.setFields([
                {
                  name: 'Email',
                  errors: ['Please enter valid credentials'],
                },
                {
                  name : "password",
                  errors: ['Please enter valid credentials']
                }
              ]);
            }
        });

    // Auth.configure({
    //   aws_cognito_region: COGNITO_EXTERNAL.REGION,
    //   aws_user_pools_id: COGNITO_EXTERNAL.USER_POOL_ID,
    //   aws_user_pools_web_client_id: COGNITO_EXTERNAL.APP_CLIENT_ID,
    // });
    if (password && password.length !== 0 && email && email.length !== 0) {
      try {
        storage.setItem(STORAGE_CONSTANTS.userType, UserType.EXTERNAL);
        // postLogin({ username: email, password });
        storage.setItem(STORAGE_CONSTANTS.userName, password);
        // storage.setItem(STORAGE_CONSTANTS.userName, `Admin`);
        storage.setItem(STORAGE_CONSTANTS.userId, `admin@deloitte.com`);
        storage.setItem(STORAGE_CONSTANTS.email, email);
        storage.setItem(STORAGE_CONSTANTS.accessToken, `admin@deloitte.com`);
        storage.setItem(STORAGE_CONSTANTS.refreshToken, `admin@deloitte.com`);
        //history.replace(routes.ingestion);



       
      } catch (error: any) {
        displayToast(error.message, LABELS.error, NotificationTypes.ERROR);
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // if (tangraLoginDetails || tangraLoginError) {
    //   if (tangraLoginDetails) {
    //     dispatch(LoginActions.postCWBLoginStart({ userName: email, password: password }));
    //     storage.setItem(STORAGE_CONSTANTS.userName, `${tangraLoginDetails.email}`);
    //     storage.setItem(STORAGE_CONSTANTS.userId, tangraLoginDetails.userId);
    //     storage.setItem(STORAGE_CONSTANTS.email, `${tangraLoginDetails.email}`);
    //     storage.setItem(STORAGE_CONSTANTS.accessToken, tangraLoginDetails.access_token);
    //     storage.setItem(STORAGE_CONSTANTS.refreshToken, tangraLoginDetails.refresh_token);
    //     storage.setItem(STORAGE_CONSTANTS.userDetails, JSON.stringify(tangraLoginDetails));
    //   } else if (tangraLoginError?.loginErrorType) {
    //     history.replace(routes.login.error);
    //     setLoading(false);
    //   } else {
    //     displayToast(LABELS.loginFailed, LABELS.error, NotificationTypes.ERROR);
    //     setLoading(false);
    //   }
    // }
  }, [tangraLoginDetails, tangraLoginError]);


  const externalLogin = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        layout="vertical"
        className="external-form"
        form={form}
      >
        <Form.Item
          label="Registered Email ID"
          name="Email"
          className="form-label"
          rules={[{ required: true, message: LABELS.requiredEmailErrorMessage }]}
        >
          <Input onChange={(e) => {
             setLoading(false);
             setEmail(e.target.value)}} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="form-label"
          rules={[{ required: true, message: LABELS.requiredPasswordErrorMessage }]}
        >
          <Input.Password onChange={(e) => {
             setLoading(false);
             setPassword(e.target.value)
             }} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            htmlType="submit"
            type="primary"
            className="login-btn"
            onClick={(e) => handleSubmit(e)}
            disabled={loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="external-login-conatiner">
      <div className="external-login-form-container">
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/mnaci_logo.svg`}
          alt="MNACI Logo"
          className="login-logo"
        />
      </div>
        <div>
          {/* <ArrowLeftOutlined className="color-black" onClick={() => setLoginType('')} /> */}
          <span className="external-user-heading">{LABELS.loginAsExternalUser}</span>
        </div>

        {externalLogin()}
      </div>
    </div>
  );
};

export default ExternalUserLogin;

