import React, { useEffect, useMemo } from 'react';
import './DataAugmentation.less';
import { Col, Form, Row } from 'antd';
import Input from 'rc-input';
import { useDispatch } from 'react-redux';
import Flag from '../../../assets/icons/flag.svg';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';
import { PersonaActions } from '../../../libs/store/actions/PersonaActions';
import { IDataAugmentationPayload } from '../../../libs/models/PersonaModels';
import Loader from '../../../components/Loader/Loader';
import { IFormItem, IInitialValue } from '../DiligenceOld/Diligence';

const DataAugmentation = () => {
  const dispatch = useDispatch();
  const { loading, augmentationData } = useAppSelector(
    ({ personaDetails: { loading, augmentationData } }: IApplicationState) => ({
      loading,
      augmentationData,
    }),
  );
  const payload: IDataAugmentationPayload = {
    submissionID: '7641885884291425',
    eventID: '7641873782602153',
    formType: 'cp8538',
  };
  const dispatcher = {
    getAugmentationData: () => dispatch(PersonaActions.getAugmentationData(payload)),
  };

  useEffect(() => {
    dispatcher.getAugmentationData();
  }, []);
  const address = [
    {
      label: 'Address',
      name: 'addr',
      value: augmentationData?.[0].rawEntities?.[0].value || '22-101, Los Angeles, CA',
    },
  ];
  const otherDetails = useMemo(() => {
    return [
      { label: 'Telephone Number', name: 'tel', value: '433-187-1094' },
      {
        label: 'File Name',
        name: 'fileName',
        value: augmentationData?.[0].fileName || '',
      },
      {
        label: 'Submitted On',
        name: 'submittedOn',
        // value: augmentationData?.[0].submittedOn || '',
        value: '11/11/2022',
      },
      {
        label: 'Submission ID',
        name: 'submissionId',
        // value: (augmentationData?.[0].submissionId as string) || '',
        value: '101156342',
      },
      {
        label: 'Event ID',
        name: 'eventId',
        // value: (augmentationData?.[0].eventId as string) || '',
        value: '101156342',
      },
    ];
  }, [augmentationData]);
  return (
    <Loader loaderName="" loading={loading}>
      <div className="data-augmentation-container">
        <Row className="data-augmentation-header">
          <Col span={24}>
            <header>
              <div className="value-container border-right-dashed">
                <img src={Flag} alt="" className="flag-img" />
                <div>
                  <p className="cost">$120,100</p>
                  <p className="grey-text">Original Value</p>
                </div>
              </div>
              <div className="value-container">
                <img src={Flag} alt="" className="flag-img" />
                <div>
                  <p className="cost overridded-cost">$130,256</p>
                  <p className="grey-text">Overidded Value</p>
                </div>
              </div>
            </header>
          </Col>
        </Row>
        <hr className="hr-border-dashed my-2" />
        <Form
          // initialValues={{}}
          layout="vertical"
          onFinish={(val) => {
            // handleSubmit(val);
          }}
          // form={form}
          className="mt-1 case-details-form"
          initialValues={{
            ...[...address, ...otherDetails].reduce((acc, { name, value }: IFormItem) => {
              acc[name] = value;
              return acc;
            }, {} as IInitialValue),
          }}
        >
          <Row gutter={16}>
            {address.map((item) => (
              <Col key={item.label} span={12}>
                <Form.Item label={item.label} name={item.name} validateTrigger="onBlur">
                  <Input className="border-style-form-component" readOnly />
                </Form.Item>
              </Col>
            ))}
            {otherDetails.map((item) => (
              <Col key={item.label} span={6}>
                <Form.Item label={item.label} validateTrigger="onBlur">
                  <Input className="border-style-form-component" readOnly value={item.value} />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </div>
    </Loader>
  );
};

export default DataAugmentation;
