import { Button, Table } from 'antd';
import React from 'react';
import './Attachments.less';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

interface IFormTableProps {
  toggleViewAttachementClick?: () => void;
  tableData: { [key: string]: string }[];
}

const FormsTable = ({ tableData, toggleViewAttachementClick }: IFormTableProps) => {
  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'form_name',
      key: 'form_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <div className="align-item-center">
          <span
            className={`status-pill mr-1 ${
              status === 'Inprogress'
                ? 'status-inprogress'
                : status === 'Completed'
                ? 'status-completed'
                : 'status-awaiting'
            }`}
          />
          {status}
        </div>
      ),
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => {
        return (
          <>
            <Button
              onClick={toggleViewAttachementClick}
              type="text"
              className="view-download-action-btn"
            >
              View
            </Button>
            <Button type="text" className="view-download-action-btn">
              Re-upload
            </Button>
            <Button type="text" className="view-download-action-btn">
              Send Email
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <Table columns={columns} dataSource={tableData} className="form-table" pagination={false} />
  );
};

interface IAttachmentsTableProps {
  toggleViewAttachementClick?: () => void;
}

const AttachmentsTable = ({ toggleViewAttachementClick }: IAttachmentsTableProps) => {
  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'form_name',
      key: 'form_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <div className="align-item-center">
          <span
            className={`status-pill mr-1 ${
              status === 'Inprogress'
                ? 'status-inprogress'
                : status === 'Completed'
                ? 'status-completed'
                : 'status-awaiting'
            }`}
          />
          {status}
        </div>
      ),
    },
    {
      title: 'Last Updated On',
      dataIndex: 'last_updated_on',
      key: 'last_updated_on',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => {
        return (
          <>
            <Button
              onClick={toggleViewAttachementClick}
              type="text"
              className="view-download-action-btn"
            >
              View
            </Button>
            <Button type="text" className="view-download-action-btn">
              Download
            </Button>
          </>
        );
      },
    },
  ];
  const data = [
    {
      key: 'attachment-1',
      form_name: 'Toyota-CP-8538.pdf',
      status: 'Inprogress',
      last_updated_on: '10/05/23',
      comments: 'Broker has to update details',
      forms: [
        {
          form_name: 'Toyota-CP-8538.pdf',
          status: 'Inprogress',
          comments: 'Broker has to resend the form',
        },
      ],
    },
  ];
  return (
    <div className="ins-table-style attachment-container">
      <Table
        columns={columns}
        dataSource={data}
        // expandable={{
        //   expandIcon: ({ expanded, onExpand, record }) =>
        //     expanded ? (
        //       <DownOutlined onClick={(e) => onExpand(record, e)} />
        //     ) : (
        //       <RightOutlined onClick={(e) => onExpand(record, e)} />
        //     ),
        //   expandedRowClassName: () => `expanded-row-antd`,
        //   expandedRowRender: (record) => <FormsTable toggleViewAttachementClick={toggleViewAttachementClick} tableData={record.forms} />,
        //   rowExpandable: (record) => true,
        // }}
      />
      ;
    </div>
  );
};

export default AttachmentsTable;
