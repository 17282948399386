import { CloudServiceProvider } from '../../AppConstants';
import { ILandingZoneState } from '../../libs/store/state/BuildDeployState';
import { ICountLandingZone } from './HomePageConstants';

export const calculateLandingZones = (landingZones: ILandingZoneState[]) => {
  const count: ICountLandingZone = {
    all: 0,
    gcp: 0,
    aws: 0,
    azure: 0,
    oracle: 0,
    openshift: 0,
    multicloud: 0,
  };
  landingZones.forEach((item: ILandingZoneState) => {
    switch (item.cloudServiceProvider) {
      case CloudServiceProvider.AWS:
        count.all++;
        count.aws++;
        break;
      case CloudServiceProvider.GCP:
        count.all++;
        count.gcp++;
        break;
      case CloudServiceProvider.AZURE:
        count.all++;
        count.azure++;
        break;
      case CloudServiceProvider.ORACLE:
        count.all++;
        count.oracle++;
        break;
      case CloudServiceProvider.OPENSHIFT:
        count.all++;
        count.openshift++;
        break;
      case CloudServiceProvider.MULTICLOUD:
        count.all++;
        count.multicloud++;
        break;
      default:
        break;
    }
  });
  return count;
};
