import { IContractCard } from '../../../containers/MNACIDashboard/MNACIDashboard';
import {
  IContractDetails,
  IContractDetailsRequestBody,
  IContractDetailsResponse,
} from '../../../containers/MNACIDashboard/MNACIDashboardModel';
import { IPostContractsPayload, IPostContractsResponse, IUploadContracts } from '../state/ContractState';
import { IStoreAction } from '../StoreHelper';

export enum ContractActionTypes {
  UPLOAD_CONTRACT_START = 'UPLOAD_CONTRACT_START',
  UPLOAD_CONTRACT_SUCCESS = 'UPLOAD_CONTRACT_SUCCESS',
  UPLOAD_CONTRACT_ERROR = 'UPLOAD_CONTRACT_ERROR',
  
  GET_PDF_URL_START = 'GET_PDF_URL_START',
  GET_PDF_URL_SUCCESS = 'GET_PDF_URL_SUCCESS',
  GET_PDF_URL_ERROR = 'GET_PDF_URL_ERROR',
  POST_SEARCH_CONTRACTS_START = 'POST_SEARCH_CONTRACTS_START',
  POST_SEARCH_CONTRACTS_SUCCESS = 'POST_SEARCH_CONTRACTS_SUCCESS',
  POST_SEARCH_CONTRACTS_ERROR = 'POST_SEARCH_CONTRACTS_ERROR',
  GET_CONTRACT_DETAILS_LIST_STARTED = 'GET_CONTRACT_DETAILS_LIST_STARTED',
  GET_CONTRACT_DETAILS_LIST_SUCCESS = 'GET_CONTRACT_DETAILS_LIST_SUCCESS',
  GET_CONTRACT_DETAILS_LIST_ERROR = 'GET_CONTRACT_DETAILS_LIST_ERROR',
  GET_CONTRACT_DETAILS_DATA_STARTED = 'GET_CONTRACT_DETAILS_DATA_STARTED',
  GET_CONTRACT_DETAILS_DATA_SUCCESS = 'GET_CONTRACT_DETAILS_DATA_SUCCESS',
  GET_CONTRACT_DETAILS_DATA_ERROR = 'GET_CONTRACT_DETAILS_DATA_ERROR',
}

export type ContractPayload =
  | IContractCard[]
  | IPostContractsPayload
  | IPostContractsResponse
  | IContractDetailsRequestBody
  | IContractDetailsResponse
  | Error
  | null
  | string
  | IUploadContracts
  | IContractDetails[];

export type ContractAction = IStoreAction<ContractActionTypes, ContractPayload>;

export class ContractActions {
  public static uploadContractStart(payload: IUploadContracts): ContractAction {
    return {
      type: ContractActionTypes.UPLOAD_CONTRACT_START,
      data: payload,
    };
  }
  public static uploadContractSuccess(): ContractAction {
    return {
      type: ContractActionTypes.UPLOAD_CONTRACT_SUCCESS,
    };
  }
  public static uploadContractError(error: Error): ContractAction {
    return {
      type: ContractActionTypes.UPLOAD_CONTRACT_SUCCESS,
      data: error,
    };
  }
  public static getPdfUrlStart(data: string): ContractAction {
    return {
      type: ContractActionTypes.GET_PDF_URL_START,
      data,
    };
  }
  public static getPdfUrlSuccess(data: string): ContractAction {
    return {
      type: ContractActionTypes.GET_PDF_URL_SUCCESS,
      data,
    };
  }
  public static getPdfUrlError(error: Error): ContractAction {
    return {
      type: ContractActionTypes.GET_PDF_URL_ERROR,
      data: error,
    };
  }
  public static postSearchContractStart(data: IPostContractsPayload): ContractAction {
    return {
      type: ContractActionTypes.POST_SEARCH_CONTRACTS_START,
      data,
    };
  }
  public static postSearchContractSuccess(data: IPostContractsResponse | undefined): ContractAction {
    return {
      type: ContractActionTypes.POST_SEARCH_CONTRACTS_SUCCESS,
      data,
    };
  }
  public static postSearchContractError(error: Error): ContractAction {
    return {
      type: ContractActionTypes.POST_SEARCH_CONTRACTS_ERROR,
      data: error,
    };
  }
  public static getContractDetailsListStart(data: IContractDetailsRequestBody): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_LIST_STARTED,
      data,
    };
  }
  public static getContractDetailsListSuccess(data: IContractDetailsResponse): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_LIST_SUCCESS,
      data,
    };
  }
  public static getContractDetailsListError(error: Error): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_LIST_ERROR,
      data: error,
    };
  }
  public static getContractDetailsDataStart(data: string): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_DATA_STARTED,
      data,
    };
  }
  public static getContractDetailsDataSuccess(data:IContractDetails[]): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_DATA_SUCCESS,
      data,
    };
  }
  public static getContractDetailsDataError(error: Error): ContractAction {
    return {
      type: ContractActionTypes.GET_CONTRACT_DETAILS_DATA_ERROR,
      data: error,
    };
  }
}
