import { IStoreAction } from '../StoreHelper';

export enum SidebarActionTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  TOGGLE_HIDDEN_SIDEBAR = 'TOGGLE_HIDDEN_SIDEBAR',
  SET_SIDEBAR_KEY = 'SET_SIDEBAR_KEY',
  TOGGLE_LOGOUT = 'TOGGLE_LOGOUT',
}

export type SidebarActionPayload = boolean | string;

export type SidebarAction = IStoreAction<SidebarActionTypes, SidebarActionPayload>;
export class SidebarActions {
  public static toggleSidebar(data: boolean): SidebarAction {
    return {
      type: SidebarActionTypes.TOGGLE_SIDEBAR,
      data,
    };
  }

  public static toggleHiddenSidebar(data: boolean): SidebarAction {
    return {
      type: SidebarActionTypes.TOGGLE_HIDDEN_SIDEBAR,
      data,
    };
  }

  public static setSidebarKey(data: string): SidebarAction {
    return {
      type: SidebarActionTypes.SET_SIDEBAR_KEY,
      data,
    };
  }

  public static toggleLogout(data: boolean): SidebarAction {
    return {
      type: SidebarActionTypes.TOGGLE_LOGOUT,
      data,
    };
  }
}
