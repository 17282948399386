import { Button, Col, Row, Table } from 'antd';
import React from 'react';
import Attention from '../../../assets/icons/red_attention.svg';
import Tick from '../../../assets/icons/green-tick.svg';
import './DataEnrichment.less';
import DataAugmentation from '../DataAugmentation/DataAugmentation';
interface IDataEnrichmentData {
  applicate_name: string;
  addr_suggested: string;
  addr_original: string;
  updateNeeded: boolean;
}
const DataEnrichment = () => {
  const columns = [
    {
      title: 'Applicate Name',
      dataIndex: 'applicate_name',
      key: 'applicate_name',
    },
    {
      title: 'Address (Suggested Value)',
      // dataIndex: 'addr_suggested',
      key: 'addr_suggested',
      render: (record: IDataEnrichmentData) => {
        return (
          <div className="flex-row-space-between align-item-center">
            <p className="suggested-addr">{record.addr_suggested}</p>{' '}
            {record.updateNeeded ? (
              <img src={Attention} alt="" className="update-icon" />
            ) : (
              <img src={Tick} alt="" className="update-icon" />
            )}
          </div>
        );
      },
    },
    {
      title: 'Address (Original Value)',
      dataIndex: 'addr_original',
      key: 'addr_original',
      //   render: (dateTime: string) => {
      //     return {
      //       children: getFormattedTimestamp(dateTime),
      //     };
      //   },
    },
    {
      title: 'Action',
      // dataIndex: 'action',
      key: 'action',
      render: (record: IDataEnrichmentData) => (
        <Button type="text" className="update-btn-color" disabled={!record.updateNeeded}>
          {' '}
          Update
        </Button>
      ),
    },
  ];
  const data = [
    {
      applicate_name: 'Nirav Sharma',
      addr_suggested: '31 Station Street, San Francisco, CA, 56642',
      addr_original: '31 Station Street, San Francisco, CA, 56642',
      updateNeeded: false,
    },
    {
      applicate_name: 'Nirav Sharma',
      addr_suggested: '32 Park Avenue, San Francisco, CA, 56642',
      addr_original: '329 Street Avenue, San Francisco, CA, 56642',
      updateNeeded: true,
    },
    {
      applicate_name: 'Nirav Sharma',
      addr_suggested: '33 London Road, San Francisco, CA, 55466',
      addr_original: '33 London Road, San Francisco, CA, 55466',
      updateNeeded: false,
    },
    {
      applicate_name: 'Nirav Sharma',
      addr_suggested: '34 Broadway Street, San Francisco, CA, 66442',
      addr_original: '98 Street, San Francisco, CA, 66442',
      updateNeeded: true,
    },
    {
      applicate_name: 'Nirav Sharma',
      addr_suggested: '35 Park Street, San Francisco, CA, 55446',
      addr_original: '35 Park Street, San Francisco, CA, 55446',
      updateNeeded: false,
    },
  ];
  return (
    <Row className="data-enrichment">
      <Col span={24}>
        <DataAugmentation />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={data} />
      </Col>
    </Row>
  );
};

export default DataEnrichment;
