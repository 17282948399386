import React, { Component, ErrorInfo, ReactNode } from 'react';
import './ErrorBoundaryStyles.less';
import { Button, Typography } from 'antd';
import { storage } from '../../libs/services/LocalStorage';
import ErrorBoundaryImage from '../../assets/images/error-boundary.svg';
import { LABELS } from './ErrorBoundaryConstants';

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public state: IState = {
    hasError: false,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Can console log the errors here
  }

  public render() {
    const {
      state: { hasError },
      props: { children },
    } = this;
    if (hasError) {
      return (
        <div className="error-boundary-container">
          <img className="error-boundary-image" src={ErrorBoundaryImage} alt="Error Boundary" />
          <Typography className="error-boundary-title">{LABELS.title}</Typography>
          <Button
            className="error-boundary-button"
            onClick={() => {
              storage.clearAll();
              window.location.reload();
            }}
          >
            {LABELS.buttonText}
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
