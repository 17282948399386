import Modal from 'antd/lib/modal/Modal';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { logoutModalText, timeOutTime } from '../../AppConstants';
import HomePageMobile from '../../containers-mobile/HomePageMobile/HomePageMobile';
import HomePage from '../../containers/HomePage/HomePage';
import { isAuthenticated } from '../../libs/helper/HelperFunctions';
import { storage } from '../../libs/services/LocalStorage';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { routes } from '../../navigation/Routes';
import NotificationSocket from '../NotificationSocket/NotificationSocket';

interface IPrivateRouteProps {
  children: React.ReactNode;
  hideHomePage?: boolean;
  isMobile?: boolean;
  showProjectSelector?: boolean;
  pageTitle?: string;
}
const PrivateRoute = ({ 
    children,
    hideHomePage,
    isMobile = false,
    showProjectSelector = true,
    pageTitle = ''
  }: IPrivateRouteProps) => {
  const [visible, setVisible] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const dispatcher = {
    postSessionToken: () => dispatch(LoginActions.sessionTokenPostStart()),
    logoutTimer: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
  };

  const onIdle = () => {
    setVisible(true);
  };

  const onModalOk = () => {
    dispatcher.logoutTimer(false);
    storage.clearAll();
    history.replace('/');
  };

  const idleTimer = useIdleTimer({ onIdle, timeout: timeOutTime }); // calling the session

  return (
    <>
      <Route
        render={(props) =>
          isAuthenticated() ? (
            hideHomePage ? (
              children
            ) : (
              <NotificationSocket>
                {isMobile && 
                  <HomePageMobile showProjectSelector={showProjectSelector} pageTitle={pageTitle}>
                      {children}
                  </HomePageMobile>}
                {!isMobile && <HomePage>{children}</HomePage>}
              </NotificationSocket>
            )
          ) : (
            <Redirect to={routes.login.viewGeneric} />
          )
        }
      />
      {visible && (
        <Modal
          visible={visible}
          onOk={onModalOk}
          cancelButtonProps={{ hidden: true }}
          closable={false}
          className="logout-modal"
        >
          {logoutModalText}
        </Modal>
      )}
    </>
  );
};

export default PrivateRoute;
