import React from 'react';
import { Col, Form, Row } from 'antd';
import Input from 'rc-input';
import PdfImg from '../../../assets/images/FormPdf.jpg';
import './Forms.less';
import { IFormItem, IInitialValue } from '../DiligenceOld/Diligence';

const Forms = () => {
  const formItems = [
    { label: 'Name', name: 'name', value: 'Luke Dunphy' },
    { label: 'Mailing Address', name: 'mail_addr', value: '22-101, Los Angeles, CA' },
    { label: 'Telephone Number', name: 'number', value: '433-187-1094' },
    { label: 'Web Address', name: 'web_addr', value: 'helloworld@xyz.com' },
    { label: 'Type of Legal Entity', name: 'type_legal_entity', value: 'XYZ Association' },
    { label: 'Proposed Effective Date', name: 'eff_date', value: '12/1/2022' },
    { label: 'Proposed Expiration Date', name: 'eff_date', value: '12/5/2023' },
    { label: 'Form Type', name: 'formType', value: 'Category Name 1' },
    { label: 'Auto Dealer Address', name: 'autoDealerAddr', value: 'cpdjhare0923710293' },
    { label: 'Auto Dealer Operations', name: 'autoDealerOps', value: 'hdfakfha;ksjfha;sfa' },
    {
      label: 'Form Classification ID scores',
      name: 'classificationScores',
      value: '(555) 555-1234',
    },
    { label: 'Auto Dealer Franchised', name: 'autoDealerBased', value: 'Value Name 2' },
    { label: 'Submission ID', name: 'subId', value: '(555) 555-1234' },
    { label: 'Event ID', name: 'eventId', value: '1237169283' },
    // { label: '', name: '', value: '' },
  ];
  return (
    <Row gutter={16} className="ing-form-container">
      <Col span={8} className="overflow-auto">
        {' '}
        <Form
          // initialValues={{}}
          layout="vertical"
          onFinish={(val) => {
            // handleSubmit(val);
          }}
          // form={form}
          className="mt-1 case-details-form"
          initialValues={{
            ...formItems.reduce((acc, { name, value }: IFormItem) => {
              acc[name] = value;
              return acc;
            }, {} as IInitialValue),
          }}
        >
          <Row className="sub-hub-form-styles forms-style">
            {formItems.map((item) => (
              <Col span={24} className="gutter-row" key={item.label}>
                <Form.Item label={item.label} name={item.name} validateTrigger="onBlur">
                  <Input className="border-style-form-component" />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Form>
      </Col>
      <Col span={16}>
        {' '}
        <img src={PdfImg} alt="" className="w-100" />
      </Col>
    </Row>
  );
};

export default Forms;
