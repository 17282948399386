import { Reducer } from 'redux';
import { IServiceCataloguePayload } from '../../models/ServiceCatalogueModel';
import {
  ServiceCatalogueAction,
  ServiceCatalogueActionTypes,
} from '../actions/ServiceCatalogueActions';
import IServiceCatalogueState from '../state/ServiceCatalogueState';

export const initialState: IServiceCatalogueState = {
  catalogueDetails: {} as IServiceCataloguePayload,
  loading: false,
  error: '',
};

export const ServiceCatalogueReducer: Reducer<IServiceCatalogueState> = (
  state = initialState,
  action: ServiceCatalogueAction,
) => {
  switch (action.type) {
    case ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_SUCCESS:
      return {
        ...state,
        catalogueDetails: action.data as IServiceCataloguePayload,
        loading: false,
      };
    case ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };
    default:
      return state;
  }
};
