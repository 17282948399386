export const riskFilters = [
    {
        severity:'Normal',
        category:'Business Climate'
    },
    {
        severity:'Low',
        category:'Bankruptcies'
    },
    {
        severity:'Normal',
        category:'Business Failures'
    },
    {
        severity:'High',
        category:'Business Creation'
    },
    {
        severity:'Low',
        category:'Foreclosures'
    },
    {
        severity:'High',
        category:'Vacancies'
    },
    {
        severity:'Normal',
        category:'Business Change Rules '
    },
]
