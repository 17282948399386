import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Row, Tag } from 'antd';
import React from 'react';
import { ICardsArrayProps } from '../SubHubInsuranceDashboardConstants';
import './ViewDetails.less';
import BreadCrumb from '../BreadCrumbNav/BreadCrumb';
import { routes } from '../../../navigation/Routes';

interface IViewDetailsProps {
  selectedRecord: ICardsArrayProps;
}

const ViewDetails = () => {
  const items = [
    { title: 'Policy Analytics', path: routes.dashboard },
    { title: 'Submission', path: routes.submissions },
    { title: 'Prevodic Auto Insurance' }
  ];
  return (
    <Row className="container">
      <Row className="mb-1">{<BreadCrumb items={items} />}</Row>
      <div className="view-details-container">
        <Row className="view-details-header">
          <div className="header-name">
            <div className="header-name-icon">
              <img
                className="view-details-spider-graph"
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/vd-header-icon.svg`}
                alt="profile icon"
              />
            </div>
            <div className="header-name-subheader">
              <div className="ins-id">
                <Tag className="ins-id-tag">POC001276</Tag>
              </div>
              <div className="ins-name">Prevodic Auto Insurance</div>
            </div>
          </div>
          <div className="header-figures">
            <div className="money-insured-container">
              <div className="money-value-icon">
                <img
                  className="icon-image"
                  src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/vd-cup.svg`}
                  alt="money"
                />
              </div>
              <div className="money-value">
                <div className='line-height'>$120,000</div>
                <div className="money-label">Total Sum</div>
              </div>
            </div>
            <div className="money-insured-container">
              <div className="money-value-icon">
                <img
                  className="icon-image"
                  src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/vd-people.svg`}
                  alt="people"
                />
              </div>
              <div className="money-value">
                <div className='line-height'>Philip Nash</div>
                <div className="money-label">Underwriter</div>
              </div>
            </div>
          </div>
          <div className="contributors-container">
            <div className="contributor-label">CONTRIBUTORS</div>
            <div className="contributor-group">
              <Avatar
                className="vd-avatar"
                size={32}
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/con-1.svg`}
              />
              <Avatar
                className="vd-avatar"
                size={32}
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/con-2.svg`}
              />
              <Avatar
                className="vd-avatar"
                size={32}
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/con-3.svg`}
              />
            </div>
          </div>
          <div className="stage-container">
            <div className="stage-label">STAGE</div>
            <div className="stage-steps">
              <img
                className="icon-image"
                src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/stage.svg`}
                alt="stage"
              />
            </div>
          </div>
          <div className="closure-container">
            <div className="closure-details">
              <div className="closure-details-left">
                <div className="label">Closure Time</div>
                <div className="time">740 min</div>
              </div>
              <div className="closure-details-right">
                <img
                  className="icon-image"
                  src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/cl-meter.svg`}
                  alt="meter"
                />
              </div>
            </div>
            <div className="updates-details">
              <div className="updates-details-left">Last updated on 12 Feb’23</div>
              <div className="updates-details-right">
                <Button className="update-button" icon={<EditOutlined />}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Row>
        <Row className="view-details-spider-graph-container">
          <div>
            <img
              className="view-details-spider-graph"
              src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/view-details-graph.svg`}
              alt="spider-graph"
            />
          </div>
        </Row>
        <Row className="view-details-map-graph-container">
          <div>
            <img
              className="view-details-map-graph"
              src={`${process.env.PUBLIC_URL}/config-icons/sub-hub-res/view-details-map-graph.svg`}
              alt="map-graph"
            />
          </div>
        </Row>
      </div>
    </Row>
  );
};

export default ViewDetails;
