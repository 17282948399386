import { Col, Input, Row } from 'antd';
import React from 'react';
import './Email.less';
import EmailIcon from '../../../assets/icons/mail-icon.svg';
import NameIcon from '../../../assets/icons/name-icon.svg';
import SettingIcon from '../../../assets/icons/setting-gear-icon.svg';
import TextArea from 'antd/lib/input/TextArea';
import StagesTabContent from '../../Personas/DataIngestion/StagesTabContent';
import { emailList } from '../NewCaseDrawer';

const EmailExtraction = () => {
  const emailBody = `Hi,

  I have this renewal policy. Need by Date: October 01, 2022 Line of business: comm auto prospect [request] that I would like you to consider. 
  Target premium/range: $486 to $4,783.
  
  Current Carrier: Toyota of Richardson Inc
  Yashasvi Awasthi
  Senior Consultant | Application Modernization and Innovation (AMM)
  Deloitte Consulting India Pvt. Ltd
  Tel/Direct: +1 615 718 3348 | Mobile: +91 888 751 6425
  yawasthi@deloitte.com | www.deloitte.com

  `;
  return (
    <div className="email-extraction-container">
      <Row gutter={[16, 16]}>
        <Col span={24} className="gutter-row">
          <Row className="email-description w-100 ml-0" gutter={[32, 16]}>
            <Col span={6} className="border-right-dashed-dark gutter-row">
              <div className="d-flex">
                <img src={EmailIcon} alt="" className="grey icon-style" />
                <p className="email">yawasthi@deloitte.com</p>
              </div>
              <p className="email-category">Broker Email</p>
            </Col>
            <Col span={6} className="border-right-dashed-dark gutter-row">
              <div className="d-flex">
                <img src={NameIcon} alt="" className="grey icon-style" />
                <p className="email">Awasthi, Yashasvi</p>
              </div>
              <p className="email-category">Broker Name</p>
            </Col>
            <Col span={6} className="border-right-dashed-dark gutter-row">
              <div className="d-flex">
                <img src={EmailIcon} alt="" className="grey icon-style" />

                <p className="email">akshchoudhary@deloitte.com</p>
              </div>
              <p className="email-category">Underwriter Email</p>
            </Col>
            <Col span={6} className="gutter-row">
              <div className="d-flex">
                <img src={NameIcon} alt="" className="grey icon-style" />
                <p className="email">Choudhary, Akshay</p>
              </div>
              <p className="email-category">Underwriter Name</p>
            </Col>
            <Col span={4} className="gutter-row">
              <div className="d-flex">
                <img src={SettingIcon} alt="" className="icon-style" />
                <p className="email font-bold">Renewal</p>
              </div>
              <p className="email-category">Type Of Business</p>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="gutter-row">
          <hr className="hr-border-dashed" />
        </Col>
        <Col span={24} className="gutter-row">
          <p className='font-16'>
            <b className="mb-0-dot-5">Email Subject</b>
          </p>
          <Input value={emailList[0].emailName} readOnly/>
        </Col>
        <Col span={24}>
          <p className="font-16">
            <b>Email Body</b>
          </p>
          <TextArea value={emailBody} rows={7} readOnly/>
        </Col>
        <Col span={24}>
          {/* <p className='font-16'>
            <b>Stages</b>
          </p> */}
          {/* <TextArea value={emailBody} rows={7} /> */}
          <StagesTabContent />
        </Col>
      </Row>
    </div>
  );
};

export default EmailExtraction;
