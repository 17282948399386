import { Environment } from '../AppConstants';
import { ICognitoProps } from '../libs/models/LoginModels';


export const congnitoConfiguration = (environment: string) => {
  let COGNITO: ICognitoProps;
  if (environment === Environment.DEV) {
    COGNITO = {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_7m7TuI2xt',
      APP_CLIENT_ID: '1eg1r16jf89s0jsshu4mi1s898',
      DOMAIN: 'https://tangra.auth.us-east-1.amazoncognito.com',
      SCOPE: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
      REDIRECT_SIGN_IN: 'https://mnaci.dca.deloitte.com/authenticate',
      REDIRECT_SIGNOUT: 'https://mnaci.dca.deloitte.com/login',
      RESPONSE_TYPE: 'code',
      CWB: {
        APP_CLIENT_ID: '6l58c8clcmvn03n8bb7n9u8t3i',
        DOMAIN: 'https://cwb.auth.us-east-1.amazoncognito.com',
        REDIRECT_SIGN_IN: 'https://tangradev.deloitte.com/authenticate/cwb',
        RESPONSE_TYPE: 'code',
      }
    };
  } else if (environment === Environment.LOCALHOST) {
    COGNITO = {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_7m7TuI2xt',
      APP_CLIENT_ID: '1eg1r16jf89s0jsshu4mi1s898',
      DOMAIN: 'https://tangra.auth.us-east-1.amazoncognito.com',
      SCOPE: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
      REDIRECT_SIGN_IN: 'http://localhost:3000/authenticate',
      REDIRECT_SIGNOUT: 'http://localhost:3000/login',
      RESPONSE_TYPE: 'code',
      CWB: {
        APP_CLIENT_ID: '6l58c8clcmvn03n8bb7n9u8t3i',
        DOMAIN: 'https://cwb.auth.us-east-1.amazoncognito.com',
        REDIRECT_SIGN_IN: 'http://localhost:3000/authenticate/cwb',
        RESPONSE_TYPE: 'code',
      }
    };
  } 
   else if (environment === Environment.DEMO) {
    COGNITO = {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_7m7TuI2xt',
      APP_CLIENT_ID: '1eg1r16jf89s0jsshu4mi1s898',
      DOMAIN: 'https://tangra.auth.us-east-1.amazoncognito.com',
      SCOPE: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
      REDIRECT_SIGN_IN: 'https://demo.dca.deloitte.com/authenticate',
      REDIRECT_SIGNOUT: 'https://demo.dca.deloitte.com/login',
      RESPONSE_TYPE: 'code',
      CWB: {
        APP_CLIENT_ID: '6l58c8clcmvn03n8bb7n9u8t3i',
        DOMAIN: 'https://cwb.auth.us-east-1.amazoncognito.com',
        REDIRECT_SIGN_IN: 'https://tangradev.deloitte.com/authenticate/cwb',
        RESPONSE_TYPE: 'code',
      }
    };
  } else {
    COGNITO = {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_7m7TuI2xt',
      APP_CLIENT_ID: '1h61q1cteuie81j6glc056v7q',
      DOMAIN: 'https://tangra.auth.us-east-1.amazoncognito.com',
      SCOPE: ['aws.cognito.signin.user.admin', 'openid', 'email', 'profile'],
      REDIRECT_SIGN_IN: 'https://www.dca.deloitte.com/authenticate',
      REDIRECT_SIGNOUT: 'https://www.dca.deloitte.com/login',
      RESPONSE_TYPE: 'code',
      CWB: {
        APP_CLIENT_ID: '6l58c8clcmvn03n8bb7n9u8t3i',
        DOMAIN: 'https://cwb.auth.us-east-1.amazoncognito.com',
        REDIRECT_SIGN_IN: 'https://tangra.deloitte.com/authenticate/cwb',
        RESPONSE_TYPE: 'code',
      }
    };
  }
  return COGNITO;
};

export const COGNITO_EXTERNAL = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_918vSUxXm',
  APP_CLIENT_ID: '4cvp40l7pgjj8821k3j769osa4',
};
