import { Reducer } from 'redux';
import {
  ICompliancePolicies,
  IComplianceSummaryData,
  IIndustryCompliancePolicies,
} from '../../models/SecurityGovernanceModels';
import {
  SecurityGovernanceAction,
  SecurityGovernanceActionsTypes,
} from '../actions/SecurityGovernanceActions';
import ISecurityGovernanceState from '../state/SecurityGovernanceState';
export const initialState: ISecurityGovernanceState = {
  complianceDetails: {} as ICompliancePolicies,
  complianceSummaryDetails: {
    compliantGraph: [
      {
        name: '',
        value: 0,
      },
      {
        name: '',
        value: 0,
      },
      {
        name: '',
        value: 0,
      },
    ],
  } as IComplianceSummaryData,
  loading: false,
  industryComplianceDetails: {} as IIndustryCompliancePolicies,
  error: '',
};

export const SecurityGovernanceReducer: Reducer<ISecurityGovernanceState> = (
  state = initialState,
  action: SecurityGovernanceAction,
) => {
  switch (action.type) {
    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_SUCCESS:
      return {
        ...state,
        complianceDetails: action.data as ICompliancePolicies,
        loading: false,
      };
    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_DETAILS_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };
    case SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_SUCCESS:
      return {
        ...state,
        industryComplianceDetails: action.data as IIndustryCompliancePolicies,
        loading: false,
      };
    case SecurityGovernanceActionsTypes.GET_INDUSTRY_COMPLIANCE_DETAILS_ERROR:
      return {
        ...state,
        error: action.data as Error,
        loading: false,
      };

    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_STARTED:
      return {
        ...state,
      };

    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        complianceSummaryDetails: action.data as IComplianceSummaryData,
      };
    case SecurityGovernanceActionsTypes.GET_COMPLIANCE_SUMMARY_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    default:
      return state;
  }
};
