import { Reducer } from 'redux';
import { IUserResponse } from '../../models/UserModels'
import { ChatbotAction, ChatbotActionTypes, ChatbotActions } from '../actions/ChatbotActions';
import IChatbotState from '../state/ChatbotState';
import { IChatbotResponseType } from '../../models/ChatbotModels';

export const initialState: IChatbotState = {
  error: '',
  loading: false,
  response: undefined,
};

export const chatbotReducer: Reducer<IChatbotState> = (state = initialState, action: ChatbotAction) => {
  switch (action.type) {
    case ChatbotActionTypes.SEND_QUERY_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case ChatbotActionTypes.SEND_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        response: action.data as IChatbotResponseType
      };
    case ChatbotActionTypes.SEND_QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
