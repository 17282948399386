import { Typography } from 'antd';
import React from 'react';
import AccountCreated from '../../assets/icons/account-notification.svg';
import AccountSuspended from '../../assets/icons/account-suspended.svg';
import { getFormattedTimestamp, timeSince } from '../../libs/helper/HelperFunctions';
import { IEmailNotification, IEmailNotificationType } from '../../libs/models/NotificationModels';
import { EmailNotificationDrawerLabels as LABELS } from './EmailNotificationDrawerConstants';
import './EmailNotificationDrawerStyles.less';

interface IEmailNotificationCardProps {
  notification: IEmailNotification;
}

const iconHelper = (notificationType: IEmailNotificationType) => {
  switch (notificationType) {
    case IEmailNotificationType.ACCOUNT_CREATED:
      return AccountCreated;
    case IEmailNotificationType.PROJECT_CREATED:
      return AccountCreated;
    case IEmailNotificationType.PROJECT_SUSPENDED:
      return AccountSuspended;
    case IEmailNotificationType.PROJECT_EDITED:
      return AccountCreated;
    default:
      return '';
  }
};

const textHelper = (
  notificationType: IEmailNotificationType,
  account: string,
  project: string,
  username: string,
  dateTime: string,
) => {
  switch (notificationType) {
    case IEmailNotificationType.PROJECT_CREATED:
      return (
        <div>
          {username}
          {LABELS.createdProject}
          <strong> {project}</strong>
          {LABELS.under}
          <strong>{account}</strong>
          {LABELS.on}
          {getFormattedTimestamp(dateTime)}
        </div>
      );
    case IEmailNotificationType.ACCOUNT_CREATED:
      return (
        <div>
          {username}
          {LABELS.createAccount}
          <strong>{account}</strong>
          {LABELS.on}
          {getFormattedTimestamp(dateTime)}
        </div>
      );
    case IEmailNotificationType.PROJECT_SUSPENDED:
      return (
        <div>
          {username}
          {LABELS.createdProject}
          <strong>{project}</strong>
          {LABELS.under}
          <strong>{account}</strong>
          {LABELS.on}
          {getFormattedTimestamp(dateTime)}
        </div>
      );
    case IEmailNotificationType.PROJECT_EDITED:
      return (
        <div>
          {username}
          {LABELS.editedProject}
          <strong>{project}</strong>
          {LABELS.under}
          <strong>{account}</strong>
          {LABELS.on}
          {getFormattedTimestamp(dateTime)}
        </div>
      );
    default:
      return '';
  }
};

const EmailNotificationCard = ({ notification }: IEmailNotificationCardProps) => {
  const { notificationType, account, project, username, dateTime } = notification;
  return (
    <div className="email-notification">
      <img src={iconHelper(notificationType)} className="email-notification-icon" alt="" />
      <div className="email-notification-content">
        <Typography className="email-notification-title">
          {textHelper(notificationType, account, project, username, dateTime)}
        </Typography>
        <Typography className="email-notification-time">{timeSince(new Date(dateTime))}</Typography>
      </div>
    </div>
  );
};

export default EmailNotificationCard;
