import Drawer from 'rc-drawer';
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader/Loader';
import CloseIcon from '../../assets/icons/cross.svg';
import './Drawer.less';
import FormSubmissionIcon from '../../assets/icons/FormSub.svg';
import DataIngestionIcon from '../../assets/icons/DataIng.svg';
import DataAugIcon from '../../assets/icons/DataAug.svg';
import DeligenceIcon from '../../assets/icons/Diligence.svg';
import AnalyticsIcon from '../../assets/icons/Analytics.svg';
// import ComingSoon from '../../assets/images/comingsoon.jpg';
import ComingSoon from '../../assets/images/coming-soon.png';
import DataIngestion from './DataIngestion/DataIngestion';
import DataAugmentation from './DataAugmentation/DataAugmentation';
import Diligence from './Diligence/Diligence';
import SideNav from './SideNav';
import FormSubmission from './FormSubmission/FormSubmission';
import DataEnrichment from './DataEnrichment/DataEnrichment';

interface IPersonaDrawerProps {
  visible?: boolean;
  setVisible?: () => void;
}
const PersonaDrawer = ({ visible, setVisible }: IPersonaDrawerProps) => {
  const [activeItem, setActiveItem] = useState('Form Submission');
  const navItems = [
    {
      title: 'Form Submission',
      icon: FormSubmissionIcon,
      window: <FormSubmission setActiveItem={setActiveItem} />,
      // window: (
      //   <div className="flex-center h-100">
      //     <img src={ComingSoon} alt="" className="coming-soon-style" />
      //   </div>
      // ),
    },
    { title: 'Data Ingestion', icon: DataIngestionIcon, window: <DataIngestion /> },
    { title: 'Data Enrichment', icon: DataAugIcon, window: <DataEnrichment /> },
    // { title: 'Data Augmentation', icon: DataAugIcon, window: <DataAugmentation /> },
    // { title: 'Diligence/ Quote Sendoff', icon: DeligenceIcon, window: <Diligence /> },
    { title: 'Diligence/ Quote Sendoff', icon: DeligenceIcon, window: <Diligence /> },
    {
      title: 'Analytics/ BI',
      icon: AnalyticsIcon,
      window: (
        <div className="flex-center h-100">
          <img src={ComingSoon} alt="" className="coming-soon-style" />
        </div>
      ),
    },
  ];

  const history = useHistory();
  return (
    <Drawer
      placement={'right'}
      width={'100%'}
      //   closable={false}
      open={true}
      key={'right'}
      className="persona-drawer"
    >
      <div>
        <div className="drawer-header-container">
          <div className="drawer-title">
            <div onClick={() => history.replace('/underwriter')}>
              <img src={CloseIcon} className="close-icon" alt="close-icon" />
            </div>
            <div className="drawer-title-text">{'Case Reference Number - ID 101156342'}</div>
            <span className="status-pill">Pending for Underwriter Review</span>
          </div>
        </div>
      </div>
      <Loader loaderName="" loading={false}>
        <Row className="drawer-body" gutter={16}>
          <Col span={7} className="gutter-row">
            <SideNav navItems={navItems} activeItem={activeItem} setActiveItem={setActiveItem} />
          </Col>
          <Col span={17} className="gutter-row selected-window-container">
            {navItems.filter((item) => item.title === activeItem)[0].window}
          </Col>
        </Row>
      </Loader>
    </Drawer>
  );
};

export default PersonaDrawer;
