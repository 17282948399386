import { isNil } from 'lodash';
import { CloudServiceProvider } from '../../../AppConstants';
import { listenerApiHelper, uploadFile } from '../../helper/HelperFunctions';
import {
  IGetPresignedURLResponse,
  IGetPresignedURLToUploadResponse,
  IUploadFileBody,
} from '../../models/BuildDeployModels';
import { IConfigureWorkLoadsPattern } from '../../models/DashboardModels';
import { storage, STORAGE_CONSTANTS } from '../../services/LocalStorage';
import { ILandingZoneErrorResponse } from '../../store/actions/BuildDeployActions';
import {
  IArchitectureCataloguePayload,
  IArchitectureCatalogueResponse,
  ICloudLandingZoneKeysPayload,
  ICloudLandingZoneKeysResponse,
  IConfigureDeployCloudPayload,
  IConfigureDeployCloudThroughConsolePayload,
  IConfigureLandingZoneRequestPayload,
  ICreateYamlPayload,
  IDeleteLandingZonePayload,
  IDeleteWorkLoadPatternPayload,
  IDeploymentLogsResponse,
  IDeploymentsResponse,
  IEnvironmentsDeployed,
  ILandingZoneState,
  IPipelinesAnalyticsResponse,
  IPipelinesCummulativeAnalyticsResponse,
  IUploadPayload,
  IYamlContent,
  IAppStreamResponse,
  IDeployActivityHistory,
  IFormInterface,
  IIndustryYamlPayload,
  IGetKeysRequestBody,
} from '../../store/state/BuildDeployState';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class BuildDeployService {
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.BuildDeploy,
    ServerType.dca,
  );
  // To be Add later to call DCAAPI
  // private readonly dcaAdminService: ApiServiceBase = new ApiService(
  //   ServiceType.None,
  //   ServerType.dcaAdmin,
  // );
  private readonly tangraService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.tangra,
  );
  private readonly dcpService: ApiServiceBase = new ApiService(ServiceType.DCP, ServerType.dcp);

  public getLandingZone(projectId: string): Promise<ILandingZoneState[]> | ILandingZoneState[] {
    const userId = storage.getItem(STORAGE_CONSTANTS.userId);
    let route: string[] = [];
    if (!isNil(userId)) route = [`${API_ROUTE.LIST_LANDING_ZONES}`];
    return this.tangraService.get({ route: route, query: { project: projectId } });
  }

  //TODO : Change the Post Method to Delete with user Id instead
  public destroyLaningZone(payload: IDeleteLandingZonePayload): Promise<string> | string {
    return this.service.delete({ route: [`${[API_ROUTE.DELETE]}/${payload.landingZoneId}`] });
  }

  public destroyWorkLoadPattern(payload: IDeleteWorkLoadPatternPayload): Promise<string> | string {
    const route = [
      `${API_ROUTE.DESTROY_WORKLOAD_PATTERN}/${payload.landingZoneId}/${payload.workloadPatternId}`,
    ];
    return this.service.delete({ route: route });
  }

  public checkLandingZoneStatus(landingZoneId: string) {
    const route: string = `${API_ROUTE.LANDING_ZONE_STATUS}${landingZoneId}`;
    return listenerApiHelper(route);
  }

  public checkMicroServicesPatternStatus(microServiceId: string) {
    const route: string = `${API_ROUTE.LANDING_ZONE_STATUS}${microServiceId}`;
    return listenerApiHelper(route);
  }

  public uploadArchitectureFile(url: string, body: IUploadFileBody): Promise<Response> {
    return uploadFile(url, body);
  }

  public startUploadFunc(
    payload: IConfigureLandingZoneRequestPayload,
  ): Promise<ILandingZoneState> | ILandingZoneState {
    const uploadData = payload.uploadData as IUploadPayload;
    return this.service.postMultipart(
      {
        route:
          payload?.providerName === CloudServiceProvider.MULTICLOUD
            ? [API_ROUTE.CREATE_MULTI_CLOUD]
            : [API_ROUTE.CREATE_LANDING_ZONE],
      },
      [uploadData.userdata, uploadData.file],
    );
  }

  public uploadMicroservicesYamlFnc(payload: IConfigureWorkLoadsPattern): Promise<string> | string {
    const uploadData = payload.uploadData as IUploadPayload;
    return this.service.postMultipart({ route: [API_ROUTE.CREATE_MICROSERVICES] }, [
      uploadData.userdata,
      uploadData.file,
    ]);
  }

  public getConfigURL(
    filename: string,
  ): Promise<IGetPresignedURLResponse> | IGetPresignedURLResponse {
    return this.tangraService.get({
      route: [`${API_ROUTE.GET_PRESIGNED_URL}`],
      query: { filename },
    });
  }

  //Service to download the image with given name
  public getDownloadURL(data: {
    filename: string;
    givenname: string;
  }): Promise<IGetPresignedURLResponse> | IGetPresignedURLResponse {
    const { filename, givenname } = data;
    return this.tangraService.get({
      route: [`${API_ROUTE.GET_PRESIGNED_URL}`],
      query: { filename, givenname },
    });
  }
  public getYamlContent(filename: string): Promise<IYamlContent> | IYamlContent {
    return this.tangraService.get({
      route: [`${API_ROUTE.yamlContent}`],
      query: { filename },
    });
  }

  public getDefaultTemplateYamlContent(
    data: ICloudLandingZoneKeysPayload,
  ): Promise<IYamlContent> | IYamlContent {
    return this.tangraService.post({ route: [API_ROUTE.createLandingZone] }, data);
  }

  public getIndustryYamlContent(data: IIndustryYamlPayload): Promise<string> | string {
    const { cloudType, industry_assignment_name } = data;
    return this.tangraService.post(
      {
        route: [`${API_ROUTE.INDUSTRY_LZ_YAML}`],
        query: { cloudType },
      },
      { industry_assignment_name },
    );
  }

  public getPresignedURLToUpload(
    filename: string,
    payload?: IArchitectureCataloguePayload,
  ): Promise<IGetPresignedURLToUploadResponse> | IGetPresignedURLToUploadResponse {
    return this.tangraService.post(
      {
        route: [`${API_ROUTE.GET_PRESIGNED_URL}`],
        query: { filename },
      },
      payload,
    );
  }

  public getLandingZoneError(
    landingZoneId: string,
  ): Promise<ILandingZoneErrorResponse> | ILandingZoneErrorResponse {
    return this.service.get({
      route: [`${API_ROUTE.LANDING_ZONE_ERROR}/${landingZoneId}`],
    });
  }
  public createLandingZoneService(
    payload: ICreateYamlPayload,
  ): Promise<{ body: string }> | { body: string } {
    return this.tangraService.post(
      {
        route: [`${API_ROUTE.createLandingZone}`],
      },
      payload,
    );
  }
  public getEnvironmentsDeployedService(
    data: string,
  ): Promise<IEnvironmentsDeployed[]> | IEnvironmentsDeployed[] {
    return this.tangraService.get({
      route: [`${API_ROUTE.ANALYTICS_CLOUD_CSP}`],
      query: { project: data },
    });
  }
  public getLandingZoneDeploymentStatusServive(
    data: string,
  ): Promise<IEnvironmentsDeployed[]> | IEnvironmentsDeployed[] {
    return this.tangraService.get({
      route: [`${API_ROUTE.ANALYTICS_CLOUD_STATUS}`],
      query: { project: data },
    });
  }
  public getArchitectureListServive():
    | Promise<IArchitectureCatalogueResponse[]>
    | IArchitectureCatalogueResponse[] {
    return this.tangraService.get({
      route: [`${API_ROUTE.GET_S3_FILES}`],
    });
  }
  public setArchitectureViewCount(id: string): Promise<string> | string {
    return this.tangraService.patch({ route: [API_ROUTE.GET_S3_FILES] }, { Id: id });
  }
  public setArchitectureDiagram(
    data: IArchitectureCataloguePayload,
  ): Promise<IArchitectureCatalogueResponse> | IArchitectureCatalogueResponse {
    return this.tangraService.post({ route: [API_ROUTE.IMAGE_UPLOAD] }, data);
  }
  public getPipelineAnalyticsService():
    | Promise<IPipelinesAnalyticsResponse[]>
    | IPipelinesAnalyticsResponse[] {
    return this.tangraService.get({
      route: [API_ROUTE.ANALYTICS_PIPELINE],
    });
  }
  public getPipelineCummulativeAnalyticsService():
    | Promise<IPipelinesCummulativeAnalyticsResponse[]>
    | IPipelinesCummulativeAnalyticsResponse[] {
    return this.tangraService.get({
      route: [API_ROUTE.ANALYTICS_PIPELINE_CUMULATIVE],
    });
  }
  public getCloudLandingZoneKeyservice(
    data: IGetKeysRequestBody,
  ): Promise<ICloudLandingZoneKeysResponse[]> | ICloudLandingZoneKeysResponse[] {
    if(data.iac){
      return this.tangraService.get({
        route: [API_ROUTE.GET_LANDING_ZONE_KEYS],
        query: { cloudType: data.csp , iac: data.iac },
      });
    }
    else{
      return this.tangraService.get({
        route: [API_ROUTE.GET_LANDING_ZONE_KEYS],
        query: { cloudType: data.csp },
      });
    }
  }
  public configureDeployCloudService(
    data: IConfigureDeployCloudPayload,
  ): Promise<{ instanceId: string }> | { instanceId: string } {
    return this.tangraService.post(
      {
        route: [API_ROUTE.WORKFLOW_START],
      },
      data,
    );
  }
  public configureDeployCloudThroughConsoleService(
    data: IConfigureDeployCloudThroughConsolePayload,
  ): Promise<{ status: string }> | { status: string } {
    return this.tangraService.post(
      {
        route: [API_ROUTE.WORKFLOW_CONSOLE_START],
      },
      data,
    );
  }
  public setCloudLandingZoneKeyservice(
    data: ICloudLandingZoneKeysPayload,
  ): Promise<string> | string {
    return this.tangraService.post({ route: [API_ROUTE.createLandingZone] }, data);
  }
  public getDeploymentsList(
    projectId: string,
  ): Promise<IDeploymentsResponse[]> | IDeploymentsResponse[] {
    let route: string[] = [];
    route = [`${API_ROUTE.WORKFLOW}/${projectId}`];
    return this.tangraService.get({ route: route });
  }

  public cloudLandingZoneDeployStatusService(
    id: string,
  ): Promise<{ state: string }> | { state: string } {
    let route: string[] = [];
    // keeping commented code for later use
    //const landingZoneId: string = 'c6521ec6-9170-496a-a7c2-53015dda012a';
    route = [`${API_ROUTE.WORKFLOW_STATUS}/${id}`];
    return this.dcpService.get({ route: route });
  }

  public getDeploymentAuditList(
    landingZoneId: string,
    workflowId: string,
  ): Promise<IDeploymentLogsResponse[]> | IDeploymentLogsResponse[] {
    let route: string[] = [];
    route = [`${API_ROUTE.LANDINGZONE_WORKFLOW_LOGS}/${landingZoneId}/${workflowId}`];
    return this.tangraService.get({ route: route });
  }

  public getCoftaUrl(): Promise<IAppStreamResponse> | IAppStreamResponse {
    return this.tangraService.get({
      route: [`${API_ROUTE.GET_COFTA_URL}`],
    });
  }

  public updateLandingZoneDeployLogs(
    status: string,
    landinZoneId: string,
    workflowId: string,
    instanceId: string,
  ): Promise<IDeployActivityHistory> | IDeployActivityHistory {
    return this.tangraService.post(
      {
        route: [`${API_ROUTE.DEPLOY_INSTANCE}/${landinZoneId}/${workflowId}/${instanceId}`],
        query: { status },
      },
      {},
    );
  }
  public getMultiCloudYamlContent(payload: IFormInterface): Promise<IYamlContent> | IYamlContent {
    return this.tangraService.post(
      {
        route: [`${API_ROUTE.multiCloudYaml}`],
      },
      payload,
    );
  }
}

const buildDeployService = new BuildDeployService();
export default buildDeployService;
