import { IChatbotResponseType } from '../../models/ChatbotModels';
import { IStoreAction } from '../StoreHelper';

export enum ChatbotActionTypes {
  SEND_QUERY_START = 'SEND_QUERY_START',
  SEND_QUERY_SUCCESS = 'SEND_QUERY_SUCCESS',
  SEND_QUERY_ERROR = 'SEND_QUERY_ERROR',
}

export type ChatbotActionPayload = string | IChatbotResponseType | Error | null;

export type ChatbotAction = IStoreAction<ChatbotActionTypes, ChatbotActionPayload>;

export class ChatbotActions {
  public static sendQueryStart(query: string): ChatbotAction {
    return {
      type: ChatbotActionTypes.SEND_QUERY_START,
      data: query,
    };
  }
  public static sendQuerySuccess(data: IChatbotResponseType): ChatbotAction {
    return {
      type: ChatbotActionTypes.SEND_QUERY_SUCCESS,
      data,
    };
  }
  public static sendQueryError(error: Error): ChatbotAction {
    return {
      type: ChatbotActionTypes.SEND_QUERY_ERROR,
      data: error,
    };
  }
}
