import {
  ICreateUserRequestBody,
  IEditUserRequestBody,
  IUserManagementObject,
} from '../../models/UserManagementModel';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class UserService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public createUser(data: ICreateUserRequestBody): Promise<string> | string {
    return this.service.post({ route: [`${API_ROUTE.CREATE_USER}`] }, data);
  }
  public getUser(): Promise<IUserManagementObject> | IUserManagementObject {
    // const userId = storage.getItem(STORAGE_CONSTANTS.userId);
    return this.service.get({ route: [`${API_ROUTE.GET_USER_MANAGEMENT_DETAILS}`] });
  }
  public updateUser(data: IEditUserRequestBody): Promise<string> | string {
    return this.service.patch({ route: [`${API_ROUTE.CREATE_USER}`] }, data);
  }
}

const userManagementService = new UserService();
export default userManagementService;
