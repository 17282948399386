import { Button, Col, Popover, Row, Typography } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import React, { ReactElement, useState } from 'react';
import CardContainer from '../../../components/CardContainer/CardContainer';
import './RightWidget.less';
import {SubHubDashboardLabels as LABELS} from '../SubHubInsuranceDashboardConstants';
interface IWidget {
    headerTitle: string;
    content: ReactElement;
  }
const RightWidget = () => {
    const [isWidgetPopoverOpen, setIsWidgetPopoverOpen] = useState(false);

    const widgets = [
        {
        headerTitle: 'Average Handling Time',
        content: <img src={`${process.env.PUBLIC_URL}/hubSub/dummyImages/handlingTime.png`} alt={'handlingTime'} />,
        },
        {
        headerTitle: 'Median Time',
        content: <img src={`${process.env.PUBLIC_URL}/hubSub/dummyImages/medianTime.png`} alt={'medianTime'} />,
        },
        {
        headerTitle: 'Average Policy Issued',
        content: <img src={`${process.env.PUBLIC_URL}/hubSub/dummyImages/policy.png`} alt={'policy'} />,
        },
        {
        headerTitle: 'Average Insurance Claimed',
        content: <img src={`${process.env.PUBLIC_URL}/hubSub/dummyImages/insurance.png`} alt={'insurance'} />,
        },
    ];

    const sumData = [
        {
            icon: 'inquiry',
            value: 2500,
            title: 'Inquiry'
        },
        {
            icon: 'quoted',
            value: 1750,
            title: 'Quoted'
        },
        {
            icon: 'bond',
            value: 1600,
            title: 'Bond'
        },
    ]

    return (
    <div className='sub-hub-dashboard-right-widget'>
        <CardContainer customclassname="right-widget-container">
            <div className="right-widget-card-title-container">
                <Typography className="right-widget-card-title">
                    <strong>{LABELS.title}</strong>
                </Typography>
                <Popover
                    content={
                    <div>
                    </div>
                    }
                    trigger="click"
                    visible={isWidgetPopoverOpen}
                    placement="bottomRight"
                    onVisibleChange={(open: boolean) => setIsWidgetPopoverOpen(open)}
                >
                    <Button type="link"
                        icon={<img src={`${process.env.PUBLIC_URL}/hubSub/icons/appStore.png`} alt={'insurance'} />}
                    />
                </Popover>
            </div>
            {/* <Row>
                {sumData.map((item, idx) => (
                    <Col span={8} className="sum-container" key={idx}>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/hubSub/icons/${item.icon}.svg`} alt={'insurance'} />
                        </div>
                        <div className="sum-details">
                            <Typography className="sum-count">
                                {item.value}
                            </Typography>
                            <Typography className="sum-title">
                                {item.title}
                            </Typography>
                        </div>
                    </Col>
                ))}
            </Row> */}
            <div className="right-widget-content">
                {widgets.map((widget, idx) => (
                    <div key={idx} className="right-widget-item">
                        <Typography className="right-widget-title">
                            <strong>{widget.headerTitle}</strong>
                        </Typography>
                        <div className="right-widget-item-content">
                            {widget.content}
                        </div>
                    </div>
                ))}
            </div>
        </CardContainer>
    </div>
  );
};

export default RightWidget;
