import {
  I5YrPlDataResponse,
  IAnalyticsChartResponse,
  IChartPayload,
  IComputeCostDataResponse,
  IStorageCostDataResponse,
} from '../../models/AnalyticsModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServiceType, ServerType } from '../ServiceType.data';

const serviceType = ServiceType.TCO;

export class AnalyticsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.cwb);
  private readonly assessmentService: ApiServiceBase = new ApiService(
    ServiceType.OMA,
    ServerType.cwb,
  );

  public getChartData(
    payload: IChartPayload,
  ): Promise<IAnalyticsChartResponse> | IAnalyticsChartResponse {
    return this.service.post({ route: [API_ROUTE.CWB.GET_CHART_DATA] }, payload);
  }

  public get5YrPlData(payload: IChartPayload): Promise<I5YrPlDataResponse> | I5YrPlDataResponse {
    return this.service.post({ route: [API_ROUTE.CWB.GET_FIVE_YR_PL_DATA] }, payload);
  }

  public getComputeCostData(
    payload: IChartPayload,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    return this.service.post({ route: [API_ROUTE.CWB.GET_COMPUTE_COST_DATA] }, payload);
  }

  public getStorageCostData(
    payload: IChartPayload,
  ): Promise<IStorageCostDataResponse> | IStorageCostDataResponse {
    return this.service.post({ route: [API_ROUTE.CWB.GET_STORAGE_COST_DATA] }, payload);
  }

  public getAssessmentData(
    payload: string,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    return this.assessmentService.get({
      route: [`${API_ROUTE.CWB.GET_ASSESSMENT_DATA}/${payload}`],
    });
  }
  public getCategoriesData(
    payload: string,
  ): Promise<IComputeCostDataResponse> | IComputeCostDataResponse {
    return this.assessmentService.get({
      route: [`${API_ROUTE.CWB.GET_CATEGORIES_DATA}/${payload}`],
    });
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
