import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';
import AnalyticsState from '../state/AnalyticsState';
import { IThemeState } from '../state/AnalyticsState';
import BuildDeployState from '../state/BuildDeployState';
import CloudSuitabilityState from '../state/CloudSuitabilityState';
import DashboardState from '../state/DashboardState';
import HitAnalytics from '../state/HitAnalyticsState';
import LoginState from '../state/LoginState';
import NotificationState from '../state/NotificationState';
import OperationsState from '../state/OperationsState';
import OverviewState from '../state/OverviewState';
import PipelineState from '../state/PipelineState';
import ISecurityGovernanceState from '../state/SecurityGovernanceState';
import SidebarState from '../state/SidebarState';
import ToastState from '../state/ToastState';
import TrafficAnalyticsState from '../state/TrafficAnalyticsState';
import FeedbackState from '../state/FeedbackState';
import UserManagementState from '../state/UserManagementState';
import UserState from '../state/UserState';
import IDevOpsAnalytics from '../state/DevOpsAnalyticsState';
import IServiceCatalogueState from '../state/ServiceCatalogueState';
import IPersonaState from '../state/PersonaState';
import IChatbotState from '../state/ChatbotState';
import IContractState from '../state/ContractState';
import IMetadataGuardrailState from '../state/MetadataGuardrailState';
import { loginReducer } from './LoginReducer';
import { overviewReducer } from './OverviewReducer';
import { sidebarReducer } from './SidebarReducer';
import { buildDeployReducer } from './BuildDeployReducer';
import { AnalyticsReducer } from './AnalyticsReducer';
import { CloudSuitabilityReducer } from './CloudSuitabilityReducer';
import { dashboardReducer } from './DashboardReducer';
import { NotificationReducer } from './NotificationReducer';
import { operationsReducer } from './OperationsReducer';
import { pipelineReducer } from './PipelineReducer';
import { SecurityGovernanceReducer } from './SecurityGovernanceReducers';
import { toastReducer } from './ToastReducer';
import { UserManagementReducer } from './UserManagementReducer';
import { userReducer } from './UserReducer';
import { HitsAnalyticsReducer } from './WebAnalyticsReducer/HitAnalyticsReducer';
import { feedbackReducer } from './FeedbackReducer';
import { TrafficAnalyticsReducer } from './WebAnalyticsReducer/TrafficAnalyticsReducer';
import { DevOpsAnalyticsReducer } from './WebAnalyticsReducer/DevOpsAnalyticsReducer';
import { ServiceCatalogueReducer } from './ServiceCatalogueReducer';
import { ThemeSettingReducer } from './ThemeSettingReducer';
import { personaReducer } from './PersonaReducer';
import { chatbotReducer } from './ChatbotReducer';
import { contractReducer } from './ContractReducer';
import { metadataGuardrailReducer } from './MetadataGuardrailReducer';
/// <summary>
/// Member names in ApplicationState must match member names in rootReducers
/// </summary>
export interface IApplicationState {
  login: LoginState;
  user: UserState;
  sidebar: SidebarState;
  overview: OverviewState;
  buildDeploy: BuildDeployState;
  analytics: AnalyticsState;
  theme: IThemeState;
  cloudSuitability: CloudSuitabilityState;
  toast: ToastState;
  dashboard: DashboardState;
  operations: OperationsState;
  pipeline: PipelineState;
  userManagement: UserManagementState;
  notification: NotificationState;
  trafficAnalytics: TrafficAnalyticsState;
  hitAnalytics: HitAnalytics;
  feedback: FeedbackState;
  securityGovernance: ISecurityGovernanceState;
  devOpsAnalytics: IDevOpsAnalytics;
  serviceCatalogue: IServiceCatalogueState;
  personaDetails: IPersonaState;
  chatbotResponse: IChatbotState;
  contracts: IContractState;
  metadataGuardrailDetails: IMetadataGuardrailState;
}

const appReducer = combineReducers<IApplicationState>({
  login: loginReducer,
  user: userReducer,
  sidebar: sidebarReducer,
  overview: overviewReducer,
  buildDeploy: buildDeployReducer,
  analytics: AnalyticsReducer,
  theme: ThemeSettingReducer,
  cloudSuitability: CloudSuitabilityReducer,
  toast: toastReducer,
  dashboard: dashboardReducer,
  operations: operationsReducer,
  pipeline: pipelineReducer,
  userManagement: UserManagementReducer,
  notification: NotificationReducer,
  trafficAnalytics: TrafficAnalyticsReducer,
  hitAnalytics: HitsAnalyticsReducer,
  feedback: feedbackReducer,
  securityGovernance: SecurityGovernanceReducer,
  devOpsAnalytics: DevOpsAnalyticsReducer,
  serviceCatalogue: ServiceCatalogueReducer,
  personaDetails: personaReducer,
  chatbotResponse: chatbotReducer,
  contracts: contractReducer,
  metadataGuardrailDetails: metadataGuardrailReducer,
});
const rootReducer: (state: IApplicationState | undefined, action: AnyAction) => IApplicationState =
  (state, action) => {
    // Emptying redux state on LOGOUT_ACTION
    if (action.type === 'TOGGLE_LOGOUT') {
      if (!action.data) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
      }
    }
    return appReducer(state, action);
  };

export const useAppSelector: TypedUseSelectorHook<IApplicationState> = useSelector;

export default rootReducer;
