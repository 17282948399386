import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MetadataGuardrailsActions } from '../../../libs/store/actions/MetadataGuardrailsActions';
import {
  IGuardrailObj,
  IGuardrailRespObj,
  IGuardrailPayload,
  MetadataGuardrailMethod,
} from '../../../libs/models/MetadataGuardrailModels';
import { IApplicationState, useAppSelector } from '../../../libs/store/reducers';

const Guardrails = () => {
  const [tableData, setTableData] = React.useState<IGuardrailObj[]>([]);
  const dispatch = useDispatch();
  const dispatcher = {
    getGuardrailDetails: (payload: IGuardrailPayload) =>
      dispatch(MetadataGuardrailsActions.getGuardrailDetailsStart(payload)),
  };

  const { guardrailDetails } = useAppSelector((state: IApplicationState) => ({
    guardrailDetails: state.metadataGuardrailDetails.guardrailDetails,
  }));

  useEffect(() => {
    dispatcher.getGuardrailDetails({ method: MetadataGuardrailMethod.GUARDRAIL_VIEW });
  }, []);

  const allGuardrails = React.useMemo(() => {
    const groupedGuardrails: IGuardrailObj[] = [];
    guardrailDetails?.forEach((guardrail: IGuardrailRespObj) => {
      const requiredGuardrail = groupedGuardrails.find(
        (groupedGuardrail) => groupedGuardrail.Type === guardrail.Type,
      );
      if (requiredGuardrail && requiredGuardrail.Guardrails) {
        requiredGuardrail.Guardrails = [...requiredGuardrail.Guardrails, guardrail.Guardrails];
      } else {
        groupedGuardrails.push({
          Type: guardrail.Type,
          Description: guardrail.Description,
          Guardrails: [guardrail.Guardrails],
        });
      }
    });
    return groupedGuardrails;
  }, [guardrailDetails]);

  useEffect(() => {
    allGuardrails && setTableData(allGuardrails);
  }, [allGuardrails]);

  return (
    <Row className="metadata-content">
      <Col span={24} className="search-filter-download-container d-flex justify-content-end">
        <div className="search-contacts-input-container">
          <Input
            placeholder="Search type"
            onChange={(e) => {
              guardrailDetails &&
                setTableData(
                  allGuardrails.filter((row) =>
                    row.Type.toLowerCase().includes(e.target.value.toLowerCase()),
                  ),
                );
            }}
            className="search-contacts-input"
            prefix={<SearchOutlined className="search-icon" />}
          />
        </div>
      </Col>
      <Col span={24}>
        <Table
          rowClassName={(record, index) => (index % 2 === 0 ? 'alternate-bg-row' : '')}
          columns={[
            { key: 'Type', dataIndex: 'Type', title: 'Type' },
            { key: 'Description', dataIndex: 'Description', title: 'Description' },
            {
              key: 'Guardrails',
              dataIndex: 'Guardrails',
              title: 'Guardrails',
              render: (rows) => {
                return (
                  <ul>
                    {/* <li className="font-bold" key={rows}>
                      {rows}
                    </li> */}
                    {rows.map((row: string) => (
                      <li className="font-bold" key={row}>
                        {row}
                      </li>
                    ))}
                  </ul>
                );
              },
            },
          ]}
          dataSource={tableData}
          pagination={{
            pageSizeOptions: [3, 5, 10],
            showSizeChanger: true,
            defaultPageSize: 3,
          }}
        />
      </Col>
    </Row>
  );
};

export default Guardrails;
