// Need to be defined explicitly as per React Joyride documentation
export const joyRideGlobalStyles = {
  options: {
    arrowColor: '#D9EF79',
    overlayColor: '#5D5D5D',
    textColor: '#11193C',
    zIndex: 1000,
    primaryColor: '#0fc39a',
  },
};

export enum TooltipButtonLabel {
  BACK = 'Back',
  CLOSE = 'Close',
  NEXT = 'Next',
}
