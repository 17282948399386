import {
  IAccountDetailsResponse,
  IAssessmentStateResponse,
  IAuditHistoryPayload,
  IAuditHistoryResponse,
  IProjectOwnersPayload,
  IProjectOwnersResponse,
} from '../../models/DashboardModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

export class DashboardService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.user, ServerType.tangra);

  private readonly tangraService: ApiServiceBase = new ApiService(
    ServiceType.None,
    ServerType.tangra,
  );

  public getUserProjects(): Promise<IAccountDetailsResponse[]> | IAccountDetailsResponse[] {
    const route: string[] = [`${API_ROUTE.PROJECT}`];
    return this.service.get({ route: route });
  }

  public getAuditDetails(): Promise<IAuditHistoryResponse> | IAuditHistoryResponse {
    const route: string[] = [`${API_ROUTE.AUDIT_HISTORY}`];
    return this.tangraService.get({ route });
  }
  public getProjectOwners({
    accountId,
    projectId,
  }: IProjectOwnersPayload): Promise<IProjectOwnersResponse[]> | IProjectOwnersResponse[] {
    return this.service.get({
      route: [`${API_ROUTE.DASHBOARD}`],
      query: { accountId, projectId },
    });
  }

  public getProjectOwnersCWB({
    projectId,
  }: IProjectOwnersPayload): Promise<IProjectOwnersResponse[]> | IProjectOwnersResponse[] {
    return this.service.get({
      route: [`${API_ROUTE.DASHBOARD}`],
      query: { projectId, cwb_flag: true },
    });
  }

  public saveAuditDetails(
    data: IAuditHistoryPayload,
  ): Promise<IAuditHistoryResponse> | IAuditHistoryResponse {
    const route: string[] = [`${API_ROUTE.AUDIT_HISTORY}`];
    return this.tangraService.post({ route }, data);
  }

  public getAssessmentsDetails(
    data: string,
  ): Promise<IAssessmentStateResponse> | IAssessmentStateResponse {
    const route: string[] = [`${API_ROUTE.GET_ASSESSMENTS_STATE}/${data}`];
    return this.tangraService.get({ route });
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
