import { Button, Dropdown, Layout, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import OverviewHeaderMobile from '../OverViewHeader/OverViewHeaderMobile';
import './AppHeaderMobileStyle.less';
import DownIcon from '../../assets/icons/DownArrowWhite.svg';
import { OverviewHeaderLabels } from '../../containers/Overview/OverviewConstants';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import { ISelectAccountPayload, ISelectProjectPayload } from '../../libs/models/OverviewModels';
import { OverviewActions } from '../../libs/store/actions/OverviewActions';
import { IProjectDetails } from '../../libs/models/DashboardModels';

const { Header } = Layout;

interface IAppHeaderMobileProps {
  isScrolled: boolean;
  showProjectSelector: boolean;
  pageTitle: string;
}

const AppHeaderMobile = ({ isScrolled, showProjectSelector, pageTitle }: IAppHeaderMobileProps) => {
  const dispatch = useDispatch();

  const { selectedProject, selectedAccount, accountsProjectsMapping } = useAppSelector(
    ({
      overview: { selectedProject, selectedAccount },
      dashboard: { accountsProjectsMapping, enabledDiscoverAssessItems },
    }: IApplicationState) => ({
      selectedProject,
      selectedAccount,
      accountsProjectsMapping,
    }),
  );

  const [projects, setProjects] = useState<IProjectDetails[]>([]);

  useEffect(() => {
    // TODO: Use id instead of name
    const account = accountsProjectsMapping.filter(
      ({ accountName }) => accountName === selectedAccount,
    );
    const projects = account.length ? account[0].projects : [];

    setProjects(projects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, accountsProjectsMapping]);

  const dispatcher = {
    setAccount: (data: ISelectAccountPayload) => dispatch(OverviewActions.selectAccount(data)),
    setProject: (data: ISelectProjectPayload) => dispatch(OverviewActions.selectProject(data)),
    toggleSidebar: (data: boolean) => dispatch(SidebarActions.toggleSidebar(data)),
  };

  const setAccount = (data: ISelectAccountPayload) => dispatcher.setAccount(data);
  const setProject = (data: ISelectProjectPayload) => dispatcher.setProject(data);

  const accounts = accountsProjectsMapping.map(({ accountName, accountId, assetType }) => ({
    accountId,
    accountName,
    assetType,
  }));

  const accountsList = (
    <DropdownMenu
      keys={accounts.map(({ accountName }) => accountName)}
      selectedKey={selectedAccount}
      onClick={(e) => setAccount(accounts.filter(({ accountName }) => accountName === e)[0])}
    />
  );
  const projectsList = (
    <DropdownMenu
      keys={projects.map(({ projectName }) => projectName)}
      selectedKey={selectedProject}
      onClick={(e) => setProject(projects.filter(({ projectName }) => projectName === e)[0])}
    />
  );

  return (
    <>
      <Header className={`app-header-container-mobile${isScrolled ? '-bottom-border' : ''}`}>
        <OverviewHeaderMobile />
      </Header>
      <div className="overview-header-mobile-container">
        {showProjectSelector &&
          <>
            <Dropdown className="dropdown-text" overlay={accountsList} trigger={['click']}>
              <Button className="select-account-button-mobile">
                <Typography className="select-button-text-mobile">
                  {selectedAccount || OverviewHeaderLabels.selectAccount}
                </Typography>
                <img src={DownIcon} className="select-button-down-icon" alt="Dropdown Icon" />
              </Button>
            </Dropdown>
            <Dropdown className="dropdown-text" overlay={projectsList} trigger={['click']}>
              <Button className="select-account-button-mobile">
                <Typography className="select-button-text-mobile">
                  {selectedProject || OverviewHeaderLabels.selectProject}
                </Typography>
                <img src={DownIcon} className="select-button-down-icon" alt="Dropdown Icon" />
              </Button>
            </Dropdown>
          </>
        }
        {!showProjectSelector && pageTitle !== '' &&
          <div className='page-title-container'>
            <Typography className="page-title-text">{pageTitle}</Typography>
          </div>
        }
      </div>
    </>
  );
};

export default AppHeaderMobile;
