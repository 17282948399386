import { Reducer } from 'redux';
import { IFeedbackResponseData } from '../../../containers/FeedbackResponse/FeedbackResponseConstants';
import { FeedbackAction, FeedbackActionTypes } from '../actions/FeedbackActions';
import FeedbackState from '../state/FeedbackState';
import IFeedbackState from '../state/FeedbackState';

export const initialState: IFeedbackState = {
  error: '',
  loading: false,
  getFeedBack: [],
};

export const feedbackReducer: Reducer<FeedbackState> = (
  state = initialState,
  action: FeedbackAction,
) => {
  switch (action.type) {
    case FeedbackActionTypes.GET_FEEDBACK_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FeedbackActionTypes.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        getFeedBack: action.data as IFeedbackResponseData[],
      };
    case FeedbackActionTypes.GET_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    case FeedbackActionTypes.POST_FEEDBACK_START:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case FeedbackActionTypes.POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case FeedbackActionTypes.POST_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data as Error,
      };
    default:
      return state;
  }
};
