import { Col, Row } from 'antd';
import React from 'react';
import LeftWidget from './LeftWidget';
import './PolicyAnalyticsDashboard.less';
import RightWidget from './RightWidget';
import BreadCrumb, { IBreadCrumbItems } from '../BreadCrumbNav/BreadCrumb';

const PolicyAnalyticsDashboards = () => {
  const items = [
    {
      title: 'Policy Analytics',
      path: '/dashboard',
    },
  ];
  return (
    <div className="policy-analytics-container">
      <Row>
        {/* <Col span={24}><h1 className='main-heading-styling'>Policy Analytics</h1></Col> */}
        <Col span={24}>
          <h1 className="main-heading-styling">{<BreadCrumb items={items} />}</h1>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16} className="gutter-row">
          <LeftWidget />
        </Col>
        <Col span={8} className="gutter-row border-style">
          <RightWidget />
        </Col>
      </Row>
    </div>
  );
};

export default PolicyAnalyticsDashboards;
