import { put, takeEvery } from 'redux-saga/effects';
import { IServiceCataloguePayload } from '../../models/ServiceCatalogueModel';
import serviceCatalogueService from '../../services/ServiceCatalogueService/ServiceCatalogueService';
import {
  ServiceCatalogueAction,
  ServiceCatalogueActions,
  ServiceCatalogueActionTypes,
} from '../actions/ServiceCatalogueActions';

export function* getServiceCatalogueDetailsWorker(action: ServiceCatalogueAction) {
  try {
    const response: IServiceCataloguePayload =
      yield serviceCatalogueService.getServiceCatalogueDetails();
    yield put(ServiceCatalogueActions.getCatalogueDetailsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(ServiceCatalogueActions.getCatalogueDetailsError(error));
    }
  }
}

export function* serviceCatalogueWatcher() {
  yield takeEvery(
    ServiceCatalogueActionTypes.GET_SERVICE_CATALOGUE_DETAILS_STARTED,
    getServiceCatalogueDetailsWorker,
  );
}
