import { put, takeEvery } from 'redux-saga/effects';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { LoginLabels } from '../../../containers/Login/LoginConstants';
import { ICWBLoginResponse } from '../../models/CWBLoginModels';
import {
  ILoginPayload,
  ILoginResponse,
  ISessionTokenResponse,
  ITangraLoginPayload,
  ITangraLoginResponse,
} from '../../models/LoginModels';
import { storage, STORAGE_CONSTANTS } from '../../services/LocalStorage';
import LoginService from '../../services/login/LoginService';
import { LoginAction, LoginActions, LoginActionTypes } from '../actions/LoginActions';
import { ToastActions } from '../actions/ToastActions';

export function* postCWBLoginWorker(action: LoginAction) {
  try {
    const data = action.data as ILoginPayload;
    const response: ILoginResponse | ICWBLoginResponse = (yield LoginService.postLogin(data))!;
    yield put(LoginActions.postCWBLoginSuccess(response));
    yield put(
      ToastActions.showToast({
        description: LoginLabels.succesfulLoginText,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.postCWBLoginError(error));
    }
  }
}

export function* postTangraLoginWorker(action: LoginAction) {
  try {
    const data = action.data as ITangraLoginPayload;
    const response: ITangraLoginResponse = (yield LoginService.postTangraLogin(data))!;
    yield put(LoginActions.postTangraLoginSuccess(response));
    yield put(
      ToastActions.showToast({
        description: LoginLabels.succesfulLoginText,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(LoginActions.sessionTokenStart());
  } catch (error) {
    yield put(LoginActions.postTangraLoginError(error));
    if (error instanceof Error) {
      yield put(LoginActions.postTangraLoginError(error));
    }
  }
}

export function* postCwbSsoLoginWorker(action: LoginAction) {
  try {
    const data = action.data as ITangraLoginPayload;
    const response: ITangraLoginResponse = (yield LoginService.postCwbSsoLogin(data))!;
    yield put(LoginActions.postCwbSsoLoginSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.postCwbSsoLoginError(error));
    }
  }
}

//API Worker to fetch session token.
export function* getSessionToken(action: LoginAction) {
  try {
    const response: ISessionTokenResponse = (yield LoginService.getSessionToken())!;
    storage.setItem(STORAGE_CONSTANTS.sessionToken, response.Id);
    yield put(LoginActions.sessionTokenSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.sessionTokenError(error));
    }
  }
}

export function* postSessionToken(action: LoginAction) {
  try {
    const response: ISessionTokenResponse = (yield LoginService.postSessionToken())!;
    yield put(LoginActions.sessionTokenPostSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(LoginActions.sessionTokenPostError(error));
    }
  }
}

export function* loginWatcher() {
  yield takeEvery(LoginActionTypes.CWB_LOGIN_REQUEST, postCWBLoginWorker);
  yield takeEvery(LoginActionTypes.TANGRA_LOGIN_REQUEST, postTangraLoginWorker);
  yield takeEvery(LoginActionTypes.CWB_SSO_LOGIN_REQUEST, postCwbSsoLoginWorker);
  yield takeEvery(LoginActionTypes.SESSION_LOGIN_TOKEN_START, getSessionToken);
  yield takeEvery(LoginActionTypes.POST_SESSION_LOGIN_TOKEN_START, postSessionToken);
}
