import {
  IAddAccountPayload,
  ICreateAccountPayload,
  ICreateProjectPayload,
  IEditProjectPayload,
  ISelectAccountPayload,
  ISelectProjectPayload,
} from '../../models/OverviewModels';
import { IStoreAction } from '../StoreHelper';

export enum OverviewActionTypes {
  SELECT_PROJECT = 'SELECT_PROJECT',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  CREATE_ACCOUNT_START = 'CREATE_ACCOUNT_START',
  CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR',
  CREATE_PROJECT_START = 'CREATE_PROJECT_START',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR',
  EDIT_PROJECT_START = 'EDIT_PROJECT_START',
  EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS',
  EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR',
  ADD_ACCOUNT_START = 'ADD_ACCOUNT_START',
  ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS',
  ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR',
  GET_INITIAL_NOTIFICATION_STATUS_STARTED = 'GET_INITIAL_NOTIFICATION_STATUS_STARTED',
  GET_INITIAL_NOTIFICATION_STATUS_SUCCESS = 'GET_INITIAL_NOTIFICATION_STATUS_SUCCESS',
  GET_INITIAL_NOTIFICATION_STATUS_ERROR = 'GET_INITIAL_NOTIFICATION_STATUS_ERROR',
}

export type OverviewActionPayload =
  | string
  | string[]
  | boolean
  | ICreateAccountPayload
  | ISelectProjectPayload
  | ISelectAccountPayload
  | ICreateProjectPayload
  | IEditProjectPayload
  | IAddAccountPayload
  | Error;

export type OverviewAction = IStoreAction<OverviewActionTypes, OverviewActionPayload>;
export class OverviewActions {
  public static selectProject(data: ISelectProjectPayload): OverviewAction {
    return {
      type: OverviewActionTypes.SELECT_PROJECT,
      data,
    };
  }

  public static selectAccount(data: ISelectAccountPayload): OverviewAction {
    return {
      type: OverviewActionTypes.SELECT_ACCOUNT,
      data,
    };
  }

  public static createAccountStart(data: ICreateAccountPayload): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_ACCOUNT_START,
      data,
    };
  }

  public static createAccountSuccess(): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_ACCOUNT_SUCCESS,
    };
  }

  public static addAccountStart(data: IAddAccountPayload): OverviewAction {
    return {
      type: OverviewActionTypes.ADD_ACCOUNT_START,
      data,
    };
  }

  public static addAccountSuccess(): OverviewAction {
    return {
      type: OverviewActionTypes.ADD_ACCOUNT_SUCCESS,
    };
  }

  public static addAccountError(data: Error): OverviewAction {
    return {
      type: OverviewActionTypes.ADD_ACCOUNT_ERROR,
      data,
    };
  }

  public static createAccountError(data: Error): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_ACCOUNT_ERROR,
      data,
    };
  }

  public static createProjectStart(data: ICreateProjectPayload): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_PROJECT_START,
      data,
    };
  }

  public static createProjectSuccess(): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_PROJECT_SUCCESS,
    };
  }

  public static createProjectError(data: Error): OverviewAction {
    return {
      type: OverviewActionTypes.CREATE_PROJECT_ERROR,
      data,
    };
  }

  public static editProjectStart(data: IEditProjectPayload): OverviewAction {
    return {
      type: OverviewActionTypes.EDIT_PROJECT_START,
      data,
    };
  }

  public static editProjectSuccess(): OverviewAction {
    return {
      type: OverviewActionTypes.EDIT_PROJECT_SUCCESS,
    };
  }

  public static editProjectError(data: Error): OverviewAction {
    return {
      type: OverviewActionTypes.EDIT_PROJECT_ERROR,
      data,
    };
  }

  public static getNotificationDetailsStart(): OverviewAction {
    return {
      type: OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_STARTED,
    };
  }

  public static getNotificationDetailsSuccess(): OverviewAction {
    return {
      type: OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_SUCCESS,
    };
  }

  public static getNotificationDetailsError(data: Error): OverviewAction {
    return {
      type: OverviewActionTypes.GET_INITIAL_NOTIFICATION_STATUS_ERROR,
      data,
    };
  }
}
