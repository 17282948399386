import { all, call, spawn } from 'redux-saga/effects';
import { analyticsWatcher } from './AnalyticsSaga';
import { buildDeployWatcher } from './BuildDeploySaga';
import { cloudSuitabilityWatcher } from './CloudSuitabilitySaga';
import { dashboardWatcher } from './DashboardSaga';
import { loginWatcher } from './LoginSaga';
import { notificationWatcher } from './NotificationSaga';
import { operationsWatcher } from './OperationsSaga';
import { overviewWatcher } from './OverviewSaga';
import { pipelineWatcher } from './PipelineSaga';
import { securityGovernanceWatcher } from './SecurityGovernanceSaga';
import { userManagementWatcher } from './UserManagementSaga';
import { userWatcher } from './UserSaga';
import { hitsAnalyticsWatcher } from './WebAnalyticsSaga/HitAnalyticsSaga';
import { feedbackWatcher } from './FeedbackSaga';
import { trafficAnalyticsWatcher } from './WebAnalyticsSaga/TrafficAnalyticsSaga';
import { devOpsAnalyticsWatcher } from './WebAnalyticsSaga/DevOpsAnalyticsSaga';
import { serviceCatalogueWatcher } from './ServiceCatalogueSaga';
import { themeWatcher } from './ThemeSaga';
import { personaWatcher } from './PersonaSaga';
import { sendQueryWatcher } from './ChatbotSaga';
import { contractWatcher } from './ContractSaga';
import { metadataGuardrailWatcher } from './MetadataGuardrailSaga';

export default function* sagas() {
  const sagas = [
    loginWatcher,
    userWatcher,
    overviewWatcher,
    buildDeployWatcher,
    analyticsWatcher,
    themeWatcher,
    cloudSuitabilityWatcher,
    dashboardWatcher,
    operationsWatcher,
    pipelineWatcher,
    userManagementWatcher,
    notificationWatcher,
    trafficAnalyticsWatcher,
    hitsAnalyticsWatcher,
    feedbackWatcher,
    securityGovernanceWatcher,
    devOpsAnalyticsWatcher,
    serviceCatalogueWatcher,
    personaWatcher,
    sendQueryWatcher,
    contractWatcher,
    metadataGuardrailWatcher
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        }
      }),
    ),
  );
}
