/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  Login = 'login',
  RefreshToken = 'icp/user/refreshToken',
  CWB = 'cwb',
  TCO = 'tco',
  CSP = 'csp',
  BuildDeploy = 'dca',
  user = 'user',
  DCP = 'dcp',
  None = '',
  Prod = 'Prod',
  OMA = 'oma',
  ANALYTICS = 'analytics',
}

// Add base URLs and refresh token logic(if the server supports)
// in ApiServiceBase.ts for a new server added here
export enum ServerType {
  cwb = 'CWB',
  dca = 'DCA',
  tangraDCA = 'TangraDCA',
  dcp = 'DCP',
  none = '',
  tangra = 'TangraBE',
  dcaAdmin = 'DCAAdmin',
  local = 'localURL',
  MNACIBE = 'MNACIBE',
  MNACIBE1 = 'MNACIBE1',
  MNACPREURL = 'MNACPREURL',
}
