import { Col, Row } from 'antd';
import React from 'react';
import { ReactComponent as SeverityAero } from '../../../assets/icons/severity-aero.svg';
import RiskAnalysisImg from '../../../assets/images/risk-analysis-graph.png';
import { riskFilters } from './InsightsDashboardConstants';
// import RiskAnalysisImg from '../../../assets/images/risk-analysis@2x.png';

const RiskAnalysis = () => {
  return (
    <Row className="risk-analysis-container">
      <Col span={24}>
        <h2>Risk Analysis</h2>
        {/* <div className="category-filters">
          {riskFilters.map((filter) => (
            <div className="category-filter" key={filter.category}>
              <div className="card-header">
                <div className={`severity-${filter.severity.toLowerCase()}`}>{filter.severity}</div>
                <div className={`aero aero-${filter.severity.toLowerCase()}`}>
                  <SeverityAero />
                </div>
              </div>
              <div className="filter-category">{filter.category}</div>
            </div>
          ))}
        </div> */}
        <Row className="category-filters" gutter={[16,16]}>
          {riskFilters.map((filter) => (
            <Col span={3} className="category-filter gutter-row" key={filter.category}>
              <div className="card-header">
                <div className={`severity-${filter.severity.toLowerCase()}`}>{filter.severity}</div>
                <div className={`aero aero-${filter.severity.toLowerCase()}`}>
                  <SeverityAero />
                </div>
              </div>
              <div className="filter-category">{filter.category}</div>
            </Col>
          ))}
        </Row>
        {/* <img src={RiskAnalysisImg} alt="risk analysis" /> */}
        <img src={RiskAnalysisImg} alt="risk analysis" className="w-100" />
      </Col>
    </Row>
  );
};

export default RiskAnalysis;
