import {
  ICloudAnalysisResponse,
  IMostVisitedArchPayload,
  IMostVisitedArchResponse,
  IMostVisitedSectionPayload,
  IMostVisitedSectionResponse,
  IVisitedPagePayload,
} from '../../store/actions/WebAnalytics/TrafficAnalyticsActions';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.ANALYTICS;

export class TrafficAnalyticsService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.tangra);

  public getMostVisitedMenu(
    payload: IMostVisitedSectionPayload,
  ): Promise<IMostVisitedSectionResponse[]> | IMostVisitedSectionResponse[] {
    const route: string[] = [`${API_ROUTE.GET_MOST_VISITED_DATA}`];
    return this.service.get({
      route: route,
      query: { startDate: payload.startDate },
    });
  }
  public getMostVisitedArch(
    payload: IMostVisitedArchPayload,
  ): Promise<IMostVisitedArchResponse[]> | IMostVisitedArchResponse[] {
    const route: string[] = [`${API_ROUTE.GET_MOST_VISITED_ARCH_DATA}`];
    return this.service.get({
      route: route,
      query: { architectureType: payload.architectureType },
    });
  }
  public getMostVisitedCloudAssessment():
    | Promise<IMostVisitedArchResponse[]>
    | IMostVisitedArchResponse[] {
    const route: string[] = [`${API_ROUTE.GET_CLOUD_ASSESSMENT_DATA}`];
    return this.service.get({
      route: route,
    });
  }
  public postVisitedMenu(payload: IVisitedPagePayload): Promise<string> | string {
    const route: string[] = [`${API_ROUTE.POST_VISITED_DATA}`];
    return this.service.post({ route: route }, payload);
  }
  public getCloudProviderAnalytics(): Promise<ICloudAnalysisResponse[]> | ICloudAnalysisResponse[] {
    const route: string[] = [`${API_ROUTE.GET_CLOUD_ANALYTICS_DATA}`];
    return this.service.get({ route: route });
  }
}

const trafficAnalyticsService = new TrafficAnalyticsService();
export default trafficAnalyticsService;
