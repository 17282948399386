import { Reducer } from 'redux';
import {
  HitsAnalyticsAction,
  HitsAnalyticsActionTypes,
  IAnalyticsAreaChartResponse,
  IAnalyticsPieChartResponse,
} from '../../actions/WebAnalytics/HitsAnalyticsActions';
import IHitsAnalyticsState from '../../state/HitAnalyticsState';

export const initialState: IHitsAnalyticsState = {
  error: '',
  loading: false,
  newVsReturningUsersData: [],
  usageRoleData: [],
  cloudStatusData: [],
  cloudStatusLoading: false,
  newVsReturningUsersLoading: false,
  usageRoleLoading: false,
  activeUsersData: {
    chartValues: [],
    totalCount: 0,
  },
  pageViewsData: {
    chartValues: [],
    totalCount: 0,
  },
  visitsData: {
    chartValues: [],
    totalCount: 0,
  },
  avgTimeData: {
    chartValues: [],
    totalCount: 0,
  },
};

export const HitsAnalyticsReducer: Reducer<IHitsAnalyticsState> = (
  state = initialState,
  action: HitsAnalyticsAction,
) => {
  switch (action.type) {
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR:
      return {
        ...state,
        newVsReturningUsersLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_SUCCESS:
      return {
        ...state,
        newVsReturningUsersLoading: false,
        newVsReturningUsersData: action.data as IAnalyticsPieChartResponse[],
      };
    case HitsAnalyticsActionTypes.GET_NEW_VS_RETURNING_USER_DATA_ERROR:
      return {
        ...state,
        newVsReturningUsersLoading: false,
        error: action.data as Error,
      };
    case HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_START:
      return {
        ...state,
        usageRoleLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_SUCCESS:
      return {
        ...state,
        usageRoleLoading: false,
        usageRoleData: action.data as IAnalyticsPieChartResponse[],
      };
    case HitsAnalyticsActionTypes.GET_USAGE_LEVEL_DATA_ERROR:
      return {
        ...state,
        usageRoleLoading: false,
        error: action.data as Error,
      };

    case HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_START:
      return {
        ...state,
        cloudStatusLoading: true,
      };
    case HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_SUCCESS:
      return {
        ...state,
        cloudStatusData: action.data as IAnalyticsPieChartResponse[],
        cloudStatusLoading: false,
      };
    case HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
        cloudStatusLoading: false,
      };
    case HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_START:
      return {
        ...state,
      };
    case HitsAnalyticsActionTypes.GET_ACTIVE_USERS_DATA_SUCCESS:
      return {
        ...state,
        activeUsersData: action.data as IAnalyticsAreaChartResponse,
      };
    case HitsAnalyticsActionTypes.GET_CLOUD_STATUS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_START:
      return {
        ...state,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_SUCCESS:
      return {
        ...state,
        visitsData: action.data as IAnalyticsAreaChartResponse,
      };
    case HitsAnalyticsActionTypes.GET_USERS_VISITS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };
    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_START:
      return {
        ...state,
      };

    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_SUCCESS:
      return {
        ...state,
        pageViewsData: action.data as IAnalyticsAreaChartResponse,
      };

    case HitsAnalyticsActionTypes.GET_PAGE_VIEWS_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    case HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_START:
      return {
        ...state,
      };
    case HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_SUCCESS:
      return {
        ...state,
        avgTimeData: action.data as IAnalyticsAreaChartResponse,
      };
    case HitsAnalyticsActionTypes.GET_AVG_SESSION_TIME_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };

    default:
      return state;
  }
};
