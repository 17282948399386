import { Reducer } from 'redux';
import {
  ICloudAnalysisResponse,
  IMostVisitedArchResponse,
  IMostVisitedSectionResponse,
  TrafficAnalyticsAction,
  TrafficAnalyticsActionTypes,
} from '../../actions/WebAnalytics/TrafficAnalyticsActions';
import TrafficAnalyticsState from '../../state/TrafficAnalyticsState';
import ITrafficAnalyticsState from '../../state/TrafficAnalyticsState';

export const initialState: ITrafficAnalyticsState = {
  error: '',
  loading: false,
  loadingMostVisitedMenu: false,
  loadingMostCloud: false,
  loadingMostViewedArch: false,
  loadingMostCloudAssessment: false,
  mostVisitedData: [],
  cloudProviderAnalysis: undefined,
  mostVisitedArchData: [],
  mostCloudAssessmentData: [],
};

export const TrafficAnalyticsReducer: Reducer<TrafficAnalyticsState> = (
  state = initialState,
  action: TrafficAnalyticsAction,
) => {
  switch (action.type) {
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_START:
      return {
        ...state,
        loadingMostVisitedMenu: true,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_SUCCESS:
      return {
        ...state,
        loadingMostVisitedMenu: false,
        mostVisitedData: action.data as IMostVisitedSectionResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_DATA_ERROR:
      return {
        ...state,
        loadingMostVisitedMenu: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_STARTED:
      return {
        ...state,
        loadingMostCloud: true,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_SUCCESS:
      return {
        ...state,
        loadingMostCloud: false,
        cloudProviderAnalysis: action.data as ICloudAnalysisResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_MOST_CLOUD_DETAILS_ERROR:
      return {
        ...state,
        loadingMostCloud: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_START:
      return {
        ...state,
        loadingMostViewedArch: true,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_SUCCESS:
      return {
        ...state,
        loadingMostViewedArch: false,
        mostVisitedArchData: action.data as IMostVisitedArchResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_ARCHITECTURE_ERROR:
      return {
        ...state,
        loadingMostViewedArch: false,
        error: action.data as Error,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_START:
      return {
        ...state,
        loadingMostCloudAssessment: true,
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loadingMostCloudAssessment: false,
        mostCloudAssessmentData: action.data as IMostVisitedArchResponse[],
      };
    case TrafficAnalyticsActionTypes.GET_MOST_VISITED_CLOUD_ASSESSMENT_ERROR:
      return {
        ...state,
        loadingMostCloudAssessment: false,
        error: action.data as Error,
      };
    default:
      return state;
  }
};
