import { Reducer } from 'redux';
import { IUserManagementObject } from '../../models/UserManagementModel';
import { UserManagementAction, UserManagementActionTypes } from '../actions/UserManagementActions';
import UserManagementState from '../state/UserManagementState';

export const initialState: UserManagementState = {
  error: '',
  loading: false,
  userManagementDetails: [],
};

export const UserManagementReducer: Reducer<UserManagementState> = (
  state = initialState,
  action: UserManagementAction,
) => {
  switch (action.type) {
    case UserManagementActionTypes.CREATE_USER_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };
    case UserManagementActionTypes.CREATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.GET_USER_MANAGEMENT_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.GET_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        userManagementDetails: action.data as IUserManagementObject[],
      };
    case UserManagementActionTypes.GET_USER_MANAGEMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UserManagementActionTypes.UPDATE_USER_MANAGEMENT_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserManagementActionTypes.UPDATE_USER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UserManagementActionTypes.UPDATE_USER_MANAGEMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
};
