import { Steps } from 'antd';
import React from 'react';
import './TopNav.less';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import AIBotBlack from '../../../src/assets/icons/MNACI/AIBotBlack.svg';
import AIBotWhite from '../../../src/assets/icons/MNACI/AIBotWhite.svg';
import AnalysisBlack from '../../../src/assets/icons/MNACI/AnalysisBlack.svg';
import AnalysisWhite from '../../../src/assets/icons/MNACI/AnalysisWhite.svg';
import IngestionBlack from '../../../src/assets/icons/MNACI/IngestionBlack.svg';
import IngestionWhite from '../../../src/assets/icons/MNACI/IngestionWhite.svg';
import { routes } from '../../navigation/Routes';

const TopNav = () => {
  const history = useHistory();
  const [current, setCurrent] = React.useState(0);
  const onChange = (value: number) => {
    setCurrent(value);
    if (value === 0) {
      history.push(routes.ingestion);
    } else if (value === 1) {
      history.push(routes.analysis);
    } else {
      history.push(routes.aibot);
    }
  };

  React.useEffect(() => {
    if (window.location.pathname === routes.ingestion) {
      setCurrent(0);
    } else if (window.location.pathname === routes.analysis) {
      setCurrent(1);
    } else if (window.location.pathname === routes.aibot) {
      setCurrent(2);
    }
  }, []);

  const { Step } = Steps;
  const items = [
    {
      title: 'Contract Details',
      selectedIcon: IngestionWhite,
      unselectedIcon: IngestionBlack,
      navigationLink: routes.ingestion,
    },
    {
      title: 'Contract Analysis',
      selectedIcon: AnalysisWhite,
      unselectedIcon: AnalysisBlack,
      navigationLink: routes.analysis,
    },
    {
      title: 'ChatBot',
      selectedIcon: AIBotWhite,
      unselectedIcon: AIBotBlack,
      navigationLink: routes.aibot,
    },
  ];
  return (
    <Steps
      current={current}
      onChange={onChange}
      className="top-nav-steps"
      labelPlacement="horizontal"
    >
      {items.map(({ title, selectedIcon, unselectedIcon, navigationLink }, ind) => (
        <Step
          key={ind}
          description={
            <div className={current === ind ? 'step-container selected' : 'step-container'}>
              <div
                className={`nav-icon-container cursor-pointer ${
                  current === ind ? 'nav-icon-container-selected' : ''
                }`}
                // onClick={() => history.push(navigationLink)}
              >
                <img
                  className="icon-container"
                  src={current === ind ? selectedIcon : unselectedIcon}
                  alt="Step Icon"
                />
              </div>
              <div className="step-title">{title}</div>
            </div>
          }
        />
      ))}
    </Steps>
  );
};

export default TopNav;
