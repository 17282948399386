import { Drawer, Menu, Alert } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import AssetWhite from '../../assets/icons/asset-white.svg';
import BarChartWhite from '../../assets/icons/barchart_white.svg';
import DashboardWhiteIcon from '../../assets/icons/Cloud_White.svg';
import ContactsWhite from '../../assets/icons/contacts_white.svg';
import FeedbackResponseWhite from '../../assets/icons/feedback-response-white.svg';
import HelpWhiteIcon from '../../assets/icons/Help_White.svg';
import OperateWhite from '../../assets/icons/operate_w.svg';
import { IAuditHistoryPayload } from '../../libs/models/DashboardModels';
import { storage } from '../../libs/services/LocalStorage';
import { DashboardActions } from '../../libs/store/actions/DashboardActions';
import { LoginActions } from '../../libs/store/actions/LoginActions';
import { SidebarActions } from '../../libs/store/actions/SidebarActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import './SidebarMobileStyles.less';
import CloseIcon from '../../assets/icons/cross-white.png';
import Logout from '../../assets/icons/logout.svg';
import { SideBarKey, SidebarLabels } from '../../containers/Sidebar/SidebarConstants';
import { SidebarMobileLabels as LABELS } from './SideBarMobileConstants';
import { useHistory } from 'react-router';
import { routes } from '../../navigation/Routes';

const SidebarMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isExpanded, project, account, logoutState, enabledDiscoverAssessItems } = useAppSelector(
    ({
      sidebar: { isExpanded, hiddenSidebar, activeKey, logout },
      overview: {
        selectedAccount,
        selectedProject,
        selectedAccountId,
        selectedAssetType,
        selectedProjectId,
      },
      dashboard: { enabledDiscoverAssessItems },
    }: IApplicationState) => ({
      isExpanded,
      hiddenSidebar,
      activeKey,
      project:
        selectedProject && selectedProjectId
          ? { name: selectedProject, id: selectedProjectId }
          : undefined,
      account:
        selectedAccount && selectedAccountId && selectedAssetType
          ? { name: selectedAccount, id: selectedAccountId, assetType: selectedAssetType }
          : undefined,
      logoutState: logout,
      enabledDiscoverAssessItems,
    }),
  );

  const dispatcher = {
    toggleSidebar: (data: boolean) => dispatch(SidebarActions.toggleSidebar(data)),
    saveAuditDetails: (data: IAuditHistoryPayload) =>
      dispatch(DashboardActions.saveAuditHistoryStart(data)),
    toggleLogout: (data: boolean) => dispatch(SidebarActions.toggleLogout(data)),
    postSessionToken: () => dispatch(LoginActions.sessionTokenPostStart()),
  };

  const toggleSidebar = () => dispatcher.toggleSidebar(!isExpanded);
  const saveAuditDetails = (data: IAuditHistoryPayload) => dispatcher.saveAuditDetails(data);
  const toggleLogout = (data: boolean) => dispatcher.toggleLogout(data);
  const postSessionToken = () => dispatcher.postSessionToken();

  const logout = () => {
    postSessionToken();
    saveAuditDetails({
      dashboard: { project, account, enabledDiscoverAssessItems },
    });
  };

  if (logoutState) {
    toggleSidebar();
    toggleLogout(false);
    storage.clearAll();
    window.location.reload();
  }
  return (
    <Drawer
      width={'100%'}
      visible={isExpanded}
      className={`sidebar-container-mobile`}
      onClose={() => {}}
      closeIcon={null}
    >
      <div className="sidebar-content">
        <div onClick={() => {}} className="app-title-mobile cursor-pointer">
          <img className="sidebar-tangra-logo-mobile" src={`${process.env.PUBLIC_URL}/dca_logo_white.png`} alt="Tangra Logo" />
          <div onClick={toggleSidebar}>
            <img className="close-logo-mobile" src={CloseIcon} alt="Tangra Logo" />
          </div>
        </div>
        <div className="sidebar-items-container-mobile">
          <Menu mode="vertical" className={'sidebar-menubar'}>

            {/* commented for future implementation */}

            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.DASHBOAARD}
              onClick={()=>{history.push(routes.dashboard); toggleSidebar();}}
              icon={
                <img src={DashboardWhiteIcon} alt="Dashboard Icon" className="dashboard-image" />
              }
            >
              {LABELS.overview}
            </Menu.Item>

            {/* <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.ASSET_AUTHORISATION}
              icon={<img src={AssetWhite} alt="Dashboard Icon" className="dashboard-image" />}
              disabled
            >
              {LABELS.asset}
            </Menu.Item>
            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.OPERATE}
              icon={<img src={OperateWhite} alt="Dashboard Icon" className="dashboard-image" />}
              disabled
            >
              {LABELS.operations}
            </Menu.Item>
            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.WEB_ANALYTICS}
              icon={<img src={BarChartWhite} alt="Dashboard Icon" className="dashboard-image" />}
              disabled
            >
              {LABELS.analytics}
            </Menu.Item>
            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.HELP}
              icon={<img src={HelpWhiteIcon} alt="Dashboard Icon" className="dashboard-image" />}
              disabled
            >
              {LABELS.help}
            </Menu.Item>*/}
            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.KEYCONTACTS}
              icon={<img src={ContactsWhite} alt="Dashboard Icon" className="dashboard-image" />}
              onClick={()=>{history.push(routes.keyContacts); toggleSidebar();}}
            >
              {LABELS.keyContacts}
            </Menu.Item>
            {/*<Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.FEEDBACK}
              icon={
                <img src={FeedbackResponseWhite} alt="Dashboard Icon" className="dashboard-image" />
              }
              disabled
            >
              {LABELS.feedback}
            </Menu.Item> */}
            
            <Alert message={SidebarLabels.mobileViewNotFunctional} className="is-mobile-info-text" type="info" showIcon />
            <Menu.Item
              className="mobile-sidebar-menuitem"
              key={SideBarKey.LOGOUT}
              onClick={logout}
              icon={<img src={Logout} alt="Dashboard Icon" className="dashboard-image" />}
            >
              {LABELS.adminPanelLogout}
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </Drawer>
  );
};

export default SidebarMobile;
