import { Spin } from 'antd';
import React, { ReactNode } from 'react';
import './LoaderStyles.less';

interface ILoaderProps {
  children: ReactNode;
  loading: boolean;
  loaderName: string;
}

const Loader = ({ children, loading, loaderName }: ILoaderProps) => {
  return (
    <Spin tip={loaderName} spinning={loading} size="large" className="loader">
      {children}
    </Spin>
  );
};

export default Loader;
