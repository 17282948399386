/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Pagination,
  PaginationProps,
  Spin,
  Popover,
  Input,
  Col,
  Row,
  Button,
  Radio,
  RadioChangeEvent,
  DatePicker,
  DatePickerProps,
  Space,
  Table,
  Select,
} from 'antd';
import './Dashboard.less';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { ContractActions } from '../../libs/store/actions/UploadContractActions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import ELAIcon from '../../assets/icons/MNACI/ELA.svg';
import EULAIcon from '../../assets/icons/MNACI/EULA.svg';
import MLAIcon from '../../assets/icons/MNACI/MLA.svg';
import MSLAIcon from '../../assets/icons/MNACI/MSLA.svg';
import CrossGreyIcon from '../../assets/icons/cross-grey-filled.svg';
import Completed from '../../assets/icons/MNACI/completed.svg';
import Failed from '../../assets/icons/MNACI/failed.svg';
import SRAIcon from '../../assets/icons/MNACI/SRA.svg';
import GridIcon from '../../assets/icons/grid-view.svg';
import ListIcon from '../../assets/icons/List.svg';
import ShowAllIcon from '../../assets/icons/MNACI/ShowAll.svg';
import ViewContractIcon from '../../assets/icons/view-contract.svg';
import ReuploadIcon from '../../assets/icons/reupload-contract.svg';
import { IContractDetailsRequestBody } from './MNACIDashboardModel';
import ContractsDrawer from './ContractsDrawer/ContractsDrawer';
import moment from 'moment';
import UploadFileModal from './UploadFileModal';
import MetadataGuardrailDrawer from './MetadataGuardrailDrawer';
export interface IContractsData {
  contract_id: string;
  contract_name: string;
  contract_type: string;
  status: string;
  date: string;
  created_by?: string;
  vmware_agreement?: string;
}
export enum ContractStatus {
  ERROR = 'error',
  EXTRACTED = 'Extracted',
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}
export enum ContractAction {
  VIEW_CONTRACT = 'View Contract',
  RE_UPLOAD = 'Re-upload',
}
const { Title } = Typography;
const { Search } = Input;

export enum Category {
  ELA = 'ELA',
  SRA = 'SRA',
  MSLA = 'MSLA',
  MLA = 'MLA',
  EULA = 'EULA',
}

const sortColumns = {
  CONTRACT_NAME: {
    key: 'contract_name',
    name: 'Contract Name',
  },
  DATE: {
    key: 'date',
    name: 'Created On',
  },
};

const SortBy = {
  ASCENDING: {
    key: 'ASC',
    name: 'Ascending',
  },
  DESCENDING: { key: 'DESC', name: 'Descending' },
};

interface ISortOrder {
  columnName?: string | undefined;
  order?: string | undefined;
}
interface ISelectedFilters {
  status: string | undefined;
  createdOn: string | undefined;
  sort: ISortOrder | undefined;
  contractName: string | undefined;
}
enum ContractView {
  GRID = 'Grid',
  TABLE = 'Table',
}

export const filterData = [
  {
    label: 'All Categories',
    value: 'All Categories',
  },
  {
    label: 'ELA',
    value: 'ELA',
  },
  {
    label: 'SRA',
    value: 'SRA',
  },

  {
    label: 'MSLA',
    value: 'MSLA',
  },
  {
    label: 'MLA',
    value: 'MLA',
  },
  {
    label: 'EULA',
    value: 'EULA',
  },
];
const MNACIDashboardNew = () => {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [selectedContract, setSelectedContract] = useState<IContractsData>();
  const [selectedContractType, setSelectedContractType] = useState<string>(filterData[0].label);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedView, setSelectedView] = useState(ContractView.GRID);

  const [searchKey, setSearchKey] = useState('');

  const [metadataGuardrailDrawerVisible, setMetadataGuardrailDrawerVisible] =
    useState<boolean>(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const [sortOrder, setSortOrder] = useState<ISortOrder | undefined>(undefined);
  const [status, setStatus] = useState<ContractStatus | undefined>();
  const [createdOn, setCreatedOn] = useState<string | undefined>();
  const [isUploadFileOpen, setIsUploadFileOpen] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>({
    status: ContractStatus.SUCCESS,
    createdOn: undefined,
    sort: undefined,
    contractName: undefined,
  });

  const { contractsListLoading, contractsListResponse, contractUploading } = useAppSelector(
    ({
      contracts: { contractsListLoading, contractsListResponse, loading: contractUploading },
    }: IApplicationState) => ({
      contractsListLoading,
      contractsListResponse,
      contractUploading,
    }),
  );

  const dispatcher = {
    getContractList: (data: IContractDetailsRequestBody) =>
      dispatch(ContractActions.getContractDetailsListStart(data)),
  };

  const togglePopover = () => {
    setPopoverVisible(!popoverVisible);
  };

  const getFilterString = () => {
    const query = [];
    if (selectedContractType !== filterData[0].label) {
      query.push(`contract_type = '${selectedContractType}'`);
    }
    /** Add sory_by condition once BE supports it */
    if (selectedFilters.status) {
      query.push(`status = '${selectedFilters.status}'`);
    }
    if (selectedFilters.createdOn) {
      query.push(`date = '${selectedFilters.createdOn}'`);
    }
    if (selectedFilters.createdOn) {
      query.push(`date = '${selectedFilters.createdOn}'`);
    }
    if (selectedFilters.contractName) {
      query.push(`contract_name like '%${selectedFilters.contractName}%'`);
    }
    const filterString = query.join(' AND ');

    const sortingFilterString =
      selectedFilters.sort?.columnName &&
      selectedFilters.sort?.order &&
      `${selectedFilters.sort?.columnName} ${selectedFilters.sort?.order}`;

    return {
      ...(sortingFilterString ? { sorting_filters: sortingFilterString } : {}),
      ...(filterString ? { filters: filterString } : {}),
    };
  };

  useEffect(() => {
    dispatcher.getContractList({
      page_size: pageSize,
      page_num: pageNumber,
      ...getFilterString(),
    });
  }, [pageNumber, pageSize, selectedContractType, selectedFilters]);

  useEffect(() => {
    setPageNumber(1);
  }, [pageSize, selectedContractType]);

  const handleReset = () => {
    // setSortBy(undefined);
    setSortOrder(undefined);
    setCreatedOn(undefined);
    setStatus(undefined);
    // setIsApplyFilters(false);
  };

  // useEffect(() => {
  //   if (!popoverVisible && !isApplyFilters) {
  //     handleReset();
  //   }
  // }, [popoverVisible]);

  const handlePageNumberChange: PaginationProps['onChange'] = (page) => {
    setPageNumber(page);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case Category.ELA:
        return ELAIcon;
      case Category.SRA:
        return SRAIcon;
      case Category.MSLA:
        return MSLAIcon;
      case Category.MLA:
        return MLAIcon;
      case Category.EULA:
        return EULAIcon;
      default:
        return ShowAllIcon;
    }
  };

  const onContractSearch = (value: string) => {
    setSearchKey(value);
  };

  const handleSortByChange = (e: RadioChangeEvent) => {
    setSortOrder((oldOrder) => ({
      ...oldOrder,
      [e.target.name as string]: e.target.value || '',
    }));
  };

  const handleStatusChange = (e: RadioChangeEvent) => {
    setStatus(e.target.value);
  };

  const handleCreatedOn: DatePickerProps['onChange'] = (date, dateString) => {
    setCreatedOn(dateString);
  };

  const handleApplyFilters = () => {
    togglePopover();
    setSelectedFilters({
      status,
      createdOn,
      contractName: selectedFilters.contractName,
      sort: sortOrder?.columnName && sortOrder.order ? sortOrder : undefined,
    });
  };

  const renderPopupFilters = (
    <div className="filter-popup-container">
      <div className="filter-item-container">
        <div className="filter-header-container">
          <div className="icon-container">
            <img
              className="icon"
              src={`${process.env.PUBLIC_URL}/broadcom-vmware/contract-filter-popup-icon.svg`}
              alt="Filter Icon"
            />
          </div>
          <div className="label">Sort by</div>
        </div>
        <div className="filter-value-container">
          <Radio.Group
            name="columnName"
            onChange={handleSortByChange}
            value={sortOrder?.columnName}
          >
            <Radio value={sortColumns.CONTRACT_NAME.key}>{sortColumns.CONTRACT_NAME.name}</Radio>
            <Radio value={sortColumns.DATE.key}>{sortColumns.DATE.name}</Radio>
          </Radio.Group>
        </div>
        <div className="filter-order-container">
          <Radio.Group name="order" onChange={handleSortByChange} value={sortOrder?.order}>
            <Radio value={SortBy.ASCENDING.key}>{SortBy.ASCENDING.name}</Radio>
            <Radio value={SortBy.DESCENDING.key}>{SortBy.DESCENDING.name}</Radio>
          </Radio.Group>
        </div>
      </div>

      <div className="filter-item-container">
        <div className="filter-header-container">
          <div className="icon-container">
            <img
              className="icon"
              src={`${process.env.PUBLIC_URL}/broadcom-vmware/contract-filter-popup-icon.svg`}
              alt="Filter Icon"
            />
          </div>
          <div className="label">Status</div>
        </div>
        <div className="filter-value-container filter-item-separator">
          <Radio.Group onChange={handleStatusChange} value={status}>
            <Radio value={ContractStatus.SUCCESS}>Extracted</Radio>
            {/* <Radio value={ContractStatus.PROCESSING}>{ContractStatus.PROCESSING}</Radio> */}
            <Radio value={ContractStatus.FAILED}>{ContractStatus.FAILED}</Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="filter-item-container">
        <div className="filter-header-container">
          <div className="icon-container">
            <img
              className="icon"
              src={`${process.env.PUBLIC_URL}/broadcom-vmware/contract-filter-popup-icon.svg`}
              alt="Filter Icon"
            />
          </div>
          <div className="label">Created On</div>
        </div>
        <div className="filter-value-container">
          {createdOn ? (
            <DatePicker
              className="date-picker-container"
              placeholder="yyyy-mm-dd"
              value={moment(createdOn, 'YYYY-MM-DD')}
              onChange={handleCreatedOn}
              placement={'bottomRight'}
              suffixIcon={
                <img
                  className="icon"
                  src={`${process.env.PUBLIC_URL}/broadcom-vmware/calendar.svg`}
                  alt="Calender"
                />
              }
            />
          ) : (
            <DatePicker
              className="date-picker-container"
              placeholder="yyyy-mm-dd"
              onChange={handleCreatedOn}
              placement={'bottomRight'}
              suffixIcon={
                <img
                  className="icon"
                  src={`${process.env.PUBLIC_URL}/broadcom-vmware/calendar.svg`}
                  alt="Calender"
                />
              }
            />
          )}
        </div>
      </div>
      <div className="cta-container">
        <Button className="reset-btn" onClick={handleReset}>
          Reset
        </Button>
        <Button className="apply-btn" type="primary" onClick={handleApplyFilters}>
          Apply
        </Button>
      </div>
    </div>
  );
  const getNameFromKey = (colName: string | undefined) => {
    switch (colName) {
      case 'date':
        return 'Created On';
      case 'contract_name':
        return 'Contract Name';
      case SortBy.ASCENDING.key:
        return SortBy.ASCENDING.name;
      case SortBy.DESCENDING.key:
        return SortBy.DESCENDING.name;
      default:
        return '';
    }
  };

  const renderFilters = () => {
    return (
      <div>
        <div className="filter-data-container">
          <div className="filter-data">
            {/* {filterData.map((item) => (
              <span
                className={item === selectedContractType ? 'selected' : ''}
                onClick={(e) => {
                  setSelectedContractType(item);
                }}
                key={item}
              >
                <img className="icon" src={getCategoryIcon(item)} alt={'Category Type'} />
                {item}
              </span>
            ))} */}
            <Select
              showSearch
              placeholder="Select contract type"
              className="w-100"
              filterOption={(input: string, option?: { label: string; value: string }) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setSelectedContractType(value)}
              options={filterData}
            />
          </div>

          <div className="search-contracts-container">
            <div className="search-contacts-input-container">
              <Input
                placeholder="Search Contract Name"
                onChange={(e) => onContractSearch(e.target.value)}
                value={searchKey}
                className="search-contacts-input"
                prefix={<SearchOutlined className="search-icon" />}
                suffix={
                  <a
                    onClick={(e) => {
                      setSelectedFilters((oldFilters) => ({
                        ...oldFilters,
                        contractName: searchKey,
                      }));
                    }}
                  >
                    Search
                  </a>
                }
              />
            </div>
            <div className="contract-filter-popup-icon-container">
              <Button
                className="contract-filter-popup-icon"
                onClick={() => {
                  setPopoverVisible(false);
                  setMetadataGuardrailDrawerVisible(true);
                }}
              >
                <img
                  className="icon"
                  src={`${process.env.PUBLIC_URL}/broadcom-vmware/guardrails-metadata-icon.svg`}
                  alt="Filter Contract"
                />
              </Button>
            </div>
            <div className="contract-filter-popup-icon-container">
              <Popover
                visible={popoverVisible}
                content={renderPopupFilters}
                placement="bottomLeft"
                trigger={'click'}
              >
                <Button className="contract-filter-popup-icon" onClick={togglePopover}>
                  <img
                    className="icon"
                    src={`${process.env.PUBLIC_URL}/broadcom-vmware/contract-filter-icon.svg`}
                    alt="Filter Contract"
                  />
                </Button>
              </Popover>
            </div>
            <div className="view-switch-container">
              <Button
                className={`view-btn ${selectedView === ContractView.GRID ? 'selected' : ''}`}
                icon={<img src={GridIcon} alt="" className="mr-0-dot-5" />}
                onClick={() => setSelectedView(ContractView.GRID)}
              >
                {ContractView.GRID}
              </Button>
              <Button
                className={`view-btn ${selectedView === ContractView.TABLE ? 'selected' : ''}`}
                icon={<img src={ListIcon} alt="" className="mr-0-dot-5" />}
                onClick={() => setSelectedView(ContractView.TABLE)}
              >
                {ContractView.TABLE}
              </Button>
            </div>
            <div className="upload-contract-container">
              <Button
                className="upload-contract"
                onClick={() => {
                  setPopoverVisible(false);
                  setIsUploadFileOpen(true);
                }}
              >
                <div>
                  <img
                    className="icon"
                    src={`${process.env.PUBLIC_URL}/broadcom-vmware/upload-contract-icon.svg`}
                    alt="Upload Contract"
                  />
                </div>
                <div className="label">Upload Contract</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderAppliedFilters = () => (
    <Row gutter={[0, 8]} className="mx-0">
      <Col span={24} className="gutter-row row-gutter">
        {(selectedFilters.status || selectedFilters.createdOn) && (
          <div className="filters-applied-container">
            <Typography className="filter-heading">Applied Filters</Typography>
            {selectedFilters.sort && (
              <Typography className="filter-pill">
                {getNameFromKey(selectedFilters.sort.columnName)}:{' '}
                <b className="ml-0-dot-5">{getNameFromKey(selectedFilters.sort.order)}</b>
                <img
                  src={CrossGreyIcon}
                  alt=""
                  onClick={() => {
                    setSelectedFilters((oldFilters) => ({
                      ...oldFilters,
                      sort: undefined,
                    }));
                  }}
                />
              </Typography>
            )}

            {selectedFilters.status && (
              <Typography className="filter-pill">
                Status:{' '}
                <b className="ml-0-dot-5">
                  {selectedFilters.status === 'Success' ? 'Extracted' : selectedFilters.status}
                </b>
                <img
                  src={CrossGreyIcon}
                  alt=""
                  onClick={() => {
                    setSelectedFilters((oldFilters) => ({
                      ...oldFilters,
                      status: undefined,
                    }));
                  }}
                />
              </Typography>
            )}

            {selectedFilters.createdOn && (
              <Typography className="filter-pill">
                Created On: <b className="ml-0-dot-5">{selectedFilters.createdOn}</b>
                <img
                  src={CrossGreyIcon}
                  alt=""
                  onClick={() => {
                    setSelectedFilters((oldFilters) => ({
                      ...oldFilters,
                      createdOn: undefined,
                    }));
                  }}
                />
              </Typography>
            )}
          </div>
        )}
      </Col>
      <Col span={24}>
        <Typography className="font-16 font-bold">
          Total Contracts: {contractsListResponse.total_records}
        </Typography>
      </Col>
    </Row>
  );
  const handleAction = (item: IContractsData) => {
    const { status } = item;
    switch (status) {
      case ContractStatus.EXTRACTED:
      case ContractStatus.SUCCESS:
        setSelectedContract(item);
        setDrawerVisible(true);

        break;
      case ContractStatus.ERROR:
      case ContractStatus.FAILED:
        return 'xyz';
      case ContractStatus.PROCESSING:
        return '';
      default:
        return '';
    }
  };
  const renderContractsGridView = () => {
    const cardTitle = (name: string) => <Popover content={name}>{name}</Popover>;
    const cardBody = (item: any) => (
      <>
        <Row className="contracts-card-body">
          <Col span={12}>
            <div>
              <p className="title">Created by</p>
              <p className="detail">
                {storage.getItem(STORAGE_CONSTANTS.email) &&
                  storage.getItem(STORAGE_CONSTANTS.email)?.split('@')[0]}
              </p>
            </div>
            <div>
              <p className="title">Agreement number</p>
              <p className="detail">
                <Popover content={item.vmware_agreement || 'NA'}>
                  {item.vmware_agreement || 'NA'}
                </Popover>
              </p>
            </div>
          </Col>
          <Col span={12} className="flex-column align-item-end">
            <div>
              <div>
                <p className="title">Created on</p>
                <p className="detail">{item.date}</p>
              </div>
              <div>
                <p className="title">Status</p>
                <p className="detail">
                  <img
                    src={item.status === ContractStatus.SUCCESS ? Completed : Failed}
                    alt=""
                    className="mr-0-dot-5"
                  />
                  {item.status === ContractStatus.SUCCESS
                    ? ContractStatus.EXTRACTED
                    : ContractStatus.FAILED}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
    return (
      <Row className="contracts-card-container" gutter={[16, 16]}>
        {contractsListResponse?.records?.map((item) => {
          // {contractsData.map((item) =contracts-dashboard> {
          return (
            <Col key={item.contract_id} span={8} lg={6} md={8} sm={12}>
              <Card
                className="contracts-card"
                title={cardTitle(item?.contract_name)}
                bordered={false}
                // style={{ width: 300 }}
                actions={[
                  <div key={item.contract_id} className="contract">
                    {item?.contract_type && (
                      <img src={getCategoryIcon(item.contract_type)} alt="" />
                    )}
                    {item?.contract_type}
                  </div>,
                  <div
                    key={item.contract_id}
                    onClick={() => {
                      handleAction(item);
                      setPopoverVisible(false);
                    }}
                    className={`action-view ${item?.status.toLowerCase()}`}
                  >
                    {item.status === ContractStatus.SUCCESS ||
                    item.status === ContractStatus.EXTRACTED
                      ? ContractAction.VIEW_CONTRACT
                      : ContractAction.RE_UPLOAD}
                  </div>,
                ]}
              >
                {cardBody(item)}
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };
  const renderContractsTableView = () => (
    <Table
      className="contract-table"
      rowClassName={(record, index) => (index % 2 === 0 ? 'alternate-table-row-bg' : '')}
      dataSource={contractsListResponse?.records || []}
      columns={[
        { title: 'Contract Name', key: 'contract_name', dataIndex: 'contract_name' },
        { title: 'Created on', key: 'date', dataIndex: 'date' },
        {
          title: 'Created by',
          key: 'created_by',
          dataIndex: 'created_by',
          render: () =>
            storage.getItem(STORAGE_CONSTANTS.email) &&
            storage.getItem(STORAGE_CONSTANTS.email)?.split('@')[0],
        },
        {
          title: 'Agreement number',
          key: 'vmware_agreement',
          dataIndex: 'vmware_agreement',
          width: '20%',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          render: (status) => (
            <div className="align-item-center">
              <img
                src={status === ContractStatus.SUCCESS ? Completed : Failed}
                alt=""
                className="mr-0-dot-5"
              />
              <Typography>
                {status === ContractStatus.SUCCESS
                  ? ContractStatus.EXTRACTED
                  : ContractStatus.FAILED}
              </Typography>
            </div>
          ),
        },
        {
          title: 'Actions',
          key: '',
          dataIndex: '',
          render: (item) => (
            <img
              src={
                item.status === ContractStatus.SUCCESS || item.status === ContractStatus.EXTRACTED
                  ? ViewContractIcon
                  : ReuploadIcon
              }
              title={
                item.status === ContractStatus.SUCCESS || item.status === ContractStatus.EXTRACTED
                  ? 'View Contract'
                  : 'Re-upload'
              }
              alt=""
              className="ml-1 cursor-pointer"
              onClick={() => handleAction(item)}
            />
          ),
        },
      ]}
      pagination={false}
    />
  );

  return (
    <div className="contracts-dashboard">
      <Spin
        className="contract-list-loading"
        spinning={contractsListLoading || contractUploading}
        tip="Fetching Contract Details..."
      >
        {renderFilters()}
        {renderAppliedFilters()}
        {selectedView === ContractView.GRID
          ? renderContractsGridView()
          : renderContractsTableView()}
        {drawerVisible && selectedContract && (
          <ContractsDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            contractDetails={selectedContract}
          />
        )}
        {metadataGuardrailDrawerVisible && (
          <MetadataGuardrailDrawer
            visible={metadataGuardrailDrawerVisible}
            onClose={() => {
              setMetadataGuardrailDrawerVisible(false);
            }}
          />
        )}
        <Pagination
          className="pagination"
          defaultCurrent={1}
          current={pageNumber}
          total={contractsListResponse?.total_records || 0}
          onChange={handlePageNumberChange}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
        />
      </Spin>
      {isUploadFileOpen && (
        <UploadFileModal show={isUploadFileOpen} setShow={setIsUploadFileOpen} />
      )}
    </div>
  );
};

export default MNACIDashboardNew;
