import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { AppSettings } from '../../AppSettings';
import { storage, STORAGE_CONSTANTS } from '../../libs/services/LocalStorage';
import { NotificationActions } from '../../libs/store/actions/NotificationActions';
import {
  ISocketNotificationState,
  IWebSocketEventMap,
  NotificationLabels as LABELS,
} from './NotificationSocketType';

interface INotificationSocketProps {
  children: ReactNode;
}

/* Generic Notification Socket to handle all the notifications
It opens 2 way websocket connection between client and server and remains open till client or server does not closes it.
Props : Dashboard as ReactNode
*/
const NotificationSocket = ({ children }: INotificationSocketProps) => {
  const dispatch = useDispatch();

  const userId = storage.getItem(STORAGE_CONSTANTS.userId);

  const dispatcher = {
    showNotificationsDot: (data: boolean) =>
      dispatch(NotificationActions.toggleLandingZoneNotificationDot(data)),
    showEmailBadge: (data: boolean) =>
      dispatch(NotificationActions.toggleEmailNotificationBadge(data)),
  };

  const showNotificationsDot = (data: boolean) => dispatcher.showNotificationsDot(data);
  const showEmailBadge = (data: boolean) => dispatcher.showEmailBadge(data);

  const onSocketMessage = (event: WebSocketEventMap[IWebSocketEventMap.Message]) => {
    const socketData: ISocketNotificationState = JSON.parse(event.data);
    if (socketData.message === LABELS.newNotification) {
      showNotificationsDot(true);
    }
    if (socketData.message === LABELS.userNotification) {
      showEmailBadge(true);
    }
  };

  // useWebSocket(`${AppSettings.socketURL}?${LABELS.userId}=${userId}`, {
  //   // eslint-disable-next-line no-console
  //   onOpen: () => console.log('Socket opened'),
  //   shouldReconnect: (closeEvent) => true, //It Will attempt to reconnect on all close events, such as server shutting down
  //   onMessage: onSocketMessage,
  //   reconnectInterval: 28000, //To reconnect web socket before 5 mins
  // });

  return <>{children}</>;
};

export default NotificationSocket;
