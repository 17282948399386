export const FeedbackResponseLabels = {
  ratings: 'Rating',
  username: 'Username',
  ratingDateTime: 'Rating Date & Time',
  feedbackType: 'Feedback Type',
  feedback: 'Feedback',
  actions: 'Actions',
  userExperience: 'User Experience',
  features: 'Features',
  functionality: 'Functionality',
  issue: 'Issue',
  featue: 'Feature',
  feedbackSubmited: 'Feedback Submited!',
  search: 'Search',
  reset: 'Reset',
  delete: 'Delete',
  suspend: 'Suspend',
};
export enum FeedbackResponseType {
  REQUEST_FEATURE = 'Request A New Feature',
  FOUND_ISSUE = 'Found An Issue',
}
export interface IFeedbackResponseData {
  Id: number;
  rating: {
    userExperience: string;
    features: string;
    functionality: string;
  };
  user: {
    userName: string;
    userId: string;
  };
  designation: string;
  timeStamp: string;
  feedBackType: FeedbackResponseType;
  comment: string;
}
