export const AUTH_CODE_PARAM_KEY = 'code';

export enum AuthorizableAsset {
  CWB = 'cwb',
  CMS = 'cms',
}

export const AuthenticationPageLabels = {
  authenticating: 'Authenticating ...',
  blockTitle: 'Your Account is Blocked',
  blockSubtitle: 'Please contact admin to access this page.',
  notCreatedTitle: 'Access Denied',
  notCreatedSubTitle: 'You don’t have permission to access this site.',
  goToLogin: 'Go To Login',
  notCreated: 'notCreated',
  authenticatePlaceHolder: ' ',
};
