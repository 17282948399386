import { Button, Drawer, Skeleton, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ILandingZoneNotification, ILandingZoneNotificationReqPayload } from '../../libs/models/NotificationModels';
import { NotificationActions } from '../../libs/store/actions/NotificationActions';
import { useAppSelector } from '../../libs/store/reducers';
import CloseIcon from '../../assets/icons/close-icon.svg';
import NotificationsMobileDrawerCard from './NotificationsMobileDrawerCard';
import BackgroundImage from '../../assets/images/notification-mobile-background.svg';
import './NotificationMobileDrawerStyles.less';

interface INotificationDrawerProps {
  isMobile?: boolean;
}

const NotificationMobileDrawer = ({ isMobile = true }: INotificationDrawerProps) => {
  const dispatch = useDispatch();

  const { landingZoneNotifications, loading, selectedAccountId, selectedProjectId, isVisible } = useAppSelector(
      ({ notification: { landingZoneNotifications, loading, isVisible },
          overview: { selectedAccountId, selectedProjectId },
      }) => ({
          landingZoneNotifications,
          selectedAccountId,
          selectedProjectId,
          loading,
          isVisible,
      }),
  );
    
  const dispatcher = {
    getLandingZoneNotifications: (data: ILandingZoneNotificationReqPayload) =>
     dispatch(NotificationActions.getLandingZoneNotificationStart(data)),
     showNotificationsDrawerMobile: (data: boolean) =>
      dispatch(NotificationActions.toggleLandingZoneNotificationStart(data)),
    toggleNotificationDot: (data: boolean) =>
      dispatch(NotificationActions.toggleLandingZoneNotificationDot(data)),
  };

  useEffect(() => {
    dispatcher.toggleNotificationDot(false);
    if (selectedAccountId) {
      dispatcher.getLandingZoneNotifications({
        accountId: selectedAccountId || '',
        projectId: selectedProjectId || '',
      });
    }
  }, []);

  return (
    <>
      <Drawer
        className="notification-drawer-mobile"
        title={`Notifications (${landingZoneNotifications?.length})`}
        placement={!isMobile ? 'left' : 'right'}
        width={!isMobile ? '100vw' : '23rem'}
        style={{background: BackgroundImage}}
        visible={isVisible}
        extra={
          <Space>
            <Button onClick={() => dispatcher.showNotificationsDrawerMobile(false)} type="text">
              <img src={CloseIcon} alt="close-icon" className="notification-drawer__close__btn" />
            </Button>
          </Space>
        }
      >
        {!loading ? (
          landingZoneNotifications?.map((item: ILandingZoneNotification, index) => (
            <NotificationsMobileDrawerCard key={index} {...item}/>
          ))
        ) : (
          <Skeleton active className="skeleton-container" />
        )}
      </Drawer>
    </>
  );
};

export default NotificationMobileDrawer;
