/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Input, Tabs, Select, Form, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import IFrame from '../../components/Iframe/IFrame';
import './Analysis.less';
import SearchIcon from '../../../src/assets/icons/MNACI/search.svg';
// import FilterIcon from '../../../src/assets/icons/MNACI/filter.svg';
import LeftIcon from '../../../src/assets/icons/MNACI/left.svg';
import AddIcon from '../../../src/assets/icons/MNACI/add.svg';
import NoDataIcon from '../../../src/assets/icons/MNACI/NoData.svg';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { ContractActions } from '../../libs/store/actions/UploadContractActions';
import IContractState, {
  IPostContractsPayload,
  IPostContractsResponse,
} from '../../libs/store/state/ContractState';
import AnalysisTable from './AnalysisTable/AnalysisTable';
import {
  ContractOptions,
  entityOptions,
  operatorOptions,
  AndOptions,
  SearchButtonLabel as LABEL,
} from './AnalysisTable/AnalysisTableConstant';
export interface IAnalysisChartData {
  key: string;
  name: string;
  isIframeUrlPresent: boolean;
  link: string;
  img: string;
}
export enum FieldTypes {
  OPERATOR = 'operator', // Enum for > < = !==
  ENTITY = 'entity', // ENUM for Entity Select
  AND = 'and', // ENUM for And Select
  USERINPUT = 'userinput', // ENUM for text input by user
}
export enum filterType {
  GEN_AI = 'gen_ai',
  KEYWORD = 'keyword',
}
export const analysisChartData: IAnalysisChartData[] = [
  {
    key: '1',
    name: 'Contracts with fees > 800,000',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/b6TdD',
    img: '',
  },
  {
    key: '2',
    name: 'Contracts with fees ranging from highest to lowest',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_5lseoiy39c',
    img: '',
  },
  {
    key: '3',
    name: 'Contracts with expiration date within 6 months',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_cuckx4y39c',
    img: '',
  },
  {
    key: '4',
    name: 'Contracts where SPP Credit allowance is greater than 8000',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_mavwzk869c',
    img: '',
  },
  {
    key: '5',
    name: 'Number of Contracts per Year',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_5r9w1k869c',
    img: '',
  },
  {
    key: '6',
    name: 'Contracts due in 2023',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_wvlcwl869c',
    img: '',
  },
  {
    key: '7',
    name: 'List of customer by contracts',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_wvlcwl869c',
    img: '',
  },
  {
    key: '8',
    name: 'List of contracts due in next 90 days',
    isIframeUrlPresent: true,
    link: 'https://lookerstudio.google.com/embed/reporting/5be6e309-6321-43ed-bc98-46ca58b4c392/page/p_wvlcwl869c',
    img: '',
  },
];
const Analysis = () => {
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState<IAnalysisChartData>(analysisChartData[0]);
  const [searchType, setSearchType] = useState<string>(filterType.GEN_AI);
  const [genAISearch, setGenAISearch] = useState<string>(selectedCard?.name);
  const [inputEnabled, setInputEnabled] = useState<boolean>(false);
  const [filteredChartData, setFilteredChart] = useState<IAnalysisChartData[]>();
  const [formQuery, setFormQuery] = useState<Record<string, string>>({});
  const [current, setCurrent] = useState<number>(1);
  const [isAnalysisCollapsed, setIsAnalysisCollapsed] = useState(false);
  const [contractType, setContractType] = useState<string>('ALL');

  const [formValues, setFormValues] = useState([
    {
      fieldType: 'select',
      fieldValue: '',
      fieldName: 'select1',
      options: entityOptions[`${contractType}`],
      defaultValue: entityOptions[`${contractType}`][0],
      type: FieldTypes.ENTITY,
    },
  ]);
  const { searchedContracts, loading } = useAppSelector(
    ({ contracts: { searchedContracts, loading } }: IApplicationState) => ({
      searchedContracts,
      loading,
    }),
  );

  const dispatcher = {
    postSearchContrat: (searchQuery: IPostContractsPayload) =>
      dispatch(ContractActions.postSearchContractStart(searchQuery)),
    resetContrat: () => dispatch(ContractActions.postSearchContractSuccess(undefined)),
  };
  useEffect(() => {
    // resetting the searchedContracts
    dispatcher.resetContrat();
  }, []);
  useEffect(() => {
    let obj = { ...formValues[0] };
    obj.options = entityOptions[contractType];
    setFormValues([obj]);
  }, [contractType]);
  const handleContractChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    setContractType(e.currentTarget.value);
  };
  const handleSelectChange = (
    event: React.SyntheticEvent<HTMLSelectElement>,
    key: number,
    type: string,
  ) => {
    // const componentName = (event.currentTarget as HTMLSelectElement).getAttribute('data-name');
    const obj = {
      ...formQuery,
      [`${event.currentTarget.name}`]: `${event.currentTarget.value}`,
    };
    setFormQuery(obj);
    const addField = (type: string) => {
      const isSelectAdded = formValues.filter((item) => item.fieldName === `select${key + 1}`);
      const isInputAdded = formValues.filter((item) => item.fieldName === `input${key + 1}`);
      if (type == FieldTypes.ENTITY && !isSelectAdded.length) {
        setFormValues([
          ...formValues,
          {
            fieldType: 'select',
            fieldValue: '',
            fieldName: `select${key + 1}`,
            options: operatorOptions,
            defaultValue: operatorOptions[0],
            type: FieldTypes.OPERATOR,
          },
        ]);
      } else if (type == FieldTypes.OPERATOR && !isInputAdded.length) {
        setFormValues([
          ...formValues,
          {
            fieldType: 'input',
            fieldValue: '',
            fieldName: `input${key + 1}`,
            options: operatorOptions,
            defaultValue: operatorOptions[0],
            type: FieldTypes.USERINPUT,
          },
        ]);
      } else if (type == FieldTypes.AND && !isSelectAdded.length) {
        setFormValues([
          ...formValues,
          {
            fieldType: 'select',
            fieldValue: '',
            fieldName: `select${key + 1}`,
            options: entityOptions[`${contractType}`],
            defaultValue: entityOptions[`${contractType}`][0],
            type: FieldTypes.ENTITY,
          },
        ]);
      } else {
        return;
      }
    };
    addField(type);
  };
  const handleInputChange = (e: any, key: number) => {
    let prevField = formValues.filter((item) => item.fieldName === `select${key - 1}`);
    let obj;
    //condition to check if the previous selected value is contains
    if (formQuery[prevField?.[0]?.fieldName] === 'like') {
      obj = {
        ...formQuery,
        [`${e.target.name}`]: `'%${e.target.value}%'`,
      };
    } else {
      obj = {
        ...formQuery,
        [`${e.target.name}`]: `'${e.target.value}'`,
      };
    }

    setFormQuery(obj);
    const isAdded = formValues.filter((item) => item.fieldName === `select${key + 1}`);
    if (!isAdded.length) {
      setFormValues([
        ...formValues,
        {
          fieldType: 'select',
          fieldValue: '',
          fieldName: `select${key + 1}`,
          options: AndOptions,
          defaultValue: AndOptions[0],
          type: FieldTypes.AND,
        },
      ]);
    }
  };
  const handleSubmit = () => {
    const searchObj = {
      'query_type': searchType === filterType.GEN_AI ? filterType.GEN_AI : filterType.KEYWORD, //keyword or genAI
      'query_string':
        searchType === filterType.GEN_AI ? genAISearch : Object.values(formQuery).join(' '),
      'page_num': 1,
      'page_size': 10,
    };
    dispatcher.postSearchContrat(searchObj);
    setCurrent(1);
  };

  const searchHandler = () => {
    setInputEnabled(!inputEnabled);
  };

  const onSearch = (searchText: React.ChangeEvent<HTMLInputElement>) => {
    const filteredAnalysisChart = analysisChartData.filter(({ name }) => {
      return name?.toLowerCase().includes(searchText.target.value.toLowerCase());
    });
    setFilteredChart(filteredAnalysisChart);
  };

  const renderGraphCard = (item: IAnalysisChartData) => {
    return (
      <>
        <Spin className="contract-list-loading" spinning={loading} tip="Please Wait...">
          <div className="graph-card">
            {searchedContracts ? (
              <>
                <div className="graph-card-header-container">
                  {searchedContracts.search_string}

                  {/* <Button type="text" className="view-more-lnk" onClick={() => {}}>
              View More
            </Button> */}
                </div>
                <hr />
                <div className="graph-card-content">
                  {/* <IFrame src={item.link} loading={loading} setLoading={setLoading} /> */}
                  {/* <img src={`${process.env.PUBLIC_URL}/dummy-images/${item.image}`} alt="graph" /> */}
                  <Tabs>
                    <Tabs.TabPane tab={'Tabular Representation'} key={'1'}>
                      <AnalysisTable
                        queryInput={item.key}
                        tableHeaders={item.key}
                        tableData={searchedContracts}
                        queryType={searchType}
                        formSearch={formQuery}
                        contextSearch={genAISearch}
                        current={current}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <>
                          {'Graphical Representation '}
                          <span
                            style={{
                              color: 'white',
                              background: 'black',
                              borderRadius: '0.3rem',
                              padding: '0.2rem 0.4rem',
                            }}
                          >
                            BETA
                          </span>
                        </>
                      }
                      key={'2'}
                    >
                      <div className="no-graphs-available">Feature Coming Soon</div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </>
            ) : (
              <div className="align-item-center flex-column no-data-wrapper">
                <>
                  <img src={NoDataIcon} alt="" />
                  <Typography className="font-bold font-16"> No Data Available</Typography>
                  <Typography className="font-14">
                    {' '}
                    Please search or add conditions to view data.
                  </Typography>
                </>
              </div>
            )}
          </div>
        </Spin>
      </>
    );
  };
  const renderSearchBar = () => {
    const fieldsToRender = formValues.map((item, key) => {
      switch (item.fieldType) {
        case 'select':
          return (
            <select
              key={key}
              className="select-form"
              // defaultValue={item.defaultValue}
              name={item.fieldName}
              onChange={(e) => handleSelectChange(e, key + 1, item?.type)}
              // options={item.options}
            >
              {item.options?.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          );
        case 'input':
          return (
            <Input
              className="input-field"
              key={key}
              placeholder="Enter"
              name={item.fieldName}
              onChange={debounce((e) => handleInputChange(e, key + 1), 500)}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          );
        default:
          return '';
      }
    });
    return (
      <>
        <Form size="small" className="search-form" layout="horizontal" wrapperCol={{ span: 24 }}>
          <Col span={3} style={{ display: 'flex' }}>
            <Button
              className={`fieldBtn ${searchType == filterType.GEN_AI ? 'selected' : ''}`}
              onClick={() => setSearchType(filterType.GEN_AI)}
            >
              {LABEL.gen_ai}
            </Button>
            <Button
              className={`fieldBtn ${searchType == filterType.KEYWORD ? 'selected' : ''}`}
              onClick={() => setSearchType(filterType.KEYWORD)}
            >
              {LABEL.keyword}
            </Button>
          </Col>
          {searchType === filterType.KEYWORD ? (
            <Col span={19}>
              <select
                key={'select0'}
                className="select-form"
                // defaultValue={item.defaultValue}
                name={'select0'}
                onChange={(e) => handleContractChange(e)}
                // options={item.options}
              >
                {ContractOptions?.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
              {fieldsToRender.map((item) => item)}
            </Col>
          ) : (
            <Col span={19}>
              <Input
                className="input-field gen-ai"
                value={genAISearch}
                onChange={(e) => setGenAISearch(e.target.value)}
                placeholder="Type your query here"
              />
            </Col>
          )}
          <Col span={2} className="align-item-center justify-content-end">
            <Button className="generate-btn" onClick={handleSubmit}>
              Generate
            </Button>
          </Col>
        </Form>
      </>
    );
  };
  return (
    <>
      <div className="analysis-graph-container">
        {renderSearchBar()}
        <Row gutter={[16, 16]}>
          <Col span={isAnalysisCollapsed ? 1 : 7}>
            <div className="contracts-pane-container">
              <div className={`header ${isAnalysisCollapsed ? 'flex-center' : ''}`}>
                {!isAnalysisCollapsed && (
                  <Typography className="title font-18"> Contracts Analysis</Typography>
                )}

                <div className="d-flex flex-row-space-between">
                  {!isAnalysisCollapsed &&
                    (!inputEnabled ? (
                      <Button
                        icon={<img src={SearchIcon} onClick={searchHandler} alt="" />}
                      ></Button>
                    ) : (
                      <Input placeholder="Search" onChange={onSearch} />
                    ))}
                  {/* <Button
                    icon={
                    }
                  ></Button> */}
                  <img
                    src={LeftIcon}
                    alt=""
                    className={`cursor-pointer ${
                      isAnalysisCollapsed ? 'rotate-180-deg' : 'ml-0-dot-5'
                    }`}
                    onClick={() => setIsAnalysisCollapsed((oldState) => !oldState)}
                  />
                  {/* <Button icon={<img src={AddIcon} alt="" />}></Button> */}
                </div>
              </div>
              {!isAnalysisCollapsed && (
                <Tabs style={{ display: 'flex', paddingLeft: '1rem' }}>
                  <Tabs.TabPane tab={'Suggested Questions'} key={'1'}>
                    <div className="contract-container">
                      {filteredChartData
                        ? filteredChartData.map((card) => (
                            <div
                              key={card.name}
                              className={`contract-card ${
                                selectedCard?.key === card.key ? 'contract-card-selected' : ''
                              }`}
                              onClick={() => {
                                setSelectedCard(card);
                                setGenAISearch(card.name);
                                setSearchType(filterType.GEN_AI);
                              }}
                            >
                              <div className="contract-header">
                                <Typography className="title font-14">{card.name}</Typography>
                              </div>
                            </div>
                          ))
                        : analysisChartData.map((card) => (
                            <div
                              key={card.name}
                              className={`contract-card ${
                                selectedCard?.key === card.key ? 'contract-card-selected' : ''
                              }`}
                              onClick={() => {
                                setSearchType(filterType.GEN_AI);
                                setGenAISearch(card.name);
                                setSelectedCard(card);
                              }}
                            >
                              <div className="contract-header">
                                <Typography className="title font-14">{card.name}</Typography>
                                {/* <p>{'GEN AI'}</p> */}
                              </div>
                            </div>
                          ))}
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <>
                        {'Recent Search Queries '}
                        <span
                          style={{
                            color: 'white',
                            background: 'black',
                            borderRadius: '0.3rem',
                            padding: '0.2rem 0.4rem',
                          }}
                        >
                          BETA
                        </span>
                      </>
                    }
                    key={'2'}
                  >
                    Feature Coming Soon
                  </Tabs.TabPane>
                </Tabs>
              )}
            </div>
          </Col>
          {selectedCard && (
            <Col span={isAnalysisCollapsed ? 23 : 17} className="analysis-graph-row">
              {renderGraphCard(selectedCard)}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default Analysis;
