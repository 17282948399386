export const AssessmentDetailsLabels = {
  legend: 'Legend',
  clickIndustry: 'Click here to see Industry chart',
  industryAverage: 'Industry Average',
  yourScore: 'Your Score',
  assessmentCategories: 'Assessment by Categories',
  loadingTitle: 'Fetching Charts ...',
};

export const AssessmentDetailsColor = [
  ['#da2a1e', '#f9dad8'],
  ['#ffce03', '#ffe785'],
  ['#88bd29', '#d2eca1'],
];

export const AssessmentDetailsLegendRanges = [
  [0, 50],
  [50, 75],
  [75, 100],
];

export const IndustryChartColor = ['#E4D354', '#29908F'];

export const RecommendationsLabels = {
  cwbInsight: 'Deloitte CWB Insight',
  more: 'Click here for more..',
  highScoreMessage:
    'Organization is outperforming the industry in this dimension. Continue to periodically review this dimension and adapt for a successful cloud adoption journey',
};

export const getLowScoreMessage = (subCategory: string): string =>
  `Organization is comparable to the industry benchmark. Prioritize and focus efforts to improve ${subCategory} for seamless cloud adoption`;
