import { ICWBLoginResponse } from '../../models/CWBLoginModels';
import {
  ILoginPayload,
  ILoginResponse,
  IRegisterUserPayload,
  ITangraLoginPayload,
  ITangraLoginResponse,
} from '../../models/LoginModels';
import { IStoreAction } from '../StoreHelper';

export enum LoginActionTypes {
  CWB_LOGIN_REQUEST = 'CWB_LOGIN_REQUEST',
  CWB_LOGIN_SUCCESS = 'CWB_LOGIN_SUCCESS',
  CWB_LOGIN_ERROR = 'CWB_LOGIN_ERROR',
  TANGRA_LOGIN_REQUEST = 'TANGRA_LOGIN_REQUEST',
  TANGRA_LOGIN_SUCCESS = 'TANGRA_LOGIN_SUCCESS',
  TANGRA_LOGIN_ERROR = 'TANGRA_LOGIN_ERROR',
  CWB_SSO_LOGIN_REQUEST = 'CWB_SSO_LOGIN_REQUEST',
  CWB_SSO_LOGIN_SUCCESS = 'CWB_SSO_LOGIN_SUCCESS',
  CWB_SSO_LOGIN_ERROR = 'CWB_SSO_LOGIN_ERROR',
  SESSION_LOGIN_TOKEN_START = 'SESSION_LOGIN_TOKEN_START',
  SESSION_LOGIN_TOKEN_SUCCESS = 'SESSION_LOGIN_TOKEN_SUCCESS',
  SESSION_LOGIN_TOKEN_ERROR = 'SESSION_LOGIN_TOKEN_ERROR',
  POST_SESSION_LOGIN_TOKEN_START = 'POST_SESSION_LOGIN_TOKEN_START',
  POST_SESSION_LOGIN_TOKEN_SUCCESS = 'POST_SESSION_LOGIN_TOKEN_SUCCESS',
  POST_SESSION_LOGIN_TOKEN_ERROR = 'POST_SESSION_LOGIN_TOKEN_ERROR',
}

export type LoginActionPayload =
  | ILoginPayload
  | IRegisterUserPayload
  | ILoginResponse
  | ICWBLoginResponse
  | ITangraLoginPayload
  | ITangraLoginResponse
  | Error
  | null;

export type LoginAction = IStoreAction<LoginActionTypes, LoginActionPayload>;
export class LoginActions {
  public static postCWBLoginStart(data: ILoginPayload): LoginAction {
    return {
      type: LoginActionTypes.CWB_LOGIN_REQUEST,
      data,
    };
  }
  public static postCWBLoginSuccess(data: ILoginResponse | ICWBLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.CWB_LOGIN_SUCCESS,
      data,
    };
  }
  public static postCWBLoginError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.CWB_LOGIN_ERROR,
      data: error,
    };
  }

  public static postTangraLoginStart(data: ITangraLoginPayload): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_REQUEST,
      data,
    };
  }
  public static postTangraLoginSuccess(data: ITangraLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_SUCCESS,
      data,
    };
  }
  public static postTangraLoginError(error: any): LoginAction {
    return {
      type: LoginActionTypes.TANGRA_LOGIN_ERROR,
      data: error,
    };
  }

  public static postCwbSsoLoginStart(data: ITangraLoginPayload): LoginAction {
    return {
      type: LoginActionTypes.CWB_SSO_LOGIN_REQUEST,
      data,
    };
  }
  public static postCwbSsoLoginSuccess(data: ITangraLoginResponse): LoginAction {
    return {
      type: LoginActionTypes.CWB_SSO_LOGIN_SUCCESS,
      data,
    };
  }
  public static postCwbSsoLoginError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.CWB_SSO_LOGIN_ERROR,
      data: error,
    };
  }

  public static sessionTokenStart(): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_START,
    };
  }
  public static sessionTokenSuccess(): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_SUCCESS,
    };
  }
  public static sessionTokenError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.SESSION_LOGIN_TOKEN_ERROR,
      data: error,
    };
  }

  public static sessionTokenPostStart(): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_START,
    };
  }
  public static sessionTokenPostSuccess(): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_SUCCESS,
    };
  }
  public static sessionTokenPostError(error: Error): LoginAction {
    return {
      type: LoginActionTypes.POST_SESSION_LOGIN_TOKEN_ERROR,
      data: error,
    };
  }
}
