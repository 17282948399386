/* eslint-disable max-lines */
import { put, select, takeEvery } from 'redux-saga/effects';
import { setCommentRange } from 'typescript';
import { CloudServiceProvider } from '../../../AppConstants';
import { Toast } from '../../../components/Notifications/Notifications';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import {
  ILandingZoneResponse,
  ILandingZoneSector,
  LandingZoneStatus,
  LzMicroserviceStatus,
} from '../../models/BuildDeployModels';
import {
  ICheckLandingZoneStatus,
  ICheckMicroServicesPatternStatus,
  IConfigureWorkLoadsPattern,
} from '../../models/DashboardModels';
import BuildDeployService from '../../services/BuildDeployService/BuildDeployService';
import {
  BuildDeployAction,
  BuildDeployActions,
  BuildDeployActionTypes,
  ILandingZoneErrorResponse,
} from '../actions/BuildDeployActions';
import { ToastActions } from '../actions/ToastActions';
import { IApplicationState } from '../reducers';
import {
  IArchitectureCataloguePayload,
  IArchitectureCatalogueResponse,
  ICloudLandingZoneKeysPayload,
  ICloudLandingZoneKeysResponse,
  IConfigureDeployCloudPayload,
  IConfigureDeployCloudThroughConsolePayload,
  IConfigureLandingZonePayload,
  IConfigureLandingZoneRequestPayload,
  IConfigureLZLoadingPayload,
  ICreateYamlPayload,
  IDeleteLandingZonePayload,
  IDeleteWorkLoadPatternPayload,
  IDeployActivityHistory,
  IDeploymentLogsPayload,
  IDeploymentLogsResponse,
  IDeploymentsResponse,
  IEnvironmentsDeployed,
  ILandingZoneDeploymentResponse,
  ILandingZoneDeploymentStatus,
  ILandingZoneState,
  IPipelinesAnalyticsResponse,
  IPipelinesCummulativeAnalyticsResponse,
  IUpdateLandingZoneDeployDetails,
  IYamlContent,
  IFormInterface,
  IIndustryYamlPayload,
  IGetKeysRequestBody,
} from '../state/BuildDeployState';

export function* getLandingZonesWorker(action: BuildDeployAction) {
  try {
    //API call to get all the landing zones
    const projectId = action.data as string;
    const landingZoneList: ILandingZoneResponse[] = yield BuildDeployService.getLandingZone(
      projectId,
    );
    const landingZones: ILandingZoneState[] = landingZoneList.map(
      ({
        LandingZoneName,
        CurrentStatus,
        CloudServiceProvider,
        LandingZoneId,
        Sector,
        GCPProjectId,
        MicroserviceInfo,
        Errors,
        IAC
      }) => {
        const isEnabled = CurrentStatus === LandingZoneStatus.CREATED;
        const microServicesConfigured = MicroserviceInfo?.status === LzMicroserviceStatus.CREATED;
        const microServicesError = MicroserviceInfo?.status === LzMicroserviceStatus.ERROR;
        return {
          landingZoneId: LandingZoneId,
          landingZoneEnabled: isEnabled,
          landingZoneConfigured: isEnabled,
          landingZoneName: LandingZoneName,
          projectName: GCPProjectId,
          loadingLandingZone: !isEnabled,
          loadingEtlWorkload: false,
          sector: Sector,
          loadingMicroServicesWorkload: Boolean(
            isEnabled && MicroserviceInfo?.ids && !microServicesConfigured && !microServicesError,
          ),
          workLoadPatternMicroServicesEnabled: microServicesConfigured,
          workLoadPatternEtlEnabled: false,
          workLoadPatternMicroServicesConfigured: microServicesConfigured,
          workLoadPatternMicroServicesError: microServicesError,
          workLoadPatternEtlConfigured: false,
          cloudServiceProvider: CloudServiceProvider,
          workLoadPatternMicroServicesId: MicroserviceInfo?.ids,
          landingZoneStatus: CurrentStatus,
          errorMessage: Errors,
          IAC,
        };
      },
    );

    yield put(BuildDeployActions.getBuildDeployDetailsSuccess(landingZones));
    // Comment out if there are any dummy In Progress LZs
    // for (const { landingZoneName, landingZoneId } of landingZones.filter(
    //   (landingZone) => landingZone.loadingLandingZone,
    // )) {
    //   yield put(
    //     BuildDeployActions.checkLandingZoneStatus({
    //       landingZoneId: landingZoneId,
    //       landingZoneName: landingZoneName,
    //     }),
    //   );
    // }

    for (const { landingZoneName, workLoadPatternMicroServicesId } of landingZones.filter(
      (landingZone) => landingZone.loadingMicroServicesWorkload,
    )) {
      if (workLoadPatternMicroServicesId)
        yield put(
          BuildDeployActions.checkMicroServicesPatternStatus({
            microServiceId: workLoadPatternMicroServicesId,
            landingZoneName: landingZoneName,
          }),
        );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getBuildDeployDetailsError(error));
    }
  }
}

export function* destroyLandingZoneWorker(action: BuildDeployAction) {
  try {
    //API call to destroy landing zone
    const payload = action.data as IDeleteLandingZonePayload;
    const { landingZoneName, landingZoneId } = payload;
    const response: string = yield BuildDeployService.destroyLaningZone(payload);
    yield put(BuildDeployActions.deleteLandingZoneSuccess(landingZoneId));
    yield put(
      ToastActions.showToast({
        description: `${landingZoneName || 'Cloud Environment'} deleting in progress`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.deleteLandingZoneError(error));
    }
  }
}

export function* destroyWorkLoadPatternWorker(action: BuildDeployAction) {
  try {
    //API call to destroy landing zone
    const payload = action.data as IDeleteWorkLoadPatternPayload;
    const { landingZoneId, landingZoneName } = payload;
    const response: string = yield BuildDeployService.destroyWorkLoadPattern(payload);
    yield put(BuildDeployActions.destroyWorkLoadPatternSuccess(landingZoneId));
    yield put(
      ToastActions.showToast({
        description: `WorkLoad Pattern For Microservices - ${
          landingZoneName || 'Cloud Environment'
        } deleted Successfully`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.deleteLandingZoneError(error));
    }
  }
}

export function* enableLandingZoneWorker(action: BuildDeployAction) {
  try {
    // API call to enable landing zone
    yield put(
      ToastActions.showToast({
        description: `Cloud Environment enabled`,
        type: NotificationTypes.SUCCESS,
      }),
    );
    yield put(BuildDeployActions.enableLandingZoneSuccess(action.data as number));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.enableLandingZoneError(error));
    }
  }
}

export function* enableMicroServicesWorkload(action: BuildDeployAction) {
  try {
    // API call to enable Microservices zone
    yield put(BuildDeployActions.enableMicroServicesPatternSuccess(action.data as number));

    const buildDeployLZ = ({ buildDeploy: { landingZones } }: IApplicationState) => landingZones;
    const landingZones: ILandingZoneState[] = yield select(buildDeployLZ);
    const microServiceEnableState =
      landingZones[action.data as number].workLoadPatternMicroServicesEnabled;
    const msConfigured = landingZones[action.data as number].workLoadPatternMicroServicesConfigured;
    if (!msConfigured) {
      yield put(
        ToastActions.showToast({
          description: `Microservices Workload ${microServiceEnableState ? 'enabled' : 'disabled'}`,
          type: NotificationTypes.SUCCESS,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.enableMicroServicesPatternError(error));
    }
  }
}

export function* enableEtlWorkLoadWorker(action: BuildDeployAction) {
  try {
    // API call to enable Microservices zone
    yield put(BuildDeployActions.enableEtlPatternSuccess(action.data as number));
    const buildDeployLZ = ({ buildDeploy: { landingZones } }: IApplicationState) => landingZones;
    const landingZones: ILandingZoneState[] = yield select(buildDeployLZ);
    const ETLEnableState = landingZones[action.data as number].workLoadPatternEtlEnabled;
    yield put(
      ToastActions.showToast({
        description: `ETL Workload ${ETLEnableState ? 'enabled' : 'disabled'}`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.enableEtlPatterError(error));
    }
  }
}

export function* configureLandingZoneWorker(action: BuildDeployAction) {
  try {
    // API call to configure landing zone
    const { index, landingZoneName, uploadData, providerName } =
      action.data as IConfigureLandingZonePayload;
    const requestPayload = { index, landingZoneName, uploadData, providerName };
    const data = JSON.parse(uploadData?.userdata.content);
    const response: ILandingZoneState = yield BuildDeployService.startUploadFunc(requestPayload);
    const landingzoneId = response.landingZoneId;
    const loadingContent: IConfigureLZLoadingPayload = {
      csp: providerName as CloudServiceProvider,
      landingZoneId: landingzoneId,
      index: 0,
      landingZoneName: landingZoneName,
      sector: data.sector,
    };
    yield put(BuildDeployActions.configureLandingZoneLoading(loadingContent));
    yield put(
      ToastActions.showToast({
        description: `${landingZoneName} Landing Zone Creating`,
        type: NotificationTypes.SUCCESS,
      }),
    );

    yield put(
      BuildDeployActions.checkLandingZoneStatus({
        landingZoneId: response.landingZoneId,
        landingZoneName: landingZoneName,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      const { index } = action.data as IConfigureLandingZoneRequestPayload;
      yield put(BuildDeployActions.configureLandingZoneError({ error, index: index }));
      yield put(
        ToastActions.showToast({
          description: `Cloud Environment creation failed`,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* configureMicroServicesWorker(action: BuildDeployAction) {
  try {
    const { landingZoneName } = action.data as IConfigureWorkLoadsPattern;
    const microServiceId: string = yield BuildDeployService.uploadMicroservicesYamlFnc(
      action.data as IConfigureWorkLoadsPattern,
    );
    yield put(
      BuildDeployActions.configureMicroServicesLoading({ landingZoneName, microServiceId }),
    );
    yield put(
      ToastActions.showToast({
        description: `Microservice Creating`,
        type: NotificationTypes.SUCCESS,
      }),
    );

    yield put(
      BuildDeployActions.checkMicroServicesPatternStatus({ microServiceId, landingZoneName }),
    );
  } catch (error) {
    if (error instanceof Error) {
      const { landingZoneName } = action.data as IConfigureWorkLoadsPattern;
      yield put(BuildDeployActions.configureMicroServicesPatternError({ error, landingZoneName }));
      yield put(
        ToastActions.showToast({
          description: `Microservice creation failed`,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}

export function* configureEtlWorker(action: BuildDeployAction) {
  try {
    // API call to configure landing zone
    yield put(BuildDeployActions.configureEtlPatternSuccess(action.data as number));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.configureEtlPatternError(error));
    }
  }
}
export function* getArchitectureListWorker(action: BuildDeployAction) {
  try {
    const architectureList: IArchitectureCatalogueResponse[] =
      yield BuildDeployService.getArchitectureListServive();
    yield put(
      BuildDeployActions.getArchitectureListSuccess(
        architectureList as IArchitectureCatalogueResponse[],
      ),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getArchitectureListError(error));
    }
  }
}

export function* checkLandingZoneStatus(action: BuildDeployAction) {
  try {
    const { landingZoneId, landingZoneName } = action.data as ICheckLandingZoneStatus;
    const status: string = yield BuildDeployService.checkLandingZoneStatus(landingZoneId);
    //TODO : Need to get response object structure changed from backend.
    if (status.toLowerCase().includes(LandingZoneStatus.CREATED.toLowerCase())) {
      yield put(BuildDeployActions.configureLandingZoneSuccess(landingZoneName));
      yield put(
        ToastActions.showToast({
          description: `Landing zone - ${landingZoneName} created!`,
          type: NotificationTypes.SUCCESS,
        }),
      );
    } else if (status.toLowerCase().includes(LandingZoneStatus.ERROR.toLowerCase())) {
      yield put(BuildDeployActions.configureLandingZoneFailed(landingZoneName));
      yield put(
        ToastActions.showToast({
          description: `Landing zone - ${landingZoneName} Error!`,
          type: NotificationTypes.SUCCESS,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      Toast(`Error in fetching Landing zone status`, '', NotificationTypes.ERROR);
    }
  }
}

export function* checkMicroServicesPatternStatus(action: BuildDeployAction) {
  try {
    const { microServiceId, landingZoneName } = action.data as ICheckMicroServicesPatternStatus;
    const status: string = yield BuildDeployService.checkMicroServicesPatternStatus(microServiceId);
    //TODO : Need to get response object structure changed from backend.
    if (status.toLowerCase().includes(LzMicroserviceStatus.CREATED.toLowerCase())) {
      yield put(
        BuildDeployActions.configureMicroServicesPatternSuccess({
          microServiceId,
          landingZoneName,
        }),
      );
      yield put(
        ToastActions.showToast({
          description: `Microservice for landing zone - ${landingZoneName} created!`,
          type: NotificationTypes.SUCCESS,
        }),
      );
    } else if (status.toLowerCase().includes(LzMicroserviceStatus.ERROR.toLowerCase())) {
      yield put(BuildDeployActions.configureMicroServicesPatternFailed(landingZoneName));
      yield put(
        ToastActions.showToast({
          description: `Microservice for landing zone - ${landingZoneName} Error!`,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      Toast(`Error in fetching microservice status`, '', NotificationTypes.ERROR);
    }
  }
}

export function* getLandingZoneError(action: BuildDeployAction) {
  try {
    const response: ILandingZoneErrorResponse = yield BuildDeployService.getLandingZoneError(
      action.data as string,
    );
    yield put(BuildDeployActions.getLandingZoneErrorSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getLandingZoneErrorFailed(error));
    }
  }
}

export function* createLandingZoneYamlWorker(action: BuildDeployAction) {
  try {
    const { landingZoneName } = action.data as ICreateYamlPayload;
    const response: { body: string } = yield BuildDeployService.createLandingZoneService(
      action.data as ICreateYamlPayload,
    );
    console.log(response);

    yield put(BuildDeployActions.createLandingZoneYamlSuccess(response.body));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.createLandingZoneYamlError(error));
    }
  }
}
export function* architectureViewCountWorker(action: BuildDeployAction) {
  try {
    yield BuildDeployService.setArchitectureViewCount(action.data as string);
    yield put(BuildDeployActions.onArchitectureViewCountSuccess());
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.onArchitectureViewCountError(error));
    }
  }
}
export function* architectureDiagramWorker(action: BuildDeployAction) {
  try {
    yield BuildDeployService.setArchitectureDiagram(action.data as IArchitectureCataloguePayload);
    yield put(BuildDeployActions.setArchitectureDiagramSuccess());
    yield put(BuildDeployActions.getArchitectureListStarted());
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.onArchitectureViewCountError(error));
    }
  }
}

export function* getEnvironmentDeployedWorker(action: BuildDeployAction) {
  try {
    const response: IEnvironmentsDeployed[] =
      yield BuildDeployService.getEnvironmentsDeployedService(action.data as string);
    yield put(BuildDeployActions.getDeployedEnvironmentsDataSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getDeployedEnvironmentsDataError(error));
    }
  }
}
export function* landingZoneDeploymentStatusWorker(action: BuildDeployAction) {
  try {
    const response: ILandingZoneDeploymentStatus[] =
      yield BuildDeployService.getLandingZoneDeploymentStatusServive(action.data as string);
    yield put(BuildDeployActions.landingZoneDeploymentStatusSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.landingZoneDeploymentStatusError(error));
    }
  }
}
export function* pipelinesAnalyticsWorker(action: BuildDeployAction) {
  try {
    const response: IPipelinesAnalyticsResponse[] =
      yield BuildDeployService.getPipelineAnalyticsService();
    yield put(BuildDeployActions.getPipelinesAnalyticsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getPipelinesAnalyticsError(error));
    }
  }
}
export function* getYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: IYamlContent = yield BuildDeployService.getYamlContent(action.data as string);
    yield put(BuildDeployActions.getYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getYamlContentError(error));
    }
  }
}

export function* getDeafultTemplateYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: IYamlContent = yield BuildDeployService.getDefaultTemplateYamlContent(action.data as ICloudLandingZoneKeysPayload);
    yield put(BuildDeployActions.getDefaultTemplateYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getDefaultTemplateYamlContentError(error));
    }
  }
}
export function* getIndustryYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: string = yield BuildDeployService.getIndustryYamlContent(
      action.data as IIndustryYamlPayload,
    );
    yield put(BuildDeployActions.getIndustryYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getIndustryYamlContentError(error));
    }
  }
}
export function* pipelinesCummulativeAnalyticsWorker(action: BuildDeployAction) {
  try {
    const response: IPipelinesCummulativeAnalyticsResponse[] =
      yield BuildDeployService.getPipelineCummulativeAnalyticsService();
    yield put(BuildDeployActions.getPipelinesCummulativeAnalyticsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getPipelinesCummulativeAnalyticsError(error));
    }
  }
}
export function* getCloudLandingZoneKeysWorker(action: BuildDeployAction) {
  try {
    const response: ICloudLandingZoneKeysResponse[] =
      yield BuildDeployService.getCloudLandingZoneKeyservice(action.data as IGetKeysRequestBody);
    yield put(BuildDeployActions.getCloudLandingZoneKeysSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getCloudLandingZoneKeysError(error));
    }
  }
}
export function* setCloudLandingZoneKeysWorker(action: BuildDeployAction) {
  const data = action.data as ICloudLandingZoneKeysPayload;
  const { landingZoneName, cloud } = action.data as ICloudLandingZoneKeysPayload;
  try {
    const response: ILandingZoneState = yield BuildDeployService.setCloudLandingZoneKeyservice(
      data,
    );
    const loadingContent: IConfigureLZLoadingPayload = {
      csp: cloud as CloudServiceProvider,
      landingZoneId: response.landingZoneId,
      index: 0,
      landingZoneName: landingZoneName,
      sector: ILandingZoneSector.GENERAL,
    };
    yield put(BuildDeployActions.configureLandingZoneLoading(loadingContent));
    yield put(
      ToastActions.showToast({
        description: `${landingZoneName} Landing Zone Creating`,
        type: NotificationTypes.SUCCESS,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.setCloudLandingZoneKeysError(error));
      yield put(
        ToastActions.showToast({
          description: `Cloud Environment creation failed`,
          type: NotificationTypes.ERROR,
        }),
      );
    }
  }
}
export function* configureDeployCloudWorker(action: BuildDeployAction) {
  try {
    const response: { instanceId: string } = yield BuildDeployService.configureDeployCloudService(
      action.data as IConfigureDeployCloudPayload,
    );
    yield put(BuildDeployActions.configureDeployCloudSuccess(response.instanceId));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.configureDeployCloudError(error));
    }
  }
}

export function* configureDeployCloudThroughConsoleWorker(action: BuildDeployAction) {
  try {
    const response: { status: string } =
      yield BuildDeployService.configureDeployCloudThroughConsoleService(
        action.data as IConfigureDeployCloudThroughConsolePayload,
      );
    yield put(BuildDeployActions.configureDeployCloudThroughConsoleSuccess(response.status));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.configureDeployCloudThroughConsoleError(error));
    }
  }
}

export function* cloudLandingZoneDeployStatusWorker(action: BuildDeployAction) {
  try {
    const response: ILandingZoneDeploymentResponse =
      yield BuildDeployService.cloudLandingZoneDeployStatusService(action.data as string);
    yield put(BuildDeployActions.cloudLandingZoneDeployStatusSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.cloudLandingZoneDeployStatusError(error));
    }
  }
}

export function* getDeploymentsWorker(action: BuildDeployAction) {
  try {
    //API call to get all deployment work flows
    const projectId = action.data as string;
    const deployments: IDeploymentsResponse[] = yield BuildDeployService.getDeploymentsList(
      projectId,
    );

    yield put(BuildDeployActions.getDeploymentDetailsSuccess(deployments));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getDeploymentDetailsError(error));
    }
  }
}

export function* getDeploymentAuditsWorker(action: BuildDeployAction) {
  try {
    //API call to get all deployment work flows
    // const { landingZoneId, workflowId } = action.data as IDeploymentLogsPayload;

    const payload = action.data as IDeploymentLogsPayload;
    const { landingZoneId, workflowId } = payload;

    const deployments: IDeploymentLogsResponse[] = yield BuildDeployService.getDeploymentAuditList(
      landingZoneId,
      workflowId,
    );

    yield put(BuildDeployActions.getDeploymentAuditSuccess(deployments));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getDeploymentAuditError(error));
    }
  }
}

export function* cloudLandingZoneDeployDetailsWorker(action: BuildDeployAction) {
  try {
    const { status, landingZoneId, workflowId, instanceId } =
      action.data as IUpdateLandingZoneDeployDetails;
    const response: IDeployActivityHistory = yield BuildDeployService.updateLandingZoneDeployLogs(
      status,
      landingZoneId,
      workflowId,
      instanceId,
    );
    yield put(BuildDeployActions.cloudLandingZoneDeployDetailedLogsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.cloudLandingZoneDeployDetailedLogsError(error));
    }
  }
}
export function* getMultiCloudYamlContentWorker(action: BuildDeployAction) {
  try {
    const response: Record<string, unknown> = yield BuildDeployService.getMultiCloudYamlContent(
      action.data as IFormInterface,
    );
    yield put(BuildDeployActions.getMultiCloudYamlContentSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(BuildDeployActions.getMultiCloudYamlContentError(error));
    }
  }
}

export function* buildDeployWatcher() {
  yield takeEvery(
    BuildDeployActionTypes.CONFIGURE_LANDING_ZONE_STARTED,
    configureLandingZoneWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.ENABLE_MICROSERVIVES_PATTERN_STARTED,
    enableMicroServicesWorkload,
  );
  yield takeEvery(BuildDeployActionTypes.ENABLE_LANDING_ZONE_STARTED, enableLandingZoneWorker);
  yield takeEvery(BuildDeployActionTypes.DELETE_LANDING_ZONE_STARTED, destroyLandingZoneWorker);
  yield takeEvery(
    BuildDeployActionTypes.CONFIGURE_MICROSERVIVES_PATTERN_STARTED,
    configureMicroServicesWorker,
  );
  yield takeEvery(BuildDeployActionTypes.CONFIGURE_ETL_PATTERN_STARTED, configureEtlWorker);
  yield takeEvery(BuildDeployActionTypes.ENABLE_ETL_PATTERN_STARTED, enableEtlWorkLoadWorker);
  yield takeEvery(BuildDeployActionTypes.GET_BUILD_DEPLOY_DETAILS_STARTED, getLandingZonesWorker);
  yield takeEvery(BuildDeployActionTypes.CHECK_LANDING_ZONE_STATUS, checkLandingZoneStatus);
  yield takeEvery(
    BuildDeployActionTypes.CHECK_MICROSERVIVES_PATTERN_STATUS,
    checkMicroServicesPatternStatus,
  );
  yield takeEvery(
    BuildDeployActionTypes.CONFIGURE_LANDING_ERROR_SUCCESS,
    configureLandingZoneWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.DESTROY_WORKLOAD_PATTERN_STARTED,
    destroyWorkLoadPatternWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_LANDING_ZONE_ERRORS_STARTED, getLandingZoneError);
  yield takeEvery(
    BuildDeployActionTypes.CREATE_LANDING_ZONE_YAML_START,
    createLandingZoneYamlWorker,
  );
  yield takeEvery(BuildDeployActionTypes.ENVIRONMENTS_DEPLOYED_START, getEnvironmentDeployedWorker);
  yield takeEvery(
    BuildDeployActionTypes.LANDING_ZONES_DEPLOYMENT_STATUS_START,
    landingZoneDeploymentStatusWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_ARCHITECTURE_LIST_STARTED, getArchitectureListWorker);
  yield takeEvery(
    BuildDeployActionTypes.SET_ARCHTECTURE_VIEW_COUNT_STARTED,
    architectureViewCountWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.SET_ARCHTECTURE_DIAGRAM_STARTED,
    architectureDiagramWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_PIPELINES_ANALYTICS_STARTED, pipelinesAnalyticsWorker);
  yield takeEvery(
    BuildDeployActionTypes.GET_PIPELINES_CUMMULATIVE_ANALYTICS_STARTED,
    pipelinesCummulativeAnalyticsWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_YAML_CONTENT_STARTED, getYamlContentWorker);
  yield takeEvery(BuildDeployActionTypes.GET_DEFAULT_TEMPLATE_YAML_CONTENT_STARTED, getDeafultTemplateYamlContentWorker);
  yield takeEvery(
    BuildDeployActionTypes.GET_INDUSTRY_YAML_CONTENT_STARTED,
    getIndustryYamlContentWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.GET_CLOUD_LANDING_ZONE_KEYS_STARTED,
    getCloudLandingZoneKeysWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.SET_CLOUD_LANDING_ZONE_KEYS_STARTED,
    setCloudLandingZoneKeysWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_DEPLOYMENT_DETAILS_STARTED, getDeploymentsWorker);
  yield takeEvery(
    BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_STARTED,
    configureDeployCloudWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.CONFIGURE_DEPLOY_CLOUD_THROUGH_CONSOLE_STARTED,
    configureDeployCloudThroughConsoleWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_STATUS_STARTED,
    cloudLandingZoneDeployStatusWorker,
  );
  yield takeEvery(BuildDeployActionTypes.GET_DEPLOYMENT_AUDIT_STARTED, getDeploymentAuditsWorker);
  yield takeEvery(
    BuildDeployActionTypes.CLOUD_LANDING_ZONE_DEPLOY_DETAILED_LOGS_STARTED,
    cloudLandingZoneDeployDetailsWorker,
  );
  yield takeEvery(
    BuildDeployActionTypes.GET_MULTICLOUD_YAML_CONTENT_STARTED,
    getMultiCloudYamlContentWorker,
  );
}
