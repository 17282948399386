import React, { useMemo } from 'react';
import { Button, Progress, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import BrokerIcon from '../../assets/icons/broker-icon.svg';
import CaseNameIcon from '../../assets/icons/case-name.svg';
import GreaterThanIcon from '../../assets/icons/greater-than.svg';
import Low from '../../assets/icons/low.svg';
import Medium from '../../assets/icons/medium.svg';
import High from '../../assets/icons/high.svg';
import NoData from '../../assets/icons/no-data.svg';

import { CardsArray, ICardsArrayProps } from './SubHubInsuranceDashboardConstants';
import { useHistory } from 'react-router';

interface IDashboardCardsProp {
  searchText: string;
  breadCrumbRequired: boolean;
}
const DashboardCards = ({ searchText, breadCrumbRequired }: IDashboardCardsProp) => {
  const history = useHistory();
  const displayCards = useMemo(
    () =>
      CardsArray?.filter((card) => {
        // check on what basis to search for
        return (
          card.brokerName.toLowerCase().includes(searchText.toLowerCase()) ||
          card.caseId.toLowerCase().includes(searchText.toLowerCase()) ||
          card.caseName.toLowerCase().includes(searchText.toLowerCase())
        );
      }),
    [searchText],
  );
  if (displayCards.length === 0) {
    return (
      <div className="no-data-container">
        <img src={NoData} alt="no data" className="no-data-img" />
        <h2>No data found!</h2>
      </div>
    );
  }
  // generate random 8 digit case Id
  // const randomCaseId = Math.floor(10000000 + Math.random() * 90000000);

  return (
    <div className="cards-row">
      {displayCards.map((card) => (
        <div className="sub-hub px-0" key={card.caseId}>
          <div className="cat-caseid-container">
            {/* <span className={`${card.priority?.toLowerCase()}-bg category-pill`}>
              {card.priority}
            </span> */}
            <span className="case-id">ID {card.caseId}</span>
          </div>
          {/* <p className="card-header font-bold">{card.cardHeader}</p> */}
          <div className="cost-name-container">
            <div className="d-flex">
              <img src={CaseNameIcon} className="icon" alt="cost" />
              <div>
                <p className="mb-0-dot-5 font-bold">{card.caseName}</p>
                <p className="grey-text">Case Name</p>
              </div>
            </div>
            <div className="d-flex">
              <img src={BrokerIcon} className="icon" alt="cup" />
              <div>
                <p className="mb-0-dot-5 font-bold">{card.brokerName}</p>
                <p className="grey-text">Broker Name</p>
              </div>
            </div>
          </div>
          <div className="risk-view-container">
            <div className="w-80">
              <div className="flex-row-space-between">
                <p className="grey-text">Quote Status</p>
                <p className="mb-0">
                  {card.resolvedQuote}/{card.totalQuote}
                </p>
              </div>
              <div>
                <Tooltip title="">
                  <Progress
                    percent={(card.resolvedQuote * 100) / card.totalQuote}
                    showInfo={false}
                    trailColor="#E7E7E7"
                    strokeColor="#F4B309"
                  />
                </Tooltip>
              </div>
            </div>
            <Button
              // uncomment to view details page on click of view
              // onClick={()=>handleViewDetails(card)}
              onClick={() =>
                breadCrumbRequired
                  ? history.push('/dashboard/submissions/82477')
                  : history.replace(`/newCaseForm?caseId=${card.caseId}`)
              }
              type="text"
              className="view-btn p-0"
            >
              View <img src={GreaterThanIcon} alt="" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;
