import { Reducer } from 'redux';
import {
  IAppInscopeResponse,
  IAppScopeAdjustmentResponse,
  ICombineAttributeCountResponse,
  IHeatmapResponse,
} from '../../models/CloudSuitabilityModels';
import {
  CloudSuitabilityAction,
  CloudSuitabilityActionTypes,
} from '../actions/CloudSuitabilityActions';
import CloudSuitabilityState from '../state/CloudSuitabilityState';

const defaultHeatMapData: IHeatmapResponse = {
  listOfLOBs: [],
  listOfAttributes: [],
  totalNoOfApps: [],
  listOfAttributesInfo: [],
};

const defaultAppScope: IAppScopeAdjustmentResponse = {
  mapOfFilterCount: {},
  mapOfPlacementCount: {},
  mapOfHostFilterCount: {},
  mapOfHostPlacementCount: {},
};
const defaultAppInscope: IAppInscopeResponse = {
  app: [],
  host: [],
};
const defaultAppAdttibuteCount: ICombineAttributeCountResponse = {
  app: {
    attributeTypes: [],
    mapOfCustomAttributes: {},
    mapOfNONTechAttributes: {},
    mapOfTechAttributes: {},
    noOfCustomAttributes: 0,
    noOfNonTechnicalAttributes: 0,
    noOfTechnincalAttributes: 0,
  },
  host: {
    attributeTypes: [],
    mapOfCustomAttributes: {},
    mapOfNONTechAttributes: {},
    mapOfTechAttributes: {},
    noOfCustomAttributes: 0,
    noOfNonTechnicalAttributes: 0,
    noOfTechnincalAttributes: 0,
  },
};
export const initialState: CloudSuitabilityState = {
  error: '',
  loading: false,
  heatmapData: defaultHeatMapData,
  appScopeDevelopmentData: defaultAppScope,
  appInscopeData: defaultAppInscope,
  appAttributeCount: defaultAppAdttibuteCount,
};

export const CloudSuitabilityReducer: Reducer<CloudSuitabilityState> = (
  state = initialState,
  action: CloudSuitabilityAction,
) => {
  switch (action.type) {
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        heatmapData: action.data as IHeatmapResponse,
      };
    case CloudSuitabilityActionTypes.GET_HEATMAP_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        heatmapData: defaultHeatMapData,
      };
    case CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        appScopeDevelopmentData: action.data as IAppScopeAdjustmentResponse,
      };
    case CloudSuitabilityActionTypes.GET_APP_SCOPE_ADJUSTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        ap: defaultAppScope,
      };
    case CloudSuitabilityActionTypes.GET_APP_INSCOPE_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CloudSuitabilityActionTypes.GET_APP_INSCOPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        appInscopeData: action.data as IAppInscopeResponse,
      };
    case CloudSuitabilityActionTypes.GET_APP_INSCOPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        appInscopeData: defaultAppInscope,
      };
    case CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        appAttributeCount: action.data as ICombineAttributeCountResponse,
      };
    case CloudSuitabilityActionTypes.GET_APP_ADJUSTMENT_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        appAttributeCount: defaultAppAdttibuteCount,
      };
    default:
      return state;
  }
};
