import {
  ISecurityAssessmentGetObj,
  IUploadAssessmentState,
} from '../../../models/DevopsAnalyticsModels';
import {
  IAssessmentQuestionsResponse,
  IAssessmentResultPayload,
  ICloudHealthDataResponse,
  IDevOpsChartValues,
  IDevopsDataResponse,
  IGovAnalysisDataResponse,
  IGovDataType,
} from '../../state/DevOpsAnalyticsState';
import { IStoreAction } from '../../StoreHelper';

export enum DevOpsAnalyticsActionTypes {
  GET_ANALYTICS_ASSESSMENT_START = 'GET_ANALYTICS_ASSESSMENT_START',
  GET_ANALYTICS_ASSESSMENT_SUCCESS = 'GET_ANALYTICS_ASSESSMENT_SUCCESS',
  GET_ANALYTICS_ASSESSMENT_ERROR = 'GET_ANALYTICS_ASSESSMENT_ERROR',
  GET_GOVERNANCE_ASSESSMENT_START = 'GET_GOVERNANCE_ASSESSMENT_START',
  GET_GOVERNANCE_ASSESSMENT_SUCCESS = 'GET_GOVERNANCE_ASSESSMENT_SUCCESS',
  GET_GOVERNANCE_ASSESSMENT_ERROR = 'GET_GOVERNANCE_ASSESSMENT_ERROR',
  POST_GOVERNANCE_ASSESSMENT_START = 'POST_GOVERNANCE_ASSESSMENT_START',
  POST_GOVERNANCE_ASSESSMENT_SUCCESS = 'POST_GOVERNANCE_ASSESSMENT_SUCCESS',
  POST_GOVERNANCE_ASSESSMENT_ERROR = 'POST_GOVERNANCE_ASSESSMENT_ERROR',
  GET_ASSESSMENT_QUESTIONS_START = 'GET_ASSESSMENT_QUESTIONS_START',
  GET_ASSESSMENT_QUESTIONS_SUCCESS = 'GET_ASSESSMENT_QUESTIONS_SUCCESS',
  GET_ASSESSMENT_QUESTIONS_ERROR = 'GET_ASSESSMENT_QUESTIONS_ERROR',
  SET_ASSESSMENT_RESULT_START = 'SET_ASSESSMENT_RESULT_START',
  SET_ASSESSMENT_RESULT_SUCCESS = 'SET_ASSESSMENT_RESULT_SUCCESS',
  SET_ASSESSMENT_RESULT_ERROR = 'SET_ASSESSMENT_QUESTIONS_ERROR',
  GET_BUILD_DEPLOY_ASSESSMENT_START = 'GET_BUILD_DEPLOY_ASSESSMENT_START',
  GET_BUILD_DEPLOY_ASSESSMENT_SUCCESS = 'GET_BUILD_DEPLOY_ASSESSMENT_SUCCESS',
  GET_BUILD_DEPLOY_ASSESSMENT_ERROR = 'GET_BUILD_DEPLOY_ASSESSMENT_ERROR',

  GET_SECURITY_ASSESSMENT_GRAPH_DATA_START = 'GET_SECURITY_ASSESSMENT_GRAPH_DATA_START',
  GET_SECURITY_ASSESSMENT_GRAPH_DATA_SUCCESS = 'GET_SECURITY_ASSESSMENT_GRAPH_DATA_SUCCESS',
  GET_SECURITY_ASSESSMENT_GRAPH_DATA_ERROR = 'GET_SECURITY_ASSESSMENT_GRAPH_DATA_ERROR',

  GET_DISCOVER_ASSESSMENT_GRAPH_START = 'GET_DISCOVER_ASSESSMENT_GRAPH_START',
  GET_DISCOVER_ASSESSMENT_GRAPH_SUCCESS = 'GET_DISCOVER_ASSESSMENT_GRAPH_SUCCESS',
  GET_DISCOVER_ASSESSMENT_GRAPH_ERROR = 'GET_DISCOVER_ASSESSMENT_GRAPH_ERROR',
}

export type DevOpsAnalyticsActionPayload =
  | Error
  | IDevOpsChartValues[]
  | IDevopsDataResponse
  | string
  | IUploadAssessmentState
  | IGovAnalysisDataResponse
  | IAssessmentQuestionsResponse[]
  | IGovDataType
  | ISecurityAssessmentGetObj
  | IAssessmentResultPayload
  | ICloudHealthDataResponse;

export type DevOpsAnalyticsAction = IStoreAction<
  DevOpsAnalyticsActionTypes,
  DevOpsAnalyticsActionPayload
>;

export class DevOpsAnalyticsActions {
  public static getAnalyticsAssessmentStart(data: string): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_START,
      data,
    };
  }
  public static getAnalyticsAssessmentSuccess(data: IDevopsDataResponse): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_SUCCESS,
      data,
    };
  }

  public static getAnalyticsAssessmentError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ANALYTICS_ASSESSMENT_ERROR,
      data: error,
    };
  }
  public static getGovernanceAssessmentStart(data: string): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_START,
      data,
    };
  }
  public static getGovernanceAssessmentSuccess(
    data: IGovAnalysisDataResponse,
  ): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_SUCCESS,
      data,
    };
  }
  public static getGovernanceAssessmentError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_GOVERNANCE_ASSESSMENT_ERROR,
      data: error,
    };
  }

  public static getBuildDeployAssessmentStart(data: string): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_START,
      data,
    };
  }
  public static getBuildDeployAssessmentSuccess(
    data: IGovAnalysisDataResponse,
  ): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_SUCCESS,
      data,
    };
  }
  public static getBuildDeployAssessmentError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_BUILD_DEPLOY_ASSESSMENT_ERROR,
      data: error,
    };
  }

  public static getSecurityGovernanceAssessmentDataStart(
    data: ISecurityAssessmentGetObj,
  ): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_START,
      data,
    };
  }
  public static getSecurityGovernanceAssessmentDataSuccess(
    data: IGovAnalysisDataResponse,
  ): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_SUCCESS,
      data,
    };
  }
  public static getSecurityGovernanceAssessmentDataError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_SECURITY_ASSESSMENT_GRAPH_DATA_ERROR,
      data: error,
    };
  }

  public static postGovernanceAssessmentStart(data: IUploadAssessmentState): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_START,
      data,
    };
  }

  public static postGovernanceAssessmentSuccess(): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_SUCCESS,
    };
  }

  public static postGovernanceAssessmentError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.POST_GOVERNANCE_ASSESSMENT_ERROR,
      data: error,
    };
  }
  public static getAssessmentQuestionsStart(data: string): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_START,
      data,
    };
  }
  public static getAssessmentQuestionsSuccess(
    data: IAssessmentQuestionsResponse[],
  ): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_SUCCESS,
      data,
    };
  }

  public static getAssessmentQuestionsError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_ASSESSMENT_QUESTIONS_ERROR,
      data: error,
    };
  }
  public static setAssessmentResultStart(data: IAssessmentResultPayload): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_START,
      data,
    };
  }

  public static setAssessmentResultSSuccess(): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_SUCCESS,
    };
  }

  public static setAssessmentResultError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.SET_ASSESSMENT_RESULT_ERROR,
      data: error,
    };
  }

  public static getDiscoverAssessmentStart(data: ISecurityAssessmentGetObj): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_START,
      data,
    };
  }
  public static getDiscoverAssessmentSuccess(data: IDevopsDataResponse): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_SUCCESS,
      data,
    };
  }
  public static getDiscoverAssessmentError(error: Error): DevOpsAnalyticsAction {
    return {
      type: DevOpsAnalyticsActionTypes.GET_DISCOVER_ASSESSMENT_GRAPH_ERROR,
      data: error,
    };
  }
}
