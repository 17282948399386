import {
  ICreatePipelineResponse,
  ICreatePipelineState,
  IDeliveryPipelineState,
  IGetInstanceDetailsPayload,
  IPipelineHistory,
  IPipelineInstancePayload,
  IRunPipelineResponse,
  IRunPipelineState,
  ISelectedPipelineDetails,
  IUpdatePipelineResponse,
  IUpdatePipelineState,
} from '../../models/PipelineModels';
import { IStoreAction } from '../StoreHelper';

export enum PipelineActionTypes {
  CREATE_PIPELINE_START = 'CREATE_PIPELINE_START',
  CREATE_PIPELINE_SUCCESS = 'CREATE_PIPELINE_SUCCESS',
  CREATE_PIPELINE_ERROR = 'CREATE_PIPELINE_ERROR',
  GET_PIPELINES_START = 'GET_PIPELINES_START',
  GET_PIPELINES_SUCCESS = 'GET_PIPELINES_SUCCESS',
  GET_PIPELINES_ERROR = 'GET_PIPELINES_ERROR',
  UPDATE_PIPELINE_START = 'UPDATE_PIPELINES_START',
  UPDATE_PIPELINE_SUCCESS = 'UPDATE_PIPELINES_SUCCESS',
  UPDATE_PIPELINE_ERROR = 'UPDATE_PIPELINES_ERROR',
  RUN_PIPELINE_START = 'RUN_PIPELINES_START',
  RUN_PIPELINE_SUCCESS = 'RUN_PIPELINES_SUCCESS',
  RUN_PIPELINE_ERROR = 'RUN_PIPELINES_ERROR',
  GET_PIPELINE_INSTANCE_DETAILS_START = 'GET_PIPELINE_INSTANCE_DETAILS_START',
  GET_PIPELINE_INSTANCE_DETAILS_SUCCESS = 'GET_PIPELINE_INSTANCE_DETAILS_SUCCESS',
  GET_PIPELINE_INSTANCE_DETAILS_ERROR = 'GET_PIPELINE_INSTANCE_DETAILS_ERROR',
  GET_PIPELINE_INSTANCES_START = 'GET_PIPELINE_INSTANCES_START',
  GET_PIPELINE_INSTANCES_SUCCESS = 'GET_PIPELINE_INSTANCES_SUCCESS',
  GET_PIPELINE_INSTANCES_ERROR = 'GET_PIPELINE_INSTANCES_ERROR',
  SET_SELECTED_PIPELINE_DETAILS = 'SET_SELECTED_PIPELINE_DETAILS',
}

export type PipelineActionPayload =
  | string
  | ICreatePipelineResponse
  | IDeliveryPipelineState[]
  | ICreatePipelineState
  | IUpdatePipelineState
  | IUpdatePipelineResponse
  | IGetInstanceDetailsPayload
  | IPipelineInstancePayload
  | IPipelineHistory[]
  | IRunPipelineState
  | IRunPipelineResponse
  | ISelectedPipelineDetails
  | Error
  | null;

export type PipelineAction = IStoreAction<PipelineActionTypes, PipelineActionPayload>;
export class PipelineActions {
  public static createPipelineStart(data: ICreatePipelineState): PipelineAction {
    return {
      type: PipelineActionTypes.CREATE_PIPELINE_START,
      data,
    };
  }
  public static createPipelineSuccess(data: ICreatePipelineResponse): PipelineAction {
    return {
      type: PipelineActionTypes.CREATE_PIPELINE_SUCCESS,
      data,
    };
  }
  public static createPipelineError(error: Error): PipelineAction {
    return {
      type: PipelineActionTypes.CREATE_PIPELINE_ERROR,
      data: error,
    };
  }
  public static getPipelinesStart(landingZoneId: string): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINES_START,
      data: landingZoneId,
    };
  }
  public static getPipelinesSuccess(data: IDeliveryPipelineState[]): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINES_SUCCESS,
      data,
    };
  }
  public static getPipelinesError(error: Error): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINES_ERROR,
      data: error,
    };
  }
  public static updatePipelineStart(data: IUpdatePipelineState): PipelineAction {
    return {
      type: PipelineActionTypes.UPDATE_PIPELINE_START,
      data,
    };
  }
  public static updatePipelineSuccess(data: IUpdatePipelineResponse): PipelineAction {
    return {
      type: PipelineActionTypes.UPDATE_PIPELINE_SUCCESS,
      data,
    };
  }
  public static updatePipelineError(error: Error): PipelineAction {
    return {
      type: PipelineActionTypes.UPDATE_PIPELINE_ERROR,
      data: error,
    };
  }
  public static runPipelineStart(data: IRunPipelineState): PipelineAction {
    return {
      type: PipelineActionTypes.RUN_PIPELINE_START,
      data,
    };
  }
  public static runPipelineSuccess(data: IRunPipelineResponse): PipelineAction {
    return {
      type: PipelineActionTypes.RUN_PIPELINE_SUCCESS,
      data,
    };
  }
  public static runPipelineError(error: Error): PipelineAction {
    return {
      type: PipelineActionTypes.RUN_PIPELINE_ERROR,
      data: error,
    };
  }
  public static getPipelineInstanceDetailsStart(data: IGetInstanceDetailsPayload): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_START,
      data,
    };
  }

  public static getPipelineInstanceDetailsSuccess(data: IPipelineInstancePayload): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_SUCCESS,
      data,
    };
  }

  public static getPipelineInstanceDetailsError(data: Error): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCE_DETAILS_ERROR,
      data,
    };
  }

  public static getPipelineInstancesStart(data: string): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCES_START,
      data,
    };
  }

  public static getPipelineInstancesSuccess(data: IPipelineHistory[]): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCES_SUCCESS,
      data,
    };
  }

  public static getPipelineInstancesError(data: Error): PipelineAction {
    return {
      type: PipelineActionTypes.GET_PIPELINE_INSTANCES_ERROR,
      data,
    };
  }

  public static setSelectedPipelineDetails(data: ISelectedPipelineDetails): PipelineAction {
    return {
      type: PipelineActionTypes.SET_SELECTED_PIPELINE_DETAILS,
      data,
    };
  }
}
