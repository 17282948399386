import {
  IFormListResp,
  IGuardrailDetailsResponse,
  IGuardrailPayload,
  IMetadataDetailResp,
  IMetadataPayload,
} from '../../models/MetadataGuardrailModels';
import { API_ROUTE } from '../ApiRoute';
import ApiService from '../ApiService';
import ApiServiceBase from '../ApiServiceBase';
import { ServerType, ServiceType } from '../ServiceType.data';

const serviceType = ServiceType.None;

export class MetadataGuardrailService {
  private readonly service: ApiServiceBase = new ApiService(serviceType, ServerType.MNACIBE);

  public getGuardrailsDetails(
    payload: IGuardrailPayload,
  ): Promise<IGuardrailDetailsResponse> | IGuardrailDetailsResponse {
    return this.service.post({ route: [`${API_ROUTE.GET_METADATA_GUARDRAIL}`] }, { ...payload });
  }
  public getMetadataDetails(
    payload: IMetadataPayload,
  ): Promise<IMetadataDetailResp> | IMetadataDetailResp {
    return this.service.post({ route: [`${API_ROUTE.GET_METADATA_GUARDRAIL}`] }, { ...payload });
  }
  public getMetadataFormlist(payload: IGuardrailPayload): Promise<IFormListResp> | IFormListResp {
    return this.service.post({ route: [`${API_ROUTE.GET_METADATA_GUARDRAIL}`] }, { ...payload });
  }
}

const metadataGuardrailService = new MetadataGuardrailService();
export default metadataGuardrailService;
