import { Reducer } from 'redux';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { ToastAction, ToastActionTypes } from '../actions/ToastActions';
import ToastState from '../state/ToastState';

export const initialState: ToastState = {
  error: '',
  loading: false,
  showToast: false,
  description: '',
  message: '',
  type: NotificationTypes.INFO,
};

export const toastReducer: Reducer<ToastState> = (state = initialState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionTypes.SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        type: action.data?.type as NotificationTypes,
        description: action.data?.description as string,
        message: action.data?.message as string,
      };

    case ToastActionTypes.HIDE_TOAST:
      return {
        ...state,
        showToast: false,
      };

    default:
      return state;
  }
};
