import { routes } from '../../navigation/Routes';

export const routesWithLoadingDisabled = [routes.accountManagement];
export interface ICountLandingZone {
  all: number;
  gcp: number;
  aws: number;
  azure: number;
  oracle: number;
  openshift: number;
  multicloud: number;
}
