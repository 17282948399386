// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAWXqYmRrgvTpc0azRwdw376T1fnCeqhJ0",
  authDomain: "project2-dlt-subscriber.firebaseapp.com",
  projectId: "project2-dlt-subscriber",
  storageBucket: "project2-dlt-subscriber.appspot.com",
  messagingSenderId: "109193599507",
  appId: "1:109193599507:web:8f910a35da782064827de6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default app;
